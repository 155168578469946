<template>
  <ui-popup
    style-for-body="width: 50vw"
    @close="emit('close')"
  >
    <ui-close-button @close="emit('close')" />
    <h3 class="text-center mb-2">История изменений</h3>
    <ui-table>
      <template #header>
        <tr>
          <template v-for="(cell, key) in TABLE_HEADER">
            <ui-table-th
              :cell="cell"
              :cell-key="key"
            />
          </template>
        </tr>
      </template>
      <template #default>
        <template v-for="row of props.data">
          <tr>
            <template v-for="(cell, key) in TABLE_HEADER">
              <ui-table-td
                v-if="key == 'id'"
                :html="getEditor(row[key])"
              />
              <ui-table-td
                v-else-if="key == 'time'"
                :html="getFormatDate(row[key])"
              />
              <ui-table-td
                v-else
                :html="row[key]"
              />
            </template>
          </tr>
        </template>
      </template>
    </ui-table>
  </ui-popup>
</template>

<script setup>
import UiPopup from './UiPopup.vue';
import UiCloseButton from '../Buttons/UiCloseButton.vue';
import UiTable from '@/ManualApp/Components/Tables/UiTable.vue';
import UiTableTd from '@/ManualApp/Components/Tables/UiTableTd.vue';
import UiTableTh from '@/ManualApp/Components/Tables/UiTableTh.vue';

import { useTable } from '@/ManualApp/use/table';

import { STORE } from '@/ManualApp/STORAGE/STORAGE';

const props = defineProps({
  data: { type: Array, default: [] },
});
const emit = defineEmits(['close']);

const TABLE_HEADER = useTable.headers.logs;

const getEditor = (id) => {
  return STORE.creators[id] || `${id}:нет_логина`;
};

const getFormatDate = (unix) => {
  return formatDateHelper(new Date(unix * 1000), 'dd.mm.yyyy, hh:nn:ss');
};
</script>

<style lang="scss" scoped></style>
