<template>
  <button
    class="button"
    :class="[{ error: props.error }, { disabled: props.disabled }]"
    @click="clickHandler"
  >
    <slot />
  </button>
</template>

<script setup>
const props = defineProps({
  error: Boolean,
  disabled: Boolean,
});

const emit = defineEmits(['click']);

const clickHandler = (e) => {
  emit('click', e);
};
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/default.scss';

</style>
