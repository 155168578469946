interface I_CATEGORIES {
  [key: number]: [number, string, string] | [number, string, string, string];
}
export const CATEGORIES: I_CATEGORIES = {
  // gCategory - главная категория номер (3)
  // category - категория номер (31)
  // category.Name - категория название
  // categories(iobj.gCategory).Name - главная категория название
  // category : gCategory(num), gCategory(name), category.Name
  0: [0, 'All objects', 'All objects', 'standard'],
  101: [0, 'All objects', 'Отключен', 'standard'],
  1: [1, 'Статичный объект', 'Статичный объект'],
  11: [1, 'Статичный объект', 'ДЭС'],
  12: [1, 'Статичный объект', 'Кран'],
  13: [1, 'Статичный объект', 'АЗС'],
  2: [2, 'Легковой транспорт', 'Легковой транспорт'],
  21: [2, 'Легковой транспорт', 'Пикап'],
  3: [3, 'Грузовики', 'Грузовики'],
  31: [3, 'Грузовики', 'Самосвал'],
  32: [3, 'Грузовики', 'Седельный тягач'],
  33: [3, 'Грузовики', 'Автобус на базе груз.тс'],
  34: [3, 'Грузовики', 'Топливозаправщик'],
  4: [4, 'Спец.техника', 'Спец.техника'],
  41: [4, 'Спец.техника', 'Бульдозер'],
  42: [4, 'Спец.техника', 'Трактор'],
  43: [4, 'Спец.техника', 'Экскаватор'],
  44: [4, 'Спец.техника', 'Каток'],
  45: [4, 'Спец.техника', 'Погрузчик'],
  46: [4, 'Спец.техника', 'Асфальукладчик'],
  47: [4, 'Спец.техника', 'Дорожная фреза'],
  5: [5, 'Автокран', 'Автокран'],
  6: [6, 'Автогрейдер', 'Автогрейдер'],
  7: [7, 'Автобусы', 'Автобусы'],
  71: [7, 'Автобусы', 'Микроавтобусы'],
  8: [8, 'VIP', 'VIP'],
  9: [9, 'Флот', 'Флот'],
  10: [10, 'Вездеход', 'Вездеход'],
};
