<template>
  <tr :class="{ 'selected-row': selectedGeofence === geofence }">
    <!-- <td @click="selectRow(geofence)" class="cursor-pointer"> -->
    <td :title="isVisible ? 'убрать с карты' : 'показать на карте'">
      <input
        type="checkbox"
        v-model="geofence.isVisible"
      />
    </td>
    <td v-if="searchType === 'id'">{{ geofence.id }}</td>
    <td
      @click="$emit('geofence-select', geofence)"
      @dblclick="$emit('geofence-show-on-map', geofence)"
      class="cursor-pointer unselectable"
    >
      {{ geofence.name }}
    </td>
    <!-- <td>{{ geofence.key }}</td> -->
    <!-- <td>{{ geofence.id }}</td> -->
    <td>
      <button
        v-show="!geofence.editing"
        @click="$emit('geo-edit', geofence)"
        class="btn btn-primary config-btn-primary"
        :disabled="Boolean(editButtonTitle)"
        :title="editButtonTitle"
      >
        Изменить
      </button>
      <button
        v-show="geofence.editing"
        @click="$emit('geo-edit-cancel', geofence)"
        class="btn btn-primary config-btn-primary"
        :disabled="!geofence.beforeEdit.geoJson"
      >
        Отменить
      </button>
    </td>
    <td>
      <button
        @click="$emit('geo-save', geofence)"
        :class="`btn btn-${saveButtonClass}`"
        :disabled="geofence.deleting || isSaveDisabled"
        :title="geofence.sendingError"
      >
        {{ saveButtonText }}
      </button>
    </td>
    <td>
      <button
        @click="$emit('remove', geofence)"
        class="btn btn-danger"
        :disabled="
          !rule_geofences_delete ||
          !radactMode ||
          geofence.sending ||
          geofence.deleting
        "
        :title="geofence.deletingError"
      >
        {{ geofence.deleting ? 'Удаляется' : 'Удалить' }}
      </button>
    </td>
    <!-- <td>{{ geofence.geoJson }}</td> -->
  </tr>
</template>

<script>
export default {
  name: 'vue-geofencies-list-item',
  props: {
    geofence: {
      type: Object,
      required: true,
    },
    radactMode: {
      type: Boolean,
      required: true,
    },
    selectedGeofence: {
      type: Object,
      required: true,
    },
    editingKeys: {
      type: Object,
      required: true,
    },
    rule_geofences_delete: {
      type: Boolean,
      required: true,
    },
    rule_geofences_create: {
      type: Boolean,
      required: true,
    },
    rule_geofences_update: {
      type: Boolean,
      required: true,
    },
    show_geofence: {
      type: Function,
      required: true,
    },
    searchType: {
      type: String,
      default: '',
    },
  },
  emits: {
    'remove': Object,
    'geofence-select': Object,
    'geofence-show-on-map': Object,
    'geo-save': Object,
    'geo-edit': Object,
    'geo-edit-cancel': Object,
  },
  // data() {
  //   // return {
  //   //   selectedGeofence: null
  //   // }
  // },
  computed: {
    isVisible() {
      return this.geofence.isVisible;
    },
    isSaveDisabled() {
      const geofence = this.geofence;
      const { id, beforeEdit, geoJson, sending } = geofence;
      if (!geoJson || sending) {
        return true;
      }
      if (!(id > 0)) {
        return false;
      }
      const editingKeys = this.editingKeys;
      for (let { key } of editingKeys) {
        if (beforeEdit[key] !== geofence[key]) {
          return !(key in beforeEdit);
        }
      }
      return true;
    },
    saveButtonText() {
      if (this.geofence.sending) {
        return 'Отправка..';
      }
      if (this.geofence.id > 0 && this.isSaveDisabled) {
        return 'Сохранено';
      }
      return 'Сохранить';
    },
    saveButtonClass() {
      const { sending, sendingError, id } = this.geofence;
      if (sending) {
        return 'warning';
      }
      if (sendingError) {
        return 'danger';
      }
      if (id > 0 && this.isSaveDisabled) {
        return 'secondary';
      }
      return 'success';
    },
    // saveButtonTitle() {
    //   return this.geofence.title ?? false;
    // },
    geoName() {
      return this.geofence.name;
    },
    editButtonTitle() {
      if (!this.rule_geofences_update) {
        return 'Недостаточно прав для редактирования';
      }
      if (!this.radactMode) {
        return 'Отключен режим редактирования';
      }
      if (!this.geofence.layer) {
        return 'Отсутствует фигура геозоны';
      }
      return '';
    },
  },
  watch: {
    geoName(name) {
      this.geofence.layer?.bindTooltip(name);
    },
    isVisible(value) {
      this.show_geofence(this.geofence, value);
    },
  },
  methods: {
    selectRow(geofence) {
      this.$emit('geofence-select', geofence);
    },
  },
};
</script>

<style scoped>
.selected-row {
  background-color: rgba(255, 255, 0, 0.56);
}
tr:hover td {
  background-color: rgb(218, 218, 218);
}
tr.selected-row:hover td {
  background-color: rgb(255, 255, 0);
}
.geofences-list-wrapper .cursor-pointer {
  cursor: pointer;
  opacity: 1;
}
table button {
  padding: 2px 4px;
  margin: 0 2px;
  font-size: small;
}
th,
td {
  border: 1px solid #ddd;
  padding: 0 5px;
}
</style>
