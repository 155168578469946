import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService.js';
import { CalculateGraphData } from '../graphics/CalculateGraphData.js';
import {formatTimeHelper2} from '@/helpers/main_helper'

const roundTo = 10; // округление до 10-ти минут
let CanIndicatorsTemplateSettingElement;

export class CanIndicatorsTemplate {
  settingEventListener = (event) => {
    const { target } = event;

    const isButton = Boolean(target.closest('.btn'));

    const isClose = Boolean(
      target.closest('.background-wrap') || target.closest('.close'),
    );

    if (isClose || isButton) {
      this.removeButtonEventListener();
      this.sittingWindow.remove();
    }

    if (isButton) {
      this.callOurAggregated();
    }
  };

  constructor(OurAggregated, skillsManTemplate, form_desc) {
    this.OurAggregated = OurAggregated;
    this.skillsManTemplate = skillsManTemplate;
    this.form_desc = form_desc;

    this.createSettingWidow();
  }

  createSettingWidow() {
    if (!CanIndicatorsTemplateSettingElement) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = this.getSettingTempate('CAN показатели');

      CanIndicatorsTemplateSettingElement = wrapper.firstElementChild;
    }

    this.sittingWindow = CanIndicatorsTemplateSettingElement;
    this.settingSubelements = this.getSubElements(
      CanIndicatorsTemplateSettingElement,
    );
  }

  showSettings() {
    document.body.append(this.sittingWindow);
    this.addButtonEventListener();
  }

  addButtonEventListener() {
    // this.sittingWindow.addEventListener('click', {handleEvent: this.settingEventListener, that: this});
    this.sittingWindow.addEventListener('click', this.settingEventListener);
  }

  removeButtonEventListener() {
    // this.sittingWindow.removeEventListener('click', {handleEvent: this.settingEventListener, that: this});
    this.sittingWindow.removeEventListener('click', this.settingEventListener);
  }

  async callOurAggregated() {
    const ourAggregated = new this.OurAggregated(this.skillsManTemplate);
    const { smenasSeconds, isGetSmenas, smenasOrigin } =
      this.getSmenasSetting();

    const templateName = 'getCalculated';

    fetchToTemplateService({
      templateName,
      form_desc: this.form_desc,
      options: {
        option: 0,
        smenasSeconds,
        isGetSmenas,
        smenasOrigin,
        settingSubelements: this.settingSubelements,
      },
      areaId: 'section-reports',
      successCallback: this.calculated(this),
      errorCallback: () =>
        ourAggregated.callOurAggregated({
          smenasSeconds,
          option: 0,
          templateName,
          // form_desc: false,
          form_desc: this.form_desc,
          callback: this.calculated(this),
          callbackComponent: this,
          roundTo: 10,
          areaId: 'section-reports',
          isGetSmenas,
          smenasOrigin,
        }),
    });
  }

  getSmenasSetting() {
    const isGetSmenas = Boolean(
      this.settingSubelements['smenasTemplateDetailFlag'].checked,
    );
    const smenasFlag = Boolean(
      this.settingSubelements['smenasTemplateSmenasFlag'].checked,
    );

    const smenasSeconds = [];
    const smenasOrigin = [];

    if (!isGetSmenas || !smenasFlag) {
      return {
        smenasSeconds,
        isGetSmenas,
        smenasOrigin,
      };
    }

    for (let s = 1; s < 4; s++) {
      const sSetting = {};
      const smenaName = `smenasTemplate-s${s}`;

      if (!this.settingSubelements[smenaName].checked) {
        break;
      }

      const sBeginHours = this.settingSubelements[`${smenaName}-bh`].value;
      const sBeginMinutes = this.settingSubelements[`${smenaName}-bm`].value;
      const sEndHours = this.settingSubelements[`${smenaName}-eh`].value;
      const sEndMinutes = this.settingSubelements[`${smenaName}-em`].value;

      sSetting['begin'] = this.getSmenasSetting_pice(
        sBeginHours,
        sBeginMinutes,
        roundTo,
      );
      sSetting['end'] = this.getSmenasSetting_pice(
        sEndHours,
        sEndMinutes,
        roundTo,
      );
      smenasOrigin.push(sSetting);

      for (let key in sSetting) {
        if (!smenasFlag) {
          break;
        }

        const secondsOfTheDay = this.getSmenas_getTime(sSetting[key]) / 1000;
        if (
          secondsOfTheDay > 0 &&
          secondsOfTheDay != 86400 &&
          !smenasSeconds.includes(secondsOfTheDay)
        ) {
          smenasSeconds.push(secondsOfTheDay);
        }
      }

      // sSetting = {};
    }

    return {
      smenasSeconds,
      isGetSmenas,
      smenasOrigin,
    };
  }

  getSmenas_getTime(timeStroke) {
    // seconds from begin day (secons of a day)
    return (
      (Number(timeStroke.split(':')[0]) * 60 +
        Number(timeStroke.split(':')[1])) *
      60000
    ); // в милисекундах
  }

  getSmenasSetting_pice(hours, minutes, roundTo) {
    roundTo = roundTo || 10;

    if (!hours && !minutes) {
      return '00:00';
    }

    if (+hours > 23 || +hours < 0) hours = '00';
    if (!minutes) {
      return hours + ':00';
    }

    if (+minutes > 60 || +minutes < 0) {
      return hours + ':' + '00';
    }

    const deciMinute = minutes.charAt(0) || '0';

    if (roundTo == 10) {
      // минута всегда будет нулевой
      return hours + ':' + deciMinute + '0'; // минуты по 10 минут допускаются
    }

    const minute = minutes.charAt(1) || '0';

    // roundTo = 5
    if (+minute < 5) {
      return hours + ':' + deciMinute + '0'; // минуты по 5 минут допускаются, но тут округление
    }
    return hours + ':' + deciMinute + '5'; // минуты по 5 минут допускаются
  }

  calculated(that) {
    return (objectsCalculated, params) => {
      that.calculateTableData(objectsCalculated, params);
    };
  }

  calculateTableData(
    objectsCalculated,
    { targetElement, buttonElement, areaId, isGetSmenas } = {},
  ) {
    const { objects = [], format, rows_summ } = objectsCalculated;

    if (!objects.length) {
      infoShowText_helper(targetElement, 'Не выбран ни один объект');
      setElementColor_helper(buttonElement, 'orange');

      return;
    }

    infoShowText_helper(
      targetElement,
      'Данные получены, анализирую...',
      areaId,
    );

    const calculateGraphData = new CalculateGraphData({ format });

    const { objectsData, violationNames } = calculateGraphData.getObjectsData(
      objects,
      rows_summ,
    );
    const periodsLabels = calculateGraphData.getPeriodsLabels(
      objects[0],
      'Графики производственных показателей по CAN данным',
    );

    const chartSummData = calculateGraphData.chartSummAnalysis(
      objectsData,
      periodsLabels,
      violationNames,
    );

    const tableSumm = this.getTableSumm(objectsCalculated);
    const tablePeriods = this.getTablePeriods(chartSummData);

    document.getElementById(areaId).innerHTML = this.getReportTemplate(
      tableSumm,
      tablePeriods,
      isGetSmenas,
    );

    infoShowText_helper(targetElement, 'CAN отчет построен. Выполнить новый?');
    setElementColor_helper(buttonElement, 'green');

  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  getReportTemplate(tableSumm, tablePeriods, isGetSmenas) {
    const { t_interval } = tableSumm;

    return `
        <button class="not-print float-left" onclick="html_to_excel_helper.export( this, 'can-indicators-template-content', 'CAN показатели', 'portrait' )">
            Экспорт&nbsp;в&nbsp;EXCEL 
        </button>
        <div class="temlate-report" id="can-indicators-template-content">
            <div data-excelsheet="Отчет по CAN (суммарный)"></div>
            <h6 class="footer-desc text-center font-weight-bold" >Отчет по CAN данным ${t_interval} (суммарный)</h6>
            <p class="text-center excel-bottom-indent">
              Отчет сформирован ${formatTimeHelper2(new Date())}
            </p>
            ${this.tableSummTemplate(tableSumm)}
            ${this.tablePeriodsWithWrapperTemplate(
              tablePeriods,
              isGetSmenas,
              t_interval,
            )}
            
            
        </div>`;
  }

  tablePeriodsWithWrapperTemplate(tablePeriods, isGetSmenas, t_interval) {
    if (!isGetSmenas) {
      return '';
    }

    return `
            <hr class="not-print not-excel">
            <div data-excelsheet="Отчет по CAN (по сменам)"></div>
            <h6 class="footer-desc page-break-before text-center font-weight-bold">Отчет по CAN данным ${t_interval} (по сменам)</h6>
            ${this.tablePeriodsTemplate(tablePeriods)}
        `;
  }

  tablePeriodsTemplate(tablePeriods) {
    const { headers, rowsGroups, formats } = tablePeriods;

    return `<table border="1" cellpadding="5" style="border-collapse:collapse; border:1px solid black; caption-side: top; margin-right: 5px;">
                ${this.tableHeaderTemplate(headers)}
                ${this.tableGroupBodyTemplate({ headers, rowsGroups, formats })}
            </table>`;
  }

  tableSummTemplate(tableSumm) {
    const { headers, rows, formats } = tableSumm;
    return `<table border="1" cellpadding="5" style="border-collapse:collapse; border:1px solid black; caption-side: top; margin-right: 5px;">
                ${this.tableHeaderTemplate(headers)}
                ${this.tableBodyTemplate({ headers, rows, formats })}
            </table>`;
  }

  tableGroupBodyTemplate({ headers, rowsGroups, formats }) {
    const rows = rowsGroups.map((rows, index) => {
      const rowColor = index % 2 ? '#EEECE1' : '';
      return rows
        .map((row) =>
          this.getTableRowTemplate({ row, headers, formats, rowColor }),
        )
        .join('');
    });

    return `<tbody>${rows.join('')}</tbody>`;
  }

  tableBodyTemplate({ headers, rows, formats }) {
    const bodyRowsArr = rows.map((row) =>
      this.getTableRowTemplate({ row, headers, formats }),
    );
    return `<tbody>${bodyRowsArr.join('')}</tbody>`;
  }

  getTableRowTemplate({ row, headers, formats, rowColor }) {
    const tdColor = rowColor ? ` bgcolor="${rowColor}"` : '';
    const colsArr = headers.map((header, hIndex) => {
      const { name, structures } = header;
      const align = hIndex
        ? ' class="text-center" data-excelalignh="center"'
        : '';

      const result = {
        formatnum: formats[name],
        data_value: '',
        value: null,
      };

      if (structures) {
        const { joinStr } = header;

        const values = structures.map((structure) => {
          const {
            name: structureName,
            format: structureFormatNum,
            toDisplay,
          } = structure;
          const originValue = row[structureName];
          const displayValue = formatToDisplay_helper(
            originValue,
            structureFormatNum,
          );

          if (toDisplay) {
            return toDisplay(displayValue);
          }

          return displayValue;
        });

        result.value = values.join(joinStr);
      } else {
        const originValue = row[name];
        const { formatnum: formatnumOfValue = 0 } = result;
        result.value = formatToDisplay_helper(originValue, formatnumOfValue);
        result.data_value =
          formatnumOfValue <= 4 ? ` data-value="${originValue}"` : '';
      }

      const { formatnum, value, data_value } = result;

      return `<td${tdColor} data-formatnum="${formatnum}"${data_value}${align}>${value}</td>`;
    });

    return `<tr>${colsArr.join('')}</tr>`;
  }

  tableHeaderTemplate(headers) {
    const headerRowArr = headers.map((header) => {
      const { text, minwidth } = header;
      const dataMinwidth = minwidth ? ` data-minwidth="${minwidth}"` : '';
      return `<th class="text-center" data-excelalignh="center"${dataMinwidth}>${header.text}</th>`;
    });

    return `<thead><tr class="print-frozen-row print-repeat">${headerRowArr.join(
      '',
    )}</tr></thead>`;
  }

  getTablePeriods(chartSummData) {
    const { summData, labels } = chartSummData;
    const { objectsName, summOfPeriods, summOfObjects } = summData;

    const headers = this.getHeadersPeriods();

    const formats = headers.reduce((accum, header) => {
      const { name, format = 0 } = header;
      accum[name] = format;

      return accum;
    }, {});

    const rowsGroups = objectsName.reduce((accum, objName, objIndex) => {
      const { rows_summ_line, avtoModel, avtoNo } = summOfObjects[objIndex];
      const { notData } = rows_summ_line;

      if (notData) {
        return accum;
      }

      const objPeriods = summOfPeriods.map((period, periodIndex) => {
        const periodLabel = labels[periodIndex]
          .split('- ')
          .map((partLabel) => partLabel.replace(/ /g, '&nbsp;'))
          .join('- ');
        const objPeriod = { objName, avtoModel, avtoNo, periodLabel };

        const cnt_real_pos = period.cnt_real_pos[objIndex];

        Object.entries(period).forEach(([key, value]) => {
          objPeriod[key] = cnt_real_pos ? value[objIndex] : 'н/д';
        });

        return objPeriod;
      });

      accum.push(objPeriods);
      return accum;
    }, []);

    return {
      rowsGroups,
      headers,
      formats,
    };
  }

  getTableSumm(objectsCalculated) {
    const { objects = [], rows_summ } = objectsCalculated;
    const { lines: linesGroup, header: rows_summ_headers } = rows_summ;
    const [lines] = linesGroup;

    const headers = this.getHeadersSumm();
    const { t_interval } = objects[0].view;

    const rows_experiment_columns = [
      'avgSpd',
      'canExpence',
      'canExpenceSpd',
      'canExpenceNoSpd',
      'canExpence100km',
      'canExpence100kmMove',
      'motoEngine',
      'motoEngineSpd',
      'motoEngineNoSpd',
      'canExpenceLHourMove',
      'canExpenceLHourIdle',
      'ptoCnt',
      'pedalBreakTime',
      'breakTime',
      'engineBreakTime',
      'engineBreakTimePercent',
      'absCount',
      'kickdownCnt',
      'clutchUseMoveStartExcessiveWorkPercent',
      'serviceDistance',
      'lowOilLampCount',
      'hightCoolTempLampCount',
      'clutchSlipVehicleSlipRpmCnt',
      'clutchSlipVehicleSlipWork',
      'clutchSlipCriticalCnt',
      'kickdownCnt100km',
      'absCount100km',
      'ptoCnt100km',
      'pedalBreakTime100km',
      'engineBreakTime100km',
      'cnt_pos_missed',
      'cnt_real_pos',
    ];

    const object_columns = [
      'client',
      'avtoModel',
      'avtoNo',
      'id',
      'name',
      'notData',
    ];
    const rows_person_columns = [];
    const summ_line_columns = [
      'forfeits',
      'forfeitsRelative',
      'DReceivedPercent',
      'DDistBegin',
      'DDist',
    ];

    const objectsData = objects.reduce(
      (accum, object, objIndex) => {
        const { rows_experiment, rows_person } = object;
        const { person: rows_person_rows, header: rows_person_headers } =
          rows_person;
        const row = {};

        object_columns.forEach((object_column) => {
          row[object_column] = object[object_column];

          if (!objIndex) {
            accum.formats[object_column] = 0;
          }
        });

        rows_experiment_columns.forEach((rows_experiment_column) => {
          row[rows_experiment_column] =
            rows_experiment[rows_experiment_column].val.val;

          if (!objIndex) {
            accum.formats[rows_experiment_column] =
              rows_experiment[rows_experiment_column].val.format;
          }
        });

        rows_person_columns.forEach((rows_person_column) => {
          row[rows_person_column] =
            rows_person_rows[rows_person_column]['DCount'];

          if (!objIndex) {
            accum.formats[rows_person_column] =
              rows_person_headers[rows_person_column]['format'];
          }
        });

        summ_line_columns.forEach((summ_line_column) => {
          const { id } = row;
          const objLine = lines.find((line) => Boolean(line.objId == id));

          row[summ_line_column] = objLine[summ_line_column];

          if (!objIndex) {
            accum.formats[summ_line_column] =
              rows_summ_headers[summ_line_column]['format'];
          }
        });

        accum.rows[objIndex] = row;

        return accum;
      },
      { t_interval, headers, rows: [], formats: {} },
    );

    const { rows: objectsRows } = objectsData;

    if (objectsRows.length > 2) {
      const columnsForСalculation =
        rows_experiment_columns.concat(summ_line_columns);

      const totalObjectsSumm = this.getTotalObjectsSumm({
        objects,
        objectsRows,
        object_columns,
        columnsForСalculation,
      });
      objectsRows.push(totalObjectsSumm);
    }

    return objectsData;
  }

  getTotalObjectsSumm({
    objects,
    objectsRows,
    object_columns,
    columnsForСalculation,
  } = {}) {
    const [object] = objects;
    const { rows_experiment } = object;

    const { periodSplitDependentRows: periodSplitDependentRows_experiment } =
      rows_experiment;
    const notCalculate = [
      'DReceivedPercent',
      'DDistBegin',
      'serviceDistance',
      'forfeitsRelative',
    ];

    const splitDependent = columnsForСalculation.reduce(
      (accum, column) => {
        if (periodSplitDependentRows_experiment.includes(column)) {
          accum.dependent.push(column);
        } else if (!notCalculate.includes(column)) {
          accum.notDependent.push(column);
        }

        return accum;
      },
      { dependent: [], notDependent: [] },
    );

    const { dependent: dependentRows, notDependent: notDependentRows } =
      splitDependent;

    const total = objectsRows.reduce((accum, objectData) => {
      if (!accum['id']) {
        object_columns
          .concat(notCalculate)
          .forEach((column) => (accum[column] = ' - '));
        accum['avtoModel'] = 'Сумма по объектам';
        accum['notData'] = true;

        notDependentRows.forEach((column) => (accum[column] = 0));
      }

      if (!objectData['notData']) {
        accum['notData'] = false;

        notDependentRows.forEach((column) => {
          const value = objectData[column] || '?';

          if (isNumber_helper(value)) {
            accum[column] += value;
          }
        });
      }

      return accum;
    }, {});

    if (total['notData']) {
      columnsForСalculation.forEach((column) => {
        total[column] = 'н/д';
      });
    } else {
      if (!('distance' in total)) {
        total['distance'] = total['DDist'];
      }

      dependentRows.forEach((column) => {
        total[column] = getRelativeValue_helper(column, total);
      });

      total['DReceivedPercent'] = getRelativeValue_helper(
        'receivedPercent',
        total,
      );
      total['forfeitsRelative'] = getRelativeValue_helper(
        'forfeitsRelative',
        total,
      );
      total['engineBreakTimePercent'] /= 100;
    }

    return total;
  }

  getSettingTempate(templateName) {
    return `
        <div>
            <!-- Настройка отчета: задний прозрачный фон-->
            <div class="background-wrap" style="display: flex;"></div>
            <!-- Настройка отчета: само окно-->
            <div class="template-setting-window" style="display: flex;">
            
                <div class="template-setting-window-header">
                    <div class="template-setting-window-header-name">
                        <strong>${templateName}</strong><br>
                        <small>Настройка отчета</small>
                    </div>
                    <!-- Картинка крестика-->
                    <img data-element="close" class="close" src="/images/close.png">
                </div>
            
                <div data-element="settings" class="overflow-auto">
                    <div>
                        <label><input data-element="ownerColumnFlag" type="checkbox">
                            Владелец (поле на суммарном листе)
                        </label>
                        <br>
                        
                        <input data-element="smenasTemplateDetailFlag" class="smenasTemplate-detailFlag" id="smenasTemplate-detailFlag" type="checkbox" checked>
                        <label for="smenasTemplate-detailFlag">
                            Детализация
                            <span class="isDetailFlag"><i class="blue"> активно деление на смены или сутки</i></span>
                            <span class="isNotDetailFlag"><i class="blue"> отключена, только сумма за период</i></span>
                        </label>
                        <br>
                        
                        <input data-element="smenasTemplateSmenasFlag" class="smenasTemplate-SmenasFlag" id="smenasTemplate-SmenasFlag" type="checkbox" checked>
                        <label for="smenasTemplate-SmenasFlag">
                            Включить смены
                            <span class="isSmenasFlag"><i class="blue"> активно деление на смены</i></span>
                            <span class="isNotSmenasFlag"><i class="blue"> активно деление на сутки</i></span>
                        </label>
                        <br>

                        <label>
                            <input data-element="smenasTemplate-s1" type="checkbox" checked disabled>
                            Смена 1
                        </label>
                        <input data-element="smenasTemplate-s1-bh" type="text" class="input-hours" value="07">
                        <span>:</span>
                        <input data-element="smenasTemplate-s1-bm" type="text" class="input-hours" value="00">
                        <span>-</span> 
                        <input data-element="smenasTemplate-s1-eh" type="text" class="input-hours" value="19">
                        <span>:</span>
                        <input data-element="smenasTemplate-s1-em" type="text" class="input-hours" value="00">
                        <br>

                        <label>
                            <input data-element="smenasTemplate-s2" type="checkbox" checked>
                            Смена 2
                        </label>
                        <input data-element="smenasTemplate-s2-bh" type="text" class="input-hours" value="19">
                        <span>:</span>
                        <input data-element="smenasTemplate-s2-bm" type="text" class="input-hours" value="00">
                        <span>-</span>
                        <input data-element="smenasTemplate-s2-eh" type="text" class="input-hours" value="07">
                        <span>:</span>
                        <input data-element="smenasTemplate-s2-em" type="text" class="input-hours" value="00">
                        <br>
                        
                        <label>
                            <input data-element="smenasTemplate-s3" type="checkbox">
                            Смена 3
                        </label>
                        <input data-element="smenasTemplate-s3-bh" type="text" class="input-hours" value="00">
                        <span>:</span>
                        <input data-element="smenasTemplate-s3-bm" type="text" class="input-hours" value="00">
                        <span>-</span> 
                        <input data-element="smenasTemplate-s3-eh" type="text" class="input-hours" value="00">
                        <span>:</span>
                        <input data-element="smenasTemplate-s3-em" type="text" class="input-hours" value="00">
                        <br>

                        
                        <!--hr-->
                        <!--Столбцы
                        <table>
                            <tr>
                                <td><input id="shiftWork-cols-endSmena" type="checkbox" checked></td>
                                <td><label title="лист детализация" for="shiftWork-cols-endSmena">Окончание смены</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-distIgn" type="checkbox" checked></td>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-distIgn">Пробег с раб. ДВС</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-distIgnOff" type="checkbox" checked></td>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-distIgnOff">Пробег с откл. ДВС</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-beginLevel" type="checkbox" checked>
                                <td><label title="лист детализация" for="shiftWork-cols-beginLevel">Уровень на начало дня</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-endLevel" type="checkbox" checked>
                                <td><label title="лист детализация" for="shiftWork-cols-endLevel">Уровень на конец дня</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-fuelIn" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-fuelIn">Заправки</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-fuelOut" type="checkbox" checked>
                                <td><label title="исты сумма и детализация" for="shiftWork-cols-fuelOut">Сливы</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-fuelExpence" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-fuelExpence">Расход</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-fuelExpenceAvgLHour" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-fuelExpenceAvgLHour">Общий расход, л/ч</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-expenceMove" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-expenceMove">Расход в движении</label>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-expenceAktiv" type="checkbox" checked>
                                <td><label title="Для спецтехники, листы сумма и детализация" for="shiftWork-cols-expenceAktiv">Расход при активной работе, л</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-expenceIdle" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-expenceIdle">Расход на стоянке</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-expenceIdlePercent" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-expenceIdlePercent">Доля расхода на стоянке</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgExpenceL100km" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-avgExpenceL100km">Общ. расход л/100км</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgExpenceMoveL100km" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-avgExpenceMoveL100km">Расход в движении л/100км</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgExpenceMoveLHour" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-avgExpenceMoveLHour">Расход в движении л/час</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgExpenceAktivLHour" type="checkbox" checked>
                                <td><label title="Для спецтехники, листы сумма и детализация" for="shiftWork-cols-avgExpenceAktivLHour">Расход при активной работе, л/час</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgExpenceParkLHour" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-avgExpenceParkLHour">Расход на стоянке л/час</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-avgSpeed" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-avgSpeed">Средняя скорость</label></td>
                            </tr>
                    
                            <tr>
                                <td><input id="shiftWork-cols-engineWork" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-engineWork">Работа ДВС</label></td>
                            </tr>
                    
                            <tr>
                                <td><input id="shiftWork-cols-engineWorkMove" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-engineWorkMove">Работа ДВС в движении</label></td>
                            </tr>
                    
                            <tr>
                                <td><input id="shiftWork-cols-engineWorkAktiv" type="checkbox" checked>
                                <td><label title="Для спецтехники, листы сумма и детализация" for="shiftWork-cols-engineWorkAktiv">  Время активной работы</label></td>
                            </tr>
                    
                            <tr>
                                <td><input id="shiftWork-cols-engineWorkPark" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-engineWorkPark">Работа ДВС на стоянке</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-moto2" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-moto2">Моточасы 2</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-expenceMoto2" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-expenceMoto2">Расход при моточасах 2</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-dataReceivePercent" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-dataReceivePercent">Получение данных %</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-faultDUT" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-faultDUT">Неисправность ДУТ</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-lastPwr" type="checkbox" checked>
                                <td><label title="листы сумма и детализация" for="shiftWork-cols-lastPwr">Б.пит. в посл. позиции</label></td>
                            </tr>
                            <tr>
                                <td><input id="shiftWork-cols-lastRealTime" type="checkbox" checked>
                                <td><label title="лист сумма" for="shiftWork-cols-lastRealTime">Б.пит. в посл. позиции</label></td>
                            </tr>
                        </table-->
                    </div>
                    <div class="padding-top-5 text-center"><small>Окончание настройки отчета</small></div>
                    
                </div>
                <div class="text-center">
                    <span class="target-show">
                    <input data-element="button" class="btn btn-primary config-btn-primary btn-sm" type="submit" value="Выполнить отчет">
                    <span class="target-show-span">Обсчет данных для получения отчета</span>
                    </span>
                </div>
            
            </div>
        </div>
        `;
  }

  getHeadersSumm() {
    const headersSumm = [
      { name: 'client', text: 'Владелец', minwidth: 20 },
      { name: 'avtoModel', text: 'Модель', minwidth: 15 },
      { name: 'avtoNo', text: 'Гос. номер', minwidth: 11 },
      {
        name: 'DDistBegin',
        text: 'Пробег по КАН на начало периода, км',
        minwidth: 10,
      },
      { name: 'DDist', text: 'Пробег по CAN за период, км', minwidth: 8 },
      { name: 'motoEngine', text: 'Время работы ДВС', minwidth: 9 },
      {
        name: 'motoEngineSpd',
        text: 'Время работы ДВС в движении',
        minwidth: 9,
      },
      {
        name: 'motoEngineNoSpd',
        text: 'Время работы ДВС на стоянке',
        minwidth: 9,
      },
      { name: 'avgSpd', text: 'Средняя скорость по CAN, км/ч', minwidth: 8 },
      {
        name: 'canExpence',
        text: 'Общий расход топлива по CAN, л.',
        minwidth: 8,
      },
      {
        name: 'canExpence100km',
        text: 'Расход топлива по CAN общий, л/100 км',
        minwidth: 8,
      },
      {
        name: 'canExpence100kmMove',
        text: 'Расход топлива по CAN при скорости > 0, л/100 км',
        minwidth: 8,
      },
      {
        name: 'canExpenceLHourMove',
        text: 'Расход топлива по CAN при скорости > 0, л/час',
        minwidth: 8,
      },
      {
        name: 'canExpenceLHourIdle',
        text: 'Расход топлива по CAN при скорости=0, л/час',
      },
      {
        name: 'ptoCnt_ptoCnt100km',
        text: 'Число поднятий кузова (если между поднятиями кузова пауза менее 1 мин. такие поднятия засчитываются как одно) / на 100км',
        minwidth: 18,
        structures: [
          {
            name: 'ptoCnt',
            format: 12,
          },
          {
            name: 'ptoCnt100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'pedalBreakTime_pedalBreakTime100km',
        text: 'Ножной тормоз, сработка при движении, время / на 100км',
        minwidth: 9,
        structures: [
          {
            name: 'pedalBreakTime',
            format: 4,
          },
          {
            name: 'pedalBreakTime100km',
            format: 4,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'engineBreakTime_engineBreakTime100km',
        text: 'Моторный тормоз, время работы при движении / на 100км',
        minwidth: 9,
        structures: [
          {
            name: 'engineBreakTime',
            format: 4,
          },
          {
            name: 'engineBreakTime100km',
            format: 4,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'engineBreakTimePercent',
        text: 'Доля моторного тормоза по времени торможения при движении, %',
        minwidth: 8,
        format: 6,
      },
      {
        name: 'absCount_absCount100km',
        text: 'Сработка ABS, кол-во / на 100км.',
        minwidth: 8,
        structures: [
          {
            name: 'absCount',
            format: 12,
          },
          {
            name: 'absCount100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },

      {
        name: 'kickdownCnt_kickdownCnt100km',
        text: 'Сработка режима Kik-daun, кол-во / на 100км.',
        minwidth: 8,
        structures: [
          {
            name: 'kickdownCnt',
            format: 12,
          },
          {
            name: 'kickdownCnt100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      // {name: 'clutchSlipVehicleSlipRpmCnt', text: 'Буксование, пробуксовка при нагреве дисков более 100 градусов, оборотов', minwidth: 8},
      // {name: 'clutchSlipVehicleSlipWork', text: 'Буксование, работа при буксовании - нагреве дисков более 100 градусов, кДж', minwidth: 8},
      {
        name: 'clutchSlipVehicleSlip_RpmCnt_Work',
        text: 'Буксование (нагрев дисков от 100 до 200 градусов), оборотов/работа,кДж',
        minwidth: 10,
        structures: [
          {
            name: 'clutchSlipVehicleSlipRpmCnt',
            format: 12,
          },
          {
            name: 'clutchSlipVehicleSlipWork',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'clutchSlipCriticalCnt',
        text: 'Критическая пробуксовка сцепления (перегрев более 200 градусов), оборотов',
        format: 13,
        minwidth: 10,
      },
      {
        name: 'clutchUseMoveStartExcessiveWorkPercent',
        text: 'Излишняя работа сцепления, %',
        minwidth: 8,
      },
      {
        name: 'lowOilLampCount_hightCoolTempLampCount',
        text: 'Сработка аварийных ламп: давления масла, аварийной температуры ДВС, кол-во',
        minwidth: 10,
        structures: [
          {
            name: 'lowOilLampCount',
            format: 12,
            toDisplay: (value) => `${value} масло`,
          },
          {
            name: 'hightCoolTempLampCount',
            format: 12,
            toDisplay: (value) => `${value} ДВС`,
          },
        ],
        joinStr: ' / ',
      },
      // {name: 'lowOilLampCount', text: 'Сработка аварийного давления масла, кол-во', minwidth: 8},
      // {name: 'hightCoolTempLampCount', text: 'Сработка аварийной температуры ДВС, кол-во', minwidth: 8},
      { name: 'serviceDistance', text: 'Пробег до ТО, км.', minwidth: 8 },
      {
        name: 'forfeitsRelative',
        text: 'Суммарные балы по "Навыкам вождения", на 100 км',
        minwidth: 8,
      },
      { name: 'DReceivedPercent', text: 'Приход данных, %', minwidth: 8 },
    ];

    if (!this.settingSubelements['ownerColumnFlag'].checked) {
      headersSumm.splice(0, 1); // delete {name: 'client'...}
    }

    return headersSumm;
  }

  getHeadersPeriods() {
    return [
      { name: 'avtoModel', text: 'Модель', format: 0, minwidth: 15 },
      { name: 'avtoNo', text: 'Гос. номер', format: 0, minwidth: 11 },
      { name: 'periodLabel', text: 'Смена', format: 0, minwidth: 10 },
      // {name: 'DDistBegin', text: 'Пробег по КАН на начало периода, км'},
      {
        name: 'dist',
        text: 'Пробег по CAN за период, км',
        format: 5.5,
        minwidth: 8,
      },
      { name: 'motoEngine', text: 'Время работы ДВС', format: 4, minwidth: 9 },
      {
        name: 'motoEngineSpd',
        text: 'Время работы ДВС в движении',
        format: 4,
        minwidth: 9,
      },
      {
        name: 'motoEngineNoSpd',
        text: 'Время работы ДВС на стоянке',
        format: 4,
        minwidth: 9,
      },
      {
        name: 'avgSpd',
        text: 'Средняя скорость по CAN, км/ч',
        format: 5.5,
        minwidth: 8,
      },
      {
        name: 'canExpence',
        text: 'Общий расход топлива по CAN, л.',
        format: 5.5,
        minwidth: 8,
      },
      {
        name: 'canExpence100km',
        text: 'Расход топлива по CAN общий, л/100 км',
        format: 5.5,
        minwidth: 11,
      },
      {
        name: 'canExpence100kmMove',
        text: 'Расход топлива по CAN при скорости > 0, л/100 км',
        format: 5.5,
        minwidth: 10,
      },
      {
        name: 'canExpenceLHourMove',
        text: 'Расход топлива по CAN при скорости > 0, л/час',
        format: 5.5,
        minwidth: 10,
      },
      {
        name: 'canExpenceLHourIdle',
        text: 'Расход топлива по CAN при скорости=0, л/час',
        format: 5.5,
        minwidth: 10,
      },
      // {name: 'ptoCnt', text: 'Число поднятий кузова (если между поднятиями кузова пауза менее 1 мин. такие поднятия засчитываются как одно)', format: 13, minwidth: 18},
      {
        name: 'ptoCnt_ptoCnt100km',
        text: 'Число поднятий кузова (если между поднятиями кузова пауза менее 1 мин. такие поднятия засчитываются как одно) / на 100км',
        minwidth: 18,
        structures: [
          {
            name: 'ptoCnt',
            format: 12,
          },
          {
            name: 'ptoCnt100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      // {name: 'pedalBreakTime', text: 'Ножной тормоз, сработка при движении, время', format: 4, minwidth: 9},
      // {name: 'engineBreakTime', text: 'Моторный тормоз, время работы при движении', format: 4, minwidth: 9},
      {
        name: 'pedalBreakTime_pedalBreakTime100km',
        text: 'Ножной тормоз, сработка при движении, время / на 100км',
        minwidth: 9,
        structures: [
          {
            name: 'pedalBreakTime',
            format: 4,
          },
          {
            name: 'pedalBreakTime100km',
            format: 4,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'engineBreakTime_engineBreakTime100km',
        text: 'Моторный тормоз, время работы при движении / на 100км',
        minwidth: 9,
        structures: [
          {
            name: 'engineBreakTime',
            format: 4,
          },
          {
            name: 'engineBreakTime100km',
            format: 4,
          },
        ],
        joinStr: ' / ',
      },

      {
        name: 'engineBreakTimePercent',
        text: 'Доля моторного тормоза по времени торможения при движении, % (1)',
        format: 6.5,
        minwidth: 11,
      },
      {
        name: 'absCount_absCount100km',
        text: 'Сработка ABS, кол-во / на 100км.',
        minwidth: 8,
        structures: [
          {
            name: 'absCount',
            format: 12,
          },
          {
            name: 'absCount100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },

      {
        name: 'kickdownCnt_kickdownCnt100km',
        text: 'Сработка режима Kik-daun, кол-во / на 100км.',
        minwidth: 8,
        structures: [
          {
            name: 'kickdownCnt',
            format: 12,
          },
          {
            name: 'kickdownCnt100km',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'clutchUseMoveStartExcessiveWorkPercent',
        text: 'Излишняя работа сцепления, %',
        format: 6.5,
        minwidth: 10,
      },
      {
        name: 'clutchSlipVehicleSlip_RpmCnt_Work',
        text: 'Буксование (нагрев дисков от 100 до 200 градусов), оборотов/работа,кДж',
        minwidth: 10,
        structures: [
          {
            name: 'clutchSlipVehicleSlipRpmCnt',
            format: 12,
          },
          {
            name: 'clutchSlipVehicleSlipWork',
            format: 14,
          },
        ],
        joinStr: ' / ',
      },
      {
        name: 'clutchSlipCriticalCnt',
        text: 'Критическая пробуксовка сцепления (перегрев более 200 градусов), оборотов',
        format: 13,
        minwidth: 10,
      },
      {
        name: 'lowOilLampCount_hightCoolTempLampCount',
        text: 'Сработка аварийных ламп: давления масла, аварийной температуры ДВС, кол-во',
        minwidth: 10,
        structures: [
          {
            name: 'lowOilLampCount',
            format: 12,
            toDisplay: (value) => `${value} масло`,
          },
          {
            name: 'hightCoolTempLampCount',
            format: 12,
            toDisplay: (value) => `${value} ДВС`,
          },
        ],
        joinStr: ' / ',
      },
      // {name: 'lowOilLampCount', text: 'Сработка аварийного давления масла, кол-во', format: 13, minwidth: 10},
      // {name: 'hightCoolTempLampCount', text: 'Сработка аварийной температуры ДВС, кол-во', format: 13, minwidth: 10},
      {
        name: 'serviceDistance',
        text: 'Пробег до ТО, км.',
        format: 5.5,
        minwidth: 10,
      },
      {
        name: 'forfeitsRelative',
        text: 'Суммарные балы по "Навыкам вождения", на 100 км',
        format: 5.5,
        minwidth: 9,
      },
      {
        name: 'receivedPercent',
        text: 'Приход данных, %',
        format: 6,
        minwidth: 8,
      },
    ];
  }
}
