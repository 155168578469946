import {formatTimeHelper2} from '@/helpers/main_helper'

export const skillsManTemplateHtml = `
  <button class="not-print float-left"
      onclick="html_to_excel_helper.export( this, 'skills-man-template-content', 'Навыки MAN суммарный', 'portrait' )">
      Экспорт&nbsp;в&nbsp;EXCEL
  </button>

    <div class="temlate-report" id="skills-man-template-content">
      <% let styleTable = 'border="1" cellpadding="5" style="border-collapse:collapse; border:1px solid black; caption-side: top; margin-right: 5px;"'; %>
      <% let styleCaption = 'style="caption-side:top;"'; %>
      <% let data_formatNum_value = ''; %>
      <h6 class="text-center font-weight-bold">Оценка эффективности эксплуатации по парку ТС <%= objects[0]['view']['t_interval'] %></h6>
      <p class="text-center excel-bottom-indent"><%= objects[0]['view']['smenasStr'] %>. Отчет сформирован ${formatTimeHelper2(new Date())}</p>
      <hr class="not-print margin-none">

      <table <%= styleTable %> >
          <caption <%= styleCaption %>>Суммарные показатели</caption>
          <thead>
              <tr class="text-center">
                  <% let colsNoSpanArr =
                          [
                              'startEngineGroup', 'motoIdlingGroup', 'moveStartGroup', 'moveGroup',
                              'ptoWorkKomGroup', 'drivingStyleGroup'
                          ];
                      for (let i = 0; i < rows_summ['settingsSumm'].length; i++){
                          let key = rows_summ['settingsSumm'][i];

                          if (colsNoSpanArr.indexOf(key) != -1) {
                              if(key != colsNoSpanArr[0]) continue;
                              %><th data-excelalignv="middle" colspan="<%= colsNoSpanArr.length %>" data-excelalignh="center">Нарушения по категориям эксплуатации ТС</th><%
                          }else{
                              %><th data-excelalignv="middle" rowspan="2" data-minwidth="<%= rows_summ['header'][key]['minWidth'] %>" data-excelalignh="center"><%= rows_summ['header'][key]['name'] %></th><%
                          }
                      }
                  %>
              </tr>
              <tr class="text-center">
                  <%
                      for(let i = 0; i < rows_summ['settingsSumm'].length; i++){
                          let key = rows_summ['settingsSumm'][i];
                          if(colsNoSpanArr.indexOf(key) == -1) continue;
                          %><th data-excelalignv="middle" data-excelalignh="center" data-minwidth="<%= rows_summ['header'][key]['minWidth'] %>"><%= rows_summ['header'][key]['name'] %></th><%
                      }
                  %>
              </tr>
          </thead>
          <tbody>
              <%  for(let i = 0; i < rows_summ['lines'][0].length; i++) { %>
                  <tr>
                      <% for(let j = 0; j < rows_summ['settingsSumm'].length; j++){
                          let key = rows_summ['settingsSumm'][j];
                          let formatNum = rows_summ['header'][key]['format'];
                          data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, rows_summ['lines'][0][i][key]);
                        //   data_formatNum_value = 'data-formatnum="1" data-value="1122907"'; // for example
                          let toAnchorBegin = '';
                          let toAnchorEnd = '';
                          let anchor = '';
                          let notData = rows_summ['lines'][0][i]['notData'];
                          let centerExcelHtml = j ? ' data-excelalignh="center"' : '';
                          let textCenterClass = j ? ' class="text-center"' : '';

                          if (! notData && key == 'DAvtoNo') {
                            data_formatNum_value = ' data-formatnum="0" data-value '
                              anchor = '<a name="summ-' + i + '"></a>';
                              toAnchorBegin = '<a class="anchor-href" href="#object-' + i + '">';
                              toAnchorEnd = '</a>';
                          } %>
                          <td <%= data_formatNum_value + textCenterClass + centerExcelHtml %>>
                          <%=  
                            toAnchorBegin + 
                            format(rows_summ['lines'][0][i][key],
                            formatNum)
                            + toAnchorEnd +
                            anchor
                          %>
                            </td>
                      <% } %>
                  </tr>
              <% } %>

          </tbody>
      </table>


      <%
          let detailColumns = ['DNum', 'DSkill', /*'DType', */'DForfeits', 'DForfeitsRelative']; //столбцы детализации
      %>

      <% for(let i = 0; i < objects.length; i++) {
          if (objects[i]['notData']) {
              continue;
          }
          let tableArr = objects[i]['rows_person']; %>
          <hr class="not-print excel-bottom-indent margin-bottom-small">
          <div class="page-break-after"></div>

          <table <%= styleTable %>>
              <caption <%= styleCaption %>>
                  <a name="object-<%= i %>"></a>
                  <a class="not-print anchor-href" href="#summ-<%= i %>"> в таблицу "Суммарные показатели"</a><br>
                  <%= objects[i]['view']['t_name'] +  objects[i]['view']['t_interval'] %>
              </caption>
              <thead>
                  <tr class="text-center">
                      <% for(let j = 0; j < detailColumns.length; j++) {
                          let key = detailColumns[j];
                          let dataRowHeight = tableArr['header'][key]['name'].length > 20 ? 'data-minheight="30"' : '';
                          %>
                          <th data-excelalignv="middle" data-excelalignh="center" data-excelcolspan="<%= tableArr['header'][key]['colspan'] %>" <%= dataRowHeight %>><%= tableArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < tableArr['personForDisplay'].length; j++) { %>
                      <% for(let jj = 0; jj < tableArr['personForDisplay'][j].length; jj++) {
                          let keyStr = tableArr['personForDisplay'][j][jj]; %>

                          <tr>
                              <% for(let k = 0; k < detailColumns.length; k++) {
                                  let key = detailColumns[k];
                                  let formatNum = tableArr['header'][key]['format'];
                                  let excelcolspan = tableArr['header'][key]['colspan'];
                                  let centerExcelHtml = k > 1 ? ' data-excelalignh="center"' : '';
                                  let textCenterClass = k > 1 ? ' class="text-center"' : '';
                                  if(jj > 0) {
                                      let warningClass = '';
                                      if(tableArr['person'][keyStr]['DCount'] && tableArr['person'][keyStr]['IsRoughly']) {
                                          warningClass = textCenterClass ? 'class="text-danger text-center"' : 'class="text-danger"';
                                          textCenterClass = '';
                                      }
                                      let dataRowHeight = tableArr['person'][keyStr][key].length > 61 ? 'data-minheight="30"' : '';
                                      data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableArr['person'][keyStr][key]);
                                      %>
                                      <td <%= warningClass + ' ' + dataRowHeight + ' ' + data_formatNum_value + centerExcelHtml + textCenterClass %> data-excelcolspan="<%= excelcolspan %>"><%= format(tableArr['person'][keyStr][key], formatNum) %></td>
                                  <% } else { %>
                                      <% if(k == 1) { %>
                                          <td colspan="3" class="font-weight-bold" data-excelcolspansumm="<%= excelcolspan + 4 %>"><%= tableArr['person'][keyStr][key] %></td>
                                      <% } else if(k < 1) { %>
                                          <td class="font-weight-bold" data-excelcolspan="<%= excelcolspan %>"><%= tableArr['person'][keyStr][key] %></td>
                                      <% } %>
                                  <% } %>
                              <% } %>
                          </tr>

                      <% } %>
                  <% } %>
              </tbody>

          </table>
          <div class="page-break-after excel-bottom-indent"></div>


          <hr class="hr-dashed not-print margin-none">

          <% let tableExArr = objects[i]['rows_experiment']; %>

          <h5 class="text-center font-weight-bold" style="margin-top:15px;"> <%= tableExArr['view']['list_name'] %> </h5>
          <span><%= 'Модель/тип ТС: ' + objects[i].avtoModel + ', VIN: ' + objects[i].vin %><br></span>
          <span><%= 'Тип КПП/число передач: ' + objects[i].gearboxType + ' /' + objects[i].gearboxCnt %><br></span>
          <table class="excel-not-border excel-bottom-indent">
              <%
                  let rHeight = '';
                  let brSplit = tableExArr['view']['locksList'].split('<br>');
                  if ( brSplit.length > 1 ) {
                      let height = 15 * brSplit.length;
                      rHeight = 'data-minheight="' + height + '"';
                  }
              %>
              <tr>
                  <td class="align-baseline" data-excelcolspan="2">Наличие внедорожного режима:</td>
                  <td data-excelcolspan="8" <%= rHeight %>><%= tableExArr['view']['locksList'] %></td>
              </tr>
          </table>


          <table class="excel-bottom-indent text-center" <%= styleTable %>>
              <caption <%= styleCaption %>>Статистика работы ТС за период</caption>
              <%
                  let ex_detailColumns = ['motoEngine', 'motoEngineNoSpd', 'motoEngineSpd', 'distance', 'avgSpd', 'canExpence', 'canExpenceSpd', 'canExpenceNoSpd']; //столбцы
                  let ex_detailValues = ['val', 'percent']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j]; %>
                          <th data-excelalignv="middle" data-excelalignh="center"><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let j = 0; j < ex_detailColumns.length; j++) {
                              let key = ex_detailColumns[j];
                              if(! (keyVal in tableExArr[key]) ) continue;

                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              let rowspan = '';
                              if(tableExArr[key][keyVal]['rowspan']) {
                                  rowspan = 'rowspan="' + tableExArr[key][keyVal]['rowspan'] + '"';
                              }%>

                              <td data-excelalignh="center" data-excelalignv="middle" <%= rowspan + ' ' + data_formatNum_value %>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>

          <table class="excel-bottom-indent text-center" <%= styleTable %>>
              <caption <%= styleCaption %>>Удельный расход топлива и пробег до ТО</caption>
              <%
                  ex_detailColumns = ['canExpence100km', 'canExpence100kmMove', 'canExpenceLHourMove', 'canExpenceLHourIdle', 'serviceDistance']; //столбцы
                  ex_detailValues = ['val']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j]; %>
                          <th data-excelcolspan="2" data-excelalignh="center" data-excelalignv="middle"><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let j = 0; j < ex_detailColumns.length; j++) {
                              let key = ex_detailColumns[j];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              %>

                              <td data-excelcolspan="2" data-excelalignh="center" <%=data_formatNum_value%>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>

          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Статистика работы трансмиссии</caption>
              <%
                  let ex_detailColumns_2 = ['swithGearBoxCnt', 'swithGearBoxCntPerDist', 'swithClutchCnt', 'swithClutchBroken', 'clutchWorkSumm']; //столбцы
                  // let ex_detailValues = ['val']; //строки
              %>
              <tbody>
                  <% for(let j = 0; j < ex_detailColumns_2.length; j++) {
                      let key = ex_detailColumns_2[j];
                      let formatNum = tableExArr[key]['val']['format'];
                      data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key]['val']['val']);
                      %>
                      <tr>
                          <th data-excelalignv="middle" class="text-left" data-excelcolspan="4" > <%= tableExArr['header'][key]['name'] %> </th>
                          <td class="text-center" data-excelalignh="center" <%= data_formatNum_value %>><%= format(tableExArr[key]['val']['val'], formatNum) %></td>
                      </tr>
                  <% } %>
              </tbody>
          </table>


          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование КПП по пробегу</caption>
              <%
                  ex_detailColumns = tableExArr.header.GearBoxNumNameArr; //столбцы
                  ex_detailValues = ['dist', 'distPercent']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j];
                          data_formatNum_value = addFormatAndValAttributesFromLodash_helper(tableExArr['header'][key]['format'], tableExArr['header'][key]['name']);
                          let textLeftClass = j ? '' : ' class="text-left"';
                          let centerExcelHtml = j ? ' data-excelalignh="center"' : '';
                          %>
                          <th <%= data_formatNum_value + textLeftClass + centerExcelHtml %>><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let k = 0; k < ex_detailColumns.length; k++) {
                              let key = ex_detailColumns[k];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              let classBoldAndAlign= k ? 'class="text-center"' : 'class="font-weight-bold text-left"';
                              let centerExcelHtml = k ? ' data-excelalignh="center"' : '';
                              %>

                              <td <%= classBoldAndAlign + ' ' + data_formatNum_value + centerExcelHtml %>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>

          <table class="excel-bottom-indent text-center" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование сцепления по началу движения</caption>
              <%
                  ex_detailColumns = [
                      'clutchUseMoveStartCnt', 'clutchUseMoveStartSlipCnt', 'clutchUseMoveStartSlipCntPercent',
                      'clutchUseMoveStartSlipHiCnt', 'clutchUseMoveStartSlipLowCnt'
                  ]; //столбцы
                  ex_detailValues = ['val']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j]; %>
                          <th data-excelcolspan="2" data-excelalignh="center" data-excelalignv="middle"><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let j = 0; j < ex_detailColumns.length; j++) {
                              let key = ex_detailColumns[j];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              %>

                              <td data-excelcolspan="2" data-excelalignh="center" <%=data_formatNum_value%>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>

          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Работа сцепления по началу движения</caption>
              <%
                  ex_detailColumns = ['clutchUseMoveStartWorkSumm', 'clutchUseMoveStartExcessiveWorkSumm', 'clutchUseMoveStartExcessiveWorkPercent',
                  'clutchUseMoveStartExcessiveWorkHi', 'clutchUseMoveStartExcessiveWorkLow', 'clutchUseMoveStartExcessiveWorkLowPercent']; //столбцы
                  // let ex_detailValues = ['val']; //строки
              %>
              <tbody>
                  <% for(let j = 0; j < ex_detailColumns.length; j++) {
                      let key = ex_detailColumns[j];
                      let formatNum = tableExArr[key]['val']['format'];
                      data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key]['val']['val']);
                      %>
                      <tr>
                          <th data-excelalignv="middle" class="text-left" data-excelcolspan="4" > <%= tableExArr['header'][key]['name'] %> </th>
                          <td class="text-center" data-excelalignh="center" <%= data_formatNum_value %>><%= format(tableExArr[key]['val']['val'], formatNum) %></td>
                      </tr>
                  <% } %>
              </tbody>
          </table>


          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование КПП по началу движения: количество, наличие пробуксовок сцепления</caption>
              <%
                  ex_detailColumns = tableExArr.header.GearBoxNumNameArr; //столбцы
                  ex_detailValues = [
                      'cntBeginMove', 'cntBeginMovePercent', 'cntRpmClutchSlip', 'cntRpmClutchSlipPercent',
                      'clutchSlipExcessiveWork', 'clutchSlipExcessiveWorkPercent', 'clutchSlipExcessiveWorkLow',
                      'avgAccelMoveBegin', 'avgAccelMoveBeginWithClutchSlip'
                  ]; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j];
                          data_formatNum_value = addFormatAndValAttributesFromLodash_helper(tableExArr['header'][key]['format'], tableExArr['header'][key]['name']);
                          let textLeftClass = j ? '' : ' class="text-left"';
                          let centerExcelHtml = j ? ' data-excelalignh="center"' : '';
                          %>
                          <th <%= data_formatNum_value + textLeftClass + centerExcelHtml %>><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let k = 0; k < ex_detailColumns.length; k++) {
                              let key = ex_detailColumns[k];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              let classBoldAndAlign= k ? 'class="text-center"' : 'class="font-weight-bold text-left"';
                              let centerExcelHtml = k ? ' data-excelalignh="center"' : '';%>

                              <td data-excelalignv="middle" <%= classBoldAndAlign + ' ' + data_formatNum_value + centerExcelHtml %>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>


          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование режимов трансмиссии</caption>
              <%
                  ex_detailColumns = tableExArr.header.transmissionLockNameArr; //столбцы
                  ex_detailValues = ['count', 'time', 'dist', 'distPercent']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j];
                          let colspan = j ? 2 : 0;
                          let textLeftClass = j ? '' : ' class="text-left"';
                          let centerExcelHtml = j ? ' data-excelalignh="center"' : '';
                          %>
                          <th data-excelalignv="middle" data-excelcolspan="<%= colspan %>"<%=textLeftClass+centerExcelHtml%>><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>
                      <tr>
                          <% for(let k = 0; k < ex_detailColumns.length; k++) {
                              let key = ex_detailColumns[k];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              let classBold = '';
                              let classBoldAndAlign= k ? 'class="text-center"' : 'class="font-weight-bold text-left"';
                              let centerExcelHtml = k ? ' data-excelalignh="center"' : '';
                              let colspan = k ? 2 : 0; %>

                              <td <%= classBoldAndAlign + centerExcelHtml + ' ' + data_formatNum_value %> data-excelcolspan="<%= colspan %>"> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>


          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование ножного и моторного тормоза</caption>
              <%
                  ex_detailColumns = [
                      'breakCount', 'breakTime', 'pedalBreakCount', 'pedalBreakTime', 'engineBreakCnt', 'engineBreakTime',
                      'engineBreakCntPercent', 'engineBreakTimePercent', 'absCount'
                  ]; //столбцы
              %>
              <tbody>
                  <% for(let j = 0; j < ex_detailColumns.length; j++) {
                      let key = ex_detailColumns[j];
                      let formatNum = tableExArr[key]['val']['format'];
                      data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key]['val']['val']);
                      %>
                      <tr>
                          <th data-excelalignv="middle" class="text-left" data-excelcolspan="7" > <%= tableExArr['header'][key]['name'] %> </th>
                          <td class="text-center" data-excelalignh="center" <%= data_formatNum_value %>><%= format(tableExArr[key]['val']['val'], formatNum) %></td>
                      </tr>
                  <% } %>
              </tbody>
          </table>




          <table class="excel-bottom-indent text-center" <%= styleTable %>>
              <caption <%= styleCaption %>>Использование коробки отбора мощности</caption>
              <%
                  ex_detailColumns = ['ptoCnt', 'ptoTime', 'ptoEngineRpm800_1000Time', 'ptoEngineRpm800_1000Percent']; //столбцы
                  ex_detailValues = ['val']; //строки
              %>
              <thead>
                  <tr>
                      <% for(let j = 0; j < ex_detailColumns.length; j++) {
                          let key = ex_detailColumns[j]; %>
                          <th data-excelcolspan="2" data-minheight="30" data-excelalignh="center" data-excelalignv="middle"><%= tableExArr['header'][key]['name'] %></th>
                      <% } %>
                  </tr>
              </thead>
              <tbody>
                  <% for(let j = 0; j < ex_detailValues.length; j++) {
                      let keyVal = ex_detailValues[j]; %>

                      <tr>
                          <% for(let j = 0; j < ex_detailColumns.length; j++) {
                              let key = ex_detailColumns[j];
                              let formatNum = tableExArr[key][keyVal]['format'];
                              data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, tableExArr[key][keyVal]['val']);
                              %>

                              <td data-excelcolspan="2" data-excelalignh="center" <%=data_formatNum_value%>> <%= format(tableExArr[key][keyVal]['val'], formatNum) %> </td>

                          <% } %>
                      </tr>

                  <% } %>
              </tbody>
          </table>

          <table class="excel-bottom-indent" <%= styleTable %>>
              <caption <%= styleCaption %> class="font-weight-bold">Неэффективная эксплуатация ТС</caption>
              <%
                  ex_detailColumns = ['rpm1900_2200Time', /*'swithClutchNoSpdCnt',*/ 'swithClutchNoSpdCountTime', /*'rpmNo800_1000less1200Cnt'*/ 'clutchSlipExcessiveRpmCnt',
                      'clutchUseMoveStartExcessiveWorkSumm',
                      'clutchSlipVehicleSlipTime', 'clutchSlipVehicleSlipRpmCnt', 'clutchSlipVehicleSlipWork', 'clutchSlipVehicleSlipLowRpmCnt', 'clutchSlipVehicleSlipLowWork',
                      'clutchUseMoveStartExcessiveWorkPercent', 'clutchSlipVehicleSlipWorkPercent', 'rpmNo800_1000less1200Time', /*'rpmSpdLess440Cnt', 'rpmStartSpdMore1200Cnt', 'accelSharplyCnt',
                      'startSpdBadRoadNoLockCnt', */'swithGearBoxWrongCnt', 'swithGearBoxWrongPercent', /*'accelSharplyViolationCnt',*/'swithClutchInsufficientCnt', 'kickdownCnt'
                  ]; //столбцы
                  // ex_detailValues = ['val']; //строки
              %>
              <% for(let j = 0; j < ex_detailColumns.length; j++) {
                  let key = ex_detailColumns[j];
                  data_formatNum_value = addFormatAndValAttributesFromLodash_helper(tableExArr[key]['val']['format'], tableExArr[key]['val']['val']);
                  let minHeight = tableExArr['header'][key]['name'].length > 86 ? 'data-minheight="30"' : '';
                  %>
                  <tr>
                      <th class="text-left" data-excelalignv="middle" data-excelcolspan="6" <%= minHeight %>> <%= tableExArr['header'][key]['name'] %> </th>
                      <td data-excelalignh="center" class="text-center" data-excelalignv="middle" <%= data_formatNum_value %>><%= format(tableExArr[key]['val']['val'], tableExArr[key]['val']['format']) %></td>
                  </tr>
              <% } %>
          </table>


          <table <%= styleTable %>>
              <caption <%= styleCaption %> class="font-weight-bold">Нарушения при эксплуатации ТС</caption>
              <%
                  ex_detailColumns = ['rpmMore2200Time', 'rpmMore1200PtoTime', /*'engineStartColdCnt', 'engineStalledspdCnt',*/
                      'clutchSlipBadRpmCnt', 'clutchSlipCriticalCnt', 'clutchSlipCriticalTime',
                      'startSpdGearBoxMore2Cnt', /*'swithGearBoxRewersSpdCnt', 'swithLockSpdCnt',
                      'swithLockAccelCnt', */'loweringSpdMore25Distance', 'loweringSpdMore25Time', 'crosswheelSpdMore7Distance',
                      'crosswheelSpdMore7Time', 'ptoSpdDistance', 'ptoSpdTime',
                      'handleBreakMoveCount', 'handleBreakMoveTime', 'lowOilLampCount', 'lowOilLampTime',
                      'hightCoolTempLampCount', 'hightCoolTempLampTime' /*, 'ptoActivateKrenCnt'*/
                  ]; //столбцы
                  // ex_detailValues = ['val']; //строки
              %>
              <% for(let j = 0; j < ex_detailColumns.length; j++) {
                  let key = ex_detailColumns[j];
                  data_formatNum_value = addFormatAndValAttributesFromLodash_helper(tableExArr[key]['val']['format'], tableExArr[key]['val']['val']);
                  %>
                  <tr>
                      <th class="text-left" data-excelcolspan="6"> <%= tableExArr['header'][key]['name'] %> </th>
                      <td data-excelalignh="center" class="text-center" <%= data_formatNum_value %>><%= format(tableExArr[key]['val']['val'], tableExArr[key]['val']['format']) %></td>
                  </tr>
              <% } %>
          </table>

      <% } %>

    </div>
`;
