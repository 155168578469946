import { addCopyObj_helper, getDateByTZH } from '@/helpers/main_helper';

import type { IPeriod } from './Smenas';
import type { IPosition } from '@/types/IPosition';
import type { IAggregationPosition } from '@/types/IAggregationPosition';

export type TSettingOfValues = {
  [key: string | number]: [number | false, boolean] | TSettingOfValues;
};

export type TCalculatedAggregationPosition = {
  [key: keyof IAggregationPosition]: number;
};
type TObject = { [key: string]: any };

const SERVER_TZH = 5;

export const checkIsSummaryDayByPos = (position: unknown): boolean => {
  if (typeof position !== 'object' || position === null) {
    throw new Error('position must be object');
  }

  if ('seconds_of_the_day' in position && !!position.seconds_of_the_day) {
    return position.seconds_of_the_day == 86400;
  }
  if ('sotd' in position && !!position.sotd) {
    return position.sotd == 86400;
  }
  if ('is_last_day_pos' in position) {
    return !!position.is_last_day_pos;
  }

  if ('time' in position) {
    const serverDate = getDateByTZH(
      new Date(Number(position.time)),
      SERVER_TZH,
    );

    return (
      serverDate.getHours() == 0 &&
      serverDate.getMinutes() == 0 &&
      serverDate.getSeconds() == 0
    );
  }

  throw new Error(
    'in the position must be property seconds_of_the_day or is_last_day_pos or time',
  );
};

export function aggregationCalculating(
  posArr: any[],
  settingOfValues: TSettingOfValues,
  Periods: IPeriod[],
  ReportBegin: number,
  ReportEnd: number,
) {
  const poscount = posArr.length;
  /* получим конфигурацию*/
  let beginValues = {}; // начальное значение (первая позиция в периоде)
  let sumOfAmountsValues = {}; // сумма суммарных значений (при переходе суток сброс значений)
  let curTotalValues: TObject = {}; // текущее значение с учетом ежесуточных сбросов
  let curTotalValuesPrev = {}; // предыдущее значение с учетом ежесуточных сбросов

  const auxiliary = {
    hasBeenBeginPos: false,
    isSummaryDay: false,
    periodNum: 0,
  };

  let w_pos: TCalculatedAggregationPosition = {};
  let isLastPos = false;
  let posIntoPeriod = false;
  const maxSpeeds: { [key: string]: unknown } = {};

  for (let j = 0; j < poscount; j++) {
    w_pos = getPosValues(posArr[j], settingOfValues);

    auxiliary.isSummaryDay = checkIsSummaryDayByPos(w_pos);

    isLastPos = Boolean(j == poscount - 1);

    posIntoPeriod = Boolean(
      w_pos['time'] >= ReportBegin && w_pos['time'] <= ReportEnd,
    );

    if (w_pos['max_speed']) {
      for (const [maxSpeedTime, maxSpeedValue] of Object.entries(
        w_pos['max_speed'],
      )) {
        maxSpeeds[maxSpeedTime] = maxSpeedValue;
      }
    }

    if (auxiliary.isSummaryDay && auxiliary.hasBeenBeginPos && posIntoPeriod) {
      // не вычиисляемые будут как первый аргумент
      sumOfAmountsValues = mathArray(
        w_pos,
        sumOfAmountsValues,
        '+',
        settingOfValues,
      );
    }

    if (!auxiliary.hasBeenBeginPos && posIntoPeriod) {
      if (!auxiliary.isSummaryDay) {
        beginValues = addCopyObj_helper(w_pos);
        // sumOfAmountsValues = addCopyObj_helper(w_pos);
        sumOfAmountsValues = mathArray(w_pos, w_pos, '-', settingOfValues);
      }
      auxiliary.hasBeenBeginPos = true;
    }

    if (auxiliary.isSummaryDay) {
      if ('time' in sumOfAmountsValues) {
        // не вычиисляемые будут как первый аргумент
        curTotalValues = mathArray(
          sumOfAmountsValues,
          beginValues,
          '-',
          settingOfValues,
        );
      } else {
        // it is first position into period begins from summary values
        curTotalValues = mathArray(w_pos, w_pos, '-', settingOfValues);
      }
    } else {
      curTotalValues = mathArray(
        sumOfAmountsValues,
        beginValues,
        '-',
        settingOfValues,
      );
      curTotalValues = mathArray(w_pos, curTotalValues, '+', settingOfValues);
    }

    curTotalValues['max_speed'] = maxSpeeds;

    if (!j) {
      curTotalValuesPrev = addCopyObj_helper(curTotalValues);
    }

    // нужно разложить по сменам
    periodsCalculate(
      isLastPos,
      settingOfValues,
      auxiliary,
      Periods as IPeriod[],
      curTotalValues,
      curTotalValuesPrev,
    );

    curTotalValuesPrev = addCopyObj_helper(curTotalValues);
  }
}

export function periodsCalculate(
  isLastPos: boolean,
  settingColumns: { [key: string]: any },
  auxiliary: { [key: string]: any },
  Periods: IPeriod[],
  curTotalValues: TObject,
  curTotalValuesPrev: TObject,
) {
  for (let p = auxiliary.periodNum; p < Periods.length; p++) {
    const period = Periods[p];

    let isBeginHere = false;
    if (
      curTotalValues['time'] >= Number(period['TimeBegin']) &&
      !period['isWebOpened']
    ) {
      // смена началась

      period['webBegin'] = addCopyObj_helper(curTotalValues);
      period['isWebOpened'] = true;
      isBeginHere = true;
    }

    const { isWebOpened, isWebClosed, TimeEnd } = period;
    const curTotalTimeLargeOrEqualPeriodTimeEnd =
      curTotalValues['time'] >= Number(TimeEnd);

    if (
      isWebOpened &&
      ((curTotalTimeLargeOrEqualPeriodTimeEnd && !isWebClosed) || isLastPos)
    ) {
      // смена завершилась

      if (curTotalValues['time'] > Number(TimeEnd) && !isBeginHere) {
        period['webEnd'] = addCopyObj_helper(curTotalValuesPrev);
      } else {
        period['webEnd'] = addCopyObj_helper(curTotalValues);
      }

      period['isWebClosed'] = true;

      auxiliary.periodNum = p + 1;

      period['webSumm'] = periodsCalculate_summ(
        period,
        settingColumns,
        period['webEnd'],
        period['webBegin'],
      );
    }

    if (isWebOpened) {
      const detail = periodsCalculate_summ(
        period,
        settingColumns,
        curTotalValues,
        curTotalValuesPrev,
      );

      period['webDetail'].push(detail);
    }

    if (Number(period['TimeBegin']) > curTotalValues['time']) {
      // эта, а значит и послежующие смены лежат дальше по времени (в будущем), чем текущее время в строке
      break;
    }
  }
}

export function periodsCalculate_summ(
  period: IPeriod,
  arrKeyNoSumSetting: TSettingOfValues,
  curValues: TObject,
  prevValues: TObject,
) {
  const summ: IPeriod['webSumm'] = {};

  for (const key in curValues) {
    if (key === 'max_speed') {
      const { max_speed, max_speed_time } = getMaxSpeed(period, curValues[key]);

      summ['max_speed'] = max_speed;
      summ['max_speed_time'] = max_speed_time;

      continue;
    }

    if (!(key in prevValues)) {
      if (curValues[key] == null) {
        summ[key] = null;
        continue;
      }

      summ[key] = 0;
      continue;
    }

    if (
      arrKeyNoSumSetting[key] &&
      arrKeyNoSumSetting[key].toString() === '[object Object]'
    ) {
      summ[key] ??= {};

      summ[key] = periodsCalculate_summ(
        period,
        arrKeyNoSumSetting[key] as TSettingOfValues,
        curValues[key],
        prevValues[key],
      );

      continue;
    }

    if (
      (key in arrKeyNoSumSetting && !arrKeyNoSumSetting[key][1]) ||
      (curValues[key] == null && prevValues[key] == null)
    ) {
      /* эти значения не вычисляются*/
      summ[key] = curValues[key];
      continue;
    }

    summ[key] = curValues[key] - prevValues[key];
  }

  return summ;
}

export function mathArray(
  objOne: { [key: string]: any },
  objTwo: { [key: string]: any },
  symbol: string,
  settingOfValues: TSettingOfValues,
  ignoreTime?: boolean,
) {
  const mathArr: { [key: string]: any } = {};

  if (!ignoreTime) {
    if (!('time' in objOne)) {
      return objTwo;
    }
    if (!('time' in objTwo)) {
      return objOne;
    }
  }

  for (const key in objOne) {
    if (objOne[key] == null && objTwo[key] == null) {
      mathArr[key] = null;
      continue;
    }

    if (key === 'max_speed_time') {
      continue;
    }

    if (key === 'max_speed' && typeof objOne[key] === 'number') {
      // если это уже элементарные значения, то ищем максимальное
      if (objOne[key] > objTwo[key]) {
        mathArr[key] = objOne[key];
        mathArr['max_speed_time'] = objOne['max_speed_time'];
      } else {
        mathArr[key] = objTwo[key];
        mathArr['max_speed_time'] = objTwo['max_speed_time'];
      }

      continue;
    }

    if (
      settingOfValues[key] &&
      settingOfValues[key].toString() === '[object Object]'
    ) {
      objOne[key] ??= {};
      objTwo[key] ??= {};

      for (const k in settingOfValues[key]) {
        if (settingOfValues[key][k].toString() === '[object Object]') {
          objOne[key][k] ??= {};
          objTwo[key][k] ??= {};
        } else {
          objOne[key][k] ??= 0;
          objTwo[key][k] ??= 0;
        }
      }

      mathArr[key] = mathArray(
        objOne[key],
        objTwo[key],
        symbol,
        settingOfValues[key] as TSettingOfValues,
        true,
      );

      continue;
    }

    if (key in settingOfValues && !settingOfValues[key][1]) {
      /* эти значения не вычисляются*/
      mathArr[key] = objOne[key];
      continue;
    }

    if (symbol == '+') {
      mathArr[key] = objOne[key] + objTwo[key];
    }
    if (symbol == '-') {
      mathArr[key] = objOne[key] - objTwo[key];
    }
  }
  return mathArr;
}

export function getPosValues<PositionType extends { [key: string]: any }>(
  position: PositionType,
  settingOfValues: TSettingOfValues,
) {
  const obj: { [key: string]: any } = {};

  for (const key in position) {
    if (
      key in settingOfValues &&
      settingOfValues[key].toString() === '[object Object]'
    ) {
      if (position[key] === null) {
        obj[key] = {};
        continue;
      }

      const objFromJson =
        typeof position[key] === 'string'
          ? JSON.parse(position[key])
          : position[key];

      obj[key] = getPosValues(
        objFromJson,
        settingOfValues[key] as TSettingOfValues,
      );
      continue;
    }

    if (position[key] == null) {
      obj[key] = position[key];
      continue;
    }

    if (key === 'max_speed_time') {
      continue;
    }

    if (key === 'max_speed') {
      const curTime = parseInt(position['time'] || '');
      let tempArr = []
      if (position[key] !== 0) {
        tempArr = position[key].split(';');
      }
      const maxSpeeds: { [key: string]: any } = {};

      tempArr.forEach((temp: any) => {
        if (!temp) {
          return;
        }

        const [sSpeed, timeShift] = temp.split('-');
        const speed = parseInt(sSpeed);
        const time = curTime - parseInt(timeShift);

        maxSpeeds[time] = speed;
      });

      obj[key] = maxSpeeds;

      continue;
    }

    let value = position[key] as any;

    if (key == 'level_out_1') {
      value = Math.abs(Number(value));
    }

    obj[key] = value;

    if (obj[key] && settingOfValues[key]) {
      if (settingOfValues[key][0] === false) {
        continue;
      }
      obj[key] /= settingOfValues[key][0] as number;
    }
  }

  return obj;
}

export function getMaxSpeed(period: IPeriod, maxSpeeds: TObject) {
  const { TimeBegin: smenaBegin, TimeEnd: smenaEnd } = period;

  const smenaBeginValue = smenaBegin ? smenaBegin.valueOf() / 1000 : 0;
  const smenaEndValue = smenaEnd ? smenaEnd.valueOf() / 1000 : 0;

  let max_speed = 0;
  let max_speed_time = 0;

  for (const [maxSpeedTimeText, maxSpeedValue] of Object.entries(maxSpeeds)) {
    const maxSpeedTime = parseInt(maxSpeedTimeText);
    if (
      Number(maxSpeedValue) > max_speed &&
      maxSpeedTime > smenaBeginValue &&
      smenaEndValue >= maxSpeedTime
    ) {
      max_speed = Number(maxSpeedValue);
      max_speed_time = maxSpeedTime;
    }
  }

  return { max_speed, max_speed_time };
}
