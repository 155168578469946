import { computed, ref } from 'vue';

import { useRequest } from '@/ManualApp/use/request';

import { concatUniqueObjectKeys, wait } from '@/helpers/main_helper';

import type { Ref } from 'vue';
import type { IFuelSetting } from './IFuelSetting';

const { send } = useRequest();

const DELAY_ON_SECONDS_FOR_REFETCH_FUEL_SETTINGS = 10 * 60;

const DELAY_ON_SECONDS_FOR_FAIL_REFETCH_FUEL_SETTINGS = 15;

const isClear: Ref<boolean> = ref(false);

const fuelSettings: Ref<IFuelSetting[]> = ref([]);

const isConnection: Ref<boolean> = ref(true);
const nextConnection: Ref<number> = ref(0);

const fuelSettingsUpdatedTrigger: Ref<number> = ref(0);

const isFirstRequestSended: Ref<boolean> = ref(true);

function getSettingById(id: number | string) {
  return fuelSettings.value.find((obj: IFuelSetting) => obj.id == id);
}

async function runUpdating() {
  isClear.value = false;

  while (true) {
    if (isClear.value) break;

    try {
      const data = await send('/Api/getFuelSettings');

      if (isFirstRequestSended.value) {
        fuelSettings.value = data;
        isFirstRequestSended.value = false;
      } else {
        await updateSettingsList(data);
      }

      fuelSettingsUpdatedTrigger.value = Date.now();

      isConnection.value = true;

      await wait(DELAY_ON_SECONDS_FOR_REFETCH_FUEL_SETTINGS);
    } catch (error: any) {
      console.error(error.message);

      isFirstRequestSended.value = false;
      isConnection.value = false;
      nextConnection.value =
        DELAY_ON_SECONDS_FOR_FAIL_REFETCH_FUEL_SETTINGS * 1000 + Date.now();

      await wait(DELAY_ON_SECONDS_FOR_FAIL_REFETCH_FUEL_SETTINGS);
    }
  }
}

async function updateSettingsList(newSettingsList: IFuelSetting[]) {
  try {
    for (let i = 0; i < newSettingsList.length; i++) {
      const { id } = newSettingsList[i];

      const object = getSettingById(id);

      if (!object) {
        fuelSettings.value.push(newSettingsList[i]);
      } else {
        updateSettingObject(object, newSettingsList[i]);
      }

      if (i % 10 === 0) await wait(0.01);
    }
  } catch (error) {
    console.error('Error on update store fuel settings', error);
  }
}

function updateSettingObject(oldObject: IFuelSetting, newObject: IFuelSetting) {
  try {
    const keys = concatUniqueObjectKeys(oldObject, newObject);

    for (const key of keys) {
      if (oldObject[key] != newObject[key]) {
        oldObject[key] = newObject[key];
      }
    }
  } catch (error) {
    console.error('Error on update store object', error);
  }
}

export const fuelSettingsStore = {
  getSettingById,
  runUpdating,
};
