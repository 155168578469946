import { encodeMessage } from '@/App/use/encrypter';
import { ref, watch } from 'vue';

const token = ref({
  token: '',
  timeTo: 0,
  isSend: false,
  isLogout: false,
  openLogoutWindowHandler: () => {},
});

export const accessToken = token;

watch(accessToken.value, (obj) => {
  const encodedToken = encodeMessage(obj.token);
  localStorage.setItem('access_token', encodedToken);
});

export const jwtSender = async (url, params) => {
  const maxRequest = 3;
  let n = 0;
  while (n < maxRequest) {
    n++;
    try {
      const oldToken = accessToken.value.token;
      if (!accessToken.value.isSend) {
        const options = setOptions(params);

        const res = await fetch(url, options);

        const response = res.clone();

        if (response.status === 401) {
          const checkAuthRes = await fetchCheckAuth(oldToken);

          if (checkAuthRes['isRetryRequest']) continue;

          await retryAuthHandler();
          continue;
        }

        if (response.status === 403) {
          return response;
        }

        if (response.status === 404) {
          return response;
        }

        if (response.status === 405) {
          return response;
        }

        if (response.status === 500) {
          continue;
        }

        const json = await response.json();

        if (!response.status >= 200 && !response.status < 300) {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }

        if (isRedirectInJson(json, 'Api/auth/check')) {
          const checkAuthRes = await fetchCheckAuth(oldToken);

          if (checkAuthRes['isRetryRequest']) continue;

          await retryAuthHandler();
          continue;
        }

        if (isRedirectInJson(json, 'Api/auth')) {
          await retryAuthHandler();
          continue;
        }

        return res;
      }

      const checkAuthRes = await fetchCheckAuth(oldToken);

      if (checkAuthRes['isRetryRequest']) continue;

      await retryAuthHandler();
      continue;
    } catch (e) {
      if (e.message.indexOf('401 (Unauthorized)') > -1) {
        const checkAuthRes = await fetchCheckAuth(oldToken);

        if (checkAuthRes['isRetryRequest']) continue;

        await retryAuthHandler();
        continue;
      }
      throw e;
    }
  }
};

const fetchCheckAuth = async (oldToken) => {
  const result = {
    isRetryRequest: false,
  };

  let checkAuthRes = null;

  const maxRequest = 100;
  let n = 0;
  while (n < maxRequest) {
    n++;
    if (accessToken.value.isSend) {
      // ждем так как запрос на обновление access отправлен другим запросом
      await new Promise((res, rej) => setTimeout(res, 500));
      continue;
    }

    // если access токен совпадает с тем который был отправлен изначально, то отправляем запрос на обновление
    if (oldToken === accessToken.value.token) {
      accessToken.value.isSend = true;

      checkAuthRes = await fetch(
        `${
          process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
        }Api/auth/check`,
        {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken.value.token}`,
          },
        },
      );
    }

    break;
  }

  if (!checkAuthRes) {
    result['isRetryRequest'] = true;
    return result;
  }

  const checkAuthJson = await checkAuthRes.json();

  if (!checkAuthJson['success']) {
    console.error(checkAuthJson['prevError']);

    if (checkAuthJson['redirectUrl'] !== 'Api/auth') {
      result['isRetryRequest'] = true;
      return result;
    }

    return result;
  }

  accessToken.value.token = checkAuthJson.accessToken;
  accessToken.value.isSend = false;

  result['isRetryRequest'] = true;
  return result;
};

const isRedirectInJson = (json, str) => {
  return json['redirectUrl'] && json['redirectUrl'] === str;
};

const retryAuthHandler = async () => {
  accessToken.value.isLogout = true;
  accessToken.value.openLogoutWindowHandler();

  // const maxCheck = 15 * 60; // 15 минут в unix

  let bool = true;
  while (bool) {
    if (
      accessToken.value.isLogout
      //  && maxCheck !== 0
    ) {
      await new Promise((res) => setTimeout(res, 1000));
      continue;
    }

    // if (accessToken.value.isLogout) window.location.reload();
    break;
  }

  accessToken.value.isSend = false;
  return;
};

const setOptions = (params = {}) => {
  const paramsKeys = Object.keys(params);

  const options = paramsKeys.reduce((acc, key) => {
    acc[key] = params[key];
    return acc;
  }, {});

  options['credentials'] = 'include';

  if (!options['headers']) options['headers'] = {};

  if (options.body && !(options.body instanceof FormData)) {
    options['headers']['Content-Type'] = 'application/json';
  }

  options['headers']['Authorization'] = `Bearer ${accessToken.value.token}`;

  return options;
};
