<template>
  <ui-popup>
    <ui-close-button @close="emit('close')" />
    <div class="confirm">
      <h3 class="mb-2">Подтвердите удаление</h3>
      <p class="mb-1">Введите: да</p>
      <p class="mb-3">Либо зажмите клавишу Shift</p>

      <div class="mb-2">
        <span
          v-if="messages.success"
          class="text-success"
          >{{ messages.success }}</span
        >
        <span
          v-else-if="messages.error"
          class="text-error"
          >{{ messages.error }}</span
        >
      </div>

      <ui-field v-model:value="form.field.value" />
      <ui-button
        class="mt-2"
        :disabled="!form.valid && !shiftPressed"
        @click="confirmDelete"
      >
        Подтвердить
      </ui-button>
    </div>
  </ui-popup>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

import { useForm } from '@/ManualApp/use/form';
import { useValidator } from '@/ManualApp/use/validator';
import UiCloseButton from '../Buttons/UiCloseButton.vue';

import UiButton from '../Buttons/UiButton.vue';
import UiField from '../Fields/UiField.vue';
import UiPopup from '../Popups/UiPopup.vue';

const props = defineProps({
  handler: { type: [Function, Boolean], default: () => {} },
});

const emit = defineEmits(['confirm', 'close']);

const { required } = useValidator;

const confirm = (val) => val.toLowerCase() === 'да';

const messages = ref({ success: '', error: '' });
const shiftPressed = ref(false);

const form = useForm({
  field: {
    value: '',
    validators: { required, confirm },
  },
});

const confirmDelete = async (e) => {
  if (!e.shiftKey) {
    if (!form.valid) return;
  }
  if (!props.handler) return;
  messages.value.success = 'Запрос отправлен';
  await props.handler();
  messages.value.success = '';
};

const onShiftPressed = (e) => {
  shiftPressed.value = e.key == 'Shift';
};

const offShiftPressed = (e) => {
  shiftPressed.value = false;
};

onMounted(() => {
  document.addEventListener('keydown', onShiftPressed);
  document.addEventListener('keyup', offShiftPressed);
});
onBeforeUnmount(() => {
  document.removeEventListener('keydown', onShiftPressed);
  document.removeEventListener('keyup', offShiftPressed);
});
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/default.scss';
.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
