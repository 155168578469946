<template>
  <div>
    <table
      border="1"
      cellpadding="5"
      style="
        border-collapse: collapse;
        border: 1px solid black;
        caption-side: top;
      "
    >
      <thead>
        <tr class="text-center">
          <th
            v-for="column in headColumns"
            data-excelalignv="middle"
            data-excelalignh="center"
            :data-excelcolspan="props.header[column]['colspan']"
            :data-minheight="[
              props.header[column]['name'].length > 20 ? 30 : '',
            ]"
          >
            {{ props.header[column]['name'] }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(j, i) in props.arrayForDisplay">
          <tr v-for="(jj, ii) in j">
            <template v-for="(column, k) in headColumns">
              <template v-if="ii > 0">
                <td
                  :class="[
                    {
                      'text-center': k > 1,
                    },
                    {
                      'text-danger':
                        props.data[jj]['DCount'] && props.data[jj]['IsRoughly'],
                    },
                  ]"
                  :data-minheight="[
                    props.data[jj][column]?.length > 61 ? 30 : '',
                  ]"
                  :data-excelalignh="isCenterExcelAlignH(k)"
                  :data-excelcolspan="props.header[column]['colspan']"
                >
                  <span>
                    {{
                      props.format(
                        props.data[jj][column],
                        props.header[column]['format'],
                      )
                    }}
                  </span>
                </td>
              </template>
              <template v-else>
                <td
                  v-if="k === 0"
                  colspan="3"
                  class="font-weight-bold"
                  :data-excelcolspansumm="props.header[column]['colspan'] + 4"
                >
                  {{ props.data[jj][column] }}
                </td>
                <td
                  v-else-if="k < 1"
                  class="font-weight-bold"
                  :data-excelcolspan="props.header[column]['colspan']"
                >
                  {{ props.data[jj][column] }}
                </td>
              </template>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const props = defineProps({
  header: {
    type: Object,
    required: true,
  },
  arrayForDisplay: {
    type: Array,
    required: true,
  },
  format: {
    type: Function,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['showDetailsViolationPopup']);

const headColumns = [
  'DNum',
  'DSkill',
  /*'DType', */ 'DForfeits',
  'DForfeitsRelative',
];

const isCenterExcelAlignH = (k) => (k > 1 ? 'center' : '');
</script>

<style lang="scss" scoped></style>
