import { OurAggregated } from '../../src/dataRequest/ourAggregated.js';
import { skillsManTemplate } from '../skills_man_template/skills_man_template.js';

// импорт всех графических компонент
import { AverageIndicator } from './AverageIndicator.js';
import { ViolationsIndicator } from './ViolationsIndicator.js';
import { ProductionsIndicator } from './ProductionsIndicator.js';

// переменная для инициализация графиков - пришлось поднять выше, стрелочная ф-ия не имеет this вообще, а должна ссылаться на класс
const graphComponents = {};
const roundTo = 10; // округление по 10 минут

export class GraphicsManager {
  that;
  settingsForm;
  settingFormSubelements;
  sidebar;
  pointers;
  area;
  button;
  objectsList;
  errorShow =
    'На странице произошла какая-то ошибка. Попробуйте повторить запрос или перезагрузить страницу';

  sidebarEvent = (event) => {
    const componentPointer = event.target.closest('[data-component]');

    if (!componentPointer || componentPointer.classList.contains('active')) {
      return;
    }

    // почему-то this в этой стрелочной ф-ии это целевой элемент, а не экземпляр класса, видимо требуется настройка webpack
    this.that.pointers.forEach((element) => element.classList.remove('active'));

    this.that.areaClear();

    componentPointer.classList.add('active');

    this.that.callGraphicComponent(
      componentPointer.dataset.component,
      'show',
      this.that.area,
    );
  };

  buttonEvent = async (event) => {
    const buttonElement = event.target;

    // получить параметры запроса и передать их нужному компоненту
    const pointer = this.that.pointers.find((item) =>
      item.classList.contains('active'),
    );

    if (!pointer) {
      alert(this.that.errorShow);
      return;
    }

    const componentName = pointer.dataset.component;

    if (!componentName) {
      alert(this.that.errorShow);
      return;
    }

    // alert(componentName);

    const targetElement = getNeighborByClass_helper(
      buttonElement,
      'target-show-span',
    );
    infoShowText_helper(targetElement, 'Отправляю запрос.');
    setElementColor_helper(buttonElement, 'blue');
    // this.that.areaClear();


    const beginTime = roundStringTime_helper(
      this.that.settingsForm['beginTime'].value,
      roundTo,
    );
    const endTime = roundStringTime_helper(
      this.that.settingsForm['endTime'].value,
      roundTo,
    );
    const isAllObjects =
      this.that.settingsForm['objectsOption'].selectedOptions[0].value ===
      'all';

    const dataSend = {
      time_begin: getTimeFromString_helper(beginTime).datetime / 1000,
      time_end: getTimeFromString_helper(endTime).datetime / 1000,
      option: 2,
    };
    const objectsList = this.globalObjectsChecked.value.map(id => this.globalObjectsList[Number(id)])

    const objectsDisableList = Object.values(this.globalObjectsList).filter(item => !this.globalObjectsChecked.value.includes(item.id))

    if (!objectsList.length) {
      this.that.callGraphicComponent(
        componentName,
        'writeInfo',
        'Не выбран ни один объект.',
      );
      infoShowText_helper(targetElement, 'Не выбран ни один объект.');
      setElementColor_helper(buttonElement, 'orange');
      return;
    }

    const objIdArr = objectsList.reduce((accum, obj) => {
      accum.push(obj.id);
      return accum;
    }, []);

    const dataArr = { objIdArr };

    // запрос позиций и подсчет значений по отчету
    const ourAggregated = new OurAggregated(skillsManTemplate);

    const templateName = 'getCalculated';

    this.that.callGraphicComponent(
      componentName,
      'writeInfo',
      'Запрос отправлен.',
    );

    const { smenasSeconds, smenasOrigin } = this.getSmenasSetting();

    // добавим смены в запрос
    for (let s in smenasSeconds) {
      const nSmena = +s + 1;
      dataSend['smena_' + nSmena] = smenasSeconds[s];
    }

    ourAggregated.getAggregated({
      dataArr,
      dataSend,
      targetElement,
      buttonElement,
      templateName,
      callback: this.that.calculated,
      // areaId: 'section-graphics-content',
      params: {
        componentName,
        objectsList,
        objectsDisableList,
        targetElement,
        buttonElement,
      },
      isGetSmenas: true,
      smenasOrigin,
    });

    // дальнейшие действия происходят внутри getAggregated - вызывается callback после получения и обсчета данных
  };

  smenasButtonEvent = () => {
    this.settingFormSubelements.smenasSettingWrap.append(
      this.settingFormSubelements.settings,
    );
    this.that.settingFormSubelements.smenasSetting.style.display = 'block';
  };

  settingEventListener = (event) => {
    const { target } = event;

    const isClose = Boolean(
      target.closest('.background-wrap') || target.closest('.close'),
    );

    if (isClose) {
      this.settingFormSubelements.smenasSetting.style.display = 'none';
      this.settingFormSubelements.smenasButtonTarget.append(
        this.settingFormSubelements.settings,
      );
    }
  };

  constructor(globalObjectsList, globalObjectsChecked) {
    this.init();
    this.globalObjectsList = globalObjectsList;
    this.globalObjectsChecked = globalObjectsChecked;
  }

  async init() {
    this.settingsForm = document
      .getElementById('section-graphics-settings')
      .querySelector('form');
    this.settingFormSubelements = this.getSubElements(this.settingsForm);
    this.sidebar = document.getElementById('section-graphics-sidebar');

    const pointers = [...this.sidebar.children];
    this.pointers = pointers;

    this.area = document.getElementById('section-graphics-content');
    this.button = document.getElementById('section-graphics-button');
    this.objectsList = document.getElementById('objectsListId');
    this.that = this;

    this.settingFormSubelements.smenasButtonTarget.append(
      this.settingFormSubelements.settings,
    );

    // инициализация графиков
    graphComponents.averageIndicator = new AverageIndicator(
      'averageIndicator',
      this.area,
      pointers,
    );
    graphComponents.averageIndicatorCopy = new AverageIndicator(
      'averageIndicatorCopy',
      this.area,
      pointers,
    );
    graphComponents.violationsIndicator = new ViolationsIndicator(
      'violationsIndicator',
      this.area,
      pointers,
    );
    graphComponents.violationsIndicator2 = new ViolationsIndicator(
      'violationsIndicator2',
      this.area,
      pointers,
    );
    graphComponents.productionsIndicator = new ProductionsIndicator(
      'productionsIndicator',
      this.area,
      pointers,
    );
    graphComponents.productionsIndicator2 = new ProductionsIndicator(
      'productionsIndicator2',
      this.area,
      pointers,
    );

    this.addEventListeners();
  }

  getSmenasSetting() {
    const { settingFormSubelements } = this;

    const smenasFlag = Boolean(
      settingFormSubelements['smenasTemplateSmenasFlag'].checked,
    );

    const smenasSeconds = [];
    const smenasOrigin = [];

    if (!smenasFlag) {
      return {
        smenasSeconds,
        smenasOrigin,
      };
    }

    for (let s = 1; s < 4; s++) {
      const sSetting = {};
      const smenaName = `smenasTemplate-s${s}`;

      if (!settingFormSubelements[smenaName].checked) {
        break;
      }

      const sBeginHours = settingFormSubelements[`${smenaName}-bh`].value;
      const sBeginMinutes = settingFormSubelements[`${smenaName}-bm`].value;
      const sEndHours = settingFormSubelements[`${smenaName}-eh`].value;
      const sEndMinutes = settingFormSubelements[`${smenaName}-em`].value;

      sSetting['begin'] = this.getSmenasSetting_pice(
        sBeginHours,
        sBeginMinutes,
        roundTo,
      );
      sSetting['end'] = this.getSmenasSetting_pice(
        sEndHours,
        sEndMinutes,
        roundTo,
      );
      smenasOrigin.push(sSetting);

      for (let key in sSetting) {
        if (!smenasFlag) {
          break;
        }

        const secondsOfTheDay = this.getSmenas_getTime(sSetting[key]) / 1000;
        if (
          secondsOfTheDay > 0 &&
          secondsOfTheDay != 86400 &&
          !smenasSeconds.includes(secondsOfTheDay)
        ) {
          smenasSeconds.push(secondsOfTheDay);
        }
      }

      // sSetting = {};
    }

    return {
      smenasSeconds,
      smenasOrigin,
    };
  }

  getSmenas_getTime(timeStroke) {
    // seconds from begin day (secons of a day)
    return (
      (Number(timeStroke.split(':')[0]) * 60 +
        Number(timeStroke.split(':')[1])) *
      60000
    ); // в милисекундах
  }

  getSmenasSetting_pice(hours, minutes, roundTo) {
    roundTo = roundTo || 10;

    if (!hours && !minutes) {
      return '00:00';
    }

    if (+hours > 23 || +hours < 0) hours = '00';
    if (!minutes) {
      return hours + ':00';
    }

    if (+minutes > 60 || +minutes < 0) {
      return hours + ':' + '00';
    }

    const deciMinute = minutes.charAt(0) || '0';

    if (roundTo == 10) {
      // минута всегда будет нулевой
      return hours + ':' + deciMinute + '0'; // минуты по 10 минут допускаются
    }

    const minute = minutes.charAt(1) || '0';

    // roundTo = 5
    if (+minute < 5) {
      return hours + ':' + deciMinute + '0'; // минуты по 5 минут допускаются, но тут округление
    }
    return hours + ':' + deciMinute + '5'; // минуты по 5 минут допускаются
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  addEventListeners() {
    this.sidebar.addEventListener('click', {
      handleEvent: this.sidebarEvent,
      that: this,
    });
    this.button.addEventListener('click', {
      handleEvent: this.buttonEvent,
      that: this,
    });
    this.settingFormSubelements.smenasButton.addEventListener('click', {
      handleEvent: this.smenasButtonEvent,
      that: this,
    });
    this.settingFormSubelements.smenasSetting.addEventListener('click', {
      handleEvent: this.settingEventListener,
      that: this,
    });
  }

  callGraphicComponent(name, method, params) {
    graphComponents[name][method](params);
  }

  areaClear() {
    const lastGraphic = this.area.firstElementChild;

    if (lastGraphic) {
      lastGraphic.remove();
    }

    // this.area.innerHTML = 'Отправляю запрос...';
  }

  calculated = (
    objectsCalculated,
    {
      componentName,
      objectsList,
      objectsDisableList,
      targetElement,
      buttonElement,
    } = {},
  ) => {
    infoShowText_helper(targetElement, 'Построить график за указанный период');
    setElementColor_helper(buttonElement, 'green');

    graphComponents[componentName]['draw']({
      area: this.area,
      objectsCalculated,
      objectsList,
      objectsDisableList,
      componentName,
    });

    // this.callGraphicComponent(componentName, 'draw', {area: this.area, objectsCalculated, objectsList});
  };
}
