import { leafletPnpolyAlgoritm } from './leaflet_pnpoly_algoritm.js';
import { getDistanceHelper } from './get_distance_helper.js';

export const leafletCheckInGeofencies = {
  featureGroup: null,
  map: null,
  fillOpacityHovered: 0.4,
  fillOpacity: 0.2,

  initialization({ map, featureGroup } = {}) {
    if (!map || !featureGroup) {
      console.error('initialization invalid arguments: map or featureGroup');
      return;
    }
    this.map = map;
    this.featureGroup = featureGroup;
    featureGroup.on('mousemove', this.mouseMoveEventListen);
    featureGroup.on('mouseout', this.mouseOutEventListen);
  },

  destroy() {
    featureGroup.off('mousemove', this.mouseMoveEventListen);
    featureGroup.off('mouseout', this.mouseOutEventListen);
  },

  mouseOutEventListen() {
    leafletCheckInGeofencies.featureGroup.eachLayer(
      leafletCheckInGeofencies.setLayerStyle,
    );
  },

  mouseMoveEventListen(event) {
    leafletCheckInGeofencies.mouseMoveEvent(event);
  },

  mouseMoveEvent(event) {
    const { latlng: mouseLatlng } = event;
    const hoveredLayers = [];
    let tooltipBody = '';
    this.featureGroup.eachLayer((layer) => {
      const isHovered = this.checkLayerInGeofence(mouseLatlng, layer);
      if (isHovered) {
        hoveredLayers.push(layer);
        tooltipBody += `${layer.content}<br>`;
      }
      this.setLayerStyle(layer, isHovered);
    });

    hoveredLayers.forEach((layer) => {
      layer.tooltipBody = tooltipBody;
      this.setLayerStyle(layer, true);
    });
  },

  checkLayerInGeofence(mouseLatlng, layer) {
    const shape = layer.pm.getShape();
    if (shape === 'Polygon') {
      return this.checkPolygon(mouseLatlng, layer);
    }
    if (shape === 'Circle') {
      return this.checkCircle(mouseLatlng, layer);
    }
    return false;
  },

  checkLayerDataInGeofence(mouseLatlng, { shape, latLngs, latLng, radius }) {
    if (shape === 'Polygon') {
      return this.checkPolygonByLayerData(mouseLatlng, latLngs);
    }
    if (shape === 'Circle') {
      return this.checkCircleByLayerData(mouseLatlng, latLng, radius);
    }
    return false;
  },

  setLayerStyle(layer, isHovered) {
    if (isHovered) {
      layer.setStyle({
        fillOpacity: leafletCheckInGeofencies.fillOpacityHovered,
      });

      if (layer.tooltipBody) layer.bindTooltip(layer.tooltipBody);
    } else {
      layer.setStyle({ fillOpacity: leafletCheckInGeofencies.fillOpacity });
    }
  },

  checkCircles(mouseLatlng, circles) {
    const inCircles = [];

    circles.forEach((circleLayer) => {
      if (this.checkCircle(mouseLatlng, circleLayer)) {
        inCircles.push(circle);
      }
    });

    return inCircles;
  },

  checkCircleByCoords({
    circleLat,
    cercleLng,
    cercleRadius,
    pointLat,
    pointLng,
  } = {}) {
    const dist =
      1000 * getDistanceHelper(circleLat, cercleLng, pointLat, pointLng);
    return cercleRadius > dist;
  },

  getDistance({ lat, lng, lat2, lng2 } = {}) {
    return 1000 * getDistanceHelper(lat, lng, lat2, lng2);
  },

  checkCircle(mouseLatlng, circleLayer) {
    const r = circleLayer.getRadius();
    const { lng: cLon, lat: cLat } = circleLayer.getLatLng();
    const dist =
      1000 * getDistanceHelper(mouseLatlng.lat, mouseLatlng.lng, cLat, cLon);
    if (r > dist) {
      return true;
    }

    return false;
  },
  checkCircleByLayerData(mouseLatlng, latLng, radius) {
    const { lng: cLon, lat: cLat } = latLng;
    const dist =
      1000 * getDistanceHelper(mouseLatlng.lat, mouseLatlng.lng, cLat, cLon);
    if (radius > dist) {
      return true;
    }

    return false;
  },

  checkPolygons(mouseLatlng, poligons) {
    const inPoligons = [];

    poligons.forEach((layerPoligon) => {
      if (this.checkPolygon(mouseLatlng, layerPoligon)) {
        inPoligons.push(layerPoligon);
      }
    });

    return inPoligons;
  },

  checkPolygon(mouseLatlng = [], layerPoligon = {}) {
    const pointer = [mouseLatlng.lat, mouseLatlng.lng];
    const latlngs = layerPoligon.getLatLngs();
    const isHovered = leafletPnpolyAlgoritm.checkGeofence(latlngs, pointer);
    this.setLayerStyle(layerPoligon, isHovered);
    return isHovered;
  },

  checkPolygonByLayerData(mouseLatlng = [], latlngs = []) {
    const pointer = [mouseLatlng.lat, mouseLatlng.lng];
    const isHovered = leafletPnpolyAlgoritm.checkGeofence(latlngs, pointer);
    return isHovered;
  },
};
