<template>
  <div
    ref="mainRef"
    v-if="isMainShow"
  >
    <button
      class="not-print float-left"
      onclick="html_to_excel_helper.export(this, 'evacuator-moving-template', 'Работа эвакуатора', 'landscape')"
    >
      Экспорт&nbsp;в&nbsp;EXCEL
    </button>
    <label class="ml-10 not-print"
      ><input
        type="checkbox"
        v-model="isDays"
      />
      По суткам / по поездкам</label
    >
    <div id="evacuator-moving-template">
      <!-- <h4>Отчет по работе эвакуатора {{ templateInteval }}</h4> -->

      <template v-if="isDays">
        <evacuator-moving-table-days
          v-for="obj in objectsCalculated"
          :key="obj.id"
          :vehicle="obj"
          :allGeofencesDict="allGeofencesDict"
        />
      </template>
      <template v-else>
        <evacuator-moving-table
          v-for="obj in objectsCalculated"
          :key="obj.id"
          :vehicle="obj"
          :columns="$options.columns"
          :allGeofencesDict="allGeofencesDict"
        />
      </template>
    </div>
  </div>
</template>

<script>
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';
import { OurAggregated } from '../../../src/dataRequest/ourAggregated.js';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template.js';
import EvacuatorMovingTable from './EvacuatorMovingTable.vue';
import EvacuatorMovingTableDays from './EvacuatorMovingTableDays.vue';

export default {
  name: 'EvacuatorMovingTemplate',
  SUMM_AREA_ID: 'section-reports',
  TEMPLATE_NAME: 'evacuatorMoving',
  EVENT_NAME: 'evacuaor-moving-template',
  inject: ['allGeofences', 'geofenceTypes', 'geofencesUpdating'],
  props: {
    trigger: {
      type: Boolean,
      require: true,
    },
    buttonId: { type: String, require: true },
    form_desc: { type: Object, require: true },
  },
  components: {
    EvacuatorMovingTable,
    EvacuatorMovingTableDays,
  },
  data() {
    return {
      isMainShow: false,
      objectsCalculated: [],
      templateContentElement: null,
      button: null,
      buttonTarget: null,
      objectsList: null,
      isDays: true,
    };
  },
  computed: {
    templateInteval() {
      const [firstObj] = this.objectsCalculated;
      if (firstObj) {
        return firstObj.view.t_interval;
      }
      return '(не был запущен)';
    },
    allGeofencesDict() {
      return this.allGeofences.reduce((acc, geo) => {
        acc[geo.key] = geo;
        return acc;
      }, {});
    },
  },
  watch: {
    buttonId(newButtonId) {
      this.button = document.getElementsByClassName(this.buttonId)[0];
      this.buttonTarget = document.getElementsByClassName(this.buttonId + '-target-id')[0];
    },
    trigger() {
      this.calculateEvent();
    },
    geofencesUpdating() {
      return;
    },
  },
  methods: {
    async calculateEvent() {
      this.button = document.getElementById(this.buttonId);
      this.buttonTarget = document.getElementsByClassName(this.buttonId + '-target-id')[0];
      const buttonTarget = this.buttonTarget;
      const SUMM_AREA_ID = this.$options.SUMM_AREA_ID;

      const beginTime = this.form_desc.beginTime;
      const endTime = this.form_desc.endTime;

      if (!this.form_desc.objects.length) {
        infoShowText_helper(
          buttonTarget,
          'Не выбран ни один объект.',
          SUMM_AREA_ID,
        );
        setElementColor_helper(this.button, 'orange');
        return;
      }

      infoShowText_helper(buttonTarget, `Отправляю запрос...`, SUMM_AREA_ID);
      setElementColor_helper(this.button, 'blue');
      const geofenceIds = this.allGeofences.reduce((acc, geo) => {
        if (geo.type === 1) {
          // geo.type === 1 для База
          acc.push(geo.key);
        }
        return acc;
      }, []);

      const dataSend = {
        time_begin: beginTime / 1000,
        time_end: endTime / 1000,
        option: 2,
        template_name: this.$options.TEMPLATE_NAME,
        geofences: geofenceIds,
        with_geofences: true,
        with_basket: true,
      };

      const obj = this.form_desc.objects;

      const dataArr = { objIdArr:obj };

      // запрос позиций и подсчет значений по отчету
      const ourAggregated = new OurAggregated(skillsManTemplate);
      // const {smenasSeconds, smenasOrigin} = this.getSmenasSetting();
      // добавим смены в запрос
      // for (let s in smenasSeconds) {
      //     const nSmena = +s + 1;
      //     dataSend['smena_' + nSmena] = smenasSeconds[s];
      // }
      
      fetchToTemplateService({
        templateName: this.$options.TEMPLATE_NAME,
        form_desc: this.form_desc,
        areaId: SUMM_AREA_ID,
        options: {
          option: 2,
          template_name: this.$options.TEMPLATE_NAME,

          geofences: geofenceIds,
          with_geofences: true,
          with_basket: true,

          isGetSmenas: false,
          smenasOrigin: [],

          params: {
            objectsList: this.form_desc.objects,
            isBasket: true,
            isMovingGroup: this.isMovingGroup,
            geofencesToTemplate: geofenceIds, //this.checkedGeofencesToTemplate,
            geofencesWorkZone: [], //this.checkedGeofencesWorkZone,
            leafletGeofences: [], //this.geofences,
            isGeoCalc: true,
            templateName: this.$options.TEMPLATE_NAME,
          },
        },
        successCallback: this.calculatedCb,
        errorCallback: () =>
          ourAggregated.getAggregated({
            dataArr,
            dataSend,
            targetElement: this.buttonTarget,
            buttonElement: this.button,
            templateName: this.$options.TEMPLATE_NAME,
            callback: this.calculatedCb,
            areaId: SUMM_AREA_ID,
            params: {
              objectsList: this.form_desc.objects,
              that: this,
              isBasket: true,
              isMovingGroup: this.isMovingGroup,
              geofencesToTemplate: geofenceIds, //this.checkedGeofencesToTemplate,
              geofencesWorkZone: [], //this.checkedGeofencesWorkZone,
              leafletGeofences: [], //this.geofences,
              isGeoCalc: true,
              templateName: this.$options.TEMPLATE_NAME,
            },
            isGetSmenas: false,
            smenasOrigin: [],
          }),
      });

      // дальнейшие действия происходят внутри getAggregated - вызывается callback после получения и обсчета данных
    },
    calculatedCb(objectsCalculated, { objectsList = [] } = {}) {
      infoShowText_helper(
        this.buttonTarget,
        `Обработка ответа`,
        this.$options.SUMM_AREA_ID,
      );

      // включаем отображене - вью наполнит данными
      this.templateContentElement.innerText = '';
      this.isMainShow = true;
      this.$nextTick(() => {
        // переносим в ID где отчеты
        this.templateContentElement.append(this.$refs.mainRef);
        infoShowText_helper(
          this.buttonTarget,
          `Отчет построен. Выполнить новый запрос?`,
        );
        setElementColor_helper(this.button, 'green');
      });
      // return;

      this.objectsCalculated = objectsCalculated.objects;
      // humanTime
      // this.objectsCalculated.forEach((obj) => {
      //   obj.eventsRows.forEach((eventRow) => {
      //     eventRow.humanTime = formatDateHelper(
      //       new Date(eventRow.time * 1000),
      //       "dd.mm.yyyy hh:nn:ss"
      //     );
      //   });
      // });

      // if (isEmptyObject_helper(this.detailRowsSettings)) {
      //   const [firstObj = {}] = this.objectsCalculated;
      //   const { rows_person = {} } = firstObj;
      //   const { person, personForDisplay } = rows_person;
      //   if (person && personForDisplay) {
      //     this.detailRowsSettings = addCopyObj_helper(person); // стока: значения колонок
      //     this.detailRowsOrder = personForDisplay.reduce((acc, group) => {
      //       // строки в каком порядке выгружать, с группировкой
      //       group.forEach((rowName) => acc.push(rowName));
      //       return acc;
      //     }, []);
      //   }
      // }

      // this.isShow = true;
      // this.$nextTick(() => {
      //   this.templateContentElement.innerText = "";
      //   this.templateContentElement.append(this.$refs.contentRef);
      // });
    },
  },
  mounted() {
    // const showTemplateButton = document.getElementById("get-template-button");
    // this.showTemplateButton = showTemplateButton;
    this.button = document.getElementsByClassName(this.buttonId)[0];
    this.buttonTarget = document.getElementsByClassName(this.buttonId + '-target-id')[0];
    this.templateContentElement = document.getElementById(
      this.$options.SUMM_AREA_ID,
    );
    // document.getElementById(this.$options.SUMM_AREA_ID);
    // this.calculateEvent();
  },
  columns: {
    tableColumns: [
      'timeIn',
      'geofence',
      'comInterval',
      'timeOut',
      'isLoaded',
      'dist',
      'canexpence100km',
      'avgSpeed',
      'forfeits',
      'forfeitsRelative',
    ],
    geofence: {
      key: 'geoName',
      text: 'Наименование геозоны, lat, lon',
      excelWidth: 17,
      get({ evacuatorRow = {} } = {}, allGeofencesDict) {
        const { geo = [], isFirstPos, basket } = evacuatorRow;
        if (geo.length) {
          return geo
            .map(
              (key) => allGeofencesDict[key]?.name ?? '(Недоступная геозона)',
            )
            .join(', ');
        }
        if (isFirstPos) {
          return basket ? 'КОМ в работе: ' : 'вне геозоны: ';
        }
        return 'Работа КОМ: ';
      },
      getAHref({ evacuatorRow } = {}) {
        const lat = myRoundNumber_helper(evacuatorRow.lat, 5);
        const lon = myRoundNumber_helper(evacuatorRow.lon, 5);
        // return `http://maps.google.com/maps?q=${lat},${lon}`;
        return `${
          process.env.VUE_APP_BACKEND_URL || window.location.origin
        }/track-motion?type=position&lat=${lat}&lon=${lon}`;
      },
      getAText({ evacuatorRow } = {}) {
        const lat = myRoundNumber_helper(evacuatorRow.lat, 5);
        const lon = myRoundNumber_helper(evacuatorRow.lon, 5);
        return `${lat}, ${lon}`;
      },
    },
    avgSpeed: {
      key: 'avgSpeed',
      text: 'Средняя скорость по CAN при движении ТС (км/ч)',
      excelWidth: 16,
      excelFormat: 13,
      get({ evacuatorRow = {} } = {}) {
        const value = evacuatorRow.avgSpeed;
        return !isNaN(value) ? myRoundNumber_helper(value, 1) : value;
      },
    },
    timeIn: {
      key: 'timeIn',
      text: 'Время въезда',
      excelWidth: 17,
      excelFormat: 1,
      getExcelValue({ evacuatorRow = {} } = {}) {
        const { timeIn, isFirstPos, geo } = evacuatorRow;
        if (isFirstPos) {
          return '';
        }
        if (timeIn > 0) {
          return timeIn / 86400 + 25569 - new Date().getTimezoneOffset() / 1440;
        }
        return '';
      },
      get({ evacuatorRow = {} } = {}) {
        const { timeIn, isFirstPos, geo } = evacuatorRow;
        if (isFirstPos) {
          return geo.length
            ? 'на нач. отчета находился в геозоне'
            : 'начало отчета';
        }
        if (timeIn > 0) {
          return formatDateHelper(
            new Date(timeIn * 1000),
            'dd.mm.yyyy hh:nn:ss',
          );
        }
        return timeIn;
      },
    },
    comInterval: {
      key: 'comInterval',
      text: 'Время работы COM',
      excelWidth: 8,
      excelFormat: 4,
      getExcelValue({ evacuatorRow = {} } = {}) {
        return evacuatorRow.comInterval / 86400;
      },
      get({ evacuatorRow = {} } = {}) {
        return formatTimeHelper(evacuatorRow.comInterval / 86400, 'tt:nn:ss');
      },
    },
    timeOut: {
      key: 'timeOut',
      text: 'Время выезда из геозоны',
      excelWidth: 17,
      excelFormat: 1,
      getExcelValue({ evacuatorRow = {} } = {}) {
        const { timeOut } = evacuatorRow;
        if (timeOut > 0) {
          return (
            timeOut / 86400 + 25569 - new Date().getTimezoneOffset() / 1440
          );
        }
        return '';
      },
      get({ evacuatorRow = {} } = {}) {
        const { timeOut } = evacuatorRow;
        if (timeOut > 0) {
          return formatDateHelper(
            new Date(timeOut * 1000),
            'dd.mm.yyyy hh:nn:ss',
          );
        }
        return timeOut;
      },
    },
    isLoaded: {
      key: 'isLoaded',
      text: 'Груженый/порожний',
      excelWidth: 10,
    },
    dist: {
      key: 't2_distance',
      text: 'Пробег (км)',
      excelWidth: 8,
      excelFormat: 7,
      get({ evacuatorRow = {} } = {}) {
        const value = evacuatorRow.dist;
        return !isNaN(value) ? myRoundNumber_helper(value, 2) : value;
      },
    },
    canexpence100km: {
      key: 'canexpence100km',
      text: 'Расход по CAN в движении (л/100км)',
      excelWidth: 10,
      excelFormat: 13,
      get({ evacuatorRow = {} } = {}) {
        const value = evacuatorRow.canexpence100km;
        return !isNaN(value) ? myRoundNumber_helper(value, 1) : value;
      },
    },
    forfeitsRelative: {
      key: 'forfeitsRelative',
      text: 'Баллы нарушений (кол-во/100км)',
      excelWidth: 10,
      excelFormat: 7,
      get({ evacuatorRow = {} } = {}) {
        const value = evacuatorRow.forfeitsSumm.forfeitsRelative;
        return !isNaN(value) ? myRoundNumber_helper(value, 2) : value;
      },
    },
    forfeits: {
      key: 'forfeitsRelative',
      text: 'Баллы нарушений (кол-во)',
      excelWidth: 10,
      excelFormat: 12,
      get({ evacuatorRow = {} } = {}) {
        return evacuatorRow.forfeitsSumm.forfeits;
      },
    },
  },
};
</script>
