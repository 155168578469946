<template>
  <div class="filter-overflow">
    <div class="filter">
      <div
        class="filter__head"
        :class="[{ active: isOpenFilter }]"
        @click="toggleFilter"
      >
        <div>{{ props.filterData[filterOn] }}</div>
      </div>
      <ul
        class="filter__list"
        :class="[{ active: isOpenFilter }]"
      >
        <li
          v-for="(item, key) in props.filterData"
          class="item"
          :class="[{ active: filterOn === key }]"
          :data-key="key"
          @click="selectFilterHandler"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  filterSelected: { type: String, default: '' },
  filterData: { type: Object, default: {} },
});

const emit = defineEmits(['update:filter-selected']);

const isOpenFilter = ref(false);

const toggleFilter = (e) => {
  if (isOpenFilter.value) {
    e.target.parentElement.parentElement.classList.remove('open');
  } else {
    e.target.parentElement.parentElement.classList.add('open');
  }
  isOpenFilter.value = !isOpenFilter.value;
};

const selectFilterHandler = (e) => {
  filterOn.value = e.target.dataset.key;
  toggleFilter(e);
};

const filterOn = computed({
  get() {
    return props.filterSelected;
  },
  set(val) {
    emit('update:filter-selected', val);
  },
});
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';

.filter-overflow {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 38px;
  font-size: 14px;

  // margin-bottom: 5px;

  @media (max-width: 1175px) {
    height: 32px;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 150px;
  height: 75px;
  overflow: hidden;
  // margin-left: 10px;
  font-size: 14px;
  height: 38px;

  &.open {
    height: 500px;
  }

  &__head div,
  &__list {
    border: 1px solid $border-color;
    width: 100%;
    padding: 6px 12px;
  }
  &__head {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: inherit;
    width: 100%;
    user-select: none;
    cursor: pointer;
    & div {
      background: $bg-secondary;
      border-radius: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.active div {
      border-radius: 15px 15px 0px 0px;
    }
  }

  &__list {
    position: absolute;
    top: -500px;
    left: 0;
    z-index: 1;

    padding: 0;

    list-style: none;
    overflow: hidden;
    user-select: none;
    background: #efd6b2;

    font-size: 14px;
    line-height: 16px;

    transition: all 0.3s ease;
    &.active {
      top: 36px;
      max-height: 150px;
      overflow-y: scroll;
    }
    & .item {
      padding: 6px 12px;
      border-bottom: 1px solid $txt-primary-color;
      word-wrap: break-word;

      &.active,
      &:hover {
        background: rgba($bg-secondary, 0.4);
      }

      &:last-child {
        border: none;
      }
    }
  }

  @media (max-width: 1175px) {
    font-size: 12px;
    &__list {
      &.active {
        top: 32px;
      }
    }
  }
}
</style>
