<template>
  <!-- <div v-show="show" class="violations_modal-mask" @click="$emit('close')"> -->
  <div
    v-show="show"
    class="violations_modal-mask"
    @click="$emit('close')"
  >
    <div class="violations_modal-wrapper">
      <div
        class="violations_modal-container"
        :style="{ width: containerWidth, height: containerHeight }"
        @click.stop
      >
        <div
          v-if="show"
          class="violations_modal-header"
        >
          <slot name="header">
            <div></div>
          </slot>
          <div
            v-if="closeIcon"
            class="close_btn"
            @click="$emit('close')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="40px"
              viewBox="0 0 24 24"
              width="40px"
              fill="#e24c4b"
            >
              <path
                d="M0 0h24v24H0z"
                fill="none"
              />
              <path
                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
              />
            </svg>
          </div>
        </div>
        <slot>
          <div></div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    show: { type: Boolean, required: true, default: false },
    clientWidth: { type: Number, required: true },
    clientHeight: { type: Number, required: true },
    closeIcon: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      containerWidth: 0,
      containerHeight: 0,
    };
  },

  watch: {
    clientWidth: {
      handler(newClientWidth) {
        const onePercentWidth = newClientWidth / 100;
        if (newClientWidth > 0) {
          this.containerWidth = onePercentWidth * 90 + 'px';
        }
      },
      immediate: true,
    },
    clientHeight: {
      handler(newClientHeight) {
        const onePercentHeight = newClientHeight / 100;
        if (newClientHeight > 0) {
          this.containerHeight = onePercentHeight * 80 + 'px';
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.close_btn {
  width: 36px;
  height: 36px;
  cursor: pointer;
  & svg {
    fill: #de6464;
  }
  &:hover img {
    fill: #e24c4b;
  }
}

.modal-filter {
  max-width: 110px;

  @media (max-width: 768px) {
    max-width: 110px;
  }
  @media (max-width: 560px) {
    font-size: 15px;
  }
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 385px) {
    font-size: 13px;
  }
  @media (max-width: 365px) {
    font-size: 12px;
    max-width: 90px;
  }
  @media (max-width: 340px) {
    font-size: 11px;
  }
}

.violations_modal-mask {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.violations_modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.violations_modal-container {
  cursor: default;
  max-width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 20px 15px;
  }
}

.violations_modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 585px) {
    & h5 {
      font-size: 1rem;
    }
    & p {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 450px) {
    & h5 {
      font-size: 0.9rem;
    }
    & p {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 410px) {
    & h5 {
      font-size: 0.8rem;
    }
    & p {
      font-size: 0.6rem;
    }
  }
  @media (max-width: 376px) {
    & h5 {
      font-size: 0.75rem;
    }
    & p {
      font-size: 0.55rem;
    }
  }
  @media (max-width: 360px) {
    & h5 {
      font-size: 0.7rem;
    }
    & p {
      font-size: 0.5rem;
    }
  }
  @media (max-width: 342px) {
    & h5 {
      font-size: 0.65rem;
    }
    & p {
      font-size: 0.45rem;
    }
  }
  @media (max-width: 325px) {
    & h5 {
      font-size: 0.6rem;
    }
    & p {
      font-size: 0.4rem;
    }
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
