<template>
  <div>
    <router-view />
    <the-toaster ref="toaster" />

    <vehicles-popup
      v-if="popups.vehicles"
      :title="vehiclesPopupTitle"
      :ids="vehiclesPopupIds"
      @close="() => togglePopup('vehicles')"
    />

    <current-tariffs-popup
      v-if="popups.currentTariffs"
      :data="currentTariffsPopupData"
      @close="() => togglePopup('currentTariffs')"
    />

    <tariff-popup
      v-if="popups.tariff"
      :data="tariffPopupData"
      @close="() => togglePopup('tariff')"
    />
  </div>
</template>

<script setup>
import { inject, onMounted, provide, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';

import TariffPopup from './Components/Popups/TariffPopup.vue';
import VehiclesPopup from './Components/Popups/VehiclesPopup.vue';
import CurrentTariffsPopup from './Pages/Contractors/Contractor/Components/CurrentTariffsPopup.vue';
import TheToaster from '@/App/Components/toaster/TheToaster.vue';

import { useRefetch } from './use/refetch';

import { STORE } from './STORAGE/STORAGE';
import { usePopup } from './use/popup';

const emit = defineEmits(['error']);

const router = useRouter();

const { popups, togglePopup } = usePopup({
  vehicles: false,
  currentTariffs: false,
  tariff: false,
});

const projectData = inject('projectData');

const toaster = ref(null);

provide('routerGo', routerGo);
provide('routerPush', routerPush);
provide('displayToast', displayToast);

const vehiclesPopupTitle = ref('');
const vehiclesPopupIds = ref([]);
provide('displayVehiclesPopup', displayVehiclesPopup);

const currentTariffsPopupData = ref({ subtitle: '', data: [] });
provide('displayCurrentTariffsPopup', displayCurrentTariffsPopup);

const tariffPopupData = ref({});
provide('displayTariffPopup', displayTariffPopup);

function displayVehiclesPopup(title = '', ids = []) {
  vehiclesPopupTitle.value = title;
  vehiclesPopupIds.value = ids;
  togglePopup('vehicles');
}

function displayCurrentTariffsPopup({ subtitle = '', data = [] }) {
  currentTariffsPopupData.value = { subtitle, data };
  togglePopup('currentTariffs');
}

function displayTariffPopup(id_t) {
  if (id_t && STORE.defaultTariffs[id_t]) {
    tariffPopupData.value = STORE.defaultTariffs[id_t];
    togglePopup('tariff');
  } else {
    displayToast('Не удалось найти тариф');
  }
}

function routerGo(number) {
  if (number !== undefined) {
    router.go(number);
  }
}

function routerPush(options) {
  router.push(options);
}

function displayToast(message = '', type = 'error', delay) {
  if (type && message) {
    toaster.value[type]({ message, delay });
  }
}

onMounted(async () => {
  const { rule_contract_watch, rule_contractor_watch, rule_vehicle_watch } =
    projectData.senderRights;

  STORE.userRole = projectData.userRole;
  STORE.senderRights = projectData.senderRights;

  useRefetch.allContractors(displayToast);

  useRefetch.allContracts(displayToast);

  useRefetch.allVehicles(displayToast);

  useRefetch.allAdditionals(displayToast);

  useRefetch.allTariffs(displayToast);
  useRefetch.tarifsContractor(displayToast);
  useRefetch.tarifsVehicle(displayToast);

  useRefetch.monitoringObjectsList(displayToast);
  useRefetch.equipment(displayToast);
});
</script>

<style lang="scss"></style>
