// требуется ExcelJS https://github.com/exceljs/exceljs
import ExcelJS from '../exceljs/dist/exceljs.min.js';
import {
  formatDateHelper,
  formatTimeHelper,
  myRoundNumber_helper,
} from './main_helper.js';

export const html_to_excel_helper = {
  in_array_helper: function (value, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i] == value) return true;
    }
    return false;
  },

  getTextAlignV: function (alignNum) {
    switch (alignNum) {
      case 0:
        return 'bottom';
      case 1:
        return 'middle';
      case 2:
        return 'top';
      default:
        return '';
    }
  },

  getTextAlignH: function (alignNum) {
    switch (alignNum) {
      case 0:
        return 'center';
      case 1:
        return 'left';
      case 2:
        return 'right';
      case 4:
        return 'left';
      default:
        return '';
    }
  },

  checkSheetOrientation: function (checkOrient, defaultOrient) {
    if (checkOrient != 'portrait' && checkOrient != 'landscape') {
      return defaultOrient;
    }
    return checkOrient;
  },

  validValue: {
    alignv: ['top', 'middle', 'bottom', 'distributed', 'justify'],
    alignh: [
      'left',
      'center',
      'right',
      'fill',
      'justify',
      'centerContinuous',
      'distributed',
    ],
  },

  export: async function (button, templateId, temlateName, orientation) {
    // требуется ExcelJS https://github.com/exceljs/exceljs

    // if (orientation != 'portrait' && orientation != 'landscape')
    const v = {};
    v.orientation = this.checkSheetOrientation(orientation, 'portrait');

    const sheetNames = {};

    let elemTextEnd = button.innerText;
    v.button = button;
    button.disabled = true;
    button.style.width = button.offsetWidth + 'px';
    v.exportInfo = 'Экспорт: ';
    let errorText = 'ОШИБКА';

    button.innerText = v.exportInfo + '0 %';

    let html = document.getElementById(templateId);
    let childrenCnt = html.children.length;
    if (!childrenCnt) {
      button.innerText = errorText;
      return;
    }

    if (html.children[0].classList.contains('temlate-report')) {
      html = html.children[0]; // это обертка отчета
      if (html.children.length == 0) {
        button.innerText = errorText;
        return;
      }
    }

    v.footerDesc = temlateName;
    v.temlateName = temlateName.replace(/ /g, '_');
    v.workbook = new ExcelJS.Workbook();

    this.workbook = v.workbook;

    v.textCenter = [];
    v.excelBottomIndent = false;

    v.cur_row = 0;
    v.col_max = 1;

    v.childrenCnt = childrenCnt;
    v.childrenMaxPercent = 90;
    v.printAreas = [];
    v.printArea = {};
    v.printArea.rowBegin = 1;
    v.printArea.colMax = 1;

    v.worksheet = null;
    v.sheetName = '';
    v.progressPercent = 0;

    // перебор первой вложенности
    for (let i = 0; i < childrenCnt; i++) {
      const elem = html.children[i];
      await this.exportElement(i, elem, v, sheetNames);

      //show progress
      const percent = Math.round((v.childrenMaxPercent * i) / v.childrenCnt);
      if (percent - v.progressPercent) {
        v.progressPercent = percent;
        v.button.innerText = `${v.exportInfo} ${percent}%`;
        await new Promise((resolve) => {
          setTimeout(() => resolve(), 0);
        });
      }
    }

    let footerDesc_this = v.sheetName
      ? v.footerDesc + ' ' + v.sheetName
      : v.footerDesc;
    this.sheetDecorator(
      v.worksheet,
      button,
      v.textCenter,
      v.exportInfo,
      v.childrenMaxPercent,
      v.cur_row,
      v.col_max,
      v.printArea,
      v.printAreas,
      footerDesc_this,
    );

    let dateTime = new Date();
    let timeString =
      dateTime.getFullYear() +
      '_' +
      dateTime.getMonth() +
      '_' +
      dateTime.getDate() +
      '__' +
      dateTime.getHours() +
      '_' +
      dateTime.getMinutes() +
      '_' +
      dateTime.getSeconds();
    this.saveAsExcel(v.workbook, v.temlateName + '__' + timeString);

    button.innerText = elemTextEnd;
    button.disabled = false;
  },

  async exportElement(i, elem, v, sheetNames) {
    let data_excelsheet = elem.getAttribute('data-excelsheet');

    if (data_excelsheet) {
      // новый лист
      if (sheetNames[data_excelsheet]) {
        for (var z = 1; z < 101; z++) {
          data_excelsheet = `${data_excelsheet}(${z})`;
          if (!sheetNames[data_excelsheet]) {
            break;
          }
        }
      }

      sheetNames[data_excelsheet] = true;

      if (v.worksheet) {
        // предыдущий лист нужно завершить
        let footerDesc_this = v.sheetName
          ? v.footerDesc + ' ' + v.sheetName
          : v.footerDesc;
        this.sheetDecorator(
          v.worksheet,
          v.button,
          v.textCenter,
          v.exportInfo,
          v.childrenMaxPercent,
          v.cur_row,
          v.col_max,
          v.printArea,
          v.printAreas,
          footerDesc_this,
        );

        // и обнулить значения для нового листа
        v.textCenter = [];
        v.cur_row = 0;
        v.col_max = 1;
        v.printAreas = [];
        v.printArea = {};
        v.printArea.rowBegin = 1;
        v.printArea.colMax = 1;
      }

      v.sheetName = data_excelsheet;
      v.worksheet = v.workbook.addWorksheet(v.sheetName, {
        pageSetup: {
          paperSize: 9,
          orientation: this.checkSheetOrientation(
            elem.getAttribute('data-excelorientation'),
            v.orientation,
          ),
        },
      });
      // worksheet.properties.outlineProperties = {summaryBelow: 2, summaryRight: 2};
      return;
    }

    if (!v.worksheet) {
      // лист еще не был создан - нужно создать
      v.worksheet = v.workbook.addWorksheet(v.temlateName, {
        pageSetup: { paperSize: 9, orientation: v.orientation },
      });
    }

    if (elem.classList.contains('page-break-after')) {
      // Insert a page break below the row
      if (elem.classList.contains('excel-bottom-indent')) {
        v.cur_row++;
      }

      const thisPrintArea =
        'A' +
        v.printArea.rowBegin +
        ':' +
        getColNumberAsExcelText_helper(v.printArea.colMax) +
        v.cur_row;
      v.printAreas.push(thisPrintArea);
      v.printArea.rowBegin = v.cur_row + 1;
      v.printArea.colMax = 1;

      v.worksheet.getRow(v.cur_row).addPageBreak();
      return;
    }

    if (
      elem.classList.contains('not-print') ||
      elem.classList.contains('not-excel')
    ) {
      if (elem.classList.contains('excel-bottom-indent')) {
        v.cur_row++;
      }
      return;
    }

    let wasRowCol;
    if (v.excelBottomIndent) {
      v.cur_row++;
    }
    v.excelBottomIndent = Boolean(
      elem.classList.contains('excel-bottom-indent'),
    );

    if (elem.tagName === 'DIV') {
      const inDivElementsCount = elem.children.length;
      for (let j = 0; j < inDivElementsCount; j++) {
        const innerElem = elem.children[j];
        await this.exportElement(i, innerElem, v, sheetNames);
      }
      return;
    }

    if (elem.tagName === 'TABLE') {
      // это таблица, выгружаем по своей ф-ии
      wasRowCol = await this.unloadTable(v.worksheet, elem, v.cur_row);
      v.cur_row = wasRowCol.cur_row;
      v.col_max = wasRowCol.col_max > v.col_max ? wasRowCol.col_max : v.col_max;
      v.printArea.colMax =
        wasRowCol.col_max > v.printArea.colMax
          ? wasRowCol.col_max
          : v.printArea.colMax;
      return;
    }

    // worksheet.getRow(++cur_row).getCell(1).value = elem.innerHTML;
    let cell = v.worksheet.getRow(++v.cur_row).getCell(1);
    cell.value = elem.innerText;
    if (elem.classList.contains('footer-desc')) {
      v.footerDesc = elem.innerHTML;
      this.setCellBold(cell, true);
    } else if (elem.classList.contains('font-weight-bold')) {
      this.setCellBold(cell, true);
    }

    if (elem.classList.contains('text-center')) {
      v.textCenter.push(v.cur_row);
    }
  },

  sheetDecorator: function (
    worksheet,
    button,
    textCenter,
    exportInfo,
    childrenMaxPercent,
    cur_row,
    col_max,
    printArea,
    printAreas,
    footerDesc,
  ) {
    let styleMaxPercent = 100 - childrenMaxPercent;
    for (let i = 0; i < textCenter.length; i++) {
      worksheet.mergeCells(textCenter[i], 1, textCenter[i], col_max); // top,left,bottom,right
      worksheet.getCell('A' + textCenter[i]).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      //show progress
      button.innerText =
        exportInfo +
        Math.round(
          childrenMaxPercent + (styleMaxPercent * (i + 1)) / textCenter.length,
        ) +
        ' %';
    }

    // Set Print Area for a sheet
    if (cur_row > printArea.rowBegin) {
      let thisPrintArea =
        'A' +
        printArea.rowBegin +
        ':' +
        getColNumberAsExcelText_helper(printArea.colMax) +
        cur_row;
      printAreas.push(thisPrintArea);
    }

    let printAreasString = '';
    for (let i = 0; i < printAreas.length; i++) {
      if (printAreasString) printAreasString += '&&';
      printAreasString += printAreas[i];
    }
    //let cell = worksheet.getRow(cur_row + 1).getCell(1);
    //cell.value = printAreasString;

    worksheet.pageSetup.printArea =
      'A1:' + getColNumberAsExcelText_helper(col_max) + cur_row;
    //worksheet.pageSetup.printArea = printAreasString;
    //worksheet.pageSetup.printArea = 'A1:N9&&A10:I53&&A54:P116&&A117:I160&&A161:P222';
    // 'A1:G10&&A11:G20';

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.3,
      right: 0.3,
      top: 0.3,
      bottom: 0.45,
      header: 0.3,
      footer: 0.3,
    };
    // Set footer (default centered), result: "Page 2 of 16"
    worksheet.headerFooter.oddFooter = '&R' + footerDesc + '  /  лист &P из &N';
  },

  getInnerText: function (elem) {
    if (!elem.children.length) {
      return elem.innerText;
    }

    let allText = elem.innerText;
    for (let m = 0; m < elem.children.length; m++) {
      if (
        elem.children[m].classList.contains('not-print') ||
        elem.children[m].classList.contains('not-excel')
      ) {
        allText = allText.replace(elem.children[m].innerText, '');
      }
    }
    return allText;
  },

  setCellBold: function (cell, isBold, alignv, alignh) {
    if (!cell.style) {
      cell.style = { font: { bold: isBold } };
    } else if (!cell.style.font) {
      cell.style.font = { bold: isBold };
    } else {
      cell.style.font.bold = isBold;
    }
  },

  unloadTable: async function (ws, table, cur_row) {
    let caption = table.getElementsByTagName('caption');
    let isAutofilter = table.getAttribute('data-excelautofilter') != null;
    let autoFilterRowSkipNumber = Number(
      table.getAttribute('data-excelautofilter-row-skip') || 0,
    );
    let isExcelNotBorder = table.classList.contains('excel-not-border');
    if (
      caption &&
      caption[0] &&
      !caption[0].classList.contains('not-print') &&
      !caption[0].classList.contains('not-excel') &&
      caption[0].innerHTML != ''
    ) {
      // ws.getRow(++cur_row).getCell(1).value = caption[0].innerText;
      ws.getRow(++cur_row).getCell(1).value = this.getInnerText(caption[0]);
    }

    let autoFilterRowBegin = cur_row + 1 + autoFilterRowSkipNumber;

    let wasRowCol;
    let col_max = 0;
    wasRowCol = await this.unloadTablePart(
      ws,
      table.tHead,
      cur_row,
      true,
      isExcelNotBorder,
      col_max,
    );
    cur_row = wasRowCol.cur_row;
    col_max = wasRowCol.col_max > col_max ? wasRowCol.col_max : col_max;

    for (let t = 0; t < table.tBodies.length; t++) {
      wasRowCol = await this.unloadTablePart(
        ws,
        table.tBodies[t],
        cur_row,
        !table.tHead,
        isExcelNotBorder,
        col_max,
      );
      cur_row = wasRowCol.cur_row;
      col_max = wasRowCol.col_max > col_max ? wasRowCol.col_max : col_max;
    }

    // autofilter
    if (isAutofilter && cur_row > autoFilterRowBegin + 1 && col_max > 1) {
      ws.autoFilter = {
        from: {
          row: autoFilterRowBegin,
          column: 1,
        },
        to: {
          row: cur_row,
          column: col_max,
        },
      };
    }

    return wasRowCol;
  },

  unloadTablePart: async function (
    ws,
    element,
    cur_row,
    isHeader,
    isExcelNotBorder,
    col_max,
  ) {
    if (!element) return { cur_row: cur_row, col_max: 0 };

    let row_spans = {};

    for (let j = 0; j < element.rows.length; j++) {
      let cur_col = 0;
      let row = ws.getRow(++cur_row);

      let outlineLevel = element.rows[j].getAttribute('data-exceloutlinelevel');
      if (outlineLevel) {
        row.outlineLevel = outlineLevel;
      }

      if (element.rows[j].classList.contains('print-frozen-row')) {
        ws.views = [{ state: 'frozen', ySplit: cur_row }];
      }

      // let row_computedStyle = getComputedStyle(element.rows[j]);
      let border = this.getBorderStyle(
        element.rows[j],
        isHeader,
        isExcelNotBorder,
      );
      if (element.rows[j].classList.contains('print-repeat')) {
        // Repeat specific rows on every printed page
        ws.pageSetup.printTitlesRow = cur_row + ':' + cur_row;
      }

      for (let k = 0; k < element.rows[j].cells.length; k++) {
        let cell = row.getCell(++cur_col);

        let htmlCell = element.rows[j].cells[k];

        if (!(cur_col in row_spans)) {
          row_spans[cur_col] = 0;
        }

        // пропуск объединенных ранее ячеек
        if (row_spans[cur_col]) {
          while (row_spans[cur_col]) {
            --row_spans[cur_col];
            ++cur_col;
          }
          cell = row.getCell(cur_col);
        }
        row_spans[cur_col] = row_spans[cur_col] ? row_spans[cur_col] - 1 : 0;

        // row.getCell(++cur_col).value = element.rows[j].cells[k].innerHTML;
        let bgcolor = this.getColor(htmlCell.getAttribute('bgcolor'));
        let alignh = htmlCell.getAttribute('data-excelalignh');
        let alignv = htmlCell.getAttribute('data-excelalignv');

        // выравнивание ячеек
        if (alignh && !this.in_array_helper(alignh, this.validValue.alignh)) {
          alignh = false;
        }
        if (alignv && !this.in_array_helper(alignv, this.validValue.alignv)) {
          alignv = false;
        }

        // выравнивание ячеек
        if (alignh && alignv) {
          cell.alignment = { vertical: alignv, horizontal: alignh };
        } else if (alignh) {
          cell.alignment = { horizontal: alignh };
        } else if (alignv) {
          cell.alignment = { vertical: alignv };
        }
        // worksheet.getCell('A' + textCenter[i]).alignment = { vertical: 'middle', horizontal: 'center' };

        if (htmlCell.classList.contains('text-danger')) {
          cell.font = { color: { argb: this.getColor('red') }, bold: true };
        } else if (
          htmlCell.tagName == 'TH' ||
          htmlCell.classList.contains('font-weight-bold')
        ) {
          this.setCellBold(cell, true);
        }

        if (htmlCell.hasAttribute('data-font-size')) {
          const fontSize = htmlCell.getAttribute('data-font-size');

          if (!!fontSize) {
            if (
              typeof cell.font === 'object' &&
              cell.font !== null &&
              'size' in cell.font
            ) {
              cell.font.size = fontSize;
            } else {
              cell.font = { size: fontSize };
            }
          }
        }

        // формат
        let formatNum = +htmlCell.getAttribute('data-formatnum') || 0;

        const dataValue = htmlCell.getAttribute('data-value');
        const dataValueNumber =
          dataValue || dataValue === 0 ? parseFloat(dataValue) : null;

        const htmlValue = this.getInnerText(htmlCell);

        // значение;
        let willBeValue = dataValue || htmlValue;

        if (htmlCell.hasAttribute('data-image')) {
          const imageType = htmlCell.getAttribute('data-image');

          let base64 = '';
          let type = 'jpg';

          let width = 500;
          let height = 500;

          if (imageType === 'svg') {
            width = htmlCell.firstChild.firstChild.getAttribute('width');
            height = htmlCell.firstChild.firstChild.getAttribute('height');
            base64 = await this.getBase64FromSvg(
              htmlCell.firstChild.firstChild,
            );
          } else {
            base64 = htmlCell.firstChild.src;
          }

          const imageId2 = this.workbook.addImage({
            base64: base64,
            extension: type,
          });

          row.height = height / 1.3;

          ws.addImage(imageId2, {
            tl: { col: cur_col - 1, row: cur_row - 1 },
            ext: { width, height },
          });

          willBeValue = '';
        }

        // работа с форматом и значением
        if (formatNum) {
          let numberValue = parseFloat(clearSpaces_helper(willBeValue));

          if (isNaN(numberValue)) {
            formatNum = 0;
          } else {
            willBeValue = numberValue;
          }
        }

        // применим формат, если сохранился, и значение
        if (formatNum) {
          const htmlValueNumber = parseFloat(clearSpaces_helper(htmlValue));
          cell.numFmt = getInnerTextFromFormatExcel_helper(formatNum);
          if (
            willBeValue &&
            ([8, 10, 11].indexOf(formatNum) > -1 ||
              ((formatNum === 6.5 || formatNum === 6) &&
                (dataValueNumber === null ||
                  (!isNaN(dataValueNumber) &&
                    Math.abs(dataValueNumber) * 10 >
                      Math.abs(htmlValueNumber)))))
          ) {
            willBeValue /= 100; // это проценты
          }
        }

        const [a] = htmlCell.getElementsByTagName('a') || [];

        const currentLocation = window.location;

        if (a && a.href.indexOf(currentLocation.origin) === -1) {
          cell.value = {
            text: willBeValue,
            hyperlink: a.href,
            tooltip: a.href,
          };
        } else {
          cell.value = willBeValue;
        }

        // cell.value = willBeValue;

        // ширина
        if (isHeader) {
          let column = ws.getColumn(cur_col);
          let minWidth = element.rows[j].cells[k].getAttribute('data-minwidth');
          if (minWidth > 0 && (!column.width || column.width < minWidth)) {
            column.width = ++minWidth - 0.36;
          }
        }

        // высота
        let minHeight = element.rows[j].cells[k].getAttribute('data-minheight');
        if (minHeight > 0 && (!row.height || row.height < minHeight)) {
          row.height = minHeight * 2;
        }

        // границы
        cell.border = border;
        if (cell.alignment) {
          cell.alignment.wrapText = true;
        } else {
          cell.alignment = { wrapText: true };
        }

        if (bgcolor) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: bgcolor },
          };
        }

        // объединение ячеек по горизонтали
        let colSpan = element.rows[j].cells[k].getAttribute(
          'data-excelcolspansumm',
        );
        if (!colSpan) {
          colSpan = element.rows[j].cells[k].getAttribute('colspan');
        }
        if (!(colSpan > 1)) {
          colSpan = element.rows[j].cells[k].getAttribute('data-excelcolspan');
        }
        if (colSpan > 1) {
          colSpan = +colSpan;
          ws.mergeCells(cur_row, cur_col, cur_row, cur_col + colSpan - 1); // top,left,bottom,right
          cur_col = cur_col + colSpan - 1;
        }

        // объединение ячеек по вертикали
        let rowSpan =
          element.rows[j].cells[k].getAttribute('data-excelrowspan') ||
          element.rows[j].cells[k].getAttribute('rowspan');
        if (rowSpan > 1) {
          rowSpan = +rowSpan;
          row_spans[cur_col] = rowSpan - 1;
          ws.mergeCells(cur_row, cur_col, cur_row + rowSpan - 1, cur_col); // top,left,bottom,right
        }

        col_max = cur_col > col_max ? cur_col : col_max;
      }
    }

    return { cur_row: cur_row, col_max: col_max };
  },

  async getBase64FromSvg(svg, format = 'jpg') {
    const dataHeader = 'data:image/svg+xml;charset=utf-8';

    const serializeAsXML = ($e) => new XMLSerializer().serializeToString($e);
    const encodeAsUTF8 = (s) => `${dataHeader},${encodeURIComponent(s)}`;

    const loadImage = async (url) => {
      const $img = document.createElement('img');
      $img.src = url;
      return new Promise((resolve, reject) => {
        $img.onload = () => resolve($img);
        $img.onerror = reject;
      });
    };

    const svgData = encodeAsUTF8(serializeAsXML(svg));

    const img = await loadImage(svgData);

    const canvas = document.createElement('canvas');
    canvas.width = svg.clientWidth;
    canvas.height = svg.clientHeight;
    canvas
      .getContext('2d')
      .drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight);

    const dataURL = await canvas.toDataURL(`image/${format}`, 1.0);

    return dataURL;
  },

  getBorderStyle: function (elem, isHeader, isExcelNotBorder) {
    if (isExcelNotBorder) return {};

    let thickBorder = { style: 'thick', color: { argb: '000000' } };
    let thinBorder = { style: 'thin', color: { argb: '000000' } };

    let isThickBorderBottom = elem.classList.contains('border-bottom-tr');

    let border = {
      left: thinBorder,
      right: thinBorder,
    };
    if (isHeader) {
      border.top = thinBorder;
    }
    if (isThickBorderBottom) {
      border.bottom = thickBorder;
    } else {
      border.bottom = thinBorder;
    }
    return border;
  },

  getColor: function (bgColor) {
    if (!bgColor) return false;

    if (bgColor.toLowerCase() == 'red') return 'FFFF0000';
    if (bgColor.toLowerCase() == 'green') return 'FF008000';
    if (bgColor.toLowerCase() == 'yellow') return 'FFFFFF00';

    if (bgColor.indexOf('#') > -1) {
      return 'FF' + bgColor.replace('#', '');
    }
  },

  saveAsExcel: function (wb, fileName) {
    var buf;
    return regeneratorRuntime.async(
      function saveAsExcel$(context$1$0) {
        while (1)
          switch ((context$1$0.prev = context$1$0.next)) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(wb.xlsx.writeBuffer());

            case 2:
              buf = context$1$0.sent;
              saveAs(new Blob([buf]), fileName + '.xlsx');

            case 4:
            case 'end':
              return context$1$0.stop();
          }
      },
      null,
      this,
    );
  },
};

export function getColNumberAsExcelText_helper(colNum) {
  const numbersAsText = [
    '0',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H', // 0 does not exist
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  if (colNum < 27) {
    return numbersAsText[colNum];
  }

  if (colNum > 702) {
    return 'ZZ'; // 702 == 'ZZ'
  }

  let remainder = (colNum - 1) % 26;
  let firstLetNum = (colNum - 1 - remainder) / 26;
  let secondLetNum = remainder + 1;

  return numbersAsText[firstLetNum] + numbersAsText[secondLetNum];
}

export function getInnerTextFromFormatExcel_helper(formatNum) {
  formatNum = +formatNum;
  switch (formatNum) {
    case 0.5:
      return 'General';
    case 0:
      return 'General';
    case 1:
    case 1.1:
      return 'DD.MM.YYYY HH:MM:SS';
    //wsws.Cell(rnum, cnum).Style.NumberFormat.Format = 'dd.mm.yyyy hh:mm:ss';
    case 1.5:
      return 'dd.mm.yyyy hh:mm'; // delimeter 86400000 turns milliseconds into days
    //wsws.Cell(rnum, cnum).Style.NumberFormat.Format = 'dd.mm.yyyy hh:mm:ss';
    case 1.6:
      return 'dd.mm.yy hh:mm'; // delimeter 86400000 turns milliseconds into days
    //wsws.Cell(rnum, cnum).Style.NumberFormat.Format = 'dd.mm.yyyy hh:mm:ss';
    case 2:
      return 'DD.MM.YYYY';
    //wsws.Cell(rnum, cnum).Style.NumberFormat.Format = 'dd.mm.yyyy';
    case 3:
      return 'hh:mm:ss';
    //wsws.Cell(rnum, cnum).Style.NumberFormat.Format = 'hh:mm:ss';
    case 4:
      return '[hh]:mm:ss';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '[hh]:mm:ss';
    case 5:
    case 5.5:
      // return '0.0';
      return '###,###,##0.0';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0';
    case 6:
    case 6.5:
      return '0.0 %';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0 %';
    case 7:
      // return '0.00';
      return '###,###,##0.00';
    // wsws.Cell(rnum, cnum).Value = val;
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.00';
    case 8:
      return '0.00 %';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.00 %';
    case 9:
      // return '0.000';
      return '###,###,##0.000';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.000';
    case 10:
      return '0.000 %';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.000 %';
    case 11:
      return '0 %';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0 %';
    case 12:
      // return '0';
      return '###,###,##0';
    // wsws.Cell(rnum, cnum).Style.NumberFormat.Format = '0';
    case 13:
      return '###,###,##0.0';
    case 14:
      return '###,###,##0.00';
    case 15:
      return '# ##0,0###; - # ##0,0###;"-"; @';
    default:
      return 'General';
  }
}

export function addFormatAndValAttributesFromLodash_getDataValue_helper(
  formatNum,
  value,
) {
  let dataValue = '';

  if (formatNum >= 1 && formatNum <= 4) {
    if (
      formatNum == 1.5 ||
      formatNum == 1.6 ||
      formatNum == 1.1 ||
      formatNum == 1
    ) {
      value = value / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440; // 1440 = 60 * 24
    }
    dataValue = ' data-value="' + value + '"';
  }

  return dataValue;
}

export function addFormatAndValAttributesFromLodash_helper(formatNum, value) {
  let dataValue = '';
  let dataFormatNum = '';

  if (isNaN(value)) {
    return '';
  }

  if (formatNum) {
    dataFormatNum = 'data-formatnum="' + formatNum + '"';
  }
  if (formatNum >= 1 && formatNum <= 4) {
    if (
      formatNum == 1.5 ||
      formatNum == 1.6 ||
      formatNum == 1.1 ||
      formatNum == 1
    ) {
      value = value / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440; // 1440 = 60 * 24
    }
    dataValue = ' data-value="' + value + '"';
  }
  return dataFormatNum + dataValue;
}

export function valueToDisplayFormat_helper(
  value,
  format,
  isCheckTableCells,
  hName,
) {
  // if (hName == 'distEngine') {
  //   hName = hName;// for debugging
  // }
  if (isCheckTableCells || isNaN(value) || !format || value === ' ') {
    return value;
  }
  let res;
  //isCheckTableCells выводит значения из таблицы "как есть"
  switch (format) {
    case 0:
    case 0.5:
      return value;
    case 1:
      return formatDateHelper(new Date(value), 'dd.mm.yyyy hh:nn:ss');
    case 1.1:
    case 1.6:
      if (value > 0) {
        if (format == 1.1)
          return formatDateHelper(new Date(value), 'dd.mm.yyyy hh:nn:ss');
        if (format == 1.6)
          return formatDateHelper(new Date(value), 'dd.mm.yy hh:nn');
      } else {
        return value;
      }
    case 1.5:
      return formatDateHelper(new Date(value), 'dd.mm.yyyy hh:nn');
    case 2:
      return formatDateHelper(new Date(value), 'dd.mm.yyyy');
    case 3:
      return formatDateHelper(new Date(value), 'hh:nn:ss');
    case 4:
      return formatTimeHelper(value, 'tt:nn:ss');
    case 5:
      res = myRoundNumber_helper(value, 1);
      if (isNaN(res)) return value;
      return res;
    case 5.5:
      res = myRoundNumber_helper(value, 1);
      if (isNaN(res)) return value;
      return res;
    case 6:
      res = myRoundNumber_helper(value * 100, 1);
      if (isNaN(res)) return value;
      return res + ' %';
    case 7:
      return myRoundNumber_helper(value, 2);
    case 8:
      res = myRoundNumber_helper(value * 100, 2);
      if (isNaN(res)) return value;
      return res + ' %';
    case 9:
      return myRoundNumber_helper(value, 3);
    case 10:
      return myRoundNumber_helper(value * 100, 3) + ' %';
    case 11:
      return myRoundNumber_helper(value, 0) + ' %'; //число уже в процентах
    case 12:
      if (isNaN(+value)) {
        return value;
      }
      return myRoundNumber_helper(value, 0);
    case 13:
      if (isNaN(+value)) {
        return value;
      }
      return myRoundNumber_helper(value, 1);

    default:
      return value;
  }
}
