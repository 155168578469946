export function getDistanceHelper(lat, lon, lat1, lon1) {
  // kilometers
  const R = 6378.137;
  const mult = Math.PI / 180;
  const y1 = lat * mult;
  const x1 = lon * mult;
  const y2 = lat1 * mult;
  const x2 = lon1 * mult;
  const dLat = y2 - y1;
  const dLon = x2 - x1;

  const dLatSin = Math.sin(dLat / 2);
  const dLonSin = Math.sin(dLon / 2);

  const a = dLatSin * dLatSin + Math.cos(y1) * Math.cos(y2) * dLonSin * dLonSin;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export function getDistanceFromMeters(lat = 0, lon = 0, offsetLat, offsetLon) {
  //Earth’s radius, sphere
  const R = 6378137;
  const Pi = Math.PI;
  const Cos = Math.cos;

  //Coordinate offsets in radians
  const dLat = offsetLat / R;
  const dLon = offsetLon / (R * Cos((Pi * lat) / 180));

  //OffsetPosition, decimal degrees
  const latO = (dLat * 180) / Pi;
  const lonO = (dLon * 180) / Pi;

  return { lat: latO, lng: lonO };
}
