<template>
  <table class="table-simple excel-bottom-indent">
    <caption class="caption-top">
      {{
        props.title
      }}
    </caption>
    <tbody>
      <tr>
        <th
          v-for="headerName in headerSetting.headers"
          data-excelalignh="center"
          :data-minwidth="headerSetting[headerName].width || ''"
        >
          {{ headerSetting[headerName]['name'] }}
          <span
            v-if="props.clickedRows.includes(headerName)"
            :class="[{ 'link-to-popup': getSumm(headerName) > 0 }]"
            @click="
              getSumm(headerName) > 0
                ? emit('showPopup', { name: headerName })
                : () => {}
            "
            >{{ getSumm(headerName) }}</span
          >
        </th>
      </tr>
      <tr
        v-for="(violation, i) in props.object.roughlyViolations"
        :style="[{ border: prevClickRow === i ? '2px solid #000' : '' }]"
      >
        <td
          v-for="headerName in headerSetting.headers"
          data-excelalignh="center"
          :data-minwidth="headerSetting[headerName].width || ''"
          :data-formatnum="getFormat(headerName)"
        >
          <div
            v-if="
              props.clickedRows.includes(headerName) &&
              (+getValue(violation[headerName], headerName) > 0 ||
                getValue(violation[headerName], headerName) !== '0')
            "
            class="link-to-popup"
            @click="showPopup(headerName, violation.DSkill, i)"
          >
            {{ getValue(violation[headerName], headerName) }}
          </div>
          <div v-else>{{ getValue(violation[headerName], headerName) }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from '@vue/reactivity';

const props = defineProps({
  title: { type: String, required: false, default: '' },
  clickedRows: { type: Array, required: false, default: [] },
  object: { type: Object, required: true },
});

const emit = defineEmits(['showPopup']);

const prevClickRow = ref(-1);

const headerSetting = {
  DNum: {
    name: '№',
    width: 8,
  },

  DSkill: {
    name: 'Навык',
    width: 51,
    format: 0.5,
  },

  DForfeits: {
    name: 'Нарушений',
    width: 12,
    format: 12,
  },

  DForfeitsRelative: {
    name: 'Нарушений на 100км',
    width: 25,
    format: 12,
  },

  headers: ['DNum', 'DSkill', 'DForfeits', 'DForfeitsRelative'],
};

const getValue = (value, header) => {
  const { format = 0 } = headerSetting[header];
  return format ? formatToDisplay_helper(value, format) : value;
};

const getSumm = (header) => {
  return props.object.roughlyViolations.reduce((acc, obj) => {
    acc += obj[header];
    return acc;
  }, 0);
};

const getFormat = (header) => {
  const { format = 0 } = headerSetting[header];

  return format ? format : '';
};

const showPopup = (name, skill, i) => {
  prevClickRow.value = i;
  emit('showPopup', { name, skill });
};
</script>

<style lang="scss" scoped></style>
