export const leafletPnpolyAlgoritm = {
  checkGeofence: function (latlngs, point) {
    let checked = false;
    let i = -1;
    for (let latlngsItem of latlngs) {
      i++;
      checked = this.checkPolygon(latlngsItem, point);
      if (!checked && !i) {
        // первый массив - внешние границы
        return checked;
      }
      if (checked && i) {
        // последующие массивы - границы внутренней вырезки
        return !checked;
      }
      if (!checked && i) {
        // во внешней границе, но не во внутренней вырезке
        checked = !checked;
      }
    }
    return checked;
  },

  pnpoly: function (polygonPoints, testPoint) {
    // pnpoly function
    let c = 0;
    const [testy, testx] = testPoint;
    const pointsCount = polygonPoints.length;

    for (let i = 0, j = pointsCount - 1; i < pointsCount; j = i++) {
      const [verty_i, vertx_i] = polygonPoints[i];
      const [verty_j, vertx_j] = polygonPoints[j];
      if (
        verty_i > testy != verty_j > testy &&
        testx <
          ((vertx_j - vertx_i) * (testy - verty_i)) / (verty_j - verty_i) +
            vertx_i
      ) {
        c = !c;
      }
    }

    return c;
  },

  checkPolygon: function (latlngs, point) {
    const polygonPoints = latlngs.map((latlon) => {
      return [latlon.lat, latlon.lng];
    });

    return this.pnpoly(polygonPoints, point);
  },
};
