<template>
  <td
    class="text-center cursor-pointer user-select-none"
    style="padding: 5px"
  >
    <img
      style="height: 25px; width: 25px"
      src="/images/manual/delete.svg"
    />
  </td>
</template>

<script setup></script>

<style lang="scss" scoped></style>
