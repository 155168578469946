<template>
  <span
    class="heartbeat target-show"
    style="position: relative"
  >
    <input
      v-model="inputHandler"
      class="form-control-sm"
      :class="props.inputClass"
      :type="props.type"
      :placeholder="props.placeholder"
      @keydown="keyDownInputHandler"
      @keyup="keyUpInputHandler"
    />
    <spin-loader
      v-show="showFilterSpin"
      style="position: absolute; top: 5px; right: 7px"
      :width="20"
      :height="20"
    />
    <span class="target-show-span">{{ props.targetText }}</span>
  </span>
</template>

<script setup>
import { computed, ref } from 'vue';

import SpinLoader from '../../../Components/SpinLoader.vue';

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  targetText: {
    type: String,
    required: false,
    default: '',
  },
  text: {
    type: String,
    required: true,
  },
  timerHandler: {
    type: Function,
    require: false,
    default: () => {},
  },
  timerDelay: {
    type: Number,
    require: false,
    default: 500,
  },
  inputClass: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['update:text']);

const showFilterSpin = ref(false);

const inputHandler = computed({
  get() {
    return props.text;
  },
  set(value) {
    emit('update:text', value);
  },
});

let typingTimer;
const keyUpInputHandler = () => {
  clearTimeout(typingTimer);
  typingTimer = setTimeout(() => {
    showFilterSpin.value = false;
    props.timerHandler();
  }, props.timerDelay);
};
const keyDownInputHandler = (event) => {
  if (event.altKey || event.ctrlKey || event.shiftKey || event.key === 'Tab') {
    return;
  }
  showFilterSpin.value = true;
  clearTimeout(typingTimer);
};
</script>

<style></style>
