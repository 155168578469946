import { CalculateGraphData } from './CalculateGraphData.js';

export class AverageIndicator {
  charts = {};
  data;
  chartSummData;
  area;
  element;
  subElements;
  format; // ф-ия форматирования перед выводом

  // forfeitsRelativeAvgFilter = (value) => value >= 0;

  constructor(componentName, area, pointers) {
    this.componentName = componentName;
    this.create(componentName, area, pointers);
  }

  template(componentName) {
    return `<div data-component=${componentName}>
            <div data-element="info">Выполните запрос за нужный период для получения графической информации</div>
            <div data-element="chartSummInfo" class="text-center"></div>
            ${this.chartContainerTemplate(
              'chartSumm',
              'height:50vh; min-height:300px;',
            )}
            ${this.chartContainerTemplate(
              'chartDispersion',
              'height:50vh; min-height:300px;',
            )}
            <div data-element="notDistList" class="border-top"></div>
            <hr class="not-excel">
            <div data-element="ratingTable"></div>
            ${this.chartContainerTemplate(
              'distForfeitsRelativeMediana',
              'height:50vh; min-height:300px;',
            )}
            ${this.chartContainerTemplate(
              'perfectObjectsChart',
              'height:50vh; min-height:300px;',
            )}
            ${this.chartContainerTemplate(
              'goodObjectsChart',
              'height:50vh; min-height:300px;',
            )}
            ${this.chartContainerTemplate(
              'badObjectsChart',
              'height:50vh; min-height:300px;',
            )}
            ${this.chartContainerTemplate(
              'veryBadObjectsChart',
              'height:50vh; min-height:300px;',
            )}
            <div data-element="chartSummObjectList" class="text-center"></div>
        </div>`;
  }

  // notDistListTemplate (objectList) {
  //     const list = this.getObjectsNames(objectList);

  //     return `<strong>Объекты, исключенные из рейтинга из-за малого пробега:</strong> ${list}`;
  // }

  chartContainerTemplate(chartName, style = '') {
    this.charts[chartName] = null;
    const styleHtml = style ? ` style="${style}"` : '';

    return `<div class="chart-container"${styleHtml}>
            <canvas data-element="${chartName}"></canvas>
        </div>`;
  }

  async create(componentName, area, pointers) {
    const pointer = [...pointers].find((element) => {
      const elementComponentName = element.dataset.component;

      if (elementComponentName) {
        return Boolean(elementComponentName === componentName);
      }

      const dataElement =
        dataElement.dataset.component ||
        element.querySelector('[data-component]');

      if (dataElement && dataElement.dataset.component === componentName) {
        return true;
      }
    });

    if (pointer) {
      this.pointer = pointer;
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = this.template(componentName);
    this.componentName = componentName;

    const element = wrapper.firstChild;

    const calculateGraphData = new CalculateGraphData();

    this.element = element;
    this.subElements = calculateGraphData.getSubElements(this.element);

    // doubleScroll_helper(this.subElements.ratingTable);

    if (area) {
      this.show(area);
    }
  }

  show(area) {
    if (!this.pointer.classList.contains('active')) {
      return;
    }

    const areaChild = area.firstChild;

    if (areaChild) {
      areaChild.remove();
    }

    area.append(this.element);
  }

  async writeInfo(innerHtml) {
    this.subElements.info.innerHTML = innerHtml;
  }

  async draw({
    area = null,
    objectsCalculated = {},
    objectsList = [],
    objectsDisableList = [],
    componentName,
  } = {}) {
    const { chartSummInfo, chartSummObjectList, ratingTable, notDistList } =
      this.subElements;

    const { objects = [], format, rows_summ } = objectsCalculated;

    this.format = format;

    if (!objectsList.length) {
      this.writeInfo(
        '<span style="color:red">Для построения графика не выбран ни один объект.<span>',
      );
      return;
    }

    if (!objects.length) {
      this.writeInfo(
        '<span style="color:red">Получены пустые данные. Попробуйте проверить параметры и повторить запрос или обновить страницу.<span>',
      );
      return;
    }

    this.writeInfo('Данные получены. Анализирую...');

    const calculateGraphData = new CalculateGraphData({ format });

    const [firstObject = {}] = objects;
    const { t_interval } = firstObject.view;

    const { objectsData, isSplitSmenas, smenasStr } =
      calculateGraphData.getObjectsData(objects, rows_summ);

    const periodsLabels = calculateGraphData.getPeriodsLabels(
      firstObject,
      'Нарушения по дням эксплуатации',
    );

    const splitObjects = isSplitSmenas
      ? calculateGraphData.splitObjectsBySmenas({
          objectsData,
          isSplitSmenas,
          smenasStr,
        })
      : objectsData;

    const chartSummData = calculateGraphData.chartSummAnalysis(
      splitObjects,
      periodsLabels,
    );

    this.chartSummData = chartSummData;
    this.data = {
      objectsData,
      periodsLabels,
    };

    // ratingTable
    const ratingTableId = `section-graphics-content-${this.componentName}-ratingTable`;
    const ratingTableValues =
      calculateGraphData.ratingTablePrepareObjects(chartSummData);
    ratingTable.innerHTML = calculateGraphData.getRatingTableTemplate({
      ratingTableValues,
      ratingTableId,
      t_interval,
    });
    notDistList.innerHTML = calculateGraphData.notDistListTemplate(
      chartSummData['summData']['objectsRaiting']['notDist'],
    );

    this.graphsDestroy();
    this.graphsCreate(calculateGraphData);

    this.writeInfo('');
    chartSummInfo.innerHTML = periodsLabels.title;
    chartSummObjectList.innerHTML = calculateGraphData.objectListTemplate(
      objectsList,
      objectsDisableList,
    );
  }

  graphsDestroy() {
    for (const [key, chart] of Object.entries(this.charts)) {
      if (chart && chart.canvas) {
        chart.destroy();
      }
    }
  }

  graphsCreate(calculateGraphData) {
    const {
      info,
      chartSumm,
      chartDispersion,
      distForfeitsRelativeMediana,
      perfectObjectsChart,
      goodObjectsChart,
      badObjectsChart,
      veryBadObjectsChart,
      chartSummInfo,
      chartSummObjectList,
    } = this.subElements;
    const { labels, summData } = this.chartSummData;

    // myLineChart.resize();
    // myLineChart.toBase64Image();
    // .generateLegend()
    // myLineChart.getElementAtEvent(e);

    // line, bar, radar, polarArea, pie, doughnut, and bubble

    // data: data,
    // options: {
    //     legend: {
    //     display: true,
    //     position: 'bottom',
    //     labels: {
    //         boxWidth: 80,
    //         fontColor: 'rgb(60, 180, 100)'
    //     }
    //     }
    // }

    // intersect

    // var chartOptions = {
    //     legend: {
    //       display: true,
    //       position: 'top',
    //       labels: {
    //         boxWidth: 80,
    //         fontColor: 'black'
    //       }
    //     },
    //     scales: {
    //       x: {
    //         gridLines: {
    //           display: false,
    //           color: "black"
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Time in Seconds",
    //           fontColor: "red"
    //         }
    //       },
    //       y: {
    //         gridLines: {
    //           color: "black",
    //           borderDash: [2, 5],
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Speed in Miles per Hour",
    //           fontColor: "green"
    //         }
    //       }
    //     }
    //   };

    // offsetGridLines: true это сдвигает надписи к середине линий сетки

    // радиально-линейной диаграммы - показать распределение по нарушениям

    // временная шкала по оси X
    // var chartOptions = {
    //     legend: {
    //       display: true,
    //       position: 'top',
    //       labels: {
    //         boxWidth: 80,
    //         fontColor: 'black'
    //       }
    //     },
    //     scales: {
    //       x: {
    //         type: "time",
    //         time: {
    //           unit: 'hour',
    //           unitStepSize: 0.5,
    //           round: 'hour',
    //           tooltipFormat: "h:mm:ss a",
    //           displayFormats: {
    //             hour: 'MMM D, h:mm A'
    //           }
    //         }
    //       },
    //       y: {
    //         gridLines: {
    //           color: "black",
    //           borderDash: [2, 5],
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Speed in Miles per Hour",
    //           fontColor: "green"
    //         }
    //       }
    //     }
    //   };
    this.charts.chartSumm = new Chart(chartSumm, {
      // The type of chart we want to create
      type: 'line',

      // The data for our dataset
      data: {
        labels: labels,
        datasets: [
          // {
          //     label: 'Нарушений всего',
          //     backgroundColor: 'rgba(255, 99, 132, 0.7)',
          //     borderColor: 'rgb(255, 99, 132)',
          //     borderWidth: 1,
          //     fill: false,
          //     data: summData.forfeits
          // },
          {
            label: 'Нарушений на 100 км пробега среднее по выборке (медиана)',
            backgroundColor: 'rgb(0,148,255)',
            borderColor: 'rgb(0,148,255,0.7)',
            borderWidth: 1,
            data: summData.distForfeitsRelativeMedianasOfPeriods,
            type: 'line',
            tension: 0.3,
            fill: false,
          },
          {
            label:
              'Нарушений на 100 км пробега среднее арифметическое по выборке выше медианы',
            backgroundColor: 'rgb(255,110,0, 0.7)',
            borderColor: 'rgb(255,110,0)',
            borderWidth: 1,
            data: summData.distForfeitsRelativeArithmeticAveragesAboveMedianOfPeriods,
            type: 'line',
            tension: 0.3,
            fill: false,
          },
          {
            label:
              'Нарушений на 100 км пробега среднее арифметическое по выборке ниже медианы',
            backgroundColor: 'rgb(68,255,0, 0.7)',
            borderColor: 'rgb(68,255,0)',
            borderWidth: 1,
            data: summData.distForfeitsRelativeArithmeticAveragesBelowMedianOfPeriods,
            type: 'line',
            tension: 0.3,
            fill: false,
          },
          // {
          //     label: 'Нарушений на 100 км пробега среднее арифметическое по выборке',
          //     backgroundColor: 'rgb(0,0,122)',
          //     borderColor: 'rgb(0,0,122,0.7)',
          //     borderWidth: 1,
          //     data: summData.distForfeitsRelativeArithmeticAveragesOfPeriods,
          //     type: 'line',
          //     fill: false
          // },
          {
            label: 'Нарушений на 100 км пробега по парку в сумме',
            backgroundColor: 'rgb(255, 99, 132, 0.7)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            data: summData.distForfeitsRelative,
            type: 'line',
            tension: 0.3,
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Нарушений на 100 км в разрезе смен: по парку в сумме и медиана',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              // fontSize: 6
              font: {
                size: 6,
              },
            },
            offsetGridLines: true,
          },
          y: {
            scaleLabel: {
              display: true,
              labelString: 'Нарушений на 100 км',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        },
      },
    });

    // Chart.defaults.global.defaultFontSize = 9;

    this.charts.chartDispersion = new Chart(chartDispersion, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Нарушений на 100 км пробега минимум за смену',
            backgroundColor: 'rgb(0,255,0,0.6)',
            borderColor: 'rgb(0,255,0)',
            borderWidth: 1,
            data: summData.distForfeitsRelativeMinimumOfPeriods.map((value) =>
              myRoundNumber_helper(value, 2),
            ),
            type: 'line',
            fill: '+1',
            tension: 0.3,
          },
          {
            label: 'Нарушений на 100 км пробега среднее за смену (медиана)',
            backgroundColor: 'rgb(255,119,0,0.6)',
            borderColor: 'rgb(255,119,0)',
            borderWidth: 3,
            data: summData.distForfeitsRelativeMedianasOfPeriods.map((value) =>
              myRoundNumber_helper(value, 2),
            ),
            type: 'line',
            tension: 0.3,
            fill: '+1',
          },
          {
            label: 'Нарушений на 100 км пробега максимум за смену',
            backgroundColor: 'rgb(255,0,0, 0.6)',
            borderColor: 'rgb(255,0,0)',
            borderWidth: 1,
            data: summData.distForfeitsRelativeMaximumOfPeriods.map((value) =>
              myRoundNumber_helper(value, 2),
            ),
            type: 'line',
            tension: 0.3,
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Нарушений на 100 км по парку в разрезе смен: минимум, максимум, среднее',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            offsetGridLines: true,
            ticks: {
              // fontSize: 6
              font: {
                size: 6,
              },
            },
          },
          y: {
            scaleLabel: {
              display: true,
              labelString: 'Нарушений на 100 км',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        },
        // tooltips: {
        //     callbacks: {
        //         afterLabel: function(tooltipItem, data) {
        //             let label = data.datasets[tooltipItem.datasetIndex].label || '';

        //             label += `ДОБАВИЛ`;

        //             return label;
        //         }
        //     }
        // },
      },
    });

    // const isDistForfeitsRelativeOfPeriods = summData.summOfPeriods.reduce((accum, period) {
    //     // const isDistForfeitsRelativeOfPeriod = period.distForfeitsRelative.map(distForfeitsRelative => Boolean(distForfeitsRelative !== -1));

    //     period.distForfeitsRelative.forEach((distForfeitsRelative, objIndex) => {
    //         Boolean(distForfeitsRelative !== -1)
    //     );

    // }, []);

    // const notDistObjectIndexes = summData.summOfPeriods[0].reduce((accum, ) {
    //     distForfeitsRelative
    // })

    const objIndexDismiss = summData['summOfObjects'].reduce(
      (accum, values, objIndex) => {
        if (!values.isDistForfeitsRelativeOfPeriods) {
          accum.push(objIndex);
        }

        return accum;
      },
      [],
    );

    const distForfeitsRelativeMedianaBubbleData = this.preparingBubbleGraph(
      summData.distForfeitsRelativeMedianaData,
      objIndexDismiss,
      {
        xName: 'dist',
        yName: 'relativeMedianAvgPercent',
        rName: 'distForfeitsRelative',
        labelName: 'avtoNo',
        maxPixels: 20,
        minPixels: 4,
        rValueText: 'баллов на 100км',
      },
    );

    const lineWidth = 2;
    const axesColor = 'rgba(0, 0, 0, 0.7)';

    this.charts.distForfeitsRelativeMediana = new Chart(
      distForfeitsRelativeMediana,
      {
        type: 'bubble',
        data: {
          datasets: distForfeitsRelativeMedianaBubbleData.datasets,
        },
        options: {
          plugins: {
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  let label =
                    data.datasets[tooltipItem.datasetIndex].label || '';
                  return label;
                },
                afterLabel: (tooltipItem, data) => {
                  // let label = data.datasets[tooltipItem.datasetIndex].label || '';
                  const yLabel = Math.round(tooltipItem.yLabel * 100) / 100;
                  const xLabel = Math.round(tooltipItem.xLabel * 100) / 100;

                  const grade =
                    calculateGraphData.getDistForfeitsRelativeMedianaGrade(
                      yLabel,
                    );

                  return `пробег: ${xLabel} км; средний % отклонения от медианы: ${yLabel} (${grade})`;
                },
              },
            },
            title: {
              display: true,
              text: 'Среднее посменное отклонение каждого объекта от медианы (размер окружности - это нарушений на 100 км за период)',
            },
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              offsetGridLines: true,
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Пробег объекта за период, км',
              },
              gridLines: {
                zeroLineColor: axesColor,
                zeroLineWidth: lineWidth,
              },
            },
            y: {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Среднее посменное отклонение от медианы, %',
                // fontSize: 10
                font: {
                  size: 10,
                },
              },
              gridLines: {
                zeroLineColor: axesColor,
                zeroLineWidth: lineWidth,
              },
            },
          },
        },
      },
    );

    // allObjects.forEach(objectList => {
    // });

    const { perfectObjects, goodObjects, badObjects, veryBadObjects, notDist } =
      summData['objectsRaiting'];

    // notDistList.innerHTML = this.notDistListTemplate(notDist);

    // const perfectObjects = allObjects.filter(object => object.values.distForfeitsRelative > -1 && object.values.distForfeitsRelativeMedianaVerification.avgPercent < perfectMedianaAvgPercent);
    // const goodObjects = allObjects.filter(object => object.values.distForfeitsRelative > -1 && object.values.distForfeitsRelativeMedianaVerification.avgPercent < goodMedianaAvgPercent && object.values.distForfeitsRelativeMedianaVerification.avgPercent >= perfectMedianaAvgPercent);
    // const badObjects = allObjects.filter(object => object.values.distForfeitsRelative > -1 && object.values.distForfeitsRelativeMedianaVerification.avgPercent < badMedianaAvgPercent && object.values.distForfeitsRelativeMedianaVerification.avgPercent >= goodMedianaAvgPercent);
    // const veryBadObjects = allObjects.filter(object => object.values.distForfeitsRelative > -1 && object.values.distForfeitsRelativeMedianaVerification.avgPercent >= badMedianaAvgPercent);
    // const notDistForfeitsRelativeList = allObjects.filter(object => object.values.distForfeitsRelative === -1);

    this.charts.perfectObjectsChart = this.createChart({
      labels,
      titleText: 'Отличная эксплуатация',
      chartElement: perfectObjectsChart,
      objects: perfectObjects,
      type: 'line',
      periods: summData['summOfPeriods'],
      color: { r: 0, g: 255, b: 0, opasity: 1 },
      // datasets: this.getObjectsDatasets({
      //     objects: perfectObjects,
      //     type: 'line',
      //     periods: summData['summOfPeriods'],
      //     color: {r: 0, g: 255, b: 0, opasity: 1}
      // })
    });

    this.charts.goodObjectsChart = this.createChart({
      labels,
      titleText: 'Хорошая эксплуатация',
      chartElement: goodObjectsChart,
      objects: goodObjects,
      type: 'line',
      periods: summData['summOfPeriods'],
      color: { r: 150, g: 255, b: 0, opasity: 1 },
      // datasets: this.getObjectsDatasets({
      //     objects: goodObjects,
      //     type: 'line',
      //     periods: summData['summOfPeriods'],
      //     color: {r: 150, g: 255, b: 0, opasity: 1}
      // })
    });

    this.charts.badObjectsChart = this.createChart({
      labels,
      titleText: 'Плохая эксплуатация',
      chartElement: badObjectsChart,
      objects: badObjects,
      type: 'line',
      periods: summData['summOfPeriods'],
      color: { r: 255, g: 150, b: 0, opasity: 1 },
      // datasets: this.getObjectsDatasets({
      //     objects: badObjects,
      //     type: 'line',
      //     periods: summData['summOfPeriods'],
      //     color: {r: 255, g: 150, b: 0, opasity: 1}
      // })
    });

    this.charts.veryBadObjectsChart = this.createChart({
      labels,
      titleText: 'Очень плохая эксплуатация',
      chartElement: veryBadObjectsChart,
      objects: veryBadObjects,
      type: 'line',
      periods: summData['summOfPeriods'],
      color: { r: 255, g: 0, b: 0, opasity: 1 },
    });
  }

  // getDistForfeitsRelativeMedianaGrade (percent) {
  //     if (percent < perfectMedianaAvgPercent) {
  //         return 'отлично';
  //     }

  //     if (percent < goodMedianaAvgPercent) {
  //         return 'хорошо';
  //     }

  //     if (percent < badMedianaAvgPercent) {
  //         return 'плохо';
  //     }

  //     return 'очень плохо!';
  // }

  createChart({
    labels,
    chartElement,
    titleText,
    objects,
    type,
    periods,
    color,
    spanGaps = true,
  }) {
    const lineWidth = 2;
    const axesColor = 'rgba(0, 0, 0, 0.7)';

    const datasets = this.getObjectsDatasets({
      objects,
      type,
      periods,
      color,
    });

    return new Chart(chartElement, {
      data: {
        labels,
        datasets,
      },
      options: {
        spanGaps, // соединять точки через null линией (true) или нет (undefined)
        plugins: {
          title: {
            display: true,
            text: titleText,
            padding: 3,
          },
          legend: {
            // align: 'start',
            labels: {
              // fontSize: 9,
              font: {
                size: 6,
              },
              boxWidth: 20,
              padding: 3,
            },
          },
        },
        // title: {
        //     display: true,
        //     text: titleText,
        //     padding: 3
        // },

        // tooltips: {
        //     mode: 'index',
        //     intersect: false
        // },
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              // fontSize: 6
              font: {
                size: 6,
              },
            },
            offsetGridLines: true,
            gridLines: {
              zeroLineColor: axesColor,
              zeroLineWidth: lineWidth,
            },
          },
          y: {
            scaleLabel: {
              display: true,
              labelString: 'Нарушений на 100 км',
            },
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              zeroLineColor: axesColor,
              zeroLineWidth: lineWidth,
            },
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const { datasetIndex, index, yLabel } = tooltipItem;

              const { label = '?', distArr = '?' } =
                data.datasets[datasetIndex];
              const dist = this.format(distArr[index], 5);
              // const yValue = Math.round(yLabel * 100) / 100;

              return `${label}: пробег ${dist} (км), нарушений ${yLabel} (на 100 км)`;
            },
            // afterLabel: (tooltipItem, data) => {
            //     // let label = data.datasets[tooltipItem.datasetIndex].label || '';
            //     const yLabel = Math.round(tooltipItem.yLabel * 100) / 100;
            //     const xLabel = Math.round(tooltipItem.xLabel * 100) / 100;

            //     const grade = calculateGraphData.getDistForfeitsRelativeMedianaGrade(yLabel);

            //     return `пробег: ${xLabel} км; средний % отклонения от медианы: ${yLabel} (${grade})`;
            // }
          },
        },
      },
    });
  }

  getObjectsDatasets({ objects, periods, type, color } = {}) {
    if (!objects.length) {
      return [];
    }

    const colorObject = { color };

    return objects.map((object) => {
      const { objValues, objIndex } = object;
      const label = replaceNbsp_helper(objValues.avtoNo);

      const distArr = periods.map((period) => period.dist[objIndex]);

      const data = periods.map((period, periodIndex) => {
        if (distArr[periodIndex] === null) {
          return null;
        }

        const distForfeitsRelative = period.distForfeitsRelative[objIndex];
        return distForfeitsRelative < 0 ? 0 : distForfeitsRelative;
      });

      colorHexShiftObject_helper(colorObject);

      return {
        type,
        tension: 0.3,
        label,
        data,
        distArr,
        borderColor: colorObject.text,
        backgroundColor: colorObject.text,
        borderWidth: 1,
        fill: false,
      };
    });
  }

  // getObjectsNames (objectsList = []) {
  //     if (!objectsList.length) {
  //         return 'отсутствуют';
  //     }

  //     const objectsNames = objectsList.reduce((accum, object, index) => {
  //         const {objValues} = object;
  //         const {name = '?', stateNumber, avtoNo} = objValues || object;
  //         const avtoStateNumber = stateNumber || avtoNo || '?';

  //         // accum[index] = (`${name}(${stateNumber})`);
  //         accum[index] = (`${avtoStateNumber}`);

  //         return accum;
  //     }, []);

  //     return objectsNames.join('; ');
  // }

  // objectListTemplate (objectsList = [], objectsDisableList = []) {
  //     const objectsNames = this.getObjectsNames(objectsList);
  //     const objectsDisableNames = this.getObjectsNames(objectsDisableList);

  //     return `
  //         <p class="text-left margin-none" style="font-size: xx-small">
  //           <span style="color:green">Объекты, включенные в запрос:</span> ${objectsNames}<br>
  //           <span style="color:red">Объекты, исключенные из запроса:</span> ${objectsDisableNames}
  //         </p>`;
  // }

  // chartSummAnalysis (objectsData, periodsLabels) {
  //     // для графика по сумме нарушений
  //     const {periods = [], labels = []} = periodsLabels;

  //     const indicators = [
  //         'dist',
  //         'forfeits',
  //         'moto',
  //         'motoNoSpd',
  //         'motoSpd'
  //     ];

  //     if (! periods.length || ! labels.length) {
  //         return {
  //             labels: [],
  //             summData: indicators.reduce((accum, indicator) => {
  //                 accum[indicator] = [];
  //                 return accum;
  //             }, {distForfeitsRelative: []})
  //         };
  //     }

  //     // сумма по всем объектам по каждому периоду
  //     const summData = objectsData.reduce((accum, objectData, objIndex) => {

  //         // accum.summ = objectData.summ;

  //         if (! (indicators[0] in accum)) {
  //             // подготовим массив периодов с нулевыми значениями по каждому из индикаторов
  //             accum['summOfObjects'] = [];
  //             accum['summOfPeriods'] = [];
  //             accum['objectsName'] = [];

  //             indicators.forEach(indicator => {
  //                 accum[indicator] = [];
  //                 periods.forEach((period, index) => {
  //                     accum[indicator][index] = 0;

  //                     accum['summOfPeriods'][index] = {
  //                         dist: [],
  //                         forfeits: [],
  //                         distForfeitsRelative: [],
  //                         moto: [],
  //                         motoSpd: [],
  //                         motoNoSpd: [],
  //                         name: []
  //                     };
  //                 });
  //             });
  //         }

  //         const {name, gearboxType, gearboxCnt, avtoModel, avtoNo} = objectData;

  //         accum['objectsName'][objIndex] = name;

  //         accum['summOfObjects'][objIndex] = {
  //             rows_experiment: objectData.rows_experiment,
  //             rows_summ_line: objectData.rows_summ_line,
  //             objIndex,
  //             name,
  //             gearboxType,
  //             gearboxCnt,
  //             avtoModel,
  //             avtoNo,
  //             distForfeitsRelative: 0,
  //             medianaVerification: [], // массив периодов с данными для сверки с медианой
  //             medianaVerificationAvg: null // среднее значение
  //         };

  //         indicators.forEach(indicator => {
  //             accum['summOfObjects'][objIndex][indicator] = 0;
  //         });

  //         accum['summOfObjects'][objIndex]['isDistForfeitsRelativeOfPeriods'] = false;

  //         const {periods: objectPeriods} = objectData;

  //         objectPeriods.forEach((objectPeriod, periodIndex) => {
  //             if (periods[periodIndex]) {
  //                 const {TimeBegin, TimeEnd} = objectPeriod;
  //                 const {TimeBegin: TimeBeginPeriod, TimeEnd: TimeEndPeriod} = periods[periodIndex];

  //                 if (TimeBegin - TimeBeginPeriod === 0 && TimeEnd - TimeEndPeriod === 0) {
  //                     const {moto = 0, motoNoSpd = 0} = objectPeriod;
  //                     const motoSpd = moto - motoNoSpd;

  //                     indicators.forEach(indicator => {
  //                         if (indicator === 'motoSpd') {
  //                             accum[indicator][periodIndex] += motoSpd;
  //                             accum['summOfObjects'][objIndex][indicator] += motoSpd;
  //                             accum['summOfPeriods'][periodIndex][indicator][objIndex] = motoSpd;
  //                         } else {
  //                             const objectPeriodIndicatorValue = objectPeriod[indicator];

  //                             accum[indicator][periodIndex] += objectPeriodIndicatorValue;
  //                             accum['summOfObjects'][objIndex][indicator] += objectPeriodIndicatorValue;
  //                             accum['summOfPeriods'][periodIndex][indicator][objIndex] = objectPeriodIndicatorValue;
  //                         }
  //                     });
  //                     const distForfeitsRelativeOfPeriod = this.getDistForfeitsRelative(objectPeriod.dist, objectPeriod.forfeits);

  //                     if (distForfeitsRelativeOfPeriod !== -1) {
  //                         accum['summOfObjects'][objIndex]['isDistForfeitsRelativeOfPeriods'] = true;
  //                     }

  //                     accum['summOfPeriods'][periodIndex]['distForfeitsRelative'][objIndex] = distForfeitsRelativeOfPeriod;

  //                 } else {
  //                     console.error('в chartSummAnalysis неверное время периода');
  //                 }

  //             } else {
  //                 console.error('в chartSummAnalysis неверный индекс периода');
  //             }

  //         });

  //         return accum;
  //     }, {});

  //     summData['summOfObjects'].forEach((summOfObject) => {
  //         // по одному объекту
  //         const {dist, forfeits} = summOfObject;
  //         summOfObject.distForfeitsRelative = this.getDistForfeitsRelative(dist, forfeits);
  //         summOfObject.distForfeitsRelativeMedianaVerification = {
  //             periods: [],
  //             avgPercent: 0
  //         };
  //     });

  //     summData.distForfeitsRelative = [];
  //     summData.distForfeitsRelativeMedianAverage = [];
  //     summData.distForfeitsRelativeArithmeticAverage = [];

  //     summData.distForfeitsRelativeOfObjects = [];
  //     summData.distForfeitsRelativeMedianAverageOfObjects = [];
  //     summData.distForfeitsRelativeArithmeticAverageOfObjects = [];

  //     summData.forfeits.forEach((forfeit, index) => {
  //         const dist = summData.dist[index] || 0;

  //         const distForfeitsRelative = this.getDistForfeitsRelative(dist, forfeit);
  //         summData.distForfeitsRelative.push((distForfeitsRelative > 0) ? distForfeitsRelative : 0);
  //     });

  //     const distForfeitsRelativeMedianAverage = medianAverage_helper(summData.distForfeitsRelative, null, this.forfeitsRelativeAvgFilter);
  //     const distForfeitsRelativeArithmeticAverage = arithmeticAverage_helper(summData.distForfeitsRelative.filter(this.forfeitsRelativeAvgFilter));

  //     summData.forfeits.forEach((forfeit, index) => {
  //         summData.distForfeitsRelativeMedianAverage[index] = distForfeitsRelativeMedianAverage;
  //         summData.distForfeitsRelativeArithmeticAverage[index] = distForfeitsRelativeArithmeticAverage;
  //     });

  //     summData.distForfeitsRelativeMedianasOfPeriods = [];
  //     summData.distForfeitsRelativeMinimumOfPeriods = [];
  //     summData.distForfeitsRelativeMaximumOfPeriods = [];
  //     summData.distForfeitsRelativeArithmeticAveragesOfPeriods = [];

  //     // среднее по парку в разрезе суток
  //     summData['summOfPeriods'].forEach((summOfPeriod, periodIndex) => {
  //         const minMax = {min: 0, max: 0};
  //         const distForfeitsRelativeMediana = medianAverage_helper(summOfPeriod['distForfeitsRelative'], minMax, this.forfeitsRelativeAvgFilter);
  //         const distForfeitsRelativeArithmeticAverage = arithmeticAverage_helper(summOfPeriod['distForfeitsRelative'].filter(this.forfeitsRelativeAvgFilter));

  //         summData.distForfeitsRelativeMedianasOfPeriods[periodIndex] = distForfeitsRelativeMediana;
  //         summData.distForfeitsRelativeArithmeticAveragesOfPeriods[periodIndex] = roundNumber_helper(distForfeitsRelativeArithmeticAverage, 2);

  //         summData.distForfeitsRelativeMinimumOfPeriods[periodIndex] = minMax.min;
  //         summData.distForfeitsRelativeMaximumOfPeriods[periodIndex] = minMax.max;

  //     });

  //     // distForfeitsRelativeMedianaVerification

  //     // по каждому объекту за каждый период сверка с медианой по парку за период
  //     summData['summOfObjects'].forEach((summOfObject, objIndex) => {
  //         // по одному объекту
  //         const forAvg = {
  //             count: 0,
  //             summ: 0
  //         }

  //         summData['summOfPeriods'].forEach((summOfPeriod, periodIndex) => {
  //             const distForfeitsRelative = summOfPeriod['distForfeitsRelative'][objIndex];

  //             if (distForfeitsRelative >= 0) {
  //                 const distForfeitsRelativeMediana = summData['distForfeitsRelativeMedianasOfPeriods'][periodIndex];
  //                 const diff = distForfeitsRelative - distForfeitsRelativeMediana;
  //                 const percent = 100 * diff / distForfeitsRelativeMediana;

  //                 summOfObject['distForfeitsRelativeMedianaVerification']['periods'][periodIndex] = myRoundNumber_helper(percent, 2);

  //                 forAvg.summ += percent;
  //                 forAvg.count++;
  //             }
  //         });

  //         summOfObject['distForfeitsRelativeMedianaVerification']['avgPercent'] = (forAvg.count) ? forAvg.summ / forAvg.count : null;
  //     });

  //     // подготовим bubble диаграмму превышение медианы / пробег
  //     // const minMaxSummDistOfObjects = getMinMaxOfArray_helper(summData['dist']);
  //     // prepareBubbleData()

  //     summData.distForfeitsRelativeMedianaData = summData['summOfObjects'].reduce((accum, summOfObject) => {
  //         const {dist = 0, avtoNo = '-', forfeits, distForfeitsRelative} = summOfObject;
  //         const relativeMedianAvgPercent = summOfObject['distForfeitsRelativeMedianaVerification']['avgPercent'];

  //         accum.push({dist, avtoNo, forfeits, distForfeitsRelative, relativeMedianAvgPercent});

  //         return accum;
  //     }, []);

  //     summData.objectsRaiting = this.getObjectsRating(summData['summOfObjects']);

  //     // const datetimeSummData = summData.forfeits.reduce((accum, y, index) => {
  //     //     // const {TimeBegin: x, TimeEnd: TimeEndPeriod} = periods[index];
  //     //     const {TimeBegin: x} = periods[index];

  //     //     accum.push({x, y});
  //     //     return accum;
  //     // }, []);

  //     // colorHexShift_helper

  //     return {
  //         summData,
  //         // datetimeSummData,
  //         labels
  //     };

  // }

  // getObjectsRating (summOfObjects) {
  //     const objectsRaiting = summOfObjects.reduce((accum, objValues, objIndex) => {
  //         // return {values, objIndex}
  //         let listName;

  //         if (! objValues.isDistForfeitsRelativeOfPeriods) {
  //             listName = 'notDist';
  //         } else if(objValues.distForfeitsRelativeMedianaVerification.avgPercent < perfectMedianaAvgPercent) {
  //             listName = 'perfectObjects';
  //         } else if(objValues.distForfeitsRelativeMedianaVerification.avgPercent < goodMedianaAvgPercent) {
  //             listName = 'goodObjects';
  //         } else if(objValues.distForfeitsRelativeMedianaVerification.avgPercent < badMedianaAvgPercent) {
  //             listName = 'badObjects';
  //         } else {
  //             listName = 'veryBadObjects';
  //         }

  //         accum[listName].push({objValues, objIndex});

  //         return accum;
  //     }, {
  //         perfectObjects: [],
  //         goodObjects: [],
  //         badObjects: [],
  //         veryBadObjects: [],
  //         notDist: []
  //     });

  //     for (let listName in objectsRaiting) {
  //         objectsRaiting[listName].sort((a, b) => a.objValues.distForfeitsRelativeMedianaVerification.avgPercent - b.objValues.distForfeitsRelativeMedianaVerification.avgPercent);
  //     }

  //     return objectsRaiting;
  // }

  preparingBubbleGraph(
    arr,
    indexDismissArr,
    { xName, yName, rName, rValueText, labelName, maxPixels, minPixels } = {},
  ) {
    const { minVal, maxVal } = arr.reduce((rMinMax, item) => {
      const r = item[rName];

      if ('minVal' in rMinMax) {
        const { minVal, maxVal } = rMinMax;

        if (minVal > r) {
          rMinMax.minVal = r;
        }

        if (maxVal < r) {
          rMinMax.maxVal = r;
        }
      } else {
        rMinMax.minVal = r;
        rMinMax.maxVal = r;
      }

      return rMinMax;
    }, {});

    const intervalVal = maxVal - minVal;
    const intervalPixels = Math.abs(maxPixels - minPixels);

    const backgroundColorObject = {
      r: 65,
      g: 105,
      b: 255,
    };

    const opacity = 0.7;

    return arr.reduce(
      (accum, item, index) => {
        if (indexDismissArr.includes(index)) {
          return accum;
        }

        const x = myRoundNumber_helper(item[xName], 2);
        const y = myRoundNumber_helper(item[yName], 2);
        const itemLabelName = replaceNbsp_helper(item[labelName]);
        const rValue = myRoundNumber_helper(item[rName], 2);

        const r = intervalVal
          ? ((rValue - minVal) / intervalVal) * intervalPixels + minPixels
          : maxPixels;

        const label = `${itemLabelName}: ${rValueText}: ${rValue}`;

        const {
          r: rColor,
          g: gColor,
          b: bColor,
        } = colorHexShift_helper(backgroundColorObject);
        backgroundColorObject.r = rColor;
        backgroundColorObject.g = gColor;
        backgroundColorObject.b = bColor;

        const backgroundColor = `rgba(${rColor},${gColor},${bColor},${opacity})`;

        accum.labels.push(label);
        accum.datasets.push({
          label: label,
          title: 'title-text',
          data: [{ x, y, r, name }],
          backgroundColor,
          tension: 0.3,
        });

        return accum;
      },
      { datasets: [], labels: [] },
    );
  }

  // getDistForfeitsRelative (dist, forfeit) {
  //     return (dist > minDistanceFromForfeitsRelative) ? Math.round(100 * forfeit / (dist / 100)) / 100 : -1;
  // }

  getSummForViolation(objects, periodIndex) {
    // суммируем все нарушения по периоду
    rows_person.personForDisplay.forEach((group) => {
      group.forEach((arrName) => {
        if (!arrName.toLowerCase().includes('group', 0)) {
          // это НЕ название группировки
          const prevSmenaOfArrName = curSmena.rowsPerson[arrName] || {};

          const { DCount, DForfeits } = rows_person.person[arrName];
          const { DCount: DCountPrev = 0, DForfeits: DForfeitsPrev = 0 } =
            prevSmenaOfArrName;

          if (!rows_person.person[arrName]['periods']) {
            rows_person.person[arrName]['periods'] = [];
          }

          rows_person.person[arrName]['periods'][periodNum] = {
            DCount: DCount - DCountPrev,
            DForfeits: DForfeits - DForfeitsPrev,
          };

          curSmena.rowsPerson[arrName] = { DCount, DForfeits };
        }
      });
    });
  }

  removeChartData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    chart.update();
  }
}
