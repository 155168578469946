<template>
  <div
    id="logout-window-confirm"
    :style="[{ display: props.show ? 'block' : 'none' }]"
  >
    <div
      v-if="isRequest"
      style="margin-top: 30%"
    >
      <preloader />
    </div>
    <template v-else>
      <!-- Картинка крестика-->
      <img
        class="close"
        src="/images/close.png"
        @click="emit('close')"
      />

      <div class="full-height text-center">
        <p>
          <span class="login-text">{{ props.login }}</span> при выходе из
          аккаунта будут забыты все треки и отчеты. Для дальнейшего
          использование системы потребуется ввести логин и пароль.
        </p>
        <a
          class="btn btn-primary config-btn-primary btn-lg"
          @click="logoutHandler"
        >
          Выйти из аккаунта
        </a>
        <div
          v-if="error"
          style="color: red"
        >
          {{ error }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';

import Preloader from '../../Components/Preloader.vue';

const props = defineProps({
  show: {
    type: Boolean,
    require: true,
  },
  login: {
    type: String,
    require: true,
  },
});

const emit = defineEmits(['close', 'logout']);

const userData = inject('userData');

const isRequest = ref(false);
const error = ref('');

const logoutHandler = () => {
  isRequest.value = true;
  fetch(
    `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }Api/auth/logout`,
    {
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      if (data['error']) {
        // error.value = data['error'];
        return;
      }
      if (data['redirectUrl'].includes('auth')) {
        error.value = '';
      }
    })
    .catch((rej) => {
      error.value = 'Произошла ошибка';
    })
    .finally(() => {
      emit('logout');
      isRequest.value = false;
    });
};
</script>

<style></style>
