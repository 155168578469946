<template>
  <div ref="geofencesListWrapper">
    <table class="my-table">
      <thead>
        <tr ref="tableHeader">
          <th title="отображение на карте">
            <input
              type="checkbox"
              v-model="isVisibleAll"
            />
          </th>
          <th
            v-if="searchType == 'id'"
            style="cursor: pointer"
            @click="() => sortTableBy('id')"
          >
            <div
              :class="{
                'filter': lastSortableKey == 'id',
                'from-more-to-less': isFromMoreToLess,
              }"
            >
              ID
            </div>
          </th>
          <th
            style="cursor: pointer"
            @click="() => sortTableBy('name')"
          >
            <div
              :class="{
                'filter': lastSortableKey == 'name',
                'from-more-to-less': isFromMoreToLess,
              }"
            >
              Наименование
            </div>
          </th>
          <!-- <th>Ключ</th> -->
          <!-- <th>Код</th> -->
          <th>Изменить</th>
          <th>Сохранить</th>
          <th>Удалить</th>
          <!-- <th>geoJson</th> -->
        </tr>
      </thead>
      <tbody>
        <vue-geofencies-list-item
          v-for="geofence in allGeofences"
          :key="geofence.key"
          :id="`geofence-${geofence.id}`"
          :radactMode="radactMode"
          :geofence="geofence"
          :selectedGeofence="selectedGeofence"
          :editingKeys="editingKeys"
          :rule_geofences_delete="rule_geofences_delete"
          :rule_geofences_create="rule_geofences_create"
          :rule_geofences_update="rule_geofences_update"
          :show_geofence="show_geofence"
          :searchType="searchType"
          @remove="$emit('remove', geofence)"
          @geofence-select="selectRow"
          @geofence-show-on-map="$emit('geofence-show-on-map', geofence)"
          @geo-edit="$emit('geo-edit', geofence)"
          @geo-edit-cancel="$emit('geo-edit-cancel', geofence)"
          @geo-save="$emit('geo-save', geofence)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import VueGeofenciesListItem from './VueGeofenciesListItem.vue';

export default {
  components: {
    VueGeofenciesListItem,
  },
  props: {
    allGeofences: {
      type: Array,
      default: [],
    },
    selectedGeofence: {
      type: Object,
      required: true,
    },
    // tableHeight: {
    //   type: String,
    //   required: true,
    // },
    radactMode: {
      type: Boolean,
      required: true,
    },
    editingKeys: {
      type: Object,
      required: true,
    },
    rule_geofences_delete: {
      type: Boolean,
      required: true,
    },
    rule_geofences_create: {
      type: Boolean,
      required: true,
    },
    rule_geofences_update: {
      type: Boolean,
      required: true,
    },
    show_geofence: {
      type: Function,
      required: true,
    },
    scrollToId: {
      type: [String, Number],
      default: '',
    },
    searchType: {
      type: String,
      default: '',
    },
  },
  emits: {
    'remove': Object,
    'geofence-select': Object,
    'geofence-show-on-map': Object,
    'geo-save': Object,
    'geo-edit': Object,
    'geo-edit-cancel': Object,
    'update:scrollToId': String,
    'sortTableBy': String,
  },
  data() {
    return {
      isVisibleAll: true,
      lastSortableKey: '',
      isFromMoreToLess: false,
    };
  },
  methods: {
    sortTableBy(key) {
      if (this.lastSortableKey === key) {
        this.isFromMoreToLess = !this.isFromMoreToLess;
      } else {
        this.isFromMoreToLess = false;
      }
      this.lastSortableKey = key;
      this.$emit('sortTableBy', key, this.isFromMoreToLess);
    },
    selectRow(geofence) {
      this.$emit('geofence-select', geofence);
    },
  },
  watch: {
    isVisibleAll(value) {
      this.allGeofences.forEach((geo) => {
        geo.isVisible = value;
      });
    },
    scrollToId(id) {
      const geoNode = document.getElementById(`geofence-${id}`);

      if (!geoNode) return;

      this.$refs.geofencesListWrapper.scrollTo({
        left: 0,
        top: geoNode.offsetTop - this.$refs.tableHeader.offsetHeight,
        behavior: 'smooth',
      });

      this.$emit('update:scrollToId', '');

      this.$nextTick(() => {
        geoNode.classList.add('blink');
        setTimeout(() => geoNode.classList.remove('blink'), 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blink {
  animation-name: blinker;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 2, 2, 1);
  animation-duration: 2s;
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(1, 2, 2, 1);
  -webkit-animation-duration: 2s;
}

@keyframes blinker {
  from {
    background: #57c0f1;
  }
  to {
    background: #ffffff;
  }
}

@-webkit-keyframes blinker {
  from {
    background: #ffffff;
  }
  to {
    background: #57c0f1;
  }
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 0 5px;
}
th {
  background-color: rgb(239, 239, 239);
  position: sticky;
  top: -1px; /* Don't forget t  his, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

  & .filter {
    display: flex;

    &::after {
      content: '▴';
      width: 5px;
    }
    &.from-more-to-less {
      &::after {
        content: '▾';
      }
    }
  }
}
</style>
