<template>
  <Datepicker
    v-model="dateModel"
    range
    locale="ru"
    autoApply
    :closeOnAutoApply="false"
    @open="openDp"
    :format="dpFormat"
    :time-picker-component="TimePicker"
    menuClassName="dp-custom-menu"
    calendarClassName="dp-custom-calendar"
  />
</template>

<script setup>
import { computed, nextTick, ref, useAttrs, watch } from '@vue/runtime-core';

import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import { getStringByUnixTime } from '@/helpers/MineHelper.js';
import TimePicker from '../../../Components/DpTimePicker.vue';

const props = defineProps(['date']);
const emit = defineEmits(['update:date']);

const oldDate = ref([]);

const dateModel = computed({
  get() {
    return props.date;
  },
  set(value) {
    emit('update:date', value);
  },
});

watch(
  dateModel,
  (newVal, oldVal) => {
    oldDate.value = oldVal;
  },
  { immediate: true },
);

const openDp = async () => {
  dateModel.value = oldDate.value;
};

const attrs = useAttrs();

const dpFormat = (date) => {
  const beginDateTime = getStringByUnixTime(date[0].getTime());
  const endDateTime = getStringByUnixTime(date[1].getTime());

  return `${beginDateTime} - ${endDateTime}`;
};
</script>

<style lang="scss">
@media (max-width: 1024px) and (orientation: landscape) {
  .dp-custom-menu {
    & div {
      & .dp__instance_calendar {
        & div {
          & div {
            & .dp__month_year_row {
              height: 25px;
            }
          }
        }
      }
    }
  }
  .dp-custom-calendar {
    & .dp__calendar-header {
      height: 25px;
    }
    & .dp__calendar {
      &_row {
        margin: 0;
      }
      &_item {
        height: 30px;
      }
    }
  }
}
</style>
