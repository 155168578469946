const minDistanceFromForfeitsRelative = 10;
const perfectMedianaAvgPercent = 0;
const goodMedianaAvgPercent = 20;
const badMedianaAvgPercent = 100;

export class CalculateGraphData {
  forfeitsRelativeAvgFilter = (value) => value >= 0;

  constructor({ format = (value) => value } = {}) {
    this.format = format;
  }

  getObjectsData(objects, rows_summ, smenasSplitObject = false) {
    // суммарные значения для упрощенного анализа
    if (!objects.length) {
      return [];
    }

    const [firstObject] = objects;

    const violationNames = this.getPersonViolationsName(firstObject);
    const { isSplitSmenas = false, smenasStr = [] } = firstObject;

    // соберем сумму нарушений по объектам
    const objectsData = objects.map((object) => {
      const iObj = this.getObjectData(
        object,
        violationNames,
        rows_summ,
        smenasSplitObject,
      );
      return iObj;
    });

    return { objectsData, violationNames, isSplitSmenas, smenasStr };
  }

  getObjectData(object, violationNames, rows_summ, smenasSplitObject) {
    // обсчет одного объекта
    const {
      id,
      name,
      avtoNo,
      avtoModel,
      gearboxType,
      gearboxCnt,
      gearboxCntForCalculation,
      notData,
    } = object;
    // const {canExpence, canExpence100km, canExpence100kmMove, clutchWorkSumm} = object.rows_experiment;
    const { periodSplitRows, periodSplitDependentRows } =
      object.rows_experiment;

    const params = [
      'canExpence',
      'canExpence100km',
      'canExpence100kmMove',
      'clutchWorkSumm',
      'avgSpd',
      'canExpenceLHourMove',
      'rpm1900_2200Time',
      'motoEngineSpd',
    ];

    periodSplitRows.concat(periodSplitDependentRows).forEach((param) => {
      if (!params.includes(param)) {
        params.push(param);
      }
    });

    // rows_person person rpmStartSpdMore1200Cnt DForfeits DForfeitsRelative
    // rows_person person accelSharplyCnt DForfeits DForfeitsRelative
    // transmission_lock_1 distPercent val format:6

    const rows_experiment = params.reduce(
      (accum, paramName) => {
        const row = object.rows_experiment[paramName];

        if (!row) {
          // transmission_lock_1_distPercent и transmission_lock_1_dist
          return accum;
        }

        const accumParam = {
          val: row.val.val,
          format: row.val.format,
        };

        if ('periods' in row) {
          accumParam.periods = row.periods;
        }

        accum[paramName] = accumParam;
        return accum;
      },
      { periodSplitRows, periodSplitDependentRows },
    );

    // rows_experiment.periodSplitRows = periodSplitRows;
    // rows_experiment.periodSplitDependentRows = periodSplitDependentRows;

    const { motoEngineSpd, rpm1900_2200Time } = rows_experiment;
    // const rpm1900_2200TimePercent_val = (motoEngineSpd.val) ? (rpm1900_2200Time.val / motoEngineSpd.val) : 0;
    const rpm1900_2200TimePercent_val = getRelativeValue_helper(
      'rpm1900_2200Percent',
      {
        motoEngineSpd: motoEngineSpd.val,
        rpm1900_2200Time: rpm1900_2200Time.val,
      },
    );

    rows_experiment.rpm1900_2200TimePercent = {
      val: rpm1900_2200TimePercent_val,
      format: 6,
    };

    const { transmission_lock_1 = {} } = object.rows_experiment;
    const {
      distPercent = { val: 'нет', format: 0, periods: [] },
      dist = { val: 'нет', format: 5, periods: [] },
    } = transmission_lock_1;

    rows_experiment['transmission_lock_1_dist'] = dist;
    rows_experiment['transmission_lock_1_distPercent'] = distPercent;

    rows_experiment['gearboxRating'] = ''; // заполнится в ratingTablePrepareObject
    rows_experiment['gearboxes'] = {};
    const { header: rows_experiment_header = {} } = object.rows_experiment;
    const { GearBoxNumNameArr: gearBoxNumNameArr = [] } =
      rows_experiment_header;

    gearBoxNumNameArr.forEach((gearboxArrName) => {
      if (gearboxArrName !== 'GearBoxNum_name') {
        rows_experiment['gearboxes'][gearboxArrName] =
          object.rows_experiment[gearboxArrName] || {};
        rows_experiment['gearboxes'][gearboxArrName]['gearboxName'] =
          rows_experiment_header[gearboxArrName]['name'];
      }
    });

    const rows_summ_line = rows_summ.lines[0].find(
      (values) => values.objId === id,
    );

    const { person } = object.rows_person;

    const {
      periodSplitRows: personPeriodSplitRows = [],
      splitDependentRows: personSplitDependentRows = [],
    } = object.rows_person;

    const personParams = personPeriodSplitRows.concat([
      'accelSharplyCnt',
      'rpmStartSpdMore1200Cnt',
    ]);
    // const personParams = ['accelSharplyCnt', 'rpmStartSpdMore1200Cnt'];

    const rows_person = personParams.reduce((accum, personParam) => {
      const {
        DForfeits,
        DForfeitsRelative,
        periods: DPeriods,
      } = person[personParam];

      accum[`${personParam}_forfeits`] = DForfeits;
      accum[`${personParam}_forfeitsRelative`] = DForfeitsRelative;

      if (DPeriods) {
        accum[`${personParam}_periods`] = DPeriods;
      }

      return accum;
    }, {});

    rows_person.periodSplitRows = personPeriodSplitRows;
    rows_person.periodSplitDependentRows = personSplitDependentRows;
    rows_person.personForDisplay = object.rows_person.personForDisplay;

    const iObj = {
      id,
      name,
      avtoNo,
      avtoModel,
      gearboxType,
      gearboxCnt,
      gearboxCntForCalculation,
      notData,

      rows_summ_line,
      rows_experiment,
      rows_person,

      periods: [],
      summ: {
        dist: 0,
        moto: 0,
        motoNoSpd: 0,
        count: 0,
        forfeits: 0,
      },
    };

    object.periods.forEach((period) => {
      const {
        t2_distance = 0,
        t2_motoengine = 0,
        t2_motoenginenospd = 0,
        TimeBegin,
        TimeEnd,
        nSmena,
      } = period;
      const dist = t2_distance / 1000;
      const moto = t2_motoengine;
      const motoNoSpd = t2_motoenginenospd;

      iObj.summ.dist += dist;
      iObj.summ.moto += moto;
      iObj.summ.motoNoSpd += motoNoSpd;

      iObj.periods.push({
        TimeBegin,
        TimeEnd,
        nSmena,
        dist,
        moto,
        motoNoSpd,
        count: 0,
        forfeits: 0,
        violations: {},
      });
    });

    // подсчет нарушений: сумма и по периодам
    for (let violationName in violationNames) {
      if (person[violationName] && person[violationName]['periods']) {
        const { DCount: DCountSumm = 0, DForfeits: DForfeitsSumm = 0 } =
          person[violationName];

        iObj.summ.count += isNumber_helper(DCountSumm) ? DCountSumm : 0;
        iObj.summ.forfeits += DForfeitsSumm;

        const { periods = [] } = person[violationName];

        periods.forEach((period, index) => {
          if (iObj.periods[index]) {
            const { DCount, DForfeits } = period;

            iObj.periods[index].count += isNumber_helper(DCount) ? DCount : 0;
            iObj.periods[index].forfeits += DForfeits;

            iObj.periods[index].violations[violationName] = DForfeits;
          }
        });
      }
    }

    return iObj;
  }

  getPersonViolationsName(object) {
    // суммируем все нарушения по периоду
    return object.rows_person.personForDisplay.reduce((accum, group) => {
      group.forEach((arrName) => {
        if (!arrName.toLowerCase().includes('group', 0)) {
          // это НЕ название группировки
          accum[arrName] = object.rows_person.person[arrName].DSkill;
        }
      });

      return accum;
    }, {});
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  getPeriodsLabels(object, titleText) {
    const { smenasStr = '', t_interval = '' } = object.view;
    const title = this.titleTemplate(smenasStr, t_interval, titleText);

    return object.periods.reduce(
      (accum, period) => {
        const { TimeBegin, TimeEnd } = period;

        // const dateBegin = formatDateHelper(TimeBegin, 'dd.mm.yyyy hh:nn');
        const dateBegin = formatDateHelper(new Date(TimeBegin), 'dd.mm hh:nn');
        // const dateEnd = formatDateHelper(TimeEnd, 'dd.mm.yyyy hh:nn');
        const dateEnd = formatDateHelper(new Date(TimeEnd), 'dd.mm hh:nn');
        const label = `${dateBegin} - ${dateEnd}`;

        accum.periods.push({ TimeBegin, TimeEnd });
        accum.labels.push(label);

        return accum;
      },
      { periods: [], labels: [], title },
    );
  }

  titleTemplate(smenasStr, t_interval, titleText) {
    return `<h5 class="center margin-none">${titleText} ${t_interval}</h5><p class="margin-none">${smenasStr}</p>`;
  }

  chartSummAnalysis(objectsData, periodsLabels, violationNames) {
    // для графика по сумме нарушений
    const { periods = [], labels = [] } = periodsLabels;

    const indicators = ['dist', 'forfeits', 'moto', 'motoNoSpd', 'motoSpd'];

    // const rowsExperimentIndicators = [
    //     'canExpence',
    //     'canExpenceSpd',
    //     'canExpenceNoSpd',

    //     'avgSpd',
    //     'canExpence100km',
    //     'canExpence100kmMove',
    //     'canExpenceLHourMove',
    //     'canExpenceLHourIdle',

    //     'ptoCnt',
    //     'ptoTime',
    //     'ptoEngineRpm800_1000Time',
    //     'ptoSpdDistance',
    //     'rpmMore1200PtoTime',
    //     'transmission_lock_1_dist',
    //     'clutchUseMoveStartWorkSumm',
    //     'clutchUseMoveStartExcessiveWorkSumm',
    //     'rpm1900_2200Time'
    // ];

    const {
      periodSplitRows: rowsExperimentIndicators = [],
      periodSplitDependentRows: rowsExperimentDependentIndicators = [],
    } = objectsData[0].rows_experiment;

    const rowsExperimentIndicatorsAll = rowsExperimentIndicators.concat(
      rowsExperimentDependentIndicators,
    );

    const rowsPersonIndicators =
      objectsData[0].rows_person.periodSplitRows || [];

    if (!periods.length || !labels.length) {
      return {
        labels: [],
        summData: indicators.reduce(
          (accum, indicator) => {
            accum[indicator] = [];
            return accum;
          },
          { distForfeitsRelative: [] },
        ),
      };
    }

    // сумма по всем объектам по каждому периоду
    const summData = objectsData.reduce((accum, objectData, objIndex) => {
      // accum.summ = objectData.summ;

      if (!accum.periodSplitRows) {
        const { periodSplitRows, periodSplitDependentRows } =
          objectData.rows_experiment;
        accum.periodSplitRows = periodSplitRows;
        accum.periodSplitDependentRows = periodSplitDependentRows;
      }

      if (!(indicators[0] in accum)) {
        // подготовим массив периодов с нулевыми значениями по каждому из индикаторов
        accum['summOfObjects'] = [];
        accum['summOfPeriods'] = []; // проверенные периоды по каждому из объектов
        accum['objectsName'] = [];
        accum['totalObjectsSumm'] = [];

        indicators.forEach((indicator) => {
          accum[indicator] = [];

          periods.forEach((period, index) => {
            accum[indicator][index] = 0;

            accum['summOfPeriods'][index] = {
              dist: [],
              violations: [],
              forfeits: [],
              distForfeitsRelative: [],
              forfeitsRelative: [],
              moto: [],
              motoSpd: [],
              motoNoSpd: [],
              name: [],
            };

            rowsExperimentIndicatorsAll.forEach((rowsExperimentIndicator) => {
              accum['summOfPeriods'][index][rowsExperimentIndicator] = [];
            });

            rowsPersonIndicators.forEach((rowsExperimentIndicator) => {
              accum['summOfPeriods'][index][
                `${rowsExperimentIndicator}_DCount`
              ] = [];
              accum['summOfPeriods'][index][
                `${rowsExperimentIndicator}_DForfeits`
              ] = [];
            });
          });
        });
      }

      const { name, gearboxType, gearboxCnt, avtoModel, avtoNo } = objectData;

      accum['objectsName'][objIndex] = name;

      const { rows_experiment, rows_summ_line, rows_person } = objectData;

      accum['summOfObjects'][objIndex] = {
        rows_experiment,
        rows_summ_line,
        rows_person,
        objIndex,
        name,
        gearboxType,
        gearboxCnt,
        avtoModel,
        avtoNo,
        distForfeitsRelative: 0,
        // violations: [],
        medianaVerification: [], // массив периодов с данными для сверки с медианой
        medianaVerificationAvg: null, // среднее значение
      };

      indicators.forEach((indicator) => {
        accum['summOfObjects'][objIndex][indicator] = 0;
      });

      accum['summOfObjects'][objIndex][
        'isDistForfeitsRelativeOfPeriods'
      ] = false;

      const { periods: objectPeriods } = objectData;

      objectPeriods.forEach((objectPeriod, periodIndex) => {
        if (periods[periodIndex]) {
          const { TimeBegin, TimeEnd } = objectPeriod;
          const { TimeBegin: TimeBeginPeriod, TimeEnd: TimeEndPeriod } =
            periods[periodIndex];

          if (
            TimeBegin - TimeBeginPeriod === 0 &&
            TimeEnd - TimeEndPeriod === 0
          ) {
            const { moto = 0, motoNoSpd = 0 } = objectPeriod;
            const motoSpd = moto - motoNoSpd;

            indicators.forEach((indicator) => {
              if (indicator === 'motoSpd') {
                accum[indicator][periodIndex] += motoSpd;
                accum['summOfObjects'][objIndex][indicator] += motoSpd;
                accum['summOfPeriods'][periodIndex][indicator][objIndex] =
                  motoSpd;
              } else {
                const objectPeriodIndicatorValue = objectPeriod[indicator];

                accum[indicator][periodIndex] += objectPeriodIndicatorValue;
                accum['summOfObjects'][objIndex][indicator] +=
                  objectPeriodIndicatorValue;
                accum['summOfPeriods'][periodIndex][indicator][objIndex] =
                  objectPeriodIndicatorValue;
              }
            });

            rowsExperimentIndicatorsAll.forEach((rowsExperimentIndicator) => {
              const { periods = [] } = rows_experiment[rowsExperimentIndicator];
              accum['summOfPeriods'][periodIndex][rowsExperimentIndicator][
                objIndex
              ] = periods[periodIndex] || 0;
              // const rowsExperimentIndicatorValue = rows_experiment[rowsExperimentIndicator]['periods'][periodIndex] || 0;
              // accum['summOfPeriods'][periodIndex][rowsExperimentIndicator][objIndex] = rowsExperimentIndicatorValue;
            });

            rowsPersonIndicators.forEach((rowsPersonIndicator) => {
              const indicatorName = `${rowsPersonIndicator}_periods`;
              const indicatorPeriods = rows_person[indicatorName] || [];
              const indicatorPeriod = indicatorPeriods[periodIndex] || {};
              const { DCount = 0, DForfeits = 0 } = indicatorPeriod;

              accum['summOfPeriods'][periodIndex][
                `${rowsPersonIndicator}_DCount`
              ][objIndex] = DCount;
              accum['summOfPeriods'][periodIndex][
                `${rowsPersonIndicator}_DForfeits`
              ][objIndex] = DForfeits;
            });

            const distForfeitsRelativeOfPeriod = this.getDistForfeitsRelative(
              objectPeriod.dist,
              objectPeriod.forfeits,
            );
            const forfeitsRelativeOfPeriod = this.getDistForfeitsRelative(
              objectPeriod.dist,
              objectPeriod.forfeits,
              true,
            );

            if (distForfeitsRelativeOfPeriod !== -1) {
              accum['summOfObjects'][objIndex][
                'isDistForfeitsRelativeOfPeriods'
              ] = true;
            }

            accum['summOfPeriods'][periodIndex]['distForfeitsRelative'][
              objIndex
            ] = distForfeitsRelativeOfPeriod;
            accum['summOfPeriods'][periodIndex]['forfeitsRelative'][objIndex] =
              forfeitsRelativeOfPeriod;
            accum['summOfPeriods'][periodIndex]['violations'][objIndex] =
              objectPeriod['violations'];
          } else {
            console.error('в chartSummAnalysis неверное время периода');
          }
        } else {
          console.error('в chartSummAnalysis неверный индекс периода');
        }
      });

      return accum;
    }, {});

    summData['summOfObjects'].forEach((summOfObject) => {
      // по одному объекту
      const { dist, forfeits } = summOfObject;
      summOfObject.distForfeitsRelative = this.getDistForfeitsRelative(
        dist,
        forfeits,
      );
      summOfObject.distForfeitsRelativeMedianaVerification = {
        periods: [],
        avgPercent: 0,
      };
    });

    summData.distForfeitsRelative = [];
    summData.distForfeitsRelativeMedianAverage = [];
    summData.distForfeitsRelativeArithmeticAverage = [];

    summData.distForfeitsRelativeOfObjects = [];
    summData.distForfeitsRelativeMedianAverageOfObjects = [];
    summData.distForfeitsRelativeArithmeticAverageOfObjects = [];

    summData.forfeits.forEach((forfeit, index) => {
      const dist = summData.dist[index] || 0;

      const distForfeitsRelative = this.getDistForfeitsRelative(dist, forfeit);
      summData.distForfeitsRelative.push(
        distForfeitsRelative > 0 ? distForfeitsRelative : 0,
      );
    });

    const distForfeitsRelativeMedianAverage = medianAverage_helper(
      summData.distForfeitsRelative,
      null,
      this.forfeitsRelativeAvgFilter,
    );
    const distForfeitsRelativeArithmeticAverage = arithmeticAverage_helper(
      summData.distForfeitsRelative.filter(this.forfeitsRelativeAvgFilter),
    );

    summData.forfeits.forEach((forfeit, index) => {
      summData.distForfeitsRelativeMedianAverage[index] =
        distForfeitsRelativeMedianAverage;
      summData.distForfeitsRelativeArithmeticAverage[index] =
        distForfeitsRelativeArithmeticAverage;
    });

    summData.distForfeitsRelativeMedianasOfPeriods = [];
    summData.distForfeitsRelativeMinimumOfPeriods = [];
    summData.distForfeitsRelativeMaximumOfPeriods = [];
    summData.distForfeitsRelativeArithmeticAveragesOfPeriods = [];
    summData.distForfeitsRelativeArithmeticAveragesAboveMedianOfPeriods = [];
    summData.distForfeitsRelativeArithmeticAveragesBelowMedianOfPeriods = [];

    // среднее по парку в разрезе суток
    summData['summOfPeriods'].forEach((summOfPeriod, periodIndex) => {
      const minMax = { min: 0, max: 0 };
      const distForfeitsRelativeMediana = medianAverage_helper(
        summOfPeriod['distForfeitsRelative'],
        minMax,
        this.forfeitsRelativeAvgFilter,
      );
      const distForfeitsRelativeArithmeticAverage = arithmeticAverage_helper(
        summOfPeriod['distForfeitsRelative'].filter(
          this.forfeitsRelativeAvgFilter,
        ),
      );

      summData.distForfeitsRelativeMedianasOfPeriods[periodIndex] =
        roundNumber_helper(distForfeitsRelativeMediana, 2);
      summData.distForfeitsRelativeArithmeticAveragesOfPeriods[periodIndex] =
        roundNumber_helper(distForfeitsRelativeArithmeticAverage, 2);

      summData.distForfeitsRelativeMinimumOfPeriods[periodIndex] = minMax.min;
      summData.distForfeitsRelativeMaximumOfPeriods[periodIndex] = minMax.max;

      // среднее арифметическое выше и ниже медианы
      const distForfeitsRelativeAboveMedianValues = summOfPeriod[
        'distForfeitsRelative'
      ].filter((value) => value >= distForfeitsRelativeMediana);
      summData.distForfeitsRelativeArithmeticAveragesAboveMedianOfPeriods[
        periodIndex
      ] = roundNumber_helper(
        arithmeticAverage_helper(
          distForfeitsRelativeAboveMedianValues.filter(
            this.forfeitsRelativeAvgFilter,
          ),
        ),
        2,
      );
      const distForfeitsRelativeBelowMedianValues = summOfPeriod[
        'distForfeitsRelative'
      ].filter((value) => value <= distForfeitsRelativeMediana);
      summData.distForfeitsRelativeArithmeticAveragesBelowMedianOfPeriods[
        periodIndex
      ] = roundNumber_helper(
        arithmeticAverage_helper(
          distForfeitsRelativeBelowMedianValues.filter(
            this.forfeitsRelativeAvgFilter,
          ),
        ),
        2,
      );
    });

    // distForfeitsRelativeMedianaVerification

    // по каждому объекту за каждый период сверка с медианой по парку за период
    summData['summOfObjects'].forEach((summOfObject, objIndex) => {
      // по одному объекту
      const forAvg = {
        count: 0,
        summ: 0,
      };

      summData['summOfPeriods'].forEach((summOfPeriod, periodIndex) => {
        const distForfeitsRelative =
          summOfPeriod['distForfeitsRelative'][objIndex];

        if (distForfeitsRelative >= 0) {
          const distForfeitsRelativeMediana =
            summData['distForfeitsRelativeMedianasOfPeriods'][periodIndex];
          const diff = distForfeitsRelative - distForfeitsRelativeMediana;
          const percent = (100 * diff) / distForfeitsRelativeMediana;

          summOfObject['distForfeitsRelativeMedianaVerification']['periods'][
            periodIndex
          ] = myRoundNumber_helper(percent, 2);

          forAvg.summ += percent;
          forAvg.count++;
        }
      });

      summOfObject['distForfeitsRelativeMedianaVerification']['avgPercent'] =
        forAvg.count ? forAvg.summ / forAvg.count : 0;
    });

    // подготовим bubble диаграмму превышение медианы / пробег
    // const minMaxSummDistOfObjects = getMinMaxOfArray_helper(summData['dist']);
    // prepareBubbleData()

    summData.distForfeitsRelativeMedianaData = summData['summOfObjects'].reduce(
      (accum, summOfObject) => {
        const {
          dist = 0,
          avtoNo = '-',
          forfeits,
          distForfeitsRelative,
        } = summOfObject;
        const relativeMedianAvgPercent =
          summOfObject['distForfeitsRelativeMedianaVerification']['avgPercent'];

        accum.push({
          dist,
          avtoNo,
          forfeits,
          distForfeitsRelative,
          relativeMedianAvgPercent,
        });

        return accum;
      },
      [],
    );

    summData.objectsRaiting = this.getObjectsRating(summData['summOfObjects']);

    // const datetimeSummData = summData.forfeits.reduce((accum, y, index) => {
    //     // const {TimeBegin: x, TimeEnd: TimeEndPeriod} = periods[index];
    //     const {TimeBegin: x} = periods[index];

    //     accum.push({x, y});
    //     return accum;
    // }, []);

    // colorHexShift_helper

    return {
      summData,
      // datetimeSummData,
      labels,
    };
  }

  getObjectsRating(summOfObjects) {
    const objectsRaiting = summOfObjects.reduce(
      (accum, objValues, objIndex) => {
        // return {values, objIndex}
        let listName;

        if (!objValues.isDistForfeitsRelativeOfPeriods) {
          listName = 'notDist';
        } else if (
          objValues.distForfeitsRelativeMedianaVerification.avgPercent <
          perfectMedianaAvgPercent
        ) {
          listName = 'perfectObjects';
        } else if (
          objValues.distForfeitsRelativeMedianaVerification.avgPercent <
          goodMedianaAvgPercent
        ) {
          listName = 'goodObjects';
        } else if (
          objValues.distForfeitsRelativeMedianaVerification.avgPercent <
          badMedianaAvgPercent
        ) {
          listName = 'badObjects';
        } else {
          listName = 'veryBadObjects';
        }

        accum[listName].push({ objValues, objIndex });

        return accum;
      },
      {
        perfectObjects: [],
        goodObjects: [],
        badObjects: [],
        veryBadObjects: [],
        notDist: [],
      },
    );

    for (let listName in objectsRaiting) {
      objectsRaiting[listName].sort(
        (a, b) =>
          a.objValues.distForfeitsRelativeMedianaVerification.avgPercent -
          b.objValues.distForfeitsRelativeMedianaVerification.avgPercent,
      );
    }

    return objectsRaiting;
  }

  getDistForfeitsRelative(dist, forfeit, isNotDistMin = false) {
    if (isNotDistMin && dist === 0) {
      return 0;
    }

    return isNotDistMin || dist > minDistanceFromForfeitsRelative
      ? Math.round((100 * forfeit) / (dist / 100)) / 100
      : -1;
  }

  ratingTablePrepareObjects(chartSummData) {
    const { summData } = chartSummData;
    const { objectsRaiting } = summData;
    const queue = [
      'perfectObjects',
      'goodObjects',
      'badObjects',
      'veryBadObjects',
      'notDist',
    ];

    return queue.reduce((accum, ratingName) => {
      objectsRaiting[ratingName].forEach((object) => {
        const objectValuesFromTable = this.ratingTablePrepareObject(
          object,
          ratingName,
        );
        accum.push(objectValuesFromTable);
      });

      return accum;
    }, []);
  }

  ratingTablePrepareObject(object, ratingName) {
    // вытаскивает данные с объекта для выгрузки в таблицу
    const { cols, headers } = this.ratingTable;
    const { objValues } = object;

    return cols.reduce(
      (accum, colName) => {
        const { structures = null } = headers[colName];

        if (colName === 'gearboxRating') {
          accum[colName] = this.getGearboxRating(objValues);
        } else if (structures) {
          structures.forEach((columnName) => {
            accum[columnName] = this.ratingTablePrepareObject_getValue(
              objValues,
              columnName,
            );
          });
        } else if (colName === 'ratingRusName') {
          accum[colName] = this.getDistForfeitsRelativeMedianaGrade(
            'no',
            ratingName,
          );
        } else {
          accum[colName] = this.ratingTablePrepareObject_getValue(
            objValues,
            colName,
          );
        }

        return accum;
      },
      { ratingName },
    );
  }

  getGearboxRating(objValues) {
    const { gearboxes = {} } = objValues.rows_experiment;
    const gerboxesArr = Object.values(gearboxes);

    gerboxesArr.sort((a, b) => b.distPercent.val - a.distPercent.val);

    const gearboxesFrequentArr = gerboxesArr.slice(0, 3); // берет три наиболее часто используемые передачи
    const gearboxesRatingArr = gearboxesFrequentArr.map((gear) => {
      const { gearboxName = '?' } = gear;
      const { val = '?' } = gear.distPercent;
      return `${gearboxName}&nbsp;-&nbsp;${this.format(val, 6)}`;
    });

    return gearboxesRatingArr.join('<br>');
  }

  ratingTablePrepareObject_getValue(objValues, colName) {
    const {
      rows_experiment,
      rows_summ_line,
      rows_person,
      distForfeitsRelativeMedianaVerification,
    } = objValues;

    if (colName in objValues) {
      return objValues[colName];
    } else if (colName in rows_experiment) {
      return rows_experiment[colName]['val'];
    } else if (colName in rows_summ_line) {
      return rows_summ_line[colName];
    } else if (colName in distForfeitsRelativeMedianaVerification) {
      return distForfeitsRelativeMedianaVerification[colName];
    } else if (colName in rows_person) {
      return rows_person[colName];
    } else if (colName.includes('GroupForfeits')) {
      const { DDist } = rows_summ_line;

      if (!DDist) {
        return 0;
      }

      const [groupName] = colName.split('Forfeits');
      const groupValue = rows_summ_line[groupName];

      return groupValue / (DDist / 100);
    }

    return '?';
  }

  getRatingTableTemplate({
    ratingTableValues,
    ratingTableId = '',
    t_interval = '',
  } = {}) {
    return `
        <div id=${ratingTableId}>
            <button class="padding-left-5 not-print float-left" onclick="html_to_excel_helper.export( this, '${ratingTableId}', 'Таблица рейтинга водителей', 'landscape' )">
                Экспорт&nbsp;в&nbsp;EXCEL 
            </button>
            <span class="footer-desc padding-left-5" style="font-weight:bold;">
                Таблица рейтинга водителей с учетом использования ТС за каждый день/смену ${t_interval}
            </span>
            <table class="table-simple">
                <!--caption class="caption-top">Таблица рейтинга водителей ${t_interval}</caption-->
                <thead>${this.getRatingTableHeaderTemplate()}</thead>
                <tbody>${this.getRatingTableBodyTemplate(
                  ratingTableValues,
                )}</tbody>
            </table>
        </div>`;
  }

  getRatingTableHeaderTemplate() {
    const headers = this.ratingTable.cols.map((header) => {
      return `<th data-excelalignh="center" data-minwidth="${this.ratingTable.headers[header]['minWidth']}">
                ${this.ratingTable.headers[header]['name']}
            </th>`;
    });

    return `<tr class="print-frozen-row print-repeat text-center">${headers.join(
      '',
    )}</tr>`;
  }

  getRatingTableBodyTemplate(ratingTableValues) {
    const bgColors = {
      perfectObjects: '#92D050',
      goodObjects: '#C4D79B',
      badObjects: '#F2DCDB',
      veryBadObjects: '#FF0000',
      notDist: '#D9D9D9',
    };

    return ratingTableValues
      .map((rowValues) => {
        const { ratingName } = rowValues;
        const bgcolor = bgColors[ratingName];
        return `<tr class="rating-${ratingName}">${this.getRatingTableRowTemplate(
          rowValues,
          bgcolor,
        )}</tr>`;
      })
      .join('');
  }

  getRatingTableRowTemplate(rowValues, bgcolor) {
    const { cols, headers } = this.ratingTable;

    return cols
      .map((col, colIndex) =>
        this.getRatingTableColumnTemplate({
          rowValues,
          col,
          colIndex,
          headers,
          bgcolor,
        }),
      )
      .join('');
  }

  getRatingTableColumnTemplate({
    rowValues = {},
    col = '',
    colIndex = 0,
    headers = {},
    bgcolor = '',
  } = {}) {
    const { structures = null } = headers[col];
    const align = colIndex
      ? ' class="text-center" data-excelalignh="center"'
      : '';

    if (structures) {
      const { joinStr } = headers[col];

      const colValues = structures.map((column) => {
        return this.format(rowValues[column], headers[column]['format']);
      });

      return `<td bgcolor="${bgcolor}"${align}>${colValues.join(joinStr)}</td>`;
    }

    const value = rowValues[col];
    const format = headers[col]['format'];

    let dataValue = '';

    if (format > 4 && !(format === 6 || format === 6.5)) {
      dataValue = ` data-value=${value}`;
    }

    // if (format === 6.5) {
    //     dataValue = ` data-value=${value / 100}`;
    // } else if (format === 6) {
    //     dataValue = ` data-value=${value * 100}`;
    // } else if (format > 4) {
    //     dataValue = ` data-value=${value}`;
    // }

    return `<td bgcolor="${bgcolor}"${align} data-formatnum="${format}"${dataValue}>
            ${this.format(value, headers[col]['format'])}
        </td>`;
  }

  notDistListTemplate(objectList, text) {
    const list = this.getObjectsNames(objectList);
    text =
      text ||
      '<strong>Объекты, исключенные из рейтинга из-за малого пробега:</strong>';

    return `${text} ${list}`;
  }

  getObjectsNames(objectsList = []) {
    if (!objectsList.length) {
      return 'отсутствуют';
    }

    const objectsNames = objectsList.reduce((accum, object, index) => {
      const { objValues } = object;
      const { name = '?', stateNumber, avtoNo } = objValues || object;
      const avtoStateNumber = stateNumber || avtoNo || '?';
      // const avtoStateNumberSplitted = `${avtoStateNumber.slice(0, 16)} ${avtoStateNumber.slice(16)}`;

      // accum[index] = (`${name}(${stateNumber})`);
      accum[index] = `${avtoStateNumber}`;

      return accum;
    }, []);

    return objectsNames.join('; ');
  }

  objectListTemplate(objectsList = [], objectsDisableList = []) {
    const objectsNames = this.getObjectsNames(objectsList);
    const objectsDisableNames = this.getObjectsNames(objectsDisableList);

    return `
            <p class="text-left margin-none" style="font-size: xx-small">
              <span style="color:green">Объекты, включенные в запрос:</span> ${objectsNames}<br>
              <span style="color:red">Объекты, исключенные из запроса:</span> ${objectsDisableNames}
            </p>`;
  }

  getDistForfeitsRelativeMedianaGrade(percent, ratingName = '') {
    if (ratingName === 'notDist') {
      return 'нет';
    } else if (
      percent < perfectMedianaAvgPercent ||
      ratingName === 'perfectObjects'
    ) {
      return 'отлично';
    } else if (
      percent < goodMedianaAvgPercent ||
      ratingName === 'goodObjects'
    ) {
      return 'хорошо';
    } else if (percent < badMedianaAvgPercent || ratingName === 'badObjects') {
      return 'плохо';
    } else if (!ratingName || ratingName === 'veryBadObjects') {
      return 'очень плохо!';
    }

    return 'нет';
  }

  ratingTable = {
    cols: [
      'avtoModel',
      'avtoNo',
      'ratingRusName',
      'dist',
      'DReceivedPercent',
      'startEngineGroupForfeits',
      'motoIdlingGroupForfeits',
      'moveStartGroupForfeits',
      'moveGroupForfeits',
      'ptoWorkKomGroupForfeits',
      'drivingStyleGroupForfeits',
      // 'forfeits',
      'forfeitsRelative',
      'avgPercent',
      'ptoCnt_ptoCnt100km',
      'canExpence',
      'canExpence100km',
      'canExpence100kmMove',
      'canExpenceLHourMove',
      'avgSpd',
      'rpm1900_2200Time_timePercent',
      'transmission_lock_1_dist_distPercent',
      'accelSharplyCnt_forfeits_forfeitsRelative',
      'rpmStartSpdMore1200Cnt_forfets_forfeitsRelative',
      'gearboxRating',
    ],

    headers: {
      avtoModel: { name: 'Модель ТС', format: 0, minWidth: 11 },
      ratingRusName: { name: 'Рейтинг', format: 0, minWidth: 11 },
      avtoNo: { name: 'Гос номер', format: 0, minWidth: 8 },
      dist: { name: 'Пробег, км', format: 13, minWidth: 8 },
      DReceivedPercent: { name: '% прихода данных', format: 6, minWidth: 8 },
      startEngineGroup: { name: 'Запуск ДВС', format: 12, minWidth: 8 },
      motoIdlingGroup: { name: 'Работа ДВС на х. х.', format: 12, minWidth: 8 },
      moveStartGroup: { name: 'Начало движения', format: 12, minWidth: 8 },
      moveGroup: { name: 'Движение', format: 12, minWidth: 8 },
      ptoWorkKomGroup: { name: 'Работа КОМ', format: 12, minWidth: 8 },
      drivingStyleGroup: { name: 'Стиль вождения', format: 12, minWidth: 8 },
      startEngineGroupForfeits: {
        name: 'Запуск ДВС, на 100км',
        format: 14,
        minWidth: 8,
      },
      motoIdlingGroupForfeits: {
        name: 'Работа ДВС на х.х., на 100км',
        format: 14,
        minWidth: 8,
      },
      moveStartGroupForfeits: {
        name: 'Начало движения, на 100км',
        format: 14,
        minWidth: 8,
      },
      moveGroupForfeits: {
        name: 'Движение, на 100км',
        format: 14,
        minWidth: 8,
      },
      ptoWorkKomGroupForfeits: {
        name: 'Работа КОМ, на 100км',
        format: 14,
        minWidth: 8,
      },
      drivingStyleGroupForfeits: {
        name: 'Стиль вождения, на 100км',
        format: 14,
        minWidth: 8,
      },
      forfeits: { name: 'Сумма нарушений', format: 12, minWidth: 8 },
      forfeitsRelative: {
        name: 'Нарушения на 100 км пробега',
        format: 14,
        minWidth: 8,
      },
      canExpence: { name: 'Расход по CAN, л', format: 13, minWidth: 8 },
      canExpence100km: {
        name: 'Расход по CAN, л/100 км',
        format: 13,
        minWidth: 8,
      },
      canExpence100kmMove: {
        name: 'Расход по CAN в движении, л/100 км',
        format: 13,
        minWidth: 8,
      },
      canExpenceLHourMove: {
        name: 'Расход по CAN в движении, л/час',
        format: 13,
        minWidth: 8,
      },
      avgSpd: { name: 'Средняя скорсть, км/ч', format: 13, minWidth: 8 },

      rpm1900_2200Time: {
        name: 'Обороты ДВС в диапазоне 1900 - 2400 об/мин, время',
        format: 4,
        minWidth: 8,
      },
      motoEngineSpd: {
        name: 'Работа ДВС при скорости > 0',
        format: 4,
        minWidth: 8,
      },
      rpm1900_2200TimePercent: {
        name: 'ДВС 1900 - 2400, доля от движения',
        format: 6,
        minWidth: 8,
      },
      rpm1900_2200Time_timePercent: {
        name: 'Время ДВС 1900 - 2400 / доля от движения',
        structures: ['rpm1900_2200Time', 'rpm1900_2200TimePercent'],
        joinStr: ' / ',
        minWidth: 10,
      },

      transmission_lock_1_dist: {
        name: 'Пробег на пониж., км',
        format: 5,
        minWidth: 8,
      },
      transmission_lock_1_distPercent: {
        name: 'Доля пробега на пониж. от общего',
        format: 6.5,
        minWidth: 8,
      },
      transmission_lock_1_dist_distPercent: {
        name: 'Пробег на пониж./ доля от общего',
        structures: [
          'transmission_lock_1_dist',
          'transmission_lock_1_distPercent',
        ],
        joinStr: ' / ',
        minWidth: 8,
      },

      avgPercent: {
        name: 'Среднее посменное отклонение от среднего, %',
        format: 6.5,
        minWidth: 10,
      },

      ptoCnt: { name: 'Работа КОМ, кол-во включений', format: 12, minWidth: 8 },
      ptoCnt100km: {
        name: 'Работа КОМ, кол-во включений на 100км',
        format: 14,
        minWidth: 8,
      },

      ptoCnt_ptoCnt100km: {
        name: 'Число поднятий кузова / на 100км',
        structures: ['ptoCnt', 'ptoCnt100km'],
        joinStr: ' / ',
        minWidth: 8,
      },

      accelSharplyCnt_forfeits: {
        name: 'Резких нажатий на газ при старте, кол-во',
        format: 12,
        minWidth: 8,
      },
      accelSharplyCnt_forfeitsRelative: {
        name: 'Резких нажатий на газ при старте, на 100 км',
        format: 14,
        minWidth: 8,
      },
      accelSharplyCnt_forfeits_forfeitsRelative: {
        name: 'Резких нажатий на газ при старте/ на 100 км.',
        structures: [
          'accelSharplyCnt_forfeits',
          'accelSharplyCnt_forfeitsRelative',
        ],
        joinStr: ' / ',
        minWidth: 10,
      },

      rpmStartSpdMore1200Cnt_forfeits: {
        name: 'ДВС&nbsp;более&nbsp;1200 при нач. движ, кол-во',
        format: 12,
        minWidth: 8,
      },
      rpmStartSpdMore1200Cnt_forfeitsRelative: {
        name: 'ДВС более  1200 при нач. движ, на 100 км.',
        format: 14,
        minWidth: 8,
      },
      rpmStartSpdMore1200Cnt_forfets_forfeitsRelative: {
        name: 'ДВС&nbsp;более&nbsp;1200 при нач. движ/на 100 км. ',
        structures: [
          'rpmStartSpdMore1200Cnt_forfeits',
          'rpmStartSpdMore1200Cnt_forfeitsRelative',
        ],
        joinStr: ' / ',
        minWidth: 8,
      },

      gearboxRating: {
        name: 'Наиболее используемые передачи, %',
        format: 0,
        desc: 'данные набираются в getObjectData, анализируются в ratingTablePrepareObject',
        minWidth: 10,
      },
    },
  };

  splitObjectsBySmenas({
    objectsData = [],
    isSplitSmenas = false,
    smenasStr = [],
  } = {}) {
    if (!isSplitSmenas) {
      return objectsData;
    }

    return objectsData.reduce((accum, objectData) => {
      smenasStr.forEach((smenaStr, smenaIndex) => {
        const splitObject = this.splitObjectBySmena({
          objectData,
          smenaStr,
          smenaIndex,
        });

        accum.push(splitObject);
      });

      return accum;
    }, []);
  }

  splitObjectBySmena({ objectData, smenaStr, smenaIndex }) {
    const {
      id,
      name,
      avtoNo,
      avtoModel,
      gearboxType,
      gearboxCnt,
      gearboxCntForCalculation,
      notData,
      rows_experiment,
      periods,
      rows_person,
      rows_summ_line,
      summ,
    } = objectData;

    const { periodSplitRows, periodSplitDependentRows } = rows_experiment;
    const {
      periodSplitRows: personPeriodSplitRows,
      periodSplitDependentRows: personPeriodSplitDependentRows,
      personForDisplay,
    } = rows_person;

    const cloneObj = {
      id,
      name,
      avtoNo: `${avtoNo} ${smenaStr}`,
      avtoModel,
      gearboxType,
      gearboxCnt,
      gearboxCntForCalculation,
      notData,
      periods: [],
      rows_experiment: { periodSplitRows, periodSplitDependentRows },
      rows_person: {
        periodSplitRows: personPeriodSplitRows,
        periodSplitDependentRows: personPeriodSplitDependentRows,
        personForDisplay,
      },
    };

    periods.forEach((period, periodIndex) => {
      const { nSmena, TimeBegin, TimeEnd } = period;
      if (nSmena === smenaIndex) {
        cloneObj.periods[periodIndex] = period;
      } else {
        cloneObj.periods[periodIndex] = {
          TimeBegin,
          TimeEnd,
          nSmena,
          count: null,
          dist: null,
          forfeits: null,
          moto: null,
          motoNoSpd: null,
          nSmena,
          violations: null,
          notData: true,
          isNull: true,
        };
      }
    });

    this.splitObjectBySmena_rowsExperimentCalculate({
      cloneObj,
      rows_experiment,
      periodSplitRows,
      periodSplitDependentRows,
    });
    this.splitObjectBySmena_rowsPersonCalculate({
      cloneObj,
      rows_person,
      personPeriodSplitRows,
      personPeriodSplitDependentRows,
    });
    this.splitObjectBySmena_summLineCalculate({
      cloneObj,
      rows_summ_line,
      personForDisplay,
    });

    return cloneObj;
  }

  splitObjectBySmena_summLineCalculate({
    cloneObj,
    rows_summ_line,
    personForDisplay,
  }) {
    const { DDistBegin, DModel, DMotoBegin, objId } = rows_summ_line;
    const DDist = cloneObj.rows_experiment.distance.val;
    const { periods, rows_experiment } = cloneObj;

    cloneObj.rows_summ_line = {
      DDistBegin,
      DModel,
      DMotoBegin,
      objId,
      DDist,
      DAvtoNo: cloneObj.avtoNo,
    };

    const { rows_summ_line: rows_summ_line_clone } = cloneObj;

    let forfeits = 0;

    let groupName;
    personForDisplay.forEach((group) => {
      group.forEach((violName) => {
        if (violName.toLowerCase().includes('group', 0)) {
          groupName = violName;
          rows_summ_line_clone[groupName] = 0;
        } else {
          periods.forEach((period) => {
            const { violations } = period;

            if (violations) {
              rows_summ_line_clone[groupName] += violations[violName] || 0;
              forfeits += violations[violName] || 0;
            }
          });
        }
      });
    });

    rows_summ_line_clone.forfeits = forfeits;
    rows_summ_line_clone.forfeitsRelative = DDist
      ? forfeits / (DDist / 100)
      : 0;

    const cnt_pos_missed = rows_experiment['cnt_pos_missed']['val'];
    const cnt_real_pos = rows_experiment['cnt_real_pos']['val'];

    //процент прихода данных
    const cntPosRecord = cnt_real_pos + cnt_pos_missed;
    if (cntPosRecord > 0) {
      rows_summ_line_clone.DReceivedPercent = 1 - cnt_pos_missed / cntPosRecord;
      rows_summ_line_clone.notData = false;
    } else {
      rows_summ_line_clone.DReceivedPercent = 0;
      rows_summ_line_clone.notData = true;
    }
  }

  splitObjectBySmena_rowsPersonCalculate({
    cloneObj,
    rows_person,
    personPeriodSplitRows,
    personPeriodSplitDependentRows,
  }) {
    const {
      rows_person: rows_person_clone,
      periods,
      rows_experiment: rows_experiment_clone,
    } = cloneObj;
    const distSumm = rows_experiment_clone.distance.val;
    // const periodSplitRowsSummValues = {};

    const personParams = personPeriodSplitRows.concat([
      'accelSharplyCnt',
      'rpmStartSpdMore1200Cnt',
    ]);

    personParams.forEach((personParam) => {
      rows_person_clone[`${personParam}_periods`] = [];
      rows_person_clone[`${personParam}_forfeits`] = 0;

      const periodsClone = rows_person_clone[`${personParam}_periods`];

      periods.forEach((period, periodIndex) => {
        if (period.isNull) {
          periodsClone[periodIndex] = {
            DCount: null,
            DForfeits: null,
          };
        } else {
          periodsClone[periodIndex] =
            rows_person[`${personParam}_periods`][periodIndex];
          const { DForfeits } = periodsClone[periodIndex];
          rows_person_clone[`${personParam}_forfeits`] += isNumber_helper(
            DForfeits,
          )
            ? DForfeits
            : 0;
        }
      });

      if (distSumm) {
        rows_person_clone[`${personParam}_forfeitsRelative`] =
          rows_person_clone[`${personParam}_forfeits`] / (distSumm / 100);
      } else {
        rows_person_clone[`${personParam}_forfeitsRelative`] = 0;
      }
    });
  }

  splitObjectBySmena_rowsExperimentCalculate({
    cloneObj,
    rows_experiment,
    periodSplitRows,
    periodSplitDependentRows,
  } = {}) {
    const { rows_experiment: rows_experiment_clone, periods } = cloneObj;
    const periodSplitRowsSummValues = {};

    const { gearboxes } = rows_experiment;

    rows_experiment_clone.gearboxes = {};
    const { gearboxes: gearboxesClone } = rows_experiment_clone;

    periodSplitRows
      .concat(periodSplitDependentRows)
      .forEach((periodSplitRow) => {
        const row_experiment_origin = rows_experiment[periodSplitRow];

        if (!(periodSplitRow in rows_experiment_clone)) {
          this.splitObjectBySmena_addCols(
            row_experiment_origin,
            rows_experiment_clone,
            periodSplitRow,
          );
        }

        const row_experiment_clone = rows_experiment_clone[periodSplitRow];
        const { periods: periodsClone } = row_experiment_clone;

        periods.forEach((period, periodIndex) => {
          if (period.isNull) {
            periodsClone[periodIndex] = null;
          } else {
            periodsClone[periodIndex] =
              row_experiment_origin['periods'][periodIndex];
          }
        });

        if (periodSplitRows.includes(periodSplitRow)) {
          const summOfArray = summOfArray_helper(
            row_experiment_clone['periods'],
          );
          row_experiment_clone['val'] = summOfArray;
          periodSplitRowsSummValues[periodSplitRow] = summOfArray;
        } else {
          const dependentSumm = getRelativeValue_helper(
            periodSplitRow,
            periodSplitRowsSummValues,
          );
          row_experiment_clone['val'] = dependentSumm;
          periodSplitRowsSummValues[periodSplitRow] = dependentSumm;
        }
      });

    rows_experiment_clone['rpm1900_2200TimePercent'] =
      rows_experiment_clone['rpm1900_2200Percent'];

    const thisSmenaDistanceSumm = rows_experiment_clone.distance.val;

    // перебор значений передач в КПП
    for (let [gearKey, gearValues] of Object.entries(gearboxes)) {
      gearboxesClone[gearKey] = {};
      gearboxesClone[gearKey]['distPercent'] = { val: 0 };

      for (let [paramKey, paramValue] of Object.entries(gearValues)) {
        if (paramKey === 'dist') {
          gearboxesClone[gearKey][paramKey] = {};

          for (let distParam in paramValue) {
            if (distParam === 'periods') {
              gearboxesClone[gearKey][paramKey][distParam] = [];

              periods.forEach((period, periodIndex) => {
                if (period.isNull) {
                  gearboxesClone[gearKey][paramKey][distParam][periodIndex] =
                    null;
                } else {
                  gearboxesClone[gearKey][paramKey][distParam][periodIndex] =
                    paramValue[distParam][periodIndex];
                }
              });

              const summGearboxDist = summOfArray_helper(
                gearboxesClone[gearKey][paramKey][distParam],
              );
              gearboxesClone[gearKey][paramKey]['val'] = summGearboxDist;

              if (thisSmenaDistanceSumm) {
                gearboxesClone[gearKey]['distPercent']['val'] =
                  summGearboxDist / thisSmenaDistanceSumm;
              }
            } else if (distParam !== 'val') {
              gearboxesClone[gearKey][paramKey][distParam] =
                paramValue[distParam];
            }
          }
        } else if (paramKey === 'distPercent') {
          for (let distPercentKey in paramValue) {
            if (distPercentKey === 'val') {
              if (thisSmenaDistanceSumm) {
                gearboxesClone[gearKey][paramKey][distPercentKey] =
                  gearboxesClone[gearKey]['dist']['val'] /
                  thisSmenaDistanceSumm;
              } else {
                gearboxesClone[gearKey][paramKey][distPercentKey] = 0;
              }
            } else {
              gearboxesClone[gearKey][paramKey][distPercentKey] =
                paramValue[distPercentKey];
            }
          }
        } else if (paramKey === 'gearboxName') {
          gearboxesClone[gearKey][paramKey] = paramValue;
        }
        // else { // пока прочие параметры вообще не перекладываем, потому что они не делятся на смены
        //         gearboxesClone[gearKey][paramKey] = paramValue;
        //}
      }
    }
  }

  splitObjectBySmena_addCols(
    row_experiment_origin,
    rows_experiment_clone,
    periodSplitRow,
  ) {
    rows_experiment_clone[periodSplitRow] = {};
    const periodSplitRowClone = rows_experiment_clone[periodSplitRow];

    for (let colName in row_experiment_origin) {
      if (colName === 'periods') {
        periodSplitRowClone[colName] = [];
      } else if (colName === 'val') {
        periodSplitRowClone[colName] = 0;
      } else {
        periodSplitRowClone[colName] = row_experiment_origin[colName];
      }
    }
  }
}
