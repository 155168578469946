<template>
  <nav
    style="
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <ul class="pagination">
      <li
        class="page-item"
        :class="[{ disabled: isDisabled('prev') }]"
        @click="prevClick"
      >
        <a
          class="page-link"
          :href="props.href"
          tabindex="-1"
          :aria-disabled="{ disabled: isDisabled('prev') }"
        >
          <span class="desktop"> Предыдущая </span>
          <span class="mobile"> &lt; </span>
        </a>
      </li>

      <li
        v-for="obj in buttonsArray"
        class="page-item"
        :class="[{ active: obj.active }]"
        :aria-current="{ page: obj.active }"
        @click="buttonClick(obj.text - 1)"
      >
        <a
          class="page-link"
          :href="props.href"
          >{{ obj.text }}</a
        >
      </li>

      <li
        class="page-item"
        :class="[{ disabled: isDisabled('next') }]"
        :aria-disabled="{ disabled: isDisabled('next') }"
        @click="nextClick"
      >
        <a
          class="page-link"
          :href="props.href"
        >
          <span class="desktop"> Следующая </span>
          <span class="mobile"> &#62; </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  pageNumber: { type: Number },
  pagesLength: { type: Number },
  href: { type: String, default: '#' },
});

const emit = defineEmits(['changeValue']);

const buttonsArray = ref([]);

const setButtonsArray = () => {
  buttonsArray.value = [];

  const countButtonsOnOneSide = 1;
  for (
    let i = props.pageNumber - countButtonsOnOneSide;
    i < props.pagesLength;
    i++
  ) {
    if (i < 0) continue;
    if (i > props.pageNumber + countButtonsOnOneSide) break;

    const buttonObject = {
      text: i + 1,
      active: false,
    };

    if (i === props.pageNumber) {
      buttonObject.active = true;
    }

    buttonsArray.value.push(buttonObject);
  }
};

watch(() => props.pageNumber, setButtonsArray);
watch(() => props.pagesLength, setButtonsArray);
onMounted(setButtonsArray);

const selectHandler = (newIndex) => {
  emit('changeValue', newIndex);
};

const prevClick = () => {
  if (props.pageNumber !== 0) {
    selectHandler(props.pageNumber - 1);
  }
};

const nextClick = () => {
  if (props.pageNumber !== props.pagesLength - 1) {
    selectHandler(props.pageNumber + 1);
  }
};

const buttonClick = (pageNumber) => {
  selectHandler(pageNumber);
};

const isDisabled = (side) => {
  if (side === 'next') {
    return props.pageNumber === props.pagesLength - 1;
  }

  if (side === 'prev') {
    return props.pageNumber === 0;
  }
};
</script>

<style lang="scss" scoped>
.desktop {
  @media (max-width: 500px) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
}
.pagination {
  margin: 0;
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
}
</style>
