export const usePrepareGeofencesForReports = (
  geofencesIds = [],
  geofences = [],
) => {
  const geofencesLayerData = {};
  if (!geofencesIds.length) return geofencesLayerData;

  const geofencesById = geofences.reduce((acc, geo) => {
    acc[geo.id] = geo;
    return acc;
  }, {});

  for (const id of geofencesIds) {
    const geo = geofencesById[id];

    if (!geo) continue;

    const { layer } = geo;

    if (!geofencesLayerData[id]) geofencesLayerData[id] = {};

    geofencesLayerData[id].shape = layer.pm.getShape();
    geofencesLayerData[id]._mRadius = layer._mRadius;

    if (geofencesLayerData[id].shape === 'Polygon') {
      geofencesLayerData[id].latLngs = layer.getLatLngs();
    }

    if (geofencesLayerData[id].shape === 'Circle') {
      geofencesLayerData[id].radius = layer.getRadius();
      geofencesLayerData[id].latLng = layer.getLatLng();
    }
  }

  return geofencesLayerData;
};
