export type GroupBy = { label: string; value: 'code' | 'fault' };
export type SortBy = {
  label: string;
  value:
    | 'code'
    | 'mif'
    | 'is'
    | 'os'
    | 'lamp1'
    | 'lamp2'
    | 'adress'
    | 'priority';
};

export const DEFAULT_DESCENDING = true;

export const GROUP_BY_OPTIONS: GroupBy[] = [
  {
    label: 'Код',
    value: 'code',
  },
  {
    label: 'Неисправность',
    value: 'fault',
  },
];
export const SORT_BY_OPTIONS: SortBy[] = [
  {
    label: 'Код',
    value: 'code',
  },
  {
    label: 'MIF',
    value: 'mif',
  },
  {
    label: 'IS',
    value: 'is',
  },
  {
    label: 'OS',
    value: 'os',
  },
  {
    label: 'Адрес',
    value: 'adress',
  },
  {
    label: 'Приоритет',
    value: 'priority',
  },
];
