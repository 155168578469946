export const splitter = {
  mounted: (el, bind) => {
    const DEFAULT_DIRECTION = 'height'

    const parentNode = el.parentElement

    const beforeNode = parentNode.firstChild
    const afterNode = parentNode.lastChild

    parentNode.style.overflow = 'hidden'
    beforeNode.style.overflow = 'scroll'
    afterNode.style.overflow = 'scroll'

    setInitSize(
      bind.value?.initSize, 
      bind.value?.direction || DEFAULT_DIRECTION, 
      beforeNode, 
      afterNode
    )

    el.classList.add('my-splitter')
    
    const span = document.createElement('span')
    el.appendChild(span)

    el.addEventListener('mousedown', ()=> {
      document.addEventListener('mousemove', changeBeforeElemHeight)
    })
    
    document.addEventListener('mouseup', ()=> {
      document.removeEventListener('mousemove', changeBeforeElemHeight)
    })

    function setInitSize(initSize, direction, beforeNode, afterNode) {
      if (initSize && initSize[0] && initSize[1]) {
        beforeNode.style[direction] = `${initSize[0]}%`
        afterNode.style[direction] = `${initSize[1]}%`
      } else {
        const DEFAULT_SIZE = 50 
        beforeNode.style[direction] = `${DEFAULT_SIZE}%`
        afterNode.style[direction] = `${DEFAULT_SIZE}%`
      } 
    }
    
    function changeBeforeElemHeight(e) {
      const direction = bind.value.direction || DEFAULT_DIRECTION 
      const parentTop = parentNode.offsetTop

      let parentSize
      if (direction === 'height') {
        parentSize = parentNode.clientHeight
      } else {
        parentSize = parentNode.clientWidth
      }

      const onePercentInPX = parentSize / 100
      const Y = e.clientY - parentTop

      let afterSize = (parentSize - Y) / onePercentInPX
      
      if (afterSize > 100) afterSize = 100
      if (afterSize < 0) afterSize = 0

      beforeNode.style[direction] = `${100 - afterSize}%`
      afterNode.style[direction] = `${afterSize}%`
    }
  }
}