<template>
  <div
    class="vue-geofence-information"
  >
    <!-- <div class="vue-geofence-information-header">
      <button>Некая кнопка</button>
    </div> -->
    <table>
      <tr
        v-for="col in $options.COLUMNS"
        :key="col.key"
      >
        <th>{{ $options.COLUMNS_TRANSLATE[col.key] }}</th>
        <!-- <td>{{ getValue(col.key) }}</td> -->
        <td>
          <select
            v-if="col.select"
            v-model="geofence[col.key]"
            :disabled="!geofence.editing"
          >
            <option
              v-for="option in col.select"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <span v-else-if="!geofence.editing || !col.changeable">{{
            geofence[col.key]
          }}</span>
          <input
            v-else-if="col.type === 'Number'"
            @keydown="numberValidator"
            type="text"
            v-model.number="geofence[col.key]"
          />
          <input
            v-else
            type="text"
            v-model="geofence[col.key]"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { leafletMain } from '../../src/dataRequest/mainScript.js';

export default {
  props: {
    geofence: {
      type: Object,
      default: {},
    },
    informationHeightPercent: {
      type: Number,
      required: true,
    },
    editingKeys: {
      type: Object,
      required: true,
    },
  },
  inject: ['geofenceTypes'],
  data() {
    return {
      // radius: ' - '
    };
  },
  computed: {
    radius() {
      return this.geofence.radius;
    },
  },
  watch: {
    radius(val) {
      const { layer } = this.geofence;
      if (val > -1 && layer.setRadius) {
        layer.setRadius(Math.round(val));
        leafletMain.reInitLayer(layer);
        this.geofence.geoJson = leafletMain.getGeoJson(layer);
      }
    },
  },
  methods: {
    numberValidator(event) {
      if (!/^\d$|^Backspace$|^Delete$/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
  mounted() {
    this.$options.COLUMNS.find((item) => item.select).select =
      this.geofenceTypes;
  },
  COLUMNS_TRANSLATE: {
    name: 'Наименование',
    type: 'Вид',
    radius: 'Радиус (м)',
    speedLimit: 'Макс. скорость',
    info: 'Информация',
    owner: 'Владелец',
    organization: 'Организация',
    id: 'Код',
    geoJson: 'geoJson',
  },
  COLUMNS: [
    {
      key: 'name',
      changeable: true,
      type: 'String',
    },
    {
      key: 'type',
      changeable: false,
      type: 'select',
      select: [],
    },
    {
      key: 'radius',
      changeable: true,
      type: 'Number',
    },
    {
      key: 'speedLimit',
      changeable: true,
      type: 'Number',
    },
    {
      key: 'info',
      changeable: true,
      type: 'String',
    },
    // {
    //   key:'owner',
    //   changeable: false,
    //   type: 'String'
    // },
    // {
    //   key:'organization',
    //   changeable: true,
    //   type: 'String'
    // },
    {
      key: 'id',
      changeable: false,
      type: 'Number',
    },
    // {
    //   key:'geoJson',
    //   changeable: false,
    //   type: 'String'
    // },
  ],
};
</script>
<style>
select:disabled {
  width: 100%;
  opacity: 1;
}
.vue-geofence-information {
  position: relative;
}
/* .vue-geofence-information-header {
    position: sticky;
    top: -1px; /* Don't forget this, required for the stickiness * / 
  } */
table {
  min-width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 0 5px;
}
th {
  width: 123px;
  background-color: rgb(239, 239, 239);
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}
</style>
