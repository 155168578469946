import { jwtSender } from '@/src/jwtSender';
import { reactive } from 'vue';

export const useRequest = () => {
  const message = reactive({ type: 'success', value: '' });

  const url = (path) => {
    return `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }${path}`;
  };

  const send = async (
    path = '',
    method = 'GET',
    requestBody = {},
    headers = {},
  ) => {
    if (!path) throw Error('[useRequest] path is required');

    let body;

    if (requestBody instanceof FormData) {
      body = requestBody;
    } else {
      body = JSON.stringify(requestBody);
    }

    const options = {};

    options.method = method;
    options.headers = headers;

    if (method !== 'GET') {
      options.body = body;
    }

    const res = await jwtSender(url(path), options);

    return await res.json();
  };

  return { url, message, send };
};
