<template>
  <span
    :class="{
      'target-show': true,
      'section__button-send': props.isSectionButton
    }"
    :style="{'margin-top: -3px': props.isSectionButton}"
    @mouseenter="addHoverOnTarget($event)"
    @mouseleave="removeHoverOnTarget($event)"
  >
    <input
      :class="[
        'btn',
        'btn-primary',
        'config-btn-primary',
        'btn-sm',
        { [props.buttonAdditionalClass]: props.buttonAdditionalClass }
      ]"
      :style="{'height: 31px; margin-bottom: 1px': props.isSectionButton}"
      :type="props.isFormSubmit ? 'submit' : 'button'"
      :name="props.isFormSubmit? 'send': ''"
      :id="props.templateButtonId"
      value="Выполнить отчет"
      @click="executeReport"
    />
    <span 
      :class="[
        'target-show-span',
        'text-center',
        {[props.hintAdditionalClass]: props.hintAdditionalClass}
        ]">
      Обсчет данных для получения отчета
    </span>
  </span>
</template>
  
<script setup>
import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../../../helpers/targetsHelper';

const emit = defineEmits(['executeReport']);

const props = defineProps({
  isSectionButton: {
    type: Boolean,
    require: true,
  },
  isFormSubmit: {
    type: Boolean,
    require: true,
  },
  templateButtonId: {
    type: String,
    require: true,
  },
  buttonAdditionalClass: {
    type: String,
    require: true,
  },
  hintAdditionalClass: {
    type: String,
    required: true,
  }
});

function executeReport() {
  emit('executeReport');
}


</script>

<style lang="scss" scoped>
.section__button-send {
  width: 30%;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
}
</style>
  