import { CalculateGraphData } from './CalculateGraphData.js';

export class ViolationsIndicator {
  charts = {};
  data;
  chartSummData;
  area;
  element;
  subElements;

  constructor(componentName, area, pointers) {
    this.create(componentName, area, pointers);
  }

  template(componentName) {
    return `<div data-component=${componentName}>
            <div data-element="info">Выполните запрос за нужный период для получения графической информации</div>
            <div data-element="chartsInfo" class="text-center"></div>
            ${this.chartContainerTemplate('violationsSumm', 'height:65vh')}
            ${this.chartContainerTemplate('violationsSummPie', 'height:460px')}
            <div data-element="notDistList" class="border-top"></div>
            <div data-element="chartSummObjectList" class="text-center"></div>
        </div>`;
  }

  chartContainerTemplate(chartName, style = '') {
    this.charts[chartName] = null;
    const styleHtml = style ? ` style="${style}"` : '';

    return `<div class="chart-container"${styleHtml}>
            <canvas data-element="${chartName}"></canvas>
        </div>`;
  }

  async create(componentName, area, pointers) {
    const pointer = [...pointers].find((element) => {
      const elementComponentName = element.dataset.component;

      if (elementComponentName) {
        return Boolean(elementComponentName === componentName);
      }

      const dataElement =
        dataElement.dataset.component ||
        element.querySelector('[data-component]');

      if (dataElement && dataElement.dataset.component === componentName) {
        return true;
      }
    });

    if (pointer) {
      this.pointer = pointer;
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = this.template(componentName);
    this.componentName = componentName;

    const element = wrapper.firstChild;

    const calculateGraphData = new CalculateGraphData();

    this.element = element;
    this.subElements = calculateGraphData.getSubElements(this.element);

    if (area) {
      this.show(area);
    }
  }

  show(area) {
    if (!this.pointer.classList.contains('active')) {
      return;
    }

    const areaChild = area.firstChild;

    if (areaChild) {
      areaChild.remove();
    }

    area.append(this.element);
  }

  async writeInfo(innerHtml) {
    this.subElements.info.innerHTML = innerHtml;
  }

  async draw({
    area = null,
    objectsCalculated = {},
    objectsList = [],
    objectsDisableList = [],
    componentName,
  } = {}) {
    const { chartsInfo, chartSummObjectList, ratingTable, notDistList } =
      this.subElements;

    const { objects = [], format, rows_summ } = objectsCalculated;

    if (!objectsList.length) {
      this.writeInfo(
        '<span style="color:red">Для построения графика не выбран ни один объект.<span>',
      );
      return;
    }

    if (!objects.length) {
      this.writeInfo(
        '<span style="color:red">Получены пустые данные. Попробуйте проверить параметры и повторить запрос или обновить страницу.<span>',
      );
      return;
    }

    this.writeInfo('Данные получены. Анализирую...');

    const calculateGraphData = new CalculateGraphData({ format });

    const { objectsData, violationNames } = calculateGraphData.getObjectsData(
      objects,
      rows_summ,
    );
    const periodsLabels = calculateGraphData.getPeriodsLabels(
      objects[0],
      'Нарушения по парку ТС',
    );
    const chartSummData = calculateGraphData.chartSummAnalysis(
      objectsData,
      periodsLabels,
      violationNames,
    );

    this.chartSummData = chartSummData;
    this.data = {
      objectsData,
      periodsLabels,
    };

    const notDistArr = chartSummData['summData']['objectsRaiting']['notDist'];
    notDistList.innerHTML = calculateGraphData.notDistListTemplate(notDistArr);

    const violationSummOfPeriodsDatasets = this.getViolationSummOfPeriods({
      summOfPeriods: chartSummData['summData']['summOfPeriods'],
      notDistArr,
      violationNames,
      type: 'line',
    });

    this.graphsDestroy();
    this.graphsCreate(violationSummOfPeriodsDatasets);

    this.writeInfo('');
    chartsInfo.innerHTML = periodsLabels.title;
    chartSummObjectList.innerHTML = calculateGraphData.objectListTemplate(
      objectsList,
      objectsDisableList,
    );
  }

  getViolationSummOfPeriods({
    summOfPeriods,
    notDistArr,
    violationNames,
    type,
  }) {
    const violationArrNames = Object.keys(violationNames);
    const notDistObjIndexArr = notDistArr.map(
      (notDistObj) => notDistObj.objIndex,
    );
    const colorObject = {
      color: { r: 0, g: 255, b: 0, opasity: 1 },
    };

    const violationsSummOfPeriods = summOfPeriods.map((period, periodIndex) => {
      const summOfPeriod = violationArrNames.reduce(
        (accum, violationArrName) => {
          accum[violationArrName] = 0;
          return accum;
        },
        {},
      );

      period.violations.forEach((objViolations, objIndex) => {
        if (!notDistObjIndexArr.includes(objIndex)) {
          violationArrNames.forEach((violationArrName) => {
            summOfPeriod[violationArrName] +=
              objViolations[violationArrName] || 0;
          });
        }
      });

      return summOfPeriod;
    }, []);

    return violationArrNames.map((violationArrName) => {
      // const {objValues, objIndex} = object;
      const label = violationNames[violationArrName];
      const data = violationsSummOfPeriods.map(
        (period) => period[violationArrName],
      );

      colorHexShiftObject_helper(colorObject);

      return {
        type,
        label,
        data,
        borderColor: colorObject.text,
        backgroundColor: colorObject.text,
        borderWidth: 1,
        fill: false,
      };
    });
  }

  graphsDestroy() {
    for (const [key, chart] of Object.entries(this.charts)) {
      if (chart && chart.canvas) {
        chart.destroy();
      }
    }
  }

  graphsCreate(violationSummOfPeriodsDatasets) {
    const { violationsSumm, violationsSummPie } = this.subElements;
    const { labels, summData } = this.chartSummData;

    const violationSummOfPeriodsDataPie = violationSummOfPeriodsDatasets.reduce(
      (accum, period, violationIndex) => {
        const { label, backgroundColor, data } = period;

        const violationSumm = data.reduce((a, b) => {
          return a + b;
        }, 0);
        accum.labels[violationIndex] = `${label}: ${violationSumm}`;

        accum.datasets[0]['data'][violationIndex] = violationSumm;
        accum.datasets[0]['backgroundColor'][violationIndex] = backgroundColor;

        return accum;
      },
      {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            label: 'число нарушений',
          },
        ],
      },
    );

    this.charts.violationsSumm = this.createChart({
      labels,
      titleText: 'График по сумме каждого из нарушений по сменам',
      yAxesLabelString: 'Сумма нарушений по объектам',
      chartElement: violationsSumm,
      datasets: violationSummOfPeriodsDatasets.filter((item) => {
        return item.data.reduce((summ, val) => summ + val, 0);
      }),
    });

    this.charts.violationsSummPie = new Chart(violationsSummPie, {
      type: 'pie',
      data: violationSummOfPeriodsDataPie,
      options: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'График по сумме каждого из нарушений за весь период отчета',
        },
        borderAlign: 'inner',
        legend: {
          align: 'start',
          position: 'right',
          labels: {
            fontSize: 9,
            boxWidth: 20,
            padding: 3,
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const { index } = tooltipItem;
              const { labels } = data;

              const label = labels[index];
              return label;
            },
            // afterLabel: (tooltipItem, data) => {
            //     // let label = data.datasets[tooltipItem.datasetIndex].label || '';
            //     const yLabel = Math.round(tooltipItem.yLabel * 100) / 100;
            //     const xLabel = Math.round(tooltipItem.xLabel * 100) / 100;

            //     const grade = calculateGraphData.getDistForfeitsRelativeMedianaGrade(yLabel);

            //     return `пробег: ${xLabel} км; средний % отклонения от медианы: ${yLabel} (${grade})`;
            // }
          },
        },
      },
    });

    // this.charts.violationsSumm = new Chart(violationsSumm, {
    //     // The type of chart we want to create
    //     type: 'line',

    //     // The data for our dataset
    //     data: {
    //         labels: labels,
    //         datasets: [
    //             // {
    //             //     label: 'Нарушений всего',
    //             //     backgroundColor: 'rgba(255, 99, 132, 0.7)',
    //             //     borderColor: 'rgb(255, 99, 132)',
    //             //     borderWidth: 1,
    //             //     fill: false,
    //             //     data: summData.forfeits
    //             // },

    //             {
    //                 label: 'Нарушений на 100 км пробега среднее по выборке (медиана)',
    //                 backgroundColor: 'rgb(0,148,255)',
    //                 borderColor: 'rgb(0,148,255,0.7)',
    //                 borderWidth: 1,
    //                 data: summData.distForfeitsRelativeMedianasOfPeriods,
    //                 type: 'line',
    //                 fill: false
    //             },
    //             {
    //                 label: 'Нарушений на 100 км пробега среднее арифметическое по выборке',
    //                 backgroundColor: 'rgb(0,0,122)',
    //                 borderColor: 'rgb(0,0,122,0.7)',
    //                 borderWidth: 1,
    //                 data: summData.distForfeitsRelativeArithmeticAveragesOfPeriods,
    //                 type: 'line',
    //                 fill: false
    //             },
    //             {
    //                 label: 'Нарушений на 100 км пробега',
    //                 backgroundColor: 'rgb(255, 99, 132, 0.7)',
    //                 borderColor: 'rgb(255, 99, 132)',
    //                 borderWidth: 1,
    //                 data: summData.distForfeitsRelative,
    //                 type: 'line',
    //                 fill: true
    //             },
    //         ]
    //     },
    //     options: {
    //         title: {
    //             display: true,
    //             text: 'Нарушений на 100 км в разрезе смен: по парку в сумме, медиана и среднее арифметическое'
    //         },
    //         tooltips: {
    //             mode: 'index',
    //             intersect: false
    //         },
    //         maintainAspectRatio: false,
    //         scales: {
    //             xAxes: [{
    //                 ticks: {
    //                     fontSize: 6
    //                 },
    //                 offsetGridLines: true
    //             }],
    //             yAxes: [{
    //                 scaleLabel: {
    //                     display: true,
    //                     labelString: 'Нарушений на 100 км'
    //                 },
    //                 ticks: {
    //                     beginAtZero: true
    //                 }
    //             }]
    //         }
    //     }
    // });
  }

  createChart({ labels, datasets, chartElement, titleText, yAxesLabelString }) {
    const zeroLineWidth = 2;
    const zeroLineColor = 'rgba(0, 0, 0, 0.7)';

    return new Chart(chartElement, {
      data: {
        labels,
        datasets,
      },
      options: {
        title: {
          display: true,
          text: titleText,
          padding: 3,
        },
        legend: {
          align: 'start',
          labels: {
            fontSize: 9,
            boxWidth: 20,
            padding: 3,
          },
        },
        // tooltips: {
        //     mode: 'index',
        //     intersect: false
        // },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 6,
              },
              offsetGridLines: true,
              zeroLineWidth,
              zeroLineColor,
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: yAxesLabelString,
              },
              ticks: {
                beginAtZero: true,
              },
              zeroLineWidth,
              zeroLineColor,
            },
          ],
        },
      },
    });
  }

  getSummForViolation(objects, periodIndex) {
    // суммируем все нарушения по периоду
    rows_person.personForDisplay.forEach((group) => {
      group.forEach((arrName) => {
        if (!arrName.toLowerCase().includes('group', 0)) {
          // это НЕ название группировки
          const prevSmenaOfArrName = curSmena.rowsPerson[arrName] || {};

          const { DCount, DForfeits } = rows_person.person[arrName];
          const { DCount: DCountPrev = 0, DForfeits: DForfeitsPrev = 0 } =
            prevSmenaOfArrName;

          if (!rows_person.person[arrName]['periods']) {
            rows_person.person[arrName]['periods'] = [];
          }

          rows_person.person[arrName]['periods'][periodNum] = {
            DCount: DCount - DCountPrev,
            DForfeits: DForfeits - DForfeitsPrev,
          };

          curSmena.rowsPerson[arrName] = { DCount, DForfeits };
        }
      });
    });
  }

  // titleTemplate (smenasStr, t_interval) {
  //     return `<h5 class="center margin-none">Информация предоставлена ${t_interval}</h5><p class="margin-none">${smenasStr}</p>`;
  // }

  // getPeriodsLabels (object) {
  //     const {smenasStr = '', t_interval = ''} = object.view;
  //     const title = this.titleTemplate(smenasStr, t_interval);

  //     return object.periods.reduce((accum, period) => {
  //         const {TimeBegin, TimeEnd} = period;

  //         // const dateBegin = formatDateHelper(TimeBegin, 'dd.mm.yyyy hh:nn');
  //         const dateBegin = formatDateHelper(TimeBegin, 'dd.mm hh:nn');
  //         // const dateEnd = formatDateHelper(TimeEnd, 'dd.mm.yyyy hh:nn');
  //         const dateEnd = formatDateHelper(TimeEnd, 'dd.mm hh:nn');
  //         const label = `${dateBegin} - ${dateEnd}`;

  //         accum.periods.push({TimeBegin, TimeEnd});
  //         accum.labels.push(label);

  //         return accum;
  //     }, {periods: [], labels: [], title});
  // }

  removeChartData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    chart.update();
  }
}
