<template>
  <div
    v-show="isChangeColumn"
    class="vertical-line"
  ></div>
  <div
    id="objectsListContainer"
    class="freezeTable objectsListContainer"
    @scroll="showNextObjectsHandler"
  >
    <div class="target-show-spans-from-objects-table">
      <ui-target
        id="monitoring-target"
        text="Показать на карте последнюю полученную от объекта позицию"
      />

      <ui-target
        id="template-target"
        text="Выполнить выбранный отчет за указанный приод"
      />

      <ui-target
        id="object-name-target"
        text="Наименование объекта мониторинга в системе"
      />

      <ui-target
        id="state-number-target"
        text="Государственный регистрационный номер объекта мониторинга"
      />

      <ui-target
        id="get-positions-target"
        text="Запросить позиции за период и построить трек на карте"
      />

      <ui-target
        id="remove-positions-target"
        text="Удалить ранее получнный запрос и трек на карте"
      />
    </div>
    <!-- <div class=""> -->
    <table
      id="objectsListId"
      width="auto"
      border="1"
      cellpadding="0"
      cellspacing="0"
      class="objectsList objectsListId sortable-table freezeTable-table"
      @click="objectsTableEvent"
    >
      <thead>
        <tr>
          <th
            v-if="props.isforMonitoring"
            v-show="props.showTableColumns['lastPositionColumn']?.show"
            class="target-show"
            @mouseenter="addHoverOnTargetById($event, 'monitoring-target')"
            @mouseleave="removeHoverOnTargetById($event, 'monitoring-target')"
          >
            М
          </th>
          <th
            v-show="props.showTableColumns['checkboxColumn']?.show"
            class="target-show"
            @mouseenter="addHoverOnTargetById($event, 'template-target')"
            @mouseleave="removeHoverOnTargetById($event, 'template-target')"
          >
            О
          </th>
          <th
            v-show="props.showTableColumns['clientColumn']?.show"
            class="column-resize"
            data-td="td_client"
            :style="{ minWidth: isMobile && '100px' }"
          >
            Владелец
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['maskWithNameColumn']?.show"
            class="column-resize"
            data-td="td_full-name"
            :style="{ minWidth: isMobile && '200px' }"
          >
            Наименование
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['maskColumn']?.show"
            class="column-resize"
            data-td="td_mask"
            :style="{ minWidth: isMobile && '100px' }"
          >
            Маска
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['nameColumn']?.show"
            id="sortTableForName"
            class="target-show cursor-pointer column-resize"
            data-td="td_name"
            :style="{ minWidth: isMobile && '150px' }"
            @mouseenter="addHoverOnTargetById($event, 'object-name-target')"
            @mouseleave="removeHoverOnTargetById($event, 'object-name-target')"
            @click="sortTableOn('name')"
          >
            Наименование
            <div
              v-if="sortingColumn === 'name'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['stateNumberColumn']?.show"
            id="sortTableForStateNumber"
            class="target-show cursor-pointer column-resize"
            :style="{ minWidth: isMobile && '150px' }"
            data-td="td_state_number"
            @mouseenter="addHoverOnTargetById($event, 'state-number-target')"
            @mouseleave="removeHoverOnTargetById($event, 'state-number-target')"
            @click="sortTableOn('stateNumber')"
          >
            Гос. №
            <div
              v-if="sortingColumn === 'stateNumber'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
              @click="sortTableOn('stateNumber')"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['idColumn']?.show"
            id="sortTableForId"
            class="column-resize cursor-pointer"
            data-td="td_id"
            :style="{ minWidth: isMobile && '100px' }"
            @click="sortTableOn('id')"
          >
            ID
            <div
              v-if="sortingColumn === 'id'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['modelColumn']?.show"
            class="column-resize cursor-pointer"
            data-td="td_model"
            :style="{ minWidth: isMobile && '150px' }"
            @click="sortTableOn('model')"
          >
            Модель
            <div
              v-if="sortingColumn === 'model'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['lastPosTimeColumn']?.show"
            id="sortTableForLastPosTimeColumn"
            class="column-resize cursor-pointer"
            data-td="td_lastPosTimeColumn"
            @click="sortTableOn('lastPosTime')"
          >
            Последние данные
            <div
              v-if="sortingColumn === 'lastPosTime'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['speedColumn']?.show"
            id="sortTableForSpeed"
            class="column-resize cursor-pointer"
            data-td="td_speed"
            :style="{ minWidth: isMobile && '100px' }"
            @click="sortTableOn('speed')"
          >
            Скорость
            <div
              v-if="sortingColumn === 'speed'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['latColumn']?.show"
            class="column-resize"
            data-td="td_lat"
            :style="{ minWidth: isMobile && '150px' }"
          >
            Широта
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['lonColumn']?.show"
            class="column-resize"
            data-td="td_lon"
            :style="{ minWidth: isMobile && '150px' }"
          >
            Долгота
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['lastTimeAggregationColumn']?.show"
            class="column-resize cursor-pointer"
            data-td="td_last-time-aggregation"
            @click="sortTableOn('last_time_aggregation')"
          >
            Готовность отчета
            <div
              v-if="sortingColumn === 'last_time_aggregation'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['installTimeColumn']?.show"
            class="column-resize cursor-pointer"
            data-td="td_install-time"
            @click="sortTableOn('install_time')"
          >
            Дата установки
            <div
              v-if="sortingColumn === 'install_time'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-show="props.showTableColumns['vinColumn']?.show"
            class="column-resize cursor-pointer"
            data-td="td_vin"
            :style="{ minWidth: isMobile && '200px' }"
            @click="sortTableOn('vin')"
          >
            VIN
            <div
              v-if="sortingColumn === 'vin'"
              class="arrow absolute-arrow"
              :class="[
                sortingFromLargerToSmaller
                  ? 'absolute-arrow-bottom'
                  : 'absolute-arrow-top',
              ]"
            ></div>
            <span class="column-width-changer"></span>
          </th>
          <th
            v-if="props.isforMonitoring"
            v-show="props.showTableColumns['getPositionsColumn']?.show"
            class="target-show"
            @mouseenter="addHoverOnTargetById($event, 'get-positions-target')"
            @mouseleave="
              removeHoverOnTargetById($event, 'get-positions-target')
            "
          >
            З
          </th>
          <th
            v-show="props.showTableColumns['removePositionsColumn']?.show"
            class="target-show"
            @mouseenter="
              addHoverOnTargetById($event, 'remove-positions-target')
            "
            @mouseleave="
              removeHoverOnTargetById($event, 'remove-positions-target')
            "
          >
            <s>З</s>
          </th>
        </tr>
      </thead>

      <div
        class="loading"
        v-show="!objectsList.length"
      >
        <div class="loading__preloader">
          <preloader />
        </div>
        <div class="loading__text">
          {{ loadingProcessDescription }}
        </div>
      </div>

      <div :id="props.isforMonitoring ? 'objects-list-margin-top-handler': 'diagnostics-objects-list-margin-top-handler'"></div>
      <tbody
        id="objects-list-body"
        v-show="objectsList.length"
      >
        <template
          v-for="(item, index) in objectsList"
          :key="item.id + index + ''"
        >
          <tr
            :id="item.id"
            :data-name="item.name"
            v-if="isShowObjectRow(item.id, index)"
            class="object-list-row"
            :style="[{ height: rowHeight + 'px' }]"
          >
            <td
              v-if="props.isforMonitoring"
              v-show="props.showTableColumns['lastPositionColumn']?.show"
              class="my-text-inline target-show flex-center"
              :style="{ height: rowHeight + 'px' }"
              data-element="show-on-map"
              @mouseenter="addHoverOnTargetByParent"
              @mouseleave="removeHoverOnTargetByParent"
              @click="(e) => showOnMapHandler(e, item)"
            >
              <span
                class="showOnMap"
                :class="{ active: showOnMapList.indexOf(item.id) !== -1 }"
                :data-id="item.id"
              ></span>
              <span class="target-show-span">
                Показать на карте последнее известное местоположение
              </span>
            </td>

            <td
              v-show="props.showTableColumns['checkboxColumn']?.show"
              class="my-text-inline toTemplate"
            >
              <label class="toTemplate-label">
                <span class="toTemplate-label-span">
                  <input
                    class="toTemplate-checkbox"
                    name="objects"
                    type="checkbox"
                    :value="item.id"
                    :checked="checkedObjectsArr.includes(item.id)"
                    v-model="checkedObjectsArr"
                  />
                </span>
              </label>
            </td>
            <td
              v-show="props.showTableColumns['clientColumn']?.show"
              class="my-text-inline obj-client obj-client-search column-resize-body"
            >
              {{ item.client }}
            </td>

            <td
              v-show="props.showTableColumns['maskWithNameColumn']?.show"
              @mouseenter="(e) => displayObjectInfo(e, item)"
              @mouseleave="(e) => hideObjectInfo(e)"
              class="column-resize-body"
            >
              <span class="search-name">{{ item.name }}</span>
            </td>

            <td
              v-show="props.showTableColumns['maskColumn']?.show"
              class="column-resize-body"
            >
              <span>{{ getObjectMaskOrName(item.name, 'mask') }}</span>
            </td>

            <td
              v-show="props.showTableColumns['nameColumn']?.show"
              class="my-text-inline target-show column-resize-body"
              data-td="td_name"
              @mouseenter="(e) => displayObjectInfo(e, item)"
              @mouseleave="(e) => hideObjectInfo(e)"
            >
              <span>{{ getObjectMaskOrName(item.name, 'name') }}</span>
            </td>

            <td
              v-show="props.showTableColumns['stateNumberColumn']?.show"
              class="my-text-inline state-number search-stateNumber column-resize-body"
              @mouseenter="(e) => displayObjectInfo(e, item)"
              @mouseleave="(e) => hideObjectInfo(e)"
            >
              <span>{{ item.state_number }}</span>
            </td>

            <td
              v-show="props.showTableColumns['idColumn']?.show"
              class="my-text-inline column-resize-body"
            >
              {{ item.id }}
            </td>

            <td
              v-show="props.showTableColumns['modelColumn']?.show"
              class="my-text-inline obj-model obj-model-search column-resize-body"
            >
              {{ item.model }}
            </td>

            <td
              v-show="props.showTableColumns['lastPosTimeColumn']?.show"
              class="my-text-inline lastPosTime-on-map column-resize-body"
            >
              {{
                formatDateHelper(
                  new Date(item.lastPosTime * 1000),
                  'dd.mm.yyyy hh:nn:ss',
                )
              }}
            </td>

            <td
              v-show="props.showTableColumns['speedColumn']?.show"
              class="my-text-inline speed-on-map column-resize-body"
            >
              {{ mySpeed(item.speed) }}
            </td>

            <td
              v-show="props.showTableColumns['latColumn']?.show"
              class="my-text-inline lat-on-map column-resize-body"
            >
              {{ item.lat }}
            </td>

            <td
              v-show="props.showTableColumns['lonColumn']?.show"
              class="my-text-inline lon-on-map column-resize-body"
            >
              {{ item.lon }}
            </td>

            <td
              v-show="props.showTableColumns['lastTimeAggregationColumn']?.show"
              class="my-text-inline obj-last_time_aggregation column-resize-body"
              :data-last-time-aggregation="item.last_time_aggregation"
            >
              {{
                formatDateHelper(
                  new Date(item.last_time_aggregation * 1000),
                  'dd.mm.yyyy hh:nn:ss',
                )
              }}
            </td>

            <td
              v-show="props.showTableColumns['installTimeColumn']?.show"
              class="my-text-inline obj-install_time-search text-center column-resize-body"
              :data-install-time="item.install_time"
            >
              {{
                formatDateHelper(
                  new Date(item.install_time * 1000),
                  'dd.mm.yyyy',
                )
              }}
            </td>

            <td
              v-show="props.showTableColumns['vinColumn']?.show"
              class="my-text-inline search-vin column-resize-body"
            >
              {{ item.vin }}
            </td>

            <td class="my-text-inline head-on-map d-none column-resize-body">
              {{ item.head }}
            </td>

            <td
              v-if="props.isforMonitoring"
              v-show="props.showTableColumns['getPositionsColumn']?.show"
              class="my-text-inline target-show flex-center"
              data-element="track-get"
              :data-id="item.id"
              :id="`get-pos-${item.id}`"
              :style="[
                { height: rowHeight + 'px' },
                getPositionsList[item.id]?.borderBottom
                  ? { borderBottom: getPositionsList[item.id]?.borderBottom }
                  : {},
              ]"
              @mouseenter="addHoverOnTargetByParent"
              @mouseleave="removeHoverOnTargetByParent"
            >
              <span
                class="getPositions"
                :style="
                  getPositionsList[item.id]?.background
                    ? { background: getPositionsList[item.id]?.background }
                    : {}
                "
                @click="getElementPosition($event, item.id)"
              ></span>
              <span class="target-show-span">
                {{
                  getPositionsList[item.id]?.showSpan
                    ? getPositionsList[item.id].showSpan
                    : 'Запросить позиции'
                }}
              </span>
            </td>

            <td
              v-show="props.showTableColumns['removePositionsColumn']?.show"
              class="my-text-inline target-show text-center"
              data-element="track-clear"
              :id="`clear-pos-${item.id}`"
              @mouseenter="addHoverOnTargetByParent"
              @mouseleave="removeHoverOnTargetByParent"
            >
              <span
                class="clearPositions"
                :data-id="item.id"
                @click="(e) => clearTrack(e, item.id)"
                >X</span
              >
              <span class="target-show-span"> Очистить трек на карте </span>
            </td>
          </tr>
        </template>
      </tbody>
      <div :id="props.isforMonitoring ? 'objects-list-margin-bottom-handler' : 'diagnostics-objects-list-margin-bottom-handler'"></div>
    </table>
  </div>
  <!-- </div> -->

  <teleport to="body">
    <span
      :id="`obj-info-target-${targetObjectInfo.id}`"
      class="target-show-span padding-none obj-info"
      :class="[{ hover: targetObjectInfo.id }]"
    >
      <table class="table obj-info-table objectsList">
        <thead>
          <tr>
            <td
              colspan="2"
              class="text-center"
            >
              <span class="state-number">{{
                targetObjectInfo.state_number
              }}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>владелец</td>
            <td class="obj-client">
              {{ targetObjectInfo.client }}
            </td>
          </tr>
          <tr>
            <td>модель</td>
            <td class="obj-model">
              {{ targetObjectInfo.model }}
            </td>
          </tr>
          <tr>
            <td>Категория</td>
            <td class="obj-category">
              {{
                CATEGORIES[targetObjectInfo.category]
                  ? CATEGORIES[targetObjectInfo.category][2]
                  : '-'
              }}
            </td>
          </tr>
          <tr>
            <td>последние данные</td>
            <td class="obj-lastPosTime">
              {{
                targetObjectInfo.lastPosTime == 'не загружено'
                  ? targetObjectInfo.lastPosTime
                  : formatDateHelper(
                      new Date(targetObjectInfo.lastPosTime * 1000),
                      'dd.mm.yyyy hh:nn:ss',
                    )
              }}
            </td>
          </tr>
          <tr>
            <td>готовность отчета</td>
            <td class="obj-last_time_aggregation">
              {{
                formatDateHelper(
                  new Date(targetObjectInfo.last_time_aggregation * 1000),
                  'dd.mm.yyyy hh:nn:ss',
                )
              }}
            </td>
          </tr>
          <tr>
            <td>скорость, км/ч</td>
            <td class="obj-speed">
              {{ mySpeed(targetObjectInfo.speed) }}
            </td>
          </tr>
          <tr>
            <td>широта (lat)</td>
            <td class="obj-lat">
              {{ myToFixed(targetObjectInfo.lat, 5) }}
            </td>
          </tr>
          <tr>
            <td>долгота (lon)</td>
            <td class="obj-lon">{{ myToFixed(targetObjectInfo.lon, 5) }}</td>
          </tr>
          <tr>
            <td>дата установки</td>
            <td class="obj-install_time">
              {{
                formatDateHelper(
                  new Date(targetObjectInfo.install_time * 1000),
                  'dd.mm.yyyy',
                )
              }}
            </td>
          </tr>
          <tr>
            <td>ДВС</td>
            <td>
              {{ targetObjectInfo.motoMask & 1 ? 'Работает' : 'Не работает' }}
            </td>
          </tr>

          <tr>
            <td>Уровень топлива по CAN, л.</td>
            <td>
              {{
                typeof targetObjectInfo.p206 === 'string'
                  ? targetObjectInfo.p206
                  : `${targetObjectInfo.p206?.toFixed(0)}`
              }}
            </td>
          </tr>

          <tr>
            <td>Обороты ДВС, об/мин</td>
            <td>
              {{
                typeof targetObjectInfo.p208 === 'string'
                  ? targetObjectInfo.p208
                  : `${targetObjectInfo.p208?.toFixed(0)}`
              }}
            </td>
          </tr>

          <tr v-if="targetObjectInfo.isDevelon">
            <td>Температура гидравлического масла, %</td>
            <td>
              {{ targetObjectInfo.hydroiltmpr }}
            </td>
          </tr>

          <tr>
            <td>Температура ДВС, град.</td>
            <td>
              {{ targetObjectInfo.p201 > -40 ? targetObjectInfo.p201 : '-' }}
            </td>
          </tr>
        </tbody>
      </table>
    </span>
  </teleport>
</template>

<script setup>
import { formatDateHelper } from '@/helpers/main_helper';
import { ref, onMounted, watch, inject, computed, nextTick } from 'vue';

import Preloader from '../../../Components/Preloader.vue';
import UiTarget from './UiTarget.vue';

import {
  sortArrayByStringUp,
  sortArrayByStringDown,
} from '@/helpers/MineHelper';
import { callOurPositions } from '../../../use/getElementPosition';
import { callOurPositionsWithGo } from '../../../use/getElementPositionWithGo';
import {
  globalObjects,
  leafletMain,
  mobileEvents,
} from '../../../../src/dataRequest/mainScript';
import {
  addHoverOnTargetByParent,
  removeHoverOnTargetByParent,
  addHoverOnTargetById,
  removeHoverOnTargetById,
} from '../../../helpers/targetsHelper';
import fetchJson from '../../../../src/dataRequest/fetch-json';
import { calcElementPosition } from '@/App/use/calcElementPosition';
import { CATEGORIES } from '@/Template/Categories';

const props = defineProps({
  interval: {
    type: Array,
    require: true,
  },
  showObjectsKeys: {
    type: Object,
    require: true,
  },
  checkedObjectsKeys: {
    type: Array,
    require: true,
  },
  showTableColumns: {
    type: Object,
    required: true,
  },
  isforMonitoring: {
    type: Boolean,
    required: true,
  }
});
const emit = defineEmits(['heartbeatShow', 'update:checkedObjectsKeys']);

const rowHeight = '28';

const targetObjectInfo = ref({});

const showOnMapList = ref([]);

const getPositionsList = ref({});

const loadingProcessDescription = ref('Загрузка доступных объектов');

const sortingColumn = ref('name');
const sortingFromLargerToSmaller = ref(true);

const objectsListScrollTop = ref(0);

const tMouse = ref({
  // isMouseDown
  // tMouse.target
  // tMouse.targetWidth
  // targetPosX
});

const isChangeColumn = ref(false);

const clientWidth = computed(() =>
  document ? document.documentElement.clientWidth : 0,
);
const isMobile = computed(() => clientWidth.value < 450);

onMounted(async () => {
  addMouseListeners();

  await nextTick();

  refreshObjects();
});

const globalObjectsList = inject('globalObjectsList');
const projectData = inject('projectData');
const userData = inject('userData');

const objectsList = ref([]);
const objectsListUpdateTrigger = inject('objectsListUpdateTrigger');

watch(objectsListUpdateTrigger, () => {
  const objectsListKeys = Object.keys(globalObjectsList);

  for (let i = 0; i < objectsListKeys.length; i++) {
    if (!objectsList[objectsListKeys[i]]) {
      objectsList.value[i] = globalObjectsList[objectsListKeys[i]];
      objectsList.value[i]['show'] = true;
      continue;
    }
  }

  if (sortingFromLargerToSmaller.value) {
    objectsList.value = sortArrayByStringDown(
      objectsList.value,
      sortingColumn.value,
    );
  } else {
    objectsList.value = sortArrayByStringUp(
      objectsList.value,
      sortingColumn.value,
    );
  }
});

const showObjectsArr = computed({
  get() {
    return props.showObjectsKeys;
  },
});

const checkedObjectsArr = computed({
  get() {
    return props.checkedObjectsKeys;
  },
  set(id) {
    emit('update:checkedObjectsKeys', id);
  },
});

let oldNodeHeight = 1000;
const getObjectsListNodeHeight = () => {
  const objectsListNode = document.getElementById('objectsListContainer');

  if (objectsListNode.clientHeight) {
    oldNodeHeight = objectsListNode.clientHeight;
  }

  return oldNodeHeight;
};

const countShownTop = 20;
const countShownBottom = 20;
const isScrollElementsShow = (index, objectsListLength) => {
  let marginTopHandler;
  if (props.isforMonitoring) {
    marginTopHandler = document.getElementById(
      'objects-list-margin-top-handler',
    );
  } else {
    marginTopHandler = document.getElementById(
      'diagnostics-objects-list-margin-top-handler',
    );
  }
  let marginBottomHandler;
  if (props.isforMonitoring) {
    marginBottomHandler = document.getElementById(
      'objects-list-margin-bottom-handler',
    );
  } else {
    marginBottomHandler = document.getElementById(
      'diagnostics-objects-list-margin-bottom-handler',
    );
  }

  const objectsListNodeHeight = getObjectsListNodeHeight();

  const maxCountOfShowElements =
    Math.ceil(objectsListNodeHeight / rowHeight) +
    countShownTop +
    countShownBottom;

  const maxShowElement = Math.ceil(
    (objectsListScrollTop.value + objectsListNodeHeight) / rowHeight,
  );

  marginTopHandler.style.marginTop =
    (maxShowElement - maxCountOfShowElements - 1 + countShownTop) * rowHeight +
    'px';

  marginBottomHandler.style.marginBottom =
    objectsListLength * rowHeight -
    maxCountOfShowElements * rowHeight -
    (maxShowElement - maxCountOfShowElements) * rowHeight +
    'px';

  return (
    index < maxShowElement && index >= maxShowElement - maxCountOfShowElements
  );
};

let nowDisplayedIndex = 0;
let countDisplayedElements = 0;
const isShowObjectRow = (id, index) => {
  if (index === 0) {
    nowDisplayedIndex = 0;

    const isDisplayElements = Object.values(showObjectsArr.value);

    countDisplayedElements = isDisplayElements.reduce((acc, isDisplay) => {
      if (isDisplay) acc++;
      return acc;
    }, 0);
  }

  if (!showObjectsArr.value[id]) return false;

  const isShow = isScrollElementsShow(
    nowDisplayedIndex,
    countDisplayedElements,
  );

  nowDisplayedIndex++;
  return isShow;
};

const refreshObjects = async () => {
  try {
    const data = await fetchJson(
      `${
        process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
      }Api/getObjectsListFromTableApi`,
      {},
    );

    if (data['redirectUrl']) {
      emit('heartbeatShow', {
        connect: false,
        text: 'Требуется повторная авторизация',
      });
      throw new Error(error);
    }

    if (data.objects === null) {
      loadingProcessDescription.value = 'Нет доступных объектов';
      return;
    }

    projectData.title = data.title;
    projectData.description = data.description;
    projectData.isAdmin = data.isAdmin;
    projectData.login = data.login;
    projectData.redirectUrl = data.redirectUrl;
    projectData.senderRights = data.senderRights;
    projectData.userRole = data.userRole;

    userData.error = data.authorizatedUser.error;
    userData.isAuth = data.authorizatedUser.isAuth;
    userData.senderRights = data.authorizatedUser.senderRights;
    userData.user = data.authorizatedUser.user;

    updateObjectsList(data.objects);

    emit('heartbeatShow', {
      connect: true,
      text: 'Связь с сервером установлена',
    });

    objectsListUpdateTrigger.value = Date.now();

    setTimeout(refreshObjects, 10000);
  } catch (error) {
    console.error(error);

    loadingProcessDescription.value = 'Ошибка загрузки доступных объектов';

    emit('heartbeatShow', {
      connect: false,
      text: 'Нет связи с сервером',
    });

    setTimeout(refreshObjects, 15000);
  }
};

const updateObjectsList = async (objects) => {
  if (!objects.length) return;

  const objectsKeys = objects.map((obj) => obj.id);
  const objectsListKeys = Object.keys(globalObjectsList);

  if (JSON.stringify(objectsKeys) !== JSON.stringify(objectsListKeys)) {
    for (let id in globalObjectsList) {
      if (objects.findIndex((obj) => obj.id == id) == -1) {
        delete globalObjectsList[id];
      }
    }
  }

  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    const id = obj.id;

    if (id < 0) continue;

    const trObject = document.getElementById(id);

    if (!trObject || !globalObjectsList[id]) {
      globalObjectsList[id] = obj;
      continue;
    }

    updateContentObjectsList(id, id, id);
    updateContentObjectsList(id, 'name', obj.name);
    updateContentObjectsList(id, 'stateNumber', obj.stateNumber);

    if (
      globalObjectsList.lat !== obj.lat ||
      globalObjectsList.lon !== obj.lon
    ) {
      updateContentObjectsList(id, 'lat', obj.lat);
      updateContentObjectsList(id, 'lon', obj.lon);

      const showOnMap = showOnMapList.value.indexOf(id) !== -1;
      if (showOnMap) {
        // обновить объект на карте, без слежения
        leafletMain.leafletAddObjPositions(globalObjectsList[id], true);
      }
    }

    updateContentObjectsList(id, 'speed', obj.speed || '0');
    updateContentObjectsList(id, 'head', obj.head);
    updateContentObjectsList(id, 'lastPosTime', obj.lastPosTime);
    updateContentObjectsList(id, 'install_time', obj.install_time);
    updateContentObjectsList(
      id,
      'last_time_aggregation',
      obj.last_time_aggregation,
    );
  }

  function updateContentObjectsList(id, key, value) {
    if (globalObjectsList[id][key] !== value) {
      globalObjectsList[id][key] = value;
    }
  }
};

const sortTableOn = (str) => {
  sortingColumn.value = str;
  if (sortingColumn.value === str) {
    if (sortingFromLargerToSmaller.value) {
      objectsList.value = sortArrayByStringUp(objectsList.value, str);
    } else {
      objectsList.value = sortArrayByStringDown(objectsList.value, str);
    }
    sortingFromLargerToSmaller.value = !sortingFromLargerToSmaller.value;
  } else {
    sortingColumn.value = str;
    sortingFromLargerToSmaller.value = true;
    objectsList.value = sortArrayByStringDown(objectsList.value, str);
  }
};

const myToFixed = (val, order) => {
  return isNaN(+val) ? val : Number(val).toFixed(+order);
};
const mySpeed = (speed) => {
  if (speed == 'Отсутствует') return '0';
  return speed;
};

const getPositionsUpdate = (dataId, value) => {
  if (!getPositionsList.value[dataId]) {
    getPositionsList.value[dataId] = {};
  }
  getPositionsList.value[dataId] = {
    ...getPositionsList.value[dataId],
    ...value,
  };
};

const getElementPosition = (event, dataId) => {
  const timeInterval = {
    timeBegin: new Date(props.interval[0]).getTime(),
    timeEnd: new Date(props.interval[1]).getTime(),
  };
  const targetNode = getNeighborByClass_helper(
    event.target,
    'target-show-span',
  );

  if (event.ctrlKey && !event.altKey) {
    callOurPositionsWithGo(
      dataId,
      event.target,
      targetNode,
      timeInterval,
      true,
      true,
      {},
      getPositionsUpdate,
    );
  } else if (event.ctrlKey && event.altKey) {
    // запрос позиций с gps фильтром
    callOurPositions({
      obj: globalObjectsList[dataId] || { id: dataId },
      clickElem: event.target,
      targetElem: targetNode,
      timeInterval,
      isOnMap: true,
      isOtherQuery: false,
      isWebSocket: false,
      isGpsFilter: true,
      funcUpdateElemState: getPositionsUpdate,
    });
  } else {
    callOurPositions({
      obj: globalObjectsList[dataId] || { id: dataId },
      clickElem: event.target,
      targetElem: targetNode,
      timeInterval,
      isOnMap: true,
      isOtherQuery: true,
      isWebSocket: event.altKey,
      isGpsFilter: false,
      objectCategory: globalObjectsList[dataId]?.category,
      funcUpdateElemState: getPositionsUpdate,
    });
  }
};

const clearTrack = (event, dataId) => {
  if (!(dataId > 0)) {
    let targetE = getNeighborByClass_helper(event.target, 'target-show-span');
    infoShowText_helper(
      targetE,
      'ошибка запроса, попробуйте обновить страницу...',
    );
    return;
  }

  let objRow = document.getElementById(dataId);
  let objName = objRow.getAttribute('data-name');

  if (leafletMain.leafletClearPositions(dataId, objName, globalObjects)) {
    // clear style
    let item = document.getElementById('get-pos-' + dataId);
    getPositionsList.value[dataId] = undefined;
    let targetE = item.querySelector('.target-show-span');
    infoShowText_helper(targetE, 'Запросить позиции');

    // clear into list
    removeSelectOption(
      document.getElementById('positionsList'),
      '[value="' + dataId + '"]',
    );
  } else {
    infoShowText_helper(
      targetE,
      'ошибка удаления трека, попробуйте обновить страницу...',
    );
  }
};

const showOnMapHandler = (event, elem) => {
  let target = event.target;
  let objData = {};

  objData.dataId = elem.id;
  objData.name = elem.name;
  objData.stateNumber = elem.stateNumber;
  objData.lat = elem.lat;
  objData.lon = elem.lon;
  objData.speed = elem.speed;
  objData.head = elem.head;
  objData.lastPosTime = 1000 * elem.lastPosTime;
  const infoElem = getNeighborByClass_helper(target, 'target-show-span');
  const addPositions = leafletMain.leafletAddObjPositions(objData, false);
  if (addPositions.status) {
    // активировать кнопку - объект на карте отображается
    showOnMapList.value.push(elem.id);
  } else {
    if (addPositions.reason === 1) {
      infoShowText_helper(
        infoElem,
        'Для данного тс отсутствует информация о последнем местоположении',
      );
      setTimeout(() => {
        infoShowText_helper(
          infoElem,
          'Показать на карте последнее известное местоположение',
        );
      }, 5000);
    }
    // снять активацию кнопки - объект не отображается
    showOnMapList.value = showOnMapList.value.filter((el) => el !== elem.id);
  }
  objData = {};
  // alert('objName=' + objName + ' stateNumber' + objStateNumber + ' id=' + dataId + ' lat=' + lat + ' lon=' + lon);
};

function removeSelectOption(selectList, childSelector) {
  // remove option
  let childOption = selectList.querySelector(childSelector);
  if (childOption) {
    selectList.removeChild(childOption);
  }
  // add default option
  if (!selectList.options.length) {
    document.getElementById('violations-setting').innerHTML =
      'Меню нарушений на карте (нет запросов).';
    selectList.innerHTML += '<option value="none">Нет запросов</option>';
  } else {
    // проверить какой объект выводится - и по нему вывести настройку отображения
  }
}

const getObjectMaskOrName = (maskWithName, condName) => {
  if (!maskWithName || !condName) return '';
  const splitMaskWithName = maskWithName.split(' ');
  if (condName === 'mask') {
    return splitMaskWithName[0];
  }
  if (condName === 'name') {
    let name = '';

    for (let i = 0; i < splitMaskWithName.length; i++) {
      if (i === 0) continue;
      name += ' ' + splitMaskWithName[i];
    }

    return name;
  }
};

const showNextObjectsHandler = (e) => {
  const target = e.target;

  objectsListScrollTop.value = target.scrollTop;
};

const mouseMoveHandler = (e) => {
  if (!tMouse.value.target || !tMouse.value.isMouseDown) return;

  const size =
    e.clientX - tMouse.value.targetWidth - tMouse.value.targetPosX + 3; // 3 - change button width/2
  tMouse.value.verticalLine.style.left = e.clientX + 'px';
  tMouse.value.width = tMouse.value.targetWidth + size;
};

const mouseDownHandler = (e) => {
  const element = e.target.parentElement;

  if (!element.dataset || !element.dataset[`td`]) return;

  if (Date.now() - tMouse.value.mouseUpTime < 500) return;

  isChangeColumn.value = true;
  tMouse.value.isMouseDown = true;

  tMouse.value.verticalLine = document.querySelector('.vertical-line');
  const th = document.querySelector(`th[data-td='${element.dataset[`td`]}']`);

  tMouse.value.target = th;
  tMouse.value.targetWidth = th.clientWidth;
  tMouse.value.targetPosX = th.getBoundingClientRect().x;
};

const mouseUpHandler = (e) => {
  if (!isChangeColumn.value) return;

  tMouse.value.mouseUpTime = Date.now();

  isChangeColumn.value = false;
  tMouse.value.target.style.minWidth = tMouse.value.width + 'px';

  if (tMouse.value.width) tMouse.value.target.style.maxWidth = '25px';

  tMouse.value = {};
};

const addMouseListeners = () => {
  window.addEventListener('mousemove', mouseMoveHandler);
  window.addEventListener('mousedown', mouseDownHandler);
  window.addEventListener('mouseup', mouseUpHandler);
};

async function displayObjectInfo(e, item) {
  targetObjectInfo.value = item;
  await nextTick();
  const targetNode = document.getElementById(
    `obj-info-target-${targetObjectInfo.value.id}`,
  );
  e.target.addEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
}

function hideObjectInfo(e) {
  const targetNode = document.getElementById(
    `obj-info-target-${targetObjectInfo.value.id}`,
  );
  targetObjectInfo.value = {};
  e.target.removeEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
}
</script>

<style lang="scss" scoped>
.column-width-changer {
  cursor: col-resize;
  user-select: none;
  width: 5px;
  // background: #000;
  height: 28px;
  position: absolute;
  right: 0px;
  top: -1px;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 7px solid #000;
    border-left: 7px solid transparent;
  }
}
.vertical-line {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 1px;
  border-left: 2px dotted #ddd;
  height: 100vh;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}
.column-resize {
  max-width: 150px;
  &-body {
    max-width: 25px;
    width: 150px;
  }
}

.arrow.absolute-arrow {
  position: absolute;
  top: 25%;
  right: 15px;

  &.absolute-arrow-top {
    transform: translateY(-50%) rotate(-45deg) translate(1px, 3.5px);
  }
  &.absolute-arrow-bottom {
    transform: translateY(-50%) rotate(135deg) translate(-4px, -1px);
  }
}

.obj-info-table {
  width: auto;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #fff;
  & caption {
    white-space: nowrap;
    padding: 0 4px;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }
  & tbody tr td {
    height: auto;
    padding: 0 4px;
  }
}
.objectsListContainer {
  height: calc(100vh - 170px);
  overflow: scroll;
  padding: 0 0 0 5px;
  //background-color: #fff;
  /* для drag table row */
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
  @media (max-width: 420px) {
    height: calc(100vh - 200px);
  }

  & table {
    & thead tr th,
    & tbody tr td {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    & tbody {
      & tr {
        & td {
          height: 28px;
        }
      }
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 270px;
    height: 270px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // border-radius: 50%;
    // border: 1px solid #000;

    &__text {
      margin-top: 15px;
      font-size: 24px;
      text-align: center;
    }
  }
}
</style>
