<template>
  <div class="search">
    <form
      ref="form"
      @submit.prevent="(e) => props.submit(e)"
    >
      <input
        v-model="search"
        class="search__input"
        type="text"
        name="search_field"
        :placeholder="props.placeholder"
      />
    </form>
    <div
      class="search__button not-strict"
      :class="{ active: searchType == 'not-strict' }"
      title="Поиск по всему тексту (без учета регистра)"
      @click="() => (searchType = 'not-strict')"
    >
      <span> Ая </span>
    </div>
    <div
      class="search__button strict"
      :class="{ active: searchType == 'strict' }"
      title="Поиск точного совпадения (с учетом регистра)"
      @click="() => (searchType = 'strict')"
    >
      <span> Ая </span>
    </div>
    <div
      class="search__button regexp"
      :class="{ active: searchType == 'regexp' }"
      title="Поиск используя регулярное выражение (все совпадения, без учета регистра)"
      @click="() => (searchType = 'regexp')"
    >
      <span>.*</span>
    </div>

    <ui-filter
      v-if="isFilter"
      v-model:filter-selected="filterOn"
      :filter-data="props.filterData"
      style="margin-left: 10px"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import UiFilter from '../Filters/UiFilter.vue';

const props = defineProps({
  search: { type: String, default: '' },
  searchType: { type: String, default: 'strict' },
  filterSelected: { type: String, default: '' },
  filterData: { type: Object, default: {} },
  placeholder: { type: String, default: 'Поиск' },
  submit: { type: Function, default: () => {} },
});

const emit = defineEmits([
  'update:search',
  'update:filter-selected',
  'update:search-type',
]);

const form = ref(null);

const search = computed({
  get() {
    return props.search;
  },
  set(val) {
    emit('update:search', val);
  },
});

const searchType = computed({
  get() {
    return props.searchType;
  },
  set(val) {
    emit('update:search-type', val);
  },
});

const filterOn = computed({
  get() {
    return props.filterSelected;
  },
  set(val) {
    emit('update:filter-selected', val);
    props.submit({ target: form.value });
  },
});

const isFilter = computed(() => Object.keys(props.filterData).length);
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';

input:focus {
  outline: none;
}

.search {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 38px;
  font-size: 14px;

  &__input {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    width: 459px;

    padding: 6px 12px;

    background: $background-color;
    border: 1px solid $border-color;
    color: $txt-primary-color;
    border-radius: 25px;
  }
  &__button {
    margin: 5px 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: background 0.2s ease;

    &.not-strict {
      margin-left: -90px;
    }

    &.strict {
      & span {
        padding: 1px;
        line-height: 1rem;
        border: 1px solid $txt-primary-color;
        border-radius: 4px;
      }
    }

    &:hover,
    &.active {
      background: rgba($txt-secondary-color, 0.2);
    }
    & img {
      object-fit: cover;
    }
  }

  @media (max-width: 1175px) {
    height: 32px;
    font-size: 12px;

    &__button {
      margin: 2px 0;
    }

    & .filter {
      &__list {
        &.active {
          top: 32px;
        }
      }
    }
  }
}
</style>
