export class TrackPlayerMotion {
  leafletMain;
  globalObjects;
  lastIndex = 0;
  isPlay;
  pause;
  stop;
  playSpeedInterval;
  playSpeedMultiplier;
  timer;
  resolve;
  marker;

  constructor({
    panel,
    points,
    leafletMain,
    objName,
    stateNumber,
    getBegin,
    getEnd,
  } = {}) {
    this.panel = panel;
    this.points = points;
    this.objName = objName;
    this.stateNumber = stateNumber;
    this.leafletMain = leafletMain;
    this.getBegin = getBegin;
    this.getEnd = getEnd;

    this.init(panel);
  }

  init(panel) {
    const subElements = this.getSubElements(panel);
    this.addEventListeners(subElements);

    this.subElements = subElements;

    const posCount = this.points.latlngs.length;
    this.posCount = posCount;

    if (!posCount) {
      const notPosElem = document.createElement('span');
      notPosElem.innerText = 'нет позиций';
      notPosElem.classList.add('track-player-not-position');
      subElements.innerScale.append(notPosElem);
    } else {
      this.setSliderValue();
    }
  }

  setSliderValue() {
    this.subElements.playerSlider.min = this.lastIndex;
    this.subElements.playerSlider.max = this.posCount - 1;
    this.subElements.playerSlider.value = this.lastIndex;
  }

  stopEventWrapper() {
    this.that.stopEvent();
  }

  stopEvent() {
    this.stop = true;

    if (this.isPlay) {
      this.pause = true;
      this.breakPlayTrackAwaitPromise();
      setTimeout(() => this.stopEvent(), 10);
      return;
    }

    this.markerRemove();

    this.manageActiveClass();
    this.setTrackValues();

    this.subElements.playerSlider.value = 0;
    this.lastIndex = 0;

    this.pause = false;
    this.stop = false;
  }

  pauseEvent() {
    const that = this.that;
    that.pause = true;
    that.breakPlayTrackAwaitPromise();
    that.manageActiveClass('pauseButton');
  }

  playBackwardEvent() {
    this.that.playTrackPlanning('backward');
  }

  playForwardEvent() {
    this.that.playTrackPlanning('forward');
  }

  manageActiveClass(activeButton = '') {
    const buttons = ['playBackButton', 'playButton', 'pauseButton'];

    buttons.forEach((button) => {
      if (button === activeButton) {
        this.subElements[button].classList.add('active');
      } else {
        this.subElements[button].classList.remove('active');
      }
    });
  }

  changePlaySpeedEvent() {
    this.that.setPlaySpeedMultiplierAndInterval();
    this.that.breakPlayTrackAwaitPromise();
  }

  playTrackPlanning(direction = 'forward') {
    if (this.stop) {
      return;
    }

    if (this.isPlay) {
      this.pause = true;
      this.breakPlayTrackAwaitPromise();
      setTimeout(() => this.playTrackPlanning(direction), 10);
      return;
    }

    this.manageActiveClass(
      direction === 'forward' ? 'playButton' : 'playBackButton',
    );

    this.isPlay = true;
    this.pause = false;

    this.setPlaySpeedMultiplierAndInterval();

    this.playTrack({ direction });
  }

  setPlaySpeedMultiplierAndInterval() {
    const speedOptions = this.subElements.speedOptions;
    const speedIndex = speedOptions.options.selectedIndex;
    const speed = speedOptions.options[speedIndex].value;

    if (!this.posCount) {
      return;
    }

    if (speed === 'step') {
      this.playSpeedMultiplier = -1;
    } else if (speed.includes('s')) {
      const totalTime = parseInt(speed, 10);
      this.playSpeedInterval = Math.floor((totalTime * 1000) / this.posCount);
      this.playSpeedMultiplier = null;
    } else {
      this.playSpeedMultiplier = parseInt(speed, 10);
      this.playSpeedInterval = null;
    }
  }

  getLatLng(index) {
    const latlngs = this.points.latlngs;
    if (index > latlngs.length - 1) {
      index = latlngs.length;
    }
    const [lat, lng] = latlngs[index];
    return [lat, lng];
  }

  async playTrack({ direction = 'forward' } = {}) {
    const posCount = this.posCount;

    const isMarker = Boolean(this.marker);

    const step = direction === 'forward' ? 1 : -1;

    for (let i = +this.lastIndex; i > -1 && i < posCount; i += step) {
      if (this.pause) {
        this.isPlay = false;
        return;
      }

      const { viewTime } = this.points.params[i];
      const time = viewTime * 1000;

      this.markerMove({ value: i }, this);

      const iNext = i + step;

      if (iNext > -1 && iNext < posCount) {
        const multiplier = this.playSpeedMultiplier;

        if (multiplier < 0) {
          // 1 step
          if (isMarker) {
            // second and more click
            this.markerMove(
              {
                value: iNext,
              },
              this,
            );
          }

          const iNextNext = iNext + step;

          if (iNextNext > -1 && iNextNext < posCount) {
            this.isPlay = false;
            this.manageActiveClass('pauseButton');
            return;
          }

          break;
        }

        let timeoutInterval = this.playSpeedInterval;
        if (multiplier) {
          const { viewTime: viewTimeNext } = this.points.params[iNext];
          const timeNext = viewTimeNext * 1000;
          timeoutInterval = Math.floor(Math.abs(time - timeNext) / multiplier);
        }

        const maxTimeout = 2000;
        if (timeoutInterval > maxTimeout) {
          timeoutInterval = maxTimeout;
        }

        await new Promise((resolve) => {
          this.resolve = resolve;

          this.timer = setTimeout(() => {
            this.resolve = null;
            this.timer = null;
            resolve();
          }, timeoutInterval);
        });
      }
    }

    this.isPlay = false;
    this.subElements.playerSlider.value =
      direction === 'forward' ? this.posCount : 0;
    this.manageActiveClass();
  }

  breakPlayTrackAwaitPromise() {
    if (this.resolve) {
      this.resolve();
      this.resolve = null;
    }

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  addMarker() {
    if (this.posCount) {
      this.marker =
        this.marker ||
        L.marker(this.getLatLng(this.lastIndex)).addTo(this.leafletMain.map);
      this.marker.bindTooltip(this.stateNumber);
      if (!!this.stateNumber) this.marker.openTooltip();
    }
  }

  markerMoveEvent(e) {
    this.that.pause = true;
    this.that.manageActiveClass('pauseButton');
    this.that.markerMove(e.target, this.that);
  }

  markerMove({ value }, that) {
    const { playerSlider } = that.subElements;

    const getBegin = that.getBegin * 1000;
    const queryInterval = that.getEnd * 1000 - getBegin;

    const [lat, lng] = that.points.latlngs[value];

    that.addMarker();

    that.marker.setLatLng([lat, lng]);
    
    const {
      viewTime,
      speed,
      useGspdSpeed,
      head,
      gps = '-',
      maxSpeed,
      violationsSumm,
    } = that.points.params[value];

    const time = viewTime * 1000;
    const shift = time - getBegin;
    const percent = Math.floor((10000 * shift) / queryInterval) / 100;

    playerSlider.value = value;

    that.lastIndex = value;

    that.setTrackValues({
      time,
      speed,
      useGspdSpeed,
      head,
      gps,
      maxSpeed,
      lat,
      lng,
      violationsSumm,
    });
  }

  setTrackValues({
    time = null,
    speed = '-',
    useGspdSpeed = false,
    maxSpeed = '-',
    lat = '-',
    lng = '-',
    gps = '-',
    head = '-',
    violationsSumm = '-',
  } = {}) {
    const {
      trackPlayerValueTime,
      trackPlayerValueSpeed,
      trackPlayerValueSpeedGspdIndicator,
      trackPlayerValueLat,
      trackPlayerValueLon,
      trackPlayerValueMaxSpeed,
      trackPlayerValueGps,
      trackPlayerViolationsCount,
    } = this.subElements;
    
    trackPlayerValueTime.innerText = time
      ? formatDateHelper(new Date(time), 'dd-mm-yy hh:nn:ss')
      : '-';
    trackPlayerValueSpeed.innerText = time ? `${speed / 10} км/ч` : '-';
    trackPlayerValueSpeedGspdIndicator.style.display = useGspdSpeed ? 'block' : 'none';
    trackPlayerValueMaxSpeed.innerText = time ? `${maxSpeed / 10} км/ч` : '-';
    trackPlayerValueLat.innerText = time
      ? `${roundNumber_helper(lat, 6)}`
      : '-';
    trackPlayerValueLon.innerText = time
      ? `${roundNumber_helper(lng, 6)}`
      : '-';
    trackPlayerValueGps.innerText = `${gps}`;
    trackPlayerViolationsCount.innerText = `${violationsSumm}`;
  }

  addEventListeners(subElements) {
    const {
      playBackButton,
      pauseButton,
      playButton,
      playerSlider,
      stopButton,
      speedOptions,
    } = subElements;

    playBackButton.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.playBackwardEvent,
    });
    playButton.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.playForwardEvent,
    });
    pauseButton.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.pauseEvent,
    });
    stopButton.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.stopEventWrapper,
    });
    speedOptions.addEventListener('change', {
      that: this,
      handleEvent: this.changePlaySpeedEvent,
    });
    playerSlider.addEventListener('input', {
      that: this,
      handleEvent: this.markerMoveEvent,
    });
  }

  removeEventListeners() {
    const {
      playBackButton,
      pauseButton,
      playButton,
      stopButton,
      speedOptions,
    } = this.subElements;

    playBackButton.removeEventListener('pointerdown', {
      that: this,
      handleEvent: this.playBackwardEvent,
    });
    playButton.removeEventListener('pointerdown', {
      that: this,
      handleEvent: this.playForwardEvent,
    });
    pauseButton.removeEventListener('pointerdown', {
      that: this,
      handleEvent: this.pauseEvent,
    });
    stopButton.removeEventListener('pointerdown', {
      that: this,
      handleEvent: this.stopEventWrapper,
    });
    speedOptions.removeEventListener('change', {
      that: this,
      handleEvent: this.changePlaySpeedEvent,
    });
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  markerRemove() {
    if (this.marker) {
      this.marker.remove();
      this.marker = null;
    }
  }

  destroy() {
    this.removeEventListeners();
    this.stop = true;
    this.pause = true;
    this.breakPlayTrackAwaitPromise();
    this.panel = null;
    this.points = null;
    this.leafletMain = null;
    this.subElements = null;
    this.objName = null;
    this.stateNumber = null;
    this.markerRemove();
  }
}
