<template>
  <div>
    <p class="font-weight-bold">
      {{ replaceNbsp_helper(object.name) }} ({{
        replaceNbsp_helper(label)
      }})
    </p>
    <label
      class="not-print"
      :target="isGroupTarget"
    >
      <input
        type="checkbox"
        v-model="isGroup"
      />
      Сгруппировать рейсы
      <span class="checkbox-target">({{ isGroupTarget }})</span>
    </label>
    <!-- <br class="not-print"><label class="not-print">
      <input type="checkbox" v-model="isEventsForDebug"> 
      Показать детализацию (для отладки)
    </label> -->

    <regular-moving-by-geo-template-table-element
      v-if="!isGroup"
      tableCaption="Рейсы в хронологическом порядке (детализация):"
      :columns="regularMovingTemplateColumnsFilteredDetail"
      :rows="object.regularMovingRows.detailRows"
      :settingColumns="settingColumns"
      :getRegularMovingColumnValue="getRegularMovingColumnValue"
      :getRegularMovingColumnDataValue="getRegularMovingColumnDataValue"
      :getRegularMovingColumnDetailTable="getRegularMovingColumnDetailTable"
      :replaceNbsp_helper="replaceNbsp_helper"
      :showDetailTable="showDetailTable"
    ></regular-moving-by-geo-template-table-element>

    <regular-moving-by-geo-template-table-element
      v-if="!isGroup"
      tableCaption="Порожние поездки в хронологическом порядке (детализация):"
      :columns="emptyMovingTemplateColumnsFilteredDetail"
      :rows="object.regularMovingRows.detailRowsEmpty"
      :settingColumns="settingColumns"
      :getRegularMovingColumnValue="getRegularMovingColumnValue"
      :getRegularMovingColumnDataValue="getRegularMovingColumnDataValue"
      :getRegularMovingColumnDetailTable="getRegularMovingColumnDetailTable"
      :replaceNbsp_helper="replaceNbsp_helper"
      :showDetailTable="showDetailTable"
    ></regular-moving-by-geo-template-table-element>

    <regular-moving-by-geo-template-table-element
      v-if="isGroup"
      tableCaption="Рейсы cгруппированные:"
      :columns="regularMovingTemplateColumnsFilteredGroup"
      :rows="
        object.regularMovingRows.groupRows.regularMovingRowsGroup.concat(
          object.regularMovingRows.groupRows.onceMovingRowsGroup,
        )
      "
      :settingColumns="settingColumns"
      :getRegularMovingColumnValue="getRegularMovingColumnValue"
      :getRegularMovingColumnDataValue="getRegularMovingColumnDataValue"
      :getRegularMovingColumnDetailTable="getRegularMovingColumnDetailTable"
      :replaceNbsp_helper="replaceNbsp_helper"
      :showDetailTable="showDetailTable"
    ></regular-moving-by-geo-template-table-element>

    <regular-moving-by-geo-template-table-element
      v-if="isGroup"
      tableCaption="Порожние поездки cгруппированные:"
      :columns="emptyMovingTemplateColumnsFilteredGroup"
      :rows="
        object.regularMovingRows.groupRowsEmpty.regularMovingRowsGroup.concat(
          object.regularMovingRows.groupRowsEmpty.onceMovingRowsGroup,
        )
      "
      :settingColumns="settingColumns"
      :getRegularMovingColumnValue="getRegularMovingColumnValue"
      :getRegularMovingColumnDataValue="getRegularMovingColumnDataValue"
      :getRegularMovingColumnDetailTable="getRegularMovingColumnDetailTable"
      :replaceNbsp_helper="replaceNbsp_helper"
      :showDetailTable="showDetailTable"
    ></regular-moving-by-geo-template-table-element>

    <table v-if="isEventsForDebug">
      <caption align="left">
        События для отладки:
      </caption>
      <thead>
        <tr>
          <th
            v-for="column in templateColunnsForDebug"
            :key="column"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(eventsRow, index) in object.eventsRows"
          :key="index"
        >
          <td
            v-for="column in templateColunnsForDebug"
            :key="column"
          >
            {{ getColumnValue(eventsRow, column) }}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
</template>

<script>
import RegularMovingByGeoTemplateTableElement from './RegularMovingByGeoTemplateTableElement.vue';

export default {
  components: {
    RegularMovingByGeoTemplateTableElement,
  },
  inject: ['geofenceTypes', 'authorizatedUserRights'],
  props: {
    isGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWeight: {
      type: Boolean,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    replaceNbsp_helper: {
      type: Function,
      required: true,
    },
    showDetailTable: {
      type: Function,
      required: true,
    },
  },
  fuelRightsColuumns: {
    motoengine: true,
    motoenginenospd: true,
    motoenginespd: true,
    avgSpeed: true,
    expence100km: true,
    expence: true,
    canexpencenospd: true,
    canexpencespd: true,
    clutc_work_summ: true,
    clutchUseMoveStartExcessiveWorkPercent: true,
    violationsCount: true,
    violationsCount100Km: true,
  },
  regularMovingTemplateColumnsDetail: [
    'index',
    'timeBegin',
    'loaded',
    'weightSumm',
    'tonOfDist',
    'geofenceBein',
    'geofenceBeginType',
    'timeEnd',
    'unloaded',
    'geofenceEnd',
    'geofenceTypeEnd',
    'interval',
    'motoengine',
    'motoenginenospd',
    'motoenginespd',
    'dist',
    'avgSpeed',
    'expence100km',
    'expence',
    'canexpencenospd',
    'canexpencespd',
    'clutc_work_summ',
    'clutchUseMoveStartExcessiveWorkPercent',
    // 'violationsCount',
    'violationsCount100Km',
  ],
  emptyMovingTemplateColumnsDetail: [
    'index',
    'timeBeginEmpty',
    'geofenceBeinEmpty',
    'geofenceBeginTypeEmpty',
    'timeEndEmpty',
    'geofenceEndEmpty',
    'geofenceTypeEndEmpty',
    'interval',
    'motoengine',
    'motoenginenospd',
    'motoenginespd',
    'dist',
    'avgSpeed',
    'expence100km',
    'expence',
    'canexpencenospd',
    'canexpencespd',
    'clutc_work_summ',
    'clutchUseMoveStartExcessiveWorkPercent',
    // 'violationsCount',
    'violationsCount100Km',
  ],
  regularMovingTemplateColumnsGroup: [
    'index',
    'geofenceBein',
    'geofenceBeginType',
    'geofenceEnd',
    'geofenceTypeEnd',
    'routesCount',
    'loaded',
    'unloaded',
    'weightSumm',
    'tonOfDist',
    'interval',
    'motoengine',
    'motoenginenospd',
    'motoenginespd',
    'dist',
    'avgSpeed',
    'expence100km',
    'expence',
    'canexpencenospd',
    'canexpencespd',
    'clutc_work_summ',
    'clutchUseMoveStartExcessiveWorkPercent',
    // 'violationsCount',
    'violationsCount100Km',
  ],
  emptyMovingTemplateColumnsGroup: [
    'index',
    'geofenceBeinEmpty',
    'geofenceBeginTypeEmpty',
    'geofenceEndEmpty',
    'geofenceTypeEndEmpty',
    'routesCount',
    'interval',
    'motoengine',
    'motoenginenospd',
    'motoenginespd',
    'dist',
    'avgSpeed',
    'expence100km',
    'expence',
    'canexpencenospd',
    'canexpencespd',
    'clutc_work_summ',
    'clutchUseMoveStartExcessiveWorkPercent',
    // 'violationsCount',
    'violationsCount100Km',
  ],
  stopper: ' - ',
  SECONDS_PER_DAY: 86400,

  data() {
    return {
      isEventsForDebug: false,
      templateColunnsForDebug: [
        'humanTime',
        'time',
        'reason',
        'geo',
        'geo_in',
        'geo_out',
        'basket',
        'unloadingGeofences',
        'lat',
        'lon',
        't2_distance',
      ],
      settingColumns: {
        index: {
          text: '№ п/п',
          dataMinWidth: 3,
          formatNum: 12,
          style: {
            'min-width': '35px',
          },
          getOriginValue: ({ index } = {}) => index + 1,
        },
        routesCount: {
          text: 'Кол-во рейсов',
          dataMinWidth: 7,
          formatNum: 12,
          getOriginValue: ({ regularMovingRow, that } = {}) =>
            regularMovingRow.count ?? that.$options.stopper,
        },
        timeBegin: {
          text: 'Время погрузки',
          dataMinWidth: 17,
          formatNum: 1,
          function: ({ regularMovingRow } = {}) =>
            formatDateHelper(
              new Date(regularMovingRow.begin.time * 1000),
              'dd.mm.yyyy hh:nn:ss',
            ),
          getOriginValue: ({ regularMovingRow } = {}) =>
            regularMovingRow.begin.time * 1000,
        },
        loaded: {
          text: 'Погружено (т)',
          dataMinWidth: 10,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { weightLoaded = null } = regularMovingRow.calculated || {};
            if (weightLoaded === null) {
              return that.$options.stopper;
            }
            return weightLoaded;
          },
        },
        weightSumm: {
          text: 'К перевозке (т)',
          dataMinWidth: 10,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { weightSumm = null } = regularMovingRow.calculated || {};
            if (weightSumm === null) {
              return that.$options.stopper;
            }
            return weightSumm;
          },
        },
        unloaded: {
          text: 'Выгружено (т)',
          dataMinWidth: 10,
          formatNum: 14,
          // getOriginValue: ({regularMovingRow} = {}) => {
          //   const {weightSumm, weightSummLoad, weightSummIsLoad} = regularMovingRow.end;
          //   if (weightSummLoad && !weightSummIsLoad) {
          //     return weightSummLoad;
          //   }
          //   return 0;
          // },
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { weightUnLoaded = null } = regularMovingRow.calculated || {};
            if (weightUnLoaded === null) {
              return that.$options.stopper;
            }
            return weightUnLoaded;
          },
        },
        tonOfDist: {
          text: 'Работа по загрузкам и CAN (т*км)',
          dataMinWidth: 8.5,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { tonOfDist = null } = regularMovingRow.calculated || {};
            if (tonOfDist === null) {
              return that.$options.stopper;
            }
            return tonOfDist;
          },
        },
        timeBeginEmpty: {
          text: 'Время начала',
          dataMinWidth: 17,
          formatNum: 1,
          function: ({ regularMovingRow } = {}) =>
            formatDateHelper(
              new Date(regularMovingRow.begin.time * 1000),
              'dd.mm.yyyy hh:nn:ss',
            ),
          getOriginValue: ({ regularMovingRow } = {}) =>
            regularMovingRow.begin.time * 1000,
        },
        geofenceBein: {
          text: 'Геозона погрузки',
          style: {
            'min-width': '150px',
          },
          dataMinWidth: 16,
          // getOriginValue: ({regularMovingRow} = {}) => {
          //   const {loading} = regularMovingRow.begin.loadingUnloadingGeo;
          //   const names = loading.map(geo => {
          //     return geo.name;
          //   })
          //   return names.join('; ');
          // },
          getAHref: ({ regularMovingRow } = {}) => {
            const { loading } = regularMovingRow.begin.loadingUnloadingGeo;
            const aHrefs = loading.map((geo) => {
              const { lat, lng, name } = geo;
              if (!lat || !lng) {
                return name;
              }

              return `
              <a href="${
                process.env.VUE_APP_BACKEND_URL || window.location.origin
              }/track-motion?type=position&lat=${lat}&lon=${lng}" target="_blank">
                ${name}
              </a>`;
            });
            return aHrefs.join('; ');
          },
        },
        geofenceBeinEmpty: {
          text: 'Геозона начала',
          dataMinWidth: 30,
          style: {
            'min-width': '230px',
          },
          getAHref: ({ regularMovingRow } = {}) => {
            const { unloading } = regularMovingRow.begin.loadingUnloadingGeo;
            const aHrefs = unloading.map((geo) => {
              const { lat, lng, name } = geo;
              if (!lat || !lng) {
                return name;
              }
              return `
              <a href="${
                process.env.VUE_APP_BACKEND_URL || window.location.origin
              }/track-motion?type=position&lat=${lat}&lon=${lng}" target="_blank">
                ${name}
              </a>`;
            });
            return aHrefs.join('; ');
          },
          // getOriginValue: ({regularMovingRow} = {}) => {
          //   const {unloading} = regularMovingRow.begin.loadingUnloadingGeo;
          //   const names = unloading.map(geo => {
          //     return geo.name;
          //   })
          //   return names.join('; ');
          // }
        },
        geofenceBeginType: {
          text: 'Тип геозоны',
          dataMinWidth: 11.5,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { loading } = regularMovingRow.begin.loadingUnloadingGeo;
            const types = loading.map((geo) => {
              if (geo.isVirt) {
                return 'Виртуальная';
              }
              return that.geofenceTypesDict[geo.type];
            });
            return types.join('; ');
          },
        },
        geofenceBeginTypeEmpty: {
          text: 'Тип геозоны',
          dataMinWidth: 11.5,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { unloading } = regularMovingRow.begin.loadingUnloadingGeo;
            const types = unloading.map((geo) => {
              if (geo.isVirt) {
                return 'Виртуальная';
              }
              return that.geofenceTypesDict[geo.type];
            });
            return types.join('; ');
          },
        },
        timeEnd: {
          text: 'Время разгрузки',
          dataMinWidth: 17,
          formatNum: 1,
          function: ({ regularMovingRow, that } = {}) => {
            const time = regularMovingRow.end?.time;
            if (time) {
              return formatDateHelper(
                new Date(time * 1000),
                'dd.mm.yyyy hh:nn:ss',
              );
            }
            return that.$options.stopper;
          },
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { time } = regularMovingRow.end || {};
            if (time) {
              return time * 1000;
            }
            return that.$options.stopper;
          },
        },
        timeEndEmpty: {
          text: 'Время окончания',
          dataMinWidth: 17,
          formatNum: 1,
          function: ({ regularMovingRow, that } = {}) => {
            const time = regularMovingRow.end?.time;
            if (time) {
              return formatDateHelper(
                new Date(time * 1000),
                'dd.mm.yyyy hh:nn:ss',
              );
            }
            return that.$options.stopper;
          },
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { time } = regularMovingRow.end || {};
            if (time) {
              return time * 1000;
            }
            return that.$options.stopper;
          },
        },
        geofenceEnd: {
          text: 'Геозона разгрузки',
          dataMinWidth: 30,
          style: {
            'min-width': '230px',
          },
          // getOriginValue: ({regularMovingRow, that} = {}) => {
          //   const {unloading:unloadingGeofences} = (regularMovingRow.end?.loadingUnloadingGeo || {});
          //   if (unloadingGeofences) {
          //     const names = unloadingGeofences.map(geo => {
          //       return geo.name;
          //     });
          //     return names.join('; ');
          //   }
          //   return that.$options.stopper;
          // },
          getAHref: ({ regularMovingRow, that } = {}) => {
            const { unloading: unloadingGeofences } =
              regularMovingRow.end?.loadingUnloadingGeo || {};
            if (unloadingGeofences) {
              const aHrefs = unloadingGeofences.map((geo) => {
                const { lat, lng, name } = geo;
                if (!lat || !lng) {
                  return name;
                }
                return `
                <a href="${
                  process.env.VUE_APP_BACKEND_URL || window.location.origin
                }/track-motion?type=position&lat=${lat}&lon=${lng}" target="_blank">
                  ${name}
                </a>`;
              });
              return aHrefs.join('; ');
            }
            return that.$options.stopper;
          },
        },
        geofenceEndEmpty: {
          text: 'Геозона окончания',
          dataMinWidth: 30,
          style: {
            'min-width': '230px',
          },
          getAHref: ({ regularMovingRow, that } = {}) => {
            const { loading: loadingGeofences } =
              regularMovingRow.end?.loadingUnloadingGeo || {};
            if (loadingGeofences) {
              const aHrefs = loadingGeofences.map((geo) => {
                const { lat, lng, name } = geo;
                if (!lat || !lng) {
                  return name;
                }
                return `
                <a href="${
                  process.env.VUE_APP_BACKEND_URL || window.location.origin
                }/track-motion?type=position&lat=${lat}&lon=${lng}" target="_blank">
                  ${name}
                </a>`;
              });
              return aHrefs.join('; ');
            }
            return that.$options.stopper;
          },
        },
        geofenceTypeEnd: {
          text: 'Тип геозоны',
          dataMinWidth: 11.5,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { unloading: unloadingGeofences } =
              regularMovingRow.end?.loadingUnloadingGeo || {};
            if (unloadingGeofences) {
              const types = unloadingGeofences.map((geo) => {
                if (geo.isVirt) {
                  return 'Виртуальная';
                }
                return that.geofenceTypesDict[geo.type];
              });
              return types.join('; ');
            }
            return that.$options.stopper;
          },
        },
        geofenceTypeEndEmpty: {
          text: 'Тип геозоны',
          dataMinWidth: 11.5,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { loading: loadingGeofences } =
              regularMovingRow.end?.loadingUnloadingGeo || {};
            if (loadingGeofences) {
              const types = loadingGeofences.map((geo) => {
                if (geo.isVirt) {
                  return 'Виртуальная';
                }
                return that.geofenceTypesDict[geo.type];
              });
              return types.join('; ');
            }
            return that.$options.stopper;
          },
        },
        interval: {
          text: 'Интервал перемещения',
          dataMinWidth: 12.8,
          formatNum: 4,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { interval = null } = regularMovingRow.calculated || {};
            if (interval === null) {
              return that.$options.stopper;
            }
            return interval;
            // return formatTimeHelper(interval / 86400, 'tt:nn:ss');
          },
        },
        dist: {
          text: 'Пробег по CAN (км)',
          dataMinWidth: 8.5,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { dist = null } = regularMovingRow.calculated || {};
            if (dist === null) {
              return that.$options.stopper;
            }
            return dist;
          },
        },
        avgSpeed: {
          text: 'Средняя скорость (км/ч)',
          dataMinWidth: 10,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { avgSpeed = null } = regularMovingRow.calculated || {};
            if (avgSpeed === null) {
              return that.$options.stopper;
            }
            return avgSpeed;
          },
        },
        expence100km: {
          text: 'Расход по CAN (л/100 км)',
          dataMinWidth: 9,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { expence100km } = regularMovingRow.calculated || {};
            if (expence100km > 0) {
              return Math.round(expence100km * 10) / 10;
            }
            return that.$options.stopper;
          },
        },
        expence: {
          text: 'Расход по CAN (л)',
          dataMinWidth: 8.5,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, that } = {}) => {
            const { expence = null } = regularMovingRow.calculated || {};
            if (expence === null) {
              return that.$options.stopper;
            }
            return expence;
          },
        },
        canexpencenospd: {
          text: 'Расход по CAN на стоянке (л)',
          dataMinWidth: 9,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        canexpencespd: {
          text: 'Расход по CAN в движении (л)',
          dataMinWidth: 11,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        clutc_work_summ: {
          text: 'Работа сцепления (кДж)',
          dataMinWidth: 8.7,
          formatNum: 14,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        clutchUseMoveStartExcessiveWorkPercent: {
          text: 'Излишняя работа сцепления (%)',
          dataMinWidth: 11.6,
          formatNum: 6.5, // число уже в процентах
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        violationsCount: {
          text: 'Кол-во нарушений',
          dataMinWidth: 11.6,
          formatNum: 12,
          getDetailsTable({ regularMovingRow } = {}) {
            const { personTableRows } = regularMovingRow;
            if (personTableRows) {
              return {
                tName: 'person',
                rows: personTableRows,
              };
            }
            return {};
          },
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            const { summLine = {} } = regularMovingRow.personTableRows || {};
            const { forfeits = that.$options.stopper } = summLine;
            return forfeits;
          },
        },
        violationsCount100Km: {
          text: 'Нарушений на 100км',
          dataMinWidth: 11.6,
          formatNum: 13,

          getDetailsTable({ regularMovingRow } = {}) {
            const { personTableRows } = regularMovingRow;
            if (personTableRows) {
              return {
                tName: 'person',
                rows: personTableRows,
              };
            }
            return {};
          },
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            const { summLine = {} } = regularMovingRow.personTableRows || {};
            const { forfeitsRelative = that.$options.stopper } = summLine;
            return forfeitsRelative;
          },
        },
        motoengine: {
          text: 'Работа ДВС',
          dataMinWidth: 7.5,
          formatNum: 4,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        motoenginespd: {
          text: 'Работа ДВС в движении',
          dataMinWidth: 8.7,
          formatNum: 4,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        motoenginenospd: {
          text: 'Работа ДВС на стоянке',
          dataMinWidth: 7.6,
          formatNum: 4,
          getOriginValue: ({ regularMovingRow, column, that } = {}) => {
            return that.settingColumns.getSimpleCalculatedValue({
              regularMovingRow,
              column,
              that,
            });
          },
        },
        getSimpleCalculatedValue({ regularMovingRow, column, that } = {}) {
          const { calculated } = regularMovingRow;
          if (calculated) {
            return calculated[column];
          }
          return that.$options.stopper;
        },
      },
    };
  },
  computed: {
    label() {
      const startIndex = this.object.avtoNo.indexOf('>') + 1;
      const endIndex = this.object.avtoNo.lastIndexOf('<');
      const innerText = this.object.avtoNo.substring(startIndex, endIndex);
      return innerText
    },
    geofenceTypesDict() {
      return this.geofenceTypes.reduce((acc, geoType) => {
        const { value, text } = geoType;
        acc[value] = text;
        return acc;
      }, {});
    },
    isGroupTarget() {
      if (this.isGroup) {
        return 'выводится группировка по рейсам';
      }
      return 'выводятся рейсы в хронологическом порядке, детализация';
    },
    emptyMovingTemplateColumnsFilteredDetail() {
      return this.filterColumnsByRightsAndSettings(
        this.$options.emptyMovingTemplateColumnsDetail,
      );
    },
    regularMovingTemplateColumnsFilteredDetail() {
      return this.filterColumnsByRightsAndSettings(
        this.$options.regularMovingTemplateColumnsDetail,
      );
    },
    regularMovingTemplateColumnsFilteredGroup() {
      return this.filterColumnsByRightsAndSettings(
        this.$options.regularMovingTemplateColumnsGroup,
      );
    },
    emptyMovingTemplateColumnsFilteredGroup() {
      return this.filterColumnsByRightsAndSettings(
        this.$options.emptyMovingTemplateColumnsGroup,
      );
    },
  },
  methods: {
    filterColumnsByRightsAndSettings(columns) {
      const rule_fuel_watch = this.authorizatedUserRights.rule_fuel_watch;
      const fuelRightsColuumns = this.$options.fuelRightsColuumns;
      const isWeight = this.isWeight;
      const weightColumns = ['loaded', 'unloaded', 'weightSumm', 'tonOfDist'];
      return columns.filter((col) => {
        if (!rule_fuel_watch && fuelRightsColuumns[col]) {
          return false;
        }
        if (!isWeight && weightColumns.includes(col)) {
          return false;
        }
        return true;
      });
      // if (this.authorizatedUserRights.rule_fuel_watch && this.isWeight) {
      //   return columns;
      // }
      // if (this.authorizatedUserRights.rule_fuel_watch) {
      //   return columns;
      // }
      // return columns.filter((col) => !(this.$options.fuelRightsColuumns[col]));
    },
    getRegularMovingColumnValue(regularMovingRow, column, index) {
      return this.settingColumns[column].function({
        regularMovingRow,
        index,
        column,
        that: this,
      });
    },
    getRegularMovingColumnDataValue(regularMovingRow, column, index) {
      const settingColumn = this.settingColumns[column];
      if ('getOriginValue' in settingColumn) {
        return settingColumn.getOriginValue({
          regularMovingRow,
          index,
          column,
          that: this,
        });
      }
      if ('getAHref' in settingColumn) {
        return settingColumn.getAHref({
          regularMovingRow,
          index,
          column,
          that: this,
        });
      }
      if ('getAHref' in settingColumn) {
        return settingColumn.getAHref({
          regularMovingRow,
          index,
          column,
          that: this,
        });
      }
      return '';
    },
    getRegularMovingColumnDetailTable(regularMovingRow, column, index) {
      const settingColumn = this.settingColumns[column];
      if ('getDetailsTable' in settingColumn) {
        return settingColumn.getDetailsTable({ regularMovingRow });
      }
      return {};
    },
    getColumnValue(row, column) {
      if (column === 'unloadingGeofences') {
        const { unloading: unloadingGeofences } = row.loadingUnloadingGeo || {};
        if (unloadingGeofences) {
          const names = unloadingGeofences.map((geo) => {
            return geo.name;
          });
          return names.join('; ');
        }
      }
      return row[column];
    },
  },
};
</script>

<style scoped>
table {
  margin: 6px 3px;
  margin-bottom: 5px;
}
table caption {
  text-align: left;
  caption-side: top;
  margin: 0;
  padding: 0 5px;
  padding-top: 1px;
  padding-bottom: 0;
  padding-left: 5px;
}
.checkbox-target {
  color: blue;
}
.font-weight-bold {
  font-weight: bold;
}
.display-none {
  display: none;
}
</style>
