<template>
  <table
    border="1"
    cellpadding="5"
    data-excelautofilter
    style="
      border-collapse: collapse;
      border: 1px solid black;
      caption-side: top;
      margin-right: 5px;
    "
    class="sortable-table freezeTable-table"
  >
    <caption
      class="not-excel"
      style="caption-side: top; padding: 4px 2px"
    >
      Суммарные показатели
    </caption>
    <thead>
      <tr class="border-bottom-tr print-repeat print-frozen-row">
        <template
          v-for="(obj, key) in TABLE_HEADER"
          :key="key"
        >
          <th
            :data-minwidth="obj.excelWidth"
            :data-formatnum="obj.formatNum"
            data-excelalignh="center"
            data-excelalignv="middle"
            :style="obj.style"
            v-html="obj.html"
          ></th>
        </template>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) of summaryRows"
        :style="{
          borderTop: index == summaryRows.length - 1 ? '2px solid' : '',
        }"
      >
        <td
          v-for="(obj, key) in TABLE_HEADER"
          :data-formatnum="obj.formatNum"
          :data-value="obj.getExcelValue ? obj.getExcelValue(row) : ''"
          :style="obj.style"
        >
          {{ obj.get ? obj.get(row) : row[key] }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="not-print excel-bottom-indent margin-bottom-small"></div>
</template>

<script setup>
import { inject, ref, watch } from 'vue';

const props = defineProps({
  trigger: Boolean,
  data: { type: Object, default: {} },
});

const TABLE_HEADER = {
  name: {
    html: 'Объект',
    excelWidth: 20,
    formatNum: 0,
    style: {
      'min-width': '200px',
    },
  },
  stateNumber: {
    html: 'Гос. номер',
    excelWidth: 15,
    formatNum: 0,
    style: {
      'min-width': '150px',
    },
  },
  summInterval: {
    html: 'Общая длительность превышений',
    excelWidth: 35,
    formatNum: 4,
    style: {
      'min-width': '100px',
    },
    getExcelValue(row) {
      return row.interval / 86400;
    },
    get(row) {
      return formatTimeHelper(row.interval / 86400, 'tt:nn:ss');
    },
  },
  maxSpeed: {
    html: 'Максимальная скорость превышения',
    excelWidth: 40,
    formatNum: 12,
    style: {
      'min-width': '200px',
    },
    get(row) {
      return !isNaN(row.maxSpeed)
        ? myRoundNumber_helper(row.maxSpeed, 1)
        : row.maxSpeed;
    },
  },
  maxOverspeed: {
    html: 'Максимальное превышение',
    excelWidth: 30,
    formatNum: 12,
    style: {
      'min-width': '150px',
    },
    get(row) {
      return !isNaN(row.maxOverspeed)
        ? myRoundNumber_helper(row.maxOverspeed, 1)
        : row.maxOverspeed;
    },
  },
  count: {
    html: 'Кол-во превышений',
    excelWidth: 20,
    formatNum: 12,
    style: {
      'min-width': '100px',
    },
    get(row) {
      return !isNaN(row.count) ? myRoundNumber_helper(row.count, 1) : row.count;
    },
  },
};

const objectsList = inject('globalObjectsList');

const summaryRows = ref([]);

function setSummaryData() {
  const rows = [];

  const summaryRow = {
    name: 'Итого:',
    stateNumber: '-',
    interval: 0,
    maxSpeed: 0,
    maxOverspeed: 0,
    count: 0,
  };

  for (let objData of props.data) {
    const { name, stateNumber } = objectsList[objData.oid];

    let interval = 0;
    let maxSpeed = 0;

    objData.events.forEach((obj) => {
      interval += +obj.ti;

      maxSpeed = obj.mspd > maxSpeed ? obj.mspd : maxSpeed;
    });

    const count = objData.events.length;

    const maxOverspeed = count ? maxSpeed - objData.events[0].sspd : 0;

    rows.push({
      name,
      stateNumber,
      interval,
      maxSpeed,
      maxOverspeed,
      count,
    });

    summaryRow.interval += +interval;
    summaryRow.count += count;
    if (maxSpeed > summaryRow.maxSpeed) {
      summaryRow.maxSpeed = maxSpeed;
    }
    if (maxOverspeed > summaryRow.maxOverspeed) {
      summaryRow.maxOverspeed = maxOverspeed;
    }
  }

  rows.push(summaryRow);

  summaryRows.value = rows;
}

watch(
  () => props.trigger,
  (val) => {
    setSummaryData();
  },
  { deep: true, immediate: true },
);
</script>

<style lang="scss" scoped></style>
