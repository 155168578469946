<template>
  <div>
    <div
      class="geofencies-header"
      ref="geofenciesHeader"
    >
      <button
        @click="getGeofencesByApi"
        :class="`btn btn-${getGeofencesClass} `"
        :title="getGeofencesTitle"
        :disabled="
          isGeofencesUpdating || !authorizatedUserRights.rule_geofences_watch
        "
      >
        {{ isGeofencesUpdating ? 'Ожидание' : 'Обновить' }}
      </button>
      <button
        @click="radactMode = !radactMode"
        class="btn btn-primary config-btn-primary"
        v-if="redactModeButtonShowing"
      >
        Редактор {{ radactMode ? 'выключить' : 'включить' }}
      </button>
      <button
        @click="createGeofence"
        class="btn btn-primary config-btn-primary"
        :disabled="creatingMode"
        v-if="radactMode"
      >
        Создать
      </button>

      <div class="d-flex geofencies-search mt-1">
        <input
          v-model="search"
          type="text"
          placeholder="Поиск"
        />
        <select v-model="searchType">
          <option
            v-for="obj in $options.searchTypes"
            :key="obj.text"
            :value="obj.value"
            v-html="obj.text"
          ></option>
        </select>
      </div>
    </div>
    <div style="height: calc(-125px + 100vh)">
      <div>
        <vue-geofencies-list
          v-model:scroll-to-id="scrollToElementId"
          :allGeofences="allGeofences"
          :selectedGeofence="selectedGeofence"
          :table-height="tableHeight"
          :radactMode="radactMode"
          :editingKeys="$options.EDITING_KEYS"
          :rule_geofences_delete="authorizatedUserRights.rule_geofences_delete"
          :rule_geofences_create="authorizatedUserRights.rule_geofences_create"
          :rule_geofences_update="authorizatedUserRights.rule_geofences_update"
          :show_geofence="showGeofence"
          :search-type="searchType"
          @remove="removeGeofence"
          @geofence-select="setSelectedGeofence"
          @geofence-show-on-map="geofenceShowOnMap"
          @geo-edit="editGeofence"
          @geo-edit-cancel="cancelEditGeofence"
          @geo-save="saveGeofence"
          @sort-table-by="sortTableBy"
        />
      </div>
      <div v-splitter="{ direction: 'height', initSize: [70, 30] }"></div>
      <div>
        <vue-geofence-information
          :geofence="selectedGeofence"
          :information-height-percent="informationHeightPercent"
          :editingKeys="$options.EDITING_KEYS"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { leafletMain } from '../../src/dataRequest/mainScript.js';
import VueGeofenciesList from './VueGeofenciesList.vue';
import VueGeofenceInformation from './VueGeofenceInformation.vue';
import fetchJson from '../../src/dataRequest/fetch-json';
import UiField from '@/ManualApp/Components/Fields/UiField.vue';

import { splitter } from '@/Directives/Splitter/Splitter';

export default {
  URL_GET_QUERY: `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/geofences/get`,
  URL_SAVED_QUERY: `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/geofences/save`,
  URL_DELETED_QUERY: `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/geofences/delete`,

  LAT_LNG_MULTIPLIER: 1_000_000_000_000_000,

  name: 'vue-geofences',

  searchTypes: [
    {
      value: 'name',
      text: 'Наименование',
    },
    {
      value: 'id',
      text: 'ID',
    },
  ],

  components: {
    VueGeofenciesList,
    VueGeofenceInformation,
    UiField,
  },
  props: { isShow: { type: Boolean, default: false } },
  inject: [
    'geofencesUpdating',
    'setGeofencesUpdating',
    // "isGeofencesUpdating",
    'authorizatedUserRights',
    'allGeofences',
  ],

  directives: {
    splitter,
  },

  data() {
    return {
      showMonitoring: 'objects',
      radactMode: false,
      // allGeofences: [],
      topElementsHeight: 0,
      informationHeightPercent: 25,
      windowHeight: 0,
      selectedGeofence: {}, // выбранная геозона для окна информация (может быть только одна)
      geofenceWaitingForming: {}, // ожидает форму геозоны на карте (может быть только одна)
      editingGeofences: new Map(), // хранит геозоны для редактирования, треюуется чобы в leaflet не передавать gefence, а только слой layer
      getGeofencesError: '',
      scrollToElementId: '',
      // geofencesUpdating: false,
      search: '',
      searchType: 'name',
    };
  },

  MAIN_MENU_EL: null,
  CONFIRM_TEXTS: ['да', 'yes'],
  IS_SHOW_PREV: false,
  REDACTING_GEO_COLOR: '#DC143C',
  GEO_COLOR: '#3388ff',

  SECTION_MONITORING_SIDEBAR_SWITCHER: null,

  EDITING_KEYS: [
    {
      key: 'name',
      type: 'String',
    },
    {
      key: 'newRadius',
      type: 'Number',
    },
    {
      key: 'shape',
      type: 'String',
    },
    {
      key: 'speedLimit',
      type: 'Number',
    },
    {
      key: 'geoJson',
      type: 'String',
    },
    {
      key: 'radius',
      type: 'Number',
    },
    {
      key: 'type',
      type: 'String',
    },
  ],

  NEW_GEOFENCE() {
    return {
      name: 'Новая геозона',
      newRadius: 0,
      shape: '',
      speedLimit: 0,
      info: '',
      owner: '',
      organization: '',
      type: 0,
      id: '-',
      geoJson: '',
      radius: '-',
      // saved: false,
      editing: false,
      layer: null,
      key: new Date().getTime(),
      // geoJsonBeforeEdit
      beforeEdit: {},
      lastEdit: {},
      sending: false,
      sendingError: '',
      deleting: false,
      deletingError: '',
      isVisible: true,
    };
  },

  computed: {
    redactModeButtonShowing() {
      const {
        rule_geofences_create,
        rule_geofences_delete,
        rule_geofences_update,
        rule_geofences_watch,
      } = this.authorizatedUserRights;

      return Boolean(
        rule_geofences_create || rule_geofences_update || rule_geofences_delete,
      );
    },
    tableHeight() {
      const { windowHeight, topElementsHeight, informationHeightPercent } =
        this;
      if (!windowHeight || !topElementsHeight || !this.isShow) {
        return '50vh';
      }
      const heightVh =
        (100 * (windowHeight - topElementsHeight - 1)) / windowHeight;
      return `${heightVh - informationHeightPercent}vh`;
    },
    isGeofencesUpdating() {
      return this.geofencesUpdating.flag;
    },
    creatingMode() {
      return Boolean('key' in this.geofenceWaitingForming);
      // return Boolean(this.allGeofences.find((g) => !g.geoJson));
    },
    pmControlEditOn() {
      return Boolean(this.editingGeofences.size);
    },
    getGeofencesClass() {
      return this.getGeofencesError ? 'danger' : 'primary config-btn-primary';
    },
    getGeofencesTitle() {
      if (!this.authorizatedUserRights.rule_geofences_watch) {
        return 'Недостаточно прав для просмотра геозон';
      }
      return this.getGeofencesError;
    },
  },
  watch: {
    pmControlEditOn(val) {
      leafletMain.pmControlEditSet(val);
      if (!this.radactMode) {
        leafletMain.removeGeofencesControls();
      }
    },
    creatingMode(val) {
      leafletMain.pmControlDrawSet(val);
      if (!this.radactMode) {
        leafletMain.removeGeofencesControls();
      }
    },
    radactMode(val) {
      if (val) {
        leafletMain.addGeofencesControls();
        return;
      }
      leafletMain.removeGeofencesControls();
    },
    isShow() {
      if (this.isShow) {
        this.$nextTick(this.resizeWindow);
      }
    },
    search(str) {
      this.searchForGeofences(str);
    },
    searchType(t) {
      this.searchForGeofences(this.search);
    },
  },
  methods: {
    sortTableBy(key, isFromMoreToLess) {
      this.allGeofences.sort((a, b) => {
        if (typeof a[key] == 'string' && typeof b[key] == 'string') {
          if (isFromMoreToLess) {
            return a[key].toUpperCase() < b[key].toUpperCase();
          } else {
            return a[key].toUpperCase() > b[key].toUpperCase();
          }
        } else {
          if (isFromMoreToLess) {
            return b[key] - a[key];
          } else {
            return a[key] - b[key];
          }
        }
      });
    },
    searchForGeofences(str) {
      const phrases = str.toLowerCase().split(',');

      const regPhrases = [];
      for (let i = 0; i < phrases.length; i++) {
        regPhrases.push(new RegExp(phrases[i].trim(), 'i'));
      }

      let flag = false;
      for (let i = 0; i < this.allGeofences.length; i++) {
        const geo = this.allGeofences[i];

        const geoNode = document.getElementById(`geofence-${geo.id}`);

        if (!geoNode) continue;

        geoNode.classList.add('d-none');

        flag = false;
        for (let j = 0; j < regPhrases.length; j++) {
          if (flag) break;

          flag = regPhrases[j].test(
            geo[this.searchType].toString().toLowerCase(),
          );
        }

        if (flag) {
          geoNode.classList.remove('d-none');
        }
      }
    },
    addPmEventListeners() {
      leafletMain.addPmEventListeners({
        createCb: this.createdCb,
        editCb: this.editCb,
        removeCb: this.removeCb,
        cutCb: this.cutCb,
      });
    },
    createdCb(geoJson, layer) {
      const geofenceWaitingForming = this.geofenceWaitingForming;
      // const lastLayer = geofenceWaitingForming.layer;
      geofenceWaitingForming.geoJson = geoJson;
      geofenceWaitingForming.layer = layer;
      // geofenceWaitingForming.radius = layer?.options?.radius ?? '-';
      geofenceWaitingForming.radius = layer.getRadius ? layer.getRadius() : '-';

      this.setEditingStyle(
        this.$options.REDACTING_GEO_COLOR,
        geofenceWaitingForming,
      );
      this.geofenceWaitingForming = {};

      if (
        !(geofenceWaitingForming.id > 0) &&
        !geofenceWaitingForming.beforeEdit.geoJson
      ) {
        geofenceWaitingForming.editing = false;
        this.editGeofence(geofenceWaitingForming);
      }
    },
    editCb({ _leaflet_id, shape, newRadius } = {}) {
      const geofence = this.editingGeofences.get(_leaflet_id);
      if (!geofence) {
        return;
      }

      const { layer, lastEdit } = geofence;
      if (leafletMain.checkSelfIntersection(layer)) {
        // самопересечение
        leafletMain.exchangeLatLngsFromGeoJson(layer, lastEdit.geoJson);
        geofence.geoJson = leafletMain.getGeoJson(layer);
        return;
      }

      if (newRadius) {
        geofence.layer.options.radius = newRadius;
        geofence.radius = newRadius;
      }
      geofence.geoJson = leafletMain.getGeoJson(geofence.layer, shape);
      geofence.newRadius = newRadius;
      geofence.shape = shape;
      this.rewriteEditingValues(
        geofence,
        geofence.lastEdit,
        this.$options.EDITING_KEYS,
      );
    },
    removeCb(_leaflet_id) {
      const geofence = this.editingGeofences.get(_leaflet_id);
      if (geofence) {
        geofence.geoJson = '';
        geofence.newRadius = 0;
        geofence.shape = '';

        this.geofenceWaitingForming = geofence;

        this.editingGeofences.delete(_leaflet_id);
      }
    },
    cutCb({ _leaflet_id, _leaflet_id_original, layer, shape } = {}) {
      const geofence = this.editingGeofences.get(_leaflet_id_original);
      if (!geofence) {
        return;
      }

      geofence.layer = layer;
      this.editingGeofences.delete(_leaflet_id_original);
      this.editingGeofences.set(_leaflet_id, geofence);

      this.editCb({ _leaflet_id, shape, newRadius: 0 });
    },
    createGeofence() {
      // this.radactMode = true;
      const newGeofence = this.$options.NEW_GEOFENCE();
      this.allGeofences.unshift(newGeofence);
      this.geofenceWaitingForming = newGeofence;
      this.editGeofence(newGeofence);

      // leafletMain.addNewGeofence();
      // leafletMain.pmControlDrawSet(true);
    },
    removeGeofence(geofence) {
      const userText = prompt(
        `Для удаления геозоны "${geofence.name}" впишите "да"`,
      )?.toLowerCase();
      if (this.$options.CONFIRM_TEXTS.includes(userText)) {
        if (geofence.id > 0) {
          this.sendGeofenceRemove(geofence);
        } else {
          this.removeGeofenceFromArray(geofence);
        }
      }
    },
    removeGeofenceFromArray(geofence, index = -1) {
      index =
        index === -1
          ? this.allGeofences.findIndex((g) => g === geofence)
          : index;
      if (index === -1) {
        return;
      }

      const removingGeofence = this.allGeofences[index];
      this.checkGeofenceWaitingForming(removingGeofence);

      if (this.selectedGeofence === removingGeofence) {
        this.selectedGeofence = {};
      }

      leafletMain.removeGeofence(removingGeofence.layer);
      this.allGeofences.splice(index, 1);

      alert(`Геозона ${geofence.name} удалена`);
    },
    async sendGeofenceRemove(geofence) {
      geofence.deleting = true;
      const query = await this.sendData(
        this.$options.URL_DELETED_QUERY,
        JSON.stringify({
          id: geofence.id,
        }),
      );
      const { responce, error: sendError } = query;
      if (sendError) {
        geofence.deleting = false;
        geofence.deletingError = sendError;
        return;
      }

      const { error } = responce;
      if (error) {
        geofence.deleting = false;
        geofence.deletingError = error;
        return;
      }
      this.removeGeofenceFromArray(geofence);
    },
    checkGeofenceWaitingForming(geofence) {
      if (this.geofenceWaitingForming === geofence) {
        this.geofenceWaitingForming = {};
      }
    },
    cancelEditGeofence(geofence) {
      const { layer, beforeEdit } = geofence;
      const { geoJson: geoJsonBeforeEdit } = beforeEdit || {};
      this.checkGeofenceWaitingForming(geofence);
      this.editingGeofences.delete(layer._leaflet_id);
      const newLayer = this.replaceLayerFromGeojson(
        geofence,
        geoJsonBeforeEdit,
      );
      // geofence.radius = geofence?.layer?.options?.radius ?? '-';
      // geofence.geoJsonBeforeEdit = '';
      this.rewriteEditingValues(
        beforeEdit,
        geofence,
        this.$options.EDITING_KEYS,
      );
      geofence.beforeEdit = {};
      geofence.lastEdit = {};

      if (newLayer) {
        this.saveGeofence(geofence, false);
      }
    },
    replaceLayerFromGeojson(geofence, geoJson) {
      const newLayer = leafletMain.replaceLayerFromGeojson(
        geofence.layer,
        geoJson,
      );
      if (newLayer) {
        geofence.layer = newLayer;
        geofence.geoJson = newLayer ? leafletMain.getGeoJson(newLayer) : '';
      }
      return newLayer;
    },
    editGeofence(geofence) {
      this.setSelectedGeofence(geofence);

      const { layer } = geofence;

      const isEditingAdded = Boolean(
        layer && this.editingGeofences.get(layer._leaflet_id),
      );
      if (geofence.editing || !layer) {
        if (layer && !isEditingAdded) {
          this.editingGeofences.set(layer._leaflet_id, geofence);
        }
        return;
      }

      geofence.editing = true;
      if (!layer) {
        return;
      }
      layer.content = geofence.name;

      leafletMain.addPmEditStyle(layer, true);
      // geofence.geoJson = leafletMain.getGeoJson(layer);
      // geofence.radius = geofence?.layer?.options?.radius ?? '-';
      // geofence.geoJsonBeforeEdit = geofence.geoJson;
      this.rewriteEditingValues(
        geofence,
        geofence.beforeEdit,
        this.$options.EDITING_KEYS,
      );
      this.rewriteEditingValues(
        geofence,
        geofence.lastEdit,
        this.$options.EDITING_KEYS,
      );

      this.editingGeofences.set(layer._leaflet_id, geofence);
      this.setEditingStyle(this.$options.REDACTING_GEO_COLOR, geofence);
    },
    geofenceShowOnMap(geofence) {
      leafletMain.layerMapFitBounds(geofence.layer);
    },
    async saveGeofence(geofence, isSend = true) {
      if (isSend) {
        geofence.sending = true;
        const error = await this.sendGeofence(geofence);
        geofence.sending = false;
        geofence.sendingError = error || '';

        if (error) {
          return;
        }

        // geofence.saved = true;
      }

      geofence.layer.content = geofence.name;
      geofence.editing = false;
      const { layer } = geofence;
      this.editingGeofences.delete(layer._leaflet_id);
      // geofence.geoJsonBeforeEdit = '';
      geofence.geoJson = leafletMain.getGeoJson(layer);
      leafletMain.addPmEditStyle(layer, false);
      geofence.beforeEdit = {};
      geofence.lastEdit = {};
      this.setEditingStyle(this.$options.GEO_COLOR, geofence);
    },
    async sendGeofence(geofence) {
      // реализовать отправку данных и потом отправить
      const query = await this.sendData(
        this.$options.URL_SAVED_QUERY,
        JSON.stringify(this.getGeofenceData(geofence)),
      );
      const { responce, error: sendError } = query;
      if (sendError) {
        return sendError;
      }

      const { geofence: resArr = [], error } = responce;
      if (error) {
        return error;
      }
      const [resGeofence] = resArr;
      if (!resGeofence) {
        return 'Ошибка получения';
      }
      const { id } = resGeofence;
      if (!id) {
        return 'Ошибка получения геозоны';
      }
      if (id && !(geofence.id > 0)) {
        geofence.id = id;
        geofence.key = id;
      }
      return false;
    },
    async getGeofencesByApi() {
      // this.geofencesUpdating.flag = true;
      this.setGeofencesUpdating(true);
      this.getGeofencesError = '';
      const query = await this.sendData(this.$options.URL_GET_QUERY, '');
      const { responce = {}, error: sendError } = query;
      const { geofences, error } = responce;

      if (sendError || error) {
        this.getGeofencesError = sendError || error;
        this.setGeofencesUpdating(false);
        return;
      }
      if (!geofences) {
        this.getGeofencesError = 'Ошибка получения геозон';
        this.setGeofencesUpdating(false);
        return;
      }

      while (!leafletMain.initializated) {
        // подождем инициализации leaflet
        await new Promise((resolve) => setTimeout(() => resolve(), 50));
      }

      this.applyReceivedGeofences(geofences);
      this.setGeofencesUpdating(false);
    },
    applyReceivedGeofences(geofences) {
      const serverGeofenceIds = geofences.reduce((acc, geo) => {
        acc[geo.id] = geo;
        return acc;
      }, {});

      const geofencesToDelete = [];

      this.allGeofences.forEach((geofence) => {
        const { id } = geofence;
        const sGeo = serverGeofenceIds[id];
        if (sGeo) {
          this.applyReceivedGeofence({ sGeo, geofence });
          delete serverGeofenceIds[id];
        } else {
          geofencesToDelete.push(geofence);
        }
      });

      geofencesToDelete.forEach((gDelete) =>
        this.removeGeofenceFromArray(gDelete),
      );
      Object.values(serverGeofenceIds).forEach((sGeo) =>
        this.applyReceivedGeofence({ sGeo }),
      );
    },
    applyReceivedGeofence({ sGeo, geofence } = {}) {
      const {
        id,
        name,
        type,
        info,
        max_speed: speedLimit,
        feature: geoJsonJson,
        rights_on_this: rights_on_this_json,
      } = sGeo;

      const createdGeo = geofence || this.$options.NEW_GEOFENCE();
      if (geofence) {
        // геозона была
        // createdGeo.saved = true;
        createdGeo.editing = false;
        createdGeo.beforeEdit = {};
        createdGeo.lastEdit = {};
        createdGeo.sending = false;
        createdGeo.sendingError = '';
        createdGeo.deleting = false;
        createdGeo.deletingError = '';
      } else {
        // геозоны не было
        this.allGeofences.unshift(createdGeo);
      }

      const geoJson = geoJsonJson ? JSON.parse(geoJsonJson) : {};
      if (
        !createdGeo.geoJson ||
        !createdGeo.layer ||
        JSON.stringify(geoJson) !== JSON.stringify(createdGeo.geoJson)
      ) {
        // изменяем/добавляем слой-геозону
        this.replaceLayerFromGeojson(createdGeo, geoJson);
        const radius = geoJson?.properties?.radius ?? '-';
        const shape = geoJson?.properties?.type ?? '';
        createdGeo.shape = shape;
        createdGeo.radius = radius > 0 ? parseFloat(radius) : radius;
        createdGeo.newRadius = createdGeo.radius;
        createdGeo.geoJson = geoJson;
      }
      createdGeo.layer.content = name;

      this.setEditingStyle(this.$options.GEO_COLOR, {
        layer: createdGeo.layer,
        name,
      });

      const rights_on_this = rights_on_this_json
        ? JSON.parse(rights_on_this_json)
        : {};
      const { owners = [] } = rights_on_this || {};
      const [owner = ''] = owners;

      createdGeo.id = parseInt(id);
      createdGeo.key = parseInt(id);
      createdGeo.name = name;
      createdGeo.type = parseInt(type);
      createdGeo.speedLimit = parseInt(speedLimit);
      createdGeo.info = info;
      createdGeo.owner = owner;

      this.addMenuScrollEventListenter(createdGeo);
    },

    addMenuScrollEventListenter(geo) {
      geo.layer.id = geo.id;
      geo.layer.on('click', (e) => {
        this.scrollToElementId = e.target.id;
      });
    },

    async sendData(url, data) {
      try {
        const responce = await fetchJson(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          credentials: 'include',
          body: data,
        });
        return { responce };
      } catch (e) {
        return {
          error: 'Ошибка отправки',
        };
      }
    },
    getGeofenceData(geofence) {
      const {
        id,
        name,
        type,
        speedLimit: max_speed,
        info,
        geoJson: feature,
      } = geofence;

      const bounds = geofence.layer.getBounds();
      const { lat: lat_min, lng: lon_min } = bounds.getSouthWest();
      const { lat: lat_max, lng: lon_max } = bounds.getNorthEast();
      const { LAT_LNG_MULTIPLIER: multiplier } = this.$options;

      // L.rectangle(bounds).addTo(leafletMain.map)

      return {
        id,
        name,
        type,
        max_speed,
        info,
        feature,
        props: {}, // какие-то дополнительные свойства
        lat_min: Math.round(lat_min * multiplier),
        lon_min: Math.round(lon_min * multiplier),
        lat_max: Math.round(lat_max * multiplier),
        lon_max: Math.round(lon_max * multiplier),
      };
    },
    setSelectedGeofence(geofence) {
      this.selectedGeofence = geofence;
    },
    resizeWindow() {
      this.windowHeight = window.innerHeight;
      this.topElementsHeight =
        this.$options.MAIN_MENU_EL.offsetHeight +
        this.$options.SECTION_MONITORING_SIDEBAR_SWITCHER.offsetHeight +
        this.$refs.geofenciesHeader.offsetHeight;
    },
    rewriteEditingValues(from, to, editingKeys) {
      editingKeys.forEach(({ key }) => {
        to[key] = from[key];
      });
    },
    setEditingStyle(color, { layer, name } = {}) {
      if (color) {
        layer.setStyle({ color });
      }
      layer.bindTooltip(name);
    },
    showGeofence(geofence, isVisible) {
      const { layer } = geofence;
      leafletMain.showLayer(layer, isVisible);
    },
  },
  mounted() {
    this.$options.MAIN_MENU_EL = document.getElementById('main-menu');
    this.$nextTick(() => {
      this.$options.SECTION_MONITORING_SIDEBAR_SWITCHER =
        document.getElementById('section-monitoring-sidebar-switcher');
      this.resizeWindow();
      window.addEventListener('resize', this.resizeWindow);
      // this.addPmEventListeners();
      window.addEventListener(
        'leaflet:initializated',
        this.addPmEventListeners,
        { once: true },
      );
      if (this.authorizatedUserRights.rule_geofences_watch) {
        this.getGeofencesByApi();
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
    leafletMain.removePmEventListeners();
  },
};
</script>

<style lang="scss">
.my-splitter {
  position: relative;
  width: 100%;
  height: 0px;

  & span {
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 10px;
    cursor: row-resize;
    transition: all 0.1s ease;

    &:hover {
      background: rgba(0, 144, 255, 0.3);
      -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 144, 255, 0.1);
      -moz-box-shadow: 0px 0px 2px 1px rgba(0, 144, 255, 0.1);
      box-shadow: 0px 0px 2px 1px rgba(0, 144, 255, 0.1);
    }
  }
}
.geofencies-header button {
  padding: 2px 4px;
  margin: 0 2px;
  font-size: small;
}
.geofencies-search {
  & input {
    width: 100%;
  }

  & input,
  & select {
    border-radius: 0;
    background: #fff;
    border: 1px solid #aaa;
    padding: 0 5px;
  }
}
</style>
