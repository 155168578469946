<template>
  <button
    type="button"
    class="btn btn-sm"
    :class="[props.isPrimary ? 'btn-primary config-btn-primary' : 'btn-outline-secondary']"
    @click="(e) => {checkSuccess(e); props.clickHandler()}"
  >
    <slot />
  </button>
</template>

<script setup>
const props = defineProps({
  isPrimary: {
    type: Boolean,
    required: false,
    default: true,
  },
  clickHandler: {
    type: Function,
    required: true,
  },
});

const checkSuccess = (e) => {
  const buttonElement = e.target;

  if (buttonElement.classList.contains("btn-success")) {
    buttonElement.classList.remove("btn-success")
  }
}
</script>

<style></style>
