<template>
  <div>
    <Datepicker
      v-model="startDate"
      locale="ru"
      autoApply
      :closeOnAutoApply="false"
      :time-picker-component="TimePicker"
      :format="dpFormat"
      :clearable="false"
      @cleared="clear('start')"
      menuClassName="dp-custom-menu"
      calendarClassName="dp-custom-calendar"
      inputClassName="dp-custom-input"
      >
      <template #input-icon />
      <template #clear-icon="{ clear }">
        <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        class="dp__icon dp__clear_icon dp__input_icons"
        style="padding: 6px"
        @click="clear"
        >
        <path
        d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
          ></path>
          <path
          d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
          ></path>
        </svg>
      </template>
    </Datepicker>
    <span class="line"></span>
    <Datepicker
    v-model="nextDate"
    locale="ru"
    autoApply
    :closeOnAutoApply="false"
    :time-picker-component="TimePicker"
    :format="dpFormat"
    :clearable="false"
      @cleared="clear('next')"
      menuClassName="dp-custom-menu"
      calendarClassName="dp-custom-calendar"
      inputClassName="dp-custom-input"
    >
      <template #input-icon />
      <template #clear-icon="{ clear }">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          class="dp__icon dp__clear_icon dp__input_icons"
          style="padding: 6px"
          @click="clear"
        >
          <path
            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
          ></path>
          <path
            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
          ></path>
        </svg>
      </template>
    </Datepicker>
  </div>
</template>

<script setup>
import { ref, computed, watch, useAttrs } from 'vue';

import { getStringByUnixTime } from '@/App/ViolationsGraphics/helpers/MineHelper.js';

import Datepicker from '@vuepic/vue-datepicker';
import TimePicker from '@/App/Components/DpSingleTimePicker.vue';

// import TimePicker from '@/App/Components/DpSingleTimePicker.vue';

const props = defineProps(['date']);
const emit = defineEmits(['update:date']);

const startDate = computed({
  get() {
    return props.date[0];
  },
  set(value) {
    emit('update:date', [value, nextDate.value]);
  },
});

const nextDate = computed({
  get() {
    return props.date[1];
  },
  set(value) {
    emit('update:date', [startDate.value, value]);
  },
});

const clear = (str) => {
  const d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  if (str === 'start') {
    return (startDate.value = d);
  }
  if (str === 'next') {
    return (nextDate.value = d);
  }
};

const dpFormat = (date) => {
  const DateTime = getStringByUnixTime(date.getTime());

  return DateTime;
};
</script>

<style lang="scss">
.custom-time-picker-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & span {
    width: 30px;
    height: 2px;
    background: #000;

    @media (max-width: 450px) {
      width: 10px;
      height: 1px;
    }
    @media (max-width: 1024px) and (orientation: landscape) {
      width: 10px;
      height: 1px;
    }
  }

  @media (max-width: 450px) {
    flex-direction: row;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    flex-direction: row;
  }
}

.line {
  margin: 5px;
  display: block;
  width: 10px;
  height: 2px;
  background: #000;
}

.select-input {
  margin: 10px 3px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
  @media (max-width: 1024px) {
    width: 60px;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    width: 60px;
    margin: 6px 3px;
  }
}
</style>
