<template>
  <ui-popup
    style-for-body="padding: 5px; width: 80vw; max-width: 100vw; height: 60vh"
    @close="emit('close')"
  >
    <template #header>
      <div class="template-setting-window-header-name">
        <strong>Эксплуатация экскаватора</strong><br />
        <small>Настройка отчета</small>
      </div>
      <img
        class="close"
        src="/images/close.png"
        @click="closeHandler"
      />
    </template>
    <div
      class="template-setting-window-header d-flex flex-column justify-content-between"
      style="height: calc(100% - 60px)"
    >
      <div
        style="max-height: 60vh"
        class="scroll"
      >
        <div class="overflow-auto d-flex flex-column">
          <div>
            <label>
              <input
                v-model="total"
                type="checkbox"
                disable
              />
              Итоги (сумма)
            </label>
          </div>

          <div>
            <label>
              <input
                v-model="isDetail"
                type="checkbox"
              />Детализация
            </label>
          </div>

          <div>
            <label>
              <input
                v-model="isDailyDetail"
                type="checkbox"
              />Детализация работы по дням
            </label>
          </div>

          <template v-if="false">
            <div class="pt-4"><label>Смены</label></div>

            <div>
              <label>
                <input
                  v-model="SmenasFlag"
                  type="checkbox"
                  disable
                />Включить смены
              </label>
            </div>

            <div>
              <label>
                <input
                  v-model="Breakdown"
                  type="checkbox"
                  :disable="!SmenasFlag"
                />Итоги смен
              </label>
            </div>

            <div class="d-flex">
              <div class="pl-3">
                <label>
                  <input
                    v-model="s1"
                    type="checkbox"
                    :disable="true || !Breakdown || !SmenasFlag"
                  />Смена 1
                </label>
              </div>
              <div class="pl-5 d-flex align-items-center">
                <input
                  v-model="s1BhBm"
                  type="time"
                  :disable="!s1 || !Breakdown || !SmenasFlag"
                />
                <span class="px-2">-</span>
                <input
                  v-model="s1EhEm"
                  type="time"
                  :disable="!s1 || !Breakdown || !SmenasFlag"
                />
              </div>
            </div>

            <div class="d-flex">
              <div class="pl-3">
                <label>
                  <input
                    v-model="s2"
                    type="checkbox"
                    :disable="!Breakdown || !SmenasFlag"
                  />Смена 2
                </label>
              </div>
              <div class="pl-5 d-flex align-items-center">
                <input
                  v-model="s2BhBm"
                  type="time"
                  :disable="!s2 || !Breakdown || !SmenasFlag"
                />
                <span class="px-2">-</span>
                <input
                  v-model="s2EhEm"
                  type="time"
                  :disable="!s2 || !Breakdown || !SmenasFlag"
                />
              </div>
            </div>

            <div class="d-flex">
              <div class="pl-3">
                <label>
                  <input
                    v-model="s3"
                    type="checkbox"
                    :disable="!Breakdown || !SmenasFlag"
                  />Смена 3
                </label>
              </div>
              <div class="pl-5 d-flex align-items-center">
                <input
                  v-model="s3BhBm"
                  type="time"
                  :disable="!s3 || !Breakdown || !SmenasFlag"
                />
                <span class="px-2">-</span>
                <input
                  v-model="s3EhEm"
                  type="time"
                  :disable="!s3 || !Breakdown || !SmenasFlag"
                />
              </div>
            </div>
          </template>

          <div class="text-center padding-top-5">
            <small>Окончание настройки отчета</small>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-center">
        <input
          class="btn btn-primary"
          type="button"
          value="Выполнить отчет"
          @click="executeReport"
        />
      </div>
    </div>
  </ui-popup>
</template>

<script setup>
import { computed, ref } from 'vue';

import ExcavatorOperation from '@/Template/ExcavatorOperation/ExcavatorOperation';
import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';

const props = defineProps({
  modelValue: Boolean,
  objects: { type: Array, default: () => [] },
  interval: { type: Object, default: () => ({ from: 0, to: 0 }) },
  areaId: { type: String, required: true },
});
const emit = defineEmits(['update:modelValue', 'displayReport', 'close']);

const total = ref(true);
const isDetail = ref(true);
const isDailyDetail = ref(true);

const SmenasFlag = ref(false);
const Breakdown = ref(true);

const s1 = ref(true);
const s1BhBm = ref('07:00');
const s1EhEm = ref('19:00');

const s2 = ref(true);
const s2BhBm = ref('19:00');
const s2EhEm = ref('07:00');

const s3 = ref(false);
const s3BhBm = ref('00:00');
const s3EhEm = ref('00:00');

function processCallback(type, message, percent) {
  const button = document.getElementById('get-template-button');
  const targetSpan = getNeighborByClass_helper(button, 'target-show-span');

  button.innerHTML = 'Выполнить отчет';
  if (type === 'error') {
    const defaultErrorMessage =
      'Произошла ошибка, попробуйте повторить запрос, либо обновить страницу';
    button.style.background = 'red';
    targetSpan.innerHTML = message || defaultErrorMessage;
  } else {
    button.style.background = '';
    targetSpan.innerHTML = message;
  }
}

function executeReport() {
  const report = new ExcavatorOperation(
    {
      settings: {
        total: total.value,
        isDetail: isDetail.value,
        isDailyDetail: isDailyDetail.value,
      },
    },
    {
      SmenasFlag: SmenasFlag.value,
      Breakdown: Breakdown.value,
      s1: s1.value,
      s1BhBm: s1BhBm.value,
      s1EhEm: s1EhEm.value,
      s2: s2.value,
      s2BhBm: s2BhBm.value,
      s2EhEm: s2EhEm.value,
      s3: s3.value,
      s3BhBm: s3BhBm.value,
      s3EhEm: s3EhEm.value,
    },
  );
  report.onProcess(processCallback);
  report.onSuccess((reportCalculated) => {
    emit('displayReport', { data: reportCalculated, areaId: props.areaId });
  });
  report.getReport({
    objects: props.objects.map((id) => ({ id })),
    from: +props.interval.from,
    to: +props.interval.to,
  });

  closeHandler();
}

function closeHandler() {
  emit('close');
}
</script>

<style lang="scss"></style>
