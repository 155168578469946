import { accessToken } from '@/src/jwtSender';

export const CODES = {
  ABNORMAL_CLOSURE: 1006,

  CLEAR: 4000,
  NOT_VALID_TOKEN: 4001,
  TIMEOUT_OVER: 4002,
  UNHANDLED_ERROR: 4003,
  RECONNECTIONS_LIMIT: 4004,
};

const CHECK_CONNECTION_TIMEOUT = 30 * 1000;
const MAX_RECONNECTIONS = 5;

class LifeTime {
  #value = 0;
  #max = 0;

  setMax(maxLifetime) {
    this.#max = maxLifetime;
  }

  update() {
    this.#value = Date.now() + this.#max;
  }

  isEmpty() {
    return this.#max == 0;
  }

  isOver() {
    return this.#value < Date.now();
  }
}

export class MyWSConnect {
  base_url = `${process.env.VUE_APP_BACKEND_GO_WS_URL}api/ws/getPositionsOur`;
  queryTimeStart;

  reconnectionsCount = MAX_RECONNECTIONS;

  checkConnectionInterval;
  lastConnectionTime = 0;

  lifetime;

  isRepeatRequest;

  constructor(url) {
    this.url = url;
    this.lifetime = new LifeTime();
    this.isRepeatRequest = false;
  }

  startWS(requestDoneHandler, requestFailHandler, showMessageHandler) {
    this.requestDoneHandler = requestDoneHandler;
    this.requestFailHandler = requestFailHandler;
    this.showMessageHandler = showMessageHandler;
    this.queryTimeStart = new Date();

    try {
      this.socket = new WebSocket(this.url);

      // события самого вебсокета
      this.socket.addEventListener('open', this.openHandler, false);
      this.socket.addEventListener('close', this.closeHandler, false);
      this.socket.addEventListener('error', this.errorHandler, false);
      this.socket.addEventListener('message', this.newMessageHandler, false);
    } catch (error) {
      console.error(error)
      this.requestFailHandler();
    }
  }

  openHandler = (event) => {
    if (this.isRepeatRequest) {
      this.sendReconnection();
    }
    return this;
  };

  errorHandler = (error) => {
    if (error.message === 'Время жизни токена авторизации истекло') {
      this.closeSocket(CODES.NOT_VALID_TOKEN, error.message);
      return;
    }
    console.error(error)
    this.closeSocket(CODES.UNHANDLED_ERROR, error.message);
  };

  closeHandler = (event) => {
    // TODO: Обработать истечение времени жизни токена
    // if (event.code == CODES.NOT_VALID_TOKEN) {
    //   this.repeatRequest();
    //   return;
    // }

    if (event.code == CODES.CLEAR) {
      return;
    }

    if (
      [
        CODES.TIMEOUT_OVER,
        CODES.UNHANDLED_ERROR,
        CODES.NOT_VALID_TOKEN,
        CODES.RECONNECTIONS_LIMIT,
      ].includes(event.code)
    ) {
      this.requestFailHandler();
      this.clear();
      return;
    }

    if (event.code == CODES.ABNORMAL_CLOSURE) {
      if (this.reconnectionsCount == 0) {
        this.requestFailHandler();
        this.clear();
      } else {
        this.repeatRequest();
      }
      return;
    }

    if (event.wasClean) {
      return;
    }
  };

  newMessageHandler = (e) => {
    const { data: json } = e;

    if (!json) {
      return;
    }

    let data = {};

    try {
      data = JSON.parse(json);
    } catch (err) {
      console.error('invalid JSON event', e);
      return;
    }

    this.reconnectionsCount = MAX_RECONNECTIONS;
    this.lastConnectionTime = Date.now();

    if (data.event === 'pong') {
      return;
    }

    this.messageHandler(data);
  };

  messageHandler(data) {
    return;
  }

  repeatRequest() {
    if (this.lifetime.isEmpty()) {
      this.reconnectionsCount--;
    } else if (this.lifetime.isOver()) {
      this.reconnectionsCount = 0;
    }

    setTimeout(() => {
      this.isRepeatRequest = true;
      this.startWS(
        this.requestDoneHandler,
        this.requestFailHandler,
        this.showMessageHandler,
      );
    }, CHECK_CONNECTION_TIMEOUT);
  }

  prepareUrlForWS(url, id, options) {
    const timeOffset = -new Date().getTimezoneOffset() / 60;

    let queryUrl = `${url}/${id}?timeOffset=${timeOffset}`;

    for (let key in options) {
      queryUrl += `&${key}=${options[key]}`;
    }

    return queryUrl;
  }

  startCheckConnection() {
    this.checkConnectionInterval = setInterval(() => {
      this.sendMessage({ event: 'ping', message: '' });
    }, CHECK_CONNECTION_TIMEOUT);
  }

  sendReconnection() {
    this.sendMessage({
      event: 'reconnection',
      message: {
        token: accessToken.value.token,
      },
    });
  }

  sendMessage(obj = {}) {
    if (obj.event === undefined) {
      throw new Error('WS: messageObject.event is not defined');
    }
    if (obj.message === undefined) {
      throw new Error('WS: messageObject.message is not defined');
    }

    if (this.checkIsSockedClosed()) {
      return;
    }
    this.socket.send(JSON.stringify(obj, dateToJSON_replacer));
  }

  clear() {
    this.closeSocket(CODES.CLEAR);
    this.url = '';
    this.queryTimeStart = 0;
    this.objId = '';
    this.timeBegin = 0;
    this.isOtherPositions = false;
  }

  closeSocket(code, reason) {
    if (this.checkIsSockedClosed()) {
      return;
    }

    try {
      if (this.socket) {
        this.socket.close(code || CODES.CLEAR, reason || '');
        this.socket = null;
      }

      clearInterval(this.checkConnectionInterval);
    } catch (error) {}
  }

  checkIsSockedClosed() {
    if (
      this.socket &&
      (this.socket.readyState === WebSocket.CLOSED ||
        this.socket.readyState === WebSocket.CLOSING)
    ) {
      return true;
    }
    return false;
  }
}
