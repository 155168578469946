export function getBaseHttpUrl_handler() {
  const portAndPath = String(process.env.VUE_APP_TEMPLATE_DEV_SERVICE_HOSTNAME);
  const [,_port] = portAndPath.split(':');

  let hostname, protocol, port;
  if (process.env.NODE_ENV === 'development') {
    hostname = window.location.hostname;
    protocol = 'http';
    port = _port;
  } else {
    hostname = window.location.hostname;
    protocol = 'https';
    port = '';
  }

  return `${protocol}://${hostname}:${port}`;
}
