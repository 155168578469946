<template>
  <div>
    <!-- <input type="button" class="btn btn-secondary btn-sm" value="выбрать все" @click="selectAll"><br> -->
    <!-- <input type="button" class="btn btn-secondary btn-sm" value="снять все" @click="unselectAll"> -->
    <table class="unselectable">
      <thead>
        <tr>
          <th class="first-column">
            <img
              target="выбрать все"
              class="my-button"
              src="/images/checked-checkbox-26.png"
              alt="selectAll"
              @click="selectAll"
            />
            <img
              target="снять выделение со всех"
              class="my-button"
              src="/images/cleared-checkbox-26.png"
              alt="selectAll"
              @click="unselectAll"
            />
          </th>
          <th>Наименование</th>
          <th>Вид</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="geo in geofences"
          :key="geo.key"
        >
          <td>
            <label class="width-max">
              <input
                type="checkbox"
                :id="`${idName}-${geo.key}`"
                :value="geo.key"
                v-model="checkedGeofences"
                @change="changeChecked"
              />
            </label>
          </td>
          <td>
            <label
              :for="`${idName}-${geo.key}`"
              class="width-max"
            >
              {{ geo.name }}
            </label>
          </td>
          <td>{{ geofenceTypes[geo.type].text }}</td>
        </tr>
      </tbody>
    </table>
    <!-- {{ checkedGeofences }} -->
  </div>
</template>

<script>
export default {
  inject: ['geofenceTypes'],
  props: {
    geofences: {
      type: Array,
      required: true,
    },
    changeGeofences: {
      type: Function,
      required: true,
    },
    idName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkedGeofences: [],
    };
  },
  methods: {
    changeChecked() {
      this.changeGeofences(this.checkedGeofences);
    },
    selectAll() {
      this.checkedGeofences = this.geofences.reduce((acc, geo) => {
        acc.push(geo.key);
        return acc;
      }, []);
      this.changeChecked();
    },
    unselectAll() {
      this.checkedGeofences = [];
      this.changeChecked();
    },
  },
};
</script>

<style scoped>
.width-max {
  width: 100%;
}
.my-button {
  cursor: pointer;
  margin: 3px 3px 3px 6px;
  width: 20px;
}
.first-column:first-child {
  margin: 3px;
}
.first-column {
  width: 70px;
}
th {
  width: auto;
}
</style>
