import fetchJson from './fetch-json';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';
import { funcReplacer } from '../../helpers/main_helper';

export default class {
  url = '';
  targetElement;
  buttonElement;
  areaId;
  VueLoadingProcess;
  VueErrorHandler;
  aggregateInterval = {};
  templateName;
  interval;
  callback;
  isGetSmenas = false;
  smenasOrigin = [];
  params = null;

  constructor({
    url,
    targetElement,
    buttonElement,
    areaId,
    VueLoadingProcess,
    VueErrorHandler,
    aggregateInterval,
    shiftWorkWorkerData,
    templateName,
    interval,
    callback,
    isGetSmenas = false,
    smenasOrigin = [],
    params = null,
  } = {}) {
    this.url = url;

    this.shiftWorkWorkerData = shiftWorkWorkerData;

    this.targetElement = targetElement;
    this.buttonElement = buttonElement;
    this.areaId = areaId;

    this.VueLoadingProcess = VueLoadingProcess;
    this.VueErrorHandler = VueErrorHandler;

    this.aggregateInterval = aggregateInterval;
    this.templateName = templateName;
    this.interval = interval;
    this.callback = callback;
    this.isGetSmenas = isGetSmenas;
    this.smenasOrigin = smenasOrigin;
    this.params = params;

    this.hostname = location.hostname;
    this.protocol = location.protocol;
  }

  async getDataAndCalculate(objIdArr, dataSend) {
    this.objIdArr = objIdArr;
    infoShowText_helper(this.targetElement, `Отправляю запрос...`, this.areaId);
    try {
      return await this.getDataStepByStep(objIdArr, dataSend);
    } catch (e) {
      console.error(e);
      this.VueErrorHandler('Ошибка получения данных. Выполните новый запрос');
      return { error: 'Ошибка получения данных с сервера' };
    }
  }

  async getDataStepByStep(objIdArr, dataSend) {
    const queryCount = objIdArr.length;
    const resultArray = [];
    const resultObject = {
      objects: [],
      rows_summ: {
        header: [],
        lines: [[]],
        settingsSumm: [],
      },
      rows_summ_forGraph_100: {
        header: [],
        lines: [[]],
        settingsSumm: [],
      },
      format: null,
      objectIdsNotIncludesInRequest: [],
    };

    const worker = new CalculateRequestDataworker();

    worker.addEventListener('message', (event) => {
      const {
        object,
        rows_summ,
        rows_summ_forGraph_100,
        format,
        objectIdsNotIncludesInRequest,
      } = event.data;

      resultObject.objects.push(object);

      resultObject.rows_summ.header = rows_summ.header;
      resultObject.rows_summ.lines[0].push(
        rows_summ.lines[0][rows_summ.lines[0].length - 1],
      );
      resultObject.rows_summ.settingsSumm = rows_summ.settingsSumm;

      resultObject.rows_summ_forGraph_100.header =
        rows_summ_forGraph_100.header;
      resultObject.rows_summ_forGraph_100.lines = rows_summ_forGraph_100.lines;

      resultObject.rows_summ_forGraph_100.settingsSumm =
        rows_summ_forGraph_100.settingsSumm;

      resultObject.objectIdsNotIncludesInRequest.concat(
        objectIdsNotIncludesInRequest,
      );

      resultObject.format = format;
    });

    worker.addEventListener('error', (error) => {
      console.error(error.message);
    });

    // есть queriesData
    // нужно создать сначала 10 fetch запросов
    // потом при ответе на один запрос создавать еще запрос
    // так до тех пор, пока все запросы не закончатся
    // а если один из них завершится ошикой, то и другие не создавать

    const fetcher = {
      isError: false,
      isDone: false,
      queryCount,
      queryIndex: 0,
      sendedCount: 0,
      responsesCount: 0,
      maxTogetherCount: 8,
      maxQueryCount: 3,
      checkAndGetFetch: async (fetcher, resolve, reject) => {
        const {
          queriesData,
          queryCount,
          sendedCount,
          responsesCount,
          maxTogetherCount,
          maxQueryCount,
          isError,
        } = fetcher;
        if (isError) {
          reject();
          worker.terminate();
          return;
        }
        if (responsesCount === queryCount) {
          fetcher.isDone = true;
          if (
            [
              'skillsMan',
              'getCalculated',
              'criticalViolations',
              'getCalculatedWithGeofences',
              // 'regularMovingGeoTemplate',
              'evacuatorMoving',
            ].includes(this.templateName)
          ) {
            while (resultObject.objects.length !== queryCount) {
              await new Promise((res) => setTimeout(res, 500));
            }
            resultObject.format = formatToDisplay_helper;
            resolve(resultObject);
          } else {
            resolve(resultArray);
          }

          worker.terminate();
          return;
        }
        if (
          sendedCount - responsesCount < maxTogetherCount &&
          sendedCount < objIdArr.length
        ) {
          const queryData = queriesData[sendedCount];
          fetcher.sendedCount++;
          if (queryData.isGetFlag) {
            try {
              this.requestRetry(
                { url: queryData['url'], id: queryData['id'], maxQueryCount },
                fetcher,
              )
                .then((res) => {
                  // при выполеннии одного из запросов

                  if (
                    [
                      'skillsMan',
                      'getCalculated',
                      'criticalViolations',
                      'getCalculatedWithGeofences',
                      // 'regularMovingGeoTemplate',
                      'evacuatorMoving',
                    ].includes(this.templateName)
                  ) {
                    const jsonParams = JSON.stringify(
                      JSON.decycle(this.params),
                    );
                    const jsonSmenasOrigin = JSON.stringify(
                      JSON.decycle(this.smenasOrigin),
                    );

                    worker.postMessage({
                      templateName: this.templateName,
                      data: {
                        i: responsesCount,
                        object: res,
                        aggregateInterval: this.aggregateInterval,
                        isGetSmenas: this.isGetSmenas,
                        jsonSmenasOrigin,
                        jsonParams,
                      },
                    });
                  } else {
                    resultArray.push(res);
                  }

                  fetcher.responsesCount++;
                  fetcher.checkAndGetFetch(fetcher, resolve, reject);
                })
                .catch((e) => {
                  console.error(e);
                  fetcher.isError = true;
                  reject();
                });
            } catch (error) {
              fetcher.isError = true;
              reject();
            }
          } else {
            fetcher.responsesCount++;
          }
          // и при добавлении одного запроса
          fetcher.checkAndGetFetch(fetcher, resolve, reject);
        }
      },
    };

    fetcher.queriesData = this.queriesPrepare(objIdArr, dataSend);

    const result = await new Promise((resolve, reject) => {
      fetcher.checkAndGetFetch(fetcher, resolve, reject);
    });
    return result;
  }

  async getData({ url, id } = {}, fetcher) {
    return new Promise((resolve, reject) => {
      try {
        fetchJson(url, {})
          .then((response) => {
            return JSON.stringify(response);
          })
          .then((REPORT) => {
            const { responsesCount, queryCount } = fetcher;
            const percent = Math.round(
              (100 * (responsesCount + 1)) / queryCount,
            );

            this.VueLoadingProcess(
              `Загружено ${responsesCount + 1} из ${queryCount} (${percent}%)`,
            );

            infoShowText_helper(
              this.targetElement,
              `Получен ответ ${
                responsesCount + 1
              } из ${queryCount} (${percent}%)`,
              this.areaId,
            );
            resolve({ id, data: REPORT });
          })
          .catch((error) => {
            console.error(error);
            reject();
            throw error;
          });
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }

  async requestRetry({ url, id, maxQueryCount }, fetcher) {
    try {
      return await this.getData({ url, id }, fetcher);
    } catch (error) {
      if (maxQueryCount < 1) throw error;

      maxQueryCount--;
      return await this.requestRetry({ url, id, maxQueryCount }, fetcher);
    }
  }

  queriesPrepare(objIdArr, dataSend) {
    const { hostname, protocol } = this;

    try {
      return objIdArr.map((id, index) => {
        let url = this.url;

        const objId = `?id=${id}`;
        const keys = Object.keys(dataSend);

        let searchParams = '';

        for (let i = 0; i < keys.length; i++) {
          if (!dataSend[keys[i]]) continue;

          if (typeof dataSend[keys[i]] === 'object') {
            dataSend[keys[i]].map((val) => {
              searchParams += `&${keys[i]}%5B%5D=${val}`;
            });
            continue;
          }

          searchParams += `&${keys[i]}=${dataSend[keys[i]]}`;
        }

        const tzh = 0 - new Date().getTimezoneOffset() / 60;
        searchParams += `&tzh=${tzh}`;

        return {
          isGetFlag: true,
          errorIndex: 0,
          id,
          url: `${url}${objId}${searchParams}`,
        };
      });
    } catch (e) {
      console.error(e);
    }
  }
}
