<template>
  <!-- isSetCurrentTariff &&  -->
  <ui-table-td
    v-if="isRequest.vehicles || !currentVehicleTariffs.all.length"
    html="Нет тарифа"
  />
  <ui-table-td
    v-else
    class="my-link"
    :html="tariffName(currentVehicleTariffs.current)"
    @click="displayCurrentTariffs"
  />
</template>

<script setup>
import { computed } from 'vue';
import UiTableTd from './UiTableTd.vue';

import { useTariffs } from '@/ManualApp/use/tariffs';
import { useTable } from '@/ManualApp/use/table';
import { isRequest } from '@/ManualApp/use/refetch';

const props = defineProps({
  contractorId: { type: String, required: true },
  vehicleId: { type: String, required: true },
});
const emit = defineEmits(['displayCurrentTariffs']);

function displayCurrentTariffs() {
  const currentTariffsData = currentVehicleTariffs.value.all;

  emit('displayCurrentTariffs', currentTariffsData);
}

function tariffName() {
  if (currentVehicleTariffs.value.current === undefined) return 'Нет тарифа';

  if (typeof currentVehicleTariffs.value.current === 'string')
    return currentVehicleTariffs.value.current;

  return (
    useTable.getTariffName(currentVehicleTariffs.value.current.id_t) ||
    currentVehicleTariffs.value.current.name
  );
}

const currentVehicleTariffs = computed(() => {
  return useTariffs.currentVehicleTariff(props.contractorId, props.vehicleId);
});
</script>

<style lang="scss" scoped></style>
