import { mobileEvents } from '../../src/dataRequest/mainScript.js';
import { calcElementPosition } from '../use/calcElementPosition.js';
import { getTargetNodeByParentNodeByClassName } from '../use/getTargetNodeByParentNode';

const targetPositionHandler = (parentNode, targetNode) => {
  parentNode.addEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
};

export const addHoverOnTarget = (event) => {
  const targetNode = event.target.querySelector('.target-show-span');
  targetNode.classList.add('hover');

  targetPositionHandler(event.target, targetNode);
};
export const removeHoverOnTarget = (event) => {
  const targetNode = event.target.getElementsByClassName('target-show-span')[0];
  targetNode.classList.remove('hover');

  event.target.removeEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
};

export const addHoverOnTargetByParent = (event) => {
  const targetNode = getTargetNodeByParentNodeByClassName(
    event.target.childNodes,
    'target-show-span',
  );
  targetNode.classList.add('hover');

  targetPositionHandler(event.target, targetNode);
};
export const removeHoverOnTargetByParent = (event) => {
  const targetNode = getTargetNodeByParentNodeByClassName(
    event.target.childNodes,
    'target-show-span',
  );
  targetNode.classList.remove('hover');

  event.target.removeEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
};

export const addHoverOnTargetById = (event, id) => {
  const targetNode = document.getElementById(id);
  targetNode.classList.add('hover');

  targetPositionHandler(event.target, targetNode);
};
export const removeHoverOnTargetById = (event, id) => {
  const targetNode = document.getElementById(id);
  targetNode.classList.remove('hover');

  event.target.removeEventListener('mousemove', (moveEvent) => {
    calcElementPosition(moveEvent, mobileEvents, targetNode);
  });
};
