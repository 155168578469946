<template>
  <!-- анимация при загрузке страницы https://tobiasahlin.com/spinkit/# -->
  <div id="loading-content">
    <div class="loading-text">
      <div class="text-light text-center">
        <h3>
          {{
            SERVICE === 'Doosan'
              ? 'Эксплуатация машин ДСТ'
              : 'Навыки вождения MAN'
          }}
        </h3>
      </div>
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

const SERVICE = inject('SERVICE');
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/config.scss';
/* анимация загрузки */
#loading-content {
  display: block;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;

  background-color: #2980b9;

  @if ($VUE_APP_SERVICE_MODE == 'doosan') {
    background-color: $primary;
  }
}

.loading-text {
  margin-top: 35vh;
}

.spinner {
  margin: auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin-left: 2px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
