<template>
  <div
    class="monitoring"
    id="section-monitoring"
  >
    <div
      id="open-sidebar-btn"
      class="open-sidebar-btn cursor-pointer"
      @click="isShow.sidebar = true"
    ></div>
    <monitoring-sidebar
      id="section-monitoring-sidebar"
      :class="[{ 'sidebar-active': isShow.sidebar }]"
      @show="changeDisplaySections"
    />

    <aside
      id="section-monitoring-map"
      :class="[{ 'map-active': isShow.map }]"
    >
      <div id="map-wrapper">
        <div
          id="track-info"
          class="row"
        >
          <div class="col-sm-6 padding-none">
            <div
              id="track-player-btn"
              title="Плеер треков на карте"
            >
              <span class="target-show">
                <img
                  src="/images/track_player/track_player.svg"
                  alt="Трек_плеер"
                />
              </span>
            </div>

            <div
              id="track-charts-btn"
              title="Панель графиков по треку"
            >
              <span class="target-show">
                <img
                  src="/images/track_charts/charts.png"
                  alt="Граифики_по_треку"
                />
              </span>
            </div>

            <select
              class="form-control-sm"
              id="positionsList"
            >
              <option value="none">Нет запросов</option>
            </select>
          </div>
          <div
            id="violations-setting"
            class="btn-primary config-btn-primary col-sm-6"
          >
            Меню нарушений на карте (нет запросов)
          </div>
        </div>
        <div
          id="mapid"
          class="monitoring__map"
        ></div>
      </div>
    </aside>
    <violation-details-modal
      v-if="showViolationDetailsModal"
      :params-index="moreDetailViolationsObject.positionIndex"
      :count-of-positions-shown-top="5"
      :count-of-positions-shown-bottom="5"
      @close="showViolationDetailsModal = false"
    />
    <request-chart-data-panel
      v-if="chartsByObjectId[requestChartDataPopup.key]"
      v-model="requestChartDataPopup.visible"
      :id="requestChartDataPopup.id"
      :fuel-settings="requestChartDataPopup.fuelSettings"
      :from="requestChartDataPopup.from"
      :to="requestChartDataPopup.to"
      :chart-names="chartsByObjectId[requestChartDataPopup.key].checked"
      :saved="chartsByObjectId[requestChartDataPopup.key].saved"
      :levels="chartsByObjectId[requestChartDataPopup.key].levels"
      @add-charts="addCharts"
    />
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';

import MonitoringSidebar from './Sidebar/MonitoringSidebar.vue';
import ViolationDetailsModal from './Map/components/ViolationDetailsModal.vue';
import RequestChartDataPanel from '@/App/Components/Chart/RequestChartDataPanel.vue';

import { ready, globalObjects } from '@/src/dataRequest/mainScript';
import {
  leafletMain,
  moreDetailViolationsObject,
} from '@/Leaflet/leaflet_main';

import DragElement_helper from '@/helpers/dragElement_helper';
import { TrackPlayerPanel } from '@/Components/trackPlayerPanel';
import { TrackChartsPanel } from '@/Components/trackChartsPanel';
import { fuelSettingsStore } from '@/App/store/FuelSettingsStore';

const props = defineProps({
  openWindow: { type: String, required: true },
  clientWidth: { type: Number, required: true },
});
const emit = defineEmits(['ready', 'update:open-window']);

const showViolationDetailsModal = ref(false);

const isShow = ref({
  sidebar: true,
  map: true,
});

const chartsPanel = ref(null);
const requestChartDataPopup = ref({
  visible: false,
  id: '',
  fuelSettings: null,
});

const defaultChartNames = [
  'speed',
  'distance',
  // 'engine_time',
  // 'active_time',
  // 'hirpm_notactime',
];
const defaultDisplayedCharts = ['distance'];

const chartsByObjectId = ref({});

onMounted(async () => {
  await nextTick();
  ready();

  fuelSettingsStore.runUpdating();

  const trackPlayerPanel = new TrackPlayerPanel({
    buttonId: 'track-player-btn',
    positionsListId: 'positionsList',
    leafletMain,
    globalObjects,
    DragElement_helper,
  });

  chartsPanel.value = new TrackChartsPanel({
    buttonId: 'track-charts-btn',
    positionsListId: 'positionsList',
    leafletMain,
    globalObjects,
    DragElement_helper,
    defaultChartNames,
    defaultDisplayedCharts,
  });

  chartsPanel.value.displayRequestPopup = displayRequestChartsPopup;

  moreDetailViolationsObject.value.openModalHandler = openModalHandler;
});

watch(
  isShow,
  (show, oldShow) => {
    const sidebarNode = document.getElementById('section-monitoring-sidebar');
    const mapNode = document.getElementById('section-monitoring-map');
    const openBtnNode = document.getElementById('open-sidebar-btn');

    if (!sidebarNode || !mapNode) return;

    if (openBtnNode) openBtnNode.classList.remove('active');

    mapNode.style.width = '100%';

    if (show.sidebar && show.map) {
      sidebarNode.style.width = '420px';
    }
    if (show.sidebar && !show.map) {
      sidebarNode.style.width = '100%';
      mapNode.style.width = '75%';
    }

    if (!show.sidebar && show.map) {
      openBtnNode.classList.add('active');
    }

    if (!show.sidebar && !show.map) {
      isShow.value.sidebar = !oldShow.sidebar;
      isShow.value.map = !oldShow.map;
    }
  },
  { deep: true },
);

const getKey = (id, from, to) => {
  return `${id}#${from}#${to}`;
};

const openModalHandler = (e) => {
  const target = e.target;

  moreDetailViolationsObject.value.selectedObjId = target.dataset.objId;

  moreDetailViolationsObject.value.positionIndex = target.dataset.paramsIndex;

  showViolationDetailsModal.value = true;
};

const displayRequestChartsPopup = (e) => {
  const { id, from, to } = e.target.dataset;

  const key = getKey(id, from, to);

  requestChartDataPopup.value = {
    key,
    visible: true,
    id,
    fuelSettings: fuelSettingsStore.getSettingById(id).fuels,
    from,
    to,
  };

  if (!chartsByObjectId.value[key]) {
    chartsByObjectId.value[key] = {
      saved: addCopyArray_helper(defaultChartNames),
      checked: addCopyArray_helper(defaultChartNames),
      levels: [],
    };
  }
};

const changeDisplaySections = async (name) => {
  if (!name) return;

  isShow.value[name] = !isShow.value[name];

  await new Promise((r) => setTimeout(r, 300));
  leafletMain.map.invalidateSize();
};

function addCharts({
  chartNames: checkedCharts,
  columns,
  levels,
  treatmentPositions,
}) {
  const { id, key, fuelSettings } = requestChartDataPopup.value;

  const checkedFuelSettings = {};
  for (const fuelKey in fuelSettings) {
    checkedFuelSettings[fuelKey] = [];

    const setting = fuelSettings[fuelKey].find((setting) =>
      levels.includes(setting.tDetailNum),
    );

    if (setting) {
      checkedFuelSettings[fuelKey].push(setting);
    }
  }

  chartsByObjectId.value[key].saved = [
    ...new Set([
      ...chartsByObjectId.value[key].saved,
      ...checkedCharts,
      ...levels,
    ]),
  ];

  chartsByObjectId.value[key].checked = [...checkedCharts];
  chartsByObjectId.value[key].levels = [...levels];

  chartsPanel.value.addCharts(id, {
    chartNames: checkedCharts,
    levels,
    columns: columns.filter((c) => c != 'time'),
    fuelSettings: checkedFuelSettings,
    positions: treatmentPositions,
  });
}
</script>

<style lang="scss" scoped>
.open-sidebar-btn {
  width: 80px;
  height: 80px;

  position: fixed;
  top: 50%;
  left: -80px;
  z-index: 500;

  transform: translateY(-50%);

  background: var(--blue);
  border-radius: 50%;

  transition: all 0.2s ease;

  &.active {
    left: -40px;

    &:hover {
      -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 11;
    width: 20px;
    height: 20px;
    transform: translateY(-50%) rotate(45deg);
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}
#section-monitoring {
  overflow: hidden;
  @media (max-width: 450px) {
    display: flex;
  }
}
#section-monitoring-sidebar {
  width: 420px;
  padding: 0;
  flex-direction: column;

  margin-left: -420px;

  transition: all 0.3s ease-in-out;
  &.sidebar-active {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    display: block;
  }
}
#section-monitoring-map {
  margin-right: -100vw;

  transition: margin-right 0.3s ease-in-out;
  &.map-active {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    display: none;
  }
}
.monitoring {
  margin: 0;

  &__map {
    height: calc(100vh - 70px);
    width: 100%;

    @media (max-width: 1070px) {
      height: calc(100vh - 86px);
    }

    @media (max-width: 768px) {
      height: calc(100vh - 70px);
    }

    @media (max-width: 575px) {
      height: calc(100vh - 91px);
    }
  }
}
</style>
