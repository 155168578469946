<template>
  <div class="custom-time-picker-component">
    <div>
      <select
        class="select-input"
        v-model="hoursSelect.firstHours"
      >
        <option
          v-for="h in hoursArray"
          :key="'h1-' + h.value"
          :value="h.value"
          :selected="h.value === hours[1]"
        >
          {{ h.text }}
        </option>
      </select>
      <select
        class="select-input"
        v-model="minutesSelect.firstMinutes"
      >
        <option
          v-for="m in minutesArray"
          :key="'m1-' + m.value"
          :value="m.value"
          :selected="m.value === minutes[0]"
        >
          {{ m.text }}
        </option>
      </select>
    </div>
    <span></span>
    <div>
      <select
        class="select-input"
        v-model="hoursSelect.secondHours"
      >
        <option
          v-for="h in hoursArray"
          :key="'h2-' + h.value"
          :value="h.value"
          :selected="h.value === hours[1]"
        >
          {{ h.text }}
        </option>
      </select>
      <select
        class="select-input"
        v-model="minutesSelect.secondMinutes"
      >
        <option
          v-for="m in minutesArray"
          :key="'m2-' + m.value"
          :value="m.value"
          :selected="m.value === minutes[1]"
        >
          {{ m.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:hours', 'update:minutes'],

  props: {
    hoursIncrement: { type: [Number, String], default: 1 },
    minutesIncrement: { type: [Number, String], default: 1 },
    is24: { type: Boolean, default: true },
    hoursGridIncrement: { type: [String, Number], default: 1 },
    minutesGridIncrement: { type: [String, Number], default: 5 },
    range: { type: Boolean, default: false },
    filters: { type: Object, default: () => ({}) },
    minTime: { type: Object, default: () => ({}) },
    maxTime: { type: Object, default: () => ({}) },
    timePicker: { type: Boolean, default: false },
    hours: { type: [Number, Array] },
    minutes: { type: [Number, Array] },
    customProps: { type: Object, default: null },
  },

  data() {
    return {
      hoursSelect: { firstHours: 0, secondHours: 0 },
      minutesSelect: { firstMinutes: 0, secondMinutes: 0 },
    };
  },

  watch: {
    hours: {
      handler(val) {
        if (
          val[0] !== this.hoursSelect.firstHours ||
          val[1] !== this.hoursSelect.secondHours
        ) {
          this.hoursSelect = { firstHours: val[0], secondHours: val[1] };
        }
      },
      immediate: true,
    },
    minutes: {
      handler(val) {
        if (
          val[0] !== this.minutesSelect.firstMinutes ||
          val[1] !== this.minutesSelect.secondMinutes
        ) {
          this.minutesSelect = { firstMinutes: val[0], secondMinutes: val[1] };
        }
      },
      immediate: true,
    },
    hoursSelect: {
      handler() {
        this.emitAll();
      },
      deep: true,
    },
    minutesSelect: {
      handler() {
        this.emitAll();
      },
      deep: true,
    },
  },

  mounted() {
    this.emitAll();
  },

  computed: {
    hoursArray() {
      const arr = [];
      for (let h = 0; h < 24; h++) {
        arr.push({ text: h < 10 ? `0${h}` : h, value: h });
      }
      return arr;
    },
    minutesArray() {
      const arr = [];
      for (let m = 0; m < 60; m++) {
        if (m === 0 || m % 10 === 0) {
          arr.push({ text: m < 10 ? `0${m}` : m, value: m });
        }
      }
      return arr;
    },
  },
  methods: {
    emitAll() {
      this.$emit('update:hours', [
        this.hoursSelect.firstHours,
        this.hoursSelect.secondHours,
      ]);
      this.$emit('update:minutes', [
        this.minutesSelect.firstMinutes,
        this.minutesSelect.secondMinutes,
      ]);
    },
  },
};
</script>

<style lang="scss">
.custom-time-picker-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & span {
    width: 30px;
    height: 2px;
    background: #000;

    @media (max-width: 450px) {
      width: 10px;
      height: 1px;
    }
    @media (max-width: 1024px) and (orientation: landscape) {
      width: 10px;
      height: 1px;
    }
  }

  @media (max-width: 450px) {
    flex-direction: row;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    flex-direction: row;
  }
}

.select-input {
  margin: 10px 3px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
  @media (max-width: 1024px) {
    width: 60px;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    width: 60px;
    margin: 6px 3px;
  }
}
</style>
