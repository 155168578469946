<template>
  <ui-popup
    style-for-body="padding: 20px;"
    @close="() => emit('close')"
  >
    <div
      class="d-flex flex-column"
      style="width: 70vw; max-height: 90vh"
      id="overspeed-template-detail"
    >
      <div class="d-flex justify-content-between">
        <button
          class="not-print float-left"
          style="max-height: 35px"
          onclick="html_to_excel_helper.export(this, 'overspeed-template-detail', 'Превышение скорости', 'landscape')"
        >
          Экспорт&nbsp;в&nbsp;EXCEL
        </button>
        <div>
          <h6
            class="text-center title mb-0"
            v-html="props.title"
          ></h6>
          <h6
            class="text-center title mt-0"
            v-html="props.interval"
          ></h6>
        </div>
        <ui-close-button
          class="not-excel"
          style="margin-top: -10px; margin-bottom: 10px"
          @click="() => emit('close')"
        />
      </div>
      <div>
        <h6
          class="text-center not-excel title--mobile mb-0"
          v-html="props.title"
        ></h6>
        <h6
          class="text-center not-excel title--mobile mt-0"
          v-html="props.interval"
        ></h6>
      </div>
      <h6 class="subtitle justify-content-center">
        Макс. установленная скорость при обсчете {{ props.speedLimit }} км/ч
      </h6>

      <ui-table>
        <template #header>
          <tr class="print-repeat print-frozen-row">
            <template v-for="(obj, key) in TABLE_HEADER">
              <ui-table-th
                :data-minwidth="obj.excelWidth"
                :cell="obj"
                :cell-key="key"
              />
            </template>
          </tr>
        </template>
        <template #default>
          <template v-for="row in props.rows">
            <tr>
              <template v-for="(obj, key) in TABLE_HEADER">
                <ui-table-td
                  v-if="key == 'coords'"
                  :html="getA(row)"
                />
                <ui-table-td
                  v-else
                  :data-formatnum="obj.formatNum"
                  :data-value="obj.excelValue ? obj.excelValue(row) : ''"
                  :html="obj.get ? obj.get(row) : row[key]"
                />
              </template>
            </tr>
          </template>
        </template>
      </ui-table>
    </div>
  </ui-popup>
</template>

<script setup>
import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import UiTable from '@/ManualApp/Components/Tables/UiTable.vue';
import UiTableTh from '@/ManualApp/Components/Tables/UiTableTh.vue';
import UiTableTd from '@/ManualApp/Components/Tables/UiTableTd.vue';

const props = defineProps({
  title: String,
  interval: String,
  speedLimit: [String, Number],
  rows: { type: Array, default: [] },
});

const emit = defineEmits(['close']);

const TABLE_HEADER = {
  from: {
    text: 'Время',
    excelWidth: 27,
    formatNum: 3,
    excelValue: ({ from }) => {
      return from
        ? +from / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
        : '';
    },
    get: ({ from }) => formatDateHelper(new Date(from), 'hh:nn:ss'),
  },
  speed: {
    text: 'Скорость, км/ч',
    excelWidth: 27,
    formatNum: 12,
  },
  overspeed: {
    text: 'Превышение на, км/ч',
    excelWidth: 27,
    formatNum: 12,
  },
  coords: {
    text: 'Координаты превышения',
    excelWidth: 40,
    formatNum: 0,
  },
};

const getA = ({ lat, lon }) => {
  const rLat = myRoundNumber_helper(+lat, 5);
  const rLon = myRoundNumber_helper(+lon, 5);

  // return `<a href="http://maps.google.com/maps?q=${lat},${lon}" target="_blank">${rLat}, ${rLon}</a>`;
  return `<a href="${
    process.env.VUE_APP_BACKEND_URL || window.location.origin
  }/track-motion?type=position&lat=${lat}&lon=${lon}" target="_blank">${rLat}, ${rLon}</a>`;
};
</script>

<style lang="scss"></style>
