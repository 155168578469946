<template>
  <div
    v-if="isMainShow"
    ref="contentRef"
  >
    <button
      class="not-print float-left"
      onclick="html_to_excel_helper.export(this, 'geofences-regular-moving-template', 'Рейсы по геозонам', 'landscape')"
    >
      Экспорт&nbsp;в&nbsp;EXCEL
    </button>
    <div id="geofences-regular-moving-template">
      <!-- <h6 class="text-center font-weight-bold">Рейсы по геозонам с {{ reportBegin }} по {{ reportEnd }}</h6> -->
      <h6 class="text-center font-weight-bold">
        Рейсы по геозонам{{ t_interval }}
      </h6>
      <p class="text-center excel-bottom-indent">
        Отчет сформирован {{ formatTimeHelper2(new Date()) }}
      </p>
      <regular-moving-by-geo-template-table
        v-for="object in objectsCalculated"
        :key="object.id"
        :object="object"
        :isGroup="isMovingGroup"
        :isWeight="isWeight"
        :replaceNbsp_helper="replaceNbsp_helper"
        :showDetailTable="showDetailTable"
      ></regular-moving-by-geo-template-table>
    </div>
    <regular-moving-geo-template-detail-table
      v-if="isDetailShow"
      :columnSettings="$options.detailColumnSettings"
      :rowsSettings="detailRowsSettings"
      :columns="$options.detailColumns"
      :rowsValues="detailRowsValues"
      :rowsOrder="detailRowsOrder"
      :getValue="detailGetValue"
      :getCellStyle="detailGetCellStyle"
      :showTable="showDetailTable"
    />
  </div>
</template>

<script>
// import fetchJson from "../../../src/dataRequest/fetch-json";
import { OurAggregated } from '../../../src/dataRequest/ourAggregated.js';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template.js';
import RegularMovingByGeoTemplateTable from './RegularMovingByGeoTemplateTable.vue';
import RegularMovingGeoTemplateDetailTable from './RegularMovingGeoTemplateDetailTable.vue';

import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService.js';
import { usePrepareGeofencesForReports } from '@/App/use/prepareGeofencesForReports';
import {formatTimeHelper2} from '@/helpers/main_helper'

export default {
  components: {
    RegularMovingByGeoTemplateTable,
    RegularMovingGeoTemplateDetailTable,
  },
  SUMM_AREA_ID: 'section-reports',
  // TEMPLATE_NAME: 'getCalculatedWithGeofences',
  TEMPLATE_NAME: 'regularMovingGeoTemplate',
  ROUND_TO: 10, // округление по 10 минут
  inject: ['geofenceTypes', 'globalObjectsList'],
  props: {
    geofences: {
      type: Array,
      required: true,
    },
    checkedGeofencesToTemplate: {
      type: Array,
      required: true,
    },
    checkedGeofencesWorkZone: {
      type: Array,
      required: true,
    },
    button: {
      type: Object,
      required: true,
    },
    buttonTarget: {
      type: Object,
      required: true,
    },
    trigger: {
      type: Boolean,
      required: true,
    },
    isBasket: {
      type: Boolean,
      required: true,
    },
    isWeight: {
      type: Boolean,
      required: true,
    },
    isMovingGroup: {
      type: Boolean,
      required: true,
    },
    resetTrigger: {
      type: Function,
      required: true,
    },
    form_desc: {
      type: Object,
      required: true,
    }
  },
  detailColumns: [
    'DNum',
    'DSkill',
    /*'DType', */
    'DForfeits',
    'DForfeitsRelative',
  ],
  detailColumnSettings: {
    DNum: {
      text: '№ п/п',
      formatNum: 0,
      style: {
        width: '10%',
      },
    },
    DSkill: {
      text: 'Навык',
      formatNum: 0,
      style: {
        width: '40%',
      },
    },
    DForfeits: {
      text: 'Количество нарушений',
      formatNum: 12,
    },
    DForfeitsRelative: {
      text: 'Количество нарушений приведенное к 100 км пробега',
      formatNum: 14,
    },
  },
  setup() {
    return {
      formatTimeHelper2
    }
  },
  data() {
    return {
      isMainShow: false,
      isDetailShow: false,
      isAllObjects: false,
      objectsCalculated: [],
      objectsListForm: {},
      objectsList: {},
      templateContentElement: {},
      detailRowsSettings: {},
      detailRowsOrder: [],
      detailRowsValues: {},
    };
  },
  computed: {
    t_interval() {
      const [firstObj] = this.objectsCalculated;
      if (firstObj) {
        return firstObj.view?.t_interval || ' - ';
      }
      return ' - ';
    },
  },
  methods: {
    replaceNbsp_helper(text) {
      return text.replace(/&nbsp;/g, ' ');
    },
    async calculateEvent() {
      infoShowText_helper(
        this.buttonTarget,
        `Отправляю запрос...`,
        this.$options.SUMM_AREA_ID,
      );
      setElementColor_helper(this.button, 'blue');

      const beginTime = this.objectsListForm['beginTime'].value;
      const endTime = this.objectsListForm['endTime'].value;

      const isCheckedOnly = !this.isAllObjects;
      const objectsList = []
      for (const key in this.globalObjectsList) {
        const val = this.globalObjectsList[key]
        if (this.form_desc.objects.includes(key)) {
          objectsList.push(this.globalObjectsList[key])
        }
      }

      const templateName = this.$options.TEMPLATE_NAME;
      const dataSend = {
        time_begin: beginTime / 1000,
        time_end: endTime / 1000,
        option: 2,
        template_name: templateName,
        geofences: this.checkedGeofencesToTemplate.concat(
          this.checkedGeofencesWorkZone,
        ),
        is_basket: this.isBasket,
        is_weight: this.isWeight,
      };

      if (
        !objectsList.length ||
        (!this.checkedGeofencesToTemplate.length && !this.isWeight)
      ) {
        const textToDisplay = !objectsList.length
          ? 'Не выбран ни один объект.'
          : 'Не выбраны геозоны для отчета';
        infoShowText_helper(
          this.buttonTarget,
          textToDisplay,
          this.$options.SUMM_AREA_ID,
        );
        setElementColor_helper(this.button, 'orange');
        return;
      }

      const objIdArr = objectsList.reduce((accum, obj) => {
        accum.push(obj.id);
        return accum;
      }, []);

      const dataArr = { objIdArr: this.form_desc.objects };

      // запрос позиций и подсчет значений по отчету
      const ourAggregated = new OurAggregated(skillsManTemplate, undefined ,this.globalObjectsList);

      const leafletGeofences = this.geofences;

      const geofencesIds = this.checkedGeofencesToTemplate.concat(
        this.checkedGeofencesWorkZone,
      );
      const geofencesLayerData = usePrepareGeofencesForReports(
        geofencesIds,
        leafletGeofences,
      );
      
      fetchToTemplateService({
        templateName,
        form_desc: this.form_desc,
        options: {
          option: 2,
          template_name: templateName,

          geofences: geofencesIds,

          is_basket: this.isBasket,
          is_weight: this.isWeight,

          isGetSmenas: false,
          smenasOrigin: [],

          params: {
            objectsList,
            // that: this,
            isBasket: this.isBasket,
            isWeight: this.isWeight,
            isMovingGroup: this.isMovingGroup,
            geofencesToTemplate: [...this.checkedGeofencesToTemplate],
            geofencesWorkZone: [...this.checkedGeofencesWorkZone],
            leafletGeofences,
            geofencesLayerData,
            isGeoCalc: true,
            templateName,
          },
        },
        areaId: this.$options.SUMM_AREA_ID,
        successCallback: this.calculatedCb,
        errorCallback: () =>
          ourAggregated.getAggregated({
            dataArr,
            dataSend,
            targetElement: this.buttonTarget,
            buttonElement: this.button,
            templateName,
            callback: this.calculatedCb,
            areaId: this.$options.SUMM_AREA_ID,
            params: {
              objectsList,
              that: this,
              isBasket: this.isBasket,
              isWeight: this.isWeight,
              isMovingGroup: this.isMovingGroup,
              geofencesToTemplate: this.checkedGeofencesToTemplate,
              geofencesWorkZone: this.checkedGeofencesWorkZone,
              leafletGeofences,
              geofencesLayerData,
              isGeoCalc: true,
              templateName,
            },
            isGetSmenas: false,
            smenasOrigin: [],
          }),
      });

      // дальнейшие действия происходят внутри getAggregated - вызывается callback после получения и обсчета данных
    },
    calculatedCbWrapper(objectsCalculated, params = {}) {
      params.that.calculatedCb(objectsCalculated, params);
    },
    calculatedCb(objectsCalculated, { objectsList = [] } = {}) {
      this.objectsCalculated = objectsCalculated.objects;
      // humanTime
      this.objectsCalculated.forEach((obj) => {
        obj.eventsRows.forEach((eventRow) => {
          eventRow.humanTime = formatDateHelper(
            new Date(eventRow.time * 1000),
            'dd.mm.yyyy hh:nn:ss',
          );
        });
      });

      if (isEmptyObject_helper(this.detailRowsSettings)) {
        const [firstObj = {}] = this.objectsCalculated;
        const { rows_person = {} } = firstObj;
        const { person, personForDisplay } = rows_person;
        if (person && personForDisplay) {
          this.detailRowsSettings = addCopyObj_helper(person); // стока: значения колонок
          this.detailRowsOrder = personForDisplay.reduce((acc, group) => {
            // строки в каком порядке выгружать, с группировкой
            group.forEach((rowName) => acc.push(rowName));
            return acc;
          }, []);
        }
      }

      infoShowText_helper(
        this.buttonTarget,
        `Отчет построен. Выполнить новый запрос?`,
      );
      setElementColor_helper(this.button, 'green');
      // this.isShow = true;
      this.isMainShow = true;
      this.$nextTick(() => {
        this.templateContentElement.innerText = '';
        this.templateContentElement.append(this.$refs.contentRef);
      });
    },
    // returnComponent() {
    //   if (this.isShow) {
    //     this.$refs.mainRef.prepend(this.$refs.contentRef);
    //     this.isShow = false;
    //     this.isDetailShow = false;
    //   }
    // },
    showDetailTable(isDetailShow, { rows = [] } = {}) {
      if (!isDetailShow) {
        this.isDetailShow = false;
        return;
      }
      this.detailRowsValues = rows;
      this.isDetailShow = true;
    },
    detailGetValue({ rowName, column } = {}) {
      if (['DSkill', 'DNum'].includes(column)) {
        return this.detailRowsSettings[rowName][column];
      }
      return this.detailRowsValues[rowName]?.[column];
    },
    detailGetCellStyle({ rowName, column } = {}) {
      const style = {};
      const setting = this.detailRowsSettings[rowName];
      if (!setting) {
        return style;
      }
      if (setting[`${column}_isbold`]) {
        style['font-weight'] = 'bold';
      }
      return style;
    },
  },
  watch: {
    trigger(val) {
      if (val) {
        this.resetTrigger();
        this.calculateEvent();
      }
    },
  },
  mounted() {
    this.templateContentElement = document.getElementById(
      this.$options.SUMM_AREA_ID,
    );
    // this.templateContentElement.addEventListener('before-write-event', this.returnComponent);
    this.objectsListForm = document.getElementById('objectsListForm');
    this.objectsList = document.getElementById('objectsListId');
  },
};
</script>

<style scoped></style>
