<template>
  <div v-show="isShowing">
    <div>
      <div class="template-setting-window">
        <div class="template-setting-window-header">
          <div class="template-setting-window-header-name">
            <strong>Послещение геозон</strong>
            <br />
            <small>Настройка отчета</small>
          </div>
          <!-- Картинка крестика-->
          <img
            data-element="close"
            class="close"
            src="/images/close.png"
            @click="close"
          /><!-- onclick="showHiddenWindow('none', 'shiftWork-template-setting-window')" -->
        </div>
        <div class="unselectable">
          <label :target="basketTarget">
            <input
              type="checkbox"
              v-model="isBasket"
            />
            Выгрузка по датчику поднятия кузова
            <span class="checkbox-target">({{ basketTarget }})</span> </label
          ><br />
          <label :target="movingGroupTarget">
            <input
              type="checkbox"
              v-model="isMovingGroup"
            />
            Группировка рейсов
            <span class="checkbox-target">({{ movingGroupTarget }})</span>
          </label>
        </div>
        <div class="overflow-auto">
          <span
            class="my-underline"
            @click="geofencesWorkZoneShow = !geofencesWorkZoneShow"
          >
            Геозоны рабочей области:
          </span>
          <geofences-list-table
            v-show="geofencesWorkZoneShow"
            :geofences="geofencesWorkZone"
            :changeGeofences="changeGeofencesWorkZone"
            idName="work-zone"
          ></geofences-list-table>
          <!-- {{ checkedGeofencesWorkZone }} -->
          <br />
          <span
            class="my-underline"
            @click="geofencesToTemplateShow = !geofencesToTemplateShow"
          >
            Геозоны для отчета:
          </span>
          <geofences-list-table
            v-show="geofencesToTemplateShow"
            :geofences="geofencesRegularZone"
            :changeGeofences="changeGeofencesToTemplate"
            idName="raid-zone"
          ></geofences-list-table>
          <!-- {{ checkedGeofencesToTemplate }} -->
          <div class="center-tag padding-top-5">
            <small>Окончание настройки отчета</small>
          </div>
        </div>
        <div class="center-tag">
          <span class="target-show"
            ><input
              class="btn btn-primary config-btn-primary btn-sm"
              type="submit"
              @click="calculateEvent"
              value="Выполнить отчет"
            /><span class="target-show-span"
              >Обсчет данных для получения отчета</span
            ></span
          >
        </div>
      </div>
    </div>
    <div
      @click="close"
      data-element="close"
      id="background-wrap"
      class="background-wrap"
      style="display: flex"
      wfd-invisible="true"
    ></div>
    <!-- <regular-moving-by-geo-template 
      :geofences="geofences"
      :checkedGeofencesToTemplate="checkedGeofencesToTemplate"
      :checkedGeofencesWorkZone="checkedGeofencesWorkZone"
      :button="button"
      :buttonTarget="buttonTarget"
      :trigger="trigger"
      :isBasket="isBasket"
      :isMovingGroup="isMovingGroup"
      :resetTrigger="resetTrigger"
    ></regular-moving-by-geo-template> -->
  </div>
</template>

<script>
import GeofencesListTable from '../Shared/GeofencesListTable.vue';
// import RegularMovingByGeoTemplate from './RegularMovingByGeoTemplate.vue';

export default {
  components: {
    GeofencesListTable,
    // RegularMovingByGeoTemplate
  },
  inject: [
    'authorizatedUserRights',
    'allGeofences',
    'geofencesUpdating',
    // "isGeofencesUpdating"
  ],
  data() {
    return {
      isShowing: false,
      showTemplateButton: {},
      geofences: [],
      checkedGeofencesToTemplate: [],
      geofencesWorkZoneShow: true,
      geofencesWorkZone: [],
      checkedGeofencesWorkZone: [],
      geofencesRegularZone: [],
      geofencesToTemplateShow: true,
      geofenceIds: {},
      button: {},
      buttonTarget: {},
      trigger: false,
      isBasket: false,
      isMovingGroup: false,
    };
  },
  methods: {
    changeGeofencesToTemplate(checkedGeofences) {
      this.checkedGeofencesToTemplate = checkedGeofences;
    },
    changeGeofencesWorkZone(checkedGeofences) {
      this.checkedGeofencesWorkZone = checkedGeofences;
    },
    showTemplateButtonEvent() {
      this.isShowing = true;
    },
    close(event) {
      if (event.target.dataset.element === 'close') {
        this.isShowing = false;
      }
    },
    geofencesListUpdate(flag) {
      if (!flag) return;
      const ids = {};
      this.allGeofences.forEach((geo) => {
        const { key } = geo;
        ids[key] = true;
        if (!this.geofenceIds[key]) {
          this.geofenceIds[key] = geo;
          this.geofences.unshift(geo);
        }
      });
      this.geofences = this.geofences.filter((geo) => {
        const { key } = geo;
        if (ids[key]) {
          return true;
        }
        delete this.geofenceIds[key];
      });
      this.geofencesWorkZone = this.geofences.filter(
        (geo) => parseInt(geo.type) === 8,
      );
      this.geofencesRegularZone = this.geofences.filter((geo) =>
        [1, 2, 3, 5].includes(parseInt(geo.type)),
      );
    },
    calculateEvent() {
      this.isShowing = false;
      this.trigger = true;
    },
    resetTrigger() {
      this.trigger = false;
    },
  },
  watch: {
    isShowing(flag) {
      this.geofencesListUpdate(flag);
    },
    isGeofencesUpdating(flag) {
      this.geofencesListUpdate(!flag);
    },
  },
  computed: {
    isGeofencesUpdating() {
      return this.geofencesUpdating.flag;
    },
    basketTarget() {
      if (this.isBasket) {
        return 'завершение рейса только по датчику поднятия кузова';
      }
      return 'датчик поднятия кузова не учитывается';
    },
    movingGroupTarget() {
      if (this.isMovingGroup) {
        return 'рейсы будут сгрупированы по геозонам погрузки и разгрузки';
      }
      return 'рейсы будут выведены по отдельности каждый в хронологическом порядке';
    },
  },
  mounted() {
    const showTemplateButton = document.getElementById('get-template-button');
    this.showTemplateButton = showTemplateButton;
    showTemplateButton.addEventListener(
      'regular-moving-template-show',
      this.showTemplateButtonEvent,
    );
    showTemplateButton.addEventListener('click', this.returnComponent);
    this.button = document.getElementsByClassName('get-template-button-id')[0];
    this.buttonTarget = document.getElementsByClassName(
      'get-template-button-target-id',
    )[0];
  },
  beforeUnmount() {
    this.showTemplateButton.removeEventListener(
      'regular-moving-template-show',
      this.showTemplateButtonEvent,
    );
  },
};
</script>

<style scoped>
.center-tag {
  text-align: center;
}
.template-setting-window {
  display: flex;
  padding: 4px 8px;
}
.my-underline {
  text-decoration: underline;
  cursor: pointer;
}
.checkbox-target {
  color: blue;
}
</style>
