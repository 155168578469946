<template>
  <table
    class="text-align-center"
    border="1"
    cellpadding="5"
    style="
      border-collapse: collapse;
      border: 1px solid black;
      caption-side: top;
      margin-right: 5px;
    "
  >
    <thead>
      <tr>
        <th
          v-for="col of DETAIL_COLUMNS"
          :key="col.label"
          v-bind="col.attrs"
          v-html="col.label"
          rowspan="2"
        ></th>
        <th
          data-excelalignh="center"
          :colspan="props.object.detailByPeriods[0].length"
        >
          Час работы от начала суток в формате: работа ДВС (моточасы), работа по
          инклинометру (моточасы) первые 30 минут / вторые 30 минут
        </th>
      </tr>
      <tr class="border-bottom-tr print-repeat print-frozen-row">
        <th
          v-for="n of props.object.detailByPeriods[0].length"
          :key="n"
          data-minwidth="14"
          data-formatnum="12"
          data-excelalignh="center"
          style="min-width: 100px"
          v-html="n - 1"
        ></th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(period, index) of props.object.detailByPeriods"
        :key="index"
      >
        <td
          v-for="col of DETAIL_COLUMNS"
          :key="col.label"
          v-bind="col.attrs"
          @click="
            () =>
              !['н/д', '0'].includes(col.format(props.object.periods[index])) &&
              col.onClick &&
              col.onClick(props.object.periods[index])
          "
        >
          <div
            :class="[
              {
                'my-link':
                  !['н/д', '0'].includes(
                    col.format(props.object.periods[index]),
                  ) && col.onClick,
              },
            ]"
            v-html="
              tableFormat(
                col.isSum
                  ? col.format(props.object.periods[index])
                  : col.format(props.object[col.name]),
              )
            "
          ></div>
        </td>
        <td
          v-for="(hour, index) of period"
          :key="props.object.name + index"
          data-excelalignh="center"
        >
          <div class="text-nowrap">
            {{ getValue(hour) }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { inject } from 'vue';

import {
  getPercent,
  myRoundNumber_helper,
  tableFormat,
} from '@/helpers/main_helper';

const props = defineProps({ object: Object });
const emit = defineEmits(['displayTrack']);

const toHours = inject('toHours');
const getFullDate = inject('getFullDate');

const DETAIL_COLUMNS = [
  {
    name: 'avtoNo',
    label: 'Гос №',
    attrs: {
      'style': { minWidth: '100px' },
      'data-minwidth': 16,
    },
    format: (val) => `${val}`,
  },
  {
    isSum: true,
    name: 'from',
    label: 'Дата',
    attrs: {
      'style': { minWidth: '150px' },
      'data-minwidth': 17,
    },
    format: (period) => getFullDate(period.TimeBegin / 1000),
  },
  {
    isSum: true,
    name: 'engineTime',
    label: 'Работа ДВС (часы)',
    attrs: {
      'style': { minWidth: '200px' },
      'data-minwidth': 15,
      'data-formatnum': 7,
    },
    format: (period) =>
      isNaN(period.webSumm.engine_time)
        ? 'н/д'
        : `${myRoundNumber_helper(toHours(period.webSumm.engine_time), 1)}`,
    onClick(period) {
      displayTrack({ from: period.TimeBegin, to: period.TimeEnd });
    },
  },
  {
    isSum: true,
    name: 'usageFactor',
    label: 'Коэф. исп. (%) , по инклинометру',
    attrs: {
      'style': { minWidth: '130px' },
      'data-minwidth': 17,
      'data-formatnum': 6,
    },
    format: (period) =>
      isNaN(period.webSumm.active_time) || isNaN(period.webSumm.engine_time)
        ? 'н/д'
        : `${getPercent(
            period.webSumm.active_time,
            period.webSumm.engine_time,
          )}%`,
  },
  {
    isSum: true,
    name: 'parkTime',
    label: 'ДВС без выполнения работы (по инклинометру)',
    attrs: {
      'style': { minWidth: '200px' },
      'data-minwidth': 26,
      'data-formatnum': 7,
    },
    format: (period) =>
      `${myRoundNumber_helper(
        toHours(period.webSumm.engine_time - period.webSumm.active_time) > 0
          ? toHours(period.webSumm.engine_time - period.webSumm.active_time)
          : 0,
        1,
      )}`,
  },
  {
    isSum: true,
    name: 'hirpm_notactime',
    label: 'Повышенные обороты без работы по инклинометру',
    attrs: {
      'style': { minWidth: '200px' },
      'data-minwidth': 26,
      'data-formatnum': 7,
    },
    format: (period) =>
      `${myRoundNumber_helper(toHours(period.webSumm.hirpm_notactime), 1)}`,
  },
];

const getValue = (hour) => {
  const values = [];
  let notData = 0;
  let notValue = 0;

  for (const pos of hour) {
    if (pos) {
      const engine_hours = toHours(pos.engine_time);
      const active_hours = toHours(pos.active_time);
      if (engine_hours == 0 && active_hours == 0) notValue++;
      values.push(`${engine_hours.toFixed(1)},${active_hours.toFixed(1)}`);
    } else {
      notData++;
    }
  }
  if (notData === hour.length) return 'н/д';
  if (notValue === hour.length) return '-';

  return values.join(' / ');
};

function displayTrack(interval) {
  emit('displayTrack', interval);
}
</script>

<style lang="scss"></style>
