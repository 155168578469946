<template>
  <div>
    <table class="objectsList objectsListId sortable-table freezeTable-table">
      <thead>
        <tr>
          <slot name="thead" />
        </tr>
      </thead>
      <tbody>
        <slot name="tbody" />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UiTable',
};
</script>

<style></style>
