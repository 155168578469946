<template>
  <div
    class="admin-modal-window"
    :style="[{ display: props.show ? 'block' : 'none' }]"
  >
    <!-- Картинка крестика-->
    <img
      class="close"
      src="/images/close.png"
      @click="emit('close')"
    />
    <!-- <template v-if="!Object.values(adminData).length">
      <div style="margin-top: 30%">
        <preloader />
      </div>
    </template> -->
    <template v-if="error">
      <div
        class="text-center"
        style="margin-top: 30%"
      >
        У пользователя
        <span style="color: #0000ff">{{ adminData.login }}</span>
        нет прав администратора.
      </div>
    </template>
    <div
      v-else
      :style="{ display: props.show ? 'block' : 'none' }"
    >
      <div
        class="container-fluid"
        style="margin-top: 20px"
      >
        <div class="row header">
          <button
            type="button"
            class="btn btn-primary config-btn-primary btn-sm"
            @click="showModalHandler('add-organization')"
          >
            Создать организацию
          </button>
          <button
            type="button"
            class="btn btn-primary config-btn-primary btn-sm my-m-2"
            @click="showModalHandler('add-user')"
          >
            Создать пользователя
          </button>
          <div class="flex">
            <button
              class="btn btn-outline-secondary btn-sm my-m-2"
              @click="refreshHandler"
            >
              Обновить
            </button>
            <img
              class="my-btn admin_panel mr-4 my-m-2 ml-2 cursor-pointer"
              id="logout"
              src="/images/logout.svg"
              alt=""
              @click="showModalHandler('admin-logout-modal')"
            />
          </div>
          <div class="flex">
            <span class="my-header-info"> &nbsp;| Вы вошли как &nbsp; </span>
            <div
              v-if="!adminData.userLogin"
              class="d-flex align-items-center"
            >
              <spin-loader
                :width="15"
                :height="15"
              />
            </div>
            <span
              v-else
              class="my-btn login-text my-header-info cursor-pointer"
              @click="showModalHandler('user-info')"
            >
              {{ adminData.userLogin }}
            </span>
          </div>
          <span class="my-header-info">
            <template v-if="adminData.main_admin">
              &nbsp;| Размер БД: {{ adminData.db_size }}
            </template>
          </span>

          <button class="btn btn-primary config-btn-primary btn-sm my-m-2" @click="showModalHandler('recalculate')">Пересчет объектов</button>
        </div>
        <hr />
        <span v-if="formInfo">{{ formInfo }}</span>
        <div class="row admin-table-wrapper">
          <div
            class="col-12"
            style="max-width: 5000px; width: 5000px; overflow: auto;"
          >
            <h6
              class="table-name my-btn pb-1 accordion__title"
              @click="toggleAccordion('users')"
            >
              <span>Таблица пользователей</span>
            </h6>
            <div
              v-if="!adminData.usersData"
              class="d-flex justify-content-center"
            >
              <spin-loader
                :width="50"
                :height="50"
              />
            </div>
            <div
              v-else
              class="my-toggle accordion__body"
              :class="{ closed: isAccordionClosed.users }"
              id="users-table"
            >
              <ui-table
                :user-role="adminData.userRole"
                :is-organization="false"
                :data="adminData"
                :contractors="contractorsData"
                @edit="editHandler"
              />
            </div>
          </div>
          <div
            class="col-12"
            style="max-width: 5000px; width: 5000px; overflow: auto;"
          >
            <h6
              class="table-name my-btn pb-1 accordion__title"
              @click="toggleAccordion('organizations')"
            >
              <span>Таблица организаций</span>
            </h6>
            <div
              v-if="!adminData.organizationsData"
              class="d-flex justify-content-center"
            >
              <spin-loader
                :width="50"
                :height="50"
              />
            </div>
            <div
              v-else
              class="my-toggle accordion__body"
              :class="{ closed: isAccordionClosed.organizations }"
              id="orgaizations-table"
            >
              <div>
                <ui-table
                  :user-role="adminData.userRole"
                  :is-organization="true"
                  :data="adminData"
                  :contractors="contractorsData"
                  @edit="editHandler"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ui-admin-form
        :create="true"
        v-if="showModal === 'add-user'"
        :data="adminData"
        :contractors="contractorsData"
        :is-organization="false"
        :action="'create'"
        @close="showModal = ''"
        @refresh="refreshHandler"
        @form-info="setFormInfo"
      />
      <ui-admin-form
        :create="true"
        v-if="showModal === 'add-organization'"
        :data="adminData"
        :contractors="contractorsData"
        :is-organization="true"
        :action="'create'"
        @close="showModal = ''"
        @refresh="refreshHandler"
        @form-info="setFormInfo"
      />
      <ui-admin-form
        :edit="true"
        v-if="showModal === 'edit-user'"
        :data="adminData"
        :contractors="contractorsData"
        :action="'edit'"
        @close="showModal = ''"
        @refresh="refreshHandler"
        @form-info="setFormInfo"
      />
      <admin-logout-modal
        :show="showModal === 'admin-logout-modal'"
        :login="adminData.userLogin"
        @close="showModal = ''"
        @to-monitoring="logoutModalHandler('toMonitoring')"
        @logout="logoutModalHandler('logout')"
      />
      <user-info
        :show="showModal === 'user-info'"
        :login="adminData.userLogin"
        :sender-rights="adminData.senderRights"
        @close="showModal = ''"
      />
      <recalculate-popup v-if="showModal === 'recalculate'" @close="showModal = ''"/>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';

import Preloader from '../Components/Preloader.vue';
import SpinLoader from '../Components/SpinLoader.vue';
import AdminLogoutModal from './modals/AdminLogoutModal.vue';
import UserInfo from './modals/UserInfo.vue';
import UiAdminForm from './modals/UiAdminForm.vue';
import UiTable from './modals/UiTable.vue';
import RecalculatePopup from './modals/RecalculatePopup.vue';

import fetchJson from '../../src/dataRequest/fetch-json';


const props = defineProps({
  show: {
    type: Boolean,
    require: true,
  },
  login: {
    type: String,
    require: true,
  },
});

const emit = defineEmits(['close', 'toMonitoring', 'logout']);

const error = ref('');

const isAccordionClosed = ref({
  users: false,
  organizations: false,
});

const adminData = ref({});
const contractorsData = ref([]);
const showModal = ref('');
const isOrganizationObject = ref({
  partText: '',
  idPart: '',
  isOrganizationChecked: '',
});
const formInfo = ref('');

const userData = inject('userData');
const projectData = inject('projectData');

watch(
  () => props.show,
  async (show) => {
    if (show) {
      refreshHandler();
    }
  },
);

watch(adminData, (obj) => {
  if (obj.error) {
    error.value = obj.error;
    return;
  }

  if (Object.values(obj).length) {
    const isOrganization = obj.is_organization !== 'f';
    isOrganizationObject.value = {
      partText: isOrganization ? ' организации' : ' пользователя',
      idPart: isOrganization ? 'organization' : 'user',
      isOrganizationChecked: isOrganization ? 'checked' : '',
    };
  }

  const owners = () => {
    const organizations = adminData.value.organizationsData;
    const users = adminData.value.usersData;

    const owners = [];

    for (let key in organizations) {
      owners.push({
        value: organizations[key].line_id,
        login: organizations[key].login,
      });
    }

    for (let key in users) {
      if (users[key].role === 'user') continue;
      owners.push({
        value: users[key].line_id,
        login: users[key].login,
      });
    }

    return owners;
  };

  adminData.value.owners = owners();
});

const toggleAccordion = (name) => {
  isAccordionClosed.value[name] = !isAccordionClosed.value[name];
};

const showModalHandler = (str) => {
  showModal.value = str;
};

const logoutModalHandler = (str) => {
  if (str === 'toMonitoring') {
    emit(str);
    return;
  }
  fetch(
    `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }Api/auth/logout`,
    {
      credentials: 'include',
    },
  )
    .then((res) => res.json())
    .then((data) => {
      if (data['error']) {
        // error.value = data['error'];
        return;
      }
      if (data['redirectUrl'].includes('auth')) {
        emit('logout');
        error.value = '';

        showModalHandler('');
      }
    })
    .catch((rej) => {
      error.value =
        'Произошла ошибка. Попробуйте повторить запрос позже, либо перезагрузить страницу';
    })
    .finally(() => emit('logout'));
};

const editHandler = (lineObj) => {
  const owner = () => {
    let res = '';

    for (let owner of adminData.value.owners) {
      if (owner.login == lineObj.owner) {
        res = owner.value;
        break;
      }
    }

    return res;
  };

  adminData.value.post = {
    active: lineObj.active,
    description: lineObj.description,
    owner: owner(),
    id_contractor: lineObj.id_contractor,
    login: lineObj.login,
    name: lineObj.name,
    objects_mask: lineObj.objects_mask,
    psw: lineObj.psw,
    userRole: lineObj.role,
    templates: lineObj.templates,
    templates_names: lineObj.templates_names,
    validity_time: lineObj.validity_time,
    validity_time_from: lineObj.validity_time_from,

    rule_fuel_watch: lineObj.rule_fuel_watch,
    rule_geofences_create: lineObj.rule_geofences_create,
    rule_geofences_delete: lineObj.rule_geofences_delete,
    rule_geofences_update: lineObj.rule_geofences_update,
    rule_geofences_watch: lineObj.rule_geofences_watch,
    rule_organizations_create: lineObj.rule_organizations_create,

    rule_contract_create: lineObj.rule_contract_create,
    rule_contract_delete: lineObj.rule_contract_delete,
    rule_contract_edit: lineObj.rule_contract_edit,
    rule_contract_watch: lineObj.rule_contract_watch,
    rule_contractor_create: lineObj.rule_contractor_create,
    rule_contractor_delete: lineObj.rule_contractor_delete,
    rule_contractor_edit: lineObj.rule_contractor_edit,
    rule_contractor_watch: lineObj.rule_contractor_watch,
    rule_vehicle_create: lineObj.rule_vehicle_create,
    rule_vehicle_delete: lineObj.rule_vehicle_delete,
    rule_vehicle_edit: lineObj.rule_vehicle_edit,
    rule_vehicle_watch: lineObj.rule_vehicle_watch,
    rule_token1c_create: lineObj.rule_token1c_create,

    isOrganization: lineObj.isOrganization,
  };

  showModalHandler('edit-user');
};

const refreshHandler = async () => {
  adminData.value = {};
  showModal.value = '';
  formInfo.value = '';

  const res = await fetchJson(
    `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }Api/admin`,
  );
  try {
    const contractors = await fetchJson(
      `${
        process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
      }Api/manual/contractors`,
    );
    contractorsData.value = contractors;
  } catch (error) {
    contractorsData.value = [];
  }

  adminData.value = res;
};

const setFormInfo = (infoStr) => {
  formInfo.value = infoStr;
};
</script>

<style lang="scss" scoped>
.admin-modal-window {
  /* min-width: 400px; */
  width: 95vw;
  /* min-height: 450px; */
  height: 95vh;
  margin: 25px auto;
  display: none;
  background: #fff;
  padding: 16px;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media screen and (max-width: 767px) {
    font-size: small;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }

  .accordion__title {
    border-bottom: 1px dashed #000;
  }
  .accordion__body {
    height: 320px;
    overflow: auto;
    transition: all 1s;
    &.closed {
      height: 0;
    }
  }
  & .my-m-2 {
    margin-left: 0.5rem;
    @media (max-width: 418px) {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }
  & .admin-table-wrapper {
    max-height: calc(82vh - 25px);
    min-height: 400px;
    @media (max-width: 768px) {
      max-height: calc(80vh - 25px);
    }
    @media (max-width: 450px) {
      max-height: calc(70vh - 25px);
    }
  }
}
</style>
