import { mapLeafletMain } from '@/Leaflet/map_leaflet_main';
import fetchJson from '../../src/dataRequest/fetch-json';
import { violationsDescriptions } from '../../src/dataRequest/mainScript';
import { ViolationsDescriptions } from '../../src/dataRequest/violationsDescriptions';
import { violationsDescriptionListHtml } from '../Monitoring/Map/templates/violationsDescriptionListHtml';

const origin = process.env.VUE_APP_BACKEND_URL || window.location.origin + '/';
const url_get_positions = `${origin}Api/getPositionsOurPost`;
const url_get_positions_with_columns = `${origin}Api/getPositionsOurWithColumns`;
const url_get_positions_with_columns_array = `${origin}Api/getPositionsOurWithColumnsArray`;
const url_get_positions_other = `${origin}Api/getPositionsOtherPost`;

export function callOurPositions(
  obj,
  clickElem,
  trackInfoHtmlRef,
  timeInterval,
  callback,
) {
  // запрос позиций с нашего сервера

  const preparing_data = preparingDataFromGetPos(
    obj.id,
    trackInfoHtmlRef,
    timeInterval,
  );
  if (!preparing_data) return;

  // подготовка для асинохронного запроса
  getCallOurPosotionsPrepareDataSends(preparing_data);

  let timeCheck = {};
  timeCheck.begin = new Date();

  let resCount = 0;
  let piecesPositions = [];
  let piecesPositionsPy = [];

  trackInfoHtmlRef.value = 'Запрос отправлен...';

  getPicesPositions();

  function getPicesPositions() {
    // простой запрос позиций без python сервиса
    if (!preparing_data.sends.length && preparing_data_py.length) {
      // нужно восстановить preparing_data.sends
      let sendIndex = 0;

      for (let ii = 0; ii < preparing_data_py.length; ii++) {
        for (let jj = 0; jj < preparing_data_py[ii].sends.length; jj++) {
          preparing_data.sends[sendIndex] = preparing_data_py[ii].sends[jj];
          sendIndex++;
        }
      }
    }

    const promises = preparePromises(url_get_positions, preparing_data.sends);
    // асинхронный запрос
    Promise.all(promises)
      .then((responses) => {
        piecesPositions = responses;

        ajaxPositionsDoneAll_action(callback);
      })
      .catch((error) => {
        console.error(error);
        ajaxPositionsFailAll();
      });

    function ajaxPositionsFailAll() {
      //fail
      console.error('error');
      trackInfoHtmlRef.value = 'Ошибка запроса. Выполните новый запрос';
      clickElem.style.background = 'red';
    }
  }

  function ajaxPositionsDoneAll_action_py() {
    let index = 0;

    for (let i = 0; i < piecesPositionsPy.length; i++) {
      for (let j = 0; j < piecesPositionsPy[i].length; j++) {
        piecesPositions[index] = piecesPositionsPy[i][j];
        index++;
      }
    }

    ajaxPositionsDoneAll_action(true);
  }

  function ajaxPositionsDoneAll_action(callback) {
    let response = ajaxPositionsDoneAll(piecesPositions, trackInfoHtmlRef);

    if (!response) return;

    trackInfoHtmlRef.value = 'Все данные получены, обрабатываю результат... ';

    treatmentResponseOurAndSave(
      response,
      timeCheck,
      trackInfoHtmlRef,
      callback,
    );
  }

  function ajaxPositionsDoneAll(piecesPositions, trackInfoHtmlRef) {
    if (!piecesPositions.length) {
      trackInfoHtmlRef.value =
        'Ошибка: данные отсутствуют, попробуйте обновить страницу. ';
      return;
    }

    function ajaxPositionsDoneAllGetParam(result, jsonRes, paramName) {
      if (!result[paramName] && jsonRes[paramName]) {
        result[paramName] = String(jsonRes[paramName]).trim();
      }
    }

    let result = {
      objName: '',
      gearboxName: '',
      objId: '',
      getBegin: 0,
      getEnd: 0,
      memory_get_peak_usage_summ: 0,
      positions: [],
    };

    let positionsArr = [];
    for (let i = 0; i < piecesPositions.length; i++) {
      let piecePos = piecesPositions[i];

      let jsonRes;
      if (piecePos !== null && typeof piecePos === 'object') {
        jsonRes = piecePos;
      } else {
        jsonRes = JSON.parse(piecePos);
        piecesPositions[i] = ''; // free memory
      }

      if (!jsonRes) {
        console.error('Ошибка: получен НЕ JSON в номере ответа ' + i);
        continue;
      }

      if (
        !('objId' in jsonRes) ||
        // !('objName' in jsonRes) ||
        !('positions' in jsonRes)
      ) {
        console.error(
          'Ошибка: ответ получен, но он невалидный !!! в номере ответа ' + i,
        );
        continue;
      }

      if (!jsonRes['positions'].json_arr) {
        if (
          !(0 in jsonRes['positions']) ||
          !('json_agg' in jsonRes['positions'][0])
        ) {
          console.error(
            'Ошибка: ответ получен, но в нем нет информации о позициях !!! в номере ответа ' +
              i,
          );
          continue;
        }
      }

      let piecePosIsEmpty = true;
      for (let key in jsonRes) {
        piecePosIsEmpty = false;
        break;
      }

      if (piecePosIsEmpty) {
        console.error(
          'Ошибка: пустое тело при запросе позиций обычным методом в ответе номер ' +
            i,
        );
        continue;
      }

      if ('objConf' in jsonRes && jsonRes['objConf']) {
        result['objConf'] = jsonRes['objConf'];
      } else {
        result['objConf'] = obj;
      }

      if ('error' in jsonRes && jsonRes['error']) {
        result['error'] += ' / ' + jsonRes['error'];
      }

      if (
        jsonRes['getBegin'] &&
        (!result['getBegin'] || result['getBegin'] > jsonRes['getBegin'])
      ) {
        result['getBegin'] = jsonRes['getBegin'];
      }

      if (
        jsonRes['getEnd'] &&
        (!result['getEnd'] || result['getEnd'] < jsonRes['getEnd'])
      ) {
        result['getEnd'] = jsonRes['getEnd'];
      }

      const objRow = document.getElementById(jsonRes.objId);

      if (objRow) {
        const tdName = objRow.children[3].innerText;
        result['objName'] = tdName || '';
      }
      // ajaxPositionsDoneAllGetParam(result, jsonRes, 'objName');
      ajaxPositionsDoneAllGetParam(result, jsonRes, 'objId');
      ajaxPositionsDoneAllGetParam(result, jsonRes, 'gearboxName');

      if (jsonRes['memory_get_peak_usage']) {
        result['memory_get_peak_usage_summ'] +=
          jsonRes['memory_get_peak_usage'] / 1000000; // usage for php script in server, Mb
      }

      if (jsonRes['positions'].json_arr) {
        const positions = arraysToObjectsArr(
          JSON.parse(jsonRes['positions'].json_arr),
        );
        jsonRes['positions'].json_arr = ''; // free memory

        if (jsonRes['other'] && !jsonRes['other']['error']) {
          const positionsOther = convertJsonAgg(
            jsonRes['other']['positions'][0]['json_agg'],
          );
          jsonRes['other']['positions'][0]['json_agg'] = ''; // free memory
          concatOtherPositions({ positions, positionsOther });
        }

        positionsArr.push({
          positions,
          getBegin: jsonRes['getBegin'],
        });
      } else if (jsonRes['positions'][0]['json_agg']) {
        const positions = convertJsonAgg(jsonRes['positions'][0]['json_agg']);
        jsonRes['positions'][0]['json_agg'] = ''; // free memory

        if (jsonRes['other'] && !jsonRes['other']['error']) {
          const positionsOther = convertJsonAgg(
            jsonRes['other']['positions'][0]['json_agg'],
          );
          jsonRes['other']['positions'][0]['json_agg'] = ''; // free memory
          concatOtherPositions({ positions, positionsOther });
        }

        positionsArr.push({
          positions,
          getBegin: jsonRes['getBegin'],
        });
      }
    }

    concatResultPositions(result, positionsArr);

    return result;
  }

  function concatOtherPositions({ positions = [], positionsOther = [] } = {}) {
    if (!positionsOther || !positionsOther.length) {
      return;
    }

    positionsOther = positionsOther.reduce((accum, pos) => {
      accum[pos.time] = pos;
      delete pos.time;
      return accum;
    }, {});

    positions.forEach((poition, index) => {
      const { time } = poition;
      const posOther = positionsOther[time] || {};

      for (let key in posOther) {
        const value = posOther[key];
        if (!index || value !== null) {
          poition[key] = value;
        }
      }
    });
  }

  function arraysToObjectsArr(arrays) {
    const columns = arrays[0];
    const objectsArr = [];
    for (let i = 1; i < arrays.length; i++) {
      objectsArr[i - 1] = {};
      for (let j = 0; j < columns.length; j++) {
        objectsArr[i - 1][columns[j]] = arrays[i][j];
      }
    }
    return objectsArr;
  }

  function concatResultPositions(result, positionsArr) {
    for (const key in result['objConf']) {
      result['objConf'][key] = String(result['objConf'][key]).trim();
    }

    positionsArr.sort(function (a, b) {
      return a['getBegin'] - b['getBegin'];
    });

    positionsArr.forEach((picePoses) => {
      const { positions } = picePoses;
      if (positions.length) {
        [].push.apply(result['positions'], positions);
      }
    });
  }

  function preparePromises(url, preparingDataArr) {
    // url = url_get_positions_with_columns;
    url = url_get_positions_with_columns_array;

    const queryCount = preparingDataArr.length;
    const infoTextBegin = 'Загрузка: ';

    let isError = false;

    return preparingDataArr.map((preparingData) => {
      const { objects, timeBegin, interval } = preparingData;
      preparingData.isPython = false;

      const body = JSON.stringify({
        oid: +objects,
        timeBegin,
        interval,
        columns:
          'time,lat,lon,head,speed,violation_1,violation_2,clutch_time_uninterruptedly,pto_cnt_violation,spd_accel,dist',
      });

      const method = 'POST';

      const options = {
        method,
        body,
      };

      options.headers = {
        'Content-Type': 'application/json;charset=utf-8',
      };

      return new Promise((resolve, reject) => {
        const queries = [{ url, options }];

        const promises = queries.map((query) => {
          return fetchJson(query.url, query.options);
        });

        Promise.all(promises)
          .then((responses) => {
            const [response, other] = responses.map((res) => {
              if (res !== null && typeof res === 'object') {
                return res;
              } else {
                try {
                  const json_res = JSON.parse(res);
                  return json_res;
                } catch (e) {
                  console.error('not JSON', res);
                }

                return '';
              }
            });

            responses = '';

            response.other = other;

            const resCountHere = ++resCount;

            if (!isError) {
              trackInfoHtmlRef.value =
                infoTextBegin +
                resCountHere +
                ' из ' +
                queryCount +
                ' (' +
                roundNumber_helper((100 * resCountHere) / queryCount, 1) +
                ' %)';
            }

            resolve(response);
          })
          .catch((error) => {
            isError = true;
            reject(error);
          });
      });
    });
  }

  function fetchCall(url, options) {
    return;
  }
}

export function convertJsonAgg(json_agg) {
  if (json_agg !== null && typeof json_agg === 'object') {
    return json_agg;
  } else {
    return JSON.parse(json_agg);
  }
}

function preparingDataFromGetPos(objId, trackInfoHtmlRef, timeInterval) {
  let preparing_data = {};
  preparing_data.objects = objId;

  if (!timeInterval.timeBegin) {
    trackInfoHtmlRef.value = 'Неверно указаны дата или время начала запроса.';
    return false;
  }
  if (!timeInterval.timeEnd) {
    trackInfoHtmlRef.value =
      'Неверно указаны дата или время окончания запроса.';
    return false;
  }
  preparing_data.timeBegin = timeInterval.timeBegin / 1000;
  preparing_data.timeEnd = timeInterval.timeEnd / 1000;

  // alert(preparing_data.timeBegin + ' ' + timeEnd + ' ' + Number(timeEnd - preparing_data.timeBegin));
  if (preparing_data.timeBegin >= preparing_data.timeEnd) {
    trackInfoHtmlRef.value =
      'неверно выбран интервал запроса, исправьте ошибку.';
    return false;
  }

  preparing_data.timeOffset = -new Date().getTimezoneOffset() / 60;

  preparing_data.interval = preparing_data.timeEnd - preparing_data.timeBegin; //86400 переводит секунды в часы

  preparing_data.allColumns = '';

  return preparing_data;
}

function getCallOurPosotionsPrepareDataSends(preparing_data) {
  // const step = 43200000; // 86400 - сутки, 43200000 = 12 часов (в милисекундах)
  const step = 28800000; // 28800 - 8 часов (в миллисекундах)
  preparing_data.sends = [];
  let allColumns = '';

  const { timeEnd, timeBegin } = preparing_data;
  const getTimeBegin = timeBegin * 1000;
  const getTimeEnd = timeEnd * 1000;
  const getTimeInterval = getTimeEnd - getTimeBegin;

  for (let i = 0; i < getTimeInterval; i += step) {
    let resBegin = getTimeBegin + i;
    if (i) resBegin++;

    let resEnd = getTimeBegin + i + step;

    if (resEnd > getTimeEnd) {
      resEnd = getTimeEnd;
    }

    let resInterval = resEnd - resBegin;

    if (resInterval <= 0) {
      continue;
    }

    let getConfig = !i || '';

    preparing_data.sends.push({
      interval: resInterval,
      objects: preparing_data.objects,
      timeBegin: resBegin,
      timeOffset: preparing_data.timeOffset,
      allColumns: allColumns,
      getConfig: getConfig,
    });
  }
}

function treatmentResponseOurAndSave(
  response,
  timeCheck,
  trackInfoHtmlRef,
  callback,
) {
  // сохранение позиций и вставка трека на карту
  if (
    !('objId' in response) ||
    !('objName' in response) ||
    !('positions' in response)
  ) {
    trackInfoHtmlRef.value = 'Ошибка: ответ получен, но он невалидный !!!';
    return;
  }

  trackInfoHtmlRef.value = 'Данные получены, обрабатываю ...';
  let positions = response['positions'];

  let infoForShow = '';
  infoForShow =
    'По объекту ' + response['objName'] + ' получено ' + positions.length;
  infoForShow += ' позиций с сервера за период с ';
  let trackBegin = formatDateHelper(
    new Date(response['getBegin'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  let trackEnd = formatDateHelper(
    new Date(response['getEnd'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  infoForShow += trackBegin + ' по ' + trackEnd;
  let objName = response['objName'];
  let gearboxName = response['gearboxName'];
  let objId = response['objId'];

  let points = getPointsFromOur(positions, objName, gearboxName);
  let trackInfo =
    'Трек по объекту ' + objName + '<br>с ' + trackBegin + ' по ' + trackEnd;

  var queryEnd = new Date();
  const queryInterval = (queryEnd - timeCheck.begin) / 1000;

  infoForShow += ` (запрос выполнен за ${roundNumber_helper(
    queryInterval,
    1,
  )} секунд)`;

  trackInfoHtmlRef.value = infoForShow;

  const globalObjects = {
    clients: [],
    globalPositions: {},
    positionsFromTemplateDetail: {},
    violationsSettingsList: {},
  };
  // violations list setting - меню нарушений на карте заполнение
  if (!(objId in globalObjects.violationsSettingsList)) {
    // по умолчанию отключаем отображение нарушений на карте
    globalObjects.violationsSettingsList[objId] = {};
  }

  mapLeafletMain.leafletAddPolyline(points, trackInfo, objId, globalObjects);
  mapLeafletMain.addViolationsOnMap(
    points,
    objId,
    objName,
    gearboxName,
    globalObjects.violationsSettingsList[objId],
    false,
  );

  callback({
    points,
    trackInfo,
    infoForShow,
    getBegin: response['getBegin'],
    getEnd: response['getEnd'],
    trackBegin,
    trackEnd,
    objName,
  });
}

export function getPointsFromOur(positions, objName, gearboxName) {
  const points = {
    objName,
    gearboxName,
    latlngs: [],
    params: [], // values: [], пишется в params
    allValues: positions, // для графиков
    violations_stat: {
      // статистика по количеству нарушений
      summ: 0, // сумма всех нарушений
    },
    violations_text: [],
    violations_values: [],
  };

  let column = 0;
  let violation_id = [];
  let violation_text = [];
  let violation_values = [];

  let posIndex = 0;
  let maxSpeed = 0;

  let distSumm = 0;
  let distPrev = -1;
  let distBegin = -1;
  let timeWithDist = 0;
  let curSpeed = 0;

  for (let k = 0; k < positions.length; k++) {
    const {
      time,
      lat = 0,
      lon = 0,
      head,
      speed,
      violation_1,
      violation_2,
      clutch_time_uninterruptedly,
      pto_cnt_violation,
      spd_accel,
      gps = 0,
      dist,
      is_last_day_pos,
    } = positions[k];

    if (speed < 2500) {
      // 250 км/ч
      curSpeed = speed;
    }

    const canDistProtocolLimit = 21055406000; // в метрах
    const isDist = Boolean(dist && dist < canDistProtocolLimit);

    if (isDist && distBegin === -1) {
      distBegin = dist;
      distPrev = dist;
    }

    if (isDist) {
      let dSummTemp = dist > distPrev ? dist - distBegin : distPrev - distBegin;
      if (timeWithDist && dSummTemp && dSummTemp > distSumm) {
        const timeDiff = time - timeWithDist;
        if (!timeDiff) {
          timeDiff = 1000;
        }
        const avgSpdTemp = (dSummTemp - distSumm) / 1000 / (timeDiff / 3600000);
        if (avgSpdTemp > 250) {
          dSummTemp = distSumm;
        }
      }

      timeWithDist = time;

      distSumm = dSummTemp;
      distPrev = dist;
    }

    positions[k].distSumm = distSumm;

    if (!(lat > 0 && lon > 0)) {
      continue;
    }

    column = 0;
    violation_id = [];
    violation_text = [];
    violation_values = '';

    while ('violation_' + Number(++column) in positions[k]) {
      const col_name = 'violation_' + column;

      if (!(positions[k][col_name] > 0)) {
        continue;
      }

      for (let i = 0; i < 31; i++) {
        if (positions[k][col_name] & (1 << i)) {
          let viol_id = i + 1 + 31 * (column - 1);
          violation_id.push(viol_id);
          let violation_desc = getVolationDesc_helper(
            viol_id,
            violationsDescriptions.desc,
          );
          violation_text.push(violation_desc['description']);

          if (viol_id in points['violations_stat']) {
            points['violations_stat'][viol_id]++;
          } else {
            points['violations_stat'][viol_id] = 1;
          }

          points['violations_stat']['summ']++;
        }
      }

      violation_values = {
        clutch_time_unine: clutch_time_uninterruptedly,
        pto_cnt_violation: pto_cnt_violation,
        spd_accel: spd_accel,
        spd: curSpeed,
      };
    }

    if (curSpeed > maxSpeed) {
      maxSpeed = curSpeed;
    }

    points['latlngs'][posIndex] = [
      lat / 1000000000000000,
      lon / 1000000000000000,
    ];

    points['params'][posIndex] = {
      viewTime: time / 1000,
      speed: curSpeed,
      violation_1: violation_1,
      violation_2: violation_2,
      violation_id: violation_id.join(';'),
      violation_text: violation_text.join(';<br>'),
      violation_values: violation_values,
      head: head,
      gps,
      maxSpeed,
      violationsSumm: points['violations_stat']['summ'],
    };

    // points['values'][posIndex] = {time, gps, maxSpeed};

    posIndex++;
  }

  // for(var i = 0; i < points['params'].length; i++){
  //   if(points['params'][i]['violation_id'].length > 0){
  //
  //   }
  // }

  return points;
}

function removeSelectOption(selectList, childSelector) {
  // remove option
  let childOption = selectList.querySelector(childSelector);
  if (childOption) {
    selectList.removeChild(childOption);
  }
  // add default option
  if (!selectList.options.length) {
    document.getElementById('violations-setting').innerHTML =
      'Меню нарушений на карте (нет запросов).';
    selectList.innerHTML += '<option value="none">Нет запросов</option>';
  } else {
    // проверить какой объект выводится - и по нему вывести настройку отображения
  }
}

function violationsSettingListInnerHtml(
  violationsDescriptionsDesc,
  violationsSettingsObject,
  violationsStat,
) {
  // add into html
  let violationsDescriptionList = getViolationsDescriptionList(
    violationsDescriptionsDesc,
    violationsSettingsObject,
    violationsStat,
  ); // html text
  document.getElementById('violations-setting').innerHTML =
    violationsDescriptionList;
}

function getViolationsDescriptionList(
  violationsDescriptionsDesc,
  violationsSetting,
  violationsStat,
) {
  // let violationsDescriptionListTemplate = document.getElementById(
  //   'violations-description-list-template',
  // ).innerHTML;
  // let compiled = _.template(violationsDescriptionListTemplate);
  let compiled = _.template(violationsDescriptionListHtml);
  let html = compiled({
    data: violationsDescriptionsDesc,
    violationsSetting: violationsSetting,
    violationsStat: violationsStat,
  });
  return html;
  // document.getElementById('section-reports').innerHTML = html;
}
