<template>
  <ui-popup
    style-for-body="width: 90vw; max-height: 95vh"
    @close="() => emit('close')"
  >
    <ui-close-button @close="() => emit('close')" />
    <div
      v-if="props.title"
      class="text-center mb-3 mt-2"
    >
      <h3>{{ props.title }}</h3>
    </div>
    <ui-vehicles
      not-display-controls
      :data="vehicles"
      :shown-columns="Object.keys(useTable.headers.vehicles)"
    />
  </ui-popup>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import UiVehicles from '@/ManualApp/Pages/Contractors/Contractor/Components/UiVehicles.vue';
import UiPopup from './UiPopup.vue';
import UiCloseButton from '../Buttons/UiCloseButton.vue';

import { useTable } from '@/ManualApp/use/table';

import { defferExecution, STORE } from '../../STORAGE/STORAGE';

const props = defineProps({
  title: { type: String, default: '' },
  ids: { type: Array, default: [] },
});

const emit = defineEmits(['close']);

const vehicles = computed(() => {
  const vehiclesObj = {};

  props.ids.forEach((id) => {
    vehiclesObj[id] = STORE.vehicles[id];
  });

  return vehiclesObj;
});

function setVehicles() {
  for (let id of props.ids) {
    vehicles.value[id] = STORE.vehicles[id];
  }
}

onMounted(async () => {
  const condition = () => !Object.keys(STORE.vehicles).length;
  defferExecution(condition, setVehicles);
});
</script>

<style lang="scss" scoped></style>
