import { TrackChartsMotion } from './trackChartsMotion';

export class TrackChartsPanel {
  leafletMain;
  globalObjects;
  wrapper;
  element;
  vue;
  opened;
  positionsList;
  items = {};

  constructor({
    buttonId,
    positionsListId,
    DragElement_helper,
    leafletMain,
    globalObjects,
    notOtherQueries = false,
    defaultChartNames = [],
    defaultDisplayedCharts = [],
  } = {}) {
    this.defaultChartNames = defaultChartNames;
    this.defaultDisplayedCharts = defaultDisplayedCharts;

    const wrapper = this.createPanel();
    document.body.append(wrapper);
    this.notOtherQueries = notOtherQueries;

    const subElements = this.getSubElements(wrapper);
    const {
      trackChartsContainer: element,
      trackChartsClose: closeElem,
      trackChartsSetting: settingElem,
      trackChartsSettingOpacity,
      trackChartsSettingClose,
      trackChartsSettingShowValues,
    } = subElements;

    this.dragElement_helper = new DragElement_helper(wrapper, 'flex');
    closeElem.addEventListener('pointerdown', this.closeEvent);
    settingElem.addEventListener('pointerdown', this.settingEvent);
    trackChartsSettingClose.addEventListener('pointerdown', this.settingEvent);
    trackChartsSettingOpacity.addEventListener(
      'change',
      this.changeOpasityEvent,
    );
    trackChartsSettingShowValues.addEventListener(
      'pointerdown',
      this.changeShowValuesEvent,
    );

    const buttonOpen = document.getElementById(buttonId);
    buttonOpen.addEventListener('pointerdown', this.openEvent);
    this.buttonOpen = buttonOpen;

    this.element = element;
    this.closeElem = closeElem;
    this.wrapper = wrapper;

    this.leafletMain = leafletMain;
    this.globalObjects = globalObjects;

    this.positionsList = document.getElementById(positionsListId);

    this.subElements = subElements;
  }

  createPanel() {
    const wrap = document.createElement('div');
    wrap.innerHTML = this.panelTemplate();
    return wrap.firstElementChild;
  }

  closeEvent = () => {
    this.wrapper.style.display = 'none';

    this.removeMarkersFromTheMap();
  };

  settingEvent = () => {
    this.subElements.trackChartsSettingContainer.classList.toggle('active');
  };

  changeOpasityEvent = (event) => {
    const value = parseInt(event.target.value);
    const opacity = 100 - value;
    this.wrapper.style.opacity = `${opacity}%`;
    this.subElements.trackChartsSettingOpacityValue.innerText = `${value} %`;
  };

  changeShowValuesEvent = (event) => {
    if (event.target.checked) {
      this.subElements.trackChartsContainer.classList.remove('without-values');
    } else {
      this.subElements.trackChartsContainer.classList.add('without-values');
    }
  };

  openEvent = () => {
    const wrapper = this.wrapper;

    const n = this.positionsList.options.selectedIndex;
    const id = this.positionsList.options[n].value;

    if (this.items[id]) {
      const { getBegin, getEnd } = this.globalObjects.globalPositions[id] || {};
      if (this.items[id].from !== getBegin || this.items[id].to !== getEnd) {
        this.removeItem(id);
      }
    }

    const item = this.wrapper.querySelector(`[data-id="${id}"]`);

    if (!this.opened || item) {
      this.opened = true;

      const zoom = document.querySelector('.leaflet-control-zoom-out');
      const toggle = document.querySelector('.leaflet-control-layers-toggle');

      const { x: zoomX, width: zoomW } = zoom.getBoundingClientRect();
      const {
        x: tgX,
        y: tgY,
        height: tgH,
        width: tgW,
      } = toggle.getBoundingClientRect();

      const clientWidth = document.documentElement.clientWidth;

      const wrapperLeft = zoomX + zoomW + 5;
      const wrapperWidth =
        clientWidth > 600 ? tgX - 5 - wrapperLeft : tgX + tgW - wrapperLeft;

      const wrapperWidthPercent =
        Math.floor((1000 * wrapperWidth) / clientWidth) / 10;

      wrapper.style.left = `${wrapperLeft}px`;
      wrapper.style.top = `${tgY}px`;
      wrapper.style.width = `${wrapperWidthPercent}vw`;
    }

    if (id !== 'none' && !item) {
      const {
        objName = 'н/у',
        stateNumber = 'н/у',
        points,
        trackBegin = '?',
        trackEnd = '?',
        getBegin,
        getEnd,
        otherQueries,
      } = this.globalObjects.globalPositions[id] || {};

      const wrap = document.createElement('div');
      const chartAreaId = `trackChartsPanel-${id}-${Date.now()}`;
      wrap.innerHTML = this.chartsTemplate({
        id,
        chartAreaId,
        objName,
        stateNumber,
        getBegin,
        getEnd,
      });
      const newItem = wrap.firstElementChild;
      const chartSubelements = this.getSubElements(newItem);

      const chartMotion = new TrackChartsMotion({
        mainWrapper: wrapper,
        trackChartsSettingIframe: this.subElements.trackChartsSettingIframe,
        panelSubelements: this.subElements,
        chartSubelements,
        panel: newItem,
        points,
        leafletMain: this.leafletMain,
        objName,
        chartAreaId,
        stateNumber,
        getBegin,
        getEnd,
        otherQueries,
        chartNames: this.defaultChartNames,
        displayedCharts: this.defaultDisplayedCharts,
      });
      const removeButton = newItem.querySelector('.track-charts-remove-button');

      this.items[id] = {
        chartMotion,
        removeButton,
        from: getBegin,
        to: getEnd,
      };

      const requestPopupButton = newItem.querySelector(
        '.track-charts-request-popup-button',
      );

      this.element.append(newItem);
      removeButton.addEventListener('pointerdown', this.closeItemEvent);
      requestPopupButton.addEventListener(
        'pointerdown',
        this.displayRequestPopup,
      );
    }

    if (item) {
      item.style.display = 'block';
    }

    wrapper.style.display = 'flex';

    this.addMarkersToTheMap();
  };

  addMarkersToTheMap() {
    for (let id in this.items) {
      if (this.items[id].chartMotion && this.items[id].chartMotion.marker) {
        this.items[id].chartMotion.marker.addTo(this.leafletMain.map);
      }
    }
  }

  removeMarkersFromTheMap() {
    for (let id in this.items) {
      if (this.items[id].chartMotion && this.items[id].chartMotion.marker) {
        this.items[id].chartMotion.marker.remove();
      }
    }
  }

  displayRequestPopup(e) {
    // Переопределяется из vue
  }

  addCharts(objId, { chartNames, levels, columns, fuelSettings, positions }) {
    this.items[objId].chartMotion.addCharts({
      chartNames,
      levels,
      columns,
      fuelSettings,
      positions,
    });
  }

  closeItemEvent = (event) => {
    const item = event.target.closest('.track-charts-item');
    this.wrapper.style.width = `${item.clientWidth}px`;
    const id = item.dataset.id;
    const { chartMotion } = this.items[id];
    if (chartMotion.polyline) {
      chartMotion.leafletMain.dropPolline(chartMotion.polyline);
    }
    if (chartMotion.marker) {
      chartMotion.leafletMain.dropMarker(chartMotion.marker);
    }
    item.style.display = 'none';
  };

  removeItem(id) {
    const { chartMotion, removeButton } = this.items[id];
    removeButton.removeEventListener('pointerdown', this.closeItemEvent);
    chartMotion.destroy();
    const item = this.wrapper.querySelector(`[data-id="${id}"]`);
    if (item) {
      item.remove();
    }
    this.items[id] = null;
    delete this.items[id];
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  chartsTemplate({ id, chartAreaId, objName, stateNumber, getBegin, getEnd }) {
    const from = formatDateHelper(
      new Date(getBegin * 1000),
      'dd.mm.yyyy hh:nn:ss',
    );
    const to = formatDateHelper(new Date(getEnd * 1000), 'dd.mm.yyyy hh:nn:ss');
    return `
        <div class="track-charts-item" data-id="${id}" style="position:relative">
            <div class="track-charts-header" data-element="trackChartHeader">
                <div class="track-charts-header-name-container">
                    <b>${objName} | ${stateNumber} | ${from} - ${to} |</b>
                    <span>
                        <label>
                            <input style="cursor:pointer" data-element="trackChartSettingHeight" title="высота графика" type="range" min="2" max="99" step="1" value="0">
                            <span data-element="trackChartSettingHeightValue">0 %</span>
                        </label>
                    </span>
                </div>
                <div class="track-charts-header-remove-button-container d-flex align-items-start">
                    <img class="track-charts-request-popup-button" data-id="${id}" data-from="${getBegin}" data-to="${getEnd}" style="width: 25px;height: 25px;margin-top: 2px" src="/images/track_charts/query_stats.svg" alt="Запросить графики"/>
                    <span class="track-charts-remove-button track-charts-button"><img height="22px" src="/images/track_player/remove-button.png" alt="удалить"></span>
                </div>
                </div>
            <div data-element="trackChartValues" class="track-charts-values" hidden>
                <div class="track-charts-values-item" title="Дата и время"><img src="/images/track_player/valuesTime.png" alt="Time"><span data-element="trackChartsValueTime">-</span></div>
                <div class="track-charts-values-item" title="Скорость"><img src="/images/track_player/valuesSpeed.png" alt="Speed"><span data-element="trackChartsValueSpeed">-</span></div>
                <div class="track-charts-values-item" title="Максимальная скорость от начала запроса"><img src="/images/track_player/valuesMaximumSpeed.png" alt="MaxSpeed"><span data-element="trackChartsValueMaxSpeed">-</span></div>
                <div class="track-charts-values-item" title="Сигнал спутников GPS/ГЛОНАСС"><img src="/images/track_player/valuesSatellite.png" alt="GPS"><span data-element="trackChartsValueGps">-</span></div>
                <div class="track-charts-values-item" title="Широта (latitude)"><img src="/images/track_player/valuesLat.png" alt="Lat"><span data-element="trackChartsValueLat">-</span></div>
                <div class="track-charts-values-item last-child" title="Долгота (longtitude)"><img src="/images/track_player/valuesLong.png" alt="Lon"><span data-element="trackChartsValueLon">-</span></div>
            </div>
            <div data-element="trackChartWrapper" class="track-chart-wrapper">
                <div data-element="trackChartWrapperInner" class="track-chart-wrapper-inner">
                    <div id="${chartAreaId}" class="track-charts-panel" draggable="false" ondragstart="return false;" ondrop="return false;" oncontextmenu="return false;">
                    </div>
                </div>
            </div>         
        </div>
        `;
  }

  panelTemplate() {
    return `
        <div class="block-draggable display-none track-charts-wrapper" id="track-charts-wrapper-${Date.now()}" style="background-color: #ffffff; flex-direction: column;">
            <iframe data-element="trackChartsSettingIframe" width=100% height=100% style="position:absolute;z-index:-1;opacity:0;"></iframe>
            <div data-element="trackChartsSettingHeader" class="block-draggable-header">Графики
                <img src="/images/track_player/setting.svg" data-element="trackChartsSetting" id="track-charts-setting" alt="settings" width="24px" height="24px">
                <img src="/images/cancel.svg" data-element="trackChartsClose" id="track-charts-close" alt="close" width="26px" height="26px">
            </div>
            <div data-element="trackChartsSettingContainer" class="track-charts-setting-container">
                <label for="track-charts-setting-opacity">Прозрачность</label>
                <input style="cursor:pointer" data-element="trackChartsSettingOpacity" type="range" min="0" max="80" step="1" value="0" id="track-charts-setting-opacity">
                <span data-element="trackChartsSettingOpacityValue">0 %</span>
                <span><label><input data-element="trackChartsSettingShowValues" type="checkbox" checked> отображать значения в позиции</label></span>
                <span data-element="trackChartsSettingClose"><img height="22px" src="/images/track_player/remove-button.png" alt="закрыть"></span>
            </div>
            <div data-element="trackChartsContainer" id="track-charts-container"></div>
        </div>
        `;
  }

  destroy() {
    this.wrapper.remove();
    this.leafletMain = null;
    this.globalObjects = null;
    this.wrapper = null;
    this.element = null;
    this.vue = null;
    this.opened = null;
    this.positionsList = null;
    this.items = {};
  }
}
