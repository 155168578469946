<template>
  <div class="module">
    <div class="module-text">
      <div class="title"><slot name="title"></slot></div>
      <div class="body"><slot name="body"></slot></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiModule',
};
</script>

<style lang="scss">
.module {
  display: flex;
  flex-direction: column;
  color: #000;
}
.module-text {
  margin-top: 12.5%;
  margin-left: 11%;
  width: 78%;
  height: 70%;

  line-height: 18px;

  @media (max-width: 1360px) {
    margin-top: 10%;
  }
  @media (max-width: 1024px) {
    margin-top: 11.5%;
  }

  & .title {
    // margin-right: -10%;
    text-align: center;

    @media (max-width: 1525px) {
      font-size: 0.8rem;
    }
    @media (max-width: 1180px) {
      font-size: 0.75rem;
      line-height: 1rem;
    }
    @media (max-width: 1024px) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
    @media (max-width: 940px) {
      font-size: 1.5rem;
    }
    @media (max-width: 850px) {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
    @media (max-width: 745px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    @media (max-width: 640px) {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
    @media (max-width: 560px) {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    @media (max-width: 500px) {
      font-size: 0.7rem;
      line-height: 0.8rem;
    }
    @media (max-width: 450px) {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
    @media (max-width: 405px) {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
    @media (max-width: 370px) {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
    @media (max-width: 340px) {
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }
  & .body {
    margin-top: 10px;
    text-align: center;
    white-space: nowrap;
    font-size: 1.8rem;
    line-height: 2rem;

    @media (max-width: 1525px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1300px) {
      font-size: 1.2rem;
      margin-top: 0;
    }
    @media (max-width: 1024px) {
      margin-top: 10px;
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @media (max-width: 640px) {
      margin-top: 5px;
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media (max-width: 450px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @media (max-width: 400px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
</style>
