import { reactive, watch } from 'vue';

import { useField } from './field';
import { useTable } from './table';
import { useTariffs } from './tariffs';

import type { TObject } from '../Types/Object';
import type { ITableData } from '../Types/ITableData';

import { STORE } from '../STORAGE/STORAGE';

interface IField {
  value: string;
  validators: TObject<boolean>;
}

interface IFilterSelected {
  value: string;
}

interface IInit {
  field: IField;
  filterSelected: IFilterSelected;
  data: ITableData;
  type: 'not-strict' | 'strict' | 'regexp';
}

export function searchHandler(str: IField, search: IInit) {
  const isShow = (val: string, type = 'not-strict') => {
    if (type == 'not-strict') {
      return val.toLowerCase().indexOf(str.value.toLowerCase()) > -1;
    }
    if (type == 'strict') {
      return val.startsWith(str.value);
    }
    if (type == 'regexp') {
      const re = new RegExp(str.value, 'gi');
      return re.test(val);
    }
  };

  const setIsShow = (obj: TObject<any>, value: any) => {
    if (value == undefined) return;

    if (value.text === undefined) {
      obj.isShow = isShow(value, search.type);
    } else {
      obj.isShow = isShow(value.text, search.type);
    }
  };

  const checkVehiclesVins = (obj: TObject<any>) => {
    if (obj.vehicles === undefined || obj.vehicles.length < 1) {
      return;
    }

    for (let id of obj.vehicles) {
      const vehicle = STORE.vehicles[id];

      if (vehicle && isShow(vehicle.vin, search.type)) {
        return true;
      }
    }

    return false;
  };

  const getStatus = (obj: { status?: string; id?: string; type?: string }) => {
    const docType = obj.type
      ? obj.type
      : obj.status
      ? 'additional'
      : 'contract';

    if (obj.id === undefined) return '';

    if (docType === 'individual') {
      return useTable.STATUS_TYPES['active'].text || '';
    } else if (docType === 'contract') {
      const contract = STORE.contracts[obj.id];

      if (!contract) return '';

      const currentTariff = useTariffs.getCurrentContractTariffs(contract);

      const type = useTable.getStatusTypeByContract(
        contract,
        currentTariff.current,
      );

      return useTable.STATUS_TYPES[type].text || '';
    } else if (docType === 'additional') {
      const additional = STORE.additionals[obj.id];

      if (!additional) return '';

      const currentTariff = {
        current: additional.tarif,
        all: [additional.tarif],
      };

      const type = useTable.getStatusTypeByContract(
        additional,
        currentTariff.current,
      );

      return useTable.STATUS_TYPES[type].text || '';
    } else {
      return '';
    }
  };

  const getDocumentStatus = (obj: any) => {
    const currentTariffs = useTariffs.currentVehicleTariff(obj.id_c, obj.id)
      .current as { type: string; id_a?: string; id_d: string };

    const options = {
      type: currentTariffs.type,
      id: currentTariffs.id_a || currentTariffs.id_d,
    };

    return getStatus(options);
  };

  const filterOn = search.filterSelected.value;

  search.data.body.forEach((obj) => {
    let value;

    // Колонка "Статус" транспортных средств
    if (filterOn === 'doc_status') {
      value = getDocumentStatus(obj);
      setIsShow(obj, value);
      return;
    }

    // Колонки "Статус" договоров и доп.соглашений
    if (filterOn === 'status') {
      value = getStatus(obj);
      setIsShow(obj, value);
      return;
    }

    // Колонки "Действует с" "Действует по" "Оплачено до"
    if (['t_from', 't_to', 'paid'].includes(filterOn)) {
      value = useTable.getFormatDate(obj[filterOn]);
      setIsShow(obj, value);
      return;
    }

    // Колонка "Контрагент"
    if (filterOn === 'id_c') {
      value = useTable.getContractorName(obj.id_c);
      setIsShow(obj, value);
      return;
    }

    // Фильтр по VIN у договоров и доп.соглашений
    if (filterOn === 'vehiclesCount') {
      const isInclude = checkVehiclesVins(obj);
      obj.isShow = isInclude;
      return;
    }

    // Стандартная фильтрация
    value = obj[filterOn];
    setIsShow(obj, value);
  });
}

export const useSearch = (
  init: IInit = {
    field: { value: '', validators: {} },
    filterSelected: { value: '' },
    data: { header: {}, body: [] },
    type: 'not-strict',
  },
) => {
  const search = reactive({
    field: useField(init.field),
    filterSelected: init.filterSelected,
    data: init.data,
    type: init.type,
    submit: (e: Event) => {
      const { search_field } = e.target as HTMLFormElement;
      searchHandler(search_field, search);
    },
  });

  watch(search.field, (field) => searchHandler(field, search), {
    deep: true,
  });

  return search;
};
