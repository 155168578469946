import { ref } from 'vue';

export const useScroll = (rowHeight: number, visibleRows: number) => {
  const UPDATE_TIME = 0; // 1000 / 60;
  const startIndex = ref(0);

  let scrollTimeout = 0;
  function onScroll(e: Event) {
    clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(() => {
      const { scrollTop } = e.target as HTMLBodyElement;
      startIndex.value = Math.floor(scrollTop / rowHeight);
    }, UPDATE_TIME);
  }

  return { onScroll, startIndex };
};
