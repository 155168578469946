import { useTariffs } from './tariffs';

import { trueInDbHelper } from '@/helpers/trueInDbHelper';

import { STORE } from '../STORAGE/STORAGE';

export const useTable = {
  getFormatDate: (unix) => {
    if (unix == '0') return 'Не определено';
    return formatDateHelper(new Date(unix * 1000), 'dd.mm.yyyy');
  },

  getCreator: (obj) => {
    if (obj.creator_login) {
      return obj.creator_login;
    }

    if (obj.creator_u) {
      return `${obj.creator_u}:нет_логина`;
    }

    return 'Не получено';
  },

  getContractorName: (contractor_id) => {
    if (contractor_id == 0) {
      return 'Нет контрагента';
    } else if (STORE.contractors[contractor_id]) {
      return STORE.contractors[contractor_id].name;
    } else {
      return `${contractor_id}:не_найден`;
    }
  },

  getContractNumber: (id_d) => {
    if (!id_d) return;

    const contract = STORE.contracts[id_d];

    if (contract) return contract.number;

    return 'Не найден';
  },

  getVehiclesVins: (vehicleIds) => {
    if (!vehicleIds) return '';
    if (typeof vehicleIds === 'string') vehicleIds = JSON.parse(vehicleIds);

    const vins = vehicleIds.map((id) => {
      if (STORE.vehicles[id]) {
        return STORE.vehicles[id].vin;
      } else {
        return `${id}: не найден`;
      }
    });

    return vins.join(', ') || '-';
  },

  getVehiclesCount: (vehicleIds) => {
    if (!vehicleIds) return;
    if (typeof vehicleIds === 'string') vehicleIds = JSON.parse(vehicleIds);

    return vehicleIds.length;
  },

  getTariff: (id_t) => {
    if (!STORE.defaultTariffs[id_t]) return;

    const tariff = STORE.defaultTariffs[id_t];

    return tariff;
  },

  getTariffName: (id_t) => {
    const tariff = STORE.defaultTariffs[id_t];

    if (!tariff) return;

    return tariff.name;
  },

  getCurrentTariffs(contract) {
    const contractTariffs = contract.tarifsContractor;

    if (!contractTariffs)
      return { current: 'Идет загрузка данных...', all: [] };

    contractTariffs.map((tariff) => {
      const tariffData = useTable.getTariff(tariff.id_t);

      if (!tariffData) return;

      tariff['name'] = tariffData['name'];
      tariff['price'] = tariffData['price'];
    });

    const currentSortedTariffs = useTariffs.sortByTimeFrom(contractTariffs);

    return {
      current: currentSortedTariffs[0],
      all: currentSortedTariffs,
    };
  },

  getCurrentContractTariffName: (contract) => {
    const currentTariffs = useTariffs.getCurrentContractTariffs(contract);
    if (currentTariffs.current.name) {
      return currentTariffs.current.name;
    } else {
      return 'Нет тарифа';
    }
  },

  getAdditionalTariffNames: (id_d) => {
    let res = '';

    for (let id_a in STORE.additionals) {
      if (STORE.additionals[id_a].id_d == id_d) {
        const { id_t, t_to, vehicles } = STORE.additionals[id_a];

        const tariff = STORE.defaultTariffs[id_t];

        if (!tariff) continue;

        res += `${tariff.name || tariff.id} - ${
          vehicles.length
        } (${useTable.getFormatDate(t_to)}) <br>`;
      }
    }

    return res;
  },

  getStatusTypeByContract: (contract, currentTariff) => {
    const DEADLINE = Date.now();
    const DEADLINE_OFFSET = 1000 * 60 * 60 * 24 * 30;

    if (!currentTariff || !Object.keys(currentTariff).length) return 'noTariff';

    if (contract && contract.t_to != 0) {
      // завершен по сроку (более 30 дней как истек срок действия)
      if (
        contract.t_to * 1000 < DEADLINE &&
        contract.t_to * 1000 < DEADLINE - DEADLINE_OFFSET
      ) {
        return 'complitedOnTimeTo';
      }

      // истек срок (от 0 до 30 дней как срок договора или допсоглашения истек, смотрим по последнему по сроку окончания действия)
      if (
        contract.t_to * 1000 < DEADLINE &&
        contract.t_to * 1000 > DEADLINE - DEADLINE_OFFSET
      ) {
        return 'hasExpiredDeadline';
      }

      // истекает срок (если сейчас активен но срок договора или доп соглашения истекает менее чем через 30 дней, смотрим по последнему по сроку окончания действия)
      if (
        contract.t_to * 1000 > DEADLINE &&
        contract.t_to * 1000 < DEADLINE + DEADLINE_OFFSET
      ) {
        return 'deadline';
      }
    }

    if (trueInDbHelper(currentTariff.constblock)) {
      return 'constblock';
    }

    if (trueInDbHelper(currentTariff.tempblock)) {
      return 'tempblock';
    }

    return 'active';
  },

  STATUS_TYPES: {
    noTariff: { text: 'Нет тарифа', color: '#f60' },
    noContract: { text: 'Нет договора', color: '#000' },
    active: { text: 'Активен', color: '#0f0' }, // - активен (есть договор или допник действующий)
    constblock: { text: 'ТС отключены', color: '#777' }, // - отключен (действует тариф "отключен")
    tempblock: { text: 'ТС временно заблокированы', color: '#999' }, // - временно заблокирован (или как мы там называли, тоже смотрит по тарифу действующему)
    deadline: { text: 'Истекает срок', color: '#f60' },
    hasExpiredDeadline: { text: 'Истек срок', color: '#800' },
    complitedOnTimeTo: { text: 'Завершен по сроку', color: '#777' },
  },

  headers: {
    contractors: {
      name: { text: 'Наименование', style: 'min-width: 200px' },
      fullname: { text: 'Полное наименование', style: 'min-width: 250px' },
      current_tariffs: { text: 'Тариф первоначальный' },
      doc_status: { text: 'Cтатус документа' },
      vehiclesCount: { text: 'ТС всего' },
      vehiclesLocked: { text: 'ТС отключено' },
      groupname: { text: 'Группа', style: 'min-width: 200px' },
      inn: { text: 'ИНН', style: 'min-width: 200px' },
      kpp: { text: 'КПП', style: 'min-width: 200px' },
      ogrn: { text: 'ОГРН', style: 'min-width: 200px' },
      email: { text: 'Email', style: 'min-width: 200px' },
      person: { text: 'Контактное лицо', style: 'min-width: 200px' },
      phone: { text: 'Телефон', style: 'min-width: 170px' },
      description: { text: 'Примечание', style: 'min-width: 350px' },
    },
    persons: {
      fullname: { text: 'ФИО', type: 'string' },
      phone: { text: 'Телефон', type: 'string' },
      email: { text: 'Email', type: 'string' },
      description: {
        text: 'Комментарий',
        type: 'string',
      },
    },
    vehicles: {
      name: { text: 'Наименование' },
      vin: { text: 'VIN' },
      brand: { text: 'Марка' },
      current_tariffs: { text: 'Примененные тарифы' },
      doc_number: { text: 'Документ' }, // компонент будет включать две ячейки: doc_number и doc_status
      doc_status: { text: 'Статус документа' },
      messagesFromVehicle: { text: 'Число сообщений в текущем месяце' },
      contractor_name: { text: 'Контрагент' },
      commercial_name: { text: 'Коммерческое наименование' },
      convention_category: {
        style: 'min-width: 325px',
        text: 'Категория транспортного средства в соответствии с Конвенцией о дорожном движении',
      },
      engine_number: {
        style: 'min-width: 150px',
        text: 'Номер двигателя (двигателей)',
      },
      chassis_number: {
        style: 'min-width: 150px',
        text: 'Номер шасси (рамы)',
      },
      body_number: {
        style: 'min-width: 150px',
        text: 'Номер кузова (кабины, прицепа)',
      },
      body_color: {
        style: 'min-width: 150px',
        text: 'Цвет кузова (кабины, прицепа)',
      },
      made_year: {
        text: 'Год изговления',
      },
      engine_brand: {
        style: 'min-width: 200px',
        text: 'Двигатель внутреннего сгорания (марка)',
      },
      engine_type: {
        style: 'min-width: 200px',
        text: 'Двигатель внутреннего сгорания (тип)',
      },
      engine_volume: {
        style: 'min-width: 200px',
        text: 'Рабочий объем цилиндров (см3)',
      },
      engine_power: {
        style: 'min-width: 200px',
        text: 'Максимальная мощность (кВт)',
      },
      max_power_rpm: {
        style: 'min-width: 200px',
        text: 'Максимальная мощность (мин-1)',
      },
      eco_class: {
        text: 'Экологический класс',
      },
      max_load: {
        style: 'min-width: 300px',
        text: 'Технически допустимая максимальная масса транспортного средства (кг)',
      },
      weight: {
        style: 'min-width: 250px',
        text: 'Масса транспортного средства в снаряженном состоянии (кг)',
      },
      wheel_formula: {
        text: 'Колесная формула',
      },
      transmission_type: {
        text: 'Трансмиссия (тип)',
      },
      fuel_type: {
        text: 'Вид топлива',
      },
      equipment: {
        text: 'Оборудование',
      },
      emergency_number: {
        style: 'min-width: 350px',
        text: 'Сведения об идентификационном номере устройства вызова экстренных оперативных служб',
      },
      chassis_brand: {
        style: 'min-width: 150px',
        text: 'Марка базового ТС (шасси)',
      },
      gears: {
        text: 'Кол-во передач',
      },
      description: {
        text: 'Примечание',
      },
    },
    contracts: {
      number: { text: 'Номер' },
      creator_login: { text: 'Добавлено' },
      contractor_name: { text: 'Контрагент' },
      vehiclesCount: { text: 'Транспортные средства' },
      current_tariffs: { text: 'Тарифы', style: 'min-width: 120px' },
      status: { text: 'Статус документа' },
      messagesFromVehicles: { text: 'Число сообщений в текущем месяце' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      paid: { text: 'Оплачено до' },
      scans: { text: 'Сканы' },
    },
    contract: {
      creator_login: { text: 'Добавлено' },
      id_c: { text: 'Контрагент' },
      status: { text: 'Статус документа' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      paid: { text: 'Оплачено до' },
      current_tariffs: { text: 'Тарифы' },
      additional_tariffs: { text: 'Тарифы по ДС' },
      scans: { text: 'Сканы' },
    },
    additionals: {
      number: { text: 'Номер' },
      creator_login: { text: 'Добавлено' },
      contractor: { text: 'Контрагент' },
      current_tariffs: { text: 'Тариф', style: 'min-width: 120px' },
      vehiclesCount: { text: 'Транспортные средства' },
      status: { text: 'Статус документа' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      paid: { text: 'Оплачено до' },
    },
    additional: {
      uid: { text: 'Уникальный номер соглашения' },
      contractor_name: { text: 'Контрагент' },
      contract_number: { text: 'Договор' },
      creator_login: { text: 'Добавлено' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      paid: { text: 'Оплачено до' },
      status: { text: 'Статус' },
      current_tariffs: { text: 'Тариф' },
      scanFilesServing: { text: 'Сканы с ООО ПКФ «РЕМЭКС»' },
      scanFilesClient: { text: 'Сканы с обслуживающей организацией' },
    },
    defaultTariffs: {
      name: { text: 'Наименование' },
      description: { text: 'Описание' },
      price: { text: 'Стоимость (₽/мес)' },
      tempblock: { text: 'Временная блокировка' },
      constblock: { text: 'Отключен навсегда' },
    },
    tarifsContractor: {
      contractor_name: { text: 'Контрагент' },
      contract_number: { text: 'Номер договора' },
      current_tariffs: { text: 'Тариф' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      description: { text: 'Примечание' },
    },
    tarifsVehicle: {
      vehicle: { text: 'Транспортное средство' },
      current_tariffs: { text: 'Тариф' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      description: { text: 'Примечание' },
    },
    currentTariffs: {
      type: { text: 'Источник' },
      name: { text: 'Наименование' },
      description: { text: 'Описание' },
      t_from: { text: 'Действует с' },
      t_to: { text: 'Действует по' },
      price: { text: 'Стоимость (₽/мес)' },
    },
    logs: {
      time: { text: 'Дата' },
      id: { text: 'Пользователь' },
      value: { text: 'Значение' },
    },
    reports: {
      realPosCount: {
        checkbox: { text: '', style: 'width: 30px' },
        name: { text: 'Наименование' },
        vin: { text: 'VIN' },
        install_time: { text: 'Дата установки' },
        last_time_aggregation: { text: 'Время агрегации до' },
        last_time_vendor: { text: 'Последнее сообщение' },
        poscnt: { text: 'Кол-во сообщений' },
        current_tariffs: { text: 'Тариф' },
      },
    },
  },
};
