import { reactive, watch } from 'vue';

import { useAdditional } from '../use/additional';

import type { TObject } from '../Types/Object';
import type { IAdditional } from './IAdditional';
import type { IContract } from './IContract';
import type { IContractor } from './IContractor';
import type { IDefaultTariff } from './IDefaultTariff';
import type { ITariffContractor } from './ITariffContractor';
import type { ITariffVehicle } from './ITariffVehicle';
import type { IVehicle } from './IVehicle';
import type { IEquipment } from './IEquipment';
import type {
  IMonitoringData,
  IMonitoringObjectsByManualVehicleId,
  IMonitoringObjectsByVins,
  ISenderRights,
} from './IMonitoringData';
import type {
  IContractorsAdditionals,
  IContractorsContracts,
  IContractorsVehicles,
} from './IContractorsData';
import type { IAdditionalsVehicles } from './IAdditionalsData';
import type { IContractsVehicles } from './IContractsData';
import type { IMessageFromVehicle } from './IMessageFromVehicle';
import { updateMessagesFromVehicles } from './messagesFromVehicles';

export const VEHICLE_INTEGER_FIELDS = [
  'engine_volume',
  'engine_power',
  'max_power_rpm',
  'max_load',
  'weight',
];

interface I_STORE {
  contractors: TObject<IContractor>;
  vehicles: TObject<IVehicle>;
  contracts: TObject<IContract>;
  additionals: TObject<IAdditional>;
  defaultTariffs: TObject<IDefaultTariff>;
  tarifsContractor: TObject<ITariffContractor>;
  tarifsVehicle: TObject<ITariffVehicle>;

  monitoring: IMonitoringData;
  monitoringObjectsByVins: IMonitoringObjectsByVins;
  monitoringObjectsByManualVehicleId: IMonitoringObjectsByManualVehicleId;
  equipment: TObject<IEquipment>;

  creators: TObject<string>;

  contractorsContracts: IContractorsContracts;
  contractorsVehicles: IContractorsVehicles;
  contractorsAdditionals: IContractorsAdditionals;

  contractsVehicles: IContractsVehicles;

  additionalsVehicles: IAdditionalsVehicles;

  isRequestedMessagesFromVehicles: boolean;
  messagesFromVehicles: TObject<IMessageFromVehicle>;

  userRole: string;
  senderRights: ISenderRights;
  [key: string]: any;
}
interface I_ADDITIONAL_TYPE {
  readonly text: string;
  readonly value: string;
}

export const STORE: I_STORE = reactive({
  contractors: {},
  vehicles: {},
  contracts: {},
  additionals: {},
  defaultTariffs: {},
  tarifsContractor: {},
  tarifsVehicle: {},

  monitoring: {},
  monitoringObjectsByVins: {},
  monitoringObjectsByManualVehicleId: {},
  equipment: {},

  creators: {},

  contractorsContracts: {},
  contractorsVehicles: {},
  contractorsAdditionals: {},

  contractsVehicles: {},

  additionalsVehicles: {},

  isRequestedMessagesFromVehicles: true,
  messagesFromVehicles: {},

  userRole: 'user',
  senderRights: {},
});

export function deepReplacement(whatToFill: any, howToFill: any): void {
  for (let key in howToFill) {
    if (typeof howToFill[key] === 'object') {
      if (whatToFill[key] === undefined) {
        whatToFill[key] = howToFill[key];
        continue;
      }

      deepReplacement(whatToFill[key], howToFill[key]);
      continue;
    }

    whatToFill[key] = howToFill[key];
  }
  return whatToFill;
}

type storageTypes =
  | IContractor[]
  | IVehicle[]
  | IContract[]
  | IAdditional[]
  | IDefaultTariff[]
  | ITariffContractor[]
  | ITariffVehicle[]
  | IEquipment[];

function updateStorage(newData: storageTypes, key: string): void {
  for (let obj of newData) {
    const id = obj.id.toString();
    if (!STORE[key][id]) {
      STORE[key][id] = obj;
    } else {
      deepReplacement(STORE[key][id], obj);
    }
  }
}

export async function defferExecution(
  conditionCallback: any,
  callback: any = () => {},
): Promise<void> {
  while (conditionCallback()) {
    await new Promise((res) => setTimeout(res, 500));
  }

  callback();
}

function updateContractorsData(
  contractorsDataKey: string,
  contractorsDataFullKey: string,
) {
  for (let id_c in STORE.contractors) {
    if (STORE[contractorsDataFullKey][id_c] === undefined) {
      STORE[contractorsDataFullKey][id_c] = {};
    }

    const contractorsData = STORE[contractorsDataFullKey][id_c];
    const newContractorsData: TObject<any> = {};

    for (let id_d in STORE[contractorsDataKey]) {
      if (STORE[contractorsDataKey][id_d].id_c.toString() != id_c) {
        continue;
      }

      newContractorsData[id_d] = STORE[contractorsDataKey][id_d];

      if (contractorsData[id_d] === undefined) {
        contractorsData[id_d] = STORE[contractorsDataKey][id_d];
      }
    }

    if (
      Object.keys(newContractorsData).length !==
      Object.keys(contractorsData).length
    ) {
      for (let id_d in contractorsData) {
        if (newContractorsData[id_d] === undefined) {
          delete STORE[contractorsDataFullKey][id_c.toString()][
            id_d.toString()
          ];
        }
      }
    }
  }
}

function updateContractorsContracts() {
  updateContractorsData('contracts', 'contractorsContracts');
}

function updateContractorsVehicles() {
  updateContractorsData('vehicles', 'contractorsVehicles');
}

function updateContractorsAdditionals() {
  updateContractorsData('additionals', 'contractorsAdditionals');
}

function updateContractsVehicles() {
  for (let id_d in STORE.contracts) {
    if (STORE.contractsVehicles[id_d] === undefined) {
      STORE.contractsVehicles[id_d] = {};
    }

    const contractVehicles = STORE.contractsVehicles[id_d];
    const newContractVehicles: TObject<any> = {};

    for (let id_v of STORE.contracts[id_d].vehicles) {
      if (STORE.vehicles[id_v.toString()] === undefined) continue;

      newContractVehicles[id_v] = STORE.vehicles[id_v.toString()];

      if (contractVehicles[id_v] === undefined) {
        contractVehicles[id_v] = STORE.vehicles[id_v.toString()];
      }
    }

    if (
      Object.keys(newContractVehicles).length !==
      Object.keys(contractVehicles).length
    ) {
      for (let id_v in contractVehicles) {
        if (newContractVehicles[id_v] === undefined) {
          delete STORE.contractsVehicles[id_d.toString()][id_v.toString()];
        }
      }
    }
  }
}

function updateAdditionalsVehicles() {
  for (let id_d in STORE.additionals) {
    if (STORE.additionalsVehicles[id_d] === undefined) {
      STORE.additionalsVehicles[id_d] = {};
    }

    const additionalVehicles = STORE.additionalsVehicles[id_d];
    const newAdditionalVehicles: TObject<any> = {};

    for (let id_v of STORE.additionals[id_d].vehicles) {
      newAdditionalVehicles[id_v] = STORE.vehicles[id_v.toString()];

      if (additionalVehicles[id_v] === undefined) {
        additionalVehicles[id_v] = STORE.vehicles[id_v.toString()];
      }
    }

    if (
      Object.keys(newAdditionalVehicles).length !==
      Object.keys(additionalVehicles).length
    ) {
      for (let id_v in additionalVehicles) {
        if (newAdditionalVehicles[id_v] === undefined) {
          delete STORE.additionalsVehicles[id_d.toString()][id_v.toString()];
        }
      }
    }
  }
}

function setMonitoringObjectsByVins() {
  STORE.monitoring.objects?.forEach((object, i) => {
    if (STORE.monitoringObjectsByVins[object.vin] === undefined) {
      STORE.monitoringObjectsByVins[object.vin] = object;
    }
  });
}

function setMonitoringObjectsByManualId() {
  for (let id_v in STORE.vehicles) {
    if (STORE.monitoringObjectsByManualVehicleId[id_v] !== undefined) continue;

    for (let vin_m in STORE.monitoringObjectsByVins) {
      if (vin_m == STORE.vehicles[id_v].vin) {
        STORE.monitoringObjectsByManualVehicleId[id_v] =
          STORE.monitoringObjectsByVins[vin_m];
      }
    }
  }
}

const getConditionCallback = (storeKey: string): (() => boolean) => {
  return () => !Object.keys(STORE[storeKey]).length;
};

function updateCreators(rows: TObject<IContract> | TObject<IAdditional>) {
  for (let id in rows) {
    if (!rows[id].creator_u || !rows[id].creator_login) return;

    if (
      STORE.creators[rows[id].change_u] === undefined ||
      STORE.creators[rows[id].change_u] !== rows[id].creator_login
    ) {
      STORE.creators[rows[id].change_u] = rows[id].creator_login;
    }
  }
}

export function updateContractors(contractors: IContractor[]): void {
  updateStorage(contractors, 'contractors');
}

export function updateContracts(contracts: IContract[]): void {
  updateStorage(contracts, 'contracts');

  updateCreators(STORE.contracts);

  let condition = getConditionCallback('contractors');
  defferExecution(condition, updateContractorsContracts);

  condition = getConditionCallback('vehicles');
  defferExecution(condition, updateContractsVehicles);
}

export function updateAdditionals(additionals: IAdditional[]): void {
  updateStorage(additionals, 'additionals');

  updateCreators(STORE.additionals);

  let condition = getConditionCallback('vehicles');
  defferExecution(condition, useAdditional.assignTariffsToVehicles);

  condition = getConditionCallback('contractors');
  defferExecution(condition, updateContractorsAdditionals);

  condition = getConditionCallback('vehicles');
  defferExecution(condition, updateAdditionalsVehicles);
}

export function updateVehicles(vehicles: IVehicle[]): void {
  vehicles.forEach((vehicle) => {
    VEHICLE_INTEGER_FIELDS.map((key) => {
      vehicle[key] /= 100;
    });
  });
  updateStorage(vehicles, 'vehicles');

  let condition = getConditionCallback('contractors');
  defferExecution(condition, updateContractorsVehicles);

  condition = getConditionCallback('contracts');
  defferExecution(condition, updateContractsVehicles);

  updateMessagesFromVehicles();
}

export function updateDefaultTariffs(tariffs: IDefaultTariff[]): void {
  updateStorage(tariffs, 'defaultTariffs');
}

export function updateTariffsContractor(tariffs: ITariffContractor[]): void {
  updateStorage(tariffs, 'tarifsContractor');
}

export function updateTariffsVehicle(tariffs: ITariffVehicle[]): void {
  updateStorage(tariffs, 'tarifsVehicle');
}

export function updateEquipment(equipment: IEquipment[]): void {
  updateStorage(equipment, 'equipment');
}

export function updateMonitoringData(data: IMonitoringData): void {
  if (!Object.keys(STORE.monitoring).length) {
    STORE.monitoring = data;
  } else {
    deepReplacement(STORE.monitoring, data);
  }

  setMonitoringObjectsByVins();
  setMonitoringObjectsByManualId();

  updateMessagesFromVehicles();
}

export const ADDITIONAL_TYPES: I_ADDITIONAL_TYPE[] = [
  {
    text: 'Распоряжение об изменений условий обслуживания ТС, подключенных к серверу телематики',
    value:
      'orderToChangeTermsOfServiceOfVehiclesConnectedToTheTelematicsServer',
  },
  {
    text: 'Дополнительное соглашение между сторонами',
    value: 'additionalBetweenTheSides',
  },
];

watch(
  STORE.vehicles,
  () => {
    const condition = getConditionCallback('contractors');
    defferExecution(condition, updateContractorsVehicles);
  },
  { deep: true },
);

watch(
  STORE.contracts,
  () => {
    let condition = getConditionCallback('contractors');
    defferExecution(condition, updateContractorsContracts);

    condition = getConditionCallback('vehicles');
    defferExecution(condition, updateContractsVehicles);
  },
  { deep: true },
);

watch(
  STORE.additionals,
  () => {
    let condition = getConditionCallback('contractors');
    defferExecution(condition, updateContractorsAdditionals);

    condition = getConditionCallback('vehicles');
    defferExecution(condition, updateAdditionalsVehicles);

    condition = getConditionCallback('vehicles');
    defferExecution(condition, useAdditional.assignTariffsToVehicles);
  },
  { deep: true },
);
