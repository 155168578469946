<template>
  <div
    v-if="isMainShow"
    ref="contentRef"
  >
    <overspeed-template
      :trigger="reportTrigger"
      :report-data="reportData"
      :params="props.params"
    />
  </div>
</template>

<script setup>
import { nextTick, onMounted, provide, ref, watch } from 'vue';

import { useRequest } from '@/ManualApp/use/request';

import OverspeedTemplate from './OverspeedTemplate.vue';

const { send } = useRequest();

const props = defineProps({
  trigger: Boolean,
  form_desc: { type: Object, required: true },
  params: { type: Object, required: true },
  buttonId: { type: String, require: true },
  areaId: { type: String, require: true },
});

const emit = defineEmits(['resetTrigger']);

const URL_GET_OVERSPEED = 'Api/getOverSpeeds';

const button = ref(null);
const targetSpan = ref(null);

const contentRef = ref(null);
const templateContentElement = ref(null);

const isMainShow = ref(false);

const reportData = ref({});
const reportTrigger = ref(false);

const speedOnKey = ref('gspd');

provide('speedOnKey', speedOnKey);

async function getReportData() {
  button.value = document.getElementById(props.buttonId)
  targetSpan.value = document.getElementsByClassName(props.buttonId + "-target-id")[0]
  infoShowText_helper(targetSpan.value, `Отправляю запрос...`, props.areaId);
  setElementColor_helper(button, 'blue');

  const reportData = [];

  try {
    for (let i = 0; i < props.form_desc.objects.length; i++) {
      const body = {
        oid: props.form_desc.objects[i],
        gids: props.params.geofencesIds,
        from: props.form_desc.beginTime / 1000,
        to: props.form_desc.endTime / 1000,
        min_interval: props.params.maxOverTime,
        mode: props.params.speedOn,
      };

      const res = await send(URL_GET_OVERSPEED, 'POST', body);

      if (res.message) {
        throw new Error(res.message);
      }

      reportData.push(res);

      const percent = Math.round(
        (100 * (i + 1)) / props.form_desc.objects.length,
      );

      infoShowText_helper(
        targetSpan.value,
        `Получен ответ ${i + 1} из ${
          props.form_desc.objects.length
        } (${percent}%)`,
        props.areaId,
      );
    }
  } catch (error) {
    infoShowText_helper(
      targetSpan.value,
      `Произошла ошибка, попробуйте еще раз или обновите страницу.`,
      props.areaId,
    );
    setElementColor_helper(button, 'red');
  }

  displayReport(reportData);
}

function displayReport(data) {
  infoShowText_helper(
    targetSpan.value,
    `Отчет построен. Выполнить новый запрос?`,
  );
  setElementColor_helper(button.value, 'green');
  data.sort((a, b) => b.events.length - a.events.length);
  reportData.value = data;

  reportTrigger.value = !reportTrigger.value;
  updateReportArea();
}

function updateReportArea() {
  isMainShow.value = true;
  nextTick(() => {
    templateContentElement.value.innerText = '';
    templateContentElement.value.append(contentRef.value);
  });
}

watch(
  () => props.trigger,
  (val) => {
    if (val) {
      getReportData();
      speedOnKey.value = props.params.speedOn == 'gps' ? 'gspd' : 'speed';
      emit('resetTrigger');
    }
  },
);

watch(
  () => props.areaId,
  (newElem, oldElem) => {
    templateContentElement.value = document.getElementById(newElem);
  }
);
</script>

<style lang="scss" scoped></style>
