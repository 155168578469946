<template>
  <div class="wrapper">
    <div class="container-fluid">
      <div class="wrap-authorization-form">
        <h4 class="text-center">
          {{
            SERVICE === 'Doosan'
              ? 'Эксплуатация машин ДСТ'
              : 'Навыки вождения MAN'
          }}
        </h4>
        <p
          id="submit-info"
          class="info-text text-center"
        >
          {{ prevError }}
        </p>
        <form
          id="athorization-form"
          action="/auth"
          method="POST"
        >
          <div class="row">
            <div class="col-3 for-input-text">
              <label for="inputLogin">Логин:</label>
            </div>
            <div class="col-9">
              <input
                type="text"
                class="form-control"
                name="login"
                id="inputLogin"
                v-model="login"
              />
              <div class="info-text">
                {{ infoLogin }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 for-input-text">
              <label for="inputPassword">Пароль:</label>
            </div>
            <div class="col-9">
              <input
                type="password"
                class="form-control"
                name="password"
                id="inputPassword"
                v-model="pass"
              />
              <div class="info-text">
                {{ infoPassword }}
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 text-center">
              <button
                id="authorization-btn"
                class="btn btn-primary config-btn-primary"
                name="send"
                @click.prevent="fetchUserByApi"
              >
                Войти
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="browsers-info">
        <ul>
          Для ПК проверена работа в браузерах:
          <li>Яндекс v19.7.2</li>
          <li>Mozilla firefox v70.0.1 (64 бит)</li>
          <li>Google Chrome v78.0 (64 бит)</li>
          <li>Opera v65.0</li>
          <li>
            Microsoft Edge v85.0.564.51 (при печати детализации необходимо
            вручную выставить альбомную ориентацию листа)
          </li>
          <li>
            <div style="text-decoration: line-through">
              IE v11.864 (при печати детализации необходимо вручную выставить
              альбомную ориентацию листа)
            </div>
          </li>
        </ul>
        <ul>
          Сервис адаптирован под мобильные устройства за исключением функции
          печати.
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import fetchJson from '../../src/dataRequest/fetch-json';

const URL_POST_USER = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/auth`;

const props = defineProps({
  error: {
    type: String,
    require: false,
    default: '',
  },
});
const emit = defineEmits(['successAuth']);

const SERVICE = inject('SERVICE');

const login = ref('');
const pass = ref('');

const prevError = ref(props.error);
const infoLogin = ref('');
const infoPassword = ref('');

const fetchUserByApi = async () => {
  prevError.value = '';
  infoLogin.value = '';
  infoPassword.value = '';

  try {
    const fd = new FormData();

    prevError.value = 'Отправляю запрос...';
    if (!checkFields(login.value, pass.value)) return;

    fd.append('login', login.value);
    fd.append('password', pass.value);
    fd.append('send', 'Войти');

    const data = await fetchJson(URL_POST_USER, {
      method: 'post',
      credentials: 'include',
      body: fd,
    });

    if (!data.success) {
      prevError.value = '';
      infoLogin.value = data.infoLogin;
      infoPassword.value = data.infoPassword;
    } else {
      prevError.value = '';
      emit('successAuth', {
        success: true,
        login: data.login,
        role: data.role,
        token: data.accessToken,
        rights: data.senderRights,
        token1c: data.token1c,
        token1c_time: Number(data.token1c_time) * 1000,
      });
    }
  } catch (err) {
    prevError.value =
      'Произошла ошибка, повторите попытку, либо обновите страницу';
    console.error(err);
  }
};

const checkFields = (login, pass) => {
  if (!login) infoLogin.value = 'Вы оставили это поле пустым';

  if (!pass) infoPassword.value = 'Вы оставили это поле пустым';

  if (infoLogin.value && infoPassword.value) return false;

  return true;
};
</script>

<style scoped>
.wrapper {
  min-width: 300px;
  font-family: 'Times New Roman', Times, serif;
}
h4 {
  margin-top: 10vh;
}
.info-text {
  line-height: 1em;
  margin-bottom: 15px;
  /* vertical-align:top; */
}
.wrap-authorization-form {
  width: 300px;
  /* height: 443px; */
  margin: 0 auto;
}
button {
  padding: 8px 40px;
  margin-bottom: 25px;
}
input[type='text'],
input[type='password'] {
  width: 100%;
}
.info-text {
  color: red;
  margin-top: 8px;
  margin-bottom: 24px;
  /* min-height: 16px; */
}
.for-input-text {
  padding-top: 7px;
}
.browsers-info {
  width: 70%;
  min-width: 300px;
  margin: 0 auto;
  border: 1px solid #c0c0c0;
  padding: 5px;
  color: #c0c0c0;
  font-size: 0.8em;
  margin-top: 10px;
}
.browsers-info ul {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
</style>
