// import { leafletCheckInGeofencies } from "../../Leaflet/leaflet_check_in_geofencies.js";
import { leafletMain } from '../../Leaflet/leaflet_main.js';
import { getDistanceHelper } from '../../Leaflet/get_distance_helper.js';
import { addCopyObj_helper } from '@/helpers/main_helper.js';
// import { getParamsCodifier } from "../../src/dataRequest/objectParamsСodifier.js";

const REASON_GEOFENCE = 1;
const REASON_BASKET = 2;
const REASON_DRIVER = 1 << 4;
const REASON_WEIGHT = 1 << 5; //
const LAT_LNG_MULTIPLIER = 1000000000000000;
const VIRTUAL_GEO_RADIUS = 500; // в метрах
const VIRTUAL_EVACUATOR_GEO_RADIUS = 30; // в метрах
const GEO_LOADING_TYPE = 2;
const GEO_UNLOADING_TYPE = 3;

const KILO = 1000;
const DAYS = ['вс.', 'пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.'];

export class EventsCalculate {
  rows = [];
  rowsDays = [];
  regularMovingRows = []; // подсчет рейсов
  regularMovingRowsGroup = [];
  regularMoving = {};
  regularMovingEmpty = {};
  regularMovingRowsEmpty = [];
  regularMovingRowsGroupEmpty = [];
  evacuatorMovingRows = []; // эвакуатор
  evacuatorMovingDaysRows = []; // эвакуатор
  evacuatorMovingRow = {}; // эвакуатор
  // prevValues = {};
  isGeoCalc = false;
  virtualUnloadingGeofences = [];
  virtualLoadingGeofences = [];
  // virtualEvacuatorGeofences = [];
  firstPosition = true;

  constructor(params = {}, calculatorParams = {}) {
    const {
      isGeoCalc = false,
      isBasket = false,
      isWeight = false,
      isMovingGroup = false,
      geofencesToTemplate = [],
      geofencesWorkZone = [],
      leafletGeofences = [],
      geofencesLayerData = {},
      templateName = '',
    } = params;

    if (!isGeoCalc) {
      return;
    }

    const {
      arrKeyViolations = [],
      setWebToTableOneAll,
      arrKeyNoSum,
      arrKeyNoSummGetLast,
      mathArray,
      calcPersonSummViolation,
      personForDisplay,
      w_poscount,
      ReportBegin = 0,
      ReportEnd = 0,
    } = calculatorParams;

    this.ReportBegin = ReportBegin;
    this.ReportEnd = ReportEnd;
    this.timeTransitions = this.calcTimeTransitions({ ReportBegin, ReportEnd });

    this.templateName = templateName;
    this.poscount = w_poscount;
    this.arrKeyViolations = arrKeyViolations;
    this.setWebToTableOneAll = setWebToTableOneAll;
    this.arrKeyNoSum = arrKeyNoSum;
    this.arrKeyNoSummGetLast = arrKeyNoSummGetLast;
    this.mathArray = mathArray;
    this.calcPersonSummViolation = calcPersonSummViolation;
    this.personForDisplay = personForDisplay;

    this.isGeoCalc = isGeoCalc;
    this.isBasketSetting = isBasket;
    this.isWeightSetting = isWeight;
    this.isMovingGroup = isMovingGroup;
    this.geofencesToTemplate = geofencesToTemplate;
    this.geofencesToTemplateDict = geofencesToTemplate.reduce((acc, gKey) => {
      acc[gKey] = true;
      return acc;
    }, {});
    this.geofencesWorkZone = geofencesWorkZone;
    this.isWorkZones = Boolean(geofencesWorkZone.length);
    // this.leafletGeofences = leafletGeofences;
    this.leafletGeofencesDict = leafletGeofences.reduce((acc, geo) => {
      const { key } = geo;
      acc[key] = geo;
      return acc;
    }, {});
    this.geofencesLayerData = geofencesLayerData;
    // this.geofencesWorkZoneLeaflet = geofencesWorkZone.map((gId) => {
    //   return leafletGeofences.find((lGeo) => (parseInt(lGeo.key) === parseInt(gId)));
    // });
  }

  calculateRows(position = {}, sum_val = {}, web_j) {
    // в skills_man_template из цикла по строкам из БД получает уместные для отчета строки
    switch (this.templateName) {
      case 'regularMovingGeoTemplate':
        this.addRegularMovingRows(position, sum_val);
        break;
      case 'evacuatorMoving':
        this.addEvacuatorMovingRows(position, sum_val, web_j);
        break;
    }
  }

  addEvacuatorMovingRows(position = {}, sum_val = {}, web_j) {
    // в skills_man_template из цикла по строкам из БД получает уместные для отчета строки
    // evacuator набор строк по данным из БД
    const {
      time,
      geo: geoSrc,
      geo_in: geoInSrcDb,
      geo_out: geoOutSrcDb,
      basket,
      reason,
      lat,
      lon,
    } = position;
    const geoInSrc = geoInSrcDb || [];
    const geoOutSrc = geoOutSrcDb || [];
    const reasonNum = reason ? parseInt(reason) : 0;
    const isLast = Boolean(web_j === this.poscount - 1);
    const isEndDay = Boolean(parseInt(position.seconds_of_the_day) === 86400);

    const { geo, geo_in, geo_out } = this.getGeofencesFiltered(
      geoSrc,
      geoInSrc,
      geoOutSrc,
    );

    const getPreparedRow = () => {
      const row = addCopyObj_helper(sum_val);
      row.geo = geo;
      row.time = parseInt(time);
      row.geo_in = geo_in;
      row.geo_out = geo_out;
      row.basket = basket;
      row.reason = reasonNum;
      row.lat = lat / LAT_LNG_MULTIPLIER;
      row.lon = lon / LAT_LNG_MULTIPLIER;
      row.isFirstPos = this.firstPosition;
      // row.loadingUnloadingGeo = loadingUnloadingGeo;
      return row;
    };

    if (this.timeTransitions[time * 1000]) {
      this.timeTransitions[time * 1000] = getPreparedRow();
    }

    if (!reasonNum && !this.firstPosition && !isLast && !isEndDay) {
      return;
    }

    let isEvent = false;

    if (this.firstPosition || geo_in.length) {
      // первая позиция или вход в геозону
      isEvent = true;
    }

    // выход из геозоны
    if (!isEvent && geo_out.length) {
      this.rows[this.rows.length - 1].timeOut = time;
      this.rows[this.rows.length - 1].timeOutGeo = geoOutSrc;
      // isEvent = true;
    }

    // поднятие кузова вне геозоны createVirtGeo, предполагается типа БАЗА (для отчета по эвакуатору)
    if (!isEvent && parseInt(reason) & 2 && !geo.length && this.rows.length) {
      // рассмотрим на какое расстояние уехала машина от первого поднятия кузова
      const {
        lat: latPrev,
        lon: lonPrev,
        reason: reasonPrev,
        geo: geoPrev,
        geo_out: geoOutPrev,
      } = this.rows[this.rows.length - 1];
      if (
        geoPrev.length ||
        geoOutPrev.length ||
        (!geoPrev.length && !geoOutPrev.length && !(parseInt(reasonPrev) & 2))
      ) {
        isEvent = true;
      } else {
        const distByPrevRow = getDistanceHelper(
          lat / LAT_LNG_MULTIPLIER,
          lon / LAT_LNG_MULTIPLIER,
          latPrev,
          lonPrev,
        );
        if (distByPrevRow * KILO > VIRTUAL_EVACUATOR_GEO_RADIUS) {
          isEvent = true;
        }
      }
      // если виртуальная геозона будет найдена - не создаем событие
      // let virtGeo = this.getVirtGeo({lat, lng: lon, virtualGeofences: this.virtualEvacuatorGeofences});
      // if (!virtGeo) {
      //   virtGeo = this.createVirtGeo({lat, lng: lon, radius: VIRTUAL_EVACUATOR_GEO_RADIUS});
      //   this.virtualEvacuatorGeofences.push(virtGeo);
      //   isEvent = true;
      // }
    }

    const addRow = () => {
      const row = getPreparedRow();
      this.rows.push(row);
      this.rowsDays.push(row);
    };

    // if (isEvent || geo_out.length) {
    if (isEvent) {
      addRow();
    }

    if (!isEvent && !isLast && (geo_out.length || isEndDay)) {
      // if (!isEvent && (geo_out.length)) {
      this.rowsDays.push(getPreparedRow());
    }

    if (isLast) {
      addRow();
    }

    this.firstPosition = false;
  }

  addRegularMovingRows(position = {}, sum_val = {}) {
    // в skills_man_template из цикла по строкам из БД получает уместные для отчета строки
    if (!this.isGeoCalc) {
      return;
    }

    const {
      time,
      geo: geoSrc,
      geo_in: geoInSrcDb,
      geo_out: geoOutSrcDb,
      basket,
      reason,
      weight_summ,
      weight_summ_load,
      weight_summ_isload,
    } = position;
    const geoInSrc = geoInSrcDb || [];
    const geoOutSrc = geoOutSrcDb || [];
    const reasonNum = reason ? parseInt(reason) : 0;
    if (!reasonNum && !this.firstPosition) {
      return;
    }

    this.firstPosition = false;

    let isEvent = false;

    let { lat: lat, lon: lng } = position;
    lat /= LAT_LNG_MULTIPLIER;
    lng /= LAT_LNG_MULTIPLIER;

    // if (this.geofencesWorkZoneLeaflet.length) {
    if (this.isWorkZones) {
      const inZone = (() => {
        for (const workZoneId of this.geofencesWorkZone) {
          const layerData = this.geofencesLayerData[workZoneId];

          if (!layerData) {
            continue;
          }

          if (
            leafletMain.checkLayerInGeofenceByLayerData({
              layerData,
              lat,
              lng,
            })
          ) {
            return true;
          }
        }
        return false;
      })();

      // обрабатываются только в рабочих зонах
      if (!inZone) {
        return;
      }
    }

    // убрать не выбранные геозоны
    // const geoSrcArray = geoSrc.g || geoSrc;
    // const geo = this.filterGeofences(geoSrcArray);
    // const geo_in = this.filterGeofences(geoInSrc);
    // const geo_out = this.filterGeofences(geoOutSrc);
    const { geo, geo_in, geo_out } = this.getGeofencesFiltered(
      geoSrc,
      geoInSrc,
      geoOutSrc,
    );

    const loadingUnloadingGeo = geo.reduce(
      (acc, gId) => {
        const geo = this.leafletGeofencesDict[gId];
        const gType = parseInt(geo?.type);
        if (gType === GEO_LOADING_TYPE) {
          acc.loading.push(geo);
        } else if (gType === GEO_UNLOADING_TYPE) {
          acc.unloading.push(geo);
        }
        return acc;
      },
      { loading: [], unloading: [] },
    );

    if (
      !this.isWeightSetting &&
      !this.isBasketSetting &&
      !isEvent &&
      geo_in &&
      geo_in.length
    ) {
      // обсчет просто по геозонам
      isEvent = true;
    }

    if (!this.isWeightSetting && !isEvent && geo_out && geo_out.length) {
      // выгрузка - это выход из геозоны
      isEvent = true;
    }

    const { unloading: unloadingGeofences, loading: loadingGeofences } =
      loadingUnloadingGeo;

    // погрузка и выгрузка по датчику нагрузки на ось
    const isWightUnload = Boolean(
      this.isWeightSetting && reasonNum & REASON_WEIGHT && !weight_summ_isload,
    );

    const isWightLoad = Boolean(
      this.isWeightSetting && reasonNum & REASON_WEIGHT && weight_summ_isload,
    );

    const isBasketUnload = Boolean(
      !this.isWeightSetting &&
        this.isBasketSetting &&
        basket &&
        reasonNum & REASON_BASKET,
    );

    if (isWightUnload || isBasketUnload) {
      // выгрузка
      // проверить есть ли геозона вида разгрузка (вид 3 - Пункт назначения)
      this.checkAndAddVirtGeo(
        unloadingGeofences,
        this.virtualUnloadingGeofences,
        { lat, lng },
      );
      // if (!unloadingGeofences.length) {
      //   let virtGeo = this.getVirtGeo({
      //     lat,
      //     lng,
      //     virtualGeofences: this.virtualUnloadingGeofences
      //   });

      //   if (!virtGeo) {
      //     virtGeo = this.createVirtGeo({
      //       lat,
      //       lng,
      //       radius: VIRTUAL_GEO_RADIUS
      //     });
      //     this.virtualUnloadingGeofences.push(virtGeo);
      //   }
      //   unloadingGeofences.push(virtGeo);
      //   virtGeo = null;
      // }
      isEvent = true;
    }

    if (isWightLoad) {
      // погрузка по датчику погрузки
      // проверить есть ли геозона вида погрузка (Пункт назначения)
      this.checkAndAddVirtGeo(loadingGeofences, this.virtualLoadingGeofences, {
        lat,
        lng,
      });
      // if (!loadingGeofences.length) {
      //   let virtGeo = this.getVirtGeo({
      //     lat,
      //     lng,
      //     virtualGeofences: this.virtualLoadingGeofences
      //   });

      //   if (!virtGeo) {
      //     virtGeo = this.createVirtGeo({
      //       lat,
      //       lng,
      //       radius: VIRTUAL_GEO_RADIUS
      //     });
      //     this.virtualLoadingGeofences.push(virtGeo);
      //   }
      //   loadingGeofences.push(virtGeo);
      //   virtGeo = null;
      // }
      isEvent = true;
    }

    if (
      !this.isWeightSetting &&
      !isEvent &&
      (loadingUnloadingGeo.loading.length ||
        (!this.isBasketSetting && loadingUnloadingGeo.unloading.length))
    ) {
      // погрузка по входу в геозону
      isEvent = true;
    }

    if (isEvent) {
      const row = addCopyObj_helper(sum_val);
      row.geo = geo;
      row.time = parseInt(time);
      row.geo_in = geo_in;
      row.geo_out = geo_out;
      row.basket = basket;
      row.reason = reasonNum;
      row.loadingUnloadingGeo = loadingUnloadingGeo;
      row.weightSumm = weight_summ / KILO;
      // row.weightSummLoad = weight_summ_load / KILO;
      row.weightLoaded = weight_summ_isload ? weight_summ_load / KILO : 0;
      row.weightUnLoaded = weight_summ_isload ? 0 : weight_summ_load / KILO;
      // row.weightSummIsLoad = weight_summ_isload;
      row.weightLoads = [
        {
          l: weight_summ_load / KILO,
          d: row.t2_distance,
          w: weight_summ / KILO,
        },
      ];

      this.rows.push(row);
    }
  }

  checkAndAddVirtGeo(
    loadOrUnloadGeofencies,
    virtualLoadOrUnloadGeofencies,
    { lat, lng },
  ) {
    if (!loadOrUnloadGeofencies.length) {
      let virtGeo = this.getVirtGeo({
        lat,
        lng,
        virtualGeofences: virtualLoadOrUnloadGeofencies,
      });

      if (!virtGeo) {
        virtGeo = this.createVirtGeo({
          lat,
          lng,
          radius: VIRTUAL_GEO_RADIUS,
        });
        virtualLoadOrUnloadGeofencies.push(virtGeo);
      }
      loadOrUnloadGeofencies.push(virtGeo);
      virtGeo = null;
    }
  }

  createVirtGeo({ lat, lng, radius } = {}) {
    if (lat > 10000 || lat < -10000) {
      lat /= LAT_LNG_MULTIPLIER;
    }
    if (lng > 10000 || lng < -10000) {
      lng /= LAT_LNG_MULTIPLIER;
    }
    const virtKey = `${Math.round(lat * 1000000)}_${Math.round(lng * 1000000)}`;
    return {
      key: virtKey,
      id: virtKey,
      name: this.getVirtualGeoName({ lat, lng }),
      radius, // в метрах
      lat,
      lng,
      isVirt: true,
    };
  }

  getVirtGeo({ lat, lng, virtualGeofences } = {}) {
    if (lat > 10000 || lat < -10000) {
      lat /= LAT_LNG_MULTIPLIER;
    }
    if (lng > 10000 || lng < -10000) {
      lng /= LAT_LNG_MULTIPLIER;
    }
    return virtualGeofences.find((vGeo) => {
      const { lat: circleLat, lng: cercleLng, radius: cercleRadius } = vGeo;
      return leafletMain.checkPointInCircle({
        circleLat,
        cercleLng,
        cercleRadius,
        pointLat: lat,
        pointLng: lng,
      });
    });
  }

  getGeofencesFiltered(geoSrc, geoInSrc, geoOutSrc) {
    const geoSrcArray = geoSrc.g || geoSrc;
    const geo = this.filterGeofences(geoSrcArray);
    const geo_in = this.filterGeofences(geoInSrc);
    const geo_out = this.filterGeofences(geoOutSrc);
    return {
      geo,
      geo_in,
      geo_out,
    };
  }

  getVirtualGeoName({ lat, lng } = {}) {
    const { geo, dist } = this.getNearlyTemplateGeo({ lat, lng });
    const latLonText = `lat:${Math.round(lat * 100000) / 100000}, lon:${
      Math.round(lng * 100000) / 100000
    }`;
    if (geo) {
      const { name } = geo;
      const distText =
        dist > 1000
          ? `${Math.round(dist / 10) / 100}км.`
          : `${Math.round(dist)}м.`;
      return `Виртуальная зона радиусом ${VIRTUAL_GEO_RADIUS}м. (${latLonText}) примерно в ${distText} от "${name}"`;
    }
    return latLonText;
  }

  getNearlyTemplateGeo({ lat, lng } = {}) {
    const result = {
      geo: null,
      dist: 0,
    };
    for (let geoKey in this.geofencesLayerData) {
      const layerData = this.geofencesLayerData[geoKey];
      if (!layerData) {
        continue;
      }

      const dist = leafletMain.getPointDistanceToGeo({ lat, lng }, layerData);
      if (dist === null) {
        continue;
      }
      if (result.dist > dist) {
        result.dist = dist;
      }
    }

    return result;
  }

  filterGeofences(geoKeys = []) {
    return geoKeys.filter((gKey) => this.geofencesToTemplateDict[gKey]);
  }

  getEventsRows() {
    return this.rows;
  }

  getRegularMovingRows() {
    if (this.templateName !== 'regularMovingGeoTemplate') {
      return {
        detailRows: [],
        groupRows: [],
        detailRowsEmpty: [],
        groupRowsEmpty: [],
      };
    }

    for (const row of this.rows) {
      this.regularMovingCalculateRow(row);
    }

    const detailRows = this.regularMovingRows;
    const groupRows = this.groupRegularMovingRows(detailRows, 'loaded');
    const detailRowsEmpty = this.regularMovingRowsEmpty;
    const groupRowsEmpty = this.groupRegularMovingRows(
      detailRowsEmpty,
      'empty',
    );

    return {
      detailRows,
      groupRows,
      detailRowsEmpty,
      groupRowsEmpty,
    };
  }

  getEvacuatorMovingDaysRows(dDistBegin) {
    // evacuator обсчет уже набранных строк для посуточного режима
    if (this.templateName !== 'evacuatorMoving') {
      return [];
    }

    const tmp = {
      dayIdx: -1,
      geo: '',
    };

    for (let i = 0; i < this.rowsDays.length; i++) {
      const row = this.rowsDays[i];
      const isLast = Boolean(i === this.rowsDays.length - 1);
      const isFirst = Boolean(i === 0);
      this.evacuatorMovingCalculateDaysRow({
        row,
        timeTransitions: this.timeTransitions,
        evacuatorMovingDaysRows: this.evacuatorMovingDaysRows,
        tmp,
        isLast,
        isFirst,
        dDistBegin,
      });
    }

    return this.evacuatorMovingDaysRows;
  }

  getEvacuatorMovingRows() {
    // evacuator обсчет уже набранных строк
    if (this.templateName !== 'evacuatorMoving') {
      return [];
    }

    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      this.evacuatorMovingCalculateRow(row, this.evacuatorMovingRows);
    }

    return this.evacuatorMovingRows;
  }

  calcCanTotalDist(day, row) {
    if (day && row.can_odometer > 0) {
      day.canTotalDistEnd = row.can_odometer / 1000;
      day.cntRealPosEnd = row.cnt_real_pos;
      day.cntRealPos = day.cntRealPosEnd - day.cntRealPosBegin;
      if (day.canTotalDistBegin > 0) {
        day.canDistByTotal = day.canTotalDistEnd - day.canTotalDistBegin;
      }
    }
  }

  evacuatorMovingCalculateDaysRow({
    row,
    timeTransitions,
    evacuatorMovingDaysRows,
    tmp,
    isLast,
    isFirst,
    dDistBegin,
  } = {}) {
    // evacuatorMovingDaysRows[dayIndex].departures[].{
    //  comWorks: [{lat, lon, begin}]
    // }
    const { dayIdx: prevDayIdx } = tmp;
    const { transitions } = timeTransitions;
    const { time, reason, geo, geo_in, geo_out, t2_distance, lat, lon } = row;

    const uTime = new Date(time * 1000); // перевод в мс

    for (let dIdx = prevDayIdx; dIdx < transitions.length; dIdx++) {
      if (dIdx < 0) {
        continue;
      }
      if (+transitions[dIdx].begin <= +uTime && uTime < transitions[dIdx].end) {
        tmp.dayIdx = dIdx;
        break;
      }
      if (
        dIdx === transitions.length - 1 &&
        +uTime === +transitions[dIdx].end
      ) {
        tmp.dayIdx = dIdx;
        break;
      }
    }

    const { dayIdx: curDayIdx } = tmp;

    if (!evacuatorMovingDaysRows[curDayIdx]) {
      // заложили начало суток
      evacuatorMovingDaysRows[curDayIdx] = this.getDefaultEvacuatorMovingDay(
        transitions[curDayIdx],
      );
      // {
      //   dayName: DAYS[transitions[curDayIdx].begin.getDay()],
      //   dayTime: transitions[curDayIdx].begin,
      //   departures: [],
      // };
    }

    const curDay = evacuatorMovingDaysRows[curDayIdx];

    // {
    //   leaveTime: 'нет',
    //   leaveGeo: '',
    //   backTime: 'нет',
    //   backGeo: '',
    //   outsideTime: 0,
    //   outsideDist: 0,
    //   comBegin: 0,
    //   comLatLon: 'нет',
    //   comWorks: [],
    // }

    // if (timeTransitions[+uTime]) {
    //   // переход суток, пока еще берутся сутки предыдущего дня, если это не начало отчета
    //   if (!timeTransitions[+uTime].isFirstPos) {

    //   }
    // }

    if (curDayIdx != prevDayIdx || isFirst) {
      // произошел переход суток, закрыть предыдущие сутки и начать следующие
      curDay.canTotalDistBegin = isFirst
        ? typeof dDistBegin === 'number'
          ? dDistBegin
          : ' -'
        : row.can_odometer
        ? row.can_odometer / 1000
        : ' -';
      curDay.cntRealPosBegin = row.cnt_real_pos;
      const prevDay = evacuatorMovingDaysRows[curDayIdx - 1] || null;
      this.calcCanTotalDist(prevDay, row);
      if (!geo.length && !geo_out.length) {
        // следим если объект вне геозоны
        if (!curDay.departures.length) {
          // в этот день на начало суток объект находится вне геозоны база
          curDay.departures.push({
            rowBegin: row,
            leaveTime: 'нет',
            leaveGeo: [],
            backTime: 'нет',
            backGeo: '',
            outsideTime: 0,
            outsideDist: 0,
            // comBegin: 0,
            // comLatLon: 'нет',
            comWorks: [],
          });
        }
        if (prevDay && prevDay.departures.length) {
          const departure =
            prevDay.departures[prevDay.departures.length - 1] ?? {};
          if (departure.rowBegin) {
            departure.outsideTime = uTime - departure.rowBegin.time * 1000;
            departure.outsideDist =
              (t2_distance - departure.rowBegin.t2_distance) / KILO;
          } else {
            departure.outsideTime = '?';
            departure.outsideDist = '?';
          }
        }
      }
    }

    if (geo_out.length) {
      // выход из зоны База
      curDay.departures.push({
        rowBegin: row,
        leaveTime: uTime,
        leaveGeo: geo_out,
        backTime: 'нет',
        backGeo: '',
        outsideTime: 0,
        outsideDist: 0,
        // comBegin: 0,
        // comLatLon: 'нет',
        comWorks: [],
      });
    }

    // else if (!geo.length && !curDay.departures.length) {
    //   // находится вне базы на начало дня (departures пусты)
    //   curDay.departures.push({
    //     rowBegin: row,
    //     leaveTime: 'нет',
    //     leaveGeo: [],
    //     backTime: 'нет',
    //     backGeo: '',
    //     outsideTime: 0,
    //     outsideDist: 0,
    //     // comBegin: 0,
    //     // comLatLon: 'нет',
    //     comWorks: [],
    //   });
    // }

    // if (!geo.length && !geo_out.length) {
    //   const idx = curDay.departures.length - 1;
    //   const departure = curDay.departures[idx] ?? {};
    //   if (departure.rowBegin) {
    //     departure.outsideTime = uTime - departure.rowBegin.time * 1000;
    //     departure.outsideDist =
    //       (t2_distance - departure.rowBegin.t2_distance) / KILO;
    //   } else {
    //     departure.outsideTime = '?';
    //     departure.outsideDist = '?';
    //   }
    // }

    if (geo_in.length) {
      // вход в зону База
      if (!curDay.departures.length) {
        // в этот день не было выхода из геозоны база и не было работы вне геозоны база
        curDay.departures.push({
          // row,
          leaveTime: 'нет',
          leaveGeo: [],
          // backTime: 'нет',
          // backGeo: '',
          // outsideTime: 0,
          // outsideDist: 0,
          // // comBegin: 0,
          // // comLatLon: 'нет',
          comWorks: [],
        });
      }
      const idx = curDay.departures.length - 1;
      const departure = curDay.departures[idx];
      departure.rowEnd = row;
      departure.backTime = uTime;
      departure.backGeo = geo_in;
      if (!departure.rowBegin) {
        const dayBeginTime = this.timeTransitions.transitions[curDayIdx].begin;
        departure.rowBegin = this.timeTransitions[+dayBeginTime] ?? null;
      }
      if (departure.rowBegin) {
        departure.outsideTime = uTime - departure.rowBegin.time * 1000;
        departure.outsideDist =
          (t2_distance - departure.rowBegin.t2_distance) / KILO;
      } else {
        departure.outsideTime = '?';
        departure.outsideDist = '?';
      }
    }

    if (parseInt(reason) & 2 && !geo.length) {
      // поднятие кузова вне геозоны База
      if (!curDay.departures.length) {
        // не было выхода из геозоны база (не добавлено по другим признакам)
        curDay.departures.push({
          // row,
          leaveTime: 'нет',
          leaveGeo: [],
          backTime: 'нет',
          backGeo: '',
          outsideTime: 0,
          outsideDist: 0,
          // // comBegin: 0,
          // // comLatLon: 'нет',
          comWorks: [],
        });
      }
      const depIdx = curDay.departures.length - 1;
      curDay.departures[depIdx].comWorks.push({
        begin: uTime,
        lat,
        lon,
      });
    }

    // dayName
    // dayTime
    // leaveTime /
    // leaveGeo
    // backTime /
    // backGeo
    // outsideTime
    // outsideDist
    // comBegin
    // comLatLon

    if (isLast) {
      // последние подсчеты после рассмотрения всех строк
      this.calcCanTotalDist(curDay, row);
      transitions.forEach((transition, dayIdx) => {
        if (!evacuatorMovingDaysRows[dayIdx]) {
          evacuatorMovingDaysRows[dayIdx] =
            this.getDefaultEvacuatorMovingDay(transition);
        }
        const dayByIdx = evacuatorMovingDaysRows[dayIdx];
        dayByIdx.departures.forEach((departure) => {
          // рассмотреть невозвращенные в геозну отправления
          if (departure.leaveTime > 0 && !(departure.backTime > 0)) {
            departure.backTime = 'нет';
          }
        });
      });
    }
  }

  getDefaultEvacuatorMovingDay(transition) {
    return {
      dayName: DAYS[transition.begin.getDay()],
      dayTime: transition.begin,
      canTotalDistBegin: ' -',
      canTotalDistEnd: ' -',
      canDistByTotal: ' -',
      cntRealPosBegin: 0,
      cntRealPosEnd: 0,
      cntRealPos: 0,
      canDistByTotal: ' -',
      departures: [],
    };
  }

  evacuatorMovingCalculateRow(row, evacuatorMovingRows) {
    // evacuator обсчет уже набранных строк, обсчет строки row, то есть поиск мест работы
    // ищем начало отчета
    if (!this.evacuatorMovingRow.begin) {
      this.evacuatorMovingRow.begin = row;
      return;
    }

    // ведем продолжение

    // вход в зону База, выход из зоны База, подъем кузова
    // if (row.geo_in.length || row.geo_out.length || parseInt(row.reason) & 2) {
    this.evacuatorMovingRow.end = row;
    this.regularMovingOneCalculate(this.evacuatorMovingRow);
    evacuatorMovingRows.push(this.evacuatorMovingRow);
    this.evacuatorMovingRow = {
      begin: row,
    };
    // }
  }

  getIsEmpty({ basket, unloading, weightUnLoaded } = {}) {
    if (this.isWeightSetting) {
      // return Boolean(weightSummLoad && !weightSummIsLoad);
      return Boolean(weightUnLoaded > 0);
    }

    const isBasketSetting = this.isBasketSetting;
    if (this.isWorkZones) {
      // при наличии рабочих зон допускаем выгрузку вне зоны разгрузка
      return Boolean(
        (isBasketSetting && basket) || (!isBasketSetting && unloading.length),
      );
    }

    // нет рабочих зон - выгрузка по датчику поднятия кузова строго в геозонах Пункт назначения
    const [firstUnloading] = unloading;

    if (isBasketSetting) {
      return Boolean(basket && firstUnloading && !firstUnloading.isVirt);
    }

    return Boolean(firstUnloading);
  }

  regularMovingCalculateRow(row) {
    const {
      basket,
      loadingUnloadingGeo,
      // weightSumm,
      // weightSummLoad,
      weightLoaded,
      weightUnLoaded,
      // weightSummIsLoad,
    } = row;

    const isLoaded = this.isWeightSetting
      ? Boolean(weightLoaded)
      : Boolean(loadingUnloadingGeo.loading.length);
    const isEmpty = this.getIsEmpty({
      basket,
      unloading: loadingUnloadingGeo.unloading,
      // weightSummLoad,
      weightUnLoaded,
    });
    // const isEmpty = Boolean((this.isBasketSetting && basket && loadingUnloadingGeo.unloading.length) ||
    //   (!this.isBasketSetting && loadingUnloadingGeo.unloading.length)
    // );

    // ищем догрузку
    if (this.regularMoving.begin && isLoaded) {
      const regularMoving =
        this.regularMovingRows[this.regularMovingRows.length - 1];
      regularMoving.begin.weightSumm = row.weightSumm;
      // regularMoving.begin.weightSummLoad += row.weightSummLoad;
      regularMoving.begin.weightLoaded += row.weightLoaded;
      regularMoving.begin.weightLoads.push({
        l: row.weight_summ_load / KILO,
        d: row.t2_distance,
        w: row.weightSumm,
      });
    }

    // ищем начало рейса
    if (!this.regularMoving.begin && isLoaded) {
      this.regularMoving.begin = row;
      this.regularMovingRows.push(this.regularMoving);
    }

    // ищем окончание рейса
    if (this.regularMoving.begin && isEmpty) {
      this.regularMoving.end = row;
      this.regularMovingOneCalculate(this.regularMoving);
      this.regularMoving = {};
    }

    // ищем начало холостой (не груженой) поездки
    if (!this.regularMovingEmpty.begin && !this.regularMoving.begin) {
      this.regularMovingEmpty.begin = row;
      this.regularMovingRowsEmpty.push(this.regularMovingEmpty);
    }

    // ищем окончание холостой (не груженой) поездки
    if (this.regularMovingEmpty.begin && this.regularMoving.begin) {
      this.regularMovingEmpty.end = row;
      this.regularMovingOneCalculate(this.regularMovingEmpty);
      this.regularMovingEmpty = {};
    }
  }

  regularMovingOneCalculate(regularMoving) {
    const { end, begin } = regularMoving;
    const dist = (end.t2_distance - begin.t2_distance) / KILO;
    const interval = end.time - begin.time;
    const expence =
      (parseInt(end.t2_canexpence) - parseInt(begin.t2_canexpence)) / KILO;
    const canexpencenospd =
      (parseInt(end.t2_canexpencenospd) - parseInt(begin.t2_canexpencenospd)) /
      KILO;
    const canexpencespd =
      (parseInt(end.t2_canexpencespd) - parseInt(begin.t2_canexpencespd)) /
      KILO;
    const clutc_work_summ =
      (parseInt(end.t2_clutc_work_summ) - parseInt(begin.t2_clutc_work_summ)) /
      KILO;
    const motoengine =
      parseInt(end.t2_motoengine) - parseInt(begin.t2_motoengine);
    const motoenginespd =
      parseInt(end.t2_motoenginespd) - parseInt(begin.t2_motoenginespd);
    const motoenginenospd =
      parseInt(end.t2_motoenginenospd) - parseInt(begin.t2_motoenginenospd);

    const clutchUseMoveStartExcessiveWorkSumm =
      (parseInt(end.t2_clutch_use_move_start_excessive_work_summ) -
        parseInt(begin.t2_clutch_use_move_start_excessive_work_summ)) /
      KILO; // в кДж
    const clutchUseMoveStartWorkSumm =
      (parseInt(end.t2_clutch_use_move_start_work_summ) -
        parseInt(begin.t2_clutch_use_move_start_work_summ)) /
      KILO; // в кДж

    /* суммируем прочие нарушения*/
    // let violationsCount = 0;
    //

    let tonOfDist = 0;
    // const {weightLoads, weightSumm, weightSummLoad} = begin;
    const { weightSumm, weightLoads = [], weightLoaded } = begin;
    const { weightUnLoaded } = end;
    let distPrev = begin.t2_distance;
    let summLoad = 0;
    weightLoads.forEach((load) => {
      const { d, l } = load;
      summLoad += l;
      tonOfDist += (summLoad * (d - distPrev)) / KILO;
      distPrev = d;
    });
    tonOfDist += (summLoad * (end.t2_distance - distPrev)) / KILO;

    regularMoving.diffValues = this.mathArray(
      end,
      begin,
      '-',
      this.arrKeyNoSum,
      this.arrKeyNoSummGetLast,
      'first',
    );

    regularMoving.personTableRows = this.getPersonTableRows(
      regularMoving.diffValues,
    );

    // this.arrKeyViolations.forEach(key => {
    //   const keyArr = key.toLowerCase();
    //   if (keyArr in end) {
    //     const prevValue = begin[keyArr] || 0;
    //     const curValue = end[keyArr] || 0;
    //     violationsCount += curValue - prevValue;

    //     // curSmena.violationsPrev[keyArr] = diffValue;
    //   }
    // });

    regularMoving.calculated = {
      interval,
      expence,
      dist,
      canexpencenospd,
      canexpencespd,
      clutc_work_summ,
      motoengine,
      motoenginespd,
      motoenginenospd,
      clutchUseMoveStartExcessiveWorkSumm,
      clutchUseMoveStartWorkSumm,
      weightLoaded,
      weightUnLoaded,
      tonOfDist,
      weightSumm,
      // weightSummLoad,
      // violationsCount
    };

    // обсчет и добавление производных значений, кроме тонно киломтров tonOfDist
    for (const foo of Object.values(this.derivativeValues)) {
      foo(regularMoving.calculated);
    }

    const { lat, lon, geo } = begin;
    const avgSpeed = motoenginespd ? dist / (motoenginespd / 3600) : ' - ';
    const canexpence100km =
      dist > 10 && expence > 0 ? (100 * expence) / dist : ' - ';
    const { t2_ptotime } = regularMoving.diffValues;

    regularMoving.evacuatorRow = {
      timeIn: begin.time,
      lat,
      lon,
      geo,
      timeOut: begin.timeOut ?? ' - ',
      dist,
      canexpencespd,
      avgSpeed,
      motoenginespd,
      canexpence100km,
      isLoaded: '?',
      comInterval: t2_ptotime,
      forfeitsSumm: regularMoving.personTableRows.summLine,
      isFirstPos: begin.isFirstPos,
      basket: begin.basket,
    };
  }

  getPersonTableRows(webArr) {
    const personTableRows = {
      summLine: {},
      specificIdling: {
        // работу на х х  пропускаем
        DCount: 0, //количество нарушений
        DForfeits: 0, //количество штрафных баллов за нарушения
      },
    };
    this.setWebToTableOneAll(personTableRows, webArr);
    const distSumm = webArr['t2_distance'] || 0; // аналог sum_val
    personTableRows.summLine = this.calcPersonSummViolation(
      this.personForDisplay,
      personTableRows,
      distSumm,
    );
    return personTableRows;
  }

  derivativeValues = {
    expence100km: (row) => {
      const { expence, dist } = row;
      row.expence100km =
        dist > 10 && expence > 0 ? (100 * expence) / dist : ' - ';
    },
    avgSpeed: (row) => {
      const { motoenginespd, dist } = row;
      row.avgSpeed = motoenginespd ? dist / (motoenginespd / 3600) : ' - ';
    },
    clutchUseMoveStartExcessiveWorkPercent: (row) => {
      const {
        clutchUseMoveStartExcessiveWorkSumm,
        clutchUseMoveStartWorkSumm,
      } = row;
      if (clutchUseMoveStartWorkSumm > 0) {
        row.clutchUseMoveStartExcessiveWorkPercent =
          (100 * clutchUseMoveStartExcessiveWorkSumm) /
          clutchUseMoveStartWorkSumm;
      } else {
        row.clutchUseMoveStartExcessiveWorkPercent = 0;
      }
    },
  };

  // derivativeCalculate(row) {
  //   const {expence, dist} = row;
  //   row.expence100km = (dist > 10 && expence > 0) ? 100 * expence / dist : ' - ';
  // }

  groupRegularMovingRows(regularMovingRows, mode = 'loaded') {
    const regularMovingRowsGroup = [];
    const onceMovingRowsGroup = [];
    const routeDict = {};

    for (const route of regularMovingRows) {
      const { begin = {}, end = {}, calculated, diffValues } = route;

      const beginKey = mode === 'loaded' ? 'loading' : 'unloading';
      const endKey = mode === 'loaded' ? 'unloading' : 'loading';
      const [geoBegin] = begin.loadingUnloadingGeo?.[beginKey] || [];
      const [geoEnd] = end.loadingUnloadingGeo?.[endKey] || [];

      if (!geoBegin || !geoEnd) {
        onceMovingRowsGroup.push(route);
        continue;
      }

      const fromKey = geoBegin.key;
      const toKey = geoEnd.key;

      const routeKey = `${fromKey}__${toKey}`;
      const groupIndex = routeDict[routeKey] ?? false;
      if (groupIndex === false) {
        routeDict[routeKey] = regularMovingRowsGroup.length;
        regularMovingRowsGroup.push({
          begin: route.begin,
          end: route.end,
          count: 1,
          calculated: addCopyObj_helper(calculated),
          diffValues: addCopyObj_helper(diffValues),
        });
      } else {
        const routeGroup = regularMovingRowsGroup[routeDict[routeKey]];
        const { calculated: routeCalculated, diffValues: routeDiffValues } =
          routeGroup;
        routeGroup.count++;

        routeGroup.diffValues = this.mathArray(
          diffValues,
          routeDiffValues,
          '+',
          this.arrKeyNoSum,
          this.arrKeyNoSummGetLast,
          'first',
        );

        for (const [key, value] of Object.entries(calculated)) {
          const foo = this.derivativeValues[key] || null;
          if (foo) {
            foo(routeCalculated);
            continue;
          }
          routeCalculated[key] += value;
        }
      }
    }

    for (const roureGroup of regularMovingRowsGroup) {
      roureGroup.personTableRows = this.getPersonTableRows(
        roureGroup.diffValues,
      );
    }

    return {
      regularMovingRowsGroup,
      onceMovingRowsGroup,
    };
  }

  calcTimeTransitions({ ReportBegin, ReportEnd } = {}) {
    const MS_BY_DAY = 86400000;
    const timeTransitions = {
      transitions: [],
    };
    const addTransitionTime = (t, isEnd) => {
      const { transitions } = timeTransitions;
      if (transitions.length) {
        transitions[transitions.length - 1].end = t;
      }
      if (!timeTransitions[+t] && !isEnd) {
        timeTransitions[+t] = {};
        transitions.push({
          begin: t,
        });
      }
    };
    const begin = new Date(+ReportBegin);
    const end = new Date(+ReportEnd);
    addTransitionTime(begin);
    const nextFirstDay = new Date(+begin.toOnlyDay() + MS_BY_DAY);
    for (
      let t = new Date(+nextFirstDay);
      t < end;
      t = new Date(+t + MS_BY_DAY)
    ) {
      if (t > begin) {
        addTransitionTime(t);
      }
    }
    addTransitionTime(end, true);

    return timeTransitions;
  }
}
