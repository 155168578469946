import { reactive } from 'vue';

import { useRequest } from './request';

import {
  updateAdditionals,
  updateContractors,
  updateContracts,
  updateDefaultTariffs,
  updateEquipment,
  updateMonitoringData,
  updateTariffsContractor,
  updateTariffsVehicle,
  updateVehicles,
} from '../STORAGE/STORAGE';

const URL_PATHS = {
  contractors: `Api/manual/contractors`,
  contracts: `Api/manual/contracts`,
  vehicles: `Api/manual/vehicles`,
  additionals: `Api/manual/additionals`,
  monitoring: `Api/getObjectsListFromTableApi`,
  allTariffs: `Api/manual/tarifs`,
  tarifsContractor: `Api/manual/tarifsContractor`,
  tarifsVehicle: `Api/manual/tarifsVehicle`,
  equipment: `Api/manual/equipment`,
};

const REFETCH_ALL_TIMEOUT = 5 * 60 * 1000;
const REFETCH_MONITORING_TIMEOUT = 10 * 60 * 1000;

const { send } = useRequest();

export const isRequest = reactive({
  contractors: true,
  contracts: true,
  vehicles: true,
  additionals: true,
  tarifsContractor: true,
  tarifsVehicle: true,
  equipment: true,
});

const nextRequestTime = {
  monitoring: 0,
  vehicles: 0,
  additionals: 0,
  contracts: 0,
  allTariffs: 0,
  tarifsContractor: 0,
  tarifsVehicle: 0,
  equipment: 0,
};

export const useRefetch = {
  allContractors(errorCallback) {
    const name = 'contractors';
    this.infinityRequest(name, updateContractors, errorCallback);
  },

  allContracts(errorCallback) {
    const name = 'contracts';
    this.infinityRequest(name, updateContracts, errorCallback);
  },

  allAdditionals(errorCallback) {
    const name = 'additionals';
    this.infinityRequest(name, updateAdditionals, errorCallback);
  },

  allVehicles(errorCallback) {
    const name = 'vehicles';
    this.infinityRequest(name, updateVehicles, errorCallback);
  },

  allTariffs(errorCallback) {
    const name = 'allTariffs';
    this.infinityRequest(name, updateDefaultTariffs, errorCallback);
  },

  tarifsContractor(errorCallback) {
    const name = 'tarifsContractor';
    this.infinityRequest(name, updateTariffsContractor, errorCallback);
  },

  tarifsVehicle(errorCallback) {
    const name = 'tarifsVehicle';
    this.infinityRequest(name, updateTariffsVehicle, errorCallback);
  },

  equipment(errorCallback) {
    const name = 'equipment';
    this.infinityRequest(name, updateEquipment, errorCallback);
  },

  monitoringObjectsList(errorCallback) {
    const name = 'monitoring';
    this.infinityRequest(
      name,
      updateMonitoringData,
      errorCallback,
      REFETCH_MONITORING_TIMEOUT,
    );
  },

  infinityRequest: async (
    name,
    successCallback,
    errorCallback = () => {},
    timeout,
  ) => {
    while (true) {
      if (nextRequestTime[name] > Date.now()) {
        await new Promise((res) => setTimeout(res, 5000));
        continue;
      }

      isRequest[name] = true;

      nextRequestTime[name] = Date.now() + timeout || REFETCH_ALL_TIMEOUT;

      try {
        const res = await send(URL_PATHS[name]);
        if (res.message) {
          isRequest[name] = false;
          // errorCallback(res.message);
          break;
        }

        successCallback(res);
      } catch (error) {
        console.error(error);
        errorCallback(error.message);
      }

      isRequest[name] = false;
      await new Promise((res) =>
        setTimeout(res, timeout || REFETCH_ALL_TIMEOUT),
      );
    }
  },
};
