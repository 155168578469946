<template>
  <Transition name="fade">
    <my-modal
      v-if="props.show"
      @close="emit('close')"
    >
      <template #header>
        <h4 class="monitoring-sidebar__settings-title">
          Отметьте необходимые столбцы
        </h4>
        <ui-close-button @click="emit('close')" />
      </template>
      <template #body>
        <div style="overflow: auto; height: 100%">
          <div class="flex objects-list-modal-settings__image-btns">
            <ui-image-button
              path="/images/checked-checkbox-26.png"
              alt="check"
              targetText="Выделить все"
              @click="checkAllSettings"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />

            <ui-image-button
              style="margin-left: 5px"
              path="/images/cleared-checkbox-26.png"
              alt="clear"
              targetText="Очистить все"
              @click="clearAllSettings"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />
          </div>

          <ul class="monitoring-sidebar__settings-list">
            <template
              v-for="column in TABLE_COLUMNS_NAMES"
              :key="column['name']"
            >
              <li v-if="column['show']">
                <label :for="`table-column-${column['name']}`">
                  <input
                    :id="`table-column-${column['name']}`"
                    type="checkbox"
                    v-model="selectedTableColumnsArr"
                    :value="column"
                    :disabled="column['disabled']"
                  />
                  <span
                    style="margin-left: 10px"
                    v-html="column['textHtml']"
                  ></span>
                </label>
              </li>
            </template>
          </ul>

          <div
            class="flex"
            style="justify-content: flex-end"
          >
            <button
              class="btn btn-primary config-btn-primary"
              @click="saveSettingsHandler"
            >
              Сохранить
            </button>
          </div>
        </div>
      </template>
    </my-modal>
  </Transition>
</template>

<script setup>
import { inject, ref, watch } from '@vue/runtime-core';

import MyModal from '@/App/Components/MyModal.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import UiImageButton from './UiImageButton.vue';

import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../../../helpers/targetsHelper';
import { MonitoringSettings } from '../../default/MonitoringSettings';
import { DiagnosticSettings } from '../../../Diagnostics/default/DiagnosticSettings';

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  clientWidth: {
    type: Number,
    required: true,
  },
  clientHeight: {
    type: Number,
    required: true,
  },
  isAdmin: {
    type: Boolean,
    required: false,
    default: false,
  },
  isForMonitoring: {
    type: Boolean,
    required: true,
  }
});

const emit = defineEmits(['close', 'saveSettings']);
let TABLE_COLUMNS_NAMES;
if (props.isForMonitoring) {
  TABLE_COLUMNS_NAMES = ref(MonitoringSettings.ObjectsListTableSettings);
} else {
  TABLE_COLUMNS_NAMES = ref(DiagnosticSettings.ObjectsListTableSettings);
} 

let selectedTableColumnsArr;
if (props.isForMonitoring) {
  selectedTableColumnsArr = ref(MonitoringSettings.checkedObjectsListTableSettings);
} else {
  selectedTableColumnsArr = ref(DiagnosticSettings.checkedObjectsListTableSettings);
} 

const projectData = inject('projectData');

watch(projectData, (data) => {
  if (!props.isAdmin) return;

  TABLE_COLUMNS_NAMES.value.map((obj) => {
    obj.show = true;
  });
});

const checkAllSettings = () => {
  selectedTableColumnsArr.value = TABLE_COLUMNS_NAMES.value.filter(
    (obj) => obj.show,
  );
};
const clearAllSettings = () => {
  selectedTableColumnsArr.value = TABLE_COLUMNS_NAMES.value.filter(
    (obj) => obj.disabled,
  );
};

const saveSettingsHandler = () => {
  emit('saveSettings', selectedTableColumnsArr.value);
  emit('close');
};
</script>

<style lang="scss" scoped>
.objects-list-modal-settings__image-btns {
  margin-left: 32.5px;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    margin-left: 12.5px;
  }
}
</style>
