import { ref } from 'vue';
import type { Ref } from 'vue';

import sort_helper from '@/helpers/sort_helper';

import type { ITableData } from '../Types/ITableData';
import type { TObject } from '../Types/Object';

export const useSort = (tableData: ITableData) => {
  const currentSortKey = ref('');
  const lastColumnForSortKey = ref('');
  const isLargeToSmallerSort = ref(false);

  const addSortClasses = (
    element: HTMLBodyElement,
    isLargeToSmaller = false,
  ) => {
    if (!element) return;

    if (isLargeToSmaller) {
      element.classList.remove('sort--up');
      element.classList.add('sort--down');
    } else {
      element.classList.remove('sort--down');
      element.classList.add('sort--up');
    }
  };

  const sortTableAction = (
    { columnKey, dataType, noSort }: TObject<string>,
    element: HTMLBodyElement,
  ) => {
    if (noSort) return;

    const isReverse = lastColumnForSortKey.value === columnKey;

    currentSortKey.value = columnKey || '';

    if (isReverse) {
      isLargeToSmallerSort.value = !isLargeToSmallerSort.value;
      addSortClasses(element, isLargeToSmallerSort.value);
      tableData.body.reverse();
      return;
    }

    tableData.body.sort((a, b) => {
      return sort_helper.dataTypes[dataType](a[columnKey], b[columnKey]);
    });

    addSortClasses(element, isLargeToSmallerSort.value);
    lastColumnForSortKey.value = columnKey || '';
    isLargeToSmallerSort.value = false;
  };

  const sortTable = (e: Event) => {
    try {
      const element = e.target as HTMLBodyElement;

      if (element.nodeName !== 'TH') return;

      const columnKey = element.dataset.columnKey;
      const dataType = element.dataset.dataType || 'string';
      const noSort = element.dataset.noSort || '';

      if (!columnKey) return;

      sortTableAction({ columnKey, dataType, noSort }, element);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return { sortTable };
};
