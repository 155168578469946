import { ref, reactive, watch } from 'vue';

export function useField(field) {
  const valid = ref(true);
  const value = ref(field.value);
  const touched = ref(!field.validators?.hasOwnProperty('required'));
  const errors = reactive({});

  const reassign = (val) => {
    valid.value = true;

    const validatorsKeys = Object.keys(field.validators ?? {});

    validatorsKeys.map((name) => {
      const isValid = field.validators[name](val);

      errors[name] = !isValid;

      if (!isValid) {
        valid.value = false;
      }
    });
  };

  watch(value, reassign, { immediate: true, deep: true });

  return {
    value,
    valid,
    errors,
    touched,
    validators: field.validators,
    blur: () => (touched.value = true),
  };
}
