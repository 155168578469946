<template>
  <th
    class="table-th"
    :data-minwidth="props.dataMinwidth"
    :data-formatnum="props.dataFormatnum"
    :data-column-key="props.cellKey"
    :data-data-type="props.cell.type || 'string'"
    :data-no-sort="props.cell.noSort"
    :style="{ width: newWidth }"
    
  >
    <span v-html="props.cell.text"></span>
    <span v-if="props.isResizable" class="column-width-changer" @mousedown="onMouseDown"></span>
  </th>
</template>

<script setup>
import { ref } from 'vue';

let startX = null;
let startWidth = null;
const startColumn = ref(null);
const newWidth = ref(null);

const props = defineProps({
  cellKey: { type: String, required: true },
  cell: { type: Object, required: true },
  dataMinwidth: { type: [String, Number, Boolean], default: false },
  dataFormatnum: { type: [String, Number, Boolean], default: false },
  isResizable: { type: Boolean, default: false }
});

function onMouseDown(e) {
  startX = e.clientX;
  startColumn.value = props.cellKey;
  const th = e.target.closest('th');
  startWidth = th.offsetWidth;

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
}

function onMouseMove(e) {
  if (startColumn.value !== null) {
    newWidth.value = `${startWidth + (e.clientX - startX)}px`;
  }
}

function onMouseUp() {
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
}


</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';
.table-th {
  font-size: 12px;
  position: sticky;
  overflow: hidden;
  background: $bg-secondary;
  border-color: $table-border-color;
}
.column-width-changer {
  cursor: col-resize;
  user-select: none;
  width: 5px;
  // background: #000;
  height: 28px;
  position: absolute;
  right: 0px;
  top: -1px;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 7px solid #000;
    border-left: 7px solid transparent;
  }
}
</style>
