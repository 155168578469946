<template>
  <ui-popup
    style-for-body="width: 50vw"
    @close="emit('close')"
  >
    <ui-close-button @close="() => emit('close')" />

    <div class="d-flex justify-content-between mt-2 mb-2">
      <h4>Список всех VIN</h4>
      <ui-button @click="copyAllVins">Скопировать</ui-button>
    </div>

    <div style="overflow-y: scroll; max-height: 75vh">
      <ol class="mt-2">
        <li
          v-for="(vehicle, i) in props.rows"
          :key="i"
        >
          <template v-if="i != props.rows.length - 1">
            {{ i + 1 }}. <span v-html="vehicle.vin"></span>,
          </template>
          <template v-else>
            {{ i + 1 }}. <span v-html="vehicle.vin"></span>;
          </template>
        </li>
      </ol>
    </div>
  </ui-popup>
</template>

<script setup>
import { inject } from 'vue';

import UiPopup from './UiPopup.vue';
import UiCloseButton from '../Buttons/UiCloseButton.vue';
import UiButton from '../Buttons/UiButton.vue';

import { useCopy } from '@/ManualApp/use/copy';

const props = defineProps({ rows: { type: Array, default: [] } });

const emit = defineEmits(['close']);

const displayToast = inject('displayToast');

const copyAllVins = () => {
  if (!props.rows.length) {
    displayToast('VIN номера не обнаружены', 'warning');
    return;
  }

  const htmlForCopy = props.rows.reduce((acc, row, i) => {
    if (i != props.rows.length - 1) {
      acc += `${i + 1}. ${row.vin},<br />`;
    } else {
      acc += `${i + 1}. ${row.vin};`;
    }

    return acc;
  }, '');

  const isSuccess = useCopy(htmlForCopy);

  if (isSuccess) {
    displayToast('VIN номера успешно скопированы', 'success');
  } else {
    displayToast('Произошла ошибка');
  }
};
</script>

<style lang="scss" scoped></style>
