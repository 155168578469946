import {
  formatTimeHelper,
  formatDateHelper,
  getPercent,
  myRoundNumber_helper,
  stringTimeToSeconds,
} from '@/helpers/main_helper';

export type Column = {
  name: string;
  label: string;
  format?: (object: unknown, type: FORMAT_TYPE, val: unknown) => string;
  headAttrs?: Record<string, string>;
  bodyAttrs?:
    | Record<string, string>
    | ((
        val: string | number,
        col?: Column,
        child?: Column,
        type?: FORMAT_TYPE | ENGINE_OIL_TEMP_FORMAT_TYPE,
      ) => Record<string, string>);
  children?: Column[];
  banner?: string;
};

export type FORMAT_TYPE =
  | 'period-start-min'
  | 'period-end-min'
  | 'period-delta-min'
  | 'min'
  | 'max'
  | 'delta';
export type ENGINE_OIL_TEMP_FORMAT_TYPE = 'interval' | 'interval-delta';
type MinMax = [number, number];

const NOT_VALUE = -1;
const NOT_VALUE_STRING = '-';
const NOT_DATA_STRING = 'н/д';

export const SUMMARY_FORMAT_TYPES: FORMAT_TYPE[] = [
  'period-start-min',
  'period-end-min',
  'period-delta-min',
  'min',
  'max',
  'delta',
];
export const FORMAT_TYPES: FORMAT_TYPE[] = ['min', 'max', 'delta'];

const translateFormatType = {
  'period-start-min': 'Мин. нач. пер.',
  'period-end-min': 'Мин. кон. пер.',
  'period-delta-min': 'Дельта по мин.',
  'min': 'Минимальное',
  'max': 'Максимальное',
  'delta': 'Дельта',
};

export const ENGINE_OIL_TEMP_FORMAT_TYPES: ENGINE_OIL_TEMP_FORMAT_TYPE[] = [
  'interval',
  'interval-delta',
];
const translateParameterFormatType = {
  'interval': 'Время работы',
  'interval-delta': 'Доля от общего времени',
};

const getIntervalBodyAttrs = (
  val: string,
  col: Column,
  child: Column,
  type: ENGINE_OIL_TEMP_FORMAT_TYPE,
) => {
  let dataFormatNum = 0;
  let dataValue = val;

  if (val === undefined || val === null) {
    dataFormatNum = 0;
    dataValue = NOT_DATA_STRING;
  } else if (val.includes('%')) {
    dataFormatNum = 11;
    dataValue = val.replace('%', '');
  } else if (val.includes(':')) {
    dataFormatNum = 4;
    dataValue = '' + stringTimeToSeconds(val) / 86400;
  }

  return {
    'data-formatnum': dataFormatNum,
    'data-value': dataValue,
  };
};

const COLUMNS = {
  format: {
    name: 'format',
    label: 'Признак',
    format: (val: never, type: FORMAT_TYPE) => {
      return translateFormatType[type] || NOT_DATA_STRING;
    },
    headAttrs: { 'data-minwidth': 15 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  parameter: {
    name: 'param',
    label: 'Параметр',
    format: (val: never, type: ENGINE_OIL_TEMP_FORMAT_TYPE) => {
      return translateParameterFormatType[type] || NOT_DATA_STRING;
    },
    headAttrs: { 'data-minwidth': 15 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  name: {
    name: 'name',
    label: 'Наименование',
    format: (val: unknown) => {
      if (typeof val === 'object' && val !== null) {
        if ('name' in val) {
          return val.name;
        }
        return NOT_VALUE_STRING;
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 18 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  stateNumber: {
    name: 'stateNumber',
    label: 'Гос №',
    format: (val: unknown) => {
      if (typeof val === 'object' && val !== null) {
        if ('stateNumber' in val) {
          return val.stateNumber;
        } else {
          return NOT_VALUE_STRING;
        }
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 18 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  nameOrStateNumber: {
    name: 'nameOrStateNumber',
    label: 'Марка, гос.номер ТС',
    format: (val: unknown) => {
      if (typeof val === 'object' && val !== null) {
        if ('name' in val) {
          return val.name;
        }
        if ('stateNumber' in val) {
          return val.stateNumber;
        }
        return NOT_DATA_STRING;
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 18 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  Date: {
    name: 'date',
    label: 'Дата',
    format: (object: unknown, type: FORMAT_TYPE, val: unknown) => {
      if (typeof val === 'object' && val !== null) {
        if ('time' in val) {
          return formatDateHelper(
            new Date(Number(val.time) * 1000),
            'dd.mm.yyyy',
          );
        }
        return NOT_VALUE_STRING;
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 10 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  Time: {
    name: 'date',
    label: 'Время',
    format: (object: unknown, type: FORMAT_TYPE, val: unknown) => {
      if (typeof val === 'object' && val !== null) {
        if ('time' in val) {
          return formatDateHelper(
            new Date(Number(val.time) * 1000),
            'hh:nn:ss',
          );
        }
        return NOT_VALUE_STRING;
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 10 },
    bodyAttrs: { 'data-formatnum': 0 },
  },
  canMoto: {
    name: 'can_moto',
    label: 'Общий моторесурс',
    format: (object: never, type: FORMAT_TYPE, val: unknown) => {
      const toHours = (val: number) => {
        if (Number.isNaN(val)) {
          return NOT_VALUE_STRING;
        } else {
          const hours = val / 3600;
          return myRoundNumber_helper(hours, 2);
        }
      };
      if (typeof val === 'object' && val !== null) {
        if (type === 'min' && 'can_moto_min' in val) {
          return toHours(Number(val.can_moto_min));
        }
        if (type === 'max' && 'can_moto_max' in val) {
          return toHours(Number(val.can_moto_max));
        }
        if (
          type === 'delta' &&
          'can_moto_max' in val &&
          'can_moto_min' in val
        ) {
          const delay = Number(val.can_moto_max) - Number(val.can_moto_min);
          return toHours(Number(delay));
        }

        if (type === 'period-start-min' && 'can_moto_period_start_min' in val) {
          return toHours(Number(val.can_moto_period_start_min));
        }
        if (type === 'period-end-min' && 'can_moto_period_end_min' in val) {
          return toHours(Number(val.can_moto_period_end_min));
        }
        if (
          type === 'period-delta-min' &&
          'can_moto_period_end_min' in val &&
          'can_moto_period_start_min' in val
        ) {
          const delay =
            Number(val.can_moto_period_end_min) -
            Number(val.can_moto_period_start_min);
          return toHours(Number(delay));
        }

        return NOT_DATA_STRING;
      } else {
        return NOT_DATA_STRING;
      }
    },
    headAttrs: { 'data-minwidth': 15 },
    bodyAttrs: { 'data-formatnum': 7 },
  },
  engineMoto: {
    name: 'engineMoto',
    label: 'Моторесурс за период',
    format: (
      row: unknown,
      type: ENGINE_OIL_TEMP_FORMAT_TYPE,
      engine_time: number,
    ) => {
      if (engine_time === undefined || engine_time === null) {
        return NOT_DATA_STRING;
      }
      if (type === 'interval') {
        return formatTimeHelper(engine_time / 86400, 'tt:nn:ss');
      } else {
        if (row && typeof row === 'object' && 'engine_time_summary' in row) {
          return (
            getPercent(Number(engine_time), Number(row?.engine_time_summary)) +
            '%'
          );
        }
      }
    },
    headAttrs: { 'data-minwidth': 15 },
    bodyAttrs: getIntervalBodyAttrs,
  },
};

const TemperatureFormat = (val: MinMax, type: FORMAT_TYPE) => {
  try {
    const [min, max] = val;
    if (type === 'min' || type === 'period-start-min') {
      return min !== NOT_VALUE ? min : NOT_VALUE_STRING;
    }
    if (type === 'max' || type === 'period-end-min') {
      return max !== NOT_VALUE ? max : NOT_VALUE_STRING;
    }
    if (type === 'delta' || type === 'period-delta-min') {
      return max !== NOT_VALUE && min !== NOT_VALUE
        ? Math.floor((max - min) * 100) / 100
        : NOT_VALUE_STRING;
    }
    return NOT_VALUE_STRING;
  } catch (error) {
    return NOT_DATA_STRING;
  }
};

const EngineTemperatureFormat = (
  val: number,
  type: ENGINE_OIL_TEMP_FORMAT_TYPE,
  engine_time: number,
) => {
  try {
    if (val === undefined || val === null || engine_time <= 0) {
      return NOT_DATA_STRING;
    }
    if (type === 'interval') {
      return val !== NOT_VALUE
        ? formatTimeHelper(val / 86400, 'tt:nn:ss')
        : NOT_VALUE_STRING;
    }
    if (type === 'interval-delta') {
      return val !== NOT_VALUE
        ? `${Math.round((val / engine_time) * 100)}%`
        : NOT_VALUE_STRING;
    }
    return NOT_VALUE_STRING;
  } catch (error) {
    return NOT_DATA_STRING;
  }
};

const getTemperatureBodyAttrs = (val: string | number) => ({
  'data-formatnum': val == NOT_VALUE_STRING ? 15 : 7,
  'data-value': val == NOT_VALUE_STRING ? 0 : val,
  'data-minwidth': 10,
});

const TemperatureHeadAttrs = {
  'data-minwidth': 10,
};

const Temperature = {
  'Less70': {
    name: '0',
    label: '< 70',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '70To75': {
    name: '1',
    label: '70 - 75',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '76To80': {
    name: '2',
    label: '76 - 80',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '81To85': {
    name: '3',
    label: '81 - 85',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '86To90': {
    name: '4',
    label: '86 - 90',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '91To95': {
    name: '5',
    label: '91 - 95',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  '96To100': {
    name: '6',
    label: '96 - 100',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
  'More100': {
    name: '7',
    label: '> 100',
    headAttrs: TemperatureHeadAttrs,
    format: TemperatureFormat,
    bodyAttrs: getTemperatureBodyAttrs,
  },
};

const TemperatureList = [
  // Temperature['Less70'],
  Temperature['70To75'],
  Temperature['76To80'],
  Temperature['81To85'],
  Temperature['86To90'],
  Temperature['91To95'],
  Temperature['96To100'],
  Temperature['More100'],
];

const PRESSURE_COLSPAN = TemperatureList.length;

const BG_COLOR = {
  BlueCrayola: '#9ACEEB',
  YellowishGreen: '#8CCB5E',
  WhiteSmoky: '#f5f5f5',
  BlueFrostySky: '#00BFFF',
};

const ENGINE_OIL_TEMP_BG_COLOR = {
  Yellow: '#f6ff00',
  Green: '#67dd4b',
  Pink: '#f8dbdc',
  PinkRed: '#f2b4b8',
  Red: '#e98f95',
};

const Pressure = {
  'Less590': {
    label: 'Замеры давления  при оборотах ДВС менее 590 об/мин',
    name: '0',
    headAttrs: {
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  '590To640': {
    label: 'Замеры давления  при оборотах ДВС 590-640 об/мин',
    name: '1',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.BlueCrayola,
      },
      bgcolor: BG_COLOR.BlueCrayola,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  '641To690': {
    label: 'Замеры давления  при оборотах ДВС 641-690 об/мин',
    name: '2',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.YellowishGreen,
      },
      bgcolor: BG_COLOR.YellowishGreen,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  '691To740': {
    label: 'Замеры давления  при оборотах ДВС 691-740 об/мин',
    name: '3',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.BlueFrostySky,
      },
      bgcolor: BG_COLOR.BlueFrostySky,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  '741To790': {
    label: 'Замеры давления  при оборотах ДВС 741-790 об/мин',
    name: '4',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.YellowishGreen,
      },
      bgcolor: BG_COLOR.YellowishGreen,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  '791To850': {
    label: 'Замеры давления  при оборотах ДВС 791-850 об/мин',
    name: '5',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.YellowishGreen,
      },
      bgcolor: BG_COLOR.YellowishGreen,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
  'More850': {
    label: 'Замеры давления  при оборотах ДВС более 850 об/мин',
    name: '6',
    headAttrs: {
      style: {
        'background-color': BG_COLOR.YellowishGreen,
      },
      bgcolor: BG_COLOR.YellowishGreen,
      colspan: PRESSURE_COLSPAN,
    },
    children: TemperatureList,
  },
};

const ENGINE_OIL_TEMP_COLUMNS = {
  '1To60': {
    name: '60',
    label: '1-60',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Yellow,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Yellow,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '61To70': {
    name: '70',
    label: '61-70',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Yellow,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Yellow,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '71To80': {
    name: '80',
    label: '71-80',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Yellow,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Yellow,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '81To90': {
    name: '90',
    label: '81-90',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Yellow,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Yellow,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '91To100': {
    name: '100',
    label: '91-100',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Green,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Green,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '101To105': {
    name: '105',
    label: '101-105',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Green,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Green,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '106To110': {
    name: '110',
    label: '106-110',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Green,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Green,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '111To115': {
    name: '115',
    label: '111-115',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Green,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Green,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '116To120': {
    name: '120',
    label: '116-120',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Pink,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Pink,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '121To125': {
    name: '125',
    label: '121-125',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Pink,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Pink,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '126To130': {
    name: '130',
    label: '126-130',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Pink,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Pink,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '131To135': {
    name: '135',
    label: '131-135',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '136To140': {
    name: '140',
    label: '136-140',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  'More140': {
    name: '141',
    label: 'Более 140',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Red,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Red,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  'Less-31': {
    name: '-31',
    label: '(-31) и ниже',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Red,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Red,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '-30To-21': {
    name: '-21',
    label: '(-30 до -21)',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.PinkRed,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '-20To-11': {
    name: '-11',
    label: '(-20 до -11)',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Pink,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Pink,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
  '-10To0': {
    name: '0',
    label: '(-10 до 0)',
    headAttrs: {
      ...TemperatureHeadAttrs,
      style: {
        'background-color': ENGINE_OIL_TEMP_BG_COLOR.Yellow,
      },
      bgcolor: ENGINE_OIL_TEMP_BG_COLOR.Yellow,
    },
    format: EngineTemperatureFormat,
    bodyAttrs: getIntervalBodyAttrs,
  },
};

const PositiveTemperaturesList = [
  ENGINE_OIL_TEMP_COLUMNS['1To60'],
  ENGINE_OIL_TEMP_COLUMNS['61To70'],
  ENGINE_OIL_TEMP_COLUMNS['71To80'],
  ENGINE_OIL_TEMP_COLUMNS['81To90'],
  ENGINE_OIL_TEMP_COLUMNS['91To100'],
  ENGINE_OIL_TEMP_COLUMNS['101To105'],
  ENGINE_OIL_TEMP_COLUMNS['106To110'],
  ENGINE_OIL_TEMP_COLUMNS['111To115'],
  ENGINE_OIL_TEMP_COLUMNS['116To120'],
  ENGINE_OIL_TEMP_COLUMNS['121To125'],
  ENGINE_OIL_TEMP_COLUMNS['126To130'],
  ENGINE_OIL_TEMP_COLUMNS['131To135'],
  ENGINE_OIL_TEMP_COLUMNS['136To140'],
  ENGINE_OIL_TEMP_COLUMNS['More140'],
];
const NegativeTemperaturesList = [
  ENGINE_OIL_TEMP_COLUMNS['Less-31'],
  ENGINE_OIL_TEMP_COLUMNS['-30To-21'],
  ENGINE_OIL_TEMP_COLUMNS['-20To-11'],
  ENGINE_OIL_TEMP_COLUMNS['-10To0'],
];

const ENGINE_OIL_TEMP = {
  PositiveTempRange: {
    label: 'Положительный диапазон температуры масла',
    name: 'engoiltmprs',
    headAttrs: {
      colspan: 14,
    },
    children: PositiveTemperaturesList,
  },
  NegativeTempRange: {
    label: 'Отрицательный диапазон температуры масла',
    name: 'engoiltmprs',
    headAttrs: {
      colspan: 4,
    },
    children: NegativeTemperaturesList,
  },
};

const SUMMARY_INFO = {
  label: '',
  name: 'info',
  headAttrs: {
    colspan: 0, // обновляется далее исходя из SUMMARY_INFO.children.length,
  },
  children: [COLUMNS.nameOrStateNumber, COLUMNS.format, COLUMNS.canMoto],
};
SUMMARY_INFO.headAttrs.colspan = SUMMARY_INFO.children.length;

export const SUMMARY_COLUMNS = [
  SUMMARY_INFO,
  {
    ...Pressure['590To640'],
    banner: 'Построить график при оборотах ДВС 590-640 об/мин',
  },
  {
    ...Pressure['641To690'],
    banner: 'Построить график при оборотах ДВС 641-690 об/мин',
  },
  {
    ...Pressure['691To740'],
    banner: 'Построить график при оборотах ДВС 691-740 об/мин',
  },
  {
    ...Pressure['741To790'],
    banner: 'Построить график при оборотах ДВС 741-790 об/мин',
  },
  {
    ...Pressure['791To850'],
    banner: 'Построить график при оборотах ДВС 791-850 об/мин',
  },
  {
    ...Pressure['More850'],
    banner: 'Построить график при оборотах ДВС более 850 об/мин',
  },
];

const DAYS_INFO = (key: 'days' | 'detail') => {
  const options = {
    label: '',
    name: 'info',
    headAttrs: {
      colspan: 0, // обновляется далее исходя из SUMMARY_INFO.children.length,
    },
    children: [
      COLUMNS.nameOrStateNumber,
      key === 'days' ? COLUMNS.Date : COLUMNS.Time,
      COLUMNS.format,
      COLUMNS.canMoto,
    ],
  };
  options.headAttrs.colspan = options.children.length;

  return options;
};

export const DAYS_COLUMNS = [
  DAYS_INFO('days'),
  {
    ...Pressure['590To640'],
    banner: 'Построить график при оборотах ДВС 590-640 об/мин',
  },
  {
    ...Pressure['641To690'],
    banner: 'Построить график при оборотах ДВС 641-690 об/мин',
  },
  {
    ...Pressure['691To740'],
    banner: 'Построить график при оборотах ДВС 691-740 об/мин',
  },
  {
    ...Pressure['741To790'],
    banner: 'Построить график при оборотах ДВС 741-790 об/мин',
  },
  {
    ...Pressure['791To850'],
    banner: 'Построить график при оборотах ДВС 791-850 об/мин',
  },
  {
    ...Pressure['More850'],
    banner: 'Построить график при оборотах ДВС более 850 об/мин',
  },
];
export const DETAIL_COLUMNS = (motoIndex: number, key: 'days' | 'detail') => {
  let pressure;
  if (motoIndex === 1) {
    pressure = Pressure['590To640'];
  }
  if (motoIndex === 2) {
    pressure = Pressure['641To690'];
  }
  if (motoIndex === 3) {
    pressure = Pressure['691To740'];
  }
  if (motoIndex === 4) {
    pressure = Pressure['741To790'];
  }
  if (motoIndex === 5) {
    pressure = Pressure['791To850'];
  }
  if (motoIndex === 6) {
    pressure = Pressure['More850'];
  }
  return [DAYS_INFO(key), pressure];
};

const ENGINE_OIL_TEMP_SUMMARY_INFO = {
  label: '',
  name: 'info',
  headAttrs: {
    colspan: 0, // обновляется далее исходя из ENGINE_OIL_TEMP_SUMMARY_INFO.children.length,
  },
  children: [COLUMNS.nameOrStateNumber, COLUMNS.parameter, COLUMNS.engineMoto],
};
ENGINE_OIL_TEMP_SUMMARY_INFO.headAttrs.colspan =
  ENGINE_OIL_TEMP_SUMMARY_INFO.children.length;

export const ENGINE_OIL_TEMP_SUMMARY_COLUMNS = [
  ENGINE_OIL_TEMP_SUMMARY_INFO,
  ENGINE_OIL_TEMP.PositiveTempRange,
  ENGINE_OIL_TEMP.NegativeTempRange,
];

const ENGINE_OIL_TEMP_DAYS_COLUMNS_INFO = {
  label: '',
  name: 'info',
  headAttrs: {
    colspan: 0, // обновляется далее исходя из ENGINE_OIL_TEMP_DAYS_COLUMNS_INFO.children.length,
  },
  children: [
    COLUMNS.nameOrStateNumber,
    COLUMNS.Date,
    COLUMNS.parameter,
    COLUMNS.engineMoto,
  ],
};
ENGINE_OIL_TEMP_DAYS_COLUMNS_INFO.headAttrs.colspan =
  ENGINE_OIL_TEMP_DAYS_COLUMNS_INFO.children.length;

export const ENGINE_OIL_TEMP_DAYS_COLUMNS = [
  ENGINE_OIL_TEMP_DAYS_COLUMNS_INFO,
  ENGINE_OIL_TEMP.PositiveTempRange,
  ENGINE_OIL_TEMP.NegativeTempRange,
];
