import {formatTimeHelper2} from '@/helpers/main_helper'

export const shiftWorkTemplateHtml = `
<button class="not-print float-left" 
    onclick="html_to_excel_helper.export( this, 'shiftWork-template-content', 'Работа ТС посуточный', 'landscape' )">
    Экспорт&nbsp;в&nbsp;EXCEL 
</button>
<button class="not-print not-excel float-left" 
    onclick="html_to_xml_helper.exportFile( this, \`<%- xmlString %>\`, 'Работа ТС посуточный' )">
    Экспорт&nbsp;в&nbsp;XML 
</button>

<div class="temlate-report " id="shiftWork-template-content">

        <% 
            let styleTable = 'border="1" cellpadding="5" style="border-collapse:collapse; border:1px solid black; caption-side: top; margin-right: 5px;"';
            let splitTrClass = 'class="border-bottom-tr"'; 
            let styleCaption = 'style="caption-side:top;"';
            let styleCaptionPaddingNone = 'style="caption-side:top;padding:0;"';
            let smenasForDisplayText = '';
            if (smenasFlag) {
                smenasForDisplayText = 'настройка смен: 1 ('+ smenasForDisplay[0] +'), 2 ('+ smenasForDisplay[1] +'), 3 ('+ smenasForDisplay[2] +')';
            } else {
                smenasForDisplayText = 'расчет ведется по суткам (сменой считаются границы суток с 00:00:00 до 23:59:59)';
            }
        %>
    
    <div data-excelsheet="Сводный"></div>

    <h4 class="text-center footer-desc display-none"><%= reportName %> сводный</h4>
    <h4 class="text-center footer-desc not-excel margin-none"><%= reportName %></h4>
    <p class="text-center margin-none">за период с <%= formatDateHelper(new Date(ResponseInterval['timeBegin'] * 1000), 'dd.mm.yyyy hh:nn') 
                + ' по ' + formatDateHelper(new Date(ResponseInterval['timeEnd'] * 1000), 'dd.mm.yyyy hh:nn') %>, <%= smenasForDisplayText %>. Отчет сформирован ${formatTimeHelper2(new Date())}</p>

    <!-- <h6 class="not-excel">Сводная таблица</h6> -->

    <table class="text-align-center" data-excelautofilter <%= styleTable %> >

        <caption class="not-excel" <%= styleCaptionPaddingNone %>>Сводная таблица</caption>
        <thead>
            <tr class="border-bottom-tr print-repeat print-frozen-row" >
                <% 
                    let table = webSummTable;
                    let colNamesArr = webSummSetting;
                    let cols = colNamesArr.length;
                    let hName;
                    let vAlign;
                    let hAlign;
                %>

                <% for (let c = 0; c < colNamesArr.length; c++) {
                    // выгрузим шапку
                    hName = colNamesArr[c];
                    if (hName in table['header']) {
                        textName = table['header'][hName]['name'] || hName;
                        colWidth = table['header'][hName]['width'] || 0;
                    } else {
                        textName = hName;
                        colWidth = 0;
                    }
                    let colWidthHtml = !colWidth ? '' : ' style="min-width:'+Math.round(colWidth*8.7)+'px;"';
                %>
                    <th data-minwidth="<%= colWidth %>"<%= colWidthHtml %> data-excelalignh="center" data-excelalignv="middle"><%= textName %></th>
                <% } %>
            </tr>
        </thead>

        <tbody>
                <%
                    let forSort = {
                        isSortByBegin : true,
                        sortBy : '',
                        sortByPrev : '',
                        category : '',
                        categoryPrev : '',
                        sortByArrName : sortByUnit ? 'unit' : 'techType',
                        rowNums : [] // для выравнивания
                    };
                    let objId = -1;
                    let errorWraps = [];
                    let row_num = 0;
                %>
            
                <%
                    let isGroupSplitSortBy = true;
                    // выгрузим значения
                    for (let rg = 0; rg < table['rowsGroup'].length; rg++) {

                        objId = -1;
                        if ( (0 in table['rowsGroup'][rg]) && ('id' in table['rowsGroup'][rg][0]) ) {
                            objId = table['rowsGroup'][rg][0]['id']['val'];
                        }

                        if (isGroupSplitSortBy && (0 in table['rowsGroup'][rg])) {
                            // вывод строк - разделителей для суммарного листа
                            forSort.category = table['rowsGroup'][rg][0]['category']['val'];
                            forSort.sortBy = table['rowsGroup'][rg][0][forSort.sortByArrName]['val'];
                            let isTotalGroup = ('isTotal' in table['rowsGroup'][rg][0]);

                            if ( isTotalGroup || (isSortBy && (forSort.sortBy != forSort.sortByPrev || ! rg)) ) {
                                if (! isTotalGroup) {
                                    forSort.isSortByBegin = true;
                                }
                                // строка - разделитель
                                for (let sc = 0; sc < cols; sc++) {
                                    if (! sc) {
                                        %><tr><td class="text-align-left font-weight-bold" bgcolor="#D3D3D3" class="padding-none padding-left-5"><b><%=isTotalGroup ? '' : forSort.sortBy%></b></td><%
                                        continue;
                                    } 
                                    %><td bgcolor="#D3D3D3"></td><%
                                }
                                %></tr><%
                                row_num++;
                                // forSort.rowNums.push(row_num);

                                // wsup.Cell(++row_num, 1).Value = isTotalGroup ? '' : forSort.sortBy;
                                // wsup.Cell(row_num, 1).Style.Font.Bold = true;
                                // wsup.Range_4(row_num, 1, row_num, col_num).Style.Fill.BackgroundColor = svc.ColorFromName('lightgray'); //#D3D3D3
                                // forSort.rowNums.push(row_num);
                            }

                            if (! isTotalGroup && (forSort.isSortByBegin || forSort.category != forSort.categoryPrev) ) {
                                forSort.isSortByBegin = false;
                                
                                for (let sc = 0; sc < cols; sc++) {
                                    if (! sc) {
                                        %><tr><td class="text-align-left font-weight-bold" bgcolor="#E9E9E9" class="padding-none padding-left-5"><b><%= forSort.category %></b></td><%
                                        continue;
                                    } 
                                    %><td bgcolor="#E9E9E9"></td><%
                                }
                                row_num++;
                                %></tr><%
                                // wsup.Cell(++row_num, 1).Value = forSort.category;
                                // wsup.Range_4(row_num, 1, row_num, col_num).Style.Fill.BackgroundColor = svc.ColorFromArgb(0.5, 0xE9, 0xE9, 0xE9); //#E9E9E9
                            }
                            // row_num++;

                            forSort.categoryPrev = forSort.category;
                            forSort.sortByPrev = forSort.sortBy;
                        }

                        let rowGroupBegin = row_num + 1;


                        for (let r = 0; r < table['rowsGroup'][rg].length; r++) {
                            row = table['rowsGroup'][rg][r];
                            row_num++;
                            col_num = 0;

                            let isError = false;
                            if ('isError' in row) {
                                isError = row['isError']['val'];
                            }

                            let outlineLevel = ! r ? '' : ' data-exceloutlinelevel="1"';
                            // bgColor = ! r ? '' : ' bgcolor="#EEECE1"';
                            bgColor = ! r ? '' : ' bgcolor="#F6F6F6"';

                            %><tr <%=outlineLevel%>><%
                            for (let c = 0; c < colNamesArr.length; c++) {
                                hName = colNamesArr[c];
                                format = 0;
                                hAlign = 1;// по центру
                                vAlign = 0;// по центру
                                if (hName in table['header']) {
                                    format = table['header'][hName]['format'] || 0;
                                    vAlign = table['header'][hName]['vAlign'] || 1;
                                    hAlign = table['header'][hName]['hAlign'] || 0;
                                }
                                if (hName in row) {
                                    val = row[hName]['val'];
                                    isbold = row[hName]['isbold'] || false;
                                    color = row[hName]['color'] || false;
                                } else {
                                    val = '?';
                                    isbold = false;
                                    color = false;
                                }
                                let valToDisplay = valueToDisplayFormat_helper(val, format, false);
                                let data_formatNum_value = addFormatAndValAttributesFromLodash_helper(format, val);
                                let hAlignText = html_to_excel_helper.getTextAlignH(hAlign);
                                let vAlignText = html_to_excel_helper.getTextAlignH(vAlign);
                                let hAlignHtml = ! hAlignText ? '' : ' data-excelalignh="'+hAlignText+'"';
                                let vAlignHtml = ! vAlignText ? '' : ' data-excelalignv="'+vAlignText+'"';
                                let alignLeftHtml = hAlignText == 'left' ? ' class="text-align-left" ' : '';
                                %><td <%=alignLeftHtml+data_formatNum_value+hAlignHtml+vAlignHtml+bgColor%>><%= valToDisplay %></td><%

                                // if (! r && linkColArrName && wsLink && hName == linkColArrName && objId > -1 && (objId in objectsRowsForLink)) {
                                    // можно создать гиперссылки на гос номер
                                    // let linkRow = objectsRowsForLink[objId]['r'];
                                    // let linkCol = objectsRowsForLink[objId]['c'];
                                    // wsup.Cell(row_num, col_num).Hyperlink = svc.GetHyperlink('\'' + wsLink.Name + '\'!' + numberToLetterColumn(linkCol) + linkRow, '');
                                    // wsLink.Cell(linkRow, linkCol).Hyperlink = svc.GetHyperlink('\'' + wsup.Name + '\'!' + numberToLetterColumn(col_num) + row_num, '');
                                // }

                                // if (! r && linkColArrName && wsLink && hName == linkColArrName && objId > -1 && ! (objId in objectsRowsForLink)) {
                                //     objectsRowsForLink[objId] = {r : row_num, c : col_num};
                                // }

                                //if (isError && (hName in isError)) {
                                //    errorWraps.push({r : row_num, c : col_num});
                                //}
                            }
                            %></tr><%
                        }

                        //if (isExcelRowsGroup && row_num > rowGroupBegin) {
                            // группировка строк
                            // wsup.Rows_3(rowGroupBegin + 1, row_num).Group();
                        //}

                        
                    }
                %>
            

        </tbody>


    </table>



    <div data-excelsheet="Детализация"></div>

    <h4 class="text-center footer-desc display-none"><%= reportName %> детализация</h4>
    <p class="text-center display-none">за период с <%= formatDateHelper(new Date(ResponseInterval['timeBegin'] * 1000), 'dd.mm.yyyy hh:nn') + ' по ' + formatDateHelper(new Date(ResponseInterval['timeEnd'] * 1000), 'dd.mm.yyyy hh:nn') %>,
    настройка смен: 1 (<%= smenasForDisplay[0] %>), 2 (<%= smenasForDisplay[1] %>), 3 (<%= smenasForDisplay[2] %>)</p>

    <!-- <h6 class="not-excel">Детализация</h6> -->

    <% if (typeof webDetailTable !== 'undefined') { %>

        <table class="text-align-center" data-excelautofilter <%= styleTable %>>
            <caption class="not-excel" <%= styleCaption %>>Детализация</caption>
            <thead>
                <tr class="border-bottom-tr print-repeat print-frozen-row" >
                    <% 
                        table = webDetailTable;
                        colNamesArr = webDetailSetting;
                        cols = colNamesArr.length;
                    %>

                    <% for (let c = 0; c < colNamesArr.length; c++) {
                        // выгрузим шапку
                        hName = colNamesArr[c];
                        if (hName in table['header']) {
                            textName = table['header'][hName]['name'] || hName;
                            colWidth = table['header'][hName]['width'] || 0;
                        } else {
                            textName = hName;
                            colWidth = 0;
                        }
                        let colWidthHtml = !colWidth ? '' : ' style="min-width:'+Math.round(colWidth*8.7)+'px;"';
                    %>
                        <th data-minwidth="<%= colWidth %>"<%= colWidthHtml %> data-excelalignh="center"  data-excelalignv="middle"><%= textName %></th>
                    <% } %>
                </tr>
            </thead>

            <tbody>
                    <%
                        forSort = {
                            isSortByBegin : true,
                            sortBy : '',
                            sortByPrev : '',
                            category : '',
                            categoryPrev : '',
                            sortByArrName : sortByUnit ? 'unit' : 'techType',
                            rowNums : [] // для выравнивания
                        };
                        objId = -1;
                        errorWraps = [];
                        row_num = 0;
                    %>
                
                    <%
                        // выгрузим значения
                        for (let rg = 0; rg < table['rowsGroup'].length; rg++) {

                            let bgColor = '';
                            if (rg % 2) {
                                // wsup.Range_4(rowGroupBegin, 1, row_num, col_num).Style.Fill.BackgroundColor = groupSplitColor;
                                bgColor = ' bgcolor="#EEECE1"';
                            }
                            
                            objId = -1;
                            if ( (0 in table['rowsGroup'][rg]) && ('id' in table['rowsGroup'][rg][0]) ) {
                                objId = table['rowsGroup'][rg][0]['id']['val'];
                            }

                            let rowGroupBegin = row_num + 1;

                            for (let r = 0; r < table['rowsGroup'][rg].length; r++) {
                                row = table['rowsGroup'][rg][r];
                                row_num++;
                                col_num = 0;

                                let isError = false;
                                if ('isError' in row) {
                                    isError = row['isError']['val'];
                                }

                                %><tr><%
                                for (let c = 0; c < colNamesArr.length; c++) {
                                    hName = colNamesArr[c];
                                    format = 0;
                                    hAlign = 1;// по центру
                                    vAlign = 0;// по центру
                                    if (hName in table['header']) {
                                        format = table['header'][hName]['format'] || 0;
                                        vAlign = table['header'][hName]['vAlign'] || 1;
                                        hAlign = table['header'][hName]['hAlign'] || 0;
                                    }
                                    if (hName in row) {
                                        val = row[hName]['val'];
                                        isbold = row[hName]['isbold'] || false;
                                        color = row[hName]['color'] || false;
                                    } else {
                                        val = '?';
                                        isbold = false;
                                        color = false;
                                    }
                                    let valToDisplay = valueToDisplayFormat_helper(val, format, false, hName);
                                    let data_formatNum_value = addFormatAndValAttributesFromLodash_helper(format, val);
                                    let hAlignText = html_to_excel_helper.getTextAlignH(hAlign);
                                    let vAlignText = html_to_excel_helper.getTextAlignH(vAlign);
                                    let hAlignHtml = ! hAlignText ? '' : ' data-excelalignh="'+hAlignText+'"';
                                    let vAlignHtml = ! vAlignText ? '' : ' data-excelalignv="'+vAlignText+'"';
                                    let alignLeftHtml = hAlignText == 'left' ? ' class="text-align-left" ' : '';
                                    %><td <%=alignLeftHtml+data_formatNum_value+bgColor+hAlignHtml+vAlignHtml%>><%= valToDisplay %></td><%
                                    // formatToDisplay (wsup, val, format, row_num, ++col_num, color, isbold);

                                    // if (! r && linkColArrName && wsLink && hName == linkColArrName && objId > -1 && (objId in objectsRowsForLink)) {
                                        // можно создать гиперссылки на гос номер
                                        // let linkRow = objectsRowsForLink[objId]['r'];
                                        // let linkCol = objectsRowsForLink[objId]['c'];
                                        // wsup.Cell(row_num, col_num).Hyperlink = svc.GetHyperlink('\'' + wsLink.Name + '\'!' + numberToLetterColumn(linkCol) + linkRow, '');
                                        // wsLink.Cell(linkRow, linkCol).Hyperlink = svc.GetHyperlink('\'' + wsup.Name + '\'!' + numberToLetterColumn(col_num) + row_num, '');
                                    // }

                                    // if (! r && linkColArrName && wsLink && hName == linkColArrName && objId > -1 && ! (objId in objectsRowsForLink)) {
                                    //     objectsRowsForLink[objId] = {r : row_num, c : col_num};
                                    // }

                                    //if (isError && (hName in isError)) {
                                    //    errorWraps.push({r : row_num, c : col_num});
                                    //}
                                }
                                %></tr><%
                            }

                            //if (isExcelRowsGroup && row_num > rowGroupBegin) {
                                // группировка строк
                                // wsup.Rows_3(rowGroupBegin + 1, row_num).Group();
                            //}

                            //if (groupSplitColor && rg % 2 && col_num) {
                                // wsup.Range_4(rowGroupBegin, 1, row_num, col_num).Style.Fill.BackgroundColor = groupSplitColor;
                            //}
                        }
                    %>

            </tbody>

        </table>

    <% } %>

</div>

`;
