<template>
  <div class="custom-time-picker-component">
    <div>
      <select
        class="select-input"
        v-model="hoursSelect"
      >
        <option
          v-for="h in hoursArray"
          :key="'h1-' + h.value"
          :value="h.value"
          :selected="h.value === hours[1]"
        >
          {{ h.text }}
        </option>
      </select>
      <select
        class="select-input"
        v-model="minutesSelect"
      >
        <option
          v-for="m in minutesArray"
          :key="'m1-' + m.value"
          :value="m.value"
          :selected="m.value === minutes[0]"
        >
          {{ m.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from '@vue/runtime-core';

const props = defineProps({
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  is24: { type: Boolean, default: true },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  range: { type: Boolean, default: false },
  filters: { type: Object, default: () => ({}) },
  minTime: { type: Object, default: () => ({}) },
  maxTime: { type: Object, default: () => ({}) },
  timePicker: { type: Boolean, default: false },
  hours: { type: [Number, Array] },
  minutes: { type: [Number, Array] },
  customProps: { type: Object, default: null },
});

const emit = defineEmits(['update:hours', 'update:minutes']);

const hoursSelect = ref(0);
const minutesSelect = ref(0);

watch(
  () => props.hours,
  (val) => {
    if (val !== hoursSelect.value) {
      hoursSelect.value = val;
    }
  },
  { immediate: true },
);
watch(
  () => props.minutes,
  (val) => {
    if (val !== minutesSelect.value) {
      minutesSelect.value = val;
    }
  },
  { immediate: true },
);

const emitAll = () => {
  emit('update:hours', hoursSelect.value);
  emit('update:minutes', minutesSelect.value);
};

watch(hoursSelect, emitAll);
watch(minutesSelect, emitAll);

const hoursArray = computed(() => {
  const arr = [];
  for (let h = 0; h < 24; h++) {
    arr.push({ text: h < 10 ? `0${h}` : h, value: h });
  }
  return arr;
});

const minutesArray = computed(() => {
  const arr = [];
  for (let m = 0; m < 60; m++) {
    if (m === 0 || m % 10 === 0) {
      arr.push({ text: m < 10 ? `0${m}` : m, value: m });
    }
  }
  return arr;
});
</script>

<style lang="scss" scoped></style>
