<template>
  <div
    id="diagnostic-section-reports"
    class="row"
  >
    Это секция выгрузки суммарных отчетов
  </div>
</template>

<style lang="scss" scoped>
#diagnostic-section-reports {
  overflow: auto;
  max-width: 100vw;
}
</style>
