<template>
  <div class="modules-list__container">
    <div class="modules-list__list">
      <ratio-out-line
        class="modules-list__item"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :data="data"
        :objects="objects"
        :size="listItem"
        :modal="modal"
        @showModal="(name) => showModal(name)"
        @closeModal="(showModalStr) => closeModal(showModalStr)"
      />

      <ui-module
        class="modules-list__item"
        :style="{ width: listItem.width, height: listItem.height }"
      >
        <template #title> Тонно километр </template>
        <template #body> - </template>
      </ui-module>

      <ratio-use-distance
        class="modules-list__item"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :data="data"
        :objects="objects"
        :size="listItem"
        :modal="modal"
        @showModal="(name) => showModal(name)"
        @closeModal="(showModalStr) => closeModal(showModalStr)"
      />

      <ui-module
        class="modules-list__item"
        :style="{ width: listItem.width, height: listItem.height }"
      >
        <template #title> Тонно км. на <br />1 ед. в среднем </template>
        <template #body> - </template>
      </ui-module>

      <all-fuel-liters
        class="modules-list__item"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :data="data"
        :objects="objects"
        :size="listItem"
        :modal="modal"
        @showModal="(name) => showModal(name)"
        @closeModal="(showModalStr) => closeModal(showModalStr)"
      />

      <can-expence-fuel-liters-on-100-km
        class="modules-list__item"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :data="data"
        :objects="objects"
        :size="listItem"
        :modal="modal"
        @showModal="(name) => showModal(name)"
        @closeModal="(showModalStr) => closeModal(showModalStr)"
      />
    </div>
  </div>
</template>

<script>
import RatioOutLine from './VModulesComponents/RatioOutLine.vue';
import UiModule from './VModulesComponents/UiModule.vue';
import CanExpenceFuelLitersOn100Km from './VModulesComponents/CanExpenceFuelLitersOn100Km.vue';
import AllFuelLiters from './VModulesComponents/AllFuelLiters.vue';
import RatioUseDistance from './VModulesComponents/ratioUseDistance.vue';

export default {
  components: {
    RatioOutLine,
    UiModule,
    CanExpenceFuelLitersOn100Km,
    AllFuelLiters,
    RatioUseDistance,
  },
  name: 'VModulesList',
  props: {
    clientWidth: {
      type: Number,
      required: true,
    },
    clientHeight: {
      type: Number,
      required: true,
    },
    objects: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loadingProcess: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      // list: { width: 0, height: 0 },
      listItem: { width: 0, height: 0 },

      modal: '',
    };
  },
  watch: {
    clientHeight: {
      handler(newClientHeight) {
        // const onePercentHeight = newClientHeight / 100;
        // if (newClientHeight < 768) {
        // this.list.height = '100%';
        //   // this.list.height = onePercentHeight * 100 - 197 + 'px';
        // } else if (newClientHeight < 1024) {
        //   this.list.height = onePercentHeight * 100 - 129 + 'px';
        // } else if (newClientHeight > 1024) {
        //   this.list.height = onePercentHeight * 100 - 74 + 'px';
        // }
      },
      immediate: true,
    },
    clientWidth: {
      handler(newClientWidth) {
        const onePercentWidth = newClientWidth / 100;
        if (newClientWidth > 0) {
          this.listItem.width = onePercentWidth * 50 - 20 + 'px';
          this.listItem.height = this.listItem.width;
        }
        if (newClientWidth > 450) {
          this.listItem.width = onePercentWidth * 33.333 - 30 + 'px';
          this.listItem.height = this.listItem.width;
        }
        if (newClientWidth > 1024) {
          this.listItem.width = onePercentWidth * 11 - 12 + 'px';
          this.listItem.height = this.listItem.width;
        }
        if (newClientWidth > 1260) {
          this.listItem.width = onePercentWidth * 11 - 20 + 'px';
          this.listItem.height = this.listItem.width;
        }
      },
      immediate: true,
    },
  },
  methods: {
    showModal(modalName) {
      if (!!Object.values(this.objects).length) {
        this.modal = modalName;
      }
    },
    closeModal(showModalStr) {
      this.modal = showModalStr;
    },
  },
};
</script>

<style lang="scss">
.modules-list__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 35px);
  @media (max-width: 768px) {
    max-height: calc(100vh - 115px);
  }
}
.modules-list__list {
  // overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  height: 100%;
}
.modules-list__item {
  background: #44cbf6;
  border-radius: 50%;

  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: 0px 4px 8px 3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px 3px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px 3px rgba(34, 60, 80, 0.2);
  }
}
</style>
