import { TrackPlayerMotion } from './trackPlayerMotion';

export class TrackPlayerPanel {
  leafletMain;
  globalObjects;
  wrapper;
  element;
  vue;
  opened;
  positionsList;
  items = {};

  constructor({
    buttonId,
    positionsListId,
    DragElement_helper,
    leafletMain,
    globalObjects,
  } = {}) {
    // const wrapper = element.closest('.block-draggable');
    const wrapper = this.addPanel();
    document.body.append(wrapper);

    const subElements = this.getSubElements(wrapper);
    const {
      trackPlayerContainer: element,
      trackPlayerClose: closeElem,
      trackPlayerSetting: settingElem,
      trackPlayerSettingOpacity,
      trackPlayerSettingClose,
      trackPlayerSettingShowValues,
    } = subElements;

    this.dragElement_helper = new DragElement_helper(wrapper);
    closeElem.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.closeEvent,
    });
    settingElem.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.settingEvent,
    });
    trackPlayerSettingClose.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.settingEvent,
    });
    trackPlayerSettingOpacity.addEventListener('change', {
      that: this,
      handleEvent: this.changeOpasityEvent,
    });
    trackPlayerSettingShowValues.addEventListener('change', {
      that: this,
      handleEvent: this.changeShowValuesEvent,
    });

    const buttonOpen = document.getElementById(buttonId);
    buttonOpen.addEventListener('pointerdown', {
      that: this,
      handleEvent: this.openEventWrapper,
    });
    this.buttonOpen = buttonOpen;

    this.element = element;
    this.closeElem = closeElem;
    this.wrapper = wrapper;

    this.leafletMain = leafletMain;
    this.globalObjects = globalObjects;

    this.positionsList = document.getElementById(positionsListId);

    this.subElements = subElements;
  }

  addPanel() {
    const wrap = document.createElement('div');
    wrap.innerHTML = this.panelTemplate();
    return wrap.firstElementChild;
  }

  closeEvent() {
    this.that.wrapper.style.display = 'none';

    this.that.setThePauseAllPlayers();
    this.that.removeMarkersFromTheMap();
  }

  settingEvent() {
    this.that.subElements.trackPlayerSettingContainer.classList.toggle(
      'active',
    );
  }

  changeOpasityEvent(event) {
    const value = parseInt(event.target.value);
    const opacity = 100 - value;
    this.that.wrapper.style.opacity = `${opacity}%`;
    this.that.subElements.trackPlayerSettingOpacityValue.innerText = `${value} %`;
  }

  changeShowValuesEvent(event) {
    if (event.target.checked) {
      this.that.subElements.trackPlayerContainer.classList.remove(
        'without-values',
      );
    } else {
      this.that.subElements.trackPlayerContainer.classList.add(
        'without-values',
      );
    }
  }

  openEventWrapper() {
    this.that.openEvent();
  }

  openEvent() {
    const wrapper = this.wrapper;

    if (!this.opened) {
      this.opened = true;

      const zoom = document.querySelector('.leaflet-control-zoom-out');
      const toggle = document.querySelector('.leaflet-control-layers-toggle');

      const { x: zoomX, width: zoomW } = zoom.getBoundingClientRect();
      const {
        x: tgX,
        y: tgY,
        height: tgH,
        width: tgW,
      } = toggle.getBoundingClientRect();

      const clientWidth = document.documentElement.clientWidth;

      const wrapperLeft = zoomX + zoomW + 5;
      const wrapperWidth =
        clientWidth > 600 ? tgX - 5 - wrapperLeft : tgX + tgW - wrapperLeft;

      const wrapperWidthPercent =
        Math.floor((1000 * wrapperWidth) / clientWidth) / 10;

      wrapper.style.left = `${wrapperLeft}px`;
      // wrapper.style.top = `${tgY + tgH + 5}px`;
      wrapper.style.top = `${tgY}px`;
      wrapper.style.width = `${wrapperWidthPercent}vw`;
    }

    const n = this.positionsList.options.selectedIndex;
    const id = this.positionsList.options[n].value;
 
    if (id !== 'none' && !this.wrapper.querySelector(`[data-id="${id}"]`) && this.globalObjects.globalPositions[id].points.latlngs.length > 0) {
      const {
        objName = 'н/у',
        stateNumber = 'н/у',
        points,
        trackBegin = '?',
        trackEnd = '?',
        getBegin,
        getEnd,
      } = this.globalObjects.globalPositions[id] || {};
      const wrap = document.createElement('div');
      wrap.innerHTML = this.playerTemplate({
        id,
        objName,
        stateNumber,
        trackBegin,
        trackEnd,
      });
      const newItem = wrap.firstElementChild;

      const trackMotion = new TrackPlayerMotion({
        panel: newItem,
        points,
        leafletMain: this.leafletMain,
        objName,
        stateNumber,
        getBegin,
        getEnd,
      });
      const removeButton = newItem.querySelector('.track-player-remove-button');
      
      this.items[id] = {
        trackMotion,
        removeButton,
      };

      this.element.append(newItem);
      removeButton.addEventListener('pointerdown', {
        that: this,
        handleEvent: this.removeItemEvent,
      });
    }

    wrapper.style.display = 'block';

    this.addMarkersToTheMap();
  }

  addMarkersToTheMap() {
    for (let id in this.items) {
      if (this.items[id].trackMotion && this.items[id].trackMotion.marker) {
        this.items[id].trackMotion.marker.addTo(this.leafletMain.map);
      }
    }
  }

  removeMarkersFromTheMap() {
    for (let id in this.items) {
      if (this.items[id].trackMotion && this.items[id].trackMotion.marker) {
        this.items[id].trackMotion.marker.remove();
      }
    }
  }

  setThePauseAllPlayers() {
    for (let id in this.items) {
      if (this.items[id].trackMotion) {
        this.items[id].trackMotion.pauseEvent.call({
          that: this.items[id].trackMotion,
        });
      }
    }
  }

  removeItemEvent(event) {
    const item = event.target.closest('.track-player-item');
    this.that.wrapper.style.width = `${item.clientWidth}px`;
    const id = item.dataset.id;
    const { trackMotion, removeButton } = this.that.items[id];
    removeButton.removeEventListener('pointerdown', {
      that: this.that,
      handleEvent: this.that.removeItemEvent,
    });
    trackMotion.destroy();
    item.remove();
    this.that.items[id] = null;
    delete this.that.items[id];
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }

  playerTemplate({ id, objName, stateNumber, trackBegin, trackEnd }) {
    return `
        <div class="track-player-item" data-id="${id}">
            <div class="track-player-header">
                ${objName} | ${stateNumber} | ${trackBegin} - ${trackEnd}
            </div>
            <div class="track-player-panel">
                <select data-element="speedOptions" name="speed">
                    <option value="100">x100</option>
                    <option value="200">x200</option>
                    <option value="400">x400</option>
                    <option value="600">x600</option>
                    <option value="800">x800</option>
                    <option value="1000">x1000</option>
                    <option value="0s">max</option>
                    <option value="5s">5сек</option>
                    <option value="10s">10сек</option>
                    <option value="15s">15сек</option>
                    <option value="20s">20сек</option>
                    <option value="step">1 шаг</option>
                    <option value="1">x1</option>
                    <option value="10">x10</option>
                </select>
                <span data-element="playBackButton" class="track-player-button"><img src="/images/track_player/playBack-button.png" alt="назад" title="Назад"></span>
                <span data-element="pauseButton" class="track-player-button"><img src="/images/track_player/pause-button.png" alt="пауза" title="Пауза"></span>
                <span data-element="playButton" class="track-player-button"><img src="/images/track_player/play-button.png" alt="вперед" title="Вперед"></span>
                <div class="track-player-scroll-bar">
                  <input style="cursor:pointer" type="range" class="track-player-inner-scale" data-element="playerSlider"/>
                </div>
                <span data-element="stopButton" class="track-player-stop-button"><img src="/images/track_player/stop-button.png" alt="стоп" title="Стоп"></span>
                <span class="track-player-remove-button" class="track-player-button"><img height="22px" src="/images/track_player/remove-button.png" alt="удалить" title="Удалить"></span>
            </div>
            <div class="track-player-values">
                <div class="track-player-values-item" title="Дата и время"><img src="/images/track_player/valuesTime.png" alt="Time"><span data-element="trackPlayerValueTime">-</span></div>
                <div class="track-player-values-item" title="Скорость"><img src="/images/track_player/valuesSpeed.png" alt="Speed"><span data-element="trackPlayerValueSpeed">-</span><span data-element="trackPlayerValueSpeedGspdIndicator" title="Значение скорости берется из gps">gps</span></div>
                <div class="track-player-values-item" title="Максимальная скорость от начала запроса"><img src="/images/track_player/valuesMaximumSpeed.png" alt="MaxSpeed"><span data-element="trackPlayerValueMaxSpeed">-</span></div>
                <div class="track-player-values-item" title="Кол-во нарушений"><img src="/images/track_player/wrong.png" alt="ViolationsCount"><span data-element="trackPlayerViolationsCount">-</span></div>
                <div hidden class="track-player-values-item" title="Сигнал спутников GPS/ГЛОНАСС"><img src="/images/track_player/valuesSatellite.png" alt="GPS"><span data-element="trackPlayerValueGps">-</span></div>
                <div class="track-player-values-item" title="Широта (latitude)"><img src="/images/track_player/valuesLat.png" alt="Lat"><span data-element="trackPlayerValueLat">-</span></div>
                <div class="track-player-values-item last-child" title="Долгота (longtitude)"><img src="/images/track_player/valuesLong.png" alt="Lon"><span data-element="trackPlayerValueLon">-</span></div>
            </div>
            
        </div>
        `;
  }

  panelTemplate() {
    return `
        <div class="block-draggable display-none" id="track-player-wrapper">
            <div class="block-draggable-header">Плеер треков
                <img src="/images/track_player/setting.svg" data-element="trackPlayerSetting" id="track-player-setting" alt="settings" width="24px" height="24px">
                <img src="/images/cancel.svg" data-element="trackPlayerClose" id="track-player-close" alt="close" width="26px" height="26px">
            </div>
            <div data-element="trackPlayerSettingContainer" class="track-player-setting-container">
                <label for="track-player-setting-opacity">Прозрачность</label>
                <input style="cursor:pointer" data-element="trackPlayerSettingOpacity" type="range" min="0" max="80" step="1" value="0" id="track-player-setting-opacity">
                <span data-element="trackPlayerSettingOpacityValue">0 %</span>
                <span><label><input data-element="trackPlayerSettingShowValues" type="checkbox" checked>отображать значения в позиции</label></span>
                <span data-element="trackPlayerSettingClose"><img height="22px" src="/images/track_player/remove-button.png" alt="закрыть"></span>
            </div>
            <div data-element="trackPlayerContainer" id="track-player-container"></div>
        </div>
        `;
  }

  destroy() {
    this.wrapper.remove();
    this.leafletMain = null;
    this.globalObjects = null;
    this.wrapper = null;
    this.element = null;
    this.vue = null;
    this.opened = null;
    this.positionsList = null;
    this.items = {};
  }
}
