interface IValidator {
  [key: string]: boolean;
}

export const useValidator = {
  required: (val: any): boolean => !!val,
  requiredArray: (val: any[]): boolean => !!val.length,
  requiredObject: (val: object): boolean => !!Object.keys(val).length,
  requiredFile: (val: File): boolean => !!val.name,

  email: (email: string): boolean => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  },
  numeric: (n: number): boolean => !isNaN(n),

  maxLength: (num: number) => {
    return (val: any[] | string): boolean => val.length <= num;
  },
  minLength: (num: number) => {
    return (val: any[] | string): boolean => val.length >= num;
  },
};
