<template>
  <div
    class="temlate-report"
    id="skills-man-template-content"
  >
    <template v-if="props.isTitle">
      <h6 class="text-center font-weight-bold">
        Оценка эффективности эксплуатации по парку ТС
        {{ props.data.objects[0]['view']['t_interval'] }}
      </h6>

      <p class="text-center excel-bottom-indent">
        {{ props.data.objects[0]['view']['smenasStr'] }}
      </p>
    </template>

    <hr class="not-print margin-none" />

    <table
      class="skills-man-report__table"
      border="1"
      cellpadding="5"
    >
      <caption style="caption-side: top">
        Суммарные показатели
      </caption>
      <thead>
        <tr class="text-center">
          <template v-for="row in props.data.rows_summ['settingsSumm']">
            <template
              v-if="skillsManReport_format.colsNoSpanArr.indexOf(row) !== -1"
            >
              <th
                v-if="row === skillsManReport_format.colsNoSpanArr[0]"
                data-excelalignv="middle"
                :colspan="skillsManReport_format.colsNoSpanArr.length"
                data-excelalignh="center"
              >
                Нарушения по категориям эксплуатации ТС
              </th>
            </template>
            <th
              v-else
              data-excelalignv="middle"
              rowspan="2"
              :data-minwidth="props.data.rows_summ['header'][row]['minWidth']"
              data-excelalignh="center"
            >
              {{ props.data.rows_summ['header'][row]['name'] }}
            </th>
          </template>
        </tr>
        <tr class="text-center">
          <template v-for="row in props.data.rows_summ['settingsSumm']">
            <th
              v-if="skillsManReport_format.colsNoSpanArr.indexOf(row) !== -1"
              data-excelalignv="middle"
              data-excelalignh="center"
              :data-minwidth="props.data.rows_summ['header'][row]['minWidth']"
            >
              {{ props.data.rows_summ['header'][row]['name'] }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(line, i) in props.data.rows_summ['lines'][0]">
          <template v-for="row in props.data.rows_summ['settingsSumm']">
            <td
              :data-formatnum="props.data.rows_summ['header'][row]['format']"
              :data-value="
                getDataValueAttribute(
                  props.data.rows_summ['header'][row]['format'],
                  line[row],
                )
              "
              data-value
              class="text-center"
              data-excelalignh="center"
            >
              <template v-if="!line['notData'] && row == 'DAvtoNo'">
                <a
                  class="anchor-href"
                  :href="`#object-${i}`"
                  v-html="
                    getFormatData(
                      line[row],
                      props.data.rows_summ['header'][row]['format'],
                    )
                  "
                >
                </a>
                <a :name="`summ-${i}`"></a>
              </template>
              <span
                v-else
                v-html="
                  getFormatData(
                    line[row],
                    props.data.rows_summ['header'][row]['format'],
                  )
                "
              ></span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <template v-for="object in props.data.objects">
      <template v-if="!object['notData']">
        <hr class="not-print excel-bottom-indent margin-bottom-small" />
        <div class="page-break-after"></div>

        <table
          border="1"
          cellpadding="5"
          class="skills-man-report__table"
        >
          <caption style="caption-side: top">
            <a name="object-0"></a>
            <a
              class="not-print anchor-href"
              href="#summ-0"
            >
              в таблицу "Суммарные показатели"</a
            ><br />
            <span
              v-html="object['view']['t_name'] + object['view']['t_interval']"
            >
            </span>
          </caption>
          <thead>
            <tr class="text-center">
              <th
                v-for="column in skillsManReport_format.detailColumns"
                data-excelalignv="middle"
                data-excelalignh="center"
                :data-excelcolspan="
                  object['rows_person']['header'][column]['colspan']
                "
                :data-minheight="
                  object['rows_person']['header'][column]['name'].length > 20
                    ? 30
                    : ''
                "
              >
                {{ object['rows_person']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="personForDisplayValue in object['rows_person'][
                'personForDisplay'
              ]"
            >
              <tr v-for="(personValue, personIndex) in personForDisplayValue">
                <template
                  v-for="(
                    column, columnIndex
                  ) in skillsManReport_format.detailColumns"
                >
                  <td
                    v-if="personIndex > 0"
                    :class="[
                      {
                        'text-danger':
                          object['rows_person']['person'][personValue][
                            'DCount'
                          ] &&
                          object['rows_person']['person'][personValue][
                            'IsRoughly'
                          ],
                      },
                      { 'text-center': columnIndex > 1 },
                    ]"
                    :data-minheight="
                      object['rows_person']['person'][personValue][column]
                        .length > 61
                        ? 30
                        : ''
                    "
                    :data-formatnum="
                      object['rows_person']['header'][column]['format']
                    "
                    :data-value="
                      getDataValueAttribute(
                        object['rows_person']['header'][column]['format'],
                        object['rows_person']['person'][personValue][column],
                      )
                    "
                    :data-excelalignh="columnIndex > 1 ? 'center' : ''"
                    :data-excelcolspan="
                      object['rows_person']['header'][column]['colspan']
                    "
                  >
                    {{
                      getFormatData(
                        object['rows_person']['person'][personValue][column],
                        object['rows_person']['header'][column]['format'],
                      )
                    }}
                  </td>
                  <template v-else>
                    <td
                      v-if="columnIndex === 1"
                      colspan="3"
                      class="font-weight-bold"
                      :data-excelcolspansumm="
                        object['rows_person']['header'][column]['colspan'] + 4
                      "
                    >
                      {{ object['rows_person']['person'][personValue][column] }}
                    </td>
                    <td
                      v-else-if="columnIndex < 1"
                      class="font-weight-bold"
                      :data-excelcolspan="
                        object['rows_person']['header'][column]['colspan']
                      "
                    >
                      {{ object['rows_person']['person'][personValue][column] }}
                    </td>
                  </template>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="page-break-after excel-bottom-indent"></div>

        <hr class="hr-dashed not-print margin-none" />

        <h5
          class="text-center font-weight-bold"
          style="margin-top: 15px"
        >
          {{ object['rows_experiment']['view']['list_name'] }}
        </h5>
        <span
          >{{ 'Модель/тип ТС: ' + object.avtoModel + ', VIN: ' + object.vin
          }}<br
        /></span>
        <span>
          {{
            'Тип КПП/число передач: ' +
            object.gearboxType +
            ' /' +
            object.gearboxCnt
          }}<br
        /></span>
        <table class="excel-not-border excel-bottom-indent">
          <tr>
            <td
              class="align-baseline"
              data-excelcolspan="2"
            >
              Наличие внедорожного режима:
            </td>
            <td
              data-excelcolspan="8"
              :data-minheight="
                object['rows_experiment']['view']['locksList'].split('<br>')
                  .length > 1
                  ? 15 *
                    object['rows_experiment']['view']['locksList'].split('<br>')
                      .length
                  : ''
              "
              v-html="object['rows_experiment']['view']['locksList']"
            ></td>
          </tr>
        </table>

        <table
          class="excel-bottom-indent text-center skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Статистика работы ТС за период
          </caption>
          <thead>
            <tr>
              <th
                v-for="column in skillsManReport_format.workVehiclePerPeriod
                  .detailColumns"
                data-excelalignv="middle"
                data-excelalignh="center"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.workVehiclePerPeriod
                .detailRows"
            >
              <template
                v-for="column in skillsManReport_format.workVehiclePerPeriod
                  .detailColumns"
              >
                <td
                  v-if="row in object['rows_experiment'][column]"
                  data-excelalignh="center"
                  data-excelalignv="middle"
                  :data-formatnum="
                    object['rows_experiment'][column][row]['format']
                  "
                  :data-value="
                    getDataValueAttribute(
                      object['rows_experiment'][column][row]['format'],
                      object['rows_experiment'][column][row]['val'],
                    )
                  "
                  :rowspan="
                    object['rows_experiment'][column][row]['rowspan']
                      ? object['rows_experiment'][column][row]['rowspan']
                      : ''
                  "
                  v-html="
                    getFormatData(
                      object['rows_experiment'][column][row]['val'],
                      object['rows_experiment'][column][row]['format'],
                    )
                  "
                ></td>
              </template>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent text-center skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Удельный расход топлива и пробег до ТО
          </caption>
          <thead>
            <tr>
              <th
                v-for="column in skillsManReport_format.fuelAndDistToMaintenance
                  .detailColumns"
                data-excelcolspan="2"
                data-excelalignh="center"
                data-excelalignv="middle"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.fuelAndDistToMaintenance
                .detailRows"
            >
              <td
                v-for="column in skillsManReport_format.fuelAndDistToMaintenance
                  .detailColumns"
                data-excelcolspan="2"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Статистика работы трансмиссии
          </caption>
          <tbody>
            <tr
              v-for="column in skillsManReport_format.transmissionOperations
                .detailColumns"
            >
              <th
                data-excelalignv="middle"
                class="text-left"
                data-excelcolspan="4"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
              <td
                class="text-center"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column]['val']['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column]['val']['format'],
                    object['rows_experiment'][column]['val']['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column]['val']['val'],
                    object['rows_experiment'][column]['val']['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование КПП по пробегу
          </caption>
          <thead>
            <tr>
              <th
                v-for="(column, k) in object['rows_experiment']['header'][
                  'GearBoxNumNameArr'
                ]"
                :class="[k ? 'text-left' : '']"
                :data-excelalignh="k ? 'center' : ''"
                :data-formatnum="
                  object['rows_experiment']['header'][column]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment']['header'][column]['format'],
                    object['rows_experiment']['header'][column]['name'],
                  )
                "
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.useGearboxByDist.detailRows"
            >
              <td
                v-for="(column, k) in object['rows_experiment']['header'][
                  'GearBoxNumNameArr'
                ]"
                :class="[k ? 'text-center' : 'font-weight-bold text-left']"
                :data-excelalignh="k ? 'center' : ''"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent text-center skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование сцепления по началу движения
          </caption>
          <thead>
            <tr>
              <th
                v-for="column in skillsManReport_format
                  .useClutchAtBeginningMovement.detailColumns"
                data-excelcolspan="2"
                data-excelalignh="center"
                data-excelalignv="middle"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.useClutchAtBeginningMovement
                .detailRows"
            >
              <td
                v-for="column in skillsManReport_format
                  .useClutchAtBeginningMovement.detailColumns"
                data-excelcolspan="2"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Работа сцепления по началу движения
          </caption>
          <tbody>
            <tr
              v-for="column in skillsManReport_format
                .clutchWorkAtBeginningMovement.detailColumns"
            >
              <th
                data-excelalignv="middle"
                class="text-left"
                data-excelcolspan="4"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
              <td
                class="text-center"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column]['val']['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column]['val']['format'],
                    object['rows_experiment'][column]['val']['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column]['val']['val'],
                    object['rows_experiment'][column]['val']['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование КПП по началу движения: количество, наличие
            пробуксовок сцепления
          </caption>
          <thead>
            <tr>
              <th
                v-for="(column, k) in object['rows_experiment']['header'][
                  'GearBoxNumNameArr'
                ]"
                :data-formatnum="
                  object['rows_experiment']['header'][column]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment']['header'][column]['format'],
                    object['rows_experiment']['header'][column]['name'],
                  )
                "
                :class="[k ? 'text-left' : '']"
                :data-excelalignh="k ? 'center' : ''"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format
                .clutchWorkAtBeginningMovement2.detailRows"
            >
              <td
                v-for="(column, k) in object['rows_experiment']['header'][
                  'GearBoxNumNameArr'
                ]"
                :class="[k ? 'text-center' : 'font-weight-bold text-left']"
                :data-excelalignh="k ? 'center' : ''"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование режимов трансмиссии
          </caption>
          <thead>
            <tr>
              <th
                v-for="(column, k) in object['rows_experiment']['header'][
                  'transmissionLockNameArr'
                ]"
                data-excelalignv="middle"
                :data-excelcolspan="k ? 2 : 0"
                :class="[k ? '' : 'text-left']"
                :data-excelalignh="k ? 'center' : ''"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.useTransmissionModes
                .detailRows"
            >
              <td
                v-for="(column, k) in object['rows_experiment']['header'][
                  'transmissionLockNameArr'
                ]"
                :class="[k ? 'text-center' : 'font-weight-bold text-left']"
                :data-excelalignh="k ? 'center' : ''"
                :data-excelcolspan="k ? 2 : 0"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование ножного и моторного тормоза
          </caption>
          <tbody>
            <tr
              v-for="column in skillsManReport_format.useFootAndMotorBrakes
                .detailColumns"
            >
              <th
                data-excelalignv="middle"
                class="text-left"
                data-excelcolspan="7"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
              <td
                class="text-center"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column]['val']['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column]['val']['format'],
                    object['rows_experiment'][column]['val']['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column]['val']['val'],
                    object['rows_experiment'][column]['val']['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent text-center skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption style="caption-side: top">
            Использование коробки отбора мощности
          </caption>
          <thead>
            <tr>
              <th
                v-for="column in skillsManReport_format.usePowerTakeOffBox
                  .detailColumns"
                data-excelcolspan="2"
                data-minheight="30"
                data-excelalignh="center"
                data-excelalignv="middle"
              >
                {{ object['rows_experiment']['header'][column]['name'] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in skillsManReport_format.usePowerTakeOffBox
                .detailRows"
            >
              <td
                v-for="(column, k) in skillsManReport_format.usePowerTakeOffBox
                  .detailColumns"
                data-excelcolspan="2"
                data-excelalignh="center"
                :data-formatnum="
                  object['rows_experiment'][column][row]['format']
                "
                :data-value="
                  getDataValueAttribute(
                    object['rows_experiment'][column][row]['format'],
                    object['rows_experiment'][column][row]['val'],
                  )
                "
                v-html="
                  getFormatData(
                    object['rows_experiment'][column][row]['val'],
                    object['rows_experiment'][column][row]['format'],
                  )
                "
              ></td>
            </tr>
          </tbody>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption
            class="font-weight-bold"
            style="caption-side: top"
          >
            Неэффективная эксплуатация ТС
          </caption>
          <tr
            v-for="column in skillsManReport_format.badUseVehicle.detailColumns"
          >
            <th
              class="text-left"
              data-excelalignv="middle"
              data-excelcolspan="6"
              :data-minheight="
                object['rows_experiment']['header'][column]['name'].length > 86
                  ? 30
                  : ''
              "
            >
              {{ object['rows_experiment']['header'][column]['name'] }}
            </th>
            <td
              class="text-center"
              data-excelalignh="center"
              data-excelalignv="middle"
              :data-formatnum="
                object['rows_experiment'][column]['val']['format']
              "
              :data-value="
                getDataValueAttribute(
                  object['rows_experiment'][column]['val']['format'],
                  object['rows_experiment'][column]['val']['val'],
                )
              "
              v-html="
                getFormatData(
                  object['rows_experiment'][column]['val']['val'],
                  object['rows_experiment'][column]['val']['format'],
                )
              "
            ></td>
          </tr>
        </table>

        <table
          class="excel-bottom-indent skills-man-report__table"
          border="1"
          cellpadding="5"
        >
          <caption
            class="font-weight-bold"
            style="caption-side: top"
          >
            Нарушения при эксплуатации ТС
          </caption>
          <tr
            v-for="column in skillsManReport_format.violationsDuringUse
              .detailColumns"
          >
            <th
              class="text-left"
              data-excelcolspan="6"
            >
              {{ object['rows_experiment']['header'][column]['name'] }}
            </th>
            <td
              class="text-center"
              data-excelalignh="center"
              :data-formatnum="
                object['rows_experiment'][column]['val']['format']
              "
              :data-value="
                getDataValueAttribute(
                  object['rows_experiment'][column]['val']['format'],
                  object['rows_experiment'][column]['val']['val'],
                )
              "
              v-html="
                getFormatData(
                  object['rows_experiment'][column]['val']['val'],
                  object['rows_experiment'][column]['val']['format'],
                )
              "
            ></td>
          </tr>
        </table>
      </template>
    </template>
  </div>
</template>

<script setup>
import { watch } from '@vue/runtime-core';

const props = defineProps({
  isTitle: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['outputTitleHtml']);

watch(
  () => props.isTitle,
  (cond) => {
    if (!cond) {
      emit(
        'outputTitleHtml',
        `<h6 class="text-center font-weight-bold">
        Оценка эффективности эксплуатации по парку ТС ${props.data.objects[0]['view']['t_interval']}
      </h6>
      
      <p class="text-center excel-bottom-indent">
      ${props.data.objects[0]['view']['smenasStr']}
      </p>
      `,
      );
    }
  },
  { immediate: true },
);

const skillsManReport_format = {
  // Столбцы суммарных показателей
  colsNoSpanArr: [
    'startEngineGroup',
    'motoIdlingGroup',
    'moveStartGroup',
    'moveGroup',
    'ptoWorkKomGroup',
    'drivingStyleGroup',
  ],

  //Cтолбцы детализации
  detailColumns: [
    'DNum',
    'DSkill',
    /*'DType', */ 'DForfeits',
    'DForfeitsRelative',
  ],

  // Статистика работы тс за период
  workVehiclePerPeriod: {
    detailColumns: [
      'motoEngine',
      'motoEngineNoSpd',
      'motoEngineSpd',
      'distance',
      'avgSpd',
      'canExpence',
      'canExpenceSpd',
      'canExpenceNoSpd',
    ],
    detailRows: ['val', 'percent'],
  },

  //Удельный расход топлива и пробег до ТО
  fuelAndDistToMaintenance: {
    detailColumns: [
      'canExpence100km',
      'canExpence100kmMove',
      'canExpenceLHourMove',
      'canExpenceLHourIdle',
      'serviceDistance',
    ],
    detailRows: ['val'],
  },

  // Статистика работы трансмиссии
  transmissionOperations: {
    detailColumns: [
      'swithGearBoxCnt',
      'swithGearBoxCntPerDist',
      'swithClutchCnt',
      'swithClutchBroken',
      'clutchWorkSumm',
    ],
  },

  // Использование КПП по пробегу
  useGearboxByDist: {
    // Столбцы вычисляются в template
    detailRows: ['dist', 'distPercent'],
  },

  // Использование сцепления по началу движения
  useClutchAtBeginningMovement: {
    detailColumns: [
      'clutchUseMoveStartCnt',
      'clutchUseMoveStartSlipCnt',
      'clutchUseMoveStartSlipCntPercent',
      'clutchUseMoveStartSlipHiCnt',
      'clutchUseMoveStartSlipLowCnt',
    ],
    detailRows: ['val'],
  },

  // Использование сцепления по началу движения
  clutchWorkAtBeginningMovement: {
    detailColumns: [
      'clutchUseMoveStartWorkSumm',
      'clutchUseMoveStartExcessiveWorkSumm',
      'clutchUseMoveStartExcessiveWorkPercent',
      'clutchUseMoveStartExcessiveWorkHi',
      'clutchUseMoveStartExcessiveWorkLow',
      'clutchUseMoveStartExcessiveWorkLowPercent',
    ],
  },

  // Использование КПП по началу движения: количество, наличие пробуксовок сцепления
  clutchWorkAtBeginningMovement2: {
    // Столбцы вычисляются в template
    detailRows: [
      'cntBeginMove',
      'cntBeginMovePercent',
      'cntRpmClutchSlip',
      'cntRpmClutchSlipPercent',
      'clutchSlipExcessiveWork',
      'clutchSlipExcessiveWorkPercent',
      'clutchSlipExcessiveWorkLow',
      'avgAccelMoveBegin',
      'avgAccelMoveBeginWithClutchSlip',
    ],
  },

  // Использование режимов трансмиссии
  useTransmissionModes: {
    // Столбцы вычисляются в template
    detailRows: ['count', 'time', 'dist', 'distPercent'],
  },

  // Использование ножного и моторного тормоза
  useFootAndMotorBrakes: {
    detailColumns: [
      'breakCount',
      'breakTime',
      'pedalBreakCount',
      'pedalBreakTime',
      'engineBreakCnt',
      'engineBreakTime',
      'engineBreakCntPercent',
      'engineBreakTimePercent',
      'absCount',
    ],
  },

  // Использование коробки отбора мощности
  usePowerTakeOffBox: {
    detailColumns: [
      'ptoCnt',
      'ptoTime',
      'ptoEngineRpm800_1000Time',
      'ptoEngineRpm800_1000Percent',
    ],
    detailRows: ['val'],
  },

  // Неэффективная эксплуатация ТС
  badUseVehicle: {
    detailColumns: [
      'rpm1900_2200Time',
      // 'swithClutchNoSpdCnt',
      'swithClutchNoSpdCountTime',
      // 'rpmNo800_1000less1200Cnt'
      'clutchSlipExcessiveRpmCnt',
      'clutchUseMoveStartExcessiveWorkSumm',
      'clutchSlipVehicleSlipTime',
      'clutchSlipVehicleSlipRpmCnt',
      'clutchSlipVehicleSlipWork',
      'clutchSlipVehicleSlipLowRpmCnt',
      'clutchSlipVehicleSlipLowWork',
      'clutchUseMoveStartExcessiveWorkPercent',
      'clutchSlipVehicleSlipWorkPercent',
      'rpmNo800_1000less1200Time',
      // 'rpmSpdLess440Cnt',
      // 'rpmStartSpdMore1200Cnt',
      // 'accelSharplyCnt',
      // 'startSpdBadRoadNoLockCnt',
      'swithGearBoxWrongCnt',
      'swithGearBoxWrongPercent',
      // 'accelSharplyViolationCnt',
      'swithClutchInsufficientCnt',
      'kickdownCnt',
    ],
  },

  // Нарушения при эксплуатации ТС
  violationsDuringUse: {
    detailColumns: [
      'rpmMore2200Time',
      'rpmMore1200PtoTime',
      // 'engineStartColdCnt',
      // 'engineStalledspdCnt',
      'clutchSlipBadRpmCnt',
      'clutchSlipCriticalCnt',
      'clutchSlipCriticalTime',
      'startSpdGearBoxMore2Cnt',
      // 'swithGearBoxRewersSpdCnt',
      // 'swithLockSpdCnt',
      // 'swithLockAccelCnt',
      'loweringSpdMore25Distance',
      'loweringSpdMore25Time',
      'crosswheelSpdMore7Distance',
      'crosswheelSpdMore7Time',
      'ptoSpdDistance',
      'ptoSpdTime',
      'handleBreakMoveCount',
      'handleBreakMoveTime',
      'lowOilLampCount',
      'lowOilLampTime',
      'hightCoolTempLampCount',
      'hightCoolTempLampTime',
      // 'ptoActivateKrenCnt'
    ],
  },
};

const getDataValueAttribute = (formatNum, value) => {
  if (formatNum < 1 && formatNum > 4) return '';

  if (
    formatNum == 1.5 ||
    formatNum == 1.6 ||
    formatNum == 1.1 ||
    formatNum == 1
  ) {
    return value / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440; // 1440 = 60 * 24
  }

  return '';
};

function getFormatData(line, format) {
  return props.data.format(line, format);
}
</script>

<style lang="scss">
.skills-man-report__table {
  border-collapse: collapse;
  border: 1px solid black;
  caption-side: top;
  margin-right: 5px;
}
</style>
