<template>
  <button
    class="not-print float-left"
    onclick="html_to_excel_helper.export(this, 'overspeed-template', 'Превышение скорости', 'landscape')"
  >
    Экспорт&nbsp;в&nbsp;EXCEL
  </button>

  <div id="overspeed-template">
    <overspeed-template-tables
      :trigger="props.trigger"
      :data="props.reportData"
      :params="props.params"
      @display-track="displayTrackPopup"
    />
  </div>

  <overspeed-track-popup
    v-if="popups.overspeedTrackPopup && props.params.detailWithMaps"
    :event="currentEvent"
    :params="props.params"
    @close="() => togglePopup('overspeedTrackPopup')"
  />
</template>

<script setup>
import { ref } from 'vue';

import OverspeedTrackPopup from './OverspeedTrackPopup.vue';
import OverspeedTemplateTables from './OverspeedTemplateTables.vue';

import { usePopup } from '@/ManualApp/use/popup';

const { popups, togglePopup } = usePopup({ overspeedTrackPopup: false });

const props = defineProps({
  trigger: Boolean,
  reportData: { type: Array, default: [] },
  params: { type: Object, default: {} },
});

const isDisplayedTrack = ref(false);

const currentEvent = ref(null);

function displayTrackPopup(event) {
  currentEvent.value = event;
  togglePopup('overspeedTrackPopup');
  isDisplayedTrack.value = true;
}
</script>

<style lang="scss" scoped></style>
