import { ref } from 'vue';
import type { Ref } from 'vue';

import type { ITableData } from '../Types/ITableData';
import type { TObject } from '../Types/Object';

export const useTableData = (TABLE_HEADER: object) => {
  const isFilledTableData: Ref<boolean> = ref(false);
  const tableData: Ref<ITableData> = ref({ header: TABLE_HEADER, body: [] });

  const displayedIds: string[] = [];

  function removeUnnecessaryData(ids: string[]) {
    for (let i = 0; i < tableData.value.body.length; i++) {
      const row = tableData.value.body[i];

      if (!ids.includes(row.id)) {
        tableData.value.body.splice(i, 1);
        displayedIds.splice(i, 1);
      }
    }
  }

  function fillTableData(rows: TObject<any>) {
    for (let id in rows) {
      if (displayedIds.includes(id)) continue;
      displayedIds.push(id);
      tableData.value.body.push(rows[id]);
    }

    const ids = Object.keys(rows);

    if (ids.length !== tableData.value.body.length) {
      removeUnnecessaryData(ids);
    }

    isFilledTableData.value = true;
  }

  return { tableData, fillTableData, isFilledTableData };
};
