<template>
  <div
    v-if="!props.notDisplayControls"
    class="accordion-content"
  >
    <ui-button @click="() => togglePopup('allVins')">Все VIN</ui-button>

    <div class="d-flex">
      <ui-search
        style="margin-bottom: 0"
        v-model:search="search.field.value"
        v-model:filter-selected="search.filterSelected.value"
        v-model:search-type="search.type"
        :filter-data="FILTER_DATA"
        :submit="search.submit"
      />

      <div
        class="d-flex justify-content-center align-items-center ml-2 vehicles-count"
      >
        {{ shownTableDataRows.length }} / {{ tableData.body.length }}
      </div>
    </div>

    <div class="d-flex">
      <ui-button
        v-if="rule_vehicle_create && !props.notDisplayAddButton"
        title="Добавить транспортное средство"
        @click="addVehicle"
      >
        Добавить
      </ui-button>
    </div>
  </div>

  <div
    v-if="isRequest.vehicles && !tableData.body.length"
    class="d-flex justify-content-center align-items-center flex-column"
  >
    <spin-loader
      :width="50"
      :height="50"
      class="mb-3"
    />
    <h4 class="text-center">Загрузка транспортных средств...</h4>
  </div>

  <div
    v-else-if="
      !isRequest.vehicles && isFilledTableData && !tableData.body.length
    "
    class="text-center"
  >
    Нет транспортных средств
  </div>

  <ui-table
    v-show="isFilledTableData && tableData.body.length"
    :style="props.styleForBody"
    :data-for-sort="tableData"
    @scroll="onScroll"
    :fixed="true"
  >
    <template #header>
      <tr>
        <ui-table-th
          v-if="!props.notDisplayControls"
          style="width: 30px"
          :cell-key="'edit'"
          :cell="{ text: '', noSort: true }"
        />
        <ui-table-th
          v-if="!props.notDisplayControls"
          style="width: 30px"
          :cell-key="'delete'"
          :cell="{ text: '', noSort: true }"
        />
        <ui-table-th
          style="width: 30px"
          :cell-key="'monitoring'"
          :cell="{ text: '', noSort: true }"
        />
        <template
          v-for="(cell, key) in TABLE_HEADER"
          :key="key"
        >
          <ui-table-th
            v-if="isShowColumn(key)"
            :cell-key="key"
            :cell="cell"
            :is-resizable="true"
          />
        </template>
      </tr>
    </template>
    <template #default>
      <div
        :style="{
          marginTop: startDisplayIndex * ROW_HEIGHT +'px',
        }"
      ></div>
      <template
        v-for="(vehicle, rowIndex) of shownTableDataRows.slice(
          startDisplayIndex,
          startDisplayIndex + VISIBLE_TABLE_ROWS + INDEX_OFFSET,
        )"
        :key="startDisplayIndex + rowIndex"
      >
        <tr
          v-if="
            (vehicle.isShow == undefined || vehicle.isShow) &&
            rowIndex < startDisplayIndex + VISIBLE_TABLE_ROWS + 1
          "
          :style="{ height: ROW_HEIGHT + 'px' }"
        >
          <ui-table-delete-td
            v-if="rule_vehicle_delete && !props.notDisplayControls"
            title="Удалить транспортное средство"
            @click="() => deleteVehicle(vehicle.id)"
          />
          <ui-table-edit-td
            v-if="rule_vehicle_edit && !props.notDisplayControls"
            title="Изменить транспортное средство"
            @click="() => editVehicle(vehicle.id)"
          />
          <ui-table-monitoring-td
            :loading="!areMonitoringObjectsLoaded"
            :is-in-monitoring="isInMonitoring(vehicle.vin)"
            @show-monitoring-vehicle="() => showMonitoringVehicle(vehicle.vin)"
          />
          <template
            v-for="(cell, key) in TABLE_HEADER"
            :key="startDisplayIndex + rowIndex + key"
          >
            <ui-table-td
              v-if="key == 'contractor_name' && isShowColumn(key)"
              :class="{ 'my-link': vehicle.id_c && vehicle.id_c != 0 }"
              :html="getContractorName(vehicle.id_c)"
              @click="() => linkToContractor(vehicle.id_c)"
            />
            <ui-table-current-vehicle-tariffs
              v-else-if="key == 'current_tariffs' && isShowColumn(key)"
              :contractor-id="vehicle.id_c"
              :vehicle-id="vehicle.id"
              @display-current-tariffs="
                (data) => displayCurrentTariffs(vehicle.vin)(data)
              "
            />
            <!-- Компонент включает две ячейки: doc_number и doc_status -->
            <ui-table-vehicle-contract-status
              v-else-if="key == 'doc_number'"
              :contractor-id="vehicle.id_c"
              :vehicle-id="vehicle.id"
            />
            <template v-else-if="key == 'doc_status'"></template>
            <ui-table-vehicle-messages-td
              v-else-if="key == 'messagesFromVehicle'"
              :vehicle-id="vehicle.id"
            />
            <ui-table-td
              v-else-if="key == 'equipment' && isShowColumn(key)"
              :html="getEquipment(vehicle[key])"
            />
            <ui-table-td
              v-else-if="isShowColumn(key)"
              :class="{ 'my-link': isShowLog(vehicle, key) }"
              :html="vehicle[key]"
              @click="
                () =>
                  isShowLog(vehicle, key) && displayLog(vehicle[key + '_log'])
              "
            ></ui-table-td>
          </template>
        </tr>
      </template>
      <div
        :style="{
          marginBottom:
            ROW_HEIGHT *
              (shownTableDataRows.length -
                (startDisplayIndex + VISIBLE_TABLE_ROWS + 1)) +
            'px',
        }"
      ></div>
    </template>
  </ui-table>

  <teleport to="body">
    <transition name="fade">
      <log-popup
        v-if="popups.logPopup"
        :data="logPopupData"
        @close="() => togglePopup('logPopup')"
      />
    </transition>

    <transition name="fade">
      <all-vins
        v-if="popups.allVins"
        :rows="tableData.body"
        @close="() => togglePopup('allVins')"
      />
    </transition>

    <transition name="fade">
      <monitoring-vehicle-popup
        v-if="popups.monitoringVehiclePopup"
        :data="monitoringVehicleData"
        @close="() => togglePopup('monitoringVehiclePopup')"
      />
    </transition>

    <transition name="fade">
      <confirm-delete-popup
        v-if="popups.confirmDelete"
        :handler="handlerOfConfirmDelete"
        @close="() => togglePopup('confirmDelete')"
      />
    </transition>
  </teleport>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import UiSearch from '@/ManualApp/Components/Search/UiSearch.vue';
import UiButton from '@/ManualApp/Components/Buttons/UiButton.vue';
import UiTable from '@/ManualApp/Components/Tables/UiTable.vue';
import UiTableCurrentVehicleTariffs from '@/ManualApp/Components/Tables/UiTableCurrentVehicleTariffs.vue';
import UiTableTd from '@/ManualApp/Components/Tables/UiTableTd.vue';
import UiTableTh from '@/ManualApp/Components/Tables/UiTableTh.vue';
import CurrentTariffsPopup from '@/ManualApp/Pages/Contractors/Contractor/Components/CurrentTariffsPopup.vue';
import LogPopup from '@/ManualApp/Components/Popups/LogPopup.vue';
import AllVins from '@/ManualApp/Components/Popups/AllVins.vue';
import UiTableEditTd from '@/ManualApp/Components/Tables/UiTableEditTd.vue';
import UiTableDeleteTd from '@/ManualApp/Components/Tables/UiTableDeleteTd.vue';
import UiTableMonitoringTd from '@/ManualApp/Components/Tables/UiTableMonitoringTd.vue';
import MonitoringVehiclePopup from '@/ManualApp/Components/Popups/MonitoringVehiclePopup.vue';
import ConfirmDeletePopup from '@/ManualApp/Components/Confirm/ConfirmDeletePopup.vue';
import SpinLoader from '@/App/Components/SpinLoader.vue';
import UiTableVehicleContractStatus from '@/ManualApp/Components/Tables/UiTableVehicleContractStatus.vue';
import UiTableVehicleMessagesTd from '@/ManualApp/Components/Tables/UiTableVehicleMessagesTd.vue';

import { useTable } from '@/ManualApp/use/table';
import { usePopup } from '@/ManualApp/use/popup';
import { useScroll } from '@/ManualApp/use/scroll';
import { useSearch } from '@/ManualApp/use/search';
import { useRequest } from '@/ManualApp/use/request';
import { useTableData } from '@/ManualApp/use/tableData';
import { isRequest } from '@/ManualApp/use/refetch';

import { STORE, defferExecution } from '@/ManualApp/STORAGE/STORAGE';

const props = defineProps({
  styleForBody: { type: String, default: '' },
  shownColumns: { type: [Array, Boolean], default: false },
  notDisplayControls: { type: Boolean, default: false },
  notDisplayAddButton: { type: Boolean, default: false },
  data: { type: Object, default: {} },
});

const URL_FOR_VEHICLES = (vehicle_id) => `Api/manual/vehicle/${vehicle_id}`;

const TABLE_HEADER = useTable.headers.vehicles;

const FILTER_DATA = {
  name: 'Наименование',
  vin: 'VIN',
  id_c: 'Контрагент',
  doc_status: 'Статус документа',
};

const ROW_HEIGHT = 36;
const INDEX_OFFSET = 10;
const VISIBLE_TABLE_ROWS = 25;

const { onScroll, startIndex: startDisplayIndex } = useScroll(
  ROW_HEIGHT,
  VISIBLE_TABLE_ROWS,
);

const route = useRoute();

const { send } = useRequest();

const { popups, togglePopup } = usePopup({
  logPopup: false,
  allVins: false,
  monitoringVehiclePopup: false,
  confirmDelete: false,
});

const {
  senderRights: { rule_vehicle_create, rule_vehicle_edit, rule_vehicle_delete },
} = inject('userData');
const routerPush = inject('routerPush');
const displayToast = inject('displayToast');
const displayCurrentTariffsPopup = inject('displayCurrentTariffsPopup');

const { id: contractor_id } = route.params;

const { tableData, fillTableData, isFilledTableData } =
  useTableData(TABLE_HEADER);

const search = useSearch({
  field: {
    value: '',
    validators: {},
  },
  filterSelected: { value: 'vin' },
  data: tableData,
  type: 'not-strict',
});

const areMonitoringObjectsLoaded = ref(false);
const logPopupData = ref([]);
const monitoringVehicleData = ref({});
const handlerOfConfirmDelete = ref(false);

const getContractorName = (contractor_id) => {
  return useTable.getContractorName(contractor_id);
};

const getEquipment = (equipment) => {
  if (!Object.keys(STORE.equipment).length) {
    return 'Загрузка данных...';
  }

  if (!equipment) return '';

  if (typeof equipment == 'string') {
    equipment = JSON.parse(equipment);
  }

  return equipment.reduce((acc, obj) => {
    if (!STORE.equipment[obj.id]) return acc;

    const equip = STORE.equipment[obj.id];

    acc += `${equip.name}: ${obj ? obj.count : '0'}; `;
    return acc;
  }, '');
};

const isShowColumn = (key) => {
  return props.shownColumns ? props.shownColumns.includes(key) : false;
};

const isShowLog = (vehicle, key) => {
  return vehicle[key] && vehicle[key + '_log'];
};

const isInMonitoring = (vin) => {
  return !!STORE.monitoringObjectsByVins[vin];
};

function displayLog(logsArray) {
  if (typeof logsArray == 'string') {
    logPopupData.value = JSON.parse(logsArray);
  } else {
    logPopupData.value = logsArray;
  }
  togglePopup('logPopup');
}

function displayCurrentTariffs(vin) {
  return function (data) {
    displayCurrentTariffsPopup({ subtitle: vin, data });
  };
}

function linkToContractor(id_c) {
  if (id_c && id_c != 0) {
    routerPush({ name: 'contractor-card', params: { id: id_c } });
  }
}

function showMonitoringVehicle(vin) {
  monitoringVehicleData.value = STORE.monitoringObjectsByVins[vin];
  togglePopup('monitoringVehiclePopup');
}

function defferSetAreMonitoringObjectsLoaded() {
  const condition = () => !Object.keys(STORE.monitoringObjectsByVins).length;
  const callback = () => {
    areMonitoringObjectsLoaded.value = true;
  };

  defferExecution(condition, callback);
}

function addVehicle() {
  routerPush({
    name: 'vehicle',
    params: { contractor_id: contractor_id || '0', action: 'create' },
  });
}

function editVehicle(id) {
  routerPush({
    name: 'vehicle',
    params: {
      action: 'update',
      contractor_id: contractor_id || '0',
      vehicle_id: id,
    },
  });
}

function deleteVehicle(id) {
  togglePopup('confirmDelete');

  handlerOfConfirmDelete.value = async () => {
    displayToast('Запрос на удаление отправлен', 'success', 1000);

    try {
      const res = await send(URL_FOR_VEHICLES(id), 'DELETE');

      if (res.message) {
        displayToast(ucFirst_helper(res.message));
        return;
      }

      delete STORE.vehicles[id];

      displayToast('Транспортное средство успешно удалено', 'success');
    } catch (error) {
      displayToast(
        'Возникла ошибка, попробуйте повторить попытку, либо перезагрузите страницу',
      );
    }

    handlerOfConfirmDelete.value = false;
    togglePopup('confirmDelete');
  };
}

function clearVehiclesIsShowProp() {
  search.field.value = ' ';
  nextTick().then(() => {
    search.field.value = '';
  });
}

const shownTableDataRows = computed(() =>
  tableData.value.body.filter((row) => row.isShow === undefined || row.isShow),
);

watch(
  () => props.data,
  (vehicles) => {
    fillTableData(vehicles);
  },
  { deep: true },
);

onMounted(() => {
  clearVehiclesIsShowProp();
  fillTableData(props.data);
  defferSetAreMonitoringObjectsLoaded();
});
</script>

<style lang="scss" scoped>
.vehicles-count {
  font-size: 14px;

  @media (max-width: 1175px) {
    font-size: 12px;
  }
}
.table-overflow {
  max-height: 500px;
}
</style>
