import { MyWSConnect } from '../MyWSConnect';
import json2 from '@discoveryjs/json-ext';

import { accessToken } from '@/src/jwtSender';
const { parseChunked } = json2;

export class WSTemplatesFetcher extends MyWSConnect {
  reportJson = [];
  responseNumber = 0;
  chunkNumber = 0;

  constructor(url, message) {
    super(url);

    this.getTemplateData = {
      event: 'get-template',
      message,
    };

    this.startCheckConnection();
  }

  messageHandler(data) {
    try {
      const { event, message = {}, i } = data;
      // внутренние типы событий

      if (event === 'connection') {
        const { MAX_LIFETIME, isExist } = message;

        this.lifetime.setMax(MAX_LIFETIME);
        this.lifetime.update();

        this.sendMessageByGetTemplate(isExist);
        this.sendSuccess(i);
        return;
      }

      this.lifetime.update();

      if (event === 'response-percent') {
        const { count } = message;

        this.displayResponsePercent(count);
        this.sendSuccess(i);
        return;
      }

      if (event === 'result-percent') {
        const { chunksCount, index, chunk } = message;

        this.displayResultPercent(chunksCount);
        this.saveChunk(chunk, index);
        this.sendSuccess(i);
        return;
      }

      if (event === 'error') {
        this.errorHandler(data);
        this.sendSuccess(i);
        return;
      }

      if (event === 'done') {
        const { chunksCount } = message;
        this.doneTypeProcess(chunksCount);
        this.sendSuccess(i);

        return;
      }

      throw new Error(`unknown message type`);
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async doneTypeProcess(chunksCount) {
    const missedIndexes = this.getMissedIndexes(chunksCount);

    if (missedIndexes.length > 0) {
      this.sendMissedIndexes(missedIndexes);
      return;
    }

    try {
      const sortedValues = Object.keys(this.reportJson)
        .map(Number)
        .sort((a, b) => a - b)
        .map(key => this.reportJson[key]);
      const data = await parseChunked(() => sortedValues)
      this.requestDoneHandler(data);
      this.clear();
    } catch (error) {
      this.errorHandler(error);
    }
  }

  getMissedIndexes(chunksCount) {
    const missedIndexes = [];

    for (let index = 0; index < chunksCount; index++) {
      if (this.reportJson[index] === undefined) {
        missedIndexes.push(index);
      }
    }

    return missedIndexes;
  }
  sendMissedIndexes(missedIndexes) {
    this.sendMessage({
      event: 'incomplete-data',
      message: {
        indexes: missedIndexes,
      },
    });
  }

  displayResponsePercent(responsesCount) {
    this.responseNumber++;

    const percent = Math.floor((this.responseNumber / responsesCount) * 100);
    const progress =
      percent >= 100
        ? `Обработка данных...`
        : `Получен ответ ${this.responseNumber} из ${responsesCount} (${percent}%)`;

    this.showMessageHandler(progress);
  }

  displayResultPercent(chunksCount) {
    this.chunkNumber++;

    const percent = Math.floor((this.chunkNumber / chunksCount) * 100);

    const str =
      percent >= 100
        ? 'Обработка данных...'
        : `Загрузка данных ${this.chunkNumber} из ${chunksCount} (${percent}%)`;

    this.showMessageHandler(str);
  }

  saveChunk(chunk = '', index) {
    if (!chunk) throw new Error('WS: result chunk is not defined');

    this.reportJson[index] = chunk;
  }

  sendMessageByGetTemplate(isExist) {
    if (!isExist) {
      this.getTemplateData.message.body.token = accessToken.value.token;
      this.sendMessage(this.getTemplateData);
    }
  }

  sendSuccess(i) {
    this.sendMessage({ event: 'success', message: i ?? '' });
  }
}
