<template>
  <template v-if="status.docType === undefined">
    <ui-table-td html="" />
    <ui-table-td html="" />
  </template>
  <template v-else-if="status.docType === 'individual'">
    <ui-table-td html="Индивидуальный тариф" />
    <ui-table-td
      :style="{ color: STATUS_TYPES[status.type]?.color || '' }"
      :html="STATUS_TYPES[status.type].text"
    />
  </template>
  <template v-else>
    <ui-table-td
      class="my-link"
      :html="status.number"
      @click="() => linkToContract(status.id, status.docType)"
    />
    <ui-table-td
      :style="{ color: STATUS_TYPES[status.type]?.color || '' }"
      :html="STATUS_TYPES[status.type].text"
    />
  </template>
</template>

<script setup>
import { computed, inject } from 'vue';
import UiTableTd from './UiTableTd.vue';

import { useTable } from '@/ManualApp/use/table';
import { useTariffs } from '@/ManualApp/use/tariffs';

import { STORE } from '@/ManualApp/STORAGE/STORAGE';

const props = defineProps({
  contractorId: { type: String, required: true },
  vehicleId: { type: String, required: true },
});

const emit = defineEmits([]);

const { STATUS_TYPES, getStatusTypeByContract } = useTable;

const routerPush = inject('routerPush');

function linkToContract(id, docType) {
  if (docType === 'contract' || docType === 'additional') {
    routerPush({ name: docType, params: { id } });
  }
}

const status = computed(() => {
  const currentTariffs = useTariffs.currentVehicleTariff(
    props.contractorId,
    props.vehicleId,
  );

  const docType = currentTariffs.current.type;

  if (docType === 'individual') {
    return {
      docType: 'individual',
      type: 'active',
    };
  } else if (docType === 'contract') {
    const contract = STORE.contracts[currentTariffs.current.id_d];

    return {
      docType,
      type: getStatusTypeByContract(contract, currentTariffs.current),
      number: contract?.number || `${currentTariffs.current.id_d}:нет_номера`,
      id: contract.id,
    };
  } else if (docType === 'additional') {
    const additional = STORE.additionals[currentTariffs.current.id_a];

    return {
      docType,
      type: getStatusTypeByContract(additional, currentTariffs.current),
      number: additional?.number || `${currentTariffs.current.id_a}:нет_номера`,
      id: additional.id,
    };
  } else {
    return {};
  }
});
</script>

<style lang="scss" scoped></style>
