import { useScrollTo } from '@/ManualApp/use/scrollTo';
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/manual',
      name: 'manual',
      components: {
        default: () => import('@/ManualApp/Pages/Contractors/Contractors.vue'),
      },
    },
    {
      path: '/manual/contractors',
      name: 'contractors',
      components: {
        default: () => import('@/ManualApp/Pages/Contractors/Contractors.vue'),
      },
      alias: '/manual',
    },

    {
      path: '/manual/contractors/:id',
      name: 'contractor-card',
      components: {
        default: () =>
          import('@/ManualApp/Pages/Contractors/Contractor/ContractorCard.vue'),
      },
    },
    {
      path: '/manual/contractors/contractor/:action/:id?',
      name: 'contractor',
      components: {
        default: () =>
          import('@/ManualApp/Pages/Forms/Contractor/ContractorForm.vue'),
      },
    },
    {
      path: '/manual/contractors/:contractor_id/vehicle/:action/:vehicle_id?',
      name: 'vehicle',
      components: {
        default: () =>
          import('@/ManualApp/Pages/Forms/Vehicle/VehicleForm.vue'),
      },
    },
    {
      path: '/manual/contracts/:id',
      name: 'contract',
      components: {
        default: () =>
          import('@/ManualApp/Pages/Contracts/Pages/ContractPage.vue'),
      },
    },

    {
      path: '/manual/vehicles',
      name: 'vehicles',
      components: {
        default: () => import('@/ManualApp/Pages/Vehicles/Vehicles.vue'),
      },
    },
    {
      path: '/manual/contracts',
      name: 'contracts',
      components: {
        default: () => import('@/ManualApp/Pages/Contracts/Contracts.vue'),
      },
    },
    {
      path: '/manual/additionals',
      name: 'additionals',
      components: {
        default: () => import('@/ManualApp/Pages/Additionals/Additionals.vue'),
      },
    },
    {
      path: '/manual/additionals/:id',
      name: 'additional',
      components: {
        default: () =>
          import('@/ManualApp/Pages/Additionals/AdditionalPage.vue'),
      },
    },
    {
      path: '/manual/tariffs',
      name: 'tariffs',
      components: {
        default: () => import('@/ManualApp/Pages/Tariffs/Tariffs.vue'),
      },
    },

    {
      path: '/manual/pdf/:path',
      name: 'show-pdf',
      components: {
        default: () => import('@/ManualApp/Pages/PDF/ShowPdf.vue'),
      },
    },

    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/ManualApp/Pages/Errors/PageNotFound.vue'),
    },
    {
      path: '/manual/error/404',
      name: '404',
      component: () => import('@/ManualApp/Pages/Errors/PageNotFound.vue'),
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const splitedHash = to.hash.split('/');
      useScrollTo(splitedHash[0]);
    } else if (savedPosition) {
      // return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
