import { STORE } from './STORAGE';

import { useRequest } from '../use/request';

const { send } = useRequest();

const URL_REAL_POS_COUNT = `Api/getRealPosCount`;
const COUNT_OF_PARALLEL_REQUESTS = 5;
const MAX_IDS_IN_REQUEST = 10;

export async function updateMessagesFromVehicles() {
  const vehicleIds = getVehiclesWithoutMessagesIds();

  if (vehicleIds.length) {
    STORE.isRequestedMessagesFromVehicles = true;

    const [from, to] = getRequestInterval();

    const slicedIds = getSlicedIds(vehicleIds);

    let requestIndex = 0;
    let responseIndex = 0;

    for (const ids of slicedIds) {
      const body = {
        ids: ids.map((o) => o.monitoring),
        from: from / 1000,
        to: to / 1000,
      };

      try {
        const request = send(URL_REAL_POS_COUNT, 'POST', body);

        request.then((res) => {
          if (res.message) {
            return;
          }

          for (const monitoringId in res.poscount) {
            const idsObj = ids.find((o) => o.monitoring == monitoringId);
            if (idsObj) {
              STORE.messagesFromVehicles[idsObj?.manual] = {
                poscount: res.poscount[monitoringId],
                from: res.from,
                to: res.to,
                poscountIndexes: res.poscountIndexes,
              };
            }

            responseIndex++;
          }
        });

        requestIndex++;

        while (requestIndex % COUNT_OF_PARALLEL_REQUESTS === 0) {
          await new Promise((res) => setTimeout(res, 1000));

          if (responseIndex)
            if (responseIndex % COUNT_OF_PARALLEL_REQUESTS === 0) break;
        }
      } catch (error) {
        console.error(error);
      }
    }

    STORE.isRequestedMessagesFromVehicles = false;
  }
}

function getSlicedIds(ids: { manual: string; monitoring: string }[]) {
  const res = [];

  for (let i = 0; i < ids.length; i += MAX_IDS_IN_REQUEST) {
    res.push(ids.slice(i, i + MAX_IDS_IN_REQUEST));
  }

  return res;
}

function getRequestInterval() {
  const d = new Date();

  const to = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);

  const daysInOneMonthAgo =
    32 - new Date(d.getFullYear(), d.getMonth(), 32).getDate();

  let from;
  if (to.getDate() >= daysInOneMonthAgo) {
    // первый день настоящего месяца
    from = new Date(d.getFullYear(), d.getMonth(), 1);
  } else {
    // тот же день текущего месяца, месяц назад
    from = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());
  }

  return [from.getTime(), to.getTime()];
}

function getVehiclesWithoutMessagesIds() {
  const { vehicles, messagesFromVehicles, monitoringObjectsByManualVehicleId } =
    STORE;

  const vehicleIds: { manual: string; monitoring: string }[] = [];

  for (const id in vehicles) {
    if (
      !messagesFromVehicles[id] &&
      monitoringObjectsByManualVehicleId[id]
      // ?.vin
    ) {
      vehicleIds.push({
        manual: id,
        monitoring: monitoringObjectsByManualVehicleId[id]?.id,
      });
    }
  }

  return vehicleIds;
}
