import { ref } from 'vue';

export const usePopup = (init = {}) => {
  const popups = ref(init);
  const togglePopup = (name) => {
    popups.value[name] = !popups.value[name];
  };

  return { popups, togglePopup };
};
