<template>
  <div class="toasts">
    <ui-toast-list
      v-for="message in messages"
      :key="message.id"
    >
      <ui-toast
        :class-name="message.class"
        :text="message.text"
        :icon="message.icon"
        @close="deleteMessage(message.id)"
      />
    </ui-toast-list>
  </div>
</template>

<script>
import UiToastList from './UIToastList.vue';
import UiToast from './UIToast.vue';

export default {
  name: 'TheToaster',

  components: { UiToastList, UiToast },

  data() {
    return {
      messages: {},
    };
  },

  methods: {
    success({ message = '', delay = 5000 }) {
      this.addMessage({ type: 'success', message, delay });
    },
    warning({ message = '', delay = 5000 }) {
      this.addMessage({ type: 'warning', message, delay });
    },
    error({ message = '', delay = 5000 }) {
      this.addMessage({ type: 'error', message, delay });
    },
    default({ message = '', delay = 5000 }) {
      this.addMessage({ type: 'default', message, delay });
    },
    addMessage({ type = 'default', message = '', delay = 5000 }) {
      const id = Date.now();

      this.messages[id] = {
        id,
        icon: type,
        class: `toast_${type}`,
        text: message,
      };

      this.hideMessagesTimer(id, delay);
    },

    hideMessagesTimer(id, delay) {
      const vm = this;
      setTimeout(() => {
        delete vm.deleteMessage(id);
      }, delay);
    },

    deleteMessage(id) {
      delete this.messages[id];
    },
  },
};
</script>

<style scoped>
.toasts {
  position: fixed;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  white-space: pre-wrap;
  z-index: 10000;
}

@media all and (min-width: 992px) {
  .toasts {
    bottom: 72px;
    right: 112px;
  }
}
</style>
