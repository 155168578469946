const sortNumber = (a: number, b: number): number => {
  return a - b;
};

const sortString = (a: string, b: string): number => {
  a = a.toLowerCase();
  b = b.toLowerCase();

  return a < b ? -1 : a > b ? 1 : 0;
};

const sortDate = (a: any, b: any): number => {
  const dateA = a.split('.').reverse().join('-');
  const dateB = b.split('.').reverse().join('-');

  return new Date(dateA).getTime() - new Date(dateB).getTime();
};

interface ISortHelper {
  [key: string]: { [key: string]: (a: any, b: any) => number };
}

const sort_helper: ISortHelper = {
  dataTypes: {
    number: sortNumber,
    string: sortString,
    date: sortDate,
  },
};

export default sort_helper;
