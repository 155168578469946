<template>
  <ui-popup
    class-for-body="my-popup"
    style-for-body="padding: 20px;"
    @close="() => emit('close')"
  >
    <div class="d-flex justify-content-between">
      <button
        style="max-height: 35px"
        @click="displayDetailPopup"
      >
        Детализация
      </button>
      <div>
        <h6
          class="text-center title mb-0"
          v-html="title"
        ></h6>
        <h6
          class="text-center title mt-0"
          v-html="interval"
        ></h6>
      </div>
      <ui-close-button
        style="margin-top: -10px; margin-bottom: 10px"
        @click="() => emit('close')"
      />
    </div>
    <div class="mb-2 subtitle">
      <div>
        <h6
          class="text-center not-excel title--mobile mb-0"
          v-html="title"
        ></h6>
        <h6
          class="text-center not-excel title--mobile mt-0"
          v-html="interval"
        ></h6>
      </div>
      <div
        class="lh-1 left"
        v-html="leftSubtitle"
      ></div>
      <div
        class="lh-1 right"
        v-html="rightSubtitle"
      ></div>
    </div>

    <div
      v-if="!isReadyTrack"
      style="width: 100%; height: calc(95vh - 40px - 75px - 48px - 0.5rem)"
      class="d-flex justify-content-center align-items-center"
    >
      <ui-message :message="message" />
    </div>
    <div
      :id="MAP_ID"
      class="overspeed-map"
      :style="{ opacity: isReadyTrack ? 1 : 0 }"
    ></div>

    <teleport to="body">
      <overspeed-detail-popup
        v-if="popups.trackDetail"
        :title="title"
        :interval="interval"
        :speed-limit="props.event.sspd"
        :rows="detailRows"
        @close="() => togglePopup('trackDetail')"
      />
    </teleport>
  </ui-popup>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref } from 'vue';

import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import OverspeedDetailPopup from './OverspeedDetailPopup.vue';
import UiMessage from '@/ManualApp/Components/Message/UiMessage.vue';

import { mapLeafletMain } from '@/Leaflet/map_leaflet_main';
import {
  convertJsonAgg,
  getPointsFromOur,
} from '@/App/use/getElementPositionForMapModal';

import { usePopup } from '@/ManualApp/use/popup';
import { useRequest } from '@/ManualApp/use/request';

const { message, send } = useRequest();
const { popups, togglePopup } = usePopup({ trackDetail: false });

const props = defineProps({
  event: { type: Object, default: {} },
  params: { type: Object, default: {} },
});

const emit = defineEmits(['close']);

const URL_GET_TRACK_DATA = 'Api/getPositionsOurWithColumns';

const MAP_ID = 'overspeed-track';
const LAT_LNG_MULTIPLIER = 1000000000000000;

const globalObjects = {
  clients: [],
  globalPositions: {},
  positionsFromTemplateDetail: {},
  violationsSettingsList: {},
};

const speedOn = inject('speedOnKey');
const objectsList = inject('globalObjectsList');
const allGeofences = inject('allGeofences');

const detailRows = ref([]);

const isReadyTrack = ref(false);

const getFormatDate = (unix, format) => {
  return formatDateHelper(
    new Date(unix * 1000),
    format || 'dd.mm.yyyy hh:nn:ss',
  );
};

function displayGeofences() {
  allGeofences.forEach((geo) => {
    if (props.params.geofencesIds.includes(geo.id)) {
      const newLayer = mapLeafletMain.replaceLayerFromGeojson(
        geo.layer,
        geo.geoJson,
      );
      newLayer.content = geo.name;
      newLayer.bindTooltip(geo.name);
    }
  });
}

async function displayTrack() {
  const json_agg = await getTrackData();

  const { positions, globalObjects, objName } = preparePolylineData(json_agg);

  const points = getPointsFromOur(positions, objName, '');
  mapLeafletMain.leafletAddPolyline(
    points,
    title.value,
    props.event.oid,
    globalObjects,
  );

  const speedData = prepareSpeedData(positions, props.event);
  mapLeafletMain.addSpeedOnMap(speedData, objName);

  detailRows.value = speedData;

  isReadyTrack.value = true;
}

function preparePolylineData(json_agg) {
  const positions = convertJsonAgg(json_agg);

  // При обсчете последняя позиция не учитывается
  positions.pop();

  const objName = objectsList[props.event.oid].name || '';

  return { positions, globalObjects, objName };
}

function prepareSpeedData(positions, event) {
  const speedData = [];

  const { name, model, stateNumber } = objectsList[props.event.oid];

  let date, from, speed, overspeed;

  for (let position of positions) {
    date = getFormatDate(position.time / 1000, 'dd.mm.yyyy');
    from = getFormatDate(position.time / 1000, 'hh:nn:ss');
    speed = position[speedOn.value] / 10;
    overspeed = speed - event.sspd;

    speedData.push({
      lat: position.lat / LAT_LNG_MULTIPLIER,
      lon: position.lon / LAT_LNG_MULTIPLIER,
      info: `
          <b>${name}</b> (${model}, ${stateNumber})<br/>
          Превышение скорости на ${overspeed} км/ч<br/>
          Дата: ${date}<br/>
          Время: ${from}<br/>
          Скорость: ${speed} км/ч
        `,

      from: position.time,
      overspeed,
      speed,
    });
  }

  return speedData;
}

async function getTrackData() {
  message.type = 'success';
  message.value = 'Загрузка данных...';

  const body = {
    oid: props.event.oid,
    timeBegin: props.event.t1,
    interval: props.event.t2 - props.event.t1,
    columns: `time,lat,lon,${speedOn.value}`,
    // ,head,speed,gspd,pwr,bat,kren,tang,com,dist,rpm,gearbox,violation_1,violation_2,clutch_time_uninterruptedly,pto_cnt_violation,spd_accel
  };
  try {
    const res = await send(URL_GET_TRACK_DATA, 'POST', body);

    if (res.message) {
      throw new Error(res.message);
    }

    message.type = 'success';
    message.value = 'Вывод трека...';

    return res.positions[0].json_agg;
  } catch (error) {
    message.type = 'error';
    message.value =
      'Произошла ошибка. Попробуйте повторить запрос, либо обновите страницу.';
    console.error(error);
  }
}

function displayDetailPopup() {
  togglePopup('trackDetail');
}

const title = computed(() => {
  const { model, stateNumber } = objectsList[props.event.oid];

  const { name: geoName } = allGeofences.find(
    (geo) => geo.id == props.event.gid,
  );

  return `
  Превышение скорости по ${speedOn.value == 'gspd' ? 'GPS/ГЛОНАСС' : 'CAN'} 
  <b>${stateNumber} / ${model} </b><br/>
  &nbsp;в геозоне <b>${geoName}</b>
  `;
});

const interval = computed(() => {
  const trackBegin = getFormatDate(props.event.t1, 'dd.mm.yyyy hh:nn:ss');
  const trackEnd = getFormatDate(props.event.t2, 'dd.mm.yyyy hh:nn:ss');
  return `с ${trackBegin} по ${trackEnd}`;
});

const leftSubtitle = computed(() => {
  return `
  Макс. скорость: ${props.event.mspd} км/ч<br/>
  Макс. установленная скорость при обсчете: ${props.event.sspd} км/ч<br/>
  Превышение скорости на: ${props.event.mspd - props.event.sspd} км/ч
  `;
});

const rightSubtitle = computed(() => {
  const interval = formatTimeHelper(props.event.ti / 86400, 'tt:nn:ss');
  const timeOfMaxSpeed = formatDateHelper(
    new Date(props.event.tmspd * 1000),
    'hh:nn:ss',
  );

  return `
  Время макc. скорости: ${timeOfMaxSpeed}<br/>
  Длительность превышения: ${interval}<br/>
  Пробег по GPS/ГЛОНАСС: ${props.event.dist} м.<br/>
  `;
});

onMounted(() => {
  mapLeafletMain.map_leaflet_initialization(MAP_ID);

  nextTick().then(() => {
    displayGeofences();
    displayTrack();
  });
});
</script>

<style lang="scss">
.my-popup {
  width: 80vw;
  height: 95vh;
  overflow: auto;

  @media (max-width: 500px) {
    width: 100vw;
  }
}

.overspeed-map {
  width: 100%;
  height: calc(95vh - 40px - 75px - 48px - 0.5rem);

  @media (max-width: 800px) {
    height: calc(95vh - 40px - 75px - 48px - 0.5rem - 25px);
  }
  @media (max-width: 600px) {
    height: calc(95vh - 40px - 75px - 48px - 0.5rem - 50px);
  }
  @media (max-width: 570px) {
    height: calc(95vh - 40px - 75px - 48px - 0.5rem - 70px);
  }
  @media (max-width: 560px) {
    height: calc(95vh - 40px - 75px - 48px - 0.5rem - 30px);
  }
  @media (max-width: 370px) {
    height: calc(95vh - 40px - 75px - 48px - 0.5rem - 40px);
  }
}

.lh-1 {
  line-height: 1;
}

.title {
  @media (max-width: 1024px) {
    font-size: 14px;
  }

  &--mobile {
    display: none;
  }
  @media (max-width: 560px) {
    display: none;

    &--mobile {
      font-size: 11px;
      display: block;
    }
  }

  @media (max-width: 400px) {
    &--mobile {
      font-size: 10px;
    }
  }
}
.subtitle {
  display: flex;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 560px) {
    font-size: 11px;

    flex-direction: column;
  }
  & .left,
  & .right {
    width: 50%;
    @media (max-width: 560px) {
      width: 100%;
    }
  }
}
</style>
