<template>
  <div class="graphics-filter">
    <div
      class="graphic-filter__selectDate"
      @click="datepickerClick"
    >
      <ui-datepicker-vue
        class="objects-list-datepicker"
        v-model:date="datepickerDate"
      />
    </div>
    <div class="flex_row_nowrap graphic-filter__body">
      <input
        type="search"
        v-model="searchStr"
        placeholder="Владелец"
        class="form-control filterOn"
      />
      <div class="flex_row_nowrap graphic-filter__btns">
        <button
          class="btn btn-primary config-btn-primary"
          @click="() => (showSmenasModal = true)"
        >
          Смены
        </button>

        <button
          class="btn btn-primary config-btn-primary"
          @click="submitDate"
        >
          Запросить
        </button>
      </div>
    </div>

    <ui-table
      class="violationsGraphicObjectsList"
      :style="{ height: table.height }"
    >
      <template #thead>
        <th
          v-for="(obj, index) in objectListTableHead"
          :key="index"
          style="cursor: pointer"
          @click="() => sortTableOn(obj.column)"
        >
          {{ obj.name }}
          <div
            v-if="sortingColumn === obj.column"
            class="arrow"
            :class="[sortingFromLargerToSmaller ? 'arrow-bottom' : 'arrow-top']"
          ></div>
        </th>
      </template>

      <template #tbody>
        <div
          v-if="!filteredObjects.length"
          class="violationsGraphicObjectsList__loading"
        >
          <preloader />
        </div>
        <tr
          v-for="obj in filteredObjects"
          :key="obj.id"
          v-show="obj.flag"
          class="violations__table"
          @click="() => showSkillsManReportModal_handler(obj.id)"
        >
          <td
            :style="{
              backgroundColor: obj.hex,
            }"
          >
            {{ obj.client }}
          </td>
          <td
            :style="{
              backgroundColor: obj.hex,
            }"
          >
            {{ obj.model }}
          </td>
          <td
            :style="{
              backgroundColor: obj.hex,
            }"
          >
            {{ obj.avtoNo }}
          </td>
          <td
            :style="{
              textAlign: 'center',
              backgroundColor: obj.hex,
              cursor: 'pointer',
            }"
          >
            {{
              obj.violationsPer100km !== 0
                ? obj.violationsPer100km.toFixed(2)
                : '-'
            }}
          </td>
        </tr>
      </template>
    </ui-table>

    <Transition name="fade">
      <my-modal
        v-if="showSmenasModal"
        @close="closeSmenasModal"
      >
        <template #header>
          <span></span>
          <ui-close-button @click="closeSmenasModal" />
        </template>
        <template #body>
          <div class="modal-smenas">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="addSmenas"
                v-model="isAddSmenas"
              />
              <label
                class="form-check-label"
                for="addSmenas"
                >Включить смены</label
              >
            </div>
            <div
              class="form-check smenas-check"
              v-for="(s, i) in smenas"
              :key="i"
            >
              <div class="flex_row_nowrap">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="smena2"
                  :disabled="i === 0 ? true : !isAddSmenas"
                  v-model="s.checked"
                />
                <label
                  class="form-check-label"
                  for="smena2"
                  >Смена {{ i + 1 }}</label
                >
              </div>

              <div class="flex_row_nowrap selects_row">
                <select
                  class="select-input"
                  v-model="s.beginTimeHours"
                  :disabled="!s.checked || !isAddSmenas"
                >
                  <option
                    v-for="h in hoursArray"
                    :key="h.value"
                    :value="h.value"
                    :selected="h.value === s.beginTimeHours"
                  >
                    {{ h.text }}
                  </option>
                </select>
                :
                <select
                  class="select-input"
                  v-model="s.beginTimeMinutes"
                  :disabled="!s.checked || !isAddSmenas"
                >
                  <option
                    v-for="m in minutesArray"
                    :key="m.value"
                    :value="m.value"
                    :selected="m.value === s.beginTimeMinutes"
                  >
                    {{ m.text }}
                  </option>
                </select>
                &nbsp;-&nbsp;
                <select
                  class="select-input"
                  v-model="s.endTimeHours"
                  :disabled="!s.checked || !isAddSmenas"
                >
                  <option
                    v-for="h in hoursArray"
                    :key="h.value"
                    :value="h.value"
                    :selected="h.value === s.endTimeHours"
                  >
                    {{ h.text }}
                  </option>
                </select>
                :
                <select
                  class="select-input"
                  v-model="s.endTimeMinutes"
                  :disabled="!s.checked || !isAddSmenas"
                >
                  <option
                    v-for="m in minutesArray"
                    :key="m.value"
                    :value="m.value"
                    :selected="m.value === s.endTimeMinutes"
                  >
                    {{ m.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </my-modal>
    </Transition>

    <Transition name="fade">
      <my-modal
        v-show="showSkillsManReportModal"
        @close="closeSkillsManReportModal"
      >
        <template #header>
          <button
            class="not-print float-left"
            onclick="html_to_excel_helper.export( this, 'skills-man-template-content', 'Навыки MAN суммарный', 'portrait' )"
          >
            Экспорт&nbsp;в&nbsp;EXCEL
          </button>
          <ui-close-button @click="closeSkillsManReportModal" />
        </template>
        <template #body>
          <div
            id="section-violations-graphics-objects-list-modal"
            style="height: calc(100% - 46px); overflow: scroll"
          ></div>
        </template>
      </my-modal>
    </Transition>

    <the-toaster ref="toaster" />
  </div>
</template>

<script>
import TimePicker from '../../Components/DpTimePicker.vue';
import MyModal from '@/App/Components/MyModal.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import UiTable from './UiTable.vue';
import UiDatepickerVue from '@/App/Components/UiDoubleDatepicker.vue';
import SkillsManReport from '../../TemplateComponents/SkillsMan/SkillsManReport.vue';

import {
  checkGearBoxType,
  sortArrayByStringDown,
  sortArrayByStringUp,
} from '@/helpers/MineHelper';
import Preloader from '../../Components/Preloader.vue';
import TheToaster from '../../Components/toaster/TheToaster.vue';
import { OurAggregated } from '../../../src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template';
import { skillsManTemplateHtml } from '../../TemplateComponents/SkillsMan/SkillsManTemplateHtml';

export default {
  name: 'ObjectsList',

  components: {
    TimePicker,
    MyModal,
    UiCloseButton,
    UiTable,
    Preloader,
    TheToaster,
    UiDatepickerVue,
    SkillsManReport,
  },

  props: {
    date: {
      type: Array,
      required: true,
    },
    completeGraphData: {
      type: Boolean,
      required: true,
    },
    selectedSmenas: {
      type: Array,
      required: true,
    },
    clientWidth: {
      type: Number,
      required: true,
    },
    clientHeight: {
      type: Number,
      required: true,
    },
    violationsObjectsList: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'submit',
    'objectsListIsUpdated',
    'getSmenasArray',
    'selectedSmenas',
    'update:date',
  ],

  data() {
    return {
      objectListTableHead: [
        {
          name: 'Владелец',
          column: 'client',
        },
        {
          name: 'Тип КПП',
          column: 'model',
        },
        {
          name: 'Гос. №',
          column: 'avtoNo',
        },
        {
          name: 'Нарушения (на 100 км)',
          column: 'violationsPer100km',
        },
      ],

      showSmenasModal: false,

      isAddSmenas: true,
      smenas: [
        {
          checked: true,

          beginTimeHours: 7,
          beginTimeMinutes: 0,
          endTimeHours: 19,
          endTimeMinutes: 0,
        },
        {
          checked: false,

          beginTimeHours: 0,
          beginTimeMinutes: 0,
          endTimeHours: 0,
          endTimeMinutes: 0,
        },
        {
          checked: false,

          beginTimeHours: 0,
          beginTimeMinutes: 0,
          endTimeHours: 0,
          endTimeMinutes: 0,
        },
      ],

      showSkillsManReportModal: false,
      skillsManReport_data: {},

      table: { width: 0, height: 0 },
      searchStr: '',

      sortingColumn: '',
      sortingFromLargerToSmaller: true,
      filteredObjects: [],
    };
  },

  inject: ['objectsListUpdateTrigger', 'globalObjectsList'],

  watch: {
    searchStr() {
      this.search();
    },
    selectedSmenas: {
      handler(newSelectedSmenas) {
        if (newSelectedSmenas.length !== 0) {
          this.isAddSmenas = true;
          const dividedSmenasTime = newSelectedSmenas.map((objSmena) => {
            const [begin, end] = Object.values(objSmena).map((val) =>
              val.split(':'),
            );
            return {
              begin: { hh: +begin[0], mm: +begin[1] },
              end: { hh: +end[0], mm: +end[1] },
            };
          });
          dividedSmenasTime.map((timesArr, index) => {
            const s = this.smenas[index];
            s.checked = true;
            s.beginTimeHours = timesArr.begin.hh;
            s.beginTimeMinutes = timesArr.begin.mm;
            s.endTimeHours = timesArr.end.hh;
            s.endTimeMinutes = timesArr.end.mm;
          });
        } else {
          this.isAddSmenas = false;
        }
      },
      deep: true,
      immediate: true,
    },
    clientHeight: {
      handler(newClientHeight) {
        const onePercentHeight = newClientHeight / 100;
        this.tableHeightHandler(onePercentHeight);
      },
      immediate: true,
    },
    clientWidth: {
      handler(newClientWidth) {
        const onePercentHeight = this.clientHeight / 100;
        this.tableHeightHandler(onePercentHeight);
      },
      immediate: true,
    },
    objectsListUpdateTrigger: {
      handler() {
        if (
          Object.keys(this.globalObjectsList).length &&
          !this.filteredObjects.length
        ) {
          this.getViolationsObjectsList();
        }
      },
      immediate: true,
    },
    violationsObjectsList: {
      handler() {
        this.sortingColumn = '';
        this.sortingFromLargerToSmaller = true;
        this.sortTableOn('violationsPer100km');
      },
      deep: true,
      // immediate: true,
    },
  },

  computed: {
    timePicker() {
      return TimePicker;
    },

    hoursArray() {
      const arr = [];
      for (let h = 0; h < 24; h++) {
        arr.push({ text: h < 10 ? `0${h}` : h, value: h });
      }
      return arr;
    },
    minutesArray() {
      const arr = [];
      for (let m = 0; m < 60; m++) {
        if (m === 0 || m % 10 === 0) {
          arr.push({ text: m < 10 ? `0${m}` : m, value: m });
        }
      }
      return arr;
    },
    datepickerDate: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit('update:date', val);
      },
    },
  },

  methods: {
    sortTableOn(str) {
      const objects = this.violationsObjectsList.map((obj) => obj);
      if (this.sortingColumn === str) {
        if (this.sortingFromLargerToSmaller) {
          this.filteredObjects = sortArrayByStringUp(objects, str);
        } else {
          this.filteredObjects = sortArrayByStringDown(objects, str);
        }
        this.sortingFromLargerToSmaller = !this.sortingFromLargerToSmaller;
      } else {
        this.sortingColumn = str;
        this.sortingFromLargerToSmaller = true;
        this.filteredObjects = sortArrayByStringDown(objects, str);
      }
    },
    tableHeightHandler(onePercentHeight) {
      if (this.clientWidth <= 768) {
        this.table.height = onePercentHeight * 100 - 208 + 'px';
      } else if (this.clientWidth <= 1550) {
        this.table.height = onePercentHeight * 100 - 173 + 'px';
      } else {
        this.table.height = onePercentHeight * 100 - 130 + 'px';
      }
    },
    closeSmenasModal() {
      this.showSmenasModal = false;
      this.createSmenasArray();
    },
    closeSkillsManReportModal() {
      this.showSkillsManReportModal = false;
    },
    submitDate() {
      if (this.completeGraphData && this.datepickerDate !== null) {
        this.$emit('submit');
      }
    },
    search() {
      this.violationsObjectsList.map((obj) => {
        obj.flag =
          obj.client.toLowerCase().indexOf(this.searchStr.toLowerCase()) !== -1
            ? true
            : false;
      });
    },
    dpFormat(date) {
      const dividedDate = date.toLocaleString().split(',');
      dividedDate[1] = dividedDate[1].slice(0, -3);
      dividedDate[3] = dividedDate[3].slice(0, -3);
      return `${dividedDate[0]} ${dividedDate[1]}, ${dividedDate[2]} ${dividedDate[3]}`;
    },
    createSmenasArray() {
      const smenasArr = [];
      if (this.isAddSmenas) {
        this.smenas.map((obj) => {
          if (obj.checked) {
            const times = Object.values(obj).map((t, index) => {
              if (index === 0) return t;
              return t < 10 ? `0${t}` : t;
            });

            smenasArr.push({
              begin: `${times[1]}:${times[2]}`,
              end: `${times[3]}:${times[4]}`,
            });
          }
        });
      }
      this.$emit('selectedSmenas', smenasArr);
    },
    getViolationsObjectsList() {
      const violationsObjectsList = [];

      const objectsListKeys = Object.keys(this.globalObjectsList);

      for (let i = 0; i < objectsListKeys.length; i++) {
        if (
          this.globalObjectsList[objectsListKeys[i]].name
            .toLowerCase()
            .indexOf('xm01') === -1
        )
          continue;

        violationsObjectsList.push({
          checked: true,
          id: this.globalObjectsList[objectsListKeys[i]].id,
          client: this.globalObjectsList[objectsListKeys[i]].client,
          model: checkGearBoxType(
            this.globalObjectsList[objectsListKeys[i]].model,
          ),
          avtoNo: this.globalObjectsList[objectsListKeys[i]].stateNumber,
          violationsPer100km: 0,
          hex: '',
          flag: true,
        });
      }

      this.$emit('objectsListIsUpdated', violationsObjectsList);
    },
    showSkillsManReportModal_handler(id) {
      const ourAggregated = new OurAggregated(skillsManTemplate);

      const form_desc = {
        beginTime: new Date(this.datepickerDate[0]).getTime(),
        endTime: new Date(this.datepickerDate[1]).getTime(),
        objects: [id],
      };

      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName: 'skillsMan',
        form_desc,
        callback: this.skillsManReportModal_callback,
        roundTo: 10,
      });

      this.$refs.toaster.warning({
        message: 'Отправлен запрос для получения данных.',
        delay: 5000,
      });
    },
    skillsManReportModal_callback(data) {
      this.showSkillsManReportModal = true;
      lodashRenderHtmlByTemplateHtml_helper(
        data,
        skillsManTemplateHtml,
        'section-violations-graphics-objects-list-modal',
      );
      const modal = document.getElementById(
        'section-violations-graphics-objects-list-modal',
      );
      modal.getElementsByTagName('button')[0].style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
.objects-list-datepicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow {
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  display: inline-block;
  &.arrow-top {
    transform: rotate(-45deg) translate(1px, 3.5px);
  }
  &.arrow-bottom {
    transform: rotate(135deg) translate(-4px, -1px);
  }
}
.modal-smenas {
  padding-bottom: 70px;
}
.selects_row {
  align-items: center;
}
.select-input {
  @media (max-width: 768px) {
    width: 55px;
  }
  @media (max-width: 510px) {
    width: 50px;
    font-size: 14px;
  }
  &:disabled {
    width: 100px;

    @media (max-width: 768px) {
      width: 55px;
    }
    @media (max-width: 510px) {
      width: 50px;
      font-size: 14px;
    }
  }
}
.form-check {
  margin-top: 1rem;
  padding-left: 3.25rem;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  @media (max-width: 470px) {
    padding-left: 2.25rem;
  }
  @media (max-width: 435px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 370px) {
    & .selects_row {
      margin-left: -40px;
    }
  }
  &-label {
    @media (max-width: 510px) {
      font-size: 14px;
    }
  }
  & input[type='checkbox'] {
    width: 25px;
    height: 25px;
    margin-top: 0;
    margin-left: -2.25rem;

    @media (max-width: 768px) {
      width: 15px;
      height: 15px;
    }
  }
}

.smenas-check {
  margin-left: 2.25rem;
  @media (max-width: 510px) {
    margin-left: 1rem;
  }
}
.flex_row_nowrap {
  display: flex;
  flex-wrap: nowrap;
}
.graphic-filter__selectDate {
  position: absolute;
  // top: 37.5px;
  top: 40px;
  z-index: 10;
  width: calc(22.5% - 5px);
  margin-left: 5px;
  & .dp__pointer {
    padding: 5px 10px;
    padding-right: 25px;
  }
  @media (max-width: 1645px) {
    & div div div input {
      font-size: 13px;
    }
  }
  @media (max-width: 1360px) {
    & div div div input {
      font-size: 12px;
    }
  }
  @media (max-width: 1240px) {
    & div div div input {
      font-size: 11px;
    }
  }
  @media (max-width: 1024px) {
    & div div div input {
      font-size: 16px;
    }
  }
  @media (max-width: 420px) {
    & div div div input {
      font-size: 14px;
    }
  }
  @media (max-width: 420px) {
    & div div div input {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    & div div div input {
      font-size: 13px;
    }
  }
  @media (max-width: 330px) {
    & div div div input {
      font-size: 12px;
    }
  }
  @media (max-width: 1024px) {
    width: calc(100% - 60px);
  }
  @media (max-width: 769px) {
    // top: 75px;
  }
}
.graphic-filter__body {
  margin: 50px 0 5px 5px;
  @media (max-width: 1550px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    & .filterOn {
      max-width: 100%;
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
  }
}

.graphic-filter__btns {
  & button {
    margin-left: 5px;
  }
  @media (max-width: 1550px) {
    width: 100%;
    margin-top: 5px;

    & button {
      width: 100%;
      & :first-child {
        margin-left: 0;
      }
    }
  }
}
.violationsGraphicObjectsList {
  overflow: scroll;
  padding: 0 0 0 5px;
  //background-color: #fff;
  /* для drag table row */
  position: relative;
  max-width: 100%;

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 270px;
    height: 270px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & table tbody tr {
    cursor: pointer;
    position: relative;
  }

  @media (max-width: 1365px) {
    & table tbody tr td {
      font-size: 12px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
  }
}
.objectsListId {
  & thead th,
  & tbody tr {
    white-space: nowrap;
    line-height: 1.5;
  }
}
.sortable-table {
  & td {
    padding: 0 5px;
  }
}
.violations-nav-button {
  padding: 3px;
  bottom: 0;
  & img {
    width: 35px;
    height: 35px;
  }
}
.objectListTable {
  margin-top: 10px;
}
</style>
