<template>
  <div class="track-motion">
    <div
      :id="MAP_ID"
      class="track-motion__map"
    ></div>
  </div>
</template>

<script setup>
import { nextTick, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { mapLeafletMain } from '@/Leaflet/map_leaflet_main';

const props = defineProps({ params: { type: Object, default: {} } });

const router = useRouter();

const MAP_ID = 'track-motion-map';

const markerIcon = mapLeafletMain.createIcon({
  iconUrl: '/images/markers/marker-icon-green.png',
  shadowUrl: '/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function displayPosition() {
  // localhost:8080/track-motion?type=position&lat=60.86377&lon=72.81068
  const { lat, lon } = props.params;

  const latLon = [+lat, +lon];

  let text = '';

  if (props.params.info) {
    text = props.params.info;
  } else {
    const { lat: rLat, lon: rLon, latDMS, lonDMS } = convertdeg(lat, lon);

    text = `
      ${latDMS}, ${lonDMS}<br>
      ${rLat}, ${rLon}
    `;
  }

  const options = {
    icon: markerIcon,
  };

  mapLeafletMain.addMaker({ latLon, text, options });
  mapLeafletMain.map.fitBounds([latLon]);
}

async function displayViolations() {
  // localhost:8080/track-motion?type=violations&oid=53519&=from=1679940522&interval=508
}

function displayOverspeed() {}

onMounted(async () => {
  mapLeafletMain.map_leaflet_initialization(MAP_ID);

  await nextTick();

  if (props.params.type === 'position') {
    displayPosition();
    return;
  }
});
</script>

<style lang="scss" scoped>
.track-motion {
  &__map {
    width: 100%;
    height: 100vh;
  }
}
</style>
