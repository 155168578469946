export default {
  'Уровень масла и ОЖ': [],

  'Правильный выбор передач для трогания': [13, 14, 15],

  'Типматик в ручном режиме': [],

  'Трогание с пробуксовкой сцепления': [44, 45, 8, 9, 43],

  'Смена диапазона типматика D/Dx': [],

  'Буксование': [8, 9],

  'Превышение допустимых оборотов ДВС': [4],

  'Движение с включенной межколесной блокировкой': [12],

  'Для механической КПП: неполное включение сцепления': [39],

  'Переключение КПП с переднего на задний ход при скорости > 0': [40],

  'Включение/выключение блокировок': [10],

  'Вкл/выкл пониженной передачи РКП': [11],

  'Движение с включенным КОМ': [27, 28, 29, 30],
};
