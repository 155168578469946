export default class DragElement_helper {
  element;
  dragElement;
  cloneElement;

  startCursorX;
  startCursorY;
  startX;
  startY;

  width;
  height;

  constructor(element, display = 'block') {
    this.display = display;
    const dragElement =
      element.querySelector('.block-draggable-header') || element;
    dragElement.addEventListener('mousedown', this.mouseDown);

    this.element = element;
    this.dragElement = dragElement;
  }

  mouseDown = () => {
    const cloneElement = this.element.cloneNode(true);
    cloneElement.draggable = true;

    // dragElement.draggable = true;
    cloneElement.addEventListener('dragstart', this.dragStart);
    document.addEventListener('mouseup', this.mouseUp);

    const { top, left } = this.element.getBoundingClientRect();
    cloneElement.style.position = 'absolute';
    cloneElement.style.left = `${left}px`;
    cloneElement.style.top = `${top}px`;

    this.element.after(cloneElement);

    this.cloneElement = cloneElement;
  };

  mouseUp = () => {
    if (this.cloneElement) {
      document.removeEventListener('mouseup', this.mouseUp);
      this.cloneElement.removeEventListener('dragstart', this.dragStart);
      this.cloneElement.remove();
      this.cloneElement = null;
    }
  };

  dragStart = (event) => {
    this.startCursorX = event.pageX; //Начальная позиция курсора по оси X
    this.startCursorY = event.pageY; //Начальная позиция курсора по оси Y

    const elem = event.target;
    const { top, left, width, height } = elem.getBoundingClientRect();
    this.startX = left;
    this.startY = top;
    this.width = width;
    this.height = height;

    elem.addEventListener('dragend', this.dragEnd);
    setTimeout(() => {
      this.element.style.display = 'none';
      this.cloneElement.style.display = 'none';
    }, 0);
  };

  dragEnd = (event) => {
    const elem = event.target;

    let top = this.startY + event.pageY - this.startCursorY;
    let left = this.startX + event.pageX - this.startCursorX;
    if (top < 0) {
      top = 0;
    }
    if (left + this.width < 40) {
      left = 40 - this.width;
    }

    const { clientHeight, clientWidth } = document.documentElement;
    if (left > clientWidth - 40) {
      left = clientWidth - 40;
    }
    if (top > clientHeight - 40) {
      top = clientHeight - 40;
    }

    this.element.style.top = `${top}px`;
    this.element.style.left = `${left}px`;
    this.element.style.display = this.display;

    elem.removeEventListener('dragend', this.dragEnd);

    this.mouseUp();
  };
}
