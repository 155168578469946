<template>
  <div
    class="loader"
    :style="[
      { border: `${getBorderSize}px solid #f3f3f3` },
      { borderTop: `${getBorderSize}px solid blue` },
      { borderBottom: `${getBorderSize}px solid blue` },
      { width: `${props.width}px` },
      { height: `${props.height}px` },
    ]"
  ></div>
</template>

<script setup>
import { computed } from '@vue/runtime-core';

const props = defineProps({
  width: {
    type: [Number, String],
    require: false,
    default: 120,
  },
  height: {
    type: [Number, String],
    require: false,
    default: 120,
  },
});
const getBorderSize = computed(() => {
  return ((props.width + props.height) / 2 / 100) * 13.33;
});
</script>

<style lang="scss" scoped>
.loader {
  // border: 16px solid #f3f3f3;
  border-radius: 50%;
  // border-top: 16px solid blue;
  // border-bottom: 16px solid blue;
  // width: 120px;
  // height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
