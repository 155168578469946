<template>
  <div
    id="track-player-btn-2"
    title="Плеер треков на карте"
    @click="displayPlayer"
  >
    <span class="target-show">
      <img
        src="/images/track_player/track_player.svg"
        alt="Трек_плеер"
      />
    </span>
  </div>

  <select
    id="trackPlayerSelect"
    class="d-none"
  >
    <option
      :value="props.treatmentPositions?.objId"
      selected
    ></option>
  </select>
</template>

<script setup>
import { TrackPlayerPanel } from '@/Components/trackPlayerPanel';
import DragElement_helper from '@/helpers/dragElement_helper';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps({
  mapWidth: Number,
  treatmentPositions: Object,
  map: Object,
});

const panel = ref();

watch(
  () => props.treatmentPositions,
  async (positions) => {
    if (!positions) return;
    await nextTick();
    panel.value = new TrackPlayerPanel({
      buttonId: 'track-player-btn-2',
      positionsListId: 'trackPlayerSelect',
      DragElement_helper,
      leafletMain: props.map,
      globalObjects: {
        globalPositions: { [positions.objId]: props.treatmentPositions },
      },
    });
  },
  { immediate: true },
);
onBeforeUnmount(() => {
  if (panel.value) panel.value.destroy();
});
function displayPlayer() {}
</script>

<style lang="scss">
#track-player-btn-2 {
  width: 30px;
}

#track-player-btn-2 img {
  display: block;
  width: 29px;
  height: 29px;
  cursor: pointer;
}
</style>
