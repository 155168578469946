<template>
  <table
    width="auto"
    border="1"
    cellpadding="0"
    cellspacing="0"
    class="admin-panel__table"
  >
  <thead>
    <tr>
      <th v-for="(val, key) in getIsOrganizationObjectValue('columns')">
        {{ val }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="row in getIsOrganizationObjectValue('dataTable')">
      <template v-for="(val, key) in getIsOrganizationObjectValue('columns')">
        <td
          :data-key="myKeys[key]"
          :data-value="row[key] ?? ''"
        >
          <template v-if="key === 'change_this'">
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg
              class="cursor-pointer"
              title="Изменить"
              @click="editBtnHandler(row)"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              enable-background="new 0 0 20 20"
              xml:space="preserve"
            >
              <image
                id="image0"
                width="20"
                height="20"
                x="0"
                y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA6lBMVEUAAABYRGZYRmZVQmdX
          RGVYRWZYRWZXR2e7tsRZR2fx5ezt8PW3s8JaR2j/rcL9ssXw4upXRWX/rcL9ssXx4OhXQ2T/rcL/
          oLb9orbw3+dYQ2bw3+f6mK7wk6ft3eW5tcP7mK7xjqLwk6fu2+XwjKHyjaL/sb//54v/25r/tbv/
          3Zb/yqj/35b/3Zf/05v/qqz/zJn/3pf/0Zz/3Zf/3Zf6wJzzm5//3pX92JfxkaAAAAC5n4L/3pb/
          35b/35b/35X/zH9ZQ2a4noL/4Jb/4Zb//39YRWZZRmfu8fb/rsP/nbLxjaL/35f///+2AWEsAAAA
          R3RSTlMAS38bbP7gIG39bv77/m39/YD+/PxM/fz9/W38/Pz7a/z9+23+d2wL9f5T/KP+/v4F7v1F
          /f3+lvR0AeD+7aFQCjnjlUQENwUKzTcAAAABYktHRE2AaCBlAAAACXBIWXMAAA7EAAAOxAGVKw4b
          AAAAB3RJTUUH5gYIFDgCpINZCwAAALRJREFUOMvFz8cSgkAQRdE2oCKYEyrmnHPCHEex//97DLhk
          2gVl+bbn1lQPAG82uwOoOQXmctPOqMIjMkYVXkn2GYX5Hf5A8PopQqYevkWiRiHEzD2eUJKvQkjx
          XNeV9FUWCdfVTFbKEZ4v3Isl2ssVC67+2a3e/6v/Vb94rd4gHZrYou4DaGOn2yO8P0DEIfd9gNHT
          cTyZ8hxm+N58wXFtuUJcb7Y7jsOeHY6nM/B32WtA7QGr1j3Nrx0zLAAAACV0RVh0ZGF0ZTpjcmVh
          dGUAMjAyMi0wNi0wOFQxNzo1NjowMiswMzowMNUcvsIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjIt
          MDYtMDhUMTc6NTY6MDIrMDM6MDCkQQZ+AAAAAElFTkSuQmCC"
                alt="Изменить"
              />
            </svg>
          </template>

          <template v-else-if="key.indexOf('time') === -1">
            <template v-if="translatedFields.indexOf(key) !== -1">
              {{ translate[row[key]] || 'нет' }}
            </template>

            <template v-else-if="key === 'contractor'">
              {{ getContractor(row['id_contractor']) }}
            </template>

            <template v-else>
              {{ row[key] }}
            </template>
          </template>

          <template v-else-if="row[key] > 0">
            {{ getStringByUnixTime(+row[key] * 1000) }}
          </template>

          <template v-else>
            {{
              ['validity_time', 'validity_time_from'].indexOf(key) !== -1
                ? 'нет ограничений'
                : 'не зафиксирован'
            }}
          </template>
        </td>
      </template>
    </tr>
  </tbody>
  </table>
</template>

<script setup>
const usersHeader = {
  change_this: '',
  line_id: 'ID',
  login: 'Логин',
  psw: 'Пароль',
  role: 'Роль',
  name: 'Имя',
  owner: 'Владелец',
  contractor: 'Контрагент',
  description: 'Описание',
  // 'right_user_ids' : 'Дополнительные права на пользователей',
  created_time: 'Создан',
  last_inter_time: 'Последний вход',
  validity_time_from: 'Действует с',
  validity_time: 'Действует до',
  objects_mask: 'Маска объектов',
  templates_names: 'Отчеты (названия)',
  rule_geofences_watch: 'Просмотр геозон',
  rule_geofences_create: 'Создание геозон',
  rule_geofences_update: 'Изменение геозон',
  rule_geofences_delete: 'Удаление геозон',
  rule_organizations_create: 'Создание организаций',
  rule_fuel_watch: 'Просмотр топлива',
  rule_contract_watch: 'Просмотр договоров',
  rule_contract_create: 'Создание договоров',
  rule_contract_edit: 'Изменение договоров',
  rule_contract_delete: 'Удаление договоров',
  rule_contractor_watch: 'Просмотр контрагентов',
  rule_contractor_create: 'Создание контрагентов',
  rule_contractor_edit: 'Изменение контрагентов',
  rule_contractor_delete: 'Удаление контрагентов',
  rule_vehicle_watch: 'Просмотр транспортных средств',
  rule_vehicle_create: 'Создание транспортных средств',
  rule_vehicle_edit: 'Изменение транспортных средств',
  rule_vehicle_delete: 'Удаление транспортных средств',
  rule_token1c_create: 'Создание токена для 1С',
  active: 'Активен',
};
const translate = {
  yes: 'да',
  no: 'нет',
  block: 'заблокирован',
  f: 'нет',
  t: 'да',
  f: 'нет',
  false: 'нет',
  n: 'нет',
  no: 'нет',
  off: 'нет',
  true: 'да',
  y: 'да',
  yes: 'да',
  on: 'да',
  main_admin: '!!! ГЛАВНЫЙ АДМИНИСТРАТОР !!!',
  admin: '!!! Администратор сервера !!!',
  customer_admin: '! Администратор клиента !',
  user: 'Пользователь',
};
const translatedFields = [
  'rule_geofences_watch',
  'rule_geofences_create',
  'rule_geofences_update',
  'rule_geofences_delete',
  'rule_organizations_create',

  'rule_contract_create',
  'rule_contract_delete',
  'rule_contract_edit',
  'rule_contract_watch',
  'rule_contractor_create',
  'rule_contractor_delete',
  'rule_contractor_edit',
  'rule_contractor_watch',
  'rule_vehicle_create',
  'rule_vehicle_delete',
  'rule_vehicle_edit',
  'rule_vehicle_watch',
  'rule_token1c_create',

  'rule_fuel_watch',
  'active',
  'role',
];
const myKeys = {
  login: 'myLogin',
  psw: 'password',
  name: 'title',
};

const props = defineProps({
  userRole: {
    type: String,
    require: true,
  },
  isOrganization: {
    type: Boolean,
    require: true,
  },
  data: {
    type: Object,
    require: true,
  },
  contractors: {
    type: Array,
    default: [],
  },
});

const emit = defineEmits(['edit']);

const editBtnHandler = (val) => {
  val.isOrganization = props.isOrganization;
  emit('edit', val);
};

const getContractor = (id_c) => {
  const contractor = props.contractors.find((c) => c.id == id_c);

  if (contractor) return contractor.name;

  return 'Нет контрагента';
};

const getIsOrganizationObjectValue = (str) => {
  if (!str) return '';

  if (['main_admin', 'admin'].hasOwnProperty(props.userRole)) {
    usersHeader['rights_on_this'] = 'Права на логин';
  }
  const organizationsHeader = usersHeader;

  if (str === 'dataTableAttr') {
    if (props.isOrganization) return ' organizations';

    return ' users';
  }
  if (str === 'columns') {
    if (props.isOrganization) return organizationsHeader;

    return usersHeader;
  }
  if (str === 'dataTable') {
    if (props.isOrganization) return props.data.organizationsData;

    return props.data.usersData;
  }
};
const getStringByUnixTime = (unixtime) => {
  const d = new Date(unixtime);

  const year = d.getFullYear();
  let month = 1 + d.getMonth();
  let day = d.getDate();

  let hours = d.getHours();
  let minutes = d.getMinutes();

  month = rotateToStringLen2_helper(month);
  day = rotateToStringLen2_helper(day);
  hours = rotateToStringLen2_helper(hours);
  minutes = rotateToStringLen2_helper(minutes);

  const date = day + '.' + month + '.' + year + ' ';

  return date + hours + ':' + minutes;
};
</script>

<style lang="scss" scoped>
.admin-panel__table {
  border-top: none;
}
thead tr th{
  padding: 0 5px;
  border: 0 1px solid #ddd;
  background: #efefef;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  opacity: 1;
  background-color: #fff;
  z-index: 1;
};
thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
  padding: 2px 0;
}
</style>
