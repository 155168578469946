<template>
  <table
    class="text-align-center"
    border="1"
    cellpadding="5"
    style="
      border-collapse: collapse;
      border: 1px solid black;
      caption-side: top;
      margin-right: 5px;
    "
  >
    <thead>
      <tr class="border-bottom-tr print-repeat print-frozen-row">
        <th data-minwidth="5">№</th>
        <th
          class="text-left"
          colspan="9"
        >
          Наименования
        </th>
        <th colspan="2">Значения</th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(row, index) of TOTAL_ROWS"
        :key="index"
      >
        <td
          data-formatnum="12"
          v-html="index + 1"
        ></td>
        <td
          class="text-left"
          colspan="9"
          v-html="row.label"
        ></td>
        <td
          data-formatnum="5"
          colspan="2"
          @click="
            () =>
              !['н/д', '0'].includes(row.format(props.object.sum[row.name])) &&
              row.onClick &&
              row.onClick()
          "
        >
          <div
            :class="[
              {
                'my-link':
                  !['н/д', '0'].includes(
                    row.format(props.object.sum[row.name]),
                  ) && row.onClick,
              },
            ]"
            v-html="
              tableFormat(
                row.format
                  ? row.format(props.object.sum[row.name])
                  : props.object.sum[row.name],
              )
            "
          ></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { inject } from 'vue';

import { myRoundNumber_helper, tableFormat } from '@/helpers/main_helper';

const props = defineProps({ object: Object });
const emit = defineEmits(['displayTrack']);

const toHours = inject('toHours');

const TOTAL_ROWS = [
  {
    name: 'engine_time',
    label: 'Моточасы работы ДВС в периоде, час.',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(toHours(val), 1)}`,
    onClick() {
      displayTrack();
    },
  },
  {
    name: 'active_time',
    label: 'Моточасы при выполнении работы (по инклинометру), час.',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(toHours(val), 1)}`,
  },
  {
    name: 'usageFactor',
    label: 'Коэффициент использования (по инклинометру), %',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(val, 1)}`,
  },
  {
    name: 'dailyAverageEngineTime',
    label: 'Ежедневная средняя наработка ДВС в периоде,  час.',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(val, 1)}`,
  },
  {
    name: 'dailyAverageActiveTime',
    label:
      'Ежедневные средние моточасы при выполнении работы (по инклинометру), час.',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(val, 1)}`,
  },
  {
    name: 'dailyAverageUsageFactor',
    label:
      'Ежежедневный средний коэффициент использования (по инклинометру), %',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(val, 1)}`,
  },
  {
    name: 'hirpm_notactime',
    label: 'Повышенные обороты без работы по инклинометру, час.',
    format: (val) =>
      Number.isNaN(val) ? 'н/д' : `${myRoundNumber_helper(toHours(val), 1)}`,
  },
];

function displayTrack() {
  emit('displayTrack');
}
</script>

<style lang="scss"></style>
