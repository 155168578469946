import {
  roundNumber_helper,
  getPercent,
  formatTimeHelper,
  tableFormat,
  checkSpecVehicle,
} from '@/helpers/main_helper';

import { IPoints, ITreatmentPositions } from './getPositionsFromOur';

import { IPeriod, Smenas } from '@/Template/Smenas';
import {
  TSettingOfValues,
  aggregationCalculating,
  mathArray,
} from '@/Template/AggregationCalculating';
import { concatAggregationWithDetail } from '@/App/use/getElementPosition';

tableFormat;

export function useTrackInfo(
  {
    points,
    getBegin,
    getEnd,
    trackBegin,
    trackEnd,
    stateNumber,
    objName,
  }: ITreatmentPositions,
  timeCheck: Date,
  objCategory?: string | number,
) {
  const {
    engine_time,
    engine_move,
    active_time,
    hirpm_notactime,
    distSumm,
    gdist_not_engine,
  } = getEngineMoveTimePerPeriod(points, +getBegin * 1000, +getEnd * 1000);

  const isSpecVehicle = checkSpecVehicle(objCategory);

  let dist = 0;
  let dist_not_engine = 0;
  let avg_speed = 0;
  let engine_work_time = '00:00:00';
  let active_work_time = '00:00:00';
  let engine_move_time = '00:00:00';
  let hirpm_notactime_string = '00:00:00';
  let percent_active_work_in_engine = 0;

  if (points.allValues.length > 0) {
    dist = distSumm;

    dist = Math.round(dist / 100) / 10;

    dist_not_engine = Math.round(gdist_not_engine / 100) / 10;

    avg_speed =
      engine_move > 0 ? Math.round(dist / (Number(engine_move) / 3600)) : 0;

    engine_work_time = formatTimeHelper(engine_time / 86400, ' tt:nn:ss');
    active_work_time = formatTimeHelper(active_time / 86400, ' tt:nn:ss');
    engine_move_time = formatTimeHelper(engine_move / 86400, ' tt:nn:ss');
    hirpm_notactime_string = formatTimeHelper(
      points.allValues[points.allValues.length - 1].hirpm_notactime / 86400,
      ' tt:nn:ss',
    );

    if (engine_time) {
      percent_active_work_in_engine = getPercent(active_time, engine_time);
    }
  }

  let trackInfo = `
      Трек по объекту ${objName} ${stateNumber}<br>
      с ${trackBegin} по ${trackEnd}<br>
    `;

  if (isSpecVehicle) {
    trackInfo += `
          Пробег по GPS (км): ${tableFormat(dist)}<br>
          Пробег по GPS (без работы ДВС, км): ${tableFormat(
            dist_not_engine,
          )}<br>
          Время работы ДВС: ${tableFormat(engine_work_time)}<br>
          Время активной работы ДВС: ${tableFormat(active_work_time)}<br>
          Время повышенных оборотов ДВС без активной работы: ${tableFormat(
            hirpm_notactime_string,
          )}<br>
          Процент активной работы: ${tableFormat(
            percent_active_work_in_engine,
          )}%
        `;
  } else {
    trackInfo += `
        Пробег (км): ${tableFormat(dist)}<br>
        Время работы ДВС: ${tableFormat(engine_work_time)}<br>
        Время работы ДВС (в движении): ${tableFormat(engine_move_time)}<br>
        Средняя скорость (км/ч): ${tableFormat(avg_speed)}
      `;
  }

  const queryEnd = new Date();
  const queryInterval = (+queryEnd - +timeCheck) / 1000;
  const infoForShow = `
  По объекту ${objName}, получено ${points.allValues.length} позиций с сервера
  за период с ${trackBegin} по ${trackEnd} 
  (запрос выполнен за ${roundNumber_helper(queryInterval, 1)} секунд)
  `;

  return { trackInfo, infoForShow };
}

function getEngineMoveTimePerPeriod(
  points: IPoints,
  trackBegin: number,
  trackEnd: number,
): {
  engine_time: number;
  engine_move: number;
  active_time: number;
  hirpm_notactime: number;
  distSumm: number;
  gdist_not_engine: number;
} {
  const { allValues } = points;

  const positionsWithAggregation = concatAggregationWithDetail({
    detail: allValues,
    aggregation: points?.aggregation?.posArray,
  });

  const smenas = new Smenas({ SmenasFlag: false });
  smenas.getSmenasSetting(5, trackBegin, trackEnd);

  const Periods = smenas.setSmenas['Periods'];

  const settingOfValues: TSettingOfValues = {
    time: [1, false],
    distSumm: [1, true],
    engine_time: [1, true],
    active_time: [1, true],
    engine_move: [1, true],
    hirpm_notactime: [1, true],
    gdist_not_engine: [1, true],
  };

  if (Periods) {
    aggregationCalculating(
      positionsWithAggregation,
      settingOfValues,
      Periods,
      trackBegin,
      trackEnd,
    );
  }

  if (Periods && Periods.length) {
    const {
      engine_time,
      engine_move,
      active_time,
      hirpm_notactime,
      distSumm,
      gdist_not_engine,
    } = getSummByPeriods(Periods, settingOfValues);

    return {
      engine_time,
      engine_move,
      active_time,
      hirpm_notactime,
      distSumm,
      gdist_not_engine,
    };
  } else {
    return {
      engine_time: 0,
      engine_move: 0,
      active_time: 0,
      hirpm_notactime: 0,
      distSumm: 0,
      gdist_not_engine: 0,
    };
  }
}

function getSummByPeriods(
  Periods: IPeriod[],
  settingOfValues: TSettingOfValues,
) {
  const getObjectWithEmptyValues = () => {
    const obj: { [key: string]: any } = {};

    for (const key in settingOfValues) {
      if (settingOfValues[key].toString() === '[object Object]') {
        obj[key] = {};
        for (const k in settingOfValues[key]) {
          obj[key][k] = 0;
        }
        continue;
      }

      obj[key] = 0;
    }

    return obj;
  };

  const accum = getObjectWithEmptyValues();

  return Periods.reduce((acc, period) => {
    if (!Object.keys(period.webSumm).length) return acc;

    acc = mathArray(period.webSumm, acc, '+', settingOfValues, true);

    return acc;
  }, accum);
}
