<template>
  <div
    class="toast"
    :class="className"
  >
    <img
      v-if="icon === 'default'"
      class="toast__icon"
      :src="imagePath('/images/notifications/default.svg')"
      alt="notification"
    />
    <img
      v-if="icon === 'success'"
      class="toast__icon"
      :src="imagePath('/images/notifications/success.svg')"
      alt="notification"
    />
    <img
      v-if="icon === 'warning'"
      class="toast__icon"
      :src="imagePath('/images/notifications/warning.svg')"
      alt="notification"
    />
    <img
      v-if="icon === 'error'"
      class="toast__icon"
      :src="imagePath('/images/notifications/error.svg')"
      alt="notification"
    />
    <span>{{ text }}</span>
    <img
      class="toast__icon__close"
      src="/images/notifications/close.svg"
      alt="notification"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'UIToast',

  props: {
    className: String,
    icon: String,
    text: String,
  },

  emits: ['close'],

  methods: {
    imagePath(path) {
      return window.location.origin + path;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';
.toast {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 18px;
  line-height: 28px;
  width: auto;
  opacity: 1;
}

.toast + .toast {
  margin-top: 20px;
}

.toast__icon {
  margin-right: 8px;
  &__close {
    margin-left: 16px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 2px $txt-primary-color;
    }
  }
}

.toast.toast_default {
  color: $txt-primary-color;
  background-color: rgba($default-color, 0.15);
  box-shadow: 0px 4px 8px rgba($default-color, 0.15);
  border: 1px solid $default-color;
}
.toast.toast_success {
  color: $txt-primary-color;
  background-color: rgba($success-color, 0.15);
  box-shadow: 0px 4px 8px rgba($success-color, 0.15);
  border: 1px solid $success-color;
}

.toast.toast_warning {
  color: $txt-primary-color;
  background-color: rgba($warning-color, 0.15);
  box-shadow: 0px 4px 8px rgba($warning-color, 0.15);
  border: 1px solid $warning-color;
}

.toast.toast_error {
  color: $txt-primary-color;
  background-color: rgba($error-color, 0.15);
  box-shadow: 0px 4px 8px rgba($error-color, 0.15);
  border: 1px solid $error-color;
}
</style>
