export const DiagnosticSettings = {
  ObjectsListTableSettings: [
    {
      show: false,
      name: 'checkboxColumn',
      textHtml: '"O" - Выполнить выбранный отчет за указанный период',
      disabled: true,
    },
    {
      show: false,
      name: 'clientColumn',
      textHtml: 'Владелец',
      disabled: false,
    },
    {
      show: true,
      name: 'maskWithNameColumn',
      textHtml: 'Полное наименование объекта',
      disabled: false,
    },
    {
      show: false,
      name: 'maskColumn',
      textHtml: 'Маска объекта',
      disabled: false,
    },
    {
      show: true,
      name: 'nameColumn',
      textHtml: 'Наименование',
      disabled: false,
    },
    {
      show: true,
      name: 'stateNumberColumn',
      textHtml: 'Гос. №',
      disabled: false,
    },
    {
      show: true,
      name: 'idColumn',
      textHtml: 'ID',
      disabled: false,
    },
    {
      show: true,
      name: 'speedColumn',
      textHtml: 'Скорость',
      disabled: false,
    },
    {
      show: true,
      name: 'lastPosTimeColumn',
      textHtml: 'Последние данные',
      disabled: false,
    },
    {
      show: true,
      name: 'lastTimeAggregationColumn',
      textHtml: 'Готовность отчета',
      disabled: false,
    },
    {
      show: true,
      name: 'installTimeColumn',
      textHtml: 'Дата установки',
      disabled: false,
    },
    {
      show: true,
      name: 'modelColumn',
      textHtml: 'Модель',
      disabled: false,
    },
    {
      show: true,
      name: 'latColumn',
      textHtml: 'Широта',
      disabled: false,
    },
    {
      show: true,
      name: 'lonColumn',
      textHtml: 'Долгота',
      disabled: false,
    },
    {
      show: true,
      name: 'vinColumn',
      textHtml: 'VIN',
      disabled: false,
    },
  ],
  checkedObjectsListTableSettings: [
    {
      show: true,
      name: 'lastPositionColumn',
      textHtml:
        '"М" - Показать на карте последнюю полученную от объекта позицию',
      disabled: true,
    },
    {
      show: true,
      name: 'checkboxColumn',
      textHtml: '"O" - Выполнить выбранный отчет за указанный период',
      disabled: true,
    },
    // {
    //   show: true,
    //   name: 'maskWithNameColumn',
    //   textHtml: 'Полное наименование объекта',
    //   disabled: false,
    // },
    {
      show: true,
      name: 'nameColumn',
      textHtml: 'Наименование',
      disabled: false,
    },
    {
      show: true,
      name: 'stateNumberColumn',
      textHtml: 'Гос. №',
      disabled: false,
    },
    {
      show: true,
      name: 'getPositionsColumn',
      textHtml: '"З" - Запросить позиции за период и построить трек на карте',
      disabled: true,
    },
    {
      show: false,
      name: 'removePositionsColumn',
      textHtml: '"<s>З</s>" - Удалить ранее полученный запрос и трек на карте',
      disabled: true,
    },
  ],
};
