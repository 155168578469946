export const useScrollTo = async (path: string, stepCallbacks: any[] = []) => {
  const steps = path.split('/');

  let step = '';

  for (let i = 0; i < steps.length; i++) {
    step += i == 0 ? steps[i].replace('#', '') : `/${steps[i]}`;
    const nextStep = step + `/${steps[i + 1]}`;

    if (!stepCallbacks[i]) stepCallbacks[i] = () => {};
    await stepCallbacks[i](step, nextStep);

    const elem = document.getElementById(step) as HTMLBodyElement;

    if (!elem) continue;

    elem.scrollIntoView({
      block: i == 0 ? 'start' : 'center',
      behavior: 'smooth',
    });
  }
};
