<template>
  <div class="field">
    <ui-label
      v-if="props.label"
      :for="props.id"
      :html="props.label"
      :required="props.required"
    />

    <textarea
      v-if="props.type === 'textarea'"
      v-model="value"
      class="field__input"
      :class="{ invalid: props.error }"
      :type="props.type"
      :id="props.id"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      autocomplete="off"
      @blur="() => emit('blur')"
    ></textarea>
    <input
      v-else
      v-model="value"
      class="field__input"
      :class="{ invalid: props.error }"
      :type="props.type"
      :id="props.id"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      autocomplete="off"
      @blur="() => emit('blur')"
    />
    <small
      v-if="props.error"
      class="field__error"
      >{{ props.error }}</small
    >
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useField } from '@/ManualApp/use/field';
import UiLabel from './UiLabel.vue';

const props = defineProps({
  required: Boolean,
  disabled: Boolean,

  id: { type: String, default: '' },
  type: { type: String, default: 'text' },
  value: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  label: { type: String, default: '' },
  error: { type: String, default: '' },
  mask: { type: [Boolean, Function], default: false },
});

const emit = defineEmits(['update:value', 'blur']);

const value = computed({
  get() {
    return props.value;
  },
  set(val) {
    const value = props.mask ? props.mask(val) : val;
    emit('update:value', value);
  },
});
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';
.field {
  width: 100%;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  &__label {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #6f7482;
    display: flex;
    & .required {
      color: $error-color;
    }
  }
  &__input {
    padding: 8px 12px;

    background: $background-color;

    outline: none;
    border: 1px solid #4a4c53;
    border-radius: 4px;

    &:active,
    &:focus {
      transition: border 0.22s;
      border: 1px solid $success-color;
    }
    &.invalid {
      border-color: $error-color;
    }
  }
  &__error {
    color: $error-color;
  }
}
</style>
