import { useRequest } from './request';
import { useTable } from './table';
import { useAdditional } from './additional';

import { trueInDbHelper } from '@/helpers/trueInDbHelper';

import { STORE } from '../STORAGE/STORAGE';

const PATH_SEND_TARIFF = 'Api/manual/tarifVehicle';

const { send } = useRequest();

const tolocal = (time) => {
  return formatDateHelper(new Date(time * 1000), 'dd.mm.yyyy hh:nn');
};
export const useTariffs = {
  sortByTimeFrom(tariffsArray) {
    if (!tariffsArray instanceof Array) return [];

    tariffsArray.sort((a, b) => b.t_from - a.t_from);

    return tariffsArray;
  },

  getCurrentContract(contracts) {
    let max_t_from = 0;
    let current_contract = {};

    const NOW_SECOND = Date.now() / 1000;

    for (let key in contracts) {
      const { t_from, t_to, tarifsContractor } = contracts[key];

      // 0 = Не определенный срок
      if (t_to != 0) {
        if (+t_to < NOW_SECOND) continue;
      }

      if (+t_from > NOW_SECOND) continue;
      if (+t_from < max_t_from) continue;
      if (!tarifsContractor || !tarifsContractor.length) continue;

      current_contract = contracts[key];
      max_t_from = t_from;
    }

    return current_contract;
  },

  getCurrentContractorContractTariffs(id_c, t_from, t_to) {
    const res = { current: {}, all: [] };
    const contractorContracts = STORE.contractorsContracts[id_c];

    if (contractorContracts === undefined) return res;

    const current_contract = this.getCurrentContract(contractorContracts);

    return this.getCurrentContractTariffs(current_contract, t_from, t_to);
  },

  getContractorVehicleContractTariffs(id_c, id_v, t_from, t_to) {
    const res = { current: {}, all: [] };
    const contractorContracts = STORE.contractorsContracts[id_c];

    if (contractorContracts === undefined) return res;

    const vehicleTariffs = [];

    for (let id_d in contractorContracts) {
      const contractorContract = contractorContracts[id_d];

      if (contractorContract.vehicles.includes(+id_v)) {
        const tariffs = this.getCurrentContractTariffs(
          contractorContract,
          t_from,
          t_to,
        );

        tariffs.all.forEach((t) => {
          vehicleTariffs.push(t);
        });
      }
    }

    if (vehicleTariffs.length) {
      const filtredTariffsByInterval = this.filterByInterval(
        vehicleTariffs,
        t_from,
        t_to,
      );

      res.all = this.sortByTimeFrom(filtredTariffsByInterval);
      res.current = res.all[0];
      return res;
    }

    return res;
  },

  getCurrentContractTariffs(current_contract, t_from, t_to) {
    const res = { current: {}, all: [] };
    let tariffs;
    if (
      current_contract.tarifsContractor &&
      current_contract.tarifsContractor.length
    ) {
      const filtredTariffsByInterval = this.filterByInterval(
        current_contract.tarifsContractor,
        t_from,
        t_to,
      );
      tariffs = this.sortByTimeFrom(filtredTariffsByInterval);
    } else {
      const contractorTariffs = [];
      for (let id in STORE.tarifsContractor) {
        if (STORE.tarifsContractor[id].id_d == current_contract.id) {
          contractorTariffs.push(STORE.tarifsContractor[id]);
        }
      }

      const filtredTariffsByInterval = this.filterByInterval(
        contractorTariffs,
        t_from,
        t_to,
      );
      tariffs = this.sortByTimeFrom(filtredTariffsByInterval);
    }

    if (!tariffs || !tariffs.length) return res;

    tariffs.map((tariff) => {
      const currentTariff = useTable.getTariff(tariff.id_t);

      if (!currentTariff) return;

      tariff.name = currentTariff.name;
      tariff.price = currentTariff.price;
      tariff.tempblock = currentTariff.tempblock;
      tariff.constblock = trueInDbHelper(currentTariff.constblock);
      tariff.type = 'contract';
    });

    res.current = tariffs[0];
    res.all = tariffs;

    return res;
  },

  currentAdditionalContractTariff(additional) {
    const { id_d } = additional;

    try {
      const current_contract = STORE.contracts[id_d];

      return useTable.getCurrentTariffs(current_contract);
    } catch (error) {
      console.error(error);

      return {
        current: {},
        all: [],
      };
    }
  },

  filterByInterval(tariffsArray, t_from, t_to) {
    if (!t_from || !t_to) return tariffsArray;

    const tariffs = tariffsArray.filter((tariff) => {
      if (+tariff.t_to <= +t_from) return false; // Закончилось действие до начала интервала
      if (+tariff.t_from >= +t_to) return false; // Еще не началось действие
      return true;
    });

    return tariffs;
  },

  currentVehicleTariff(id_c, id_v, t_from, t_to) {
    const res = {
      current: {},
      all: [],
    };

    if (!STORE.vehicles[id_v]) return res;

    const vehicle = STORE.vehicles[id_v];

    try {
      const individual_tariffs = vehicle['tarifs_vehicle'] || [];

      if (!vehicle['additional_tariffs']) {
        useAdditional.assignTariffsToVehicles();
      }

      const additional_tariffs = vehicle['additional_tariffs'] || [];

      let currentSortedByPriorityTariffs = [];

      if (individual_tariffs.length) {
        individual_tariffs.forEach((tariff) => {
          const currentTariff = useTable.getTariff(tariff.id_t);

          if (!currentTariff) return;

          tariff.name = currentTariff.name;
          tariff.price = currentTariff.price;
          tariff.tempblock = currentTariff.tempblock;
          tariff.constblock = trueInDbHelper(currentTariff.constblock);
        });

        const filtredTariffsByInterval = this.filterByInterval(
          individual_tariffs,
          t_from,
          t_to,
        );

        const tariffs = this.sortByTimeFrom(filtredTariffsByInterval);

        tariffs.forEach((tariff) => (tariff.type = 'individual'));

        currentSortedByPriorityTariffs =
          currentSortedByPriorityTariffs.concat(tariffs);
      }

      if (additional_tariffs.length) {
        const filtredTariffsByInterval = this.filterByInterval(
          additional_tariffs,
          t_from,
          t_to,
        );

        const tariffs = this.sortByTimeFrom(filtredTariffsByInterval);

        tariffs.forEach((tariff) => {
          tariff.type = 'additional';
        });

        currentSortedByPriorityTariffs =
          currentSortedByPriorityTariffs.concat(tariffs);
      }

      const currentContractTariffs = this.getContractorVehicleContractTariffs(
        id_c,
        id_v,
        t_from,
        t_to,
      );

      currentContractTariffs.all.forEach(
        (tariff) => (tariff.type = 'contract'),
      );

      currentSortedByPriorityTariffs = currentSortedByPriorityTariffs.concat(
        currentContractTariffs.all,
      );

      res.current = currentSortedByPriorityTariffs[0] || {};
      res.all = currentSortedByPriorityTariffs;

      return res;
    } catch (error) {
      console.error(error);

      return {
        current: {},
        all: [],
      };
    }
  },
};
