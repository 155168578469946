import { createApp } from 'vue';

import App from './App.vue';
import router from './App/router/router';
import VueColumnsResizable from './plugins/vue-columns-resizable';

import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';

const vueApp = createApp(App);

vueApp.component('Datepicker', Datepicker);
vueApp.use(VueColumnsResizable);
vueApp.use(router);

vueApp.mount('#app');

vueApp.config.unwrapInjectedRef = true;
