<template>
  <div class="graphics__list">
    <div
      class="title"
      v-if="graphData.length"
      v-html="isTitle"
    ></div>

    <div
      class="text-left link-to-popup"
      style="margin-left: 15px"
      @click="showStatisticOfViolationsModal()"
    >
      Статистика по нарушениям
    </div>
    <div>
      <ui-chart
        :id="'violations-chart'"
        :data="graphData"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :dataValueStr="'valueOn100km'"
        :columnClickHandler="chartColumnClickHandler"
      />
    </div>

    <Transition name="fade">
      <my-modal
        v-if="showModal"
        @close="closeModal"
      >
        <template #header>
          <input
            type="search"
            v-model="searchStr"
            placeholder="Владелец"
            class="form-control filterOn modal-filter"
          />
          <div class="violations-target__table--title">
            {{ `Нарушения с` }}
            <br v-if="clientWidth < 560" />
            {{
              `${targetTableData.label[0]} по
            ${targetTableData.label[1]}`
            }}
          </div>

          <ui-close-button @click="closeModal" />
        </template>
        <template #body>
          <div
            class="text-left link-to-popup"
            @click="showStatisticOfViolationsModal(targetTableData.id)"
          >
            Статистика по нарушениям
          </div>
          <ui-table class="violations-target__table">
            <template #thead>
              <th
                v-for="(obj, index) in targetTableDataHead"
                :key="index"
                @click="sortTableOn(obj.column)"
              >
                {{ obj.name }}
                <div
                  v-if="sortingColumn === obj.column"
                  class="arrow"
                  :class="[
                    sortingFromLargerToSmaller ? 'arrow-bottom' : 'arrow-top',
                  ]"
                ></div>
              </th>
            </template>
            <template #tbody>
              <tr
                v-for="d in targetTableData.data"
                :key="d.id || 0"
                :bgcolor="d.hex"
                v-show="d.flag"
              >
                <td>{{ d.client }}</td>
                <td>{{ d.model }}</td>
                <td v-html="d.avtoNo"></td>
                <td
                  style="text-align: center; cursor: pointer"
                  @click="
                    tableDistanceClickHandler(d.objId, d.name, d.interval)
                  "
                >
                  <a
                    style="border-bottom: 1px dashed #000"
                    @click.prevent
                  >
                    {{ d.distance }}
                  </a>
                </td>
                <td style="text-align: center">
                  {{ isDistanceMore10km(d.distance, d.sumAllViolations) }}
                </td>
                <td
                  style="text-align: center; cursor: pointer"
                  @click="showSkillsManReportModal_handler(d.objId, d.interval)"
                  @mouseenter="addHoverHandler($event, d.distance)"
                  @mouseleave="removeHoverHandler($event, d.distance)"
                >
                  <a
                    style="border-bottom: 1px dashed #000"
                    @click.prevent
                  >
                    {{
                      isDistanceMore10km(
                        d.distance,
                        d.violationsPer100kmPerPeriodOfObject,
                      )
                    }}
                  </a>
                  <ui-target :text="'Пробег менее 10км'" />
                </td>
              </tr>
            </template>
          </ui-table>
        </template>
      </my-modal>
    </Transition>

    <Transition name="fade">
      <my-modal
        v-if="showSkillsManReportModal"
        @close="closeModalByName('showSkillsManReportModal')"
      >
        <template #header>
          <button
            class="not-print float-left"
            onclick="html_to_excel_helper.export( this, 'skills-man-template-content', 'Навыки MAN суммарный', 'portrait' )"
          >
            Экспорт&nbsp;в&nbsp;EXCEL
          </button>

          <div v-html="skillsManReportTitle"></div>

          <ui-close-button
            @click="closeModalByName('showSkillsManReportModal')"
          />
        </template>
        <template #body>
          <skills-man-report
            :isTitle="false"
            :data="skillsManReportData"
            @output-title-html="setSkillsManReportTitle"
          />
        </template>
      </my-modal>
    </Transition>

    <Transition name="fade">
      <my-modal
        v-if="showStatisticOfViolations"
        @close="closeModalByName('showStatisticOfViolations')"
      >
        <template #header>
          <div></div>
          <div>
            {{ statisticOfViolations.title }}
          </div>
          <ui-close-button
            @click="closeModalByName('showStatisticOfViolations')"
          />
        </template>
        <template #body>
          <StatisticOfViolations
            :header="graphData[0].statisticOfViolationsPerAllPeriods.header"
            :array-for-display="
              graphData[0].statisticOfViolationsPerAllPeriods.personForDisplay
            "
            :format="graphData[0].statisticOfViolationsPerAllPeriods.format"
            :data="statisticOfViolations.data"
            @show-details-violation-popup="showDetailsViolationPopupHandler"
          />
        </template>
      </my-modal>
    </Transition>

    <the-toaster ref="toaster" />
  </div>
</template>

<script>
import MyModal from '@/App/Components/MyModal.vue';
import Modal from './Modal.vue';
import UiChart from './UiChart.vue';
import UiTable from './UiTable.vue';
import TheToaster from '../../Components/toaster/TheToaster.vue';
import UiTarget from '@/App/Monitoring/Sidebar/components/UiTarget.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';

import {
  sortArrayByStringDown,
  sortArrayByStringUp,
} from '@/helpers/MineHelper';
import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../../helpers/targetsHelper';

import { OurAggregated } from '@/src/dataRequest/ourAggregated';
import { skillsManTemplate } from '@/Template/skills_man_template/skills_man_template';
// import { skillsManTemplateHtml } from '@/App/TemplateComponents/SkillsMan/SkillsManTemplateHtml';
import SkillsManReport from '@/App/TemplateComponents/SkillsMan/SkillsManReport.vue';
import StatisticOfViolations from './VGraphicsComponents/StatisticOfViolations.vue';

export default {
  components: {
    MyModal,
    Modal,
    UiChart,
    UiTable,
    TheToaster,
    UiTarget,
    UiCloseButton,
    SkillsManReport,
    StatisticOfViolations,
  },
  name: 'GraphicsList',

  props: {
    graphData: {
      type: Array,
      required: true,
    },
    clientWidth: {
      type: Number,
      required: true,
    },
    clientHeight: {
      type: Number,
      required: true,
    },
  },

  inject: ['mapModalData'],

  data() {
    return {
      showSkillsManReportModal: false,
      skillsManReportTitle: '',
      skillsManReportData: {},

      showStatisticOfViolations: false,
      targetTableData: [],
      searchStr: '',
      showModal: false,
      sortingColumn: '',
      sortingFromLargerToSmaller: true,

      targetTableDataHead: [
        {
          name: 'Владелец',
          column: 'client',
        },
        {
          name: 'Тип КПП',
          column: 'model',
        },
        {
          name: 'Гос №',
          column: 'avtoNo',
        },
        {
          name: 'Пробег (км)',
          column: 'distance',
        },
        {
          name: 'Нарушения (кол-во)',
          column: 'sumAllViolations',
        },
        {
          name: 'Нарушения (на 100 км)',
          column: 'violationsPer100kmPerPeriodOfObject',
        },
      ],

      statisticOfViolations: {
        data: [],
        title: '',
      },
    };
  },
  watch: {
    searchStr() {
      this.search();
    },
  },

  mounted() {
    if (this.clientWidth <= 768) {
      const t = document.getElementById('violations-chart-target');
      t.addEventListener('click', () => {
        this.showModal = !this.showModal;
      });
    }
  },

  computed: {
    isTitle() {
      return this.graphData[0]?.title || '';
    },
  },

  methods: {
    setSkillsManReportTitle(html) {
      this.skillsManReportTitle = html;
    },
    showDetailsViolationPopupHandler(column) {
      const interval = Array.from(
        this.statisticOfViolations.title.matchAll(
          /\d\d\.\d\d\.\d\d\d\d\ \d\d\:\d\d\:\d\d/g,
        ),
        (m) => m[0],
      );

      const unixTimeInterval = interval.map(
        (stringTime) => getTimeFromString_helper(stringTime).datetime,
      );

      const objIds = this.graphData[0].countViolationsOfPeriods.map(
        (obj) => obj.objId,
      );
    },
    showStatisticOfViolationsModal(periodId) {
      if (periodId + 1) {
        this.statisticOfViolations = {
          data: this.graphData[periodId].statisticOfViolations,
          title: `Статистика по нарушениям ${this.targetTableData.label[0]} по
            ${this.targetTableData.label[1]}`,
        };
      } else {
        this.statisticOfViolations = {
          data: this.graphData[0].statisticOfViolationsPerAllPeriods.data,
          title: `Статистика по нарушениям ${this.graphData[0].statisticOfViolationsPerAllPeriods.view.t_interval}`,
        };
      }
      this.$nextTick(() => {
        this.showStatisticOfViolations = true;
      });
    },
    addHoverHandler(event, distance) {
      if (distance < 10) {
        addHoverOnTarget(event);
      }
    },
    removeHoverHandler(event, distance) {
      if (distance < 10) {
        removeHoverOnTarget(event);
      }
    },
    isDistanceMore10km(distance, value) {
      return distance > 10 ? value : '-';
    },
    isShowTargetTable() {
      this.showTargetTable = !this.showTargetTable;
    },
    sortTableOn(str) {
      if (this.sortingColumn === str) {
        if (this.sortingFromLargerToSmaller) {
          this.targetTableData.data = sortArrayByStringUp(
            this.targetTableData.data,
            str,
          );
        } else {
          this.targetTableData.data = sortArrayByStringDown(
            this.targetTableData.data,
            str,
          );
        }
        this.sortingFromLargerToSmaller = !this.sortingFromLargerToSmaller;
      } else {
        this.sortingColumn = str;
        this.sortingFromLargerToSmaller = true;
        this.targetTableData.data = sortArrayByStringDown(
          this.targetTableData.data,
          str,
        );
      }
    },
    search() {
      this.targetTableData.data.map((obj) => {
        obj.flag =
          obj.client.toLowerCase().indexOf(this.searchStr.toLowerCase()) !== -1
            ? true
            : false;
      });
    },
    closeModal() {
      this.searchStr = '';
      this.targetTableData.data = [];
      this.showModal = false;
    },
    chartColumnClickHandler(event, d) {
      d.countViolationsOfPeriods.map((obj) => (obj.flag = true));

      this.targetTableData = {
        id: d.id,
        data: d.countViolationsOfPeriods,
        label: d.label.split(' - '),
      };

      this.sortingColumn = '';
      this.sortTableOn('violationsPer100kmPerPeriodOfObject');

      if (this.clientWidth > 768) {
        this.showModal = !this.showModal;
      }
    },
    tableDistanceClickHandler(id, name, interval) {
      try {
        const unixInterval = [
          new Date(interval[0]).getTime(),
          new Date(interval[1]).getTime(),
        ];

        this.mapModalData.data = {
          objId: id,
          objName: name,
          interval: unixInterval,
        };
      } catch (err) {
        console.error(err);
      }
    },
    closeModalByName(name) {
      this[name] = false;
    },
    showSkillsManReportModal_handler(id, interval) {
      const ourAggregated = new OurAggregated(skillsManTemplate);

      const form_desc = {
        beginTime: new Date(interval[0]).getTime(),
        endTime: new Date(interval[1]).getTime(),
        objects: [id],
      };

      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName: 'skillsMan',
        form_desc,
        callback: this.skillsManReportModal_callback,
        roundTo: 10,
      });

      this.$refs.toaster.warning({
        message: 'Отправлен запрос для получения данных.',
        delay: 5000,
      });
    },
    skillsManReportModal_callback(data) {
      this.skillsManReportData = data;

      this.showSkillsManReportModal = true;
    },
  },
};
</script>

<style lang="scss">
#violations-chart-target {
  position: absolute;
  padding: 5px 10px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  display: none;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;

  &.show,
  &:hover {
    display: flex;
  }

  @media (max-width: 768px) {
    &.show {
      display: flex;
    }
  }
}

.violations-target__table {
  max-width: 100%;
  max-height: calc(100% - 70px);
  overflow: scroll;
  position: relative;
  font-size: 16px;
  border-top: 1px solid #000;

  &--title {
    @media (max-width: 560px) {
      font-size: 15px;
    }
    @media (max-width: 410px) {
      font-size: 14px;
    }
    @media (max-width: 385px) {
      font-size: 13px;
    }
    @media (max-width: 365px) {
      font-size: 12px;
    }
    @media (max-width: 340px) {
      font-size: 11px;
    }
  }
  & table tbody tr td,
  & table thead tr th {
    padding: 0 10px;
    white-space: nowrap;
    border: 1px solid #000;
    color: #000;
  }
  & table tbody tr {
    // &:hover {
    // }
    & td {
      background: transparent;
      font-size: 14px;
      cursor: default;
    }
  }
  & table thead tr th {
    background: #fff;
    text-align: center;
    border-top: none;
    white-space: nowrap;
    cursor: pointer;
  }
  & table {
    border-top: none;
  }
}
text {
  font-size: 14px;
  @media (max-width: 770px) {
    font-size: 12px;
  }
}
.graphics__list {
  padding: 0 10px;
}
.graphics__item {
  margin: 0 auto;
  margin-top: 50px;
  @media (max-width: 770px) {
    margin-top: 10px;
  }
}
.title {
  margin-top: 5px;
  text-align: center;
  & h5 {
    font-size: 20px;
  }
  @media (max-width: 770px) {
    font-size: 16px;
    & p {
      font-size: 12px;
    }
  }
}
</style>
