import RequestsQueue from '@/src/dataRequest/RequestQueue';

import { useRequest } from '@/ManualApp/use/request';

import {
  addCopyArrOfObjects_helper,
  getPercent,
  getTzh,
} from '@/helpers/main_helper';

import type {
  AggregationPosition,
  EnginePressure,
  MinMax,
  OilPressure,
  Pressure,
  PressureAggregation,
  TemperatureList,
} from '@/types/Pressure';
import type { TProcessCallback } from '@/types/TProcessCallback';
import {
  AggregationResponse,
  EngineOilTempAggregationPosition,
  EngineOilTemperature,
} from '../Diagnostic/Aggregation';
import {
  TSettingOfValues,
  aggregationCalculating,
  checkIsSummaryDayByPos,
} from '../AggregationCalculating';
import { IPeriod, Smenas } from '../Smenas';

export type Id = string;
export type Response = (
  | PressureAggregation
  | AggregationResponse<EngineOilTempAggregationPosition>
) & { id: Id };

export type QueryParameters = {
  [key: string]: string | number | boolean;
  time_begin: number;
  time_end: number;
  is_expanded: boolean;
};

export type TOnSuccess = (result: ReportCalculated) => void;

const { send } = useRequest();

export type ReportName = 'oilPressure' | 'engineOilTemp';

export interface ReportOptions {
  objects: Id[];
  from: number;
  to: number;
  objectsData: {
    [id: string]: {
      name: string;
      stateNumber: string;
    };
  };
  isSummary: boolean;
  isDays: boolean;
  isDetail?: boolean;
}

export interface Interval {
  from: number;
  to: number;
}

export interface DetailPressure {
  name: string;
  stateNumber: string;
  time: number;
  can_moto_max: number;
  can_moto_min: number;
  pressure: Pressure;
}

export interface DayPressure {
  name: string;
  stateNumber: string;
  time: number;
  can_moto_max: number;
  can_moto_min: number;
  pressure: Pressure['ds'];
}

export interface SummaryPressure {
  name: string;
  stateNumber: string;
  can_moto_max?: number;
  can_moto_min?: number;
  can_moto_period_start_min?: number;
  can_moto_period_end_min?: number;
  minPerPeriod?: Pressure;
  pressure: {
    e: EnginePressure;
    o: OilPressure;
  };
}

export interface EngineIntervalPressure {
  [key: string]: string | number | EngineOilTemperature;
  id: string;
  time: number;
  name: string;
  stateNumber: string;
  cnt_real_pos: number;
  engine_time: number;
  engoiltmprs: EngineOilTemperature;
  max_speed: number;
  max_speed_time: number;
  seconds_of_the_day: number;
}

export interface EngineOilTempPressure {
  [key: string]: string | number | EngineOilTemperature | undefined;
  time: number;
  engine_time: number;
  engoiltmprs: EngineOilTemperature;
  cnt_real_pos?: number;
  max_speed?: number;
  max_speed_time?: number;
  seconds_of_the_day?: number;
}

export interface ObjectPressure {
  id: string;
  name: string;
  stateNumber: string;
  pressure: {
    summary?: [SummaryPressure];
    days?: DayPressure[];
    detail?: DetailPressure[];
    engineOilTempDays?: EngineOilTempPressure[];
    engineOilTempSummary?: [EngineOilTempPressure];
  };
}
export interface ReportCalculated {
  objects: ObjectPressure[];
  responseInterval: Interval;
}

const URL_GET_OIL_PRESSURE_AGGREGATION =
  'https://dev.telecom-guard.ru/Api/getOilPressAggregated';

const URL_GET_OUR_AGGREGATED_PATH = 'Api/other/getAgregationPositions';

const NOT_VALUE: MinMax = [-1, -1];
const ROUND_TO = 5; // округление по 5 минут

const ENGINE_OIL_TEMP = {
  OPTION: 7,
  TIME_SCALE_MINUTES: 0,
};

export default class {
  _reportName: ReportName;
  _interval: Interval = { from: 0, to: 0 };

  _onProcess: TProcessCallback | null;
  _onSuccess: TOnSuccess | null;

  _settingOfValues: TSettingOfValues = {
    time: [0.001, false],
    cnt_real_pos: [1, false],
    engine_time: [1, true],
    engoiltmprs: {
      '-31': [1, true],
      '-21': [1, true],
      '-11': [1, true],
      '0': [1, true],
      '60': [1, true],
      '70': [1, true],
      '80': [1, true],
      '90': [1, true],
      '100': [1, true],
      '105': [1, true],
      '110': [1, true],
      115: [1, true],
      '120': [1, true],
      '125': [1, true],
      130: [1, true],
      135: [1, true],
      140: [1, true],
      141: [1, true],
    },
    can_moto: [1, false],
    seconds_of_the_day: [1, false],
  };

  constructor(reportName: ReportName) {
    this._reportName = reportName;
    this._onProcess = null;
    this._onSuccess = null;
  }

  getReport({
    objects,
    from,
    to,
    objectsData,
    isSummary,
    isDays,
    isDetail,
  }: ReportOptions): void {
    if (!this._onSuccess) {
      throw new Error('onSuccess is not defined');
    }
    if (!this._onProcess) {
      throw new Error('onProcess is not defined');
    }

    this._onProcess('success', 'Обсчет данных для получения отчета');

    this._interval.from = this._formatTimeToServerSettings(from);
    this._interval.to = this._formatTimeToServerSettings(to);

    this._requestReport(objects, objectsData, isSummary, isDays, isDetail);
  }

  _formatTimeToServerSettings(date: number): number {
    const d = new Date(date);

    const dateRoundedToDay = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
    );

    return this._roundMinutes(dateRoundedToDay, ROUND_TO);
  }

  _roundMinutes(date: Date, roundTo: number): number {
    const minutes = date.getMinutes();

    date.setMinutes(minutes - (minutes % roundTo));

    return date.getTime();
  }

  _requestReport(
    objects: ReportOptions['objects'],
    objectsData: ReportOptions['objectsData'],
    isSummary: ReportOptions['isSummary'],
    isDays: ReportOptions['isDays'],
    isDetail: ReportOptions['isDetail'],
  ): void {
    const responses: Response[] = [];

    const queue = new RequestsQueue(5);
    queue.onProcess(
      async (
        {
          id,
          params,
        }: {
          id: Id;
          params: QueryParameters;
        },
        next,
      ) => {
        let url = String(URL_GET_OUR_AGGREGATED_PATH);
        url += `?id=${id}`;
        for (const key in params) {
          url += `&${key}=${params[key]}`;
        }

        try {
          let data: any = {};
          url += `&template=${this._reportName}`;
          data = await send(url);
          data.id = id;
          next(null, data);
        } catch (error: any) {
          next(error, null);
        }
      },
    );

    const queryCount = objects.length;
    let queryNumber = 0;
    queue.onSuccess((response: Response) => {
      responses[queryNumber] = response;

      queryNumber++;

      const percent = getPercent(queryNumber, queryCount);

      if (this._onProcess) {
        this._onProcess(
          'success',
          `Выполнено ${queryNumber} из ${queryCount} (${percent}%) `,
          percent,
        );
      }
    });

    queue.onFailure((error) => {
      if (this._onProcess) {
        this._onProcess(
          'error',
          'Ошибка выполнения запроса. Выполните новый запрос, либо обновиту страницу',
          0,
        );

        console.error(error);
      }
    });

    queue.onDone(() => {
      const objects =
        this._reportName === 'engineOilTemp'
          ? this._calculateEngineIntervals(responses, objectsData)
          : this._calculate(responses, objectsData, {
              isSummary,
              isDays,
              isDetail,
            });
      if (this._onSuccess) {
        this._onSuccess({ objects, responseInterval: this._interval });
      }
    });

    const params =
      this._reportName === 'engineOilTemp'
        ? {
            time_begin: this._interval.from / 1000,
            time_end: this._interval.to / 1000,
            tzh: getTzh(),
            option: ENGINE_OIL_TEMP.OPTION,
            time_scale_minutes: ENGINE_OIL_TEMP.TIME_SCALE_MINUTES,
          }
        : {
            time_begin: this._interval.from / 1000,
            time_end: this._interval.to / 1000,
            is_expanded: !!isDetail,
          };

    objects.forEach((id) =>
      queue.add({
        id,
        params,
      }),
    );
  }

  _calculate(
    Responses: Response[],
    objectsData: ReportOptions['objectsData'],
    {
      isSummary,
      isDays,
      isDetail,
    }: {
      isSummary: ReportOptions['isSummary'];
      isDays: ReportOptions['isDays'];
      isDetail: ReportOptions['isDetail'];
    },
  ): ObjectPressure[] {
    const objects: ObjectPressure[] = [];
    let objectIndex = 0;

    for (const response of Responses) {
      if (response.error) {
        throw new Error(response.error);
      }

      const object: ObjectPressure = {
        id: response.id,
        name: objectsData[response.id].name,
        stateNumber: objectsData[response.id].stateNumber,
        pressure: {},
      };

      object.pressure = this._calculatePressure(
        response,
        {
          isSummary,
          isDays,
          isDetail,
        },
        objectsData[response.id],
      );

      objects[objectIndex++] = object;
    }

    return objects;
  }

  _calculateEngineIntervals(
    responses: Response[],
    objectsData: ReportOptions['objectsData'],
  ): ObjectPressure[] {
    const smenas = new Smenas({
      SmenasFlag: false,
    });

    smenas.getSmenasSetting(ROUND_TO, this._interval.from, this._interval.to);

    const objects: ObjectPressure[] = [];

    for (const response of responses) {
      const { posArray } = response || {};

      const Periods = addCopyArrOfObjects_helper(
        smenas.setSmenas['Periods'] || [],
      ) as IPeriod[];

      posArray.forEach((position) => {
        if (!('engoiltmprs' in position)) return;

        const engoiltmprs = JSON.parse(position.engoiltmprs) || {};

        for (const key in this._settingOfValues['engoiltmprs']) {
          if (!(key in engoiltmprs)) {
            engoiltmprs[key] = -1;
          }
        }

        position.engoiltmprs = engoiltmprs;
      });

      aggregationCalculating(
        posArray,
        this._settingOfValues,
        Periods,
        this._interval.from,
        this._interval.to,
      );

      const engineOilTempSummary: EngineOilTempPressure = {
        time: 0,
        engine_time: 0,
        engine_time_summary: 0,
        engoiltmprs: {},
      };
      const engineOilTempDays: ObjectPressure['pressure']['engineOilTempDays'] =
        [];

      Periods.forEach((period) => {
        if (!period.TimeBegin || !period.TimeEnd) {
          return;
        }

        engineOilTempDays.push({
          time: period.TimeBegin / 1000,
          cnt_real_pos: period.webSumm.cnt_real_pos,
          engine_time: period.webSumm.engine_time,
          engoiltmprs: period.webSumm.engoiltmprs,
          max_speed: period.webSumm.max_speed,
          max_speed_time: period.webSumm.max_speed_time,
          seconds_of_the_day: period.webSumm.seconds_of_the_day,
        });

        for (const key in period.webSumm) {
          if (key === 'engoiltmprs') {
            for (const engKey in period.webSumm.engoiltmprs) {
              if (engineOilTempSummary[key][engKey] === undefined) {
                engineOilTempSummary[key][engKey] = -1;
              }
              const summValue = engineOilTempSummary[key][engKey];
              const periodValue = period.webSumm.engoiltmprs[engKey];

              if (summValue !== -1 && periodValue !== -1) {
                engineOilTempSummary[key][engKey] +=
                  period.webSumm.engoiltmprs[engKey];
              } else if (summValue === -1 && periodValue !== -1) {
                engineOilTempSummary[key][engKey] =
                  period.webSumm.engoiltmprs[engKey];
              } else if (summValue !== -1 && periodValue === -1) {
                continue;
              } else if (summValue === -1 && periodValue === -1) {
                continue;
              }
            }
          } else if (key === 'engine_time') {
            engineOilTempSummary['engine_time_summary'] += period.webSumm[key];
          }
        }
      });
      engineOilTempDays.forEach((elem) => {
        elem.engine_time_summary = engineOilTempSummary['engine_time_summary'];
        engineOilTempSummary['engine_time'] += elem.engine_time || 0;
      });
      objects.push({
        id: response.id,
        name: objectsData[response.id].name,
        stateNumber: objectsData[response.id].stateNumber,
        pressure: {
          engineOilTempDays,
          engineOilTempSummary: [engineOilTempSummary],
        },
      });
    }

    return objects;
  }

  _calculatePressure(
    response: Response,
    settings: {
      isSummary: ReportOptions['isSummary'];
      isDays: ReportOptions['isDays'];
      isDetail: ReportOptions['isDetail'];
    },
    objectData: {
      name: string;
      stateNumber: string;
    },
  ) {
    const summary: SummaryPressure = {
      name: objectData.name,
      stateNumber: objectData.stateNumber,
      minPerPeriod: {} as Pressure,
      pressure: {
        e: {} as EnginePressure,
        o: {} as OilPressure,
      },
    };

    const pressure: ObjectPressure['pressure'] = {
      summary: [summary],
      days: [],
      detail: [],
    };

    let positionIndex: number;
    let currPosition: AggregationPosition;
    let prevPosition: AggregationPosition;

    const detail: DetailPressure[] = [];
    const days: DayPressure[] = [];

    if (!('lastTimeAgg' in response)) {
      return pressure;
    }

    if (
      !response.posArray ||
      (!response.posArray.length && +response.lastTimeAgg < response.timeBegin)
    ) {
      return pressure;
    }

    for (
      positionIndex = 0;
      positionIndex < response.posArray.length;
      positionIndex++
    ) {
      currPosition = response.posArray[positionIndex];
      prevPosition = response.posArray[positionIndex - 1];

      if (!prevPosition) {
        continue;
      }

      const oilpress =
        typeof currPosition.oilpress === 'string'
          ? JSON.parse(currPosition.oilpress)
          : currPosition.oilpress;

      const pressure = this._calculatePositionOilPress(oilpress) as Pressure;

      if (
        checkIsSummaryDayByPos(currPosition) &&
        currPosition.time > this._interval.from / 1000
      ) {
        const dayPosition: DayPressure = {
          name: objectData.name,
          stateNumber: objectData.stateNumber,
          time: prevPosition.time,
          can_moto_max: currPosition.can_moto,
          can_moto_min: prevPosition.can_moto,
          pressure: pressure.ds,
        };
        days.push(dayPosition);
      }

      detail[positionIndex - 1] = {
        name: objectData.name,
        stateNumber: objectData.stateNumber,
        time: prevPosition.time,
        can_moto_max: currPosition.can_moto,
        can_moto_min: prevPosition.can_moto,
        pressure,
      };
    }

    pressure['days'] = days;

    if (settings.isDetail) {
      pressure['detail'] = detail;
    }

    days.forEach((day) => {
      this._calculateMinByPeriod(
        day.pressure,
        summary.minPerPeriod as Pressure,
      );
    });

    if (days.length > 0) {
      summary.can_moto_max = days[days.length - 1].can_moto_max;
      summary.can_moto_min = days[0].can_moto_min;
      summary.can_moto_period_start_min = days[0].can_moto_min;
      summary.can_moto_period_end_min = days[days.length - 1].can_moto_max;
    }

    if (settings.isSummary) {
      for (positionIndex = 0; positionIndex < days.length; positionIndex++) {
        const day = days[positionIndex];

        for (let i = 0; i < 7; i++) {
          if (!(i in summary.pressure.e)) {
            summary.pressure.e[i] = {} as TemperatureList;
          }
          if (!(i in summary.pressure.o)) {
            summary.pressure.o[i] = {} as TemperatureList;
          }

          for (let j = 0; j < 8; j++) {
            if (!(j in summary.pressure.e[i]) && !summary.pressure.e[i][j]) {
              summary.pressure.e[i][j] = NOT_VALUE;
            }
            if (!(j in summary.pressure.o[i]) && !summary.pressure.o[i][j]) {
              summary.pressure.o[i][j] = NOT_VALUE;
            }

            if (j in (day?.pressure?.e?.[i] || {})) {
              const [sumMin, sumMax] = summary.pressure.e[i][j];
              const [dayMin, dayMax] = day.pressure.e[i][j];
              const currMin = this._getMinValue(sumMin, dayMin);
              const currMax = this._getMaxValue(sumMax, dayMax);
              summary.pressure.e[i][j] = [currMin, currMax];
            }

            if (j in (day?.pressure?.o?.[i] || {})) {
              const [sumMin, sumMax] = summary.pressure.o[i][j];
              const [dayMin, dayMax] = day.pressure.o[i][j];
              const currMin = this._getMinValue(sumMin, dayMin);
              const currMax = this._getMaxValue(sumMax, dayMax);
              summary.pressure.o[i][j] = [currMin, currMax];
            }
          }
        }
      }

      pressure['summary'] = [summary];
    }

    return pressure;
  }

  _calculateMinByPeriod(period: Partial<Pressure>, minPerPeriod: Pressure) {
    if (minPerPeriod['e'] === undefined) {
      minPerPeriod['e'] = {} as Pressure['e'];
    }
    if (minPerPeriod['o'] === undefined) {
      minPerPeriod.o = {} as Pressure['o'];
    }
    for (let i = 0; i < 7; i++) {
      if (!(i in minPerPeriod.e)) {
        minPerPeriod.e[i] = {} as TemperatureList;
      }
      if (!(i in minPerPeriod.o)) {
        minPerPeriod.o[i] = {} as TemperatureList;
      }

      for (let j = 0; j < 8; j++) {
        if (!(j in minPerPeriod.e[i]) && !minPerPeriod.e[i][j]) {
          minPerPeriod.e[i][j] = NOT_VALUE;
        }
        if (!(j in minPerPeriod.o[i]) && !minPerPeriod.o[i][j]) {
          minPerPeriod.o[i][j] = NOT_VALUE;
        }

        if (j in (period?.e?.[i] || {})) {
          const [sumMin] = period?.e?.[i]?.[j] || NOT_VALUE;
          const [prdMin, prdMax] = minPerPeriod.e[i][j];

          const min = prdMin > -1 ? prdMin : sumMin;
          const max = sumMin > -1 ? sumMin : prdMax;

          minPerPeriod.e[i][j] = [min, max];
        }

        if (j in (period?.o?.[i] || {})) {
          const [sumMin] = period?.o?.[i]?.[j] || NOT_VALUE;
          const [prdMin, prdMax] = minPerPeriod.o[i][j];

          const min = prdMin > -1 ? prdMin : sumMin;
          const max = sumMin > -1 ? sumMin : prdMax;

          minPerPeriod.o[i][j] = [min, max];
        }
      }
    }
  }

  _calculatePositionOilPress(oilpress: Pressure) {
    const enginePressure = this._calculateOilPress(oilpress?.e);
    const oilPressure = this._calculateOilPress(oilpress?.o);
    if (!!oilpress?.ds) {
      const summaryEnginePressure = this._calculateOilPress(oilpress?.ds?.e);
      const summaryOilPressure = this._calculateOilPress(oilpress?.ds?.o);

      return {
        e: enginePressure,
        o: oilPressure,
        ds: {
          e: summaryEnginePressure,
          o: summaryOilPressure,
        },
      };
    } else {
      return {
        e: enginePressure,
        o: oilPressure,
      };
    }
  }
  _calculateOilPress(pressure?: EnginePressure | OilPressure) {
    const result: { [key: string]: { [key: string]: MinMax } } = {};

    if (!pressure) {
      pressure = {} as EnginePressure | OilPressure;
    }

    for (let i = 0; i < 7; i++) {
      if (!(i in result)) {
        result[i] = {};
      }
      for (let j = 0; j < 8; j++) {
        if (i in pressure && !(j in pressure[i])) {
          result[i][j] = [-1, -1];
        } else {
          if (i in pressure && j in pressure[i]) {
            result[i][j] = [pressure[i][j][0], pressure[i][j][1]];
          } else {
            result[i][j] = [-1, -1];
          }
        }
      }
    }

    return result;
  }

  _getMinValue(a: MinMax[0], b: MinMax[0]) {
    if (a === -1 && b > -1) {
      return b;
    }
    if (b === -1 && a > -1) {
      return a;
    }

    return a < b && a != -1 ? a : b;
  }

  _getMaxValue(a: MinMax[1], b: MinMax[1]) {
    return a > b ? a : b;
  }

  onProcess(listener: TProcessCallback): this {
    this._onProcess = listener;
    return this;
  }
  onSuccess(listener: TOnSuccess): this {
    this._onSuccess = listener;
    return this;
  }
}
