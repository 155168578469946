<template>
  <ui-popup
    style-for-body="width: 55vw; padding: 20px; max-height: 85vh; overflow-y:scroll"
    @close="() => emit('close')"
  >
    <ui-close-button @close="() => emit('close')" />

    <div
      v-if="!Object.keys(props.data)"
      class="text-center"
    >
      Не удалось загрузить объект мониторинга
    </div>

    <div>
      <h4 class="text-center">Объект мониторинга</h4>
      <table>
        <tr v-for="(val, key) in tableData.header">
          <td v-html="tableData.header[key]"></td>
          <td v-html="tableData.body[key]"></td>
        </tr>
      </table>
    </div>
  </ui-popup>
</template>

<script setup>
import UiPopup from './UiPopup.vue';
import UiCloseButton from '../Buttons/UiCloseButton.vue';
import { computed } from 'vue';
import { formatDateHelper } from '@/helpers/main_helper';

const props = defineProps({ data: { type: Object, default: {} } });
const emit = defineEmits(['close']);

const tableData = computed(() => {
  const header = {
    id: 'ID',
    name: 'Наименование',
    state_number: 'Гос №',
    model: 'Модель',
    client: 'Владелец',
    vin: 'VIN',
    install_time: 'Дата установки',
    // speed: 'Скорость',
    last_time_aggregation: 'Готовность отчета, до',
    lastPosTime: 'Дата последнего сообщения',
  };
  const body = {};

  for (let key in header) {
    if (key === 'speed') {
      body[key] = props.data[key] == 'отсутствует' ? 0 : props.data[key];
      continue;
    }

    if (
      key === 'install_time' ||
      key === 'last_time_aggregation' ||
      key === 'lastPosTime'
    ) {
      body[key] = formatDateHelper(
        new Date(+props.data[key] * 1000),
        'dd.mm.yyyy',
      );
      continue;
    }

    body[key] = props.data[key];
  }

  return { header, body };
});
</script>

<style lang="scss" scoped></style>
