import { CalculateGraphData } from './CalculateGraphData.js';

export class ProductionsIndicator {
  charts = {};
  data;
  chartSummData;
  area;
  element;
  subElements;

  constructor(componentName, area, pointers) {
    this.create(componentName, area, pointers);
  }

  template(componentName) {
    return `<div data-component=${componentName}>
            <div data-element="info">Выполните запрос за нужный период для получения графической информации</div>
            <div data-element="chartsInfo" class="text-center"></div>
            ${this.chartContainerTemplate('fuelExpence', 'height:460px')}
            ${this.chartContainerTemplate('ptoGraph', 'height:460px')}
            ${this.chartContainerTemplate('distanceGraph', 'height:460px')}
            <div data-element="notDistList" class="border-top"></div>
            <div data-element="chartSummObjectList" class="text-center"></div>
        </div>`;
  }

  chartContainerTemplate(chartName, style = '') {
    this.charts[chartName] = null;
    const styleHtml = style ? ` style="${style}"` : '';

    return `<div class="chart-container"${styleHtml}>
            <canvas data-element="${chartName}"></canvas>
        </div>`;
  }

  async create(componentName, area, pointers) {
    const pointer = [...pointers].find((element) => {
      const elementComponentName = element.dataset.component;

      if (elementComponentName) {
        return Boolean(elementComponentName === componentName);
      }

      const dataElement =
        dataElement.dataset.component ||
        element.querySelector('[data-component]');

      if (dataElement && dataElement.dataset.component === componentName) {
        return true;
      }
    });

    if (pointer) {
      this.pointer = pointer;
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = this.template(componentName);
    this.componentName = componentName;

    const element = wrapper.firstChild;

    const calculateGraphData = new CalculateGraphData();

    this.element = element;
    this.subElements = calculateGraphData.getSubElements(this.element);

    if (area) {
      this.show(area);
    }
  }

  show(area) {
    if (!this.pointer.classList.contains('active')) {
      return;
    }

    const areaChild = area.firstChild;

    if (areaChild) {
      areaChild.remove();
    }

    area.append(this.element);
  }

  async writeInfo(innerHtml) {
    this.subElements.info.innerHTML = innerHtml;
  }

  async draw({
    area = null,
    objectsCalculated = {},
    objectsList = [],
    objectsDisableList = [],
    componentName,
  } = {}) {
    const { chartsInfo, chartSummObjectList, ratingTable, notDistList } =
      this.subElements;

    const { objects = [], format, rows_summ } = objectsCalculated;

    if (!objectsList.length) {
      this.writeInfo(
        '<span style="color:red">Для построения графика не выбран ни один объект.<span>',
      );
      return;
    }

    if (!objects.length) {
      this.writeInfo(
        '<span style="color:red">Получены пустые данные. Попробуйте проверить параметры и повторить запрос или обновить страницу.<span>',
      );
      return;
    }

    this.writeInfo('Данные получены. Анализирую...');

    const calculateGraphData = new CalculateGraphData({ format });

    const { objectsData, violationNames } = calculateGraphData.getObjectsData(
      objects,
      rows_summ,
    );
    const periodsLabels = calculateGraphData.getPeriodsLabels(
      objects[0],
      'Производственные показатели, работа сцепления',
    );
    const chartSummData = calculateGraphData.chartSummAnalysis(
      objectsData,
      periodsLabels,
      violationNames,
    );

    this.chartSummData = chartSummData;
    this.data = {
      objectsData,
      periodsLabels,
    };

    const notDistArr = chartSummData['summData']['objectsRaiting']['notDist'];
    notDistList.innerHTML = calculateGraphData.notDistListTemplate(
      notDistArr,
      'Объекты с малым пробегом: ',
    );

    const allDatasets = this.getDatasets({
      summData: chartSummData['summData'],
      type: 'line',
    });

    this.graphsDestroy();
    this.graphsCreate(allDatasets);

    this.writeInfo('');
    chartsInfo.innerHTML = periodsLabels.title;
    chartSummObjectList.innerHTML = calculateGraphData.objectListTemplate(
      objectsList,
      objectsDisableList,
    );
  }

  getDatasets({ summData, type }) {
    const {
      summOfPeriods,
      summOfObjects,
      periodSplitRows,
      periodSplitDependentRows,
    } = summData;
    const { rows_person = {} } = summOfObjects[0] || {};
    const {
      periodSplitRows: personPeriodSplitRows = [],
      periodSplitDependentRows: personPeriodSplitDependentRows = [],
    } = rows_person;

    const personPeriodSplitRowsReplace = personPeriodSplitRows.reduce(
      (accum, personPeriodSplitRow) => {
        accum.push(`${personPeriodSplitRow}_DCount`);
        accum.push(`${personPeriodSplitRow}_DForfeits`);

        return accum;
      },
      [],
    );

    const indicatorsReplace = {
      motoEngine: 'moto',
      distance: 'dist',
      motoEngineSpd: 'motoSpd',
    };

    const indicators = [
      // yAxisID отвечает за график и за ось У на этом графике
      {
        arrName: 'canExpence',
        name: 'Общий расход топлива по парку, литров',
        yAxisID: 'expence',
      },
      {
        arrName: 'canExpenceNoSpd',
        name: 'Общий расход топлива при скорости =0, литров',
        yAxisID: 'expence',
      },
      {
        arrName: 'canExpence100km',
        name: 'Расход топлива л/100 км',
        yAxisID: 'expenceRelative',
      },
      {
        arrName: 'canExpence100kmMove',
        name: 'Расход топлива л/100 км в движении',
        yAxisID: 'expenceRelative',
      },
      {
        arrName: 'canExpenceLHourMove',
        name: 'Расход топлива литров в час в движении',
        yAxisID: 'expenceRelative',
      },
      {
        arrName: 'avgSpd',
        name: 'Средняя скорость при движении',
        yAxisID: 'expenceRelative',
      },

      { arrName: 'ptoCnt', name: 'Число поднятий кузова', yAxisID: 'pto' },
      {
        arrName: 'ptoEngineRpm800_1000Percent',
        name: 'Доля работы КОМ при предпочтительных оборотах ДВС 800 - 1000, %',
        yAxisID: 'pto',
      },
      {
        arrName: 'rpmMore1200PtoPercent',
        name: 'Обороты ДВС более 1200 при работе КОМ, доля от общего времени работы КОМ, %',
        yAxisID: 'pto',
      },
      {
        arrName: 'ptoSpdDistance',
        name: 'Пробег с включенным КОМ, км',
        yAxisID: 'pto',
      },

      {
        arrName: 'distance',
        name: 'Общий пробег, км',
        yAxisID: 'distanceSumm',
      },
      {
        arrName: 'transmission_lock_1_dist',
        name: 'Пробег на понижающей, км',
        yAxisID: 'distanceSumm',
      },
      {
        arrName: 'clutchUseMoveStartExcessiveWorkPercent',
        name: 'Излишняя работа сцепления, %',
        yAxisID: 'distance',
      },

      {
        arrName: 'slipping_DCount',
        name: 'Буксование (нагрев дисков сцепления более 100 градусов), кол-во',
        yAxisID: 'distance',
      },
      {
        arrName: 'rpm1900_2200Percent',
        name: 'Обороты двс более 1900, доля от общего времени ДВС в движ, %',
        yAxisID: 'distance',
      },
      {
        arrName: 'engineBreakTimePercent',
        name: 'Доля использования моторного тормоза по времени торможения, %',
        yAxisID: 'distance',
      },
      {
        arrName: 'kickdownCnt',
        name: 'Сработка режима kickdown, кол-во',
        yAxisID: 'distance',
      },
      {
        arrName: 'absCount',
        name: 'Сработка ABS, кол-во',
        yAxisID: 'distance',
      },
      // {arrName: 'transmission_lock_1_distPercent', name: 'Пробег на понижающей, км', yAxisID: 'distance'},
    ];

    const summByPeriodsForGraph = summOfPeriods.map((period) => {
      const periodSumm = periodSplitRows
        .concat(personPeriodSplitRowsReplace)
        .reduce((accum, arrName) => {
          const replaceArrName = indicatorsReplace[arrName] || arrName;

          accum[arrName] = summOfArray_helper(period[replaceArrName]);
          return accum;
        }, {});

      periodSplitDependentRows
        .concat(personPeriodSplitDependentRows)
        .forEach((arrName) => {
          periodSumm[arrName] = getRelativeValue_helper(
            arrName,
            periodSumm,
            3600,
          );
        });

      return periodSumm;
    });

    // datasets: [{
    //     label: 'A',
    //     yAxisID: 'A',
    //     data: [100, 96, 84, 76, 69]
    //   }, {
    //     label: 'B',
    //     yAxisID: 'B',
    //     data: [1, 1, 1, 1, 0]
    //   }]

    let colorObject;

    return indicators.reduce(
      (accum, indicator) => {
        const { arrName, name, yAxisID } = indicator;

        const label = name;
        const data = summByPeriodsForGraph.map((period) =>
          myRoundNumber_helper(period[arrName], 2),
        );

        let graphName = '?';

        switch (yAxisID) {
          case 'expence':
          case 'expenceRelative':
            graphName = 'fuel';
            break;

          case 'pto':
            graphName = 'pto';
            break;

          case 'distance':
          case 'distanceSumm':
          default:
            graphName = 'distance';
            break;
        }

        if (!accum[graphName]) {
          accum[graphName] = [];
        }

        if (!accum[graphName].length) {
          colorObject = this.getColorObjectBegin();
        }

        colorHexShiftObject_helper(colorObject);

        accum[graphName].push({
          type,
          label,
          data,
          yAxisID,
          borderColor: colorObject.text,
          backgroundColor: colorObject.text,
          borderWidth: 1,
          fill: false,
        });

        return accum;
      },
      {
        fuel: [],
        pto: [],
        distance: [],
      },
    );
  }

  getColorObjectBegin() {
    return {
      color: { r: 0, g: 255, b: 0, opasity: 1 },
    };
  }

  getViolationSummOfPeriods({
    summOfPeriods,
    notDistArr,
    violationNames,
    type,
  }) {
    const violationArrNames = Object.keys(violationNames);
    const notDistObjIndexArr = notDistArr.map(
      (notDistObj) => notDistObj.objIndex,
    );
    const colorObject = {
      color: { r: 0, g: 255, b: 0, opasity: 1 },
    };

    const violationsSummOfPeriods = summOfPeriods.map((period) => {
      const summOfPeriod = violationArrNames.reduce(
        (accum, violationArrName) => {
          accum[violationArrName] = 0;
          return accum;
        },
        {},
      );

      period.violations.forEach((objViolations, objIndex) => {
        if (!notDistObjIndexArr.includes(objIndex)) {
          violationArrNames.forEach((violationArrName) => {
            summOfPeriod[violationArrName] +=
              objViolations[violationArrName] || 0;
          });
        }
      });

      return summOfPeriod;
    });

    return violationArrNames.map((violationArrName) => {
      const label = violationNames[violationArrName];
      const data = violationsSummOfPeriods.map(
        (period) => period[violationArrName],
      );

      colorHexShiftObject_helper(colorObject);

      return {
        type,
        label,
        data,
        borderColor: colorObject.text,
        backgroundColor: colorObject.text,
        borderWidth: 1,
        fill: false,
      };
    });
  }

  graphsCreate(allDatasets) {
    const { fuelExpence, ptoGraph, distanceGraph } = this.subElements;
    const { labels, summData } = this.chartSummData;

    this.charts.fuelExpence = this.createChart({
      labels,
      titleText: 'График по топливу',
      yAxesLabelString: 'Расход топлива, л',
      chartElement: fuelExpence,
      datasets: allDatasets.fuel,
      yAxes: [
        {
          id: 'expence',
          scaleLabel: {
            display: true,
            labelString: 'Расход топлива (л)',
          },
          ticks: {
            beginAtZero: true,
          },
          zeroLineWidth: 2,
          zeroLineColor: 'rgba(0, 0, 0, 0.7)',
        },
        {
          id: 'expenceRelative',
          scaleLabel: {
            display: true,
            labelString:
              'Расход топлива (л/100км, л/ч), средняя скорость (км/ч)',
          },
          ticks: {
            beginAtZero: true,
          },
          zeroLineWidth: 2,
          zeroLineColor: 'rgba(0, 0, 0, 0.7)',
        },
      ],
    });

    this.charts.ptoGraph = this.createChart({
      labels,
      titleText: 'График использования КОМ',
      chartElement: ptoGraph,
      datasets: allDatasets.pto,
      yAxes: [
        {
          id: 'pto',
          scaleLabel: {
            display: true,
            labelString: 'Значение',
          },
          ticks: {
            beginAtZero: true,
          },
          zeroLineWidth: 2,
          zeroLineColor: 'rgba(0, 0, 0, 0.7)',
        },
      ],
    });

    this.charts.distanceGraph = this.createChart({
      labels,
      titleText:
        'График по пробегу, повышенным оборотам ДВС, использованию: понижающей передачи, моторного тормоза, ABS, Kikdown, сцепления',
      chartElement: distanceGraph,
      datasets: allDatasets.distance,
      yAxes: [
        {
          id: 'distanceSumm',
          scaleLabel: {
            display: true,
            labelString: 'Общий пробег, км',
          },
          ticks: {
            beginAtZero: true,
          },
          zeroLineWidth: 2,
          zeroLineColor: 'rgba(0, 0, 0, 0.7)',
        },
        {
          id: 'distance',
          scaleLabel: {
            display: true,
            labelString: 'Прочее',
          },
          ticks: {
            beginAtZero: true,
          },
          zeroLineWidth: 2,
          zeroLineColor: 'rgba(0, 0, 0, 0.7)',
        },
      ],
    });
  }

  createChart({
    labels,
    datasets,
    chartElement,
    titleText,
    yAxesLabelString,
    yAxes,
  }) {
    const zeroLineWidth = 2;
    const zeroLineColor = 'rgba(0, 0, 0, 0.7)';

    yAxes = yAxes || [
      {
        scaleLabel: {
          display: true,
          labelString: yAxesLabelString,
        },
        ticks: {
          beginAtZero: true,
        },
        zeroLineWidth,
        zeroLineColor,
      },
    ];

    return new Chart(chartElement, {
      data: {
        labels,
        datasets,
      },
      options: {
        title: {
          display: true,
          text: titleText,
          padding: 3,
        },
        legend: {
          align: 'start',
          labels: {
            fontSize: 9,
            boxWidth: 20,
            padding: 3,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 6,
              },
              offsetGridLines: true,
              zeroLineWidth,
              zeroLineColor,
            },
          ],
          yAxes,
        },
      },
    });
  }

  getSummForViolation(objects, periodIndex) {
    // суммируем все нарушения по периоду
    rows_person.personForDisplay.forEach((group) => {
      group.forEach((arrName) => {
        if (!arrName.toLowerCase().includes('group', 0)) {
          // это НЕ название группировки
          const prevSmenaOfArrName = curSmena.rowsPerson[arrName] || {};

          const { DCount, DForfeits } = rows_person.person[arrName];
          const { DCount: DCountPrev = 0, DForfeits: DForfeitsPrev = 0 } =
            prevSmenaOfArrName;

          if (!rows_person.person[arrName]['periods']) {
            rows_person.person[arrName]['periods'] = [];
          }

          rows_person.person[arrName]['periods'][periodNum] = {
            DCount: DCount - DCountPrev,
            DForfeits: DForfeits - DForfeitsPrev,
          };

          curSmena.rowsPerson[arrName] = { DCount, DForfeits };
        }
      });
    });
  }

  graphsDestroy() {
    for (const [key, chart] of Object.entries(this.charts)) {
      if (chart && chart.canvas) {
        chart.destroy();
      }
    }
  }

  // titleTemplate (smenasStr, t_interval) {
  //     return `<h5 class="center margin-none">Информация предоставлена ${t_interval}</h5><p class="margin-none">${smenasStr}</p>`;
  // }

  // getPeriodsLabels (object) {
  //     const {smenasStr = '', t_interval = ''} = object.view;
  //     const title = this.titleTemplate(smenasStr, t_interval);

  //     return object.periods.reduce((accum, period) => {
  //         const {TimeBegin, TimeEnd} = period;

  //         // const dateBegin = formatDateHelper(TimeBegin, 'dd.mm.yyyy hh:nn');
  //         const dateBegin = formatDateHelper(TimeBegin, 'dd.mm hh:nn');
  //         // const dateEnd = formatDateHelper(TimeEnd, 'dd.mm.yyyy hh:nn');
  //         const dateEnd = formatDateHelper(TimeEnd, 'dd.mm hh:nn');
  //         const label = `${dateBegin} - ${dateEnd}`;

  //         accum.periods.push({TimeBegin, TimeEnd});
  //         accum.labels.push(label);

  //         return accum;
  //     }, {periods: [], labels: [], title});
  // }

  removeChartData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    chart.update();
  }
}
