<template>
  <td
    v-if="detailTable.rows"
    :data-formatnum="dataFormatnum"
    :data-value="dataValue"
    @click.prevent="showDetailTable(true, detailTable)"
    href="#"
    v-html="htmlText"
    class="my-link"
  ></td>
  <td
    v-else
    :data-formatnum="dataFormatnum"
    :data-value="dataValue"
    v-html="htmlText"
  ></td>
</template>

<script>
export default {
  props: {
    detailTable: {
      type: Object,
      required: true,
    },
    htmlText: {
      type: String,
      required: true,
    },
    dataFormatnum: {
      type: Number,
      required: false,
      default: 0,
    },
    dataValue: {
      type: String,
      required: false,
      default: '',
    },
    showDetailTable: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style></style>
