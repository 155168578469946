<template>
  <div class="template-values-window">
    <div class="template-setting-window-header">
      <div class="template-setting-window-header-name">
        <strong>Статистика по организациям</strong>
        <br />
        <small>Объекты</small>
      </div>
      <!-- Картинка крестика-->
      <img
        data-element="close"
        class="close"
        src="/images/close.png"
        @click="$emit('close')"
      /><!-- onclick="showHiddenWindow('none', 'shiftWork-template-setting-window')" -->
    </div>
    <div class="owerflow-auto">
      <button
        class="not-print float-left"
        onclick="html_to_excel_helper.export( this, 'clients_monthly_detail_template', 'Статистика по организациям (детали)', 'portrait' )"
      >
        Экспорт&nbsp;в&nbsp;EXCEL
      </button>
      <div id="clients_monthly_detail_template">
        <div data-excelsheet="Статистика (детали)"></div>
        <h6 class="text-center font-weight-bold">
          За период с {{ timeBegin }} по {{ timeEnd }}
        </h6>
        <table
          border="1"
          cellpadding="5"
        >
          <thead>
            <tr
              class="text-center print-repeat print-frozen-row data-excelautofilter"
            >
              <th
                v-for="column in $options.columns.headers"
                :key="column"
                :data-minwidth="$options.columns[column].excelWidth"
                data-excelalignh="center"
                data-excelalignv="middle"
              >
                <!-- :data-minheight="($options.columns[column].text.length > 20) ? 30 : 15" -->
                {{ $options.columns[column].text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="obj in summInterval.objects"
              :key="obj.id"
            >
              <td
                v-for="column in $options.columns.headers"
                :key="column"
                :data-formatnum="$options.columns[column].excelFormat ?? ''"
                :data-value="
                  $options.columns[column].getExcelValue
                    ? $options.columns[column].getExcelValue(obj)
                    : ''
                "
              >
                {{
                  $options.columns[column].get
                    ? $options.columns[column].get(
                        obj,
                        summInterval.intervalIndex,
                      )
                    : obj[column]
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    @click="$emit('close')"
    data-element="close"
    class="background-wrap"
    style="display: flex"
  ></div>
</template>

<script>
export default {
  name: 'ClientsMonthlyDetail',
  props: {
    summInterval: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  // methods: {
  //   close() {
  //     this.showTable(false);
  //   },
  // },
  columns: {
    headers: [
      'client',
      'stateNumber',
      'model',
      'vin',
      'installTime',
      'isWasOnline',
      'distance',
      'canExpence',
    ],
    client: {
      text: 'Организация',
      excelWidth: 15,
    },
    stateNumber: {
      text: 'Гос. номер',
      excelWidth: 13,
    },
    model: {
      text: 'Модель',
      excelWidth: 14,
    },
    vin: {
      text: 'VIN',
      excelWidth: 22,
    },
    installTime: {
      text: 'Дата установки',
      excelFormat: 2,
      excelWidth: 10,
      getExcelValue(obj) {
        const { installTime } = obj;
        if (installTime > 0) {
          return (
            installTime / 86400 + 25569 - new Date().getTimezoneOffset() / 1440
          );
        }
        return '';
      },
      get(obj) {
        const { installTime } = obj;
        if (installTime > 0) {
          return formatDateHelper(new Date(installTime * 1000), 'dd.mm.yyyy');
        }
        return 'НЕИЗВЕСТНО';
      },
    },
    isWasOnline: {
      text: 'На связи',
      excelWidth: 8,
      get(obj, intervalIndex) {
        return obj.intervals[intervalIndex].isWasOnline ? 'да' : 'нет';
      },
    },
    distance: {
      text: 'Пробег (км)',
      excelWidth: 10,
      excelFormat: 7,
      get(obj, intervalIndex) {
        const { distance } = obj.intervals[intervalIndex];
        if (distance > 0) {
          return myRoundNumber_helper(distance, 2);
        }
        return distance;
      },
    },
    canExpence: {
      text: 'Расход по CAN (л)',
      excelWidth: 10,
      excelFormat: 7,
      get(obj, intervalIndex) {
        const { canExpence } = obj.intervals[intervalIndex];
        if (canExpence > 0) {
          return myRoundNumber_helper(canExpence, 2);
        }
        return canExpence;
      },
    },
    // forfeits: {
    //   text: 'Нарушений',
    //   excelWidth: 8,
    //   excelFormat: 7,
    //   get(obj, intervalIndex) {
    //     obj.intervals[intervalIndex].forfeits;
    //   },
    // }
    // distanceForCanExpence: {
    //   text: 'Расход по CAN (л/100 км)',
    //   excelWidth: 7,
    //   excelFormat: 7,
    //   get(obj, intervalIndex) {
    //     const {distanceForCanExpence, canExpence} = obj.summ;
    //     if (distanceForCanExpence > 0) {

    //     }
    //     return myRoundNumber_helper(obj.intervals[intervalIndex].distanceForCanExpence, 2);
    //   },
    // },
  },
  computed: {
    timeBegin() {
      return formatDateHelper(
        new Date(this.summInterval.begin),
        'dd.mm.yyyy hh:nn:ss',
      );
    },
    timeEnd() {
      return formatDateHelper(
        new Date(this.summInterval.end),
        'dd.mm.yyyy hh:nn:ss',
      );
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid black;
  caption-side: top;
  margin: 0;
}
table th {
  position: sticky;
  top: 0;
}
caption {
  caption-side: top;
}
.center-tag {
  text-align: center;
}
.template-values-window {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  width: 85vw;
  height: 92vh;
  margin: auto;
  background: #fff;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.checkbox-target {
  color: blue;
}
.owerflow-auto {
  width: 100%;
  overflow: auto;
  border: 1px solid gray;
}
</style>
