import { watch } from 'vue';
import { STORE } from '../STORAGE/STORAGE';

const STATUS_VARIABLES = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  disabled: 'disabled',
};

export const useAdditional = {
  getStatus: (additional, userRole = 'user') => {
    const isStaff = userRole === 'main_admin' || userRole === 'admin';

    if (!additional) return;

    const { status } = additional;
    const res = {
      type: isStaff ? STATUS_VARIABLES.disabled : STATUS_VARIABLES.warning,
      text: !!additional.scanFilesServing?.length
        ? 'Готов к отправке'
        : 'Не прикреплен скан',
      reason: '',
      value: 'created',
    };

    if (additional.reason) {
      res.reason = `Документ был отклонен по причине: <br>${additional.reason}`;
      res.rejected_time = additional.rejected_time;
    }

    if (status === 'sent') {
      res.type = isStaff ? STATUS_VARIABLES.disabled : STATUS_VARIABLES.warning;
      res.text = 'Документ отправлен';
      res.value = 'sent';
      return res;
    }

    if (status === 'recalled') {
      res.type = isStaff ? STATUS_VARIABLES.disabled : STATUS_VARIABLES.warning;
      res.text = 'Документ отозван';
      res.value = 'recalled';
      return res;
    }

    if (status === 'received') {
      res.type = isStaff ? STATUS_VARIABLES.warning : STATUS_VARIABLES.disabled;
      res.text = 'Документ проверяется сотрудником';
      res.value = 'received';
      return res;
    }

    if (status === 'rejected') {
      res.type = isStaff ? STATUS_VARIABLES.disabled : STATUS_VARIABLES.error;
      res.text = 'Документ отклонен';
      res.value = 'rejected';
      return res;
    }

    if (status === 'approved') {
      res.type = isStaff ? STATUS_VARIABLES.warning : STATUS_VARIABLES.disabled;
      res.text = 'Документ принят в обработку';
      res.value = 'approved';
      return res;
    }

    if (status === 'processed' && +additional.t_to * 1000 < Date.now()) {
      res.type = STATUS_VARIABLES.error;
      res.text = 'Требует обработки после окончания';
      res.value = 'processed_with_end_lifetime';
      return res;
    }

    if (status === 'processed') {
      res.type = STATUS_VARIABLES.success;
      res.text = 'Документ обработан';
      res.value = 'processed';
      return res;
    }

    if (status === 'processed_after') {
      res.type = STATUS_VARIABLES.success;
      res.text = 'Документ обработан';
      res.value = 'processed_after';
      return res;
    }

    return res;
  },

  assignTariffsToVehicles() {
    const additionalIds = Object.keys(STORE.additionals);

    additionalIds.forEach((id) => {
      const { vehicles, tarif, t_from, t_to } = STORE.additionals[id];

      vehicles.forEach((id_v) => {
        if (!STORE.vehicles[id_v]) {
          return;
        }

        if (!STORE.vehicles[id_v].additional_tariffs) {
          STORE.vehicles[id_v].additional_tariffs = [];
        }

        if (!tarif.id) return;

        const tariffsIds = STORE.vehicles[id_v].additional_tariffs.map(
          (t) => t.id,
        );

        if (tariffsIds.includes(tarif.id)) return;

        STORE.vehicles[id_v].additional_tariffs.push({
          ...tarif,
          t_from,
          t_to,
          id_a: id,
          type: 'additional',
        });
      });
    });
  },

  deleteTariffsForVehicles(id_d) {
    if (!STORE.additionals[id_d]) return;

    const { vehicles, tarif } = STORE.additionals[id_d];

    if (!tarif.id) return;

    vehicles.forEach((id_v) => {
      if (!STORE.vehicles[id_v]) return;

      STORE.vehicles[id_v].additional_tariffs.forEach((vehicleTarif, i) => {
        if (vehicleTarif.id == tarif.id) {
          STORE.vehicles[id_v].additional_tariffs.splice(i, 1);
        }
      });
    });
  },
};
