import {
  arraySumm,
  calculateCountPer100km,
  checkGearBoxType,
  colorByNumberHelper,
  formatDCount,
} from '../../helpers/MineHelper.js';

import { CalculateGraphData } from '../graphics/CalculateGraphData.js';

export default {
  getViolationSummOfPeriods(objectsCalculated) {
    const {
      summOfPeriods,
      summOfObjects,
      regularMovingRowsObjectsData,
      labels,
      title,
      smenasStr,
      notDistArr,
      violationNames,
      type,
      objects,
    } = this.prepareData(objectsCalculated);

    const violationArrNames = Object.keys(violationNames);
    const notDistObjIndexArr = notDistArr.map(
      (notDistObj) => notDistObj.objIndex,
    );

    const shortLabelsArray = [];
    const isDivideSmenas = smenasStr[0].indexOf('Расчет ведется по суткам');

    if (isDivideSmenas === -1) {
      smenasStr.map((str, index) => {
        shortLabelsArray[0] = `${labels[0].substr(0, 5)}(${smenasStr.length}н)`;
        for (
          let i = index + 1;
          i < summOfPeriods.length;
          i += smenasStr.length
        ) {
          shortLabelsArray[i] = `${labels[i].substr(0, 5)}(${index + 1})`;
        }
        shortLabelsArray[labels.length - 1] = `${labels[
          labels.length - 1
        ].substr(0, 5)}(${smenasStr.length}н)`;
      });
    } else {
      summOfPeriods.map((elem, index) => {
        shortLabelsArray[index] = labels[index].substr(0, 5).toString();
      });
    }

    const distsPerAllObjects = objects.reduce(
      (acc, obj) => {
        obj.rows_experiment.distance.periods.map((dist, index) => {
          if (dist < 10) return acc;
          acc.summ += dist;
          if (!acc.periods[index]) {
            acc.periods[index] = dist;
            return acc;
          }
          acc.periods[index] += dist;
        });

        return acc;
      },
      { periods: [], summ: 0 },
    );

    const statisticOfViolationsPerAllPeriods = [];
    const statisticOfViolationsDataArray = distsPerAllObjects.periods.map(
      (dist, distIndex) => {
        const resultArray = {};

        objects.map((obj) => {
          if (obj.rows_experiment.distance.periods[distIndex] < 10) return;

          for (let key in obj.rows_person.person) {
            const val = obj.rows_person.person[key];

            if (!resultArray[key]) {
              resultArray[key] = {
                DNum: val.DNum,
                DSkill: val.DSkill,

                DCount: 0,
                DForfeits: 0,
                DForfeitsRelative: 0,
              };
            }
            if (!statisticOfViolationsPerAllPeriods[key]) {
              statisticOfViolationsPerAllPeriods[key] = {
                DNum: val.DNum,
                DSkill: val.DSkill,

                DCount: 0,
                DForfeits: 0,
                DForfeitsRelative: 0,
              };
            }

            if (val.IsRoughly) {
              resultArray[key].IsRoughly = val.IsRoughly;
              statisticOfViolationsPerAllPeriods[key].IsRoughly = val.IsRoughly;
            }

            if (val.periods) {
              resultArray[key].DCount = formatDCount(
                resultArray[key].DCount,
                val.periods[distIndex].DCount,
              );
              resultArray[key].DForfeits += val.periods[distIndex].DForfeits;

              statisticOfViolationsPerAllPeriods[key].DCount = formatDCount(
                statisticOfViolationsPerAllPeriods[key].DCount,
                val.periods[distIndex].DCount,
              );
              statisticOfViolationsPerAllPeriods[key].DForfeits +=
                val.periods[distIndex].DForfeits;
            }
          }
        });

        for (let key in resultArray) {
          resultArray[key].DForfeitsRelative = calculateCountPer100km(
            resultArray[key].DForfeits,
            dist,
          );
        }

        return resultArray;
      },
    );

    for (let key in statisticOfViolationsPerAllPeriods) {
      statisticOfViolationsPerAllPeriods[key].DForfeitsRelative =
        calculateCountPer100km(
          statisticOfViolationsPerAllPeriods[key].DForfeits,
          distsPerAllObjects.summ,
        );
    }

    const statisticOfViolationsData = {
      header: objects[0].rows_person.header,
      personForDisplay: objects[0].rows_person.personForDisplay,
      data: statisticOfViolationsPerAllPeriods,
      view: { t_interval: objects[0].view.t_interval },
    };

    const violationsSummOfPeriods = summOfPeriods.map((period, periodIndex) => {
      const summOfPeriod = violationArrNames.reduce(
        (accum, violationArrName) => {
          accum[violationArrName] = 0;
          return accum;
        },
        {},
      );

      period.violations.forEach((objViolations, objIndex) => {
        if (!notDistObjIndexArr.includes(objIndex)) {
          violationArrNames.forEach((violationArrName) => {
            summOfPeriod[violationArrName] +=
              objViolations[violationArrName] || 0;
          });
        }
      });

      let countOfObjectsWithDistMore10km = 0;
      period.dist.map((objDist, index) => {
        if (objDist > 10) {
          countOfObjectsWithDistMore10km++;
        }
      });

      const valueOfPeriods = period.violations.map((obj, index) => {
        const sumAllViolations = arraySumm(Object.values(obj));

        let violationsPer100kmPerPeriodOfObject = 0;
        if (period.dist[index] > 10) {
          violationsPer100kmPerPeriodOfObject =
            (sumAllViolations / period.dist[index]) * 100;
        }

        const avtoModel = checkGearBoxType(summOfObjects[index].avtoModel);
        const avtoNo = summOfObjects[index].avtoNo;
        const dist = parseFloat(period.dist[index].toFixed(1));
        const objId = summOfObjects[index].rows_summ_line.objId;

        const regMovRowsObj = regularMovingRowsObjectsData.find(
          (obj) => obj.avtoNo === avtoNo,
        );

        let regularMovingDist = 0;
        if (!!regMovRowsObj.regularMovingRows.detailRows.length) {
          const p_timeBegin = new Date(period.TimeBegin).getTime() / 1000;
          const p_timeEnd = new Date(period.TimeEnd).getTime() / 1000;
          regMovRowsObj.regularMovingRows.detailRows.map((row) => {
            // Если есть время окончания поездки проверяем
            if (!!row.begin.time && !!row.end?.time) {
              // Сверяем время начала поездки с началом периода и конец поездки с концом периода
              if (row.begin.time > p_timeBegin && row.end.time < p_timeEnd) {
                regularMovingDist += row.calculated.dist;
              }
            }
          });
        }

        return {
          interval: [period.TimeBegin, period.TimeEnd],
          client: summOfObjects[index].client,
          name: summOfObjects[index].name,
          canExpence: period.canExpence[index],
          model: avtoModel,
          avtoNo: avtoNo,
          objId: objId,
          regularMovingDist,
          sumAllViolations: sumAllViolations,
          distance: dist,
          hex: colorByNumberHelper(violationsPer100kmPerPeriodOfObject),
          violationsPer100kmPerPeriodOfObject: parseFloat(
            violationsPer100kmPerPeriodOfObject.toFixed(1),
          ),
        };
      });

      return {
        countOfObjectsWithDistMore10km: countOfObjectsWithDistMore10km,
        summOfPeriod,
        valueOfPeriods,
      };
    }, []);

    // const

    const violationSummOfPeriodsDatasets = violationsSummOfPeriods.map(
      (elem, index) => {
        const countOfPeriod = arraySumm(Object.values(elem.summOfPeriod));
        let sumViolationsOfAllObjectsPerPeriod = 0;
        let sumDistsOfAllObjectsPerPeriod = 0;

        elem.valueOfPeriods.map((elem) => {
          if (elem.distance > 10) {
            sumViolationsOfAllObjectsPerPeriod += elem.sumAllViolations;
            sumDistsOfAllObjectsPerPeriod += elem.distance;
          }
        });
        const sumViolationsPer100kmPerPeriod =
          sumDistsOfAllObjectsPerPeriod !== 0
            ? (sumViolationsOfAllObjectsPerPeriod /
                sumDistsOfAllObjectsPerPeriod) *
              100
            : 0;

        const color = colorByNumberHelper(sumViolationsPer100kmPerPeriod);

        const resultObject = {
          id: index,
          type,
          value: countOfPeriod,
          valueOn100km: parseFloat(sumViolationsPer100kmPerPeriod.toFixed(2)),
          label: labels[index],
          shortLabel: shortLabelsArray[index],
          countViolationsOfPeriods: elem.valueOfPeriods,
          countOfObjectsWithDistMore10km: elem.countOfObjectsWithDistMore10km,
          title: index === 0 ? title : '',
          backgroundColor: color,

          statisticOfViolations: statisticOfViolationsDataArray[index],
        };

        if (index === 0) {
          resultObject['statisticOfViolationsPerAllPeriods'] =
            statisticOfViolationsData;
        }

        return resultObject;
      },
    );

    const titleParts = objects[0].view;

    return {
      violationSummOfPeriodsDatasets,
      titleParts,
      format: formatToDisplay_helper,
    };
  },

  prepareData(objectsCalculated) {
    let { objects = [], rows_summ } = objectsCalculated;

    const clients = objects.map((obj) => obj.client);

    const calculateGraphData = new CalculateGraphData();

    const { objectsData, violationNames } = calculateGraphData.getObjectsData(
      objects,
      rows_summ,
    );
    let periodsLabels = calculateGraphData.getPeriodsLabels(
      objects[0],
      'График нарушений на 100 км по парку ',
    );

    const chartSummData = calculateGraphData.chartSummAnalysis(
      // objectsMileageOfMore10km, //Если мы хотим, чтобы объекты не прошедшие 10 км не входили в список
      objectsData,
      periodsLabels,
      violationNames,
    );

    clients.map(
      (client, index) =>
        (chartSummData['summData']['summOfObjects'][index].client = client),
    );

    const regularMovingRowsObjectsData = objects.map((obj) => ({
      avtoNo: obj.avtoNo,
      regularMovingRows: obj.regularMovingRows,
    }));

    periodsLabels.periods.map((period, index) => {
      chartSummData['summData']['summOfPeriods'][index]['TimeBegin'] =
        period.TimeBegin;
      chartSummData['summData']['summOfPeriods'][index]['TimeEnd'] =
        period.TimeEnd;
    });

    return {
      summOfPeriods: chartSummData['summData']['summOfPeriods'],
      summOfObjects: chartSummData['summData']['summOfObjects'],
      regularMovingRowsObjectsData,
      labels: periodsLabels['labels'],
      title: periodsLabels['title'],
      smenasStr: objects[0].smenasStr,
      notDistArr: [],
      violationNames,
      type: 'line',
      objects,
    };
  },
};
