import {
  convertDegrees,
  formatDateHelper,
  formatTimeHelper,
  tableFormat,
} from '@/helpers/main_helper';

const LAT_LON_DELIMETER = 1_000_000_000_000_000;

const getFullDate = (val) => {
  return formatDateHelper(new Date(+val * 1000), 'dd.mm.yyyy hh:nn:ss');
};

export const TRACK_DETAIL_COLUMNS = [
  {
    name: 'time',
    label: 'Дата',
    format: (time) => getFullDate(time / 1000),
  },
  {
    name: 'lat',
    label: 'Широта',
    format: (lat) => convertDegrees(+lat / LAT_LON_DELIMETER, 0).latDMS,
  },
  {
    name: 'lon',
    label: 'Долгота',
    format: (lon) => convertDegrees(0, +lon / LAT_LON_DELIMETER).lonDMS,
  },
  {
    name: 'head',
    label: 'Направление <br> движения (град)',
    format: (value) => tableFormat(Math.round(value * 100) / 100),
  },
  {
    name: 'speed',
    label: 'Скорость (км/ч)',
    format: (value) => tableFormat(value),
  },
  {
    name: 'gps',
    label: 'ГЛОНАСС (ед.)',
    format: (value) => tableFormat(value),
  },
  {
    name: 'gsm',
    label: 'GSM (у.е.)',
    format: (value) => tableFormat(value),
  },
  {
    name: 'pwr',
    label: 'Борт. <br>напряжение (В)',
    format: (value) => tableFormat(Math.round((value / 1000) * 100) / 100),
  },
  {
    name: 'bat',
    label: 'Внутр. заряд (В)',
    format: (value) => tableFormat(Math.round((value / 1000) * 100) / 100),
  },
  {
    name: 'moto_mask',
    label: 'ДВС',
    format: (moto_mask) =>
      Boolean(moto_mask & 1) ? 'Работает' : 'Не работает',
  },
  {
    name: 'rpm',
    label: 'Обороты ДВС (об/мин)',
    format: (value) => tableFormat(value / 1000),
  },
  {
    name: 'engine_time',
    label: 'Общее время <br>работы ДВС',
    format: (interval) => formatTimeHelper(interval / 86400000, ' hh:nn:ss'),
  },
  {
    name: 'engine_move',
    label: 'Время работы <br>ДВС в движении',
    format: (interval) => formatTimeHelper(interval / 86400000, ' hh:nn:ss'),
  },
  {
    name: 'active_time',
    label: 'Время активной<br> работы',
    format: (interval) => formatTimeHelper(interval / 86400000, ' hh:nn:ss'),
  },
  {
    name: 'kren',
    label: 'Крен (град)',
    format: (value) => tableFormat(Math.round(value * 100) / 100),
  },
  {
    name: 'tang',
    label: 'Тангаж (град)',
    format: (value) => tableFormat(Math.round(value * 100) / 100),
  },
  {
    name: 'original_consumption_1',
    label: 'Уровень топлива (л.)',
    format: (value) => tableFormat(Math.round(value / 1000)),
  },
  {
    name: 'distSumm',
    label: 'Пробег (км)',
    format: (value) => tableFormat(Math.round(value / 1000)),
  },
];
