<template>
  <ui-popup
    style-for-body="width: 80vw"
    @close="() => emit('close')"
  >
    <ui-close-button @close="() => emit('close')" />
    <h4 class="text-center">Примененные тарифы {{ props.data.subtitle }}</h4>
    <div class="d-flex">
      <div class="d-flex align-items-center">
        <span class="actual"></span>
        <div class="ml-2">Действующие</div>
      </div>
      <div class="d-flex align-items-center ml-3">
        <span class="not-actual"></span>
        <div class="ml-2">Не действующие</div>
      </div>
    </div>
    <div class="mt-3">
      <ui-table>
        <template #header>
          <tr>
            <template
              v-for="(cell, key) in TABLE_HEADER"
              :key="key"
            >
              <ui-table-th
                :cell="cell"
                :cell-key="key"
              />
            </template>
          </tr>
        </template>
        <template #default>
          <template
            v-for="(tariff, index) of props.data.data"
            :key="index"
          >
            <tr :class="{ disabled: index > 0 || tariff.tempblock == 't' }">
              <template
                v-for="(cell, key) in TABLE_HEADER"
                :key="index + key"
              >
                <ui-table-td
                  v-if="key == 'type'"
                  :html="getType(tariff[key])"
                />
                <ui-table-td
                  v-else-if="key == 'name'"
                  :class="{ 'my-link': tariff.id_t }"
                  :html="tariff[key]"
                  @click="() => tariff.id_t && displayTariffPopup(tariff.id_t)"
                />
                <ui-table-td
                  v-else-if="key == 't_from' || key == 't_to'"
                  :html="getFormatDate(tariff[key])"
                />
                <ui-table-td
                  v-else
                  :html="tariff[key]"
                />
              </template>
            </tr>
          </template>
        </template>
      </ui-table>
    </div>
  </ui-popup>
</template>

<script setup>
import { inject } from 'vue';

import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import UiTable from '@/ManualApp/Components/Tables/UiTable.vue';
import UiTableTd from '@/ManualApp/Components/Tables/UiTableTd.vue';
import UiTableTh from '@/ManualApp/Components/Tables/UiTableTh.vue';
import UiCloseButton from '@/ManualApp/Components/Buttons/UiCloseButton.vue';

import { useTable } from '@/ManualApp/use/table';

const props = defineProps({
  data: { type: Object, default: { subtitle: '', data: [] } },
});

const emit = defineEmits(['close']);

const TABLE_HEADER = useTable.headers.currentTariffs;

const TRANSLATE_TARIFF_TYPE = {
  individual: 'Индивидуальный тариф',
  additional: 'Дополнительное соглашение',
  contract: 'Договор',
};

const displayTariffPopup = inject('displayTariffPopup');

const getType = (type) => {
  return TRANSLATE_TARIFF_TYPE[type];
};

const getFormatDate = (unix) => {
  return formatDateHelper(new Date(+unix * 1000), 'dd.mm.yyyy hh:nn');
};
</script>

<style lang="scss" scoped>
.actual,
.not-actual {
  border: 1px solid #ddd;
  width: 25px;
  height: 15px;
}

.actual {
  background: #fff;
}
.not-actual {
  background: #ddd;
}
</style>
