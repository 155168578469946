type TOnProcess<T> = (
  task: T,
  callback: (error: Error | null, result: unknown) => void
) => void;
type TOnSuccess<T> = (result: T) => void;
type TOnFailure = (error: Error) => void;
type TOnDrain = (...args: unknown[]) => void;

class RequestsQueue {
  private _concurrency: number;
  private _count: number;
  private _waiting: unknown[];
  private _onProcess: TOnProcess<any> | null;
  private _onSuccess: TOnSuccess<any> | null;
  private _onFailure: TOnFailure | null;
  private _onDrain: TOnDrain | null;

  constructor(concurrency: number) {
    this._concurrency = concurrency;
    this._count = 0;
    this._waiting = [];
    this._onProcess = null;
    this._onSuccess = null;
    this._onFailure = null;
    this._onDrain = null;
  }

  public static channels(concurrency: number) {
    return new RequestsQueue(concurrency);
  }

  public add<T>(task: T) {
    const hasChannel = this._count < this._concurrency;

    if (hasChannel) {
      this.next(task);
      return;
    }

    this._waiting.push(task);
  }

  private next<T>(task: T) {
    this._count++;

    if (!this._onProcess) return;

    this._onProcess(task, (err, result) => {
      if (err) {
        if (this._onFailure) {
          this._onFailure(err);
          console.error(err);
          return;
        }
      } else if (this._onSuccess) {
        this._onSuccess(result);
      }

      this._count--;

      if (this._waiting.length > 0) {
        const task = this._waiting.shift();
        this.next(task);
        return;
      }

      if (this._count === 0 && this._onDrain) {
        this._onDrain();
      }
    });
  }

  public onProcess<T>(listener: TOnProcess<T>) {
    this._onProcess = listener;
    return this;
  }

  public onSuccess<T>(listener: TOnSuccess<T>) {
    this._onSuccess = listener;
    return this;
  }

  public onFailure(listener: TOnFailure) {
    this._onFailure = listener;
    return this;
  }

  public onDone(listener: TOnDrain) {
    this._onDrain = listener;
    return this;
  }
}

export default RequestsQueue;
