const copyWithStyle = (element) => {
  const doc = document;
  let range;
  let selection;

  if (doc.body.createTextRange) {
    range = doc.body.createTextRange();
    range.moveToElement(element);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();

    range = doc.createRange();
    range.selectNodeContents(element);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  document.execCommand('copy');
  window.getSelection().removeAllRanges();
};

const getOrCreateNodeForCopy = () => {
  let node = document.getElementById('for-copy');
  if (!node) {
    node = document.createElement('div');
    node.setAttribute('id', 'for-copy');
    document.body.appendChild(node);
  }

  return node;
};

export const useCopy = (html) => {
  try {
    const nodeForCopy = getOrCreateNodeForCopy();

    nodeForCopy.innerHTML = html;
    copyWithStyle(nodeForCopy);
    nodeForCopy.innerHTML = '';

    return true;
  } catch (error) {
    return false;
  }
};
