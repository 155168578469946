export default [
  {
    value: 23,
  },
  {
    value: 67,
  },
  {
    value: 219,
  },
  {
    value: 77,
  },
  {
    value: 270,
  },
  {
    value: 70,
  },
  {
    value: 210,
  },
  {
    value: 49,
  },
  {
    value: 196,
  },
  {
    value: 49,
  },
  {
    value: 196,
  },
  {
    value: 56,
  },
  {
    value: 226,
  },
  {
    value: 57,
  },
  {
    value: 248,
  },
  {
    value: 72,
  },
  {
    value: 245,
  },
  {
    value: 59,
  },
  {
    value: 257,
  },
  {
    value: 64,
  },
  {
    value: 186,
  },
  {
    value: 51,
  },
  {
    value: 208,
  },
  {
    value: 72,
  },
  {
    value: 248,
  },
  {
    value: 71,
  },
  {
    value: 212,
  },
  {
    value: 73,
  },
  {
    value: 262,
  },
  {
    value: 85,
  },
  {
    value: 281,
  },
  {
    value: 81,
  },
  {
    value: 286,
  },
  {
    value: 99,
  },
  {
    value: 280,
  },
  {
    value: 105,
  },
  {
    value: 295,
  },
  {
    value: 120,
  },
  {
    value: 279,
  },
  {
    value: 112,
  },
  {
    value: 288,
  },
  {
    value: 92,
  },
  {
    value: 295,
  },
  {
    value: 109,
  },
  {
    value: 233,
  },
  {
    value: 86,
  },
  {
    value: 240,
  },
  {
    value: 87,
  },
  {
    value: 223,
  },
  {
    value: 57,
  },
  {
    value: 189,
  },
  {
    value: 70,
  },
  {
    value: 223,
  },
  {
    value: 61,
  },
  {
    value: 226,
  },
  {
    value: 70,
  },
  {
    value: 254,
  },
  {
    value: 63,
  },
  {
    value: 252,
  },
  {
    value: 64,
  },
];
