class MobileEvents {
  // startPoint: {},
  // lastPoint: {},
  // nowPoint: {},
  // ldelay: 0,
  lastSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  mobileWidth = 767; //px

  // table scrolling
  objTable = '';

  resizeWindow = function () {
    let sizeNow = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  removeActiveClassInMenu() {
    // document.getElementById('menu-btn-monitoring').classList.remove('active');
    // document.getElementById('menu-btn-templates').classList.remove('active');
    // document
    //   .getElementById('menu-btn-templates-detail')
    //   .classList.remove('active');
    // document
    //   .getElementById('menu-btn-templates-detail')
    //   .classList.remove('active');
    // document.getElementById('menu-btn-graphics').classList.remove('active');
  }

  setDefaultSize = function () {
    // calculate size
    let windowHeight = window.innerHeight;

    let objectsListContainer = document.getElementById('objectsListContainer');
    let mapId = document.getElementById('mapid');

    let mainMenuHeight = document.getElementById('main-menu').offsetHeight;
    let monitoringSidebarSwitcherHeight = document.getElementById(
      'section-monitoring-sidebar-switcher',
    ).offsetHeight;
    let chooseDatesHeight = document.getElementById('chooseDates').offsetHeight;
    let trackInfoHeight = document.getElementById('track-info').offsetHeight;
    let sectionReports = document.getElementById('section-reports');
    let sectionReportsDetail = document.getElementById(
      'section-reports-detail',
    );
    let sectionGraphics = document.getElementById('section-graphics');

    if (chooseDatesHeight) {
      objectsListContainer.style.height =
        (100 *
          (windowHeight -
            mainMenuHeight -
            monitoringSidebarSwitcherHeight -
            chooseDatesHeight -
            5)) /
          windowHeight +
        'vh';
      let objectsListContainerHeight = objectsListContainer.offsetHeight;
      let parentHeight = objectsListContainer.parentNode.offsetHeight;
      objectsListContainer.style.height =
        (100 *
          (windowHeight -
            mainMenuHeight -
            monitoringSidebarSwitcherHeight -
            chooseDatesHeight -
            1 -
            (parentHeight - objectsListContainerHeight))) /
          windowHeight +
        'vh';
    }

    if (trackInfoHeight) {
      mapId.style.height =
        (100 * (windowHeight - mainMenuHeight - trackInfoHeight)) /
          windowHeight +
        'vh';
    }

    const sectionHeightValue =
      (100 * (windowHeight - mainMenuHeight - 3)) / windowHeight;
    const sectionHeight =
      (100 * (windowHeight - mainMenuHeight - 3)) / windowHeight + 'vh';

    if (sectionReports) {
      sectionReports.style.height = sectionHeight;
    }

    if (sectionReportsDetail) {
      sectionReportsDetail.style.height = sectionHeight;
    }

    if (sectionGraphics) {
      sectionGraphics.style.height = sectionHeight;

      const sectionGraphicsSettingsHeight = 35;

      const sectionGraphicsSettings = document.getElementById(
        'section-graphics-settings',
      );
      const sectionGraphicsContainer = document.getElementById(
        'section-graphics-container',
      );

      if (sectionGraphicsSettings && sectionGraphicsContainer) {
        // sectionGraphicsSettingsHeight = sectionGraphicsSettings.offsetHeight;
        sectionGraphicsSettings.style.height = `${sectionGraphicsSettingsHeight}px`;

        const sectionHeightPxValue = (sectionHeightValue * windowHeight) / 100;

        const sectionGraphicsContainerHeight =
          (100 * (sectionHeightPxValue - sectionGraphicsSettingsHeight - 1)) /
          sectionHeightPxValue;
        sectionGraphicsContainer.style.height = `${sectionGraphicsContainerHeight}%`;
      }
    }
  };

  violationsSettingSwitchShow = function (event) {
    let className = 'active';
    let violationsSettingList = document.getElementById(
      'violations-setting-list',
    );
    if (
      !violationsSettingList /* || (window.innerWidth >= mobileEvents.mobileWidth && ! violationsSettingList.classList.contains(className)) */
    ) {
      return;
    }

    event = event || window.event;
    let target = event.target || event.srcElement;
    if (target.id != 'violations-setting') {
      return;
    }

    if (violationsSettingList.classList.contains(className)) {
      violationsSettingList.classList.remove(className);
    } else {
      violationsSettingList.classList.add(className);
    }
  };

  isTargetTouch = false;

  targetShowTouch = (element) => {
    element.addEventListener('touchstart', (event) => {
      this.isTargetTouch = true; // задержит скрытие по mouseleave и отменит перемещение по mousemove
      const currentElement = event.currentTarget; //this;

      setTimeout(() => {
        currentElement.classList.remove('hover');
        this.isTargetTouch = false;
      }, 100);
    });
  };
}

//   export
const mobileEvents = new MobileEvents();

export default mobileEvents;
