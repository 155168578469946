<template>
  <div class="search-with-list">
    <input
      v-model="search"
      :id="`${props.id}-search-with-list-input`"
      type="search"
      :class="[props.inputClass]"
      :placeholder="selectedText"
      :disabled="props.disabled"
      @focus="toggleSelect"
      @blur="toggleSelect"
    />

    <ul
      class="drop-down__list"
      :class="{ active: isShowSelect }"
    >
      <template v-if="props.data.length">
        <li
          v-for="obj in props.data"
          v-show="obj.isShow"
          class="drop-down__item"
          :class="{ active: obj.value == props.selected }"
          :style="obj.style"
          v-html="obj.text"
          @click="() => selectHandler(obj)"
        ></li>
      </template>
      <div
        v-else
        class="d-flex align-items-center justify-content-center"
        style="height: 100%"
      >
        {{ props.notData }}
      </div>
    </ul>
  </div>
</template>

<script setup>
import sort_helper from '@/helpers/sort_helper';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps({
  id: { type: String, default: '' },
  notData: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  selected: { type: [String, Number], default: '' },
  data: { type: Array, default: [] },
  inputClass: { type: String, default: '' },
  exceptions: { type: Array, default: [] },
  disabled: Boolean,
});

const emit = defineEmits(['update:selected']);

const search = ref('');

const isShowSelect = ref(false);

watch(
  search,
  (str) => {
    props.data.map((obj) => {
      if (!obj.text) return;
      obj.isShow = obj.text.toLowerCase().indexOf(str.toLowerCase()) > -1;
    });
  },
  { immediate: false },
);

const selectHandler = ({ text, value }) => {
  emit('update:selected', value);
  search.value = '';
  isShowSelect.value = false;
};

const toggleSelect = (e) => {
  if (props.disabled) return;
  if (
    e.target.id === `${props.id}-search-with-list-input` &&
    isShowSelect.value
  )
    return;
  isShowSelect.value = !isShowSelect.value;
};

watch(
  () => props.data,
  () => {
    props.data.map((obj) => {
      obj.isShow = true;
    });
  },
  { immediate: true },
);

const selectedText = computed(() => {
  return (
    props.data.find((obj) => obj.value === props.selected)?.text ||
    props.placeholder ||
    props.notData
  );
});

onMounted(() => {
  document.addEventListener(
    'click',
    (e) => {
      if (e.target.id === `${props.id}-search-with-list-input`) return;
      isShowSelect.value = false;
    },
    true,
  );
});
onBeforeUnmount(() => {
  document.removeEventListener(
    'click',
    () => {
      isShowSelect.value = false;
    },
    true,
  );
});
</script>

<style lang="scss">
@import '@/ManualApp/scss/default.scss';
.search-with-list {
  display: flex;
  flex-direction: column;
  position: relative;
  & .drop-down {
    &__list {
      position: absolute;
      z-index: 100;
      bottom: 0;
      transform: translateY(calc(100% + 2px));
      box-shadow: 0px 4px 16px rgba(#000, 0.4);
      list-style: none;
      background: #fff;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      padding: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #ccc;
      display: none;
      &.active {
        display: block;
      }
    }

    &__item {
      text-align: left;
      padding: 4px 12px;
      border-bottom: 1px solid #bbb;
      cursor: pointer;

      transition: background-color 0.2s ease;
      &:hover,
      &.active {
        background-color: rgba(#000, 0.1);
      }
    }
  }
}
</style>
