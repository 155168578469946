import OurAggregated from '@/src/dataRequest/OurAggregatedClass';

import { IPeriod, Smenas } from '../Smenas';

import {
  aggregationCalculating,
  TSettingOfValues,
} from '../AggregationCalculating';

import type {
  AggregationResponse,
  DiagnosticAggregationPosition,
  DiagnosticRaw,
  DiagnosticRawArray,
  PosDtcLabels,
  ObjConf,
} from './Aggregation';

import {
  getPositionsFromOur,
  ITreatmentPositions,
} from '@/App/TemplateComponents/ExcavatorOperation/getPositionsFromOur';

import {
  addCopyArrOfObjects_helper,
  getPercent,
  getDateByTZH,
} from '@/helpers/main_helper';
import { IPosition } from './Type';
import { TProcessCallback } from '@/types/TProcessCallback';
import { DiagnosticNext } from './Detail';

export type ReportName =
  | 'diagnostic'
  | 'diagnosticDetail'
  | 'descriptionDiagnostic';
export type Id = string;
export type Response = AggregationResponse<DiagnosticAggregationPosition>;

export type TOnSuccess = (result: ReportCalculated) => void;

export interface ReportOptions {
  objects: Id[];
  from: Date;
  to: Date;
  templateName: ReportName;
}

export interface Interval {
  from: number;
  to: number;
}

export type DiagnosticRow = {
  [T in keyof DiagnosticRaw]: DiagnosticRaw[T];
};

export interface ObjectDiagnostic {
  name: string;
  stateNumber: string;
  model: string;
  cnt_real_pos: number;
  Periods: any;
  can_moto_delta?: number;
  posDtcLabels?: PosDtcLabels | null;
}

export interface ReportCalculated {
  objects: ObjectDiagnostic[];
  responseInterval: Interval;
  templateSettings?: any;
}

const DETAIL_COLUMNS = ['time', 'engine_time', 'dgnc', 'is_last_day_pos'];

const SERVER_TZH = 5;
const ROUND_TO = 5; // округление по 5 минут
const WITH_DESCRIPTION_OPTION = 6;
const DEFAULT_OPTION = 5;

const AGGREGATION_ARRAY_TEMPLATE = {
  begin: 0,
  interval: 1,
  code: 2,
  mif: 3,
  is: 4,
  os: 5,
  lamp1: 6,
  lamp2: 7,
  addr: 8,
  priority: 9,
};
const DETAIL_ARRAY_TEMPLATE = {
  code: 0,
  mif: 1,
  is: 2,
  os: 3,
  lamp1: 4,
  lamp2: 5,
  addr: 6,
  priority: 7,
  counter: 8,
};

export default class {
  private _withDescription: boolean;
  private _interval: Interval = { from: 0, to: 0 };

  private _smenas: Smenas;

  private _onProcess: TProcessCallback | null;
  private _onSuccess: TOnSuccess | null;

  constructor(withDescription = false) {
    this._withDescription = withDescription;

    this._onProcess = null;
    this._onSuccess = null;

    this._smenas = new Smenas({ SmenasFlag: false });
  }

  getReport(
    { objects, from, to, templateName }: ReportOptions,
    templateSettings: ReportCalculated['templateSettings'],
  ): void {
    this._smenas.resetSettings();

    if (this._onProcess) {
      this._onProcess('success', 'Обсчет данных для получения отчета');
    }

    this._smenas.getSmenasSetting(ROUND_TO, +from, +to);

    if (this._smenas.badSmenasFlag) {
      if (this._onProcess) {
        this._onProcess('error', 'Смены были заданы неверно');
      } else {
        console.error('Смены были заданы неверно');
      }
      return;
    } else {
      if (this._onProcess) {
        this._onProcess('success', 'Обсчет данных для получения отчета');
      }
    }

    this._interval.from = this._roundMinutes(new Date(+from), ROUND_TO);
    this._interval.to = this._roundMinutes(new Date(+to), ROUND_TO);

    if (
      templateName === 'diagnostic' ||
      templateName === 'descriptionDiagnostic'
    ) {
      this._requestAggregation(objects, templateName, templateSettings);
    } else {
      this._requestDetail(objects);
    }
  }

  private _requestAggregation(
    objects: ReportOptions['objects'],
    templateName: ReportOptions['templateName'],
    templateSettings: ReportCalculated['templateSettings'],
  ): void {
    const ourAggregated = new OurAggregated('diagnostic', {
      timeBegin: this._interval.from,
      timeEnd: this._interval.to,
    });

    if (this._onProcess) {
      ourAggregated.onProcess(this._onProcess as TProcessCallback);
    }

    ourAggregated.onSuccess<Response[]>((responses) => {
      try {
        const reportCalculated = this._calculate(responses);

        reportCalculated.templateSettings = templateSettings;
        if (this._onSuccess) this._onSuccess(reportCalculated);
      } catch (error) {
        if (this._onProcess) {
          const type = `error`;
          const message = `Произошла ошибка при обсчете данных. Повторите запрос, либо перезагрузите страницу`;
          this._onProcess(type, message);
        }
      }
    });

    const option = this._withDescription
      ? WITH_DESCRIPTION_OPTION
      : DEFAULT_OPTION;

    ourAggregated.callOurAggregated(
      objects.map((id) => ({ id })),
      [],
      option,
    );
  }

  private async _requestDetail(
    objectsIds: ReportOptions['objects'],
  ): Promise<void> {
    const countOfQueries = objectsIds.length;
    let numberOfResponse = 0;

    const objects: ObjectDiagnostic[] = [];
    for (const id of objectsIds) {
      try {
        const treatmentPositions = await getPositionsFromOur({
          objId: id,
          processCallback: () => {},
          isOnMap: true,
          interval: this._interval,
          columns: DETAIL_COLUMNS,
        });

        if (!treatmentPositions) continue;

        numberOfResponse++;

        if (this._onProcess) {
          const percent = getPercent(numberOfResponse, countOfQueries);
          this._onProcess(
            'loading',
            `Выполнено ${numberOfResponse} из ${countOfQueries} (${percent}%) `,
            percent,
          );
        }

        const object = this._calculateDiagnosticDetail(treatmentPositions);

        objects.push(object);
      } catch (error) {
        if (this._onProcess) {
          const type = `error`;
          const message = `Произошла ошибка при запросе данных. Повторите запрос, либо перезагрузите страницу`;
          this._onProcess(type, message);
          break;
        }
      }
    }

    if (this._onSuccess) {
      this._onSuccess({
        objects,
        responseInterval: this._interval,
      });
    }
  }

  private _roundMinutes(date: Date, roundTo: number): number {
    const minutes = date.getMinutes();

    date.setMinutes(minutes - (minutes % roundTo));

    return date.getTime();
  }

  private _calculate(Responses: Response[]): ReportCalculated {
    try {
      const objects: ReportCalculated['objects'] = [];
      for (const response of Responses) {
        const { posArray, objConf, error, posDtcLabels = null } = response;
        if (error) {
          console.error(error, response);
          continue;
        }

        const Periods = addCopyArrOfObjects_helper(
          this._smenas.setSmenas.Periods || [],
        ) as IPeriod[];

        if (!Periods) continue;

        const preparedPositions: any[] = [];
        let cnt_real_pos = 0;
        posArray.forEach((cur) => {
          cnt_real_pos += Number(cur.cnt_real_pos) || 0;
          preparedPositions.push({
            ...cur,
            dgnc: JSON.parse(cur.dgnc),
          });
        });

        aggregationCalculating(
          preparedPositions,
          this._settingOfValues,
          Periods,
          this._interval.from,
          this._interval.to,
        );

        let can_moto_delta = 0;
        if (this._withDescription) {
          if (preparedPositions.length > 1) {
            const can_moto_begin =
              preparedPositions?.find?.((pos) => pos.can_moto > 0)?.can_moto ||
              0;
            const can_moto_end =
              preparedPositions?.reverse?.()?.find?.((pos) => pos.can_moto > 0)
                ?.can_moto || 0;
            can_moto_delta = can_moto_end - can_moto_begin;
          }

          this._calculateDiagnosticWithDescription(
            Periods,
            objConf,
            posDtcLabels,
          );
        } else {
          this._calculateDiagnostic(Periods, objConf);
        }

        objects.push({
          name: objConf.name,
          stateNumber: objConf.state_number,
          model: objConf.model,
          cnt_real_pos,
          Periods,
          can_moto_delta,
          posDtcLabels,
        });
      }

      return {
        objects,
        responseInterval: this._interval,
      };
    } catch (error) {
      console.error(error);

      return {
        objects: [],
        responseInterval: this._interval,
      };
    }
  }

  private _calculateDiagnosticDetail(
    treatmentPositions: ITreatmentPositions,
  ): ObjectDiagnostic {
    const { objName, stateNumber, points } = treatmentPositions;

    const Periods = addCopyArrOfObjects_helper(
      this._smenas.setSmenas.Periods || [],
    ) as IPeriod[];

    aggregationCalculating(
      points.allValues,
      this._settingOfValues,
      Periods,
      this._interval.from,
      this._interval.to,
    );

    let positionIndex = 0;
    for (const period of Periods) {
      const { TimeBegin, TimeEnd } = period;
      if (!TimeBegin || !TimeEnd) {
        console.error('Period interval is not defined', TimeBegin, TimeEnd);
        continue;
      }

      period.diagnostic = [];

      for (
        positionIndex;
        positionIndex < points.allValues.length;
        positionIndex++
      ) {
        const position = points.allValues[positionIndex];
        const diagnosticRaw: IPosition['dgnc'] = position.dgnc;

        if (position.time < TimeEnd) {
          if (Array.isArray(diagnosticRaw)) {
            const isPresecenceSignals = this.checkPresenceSignals(
              diagnosticRaw,
              DETAIL_ARRAY_TEMPLATE,
            );

            if (!isPresecenceSignals) {
              continue;
            }

            period.diagnostic.push([position.time / 1000, ...diagnosticRaw]);
          } else {
            const {
              dgnc_spn = null,
              dgnc_fmi = null,
              dgnc_cm = null,
              dgnc_oc = null,
              dgnc_lamp1 = null,
              dgnc_lamp2 = null,
              dgnc_addr = null,
              dgnc_priority = null,
              dgnc_counter = null,
            } = diagnosticRaw || {};

            const diagnosticArray: DiagnosticNext = [
              dgnc_spn,
              dgnc_fmi,
              dgnc_cm,
              dgnc_oc,
              dgnc_lamp1,
              dgnc_lamp2,
              dgnc_addr,
              dgnc_priority,
              dgnc_counter,
            ];

            const isPresecenceSignals = this.checkPresenceSignals(
              diagnosticArray,
              DETAIL_ARRAY_TEMPLATE,
            );

            if (!isPresecenceSignals) {
              continue;
            }

            period.diagnostic.push({
              name: objName || 'н/у',
              stateNumber: stateNumber || 'г/н н/у',
              begin: position.time / 1000,
              code: diagnosticArray[0],
              mif: diagnosticArray[1],
              is: diagnosticArray[2],
              os: diagnosticArray[3],
              lamp1: diagnosticArray[4],
              lamp2: diagnosticArray[5],
              addr: diagnosticArray[6],
              priority: diagnosticArray[7],
              counter: diagnosticArray[8],
            });
          }
        } else {
          // value in next period
          break;
        }
      }
    }

    return {
      name: objName || 'н/у',
      stateNumber: stateNumber || 'г/н н/у',
      model: '',
      cnt_real_pos: points.length,
      Periods,
    };
  }

  private _settingOfValues: TSettingOfValues = {
    time: [0.001, false],
    cnt_real_pos: [1, true],
    engine_time: [1, true],
    can_moto: [1, true],
    seconds_of_the_day: [1, false],
    dgnc: [false, false],
  };

  private checkPresenceSignals(
    dgnc: DiagnosticRaw | DiagnosticNext,
    template: typeof AGGREGATION_ARRAY_TEMPLATE | typeof DETAIL_ARRAY_TEMPLATE,
  ) {
    const lamp1 = dgnc[template['lamp1']];

    if (lamp1 === null || lamp1 === undefined) {
      return false;
    }

    if (((lamp1 >> 2) & 3) === 1) {
      // Yellow signal
      return true;
    }

    if (((lamp1 >> 4) & 3) === 1) {
      // Red signal
      return true;
    }

    return false;
  }

  private _calculateDiagnostic(Periods: IPeriod[], objConf: ObjConf) {
    const temp: { [posDiagnostic: string]: any } = {};

    for (const period of Periods) {
      const { time, dgnc } = period.webSumm;

      for (const key in dgnc) {
        const {
          0: begin,
          1: interval,
          2: code,
          3: mif,
          4: is,
          5: os,
          6: lamp1,
          7: lamp2,
          8: addr,
          9: priority,
        } = dgnc[key];

        const posEntry = `${begin}#${code}#${mif}#${is}#${os}#${lamp1}#${lamp2}#${addr}#${priority}`;

        const faultInterval = this._prepareDiagnosticInterval({
          begin,
          interval,
          period,
        });

        const isPresecenceSignals = this.checkPresenceSignals(
          dgnc[key],
          AGGREGATION_ARRAY_TEMPLATE,
        );

        if (!isPresecenceSignals) {
          continue;
        }

        temp[posEntry] = {
          posTime: time,
          name: objConf.name,
          stateNumber: objConf.state_number,
          model: objConf.model,
          begin,
          interval: faultInterval,
          code,
          mif,
          is,
          os,
          lamp1,
          lamp2,
          addr,
          priority,
        };
      }
    }

    for (const period of Periods) {
      const { TimeBegin, TimeEnd } = period;
      if (!TimeBegin || !TimeEnd) {
        console.error('Period interval is not defined', TimeBegin, TimeEnd);
        continue;
      }

      period.diagnostic = [];

      for (const key in temp) {
        period.diagnostic.push(temp[key]);
        delete temp[key];
      }
    }
  }

  private _calculateDiagnosticWithDescription(
    Periods: IPeriod[],
    objConf: ObjConf,
    posDtcLabels: PosDtcLabels | null,
  ) {
    const temp: { [posDiagnostic: string]: any } = {};

    for (const period of Periods) {
      const { time, dgnc } = period.webSumm;

      if (!dgnc) continue;

      for (const key in dgnc) {
        const {
          0: begin,
          1: interval,
          2: code,
          3: mif,
          4: is,
          5: os,
          6: lamp1,
          7: lamp2,
          8: addr,
          9: priority,
        } = dgnc[key];

        if (lamp1 === null || lamp1 === 0) {
          continue;
        }

        if (+interval < 60) {
          continue;
        }

        // const serverBegin = getDateByTZH(
        //   new Date((begin + interval) * 1000),
        //   SERVER_TZH,
        // );

        // if (+serverBegin < Periods[0].TimeBegin) {
        //   continue;
        // }

        const posEntry = `${code}#${mif}#${is}#${addr}`;

        const intervalNum = this._prepareDiagnosticInterval({
          begin,
          interval,
          period,
        });

        let redLampInterval = 0;
        let yellowLampInterval = 0;
        let CO2ErrorInterval = 0;
        let noSubsystemErrorInterval = 0;

        if (((lamp1 >> 4) & 3) === 1) {
          // биты 5-6, "Красная лампа" (требуется остановка ТС): состояние включено ‘01’
          redLampInterval = intervalNum;
        }
        if (((lamp1 >> 2) & 3) === 1) {
          // биты 3-4, "Желтая лампа" (предупреждение, не требуется немедленная остановка ТС): состояние включено ‘01’
          yellowLampInterval = intervalNum;
        }
        if (((lamp1 >> 6) & 3) === 1) {
          // биты 7-8, Ошибка влияет на выбросы СО2, состояние включено ‘01’
          CO2ErrorInterval = intervalNum;
        }
        if ((lamp1 & 3) === 1) {
          // биты 1-2, Ошибка, скорее всего не связана с электронной подсистемой: , состояние включено ‘01’
          noSubsystemErrorInterval = intervalNum;
        }

        if (
          !redLampInterval &&
          !yellowLampInterval &&
          !CO2ErrorInterval &&
          !noSubsystemErrorInterval
        ) {
          continue;
        }

        if (posEntry in temp) {
          temp[posEntry].interval += intervalNum;
          temp[posEntry].redLampInterval += redLampInterval;
          temp[posEntry].yellowLampInterval += yellowLampInterval;
          temp[posEntry].CO2ErrorInterval += CO2ErrorInterval;
          temp[posEntry].noSubsystemErrorInterval += noSubsystemErrorInterval;
        } else {
          temp[posEntry] = {
            posTime: time,
            name: objConf.name,
            stateNumber: objConf.state_number,
            model: objConf.model,
            begin,
            interval: intervalNum,
            code,
            mif,
            is,
            os,
            lamp1,
            lamp2,
            redLampInterval,
            yellowLampInterval,
            CO2ErrorInterval,
            noSubsystemErrorInterval,
            addr,
            priority,
            posDtcLabels,
          };
        }
      }
    }

    if (posDtcLabels) {
      // clear posDtcLabels addrs and fmis that are not in temp
      for (const addrKey in posDtcLabels.addrs) {
        let found = false;
        for (const key in temp) {
          if (temp[key].addr == addrKey) {
            found = true;
            break;
          }
        }
        if (!found) {
          delete posDtcLabels.addrs[addrKey];
        }
      }

      for (const fmiKey in posDtcLabels.fmis) {
        let found = false;
        for (const key in temp) {
          if (temp[key].mif == fmiKey) {
            found = true;
            break;
          }
        }
        if (!found) {
          delete posDtcLabels.fmis[fmiKey];
        }
      }
    }

    for (const period of Periods) {
      const { TimeBegin, TimeEnd } = period;
      if (!TimeBegin || !TimeEnd) {
        console.error('Period interval is not defined', TimeBegin, TimeEnd);
        continue;
      }

      period.diagnostic = [];

      for (const key in temp) {
        period.diagnostic.push(temp[key]);
        delete temp[key];
      }
    }
  }

  _prepareDiagnosticInterval({
    begin,
    interval,
    period,
  }: {
    begin: number;
    interval: number;
    period: IPeriod;
  }): number {
    if (!period || !period.TimeBegin || !period.TimeEnd) {
      throw new Error('Period is not defined');
    }

    const beginUnix = begin * 1000;
    let intervalUnix = Number(interval) * 1000;

    if (beginUnix < period.TimeBegin) {
      if (beginUnix + intervalUnix > period.TimeBegin) {
        intervalUnix -= period.TimeBegin - beginUnix;
      } else {
        // violation in the previous period
        return 0;
      }
    }
    if (beginUnix + intervalUnix > period.TimeEnd) {
      intervalUnix -= beginUnix + intervalUnix - period.TimeEnd;
    }

    return intervalUnix / 1000;
  }

  onProcess(listener: TProcessCallback): this {
    this._onProcess = listener;
    return this;
  }
  onSuccess(listener: TOnSuccess): this {
    this._onSuccess = listener;
    return this;
  }
}
