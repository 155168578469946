<template>
  <!-- logout: само окно-->
  <div
    class="admin-modal__logout-window"
    v-if="props.show"
    style="display: block"
  >
    <!-- Картинка крестика-->
    <img
      class="close"
      @click="emit('close')"
      src="/images/close.png"
    />

    <div class="full-height text-center">
      <a
        id="logout-btn"
        class="btn btn-primary config-btn-primary btn-lg"
        @click="emit('toMonitoring')"
      >
        Перейти в панель мониторинга без выхода из аккаунта
      </a>
      <br />
      при переходе будут забыты все не сохраненные изменения, ввод логина и
      пароля НЕ потребуется.
      <hr />
      <p>
        <span class="login-text">{{ props.login }}</span> при выходе: будут
        забыты все на сохраненные изменения, для дальнейшего использование
        системы потребуется ввести логин и пароль (подходит для тестирования
        только что созданного пользователя).
      </p>
      <a
        id="logout-btn"
        class="btn btn-primary config-btn-primary btn-lg"
        @click.prevent="logoutHandler"
      >
        Выйти из аккаунта
      </a>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    require: true,
  },
  login: {
    type: String,
    require: true,
  },
});

const emit = defineEmits(['close', 'toMonitoring', 'logout']);

const logoutHandler = () => {
  emit('logout');
};
</script>

<style>
.admin-modal__logout-window {
  /* min-width: 400px; */
  width: 95vw;
  /* min-height: 450px; */
  height: 95vh;
  margin: 25px auto;
  display: none;
  background: #fff;
  padding: 16px;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
