<template>
  <div
    ref="chartNode"
    style="min-width: '100wv'; height 250px"
    :id="CHART_ID"
  ></div>
</template>

<script setup>
import { onMounted, nextTick, inject, ref } from 'vue';

import { D3LinearChart } from '@/d3/D3LinearChart';

import {
  formatDateHelper,
  formatTimeHelper,
  myRoundNumber_helper,
} from '@/helpers/main_helper';

const props = defineProps({
  object: { type: Object, required: true },
  interval: { type: Object, required: true },
});

const CHART_ID = 'excavator-operation-total-chart' + props.object.id;
const MIN_CHART_WIDTH = 550;
const MIN_CHART_HEIGHT = 250;

const toHours = inject('toHours');
const getFullDate = inject('getFullDate');

const chartNode = ref('chartNode');
const chart = ref(null);

onMounted(async () => {
  await nextTick();
  createChart();
});

function createChart() {
  const datasets = getDatasets(props.object);

  const { labels, engine_time, active_time, hirpm_notactime } = datasets;

  const minMax = getMinMaxByInterval();

  const { width, height } = getChartSize();

  const getLabel = (index) => {
    const label = getFullDate(+labels[index] / 1000);
    const engine_timeVal = myRoundNumber_helper(engine_time[index], 1);
    const active_timeVal = myRoundNumber_helper(active_time[index], 1);
    const hirpm_notactimeVal = myRoundNumber_helper(hirpm_notactime[index], 1);

    return `
            Дата: ${label}<br>
            Время работы ДВС: ${engine_timeVal}<br>
            Время активной работы ${active_timeVal}<br>
            Повышенные обороты без активной работы: ${hirpm_notactimeVal}
          `;
  };
  const config = {
    width,
    height,
    isLegend: true,
    legendTextSize: '14px',
    legendRectWidth: 15,
    infoTitleSize: '14px',
    infoTitleBgFill: 'rgba(211, 211, 211,0.8)',
    dataTextSize: '14px',
    dataTextFontWeight: 'bold',
    margin: {
      top: 20,
      left: 50,
      bottom: 60,
      right: 100,
    },
    charts: [
      {
        type: 'bar',
        data: engine_time.map((val, index) => {
          const tooltipHtml = getLabel(index);
          return {
            x: labels[index],
            y: val,
            tooltipHtml,
          };
        }),
        colorStroke: '#0000ff',
        strokeWidth: 1,
        opacity: 0.75,
        label: 'Работа ДВС:',
        legendValueFormat: ({ data, index }) => {
          const { y: value = null } = data[index] ?? {};
          if (value === null) {
            return ' -';
          }
          return myRoundNumber_helper(value, 1) + ' (час)';
        },
        xAxis: 'x',
        yAxis: 'y',
        isDot: false,
        isLine: false,
        isBar: true,
        labelWidth: 120,
        legendValueSymbolsCount: 8,
      },
      {
        type: 'line',
        data: active_time.map((val, index) => ({
          x: labels[index],
          y: val,
        })),
        colorStroke: '#ff0000',
        strokeWidth: 2,
        opacity: 0.75,
        label: 'Активная работа (по инклинометру):',
        legendValueFormat: ({ data, index }) => {
          const { y: value = null } = data[index] ?? {};
          if (value === null) {
            return ' -';
          }
          return myRoundNumber_helper(value, 1) + ' (час)';
        },
        xAxis: 'x',
        yAxis: 'y',
        isDot: false,
        isLine: true,
        isBezierCurve: true,
        legendValueSymbolsCount: 8,
      },
      {
        type: 'bar',
        data: hirpm_notactime.map((val, index) => {
          const tooltipHtml = getLabel(index);

          return {
            x: labels[index],
            y: val,
            tooltipHtml,
          };
        }),
        colorStroke: '#000000',
        strokeWidth: 1,
        opacity: 0.75,
        label: 'Повышенные обороты без активной работы:',
        legendValueFormat: ({ data, index }) => {
          const { y: value = null } = data[index] ?? {};
          if (value === null) {
            return ' -';
          }
          return myRoundNumber_helper(value, 1) + ' (час)';
        },
        xAxis: 'x',
        yAxis: 'y',
        isDot: false,
        isLine: false,
        isBar: true,
        legendValueSymbolsCount: 8,
      },
    ],
    axes: [
      {
        id: 'x',
        label: 'Дата / время',
        min: minMax.min,
        max: minMax.max,
        isTicks: true,
        isTime: true,
        tickFormat: (date) => {
          if (date.getHours() || date.getMinutes() || date.getSeconds()) {
            return formatDateHelper(date, 'hh:nn');
          }

          return formatDateHelper(date, 'dd.mm.yy');
        },
        titleFormat: (date) => {
          return formatDateHelper(date, 'hh:nn:ss dd.mm.yy');
        },
        titleIntervalFormat: (milliseconds) => {
          return formatTimeHelper(milliseconds / 86400000, 'dd - hh:nn:ss');
        },
      },
      {
        id: 'y',
        label: 'Часы',
        orientText: 'left',
        orientScale: 'left',
        isTicks: true,
        isGrids: false,
      },
    ],
  };

  chart.value = new D3LinearChart({
    d3,
    elementId: CHART_ID,
    config,
    eventsHandling: false,
    clearAllButton: false,
  });
}

function getMinMaxByInterval() {
  const sideOffset = 5 * 3600 * 1000;
  const minMax = {
    min: new Date(props.interval.from - sideOffset),
    max: new Date(props.interval.to - 86400000 + sideOffset),
  };
  return minMax;
}

function getChartSize() {
  let width = (props.interval.to - props.interval.from) / 86400000;

  width *= 50;

  if (width < MIN_CHART_WIDTH) width = MIN_CHART_WIDTH;

  let height = 0.4 * document.documentElement.clientHeight;
  if (height < MIN_CHART_HEIGHT) height = MIN_CHART_HEIGHT;

  return { width, height };
}

function getDatasets(object) {
  const { periods } = object;

  const result = {
    labels: [],
    engine_time: [],
    active_time: [],
    hirpm_notactime: [],
  };

  periods.forEach((period, index) => {
    const { TimeBegin, webSumm } = period;
    const { engine_time, active_time, hirpm_notactime } = webSumm;

    result.labels[index] = new Date(TimeBegin);
    result.engine_time[index] = toHours(engine_time);
    result.active_time[index] = toHours(active_time);
    result.hirpm_notactime[index] = toHours(hirpm_notactime);
  });

  return result;
}
</script>
