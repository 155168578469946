<template>
  <table class="table-simple excel-bottom-indent">
    <caption class="caption-top">
      {{
        props.title
      }}
    </caption>
    <tbody>
      <tr>
        <th
          v-for="headerName in props.headerSetting.headers"
          data-excelalignh="center"
          :data-minwidth="props.headerSetting[headerName].width || ''"
        >
          {{ props.headerSetting[headerName]['name'] }}
          <span
            v-if="props.headerClickedRows.includes(headerName)"
            class="link-to-popup"
            @click="emit('showPopup', { name: headerName })"
            >{{ getSumm(headerName) }}</span
          >
        </th>
      </tr>
      <tr
        v-for="(row, i) in props.rows"
        :style="[{ border: prevClickRow === i ? '2px solid #000' : '' }]"
      >
        <td
          v-for="headerName in props.headerSetting.headers"
          data-excelalignh="center"
          :data-formatnum="getFormat(headerName)"
        >
          <span
            v-if="props.bodyClickedRows.includes(headerName)"
            :class="[
              {
                'link-to-popup':
                  +getValue(headerName, row) > 0 ||
                  getValue(headerName, row) !== '0',
              },
            ]"
            v-html="getValue(headerName, row)"
            @click="showPopup(headerName, row, i)"
          ></span>

          <span
            v-else
            v-html="getValue(headerName, row)"
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from '@vue/reactivity';

const props = defineProps({
  title: { type: String, default: '' },
  headerClickedRows: { type: Array, default: [] },
  bodyClickedRows: { type: Array, default: [] },
  headerSetting: { type: Object, required: true },
  rows: { type: Array, default: [] },
});

const emit = defineEmits(['showPopup']);

const prevClickRow = ref(-1);

const getFormat = (header) => {
  const { format = 0 } = props.headerSetting[header];

  return format ? format : '';
};

const getValue = (header, row) => {
  const { format = 0 } = props.headerSetting[header];

  return format ? formatToDisplay_helper(row[header], format) : row[header];
};

const getSumm = (header) => {
  return props.rows.reduce((acc, row) => {
    acc += row[header];
    return acc;
  }, 0);
};

const showPopup = (headerName, row, i) => {
  prevClickRow.value = i;
  if (headerName === 'DForfeits') {
    emit('showPopup', { name: headerName, skill: row.DSkill, id: row.id });
    return;
  }

  if (headerName === 'DCount') {
    emit('showPopup', { name: headerName, skill: row.DSkill });
    return;
  }

  emit('showPopup', { name: headerName, id: row.id || -1 });
};
</script>

<style lang="scss" scoped></style>
