import { MyWSConnect } from '../MyWSConnect';

const maxCountOfRepeatRequest = 3;

export class WSPositionsFetcher extends MyWSConnect {
  isRepeat = false;

  responseCount = 0;
  responsesCount = 0;
  responseNumbers = {};
  positionsCount = 0;

  concatedPositions = [];

  objId = '';
  objName = '';
  config = {};

  repeatRequestNumber = 0;

  constructor(
    objId,
    { timeBegin = 0, interval = 0, isOtherPositions = false },
  ) {
    super();

    if (!objId) throw new Error('objId is not found');
    if (!timeBegin) throw new Error('timeBegin is not found');
    if (!interval) throw new Error('interval is not found');

    this.objId = objId;
    this.timeBegin = timeBegin;
    this.isOtherPositions = isOtherPositions;

    const url = this.prepareUrlForWS(this.base_url, objId, {
      timeBegin,
      interval,
      isOtherPositions,
    });

    this.url = url;
  }

  openHandler(event) {}

  closeHandler(event) {
    super.closeHandler(event);
  }

  messageHandler(data) {
    try {
      const { event } = data;

      // внутренние типы событий
      if (event === 'message') return this.messageTypeProcess(data);

      if (event === 'objconf') return this.objconfTypeProcess(data);

      if (event === 'positions') return this.positionsTypeProcess(data);

      if (event === 'myerror') return this.errorTypeProcess(data);

      if (event === 'done') return this.doneTypeProcess(data);

      throw new Error(`
        unknown message type\r\n
        unknown message type data: ${data}
      `);
    } catch (ex) {
      this.errorHandler(ex);
    }
  }

  errorTypeProcess(e) {
    return;
  }

  repeatRequestAction() {
    // например, сервер убил процесс или сеть недоступна
    // обычно в этом случае event.code 1006
    if (this.repeatRequestNumber < maxCountOfRepeatRequest) {
      this.repeatRequest();
      this.repeatRequestNumber++;
      return false;
    }
    return true;
  }

  clear() {
    super.clear();

    this.repeatRequestNumber = 0;
  }

  doneTypeProcess(data) {
    try {
      // обработка всех ответов

      const { message } = data;

      const positions = this.concatedPositions.reduce((acc, arr) => {
        acc = acc.concat(arr);
        return acc;
      }, []);

      const response = this.prepareDoneObject(positions);

      const intervalIndexs = Object.keys(this.responseNumbers);

      if (intervalIndexs.length > 0) {
        this.repeatRequestForRemainingPositions(intervalIndexs);
      }

      if (
        this.positionsCount === +message.positionsCount &&
        intervalIndexs.length === 0
      ) {
        this.requestDoneHandler(response);
      } else {
        throw new Error(
          `Запрос вернул не все позиции ${this.positionsCount} из ${message.positionsCount}`,
        );
      }
    } catch (ex) {
      this.errorHandler(ex);
    }
  }

  positionsTypeProcess(data) {
    const {
      positionsCount = null,
      positions: thisPositions,
      otherPositions,
      concatedPositions,
    } = data.message;

    let { i } = data.message;

    if (this.isRepeat) {
      if (i === 0) this.positionsCount = 0;

      i = this.getIndexOfRepeatResponse(i);
    }

    const positions = concatedPositions || thisPositions;

    // если пришли не все позиции в конце дозапросим
    if (positionsCount !== positions.length) return;

    this.responseCount++;

    delete this.responseNumbers[i];

    this.concatedPositions[i] = positions;

    this.positionsCount += positions.length;

    const percent = Math.round(
      (this.responseCount / this.responsesCount) * 100,
    );

    this.showMessageHandler(
      `Получен ответ ${this.responseCount} из ${this.responsesCount}, (${percent}%)`,
    );
  }

  getIndexOfRepeatResponse(i) {
    let intervalIndex = this.intervalIndexs.find(
      (idx) => idx - this.intervalIndexs[0] === i,
    );

    return intervalIndex;
  }

  messageTypeProcess(data) {
    const { message: myBody } = data;

    const {
      i,
      positionsCount = null,
      intervalCount = null,
      positions,
      objName,
    } = myBody;

    if (objName) {
      return;
    }

    if (this.isRepeat) return;

    if (intervalCount !== null) {
      for (let i = 0; i < intervalCount; i++) {
        this.responseNumbers[i] = 0;
      }

      this.responsesCount = intervalCount;

      return;
    }

    console.error(`message UNKNOWN`, data);
  }

  objconfTypeProcess(data) {
    if (this.isRepeat) return;

    const { message: myBody } = data;

    this.config = myBody;
  }

  prepareDoneObject(positions) {
    return {
      gearboxName: this.config.objConf.gearbox_name || '',
      getBegin: this.config.getBegin || 0,
      getEnd: this.config.getEnd || 0,
      objId: this.config.objConf.id || '',
      objName: this.config.objConf.name || '',
      objConf: this.config.objConf || {},
      positions,
    };
  }

  repeatRequestForRemainingPositions(intervalIndexs = []) {
    if (!intervalIndexs.length) {
      throw new Error(
        'Для повторения запроса оставшихся позиций необходим хотя бы один индекс запрашиваемого интервала',
      );
    }

    this.showMessageHandler('Были получены не все данные. Дозапрашиваем...');

    const requestPositionsIntervals = [
      {
        timeBegin: this.timeBegin,
        interval: 0,
        indexs: [],
      },
    ];

    let posIntervalIndex = 0;
    for (let i = 0; i < intervalIndexs.length; i++) {
      if (
        requestPositionsIntervals[i].indexs[posIntervalIndex] ===
        intervalIndexs[i] - 1
      ) {
        requestPositionsIntervals[i].interval += 86400;
        requestPositionsIntervals[i].indexs.push(intervalIndexs[i]);
        posIntervalIndex++;
        continue;
      }

      posIntervalIndex = 0;

      const interval = {
        timeBegin: this.timeBegin + 86400 * intervalIndexs[i],
        interval: 86400,
        indexs: [intervalIndexs[i]],
      };

      if (i === 0) {
        requestPositionsIntervals[0] = interval;

        continue;
      }

      requestPositionsIntervals.push(interval);
    }

    requestPositionsIntervals.map(async (obj) => {
      const url = this.prepareUrlForWS(this.base_url, this.objId, {
        timeBegin: obj.timeBegin,
        interval: obj.interval,
        isOtherPositions: this.isOtherPositions,
      });

      while (this.isRepeat) {
        await new Promise((res) => setTimeout(res, 500));
      }

      this.intervalIndexs = obj.indexs;

      this.isRepeat = true;

      this.url = url;

      this.startWS(
        this.requestDoneHandler,
        this.requestFailHandler,
        this.showMessageHandler,
      );
    });
  }
}
