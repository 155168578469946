export class TableRowsDrag {
  delayBuildDom = 500; //ms
  minMoving = 5; //px
  table;
  curBlock;
  row;
  timerMoveId;
  lastTime;
  indexPlanned;

  isNeedMoved = true;
  belowRowIndex;
  lastCursor = {};
  timePlanned;

  //   rowSelected;
  dragOverCount = 0;

  hide(that) {
    if (that.curBlock) {
      that.curBlock.style.display = 'none';
    }
  }

  dragStart(event) {
    const that = this.that;
    setTimeout(() => that.hide(that), 0);
    that.row.classList.add('draggable');

    const { clientX, clientY } = event;
    that.lastCursor = { clientX, clientY };
  }

  mouseUp(e) {
    this.that.removeBlockAndEvent();
  }

  drop() {
    this.that.removeBlockAndEvent();
  }

  removeBlockAndEvent() {
    if (this.curBlock) {
      this.curBlock.remove();
      this.curBlock = null;
    }

    if (this.row) {
      this.row.classList.remove('draggable');
      this.row = null;
    }

    document.removeEventListener('mouseup', {
      handleEvent: this.mouseUp,
      that: this,
    });
    document.removeEventListener('drop', {
      handleEvent: this.drop,
      that: this,
    });
    // this.table.removeEventListener('dragend', this.dragEnd);
    this.table.removeEventListener('dragover', {
      handleEvent: this.dragOver,
      that: this,
    });
  }

  dragOver(event) {
    event.preventDefault();
    this.that.dragOverFunction(event);
  }

  dragOverFunction(event) {
    // webpack искажает this в стрелочной ф-ии dragOver
    this.dragOverCount++;
    if (this.dragOverCount < 10) {
      return;
    }
    this.dragOverCount = 0;

    const time = new Date();

    const { clientX, clientY } = event;
    const { clientX: lastX, clientY: lastY } = this.lastCursor;

    if (Math.abs(clientY - lastY) < this.minMoving) {
      if (this.isNeedMove) {
        this.checkMove({ time });
      }

      return;
    }

    this.lastCursor = { clientX, clientY };

    const belowElem = document.elementFromPoint(clientX, clientY);
    const belowRow = belowElem ? belowElem.closest('.object-list-row') : null;
    const row = this.row;

    if (!belowRow || row === belowRow) {
      return;
    }

    this.indexPlanned = belowRow.rowIndex;

    this.checkMove({ time, belowRow, row });

    if (this.isNeedMove) {
      return;
    }

    this.isNeedMove = true;
    this.timePlanned = time;
  }

  checkMove({ time, belowRow, row = this.row } = {}) {
    if (this.isNeedMove && time - this.timePlanned > this.delayBuildDom) {
      this.isNeedMove = false;
      belowRow = belowRow || this.table.rows[this.indexPlanned];

      if (belowRow.rowIndex > row.rowIndex) {
        belowRow.after(row);
      } else {
        belowRow.before(row);
      }

      return true;
    }

    return false;
  }

  init(tableId, cssDrag, { fromSelector, removeClass } = {}) {
    const table = document.getElementById(tableId);
    this.table = table;
    const that = this;

    // this.table.addEventListener('dragend', this.dragEnd);

    this.table.addEventListener('mousedown', (e) => {
      that.mousedownOnTableFunction({
        e,
        tableId,
        cssDrag,
        fromSelector,
        removeClass,
      });
    });
  }

  mousedownOnTableFunction({
    e,
    tableId,
    cssDrag,
    fromSelector,
    removeClass,
  } = {}) {
    if (cssDrag && !e.target.closest(cssDrag)) {
      return;
    }

    this.removeBlockAndEvent();

    const row = e.target.closest('tr');
    if (!row) {
      return;
    }

    if (fromSelector && removeClass) {
      const fromSelectorElement = row.querySelector(fromSelector);
      if (fromSelectorElement) {
        fromSelectorElement.classList.remove(removeClass);
      }
    }

    this.row = row;

    this.isFocused = true;
    const { top, left } = e.target.getBoundingClientRect();
    // const {clientX, clientY} = e;
    // const startCoords = {top, left}

    const curBlock = row.cloneNode(true);
    const cellsCount = row.cells.length;

    for (let i = 0; i < cellsCount; i++) {
      const { offsetWidth: cellWidth } = row.cells[i];
      if (cellWidth) {
        curBlock.cells[i].style.width = `${cellWidth}px`;
      }
    }

    // this.table.addEventListener('drag', this.dragMove);

    curBlock.style.position = 'fixed';

    curBlock.style.left = `${left}px`;
    curBlock.style.top = `${top}px`;

    curBlock.draggable = true;
    this.table.append(curBlock);

    this.curBlock = curBlock;

    this.table.addEventListener('dragover', {
      handleEvent: this.dragOver,
      that: this,
    });
    this.table.addEventListener('dragstart', {
      handleEvent: this.dragStart,
      that: this,
    });

    document.addEventListener('mouseup', {
      handleEvent: this.mouseUp,
      that: this,
    });
    document.addEventListener('drop', { handleEvent: this.drop, that: this });
  }
}
