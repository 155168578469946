<template>
  <tr v-for="detailIdx in detailIdxes">
    <td
      v-for="colName in columnsSetting.tableColumns"
      :key="colName"
      :data-formatnum="columnsSetting[colName].excelFormat ?? ''"
      :data-value="
        columnsSetting[colName].getExcelValue
          ? columnsSetting[colName].getExcelValue({
              row,
              departureIdx: detailIdx.departureIdx,
              comWorkIdx: detailIdx.comWorkIdx,
            })
          : ''
      "
    >
      {{
        columnsSetting[colName].get({
          row,
          allGeofencesDict,
          departureIdx: detailIdx.departureIdx,
          comWorkIdx: detailIdx.comWorkIdx,
        })
      }}
      <template
        v-if="
          columnsSetting[colName].getAHref &&
          columnsSetting[colName].getAHref({
            row,
            allGeofencesDict,
            departureIdx: detailIdx.departureIdx,
            comWorkIdx: detailIdx.comWorkIdx,
          })
        "
      >
        <a
          target="_blank"
          :href="
            columnsSetting[colName].getAHref({
              row,
              allGeofencesDict,
              departureIdx: detailIdx.departureIdx,
              comWorkIdx: detailIdx.comWorkIdx,
            })
          "
        >
          {{
            columnsSetting[colName].getAText({
              row,
              allGeofencesDict,
              departureIdx: detailIdx.departureIdx,
              comWorkIdx: detailIdx.comWorkIdx,
            })
          }}
        </a>
      </template>
    </td>
  </tr>
</template>
<script>
// import EvacuatorMovingColumn from './EvacuatorMovingColumn.vue'

export default {
  name: 'EvacuatorMovingTableDaysDetailRows',
  // components: {
  //   EvacuatorMovingColumn
  // },
  props: {
    row: {
      type: Object,
      require: true,
    },
    columnsSetting: {
      type: Object,
      require: true,
    },
    allGeofencesDict: {
      type: Object,
      require: true,
    },
  },
  computed: {
    // isNeed() {
    //   const {departures} = this.row;
    //   if (departures.len)
    //   return this.row.de
    // },
    detailIdxes() {
      // departures[0] и departures[0].comWorks[0] размещены без этого модуля
      // departureIdx
      // comWorkIdx
      const { departures = [] } = this.row;
      const detailIdxes = [];
      for (
        let departureIdx = 0;
        departureIdx < departures.length;
        departureIdx++
      ) {
        const departure = departures[departureIdx];
        const { comWorks = [] } = departure;
        if (!comWorks.length && departureIdx) {
          detailIdxes.push({
            departureIdx,
            comWorkIdx: 0,
          });
        }
        comWorks.forEach((_, comWorkIdx) => {
          if (departureIdx || comWorkIdx) {
            detailIdxes.push({
              departureIdx,
              comWorkIdx,
            });
          }
        });
      }
      return detailIdxes;
    },
  },
};
</script>
