<template>
  <td
    v-if="count !== undefined"
    class="table-td"
    v-html="count.toPrice() || '-'"
  ></td>
  <td class="table-td" v-else>
    <spin-loader
      :width="15"
      :height="15"
    />
  </td>
</template>

<script setup>
import { ref, watch } from 'vue';

import SpinLoader from '@/App/Components/SpinLoader.vue';
import { STORE } from '@/ManualApp/STORAGE/STORAGE';

const props = defineProps({
  vehicleId: { type: [Number, String, undefined], default: undefined },
});

const count = ref();

watch(() => props.vehicleId, setCount, { immediate: true });
watch(() => STORE.isRequestedMessagesFromVehicles, setCount, {
  immediate: true,
});

function setCount() {
  if (STORE.isRequestedMessagesFromVehicles) {
    count.value = undefined;
    return;
  }

  if (STORE.messagesFromVehicles[props.vehicleId]) {
    count.value = STORE.messagesFromVehicles[props.vehicleId].poscount.pc;
  } else {
    count.value = 0;
  }
}
</script>

<style lang="scss" scoped>
@import '@/ManualApp/scss/vars.scss';
.table-td {
  font-size: 12px;
  border-color: $table-border-color;
}
</style>
