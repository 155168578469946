<template>
  <ui-module
    :style="{
      width: size.width,
      height: size.height,
      backgroundColor:
        objects?.canExpenceFuelLiters100kmOfPeriod?.backgroundColor,
    }"
    @click="$emit('showModal', 'canExpenceFuelLiters100km')"
  >
    <template #title>
      Расход топлива литров на 100 км. среднее по парку ТС
    </template>
    <template #body>
      {{ this.objects?.canExpenceFuelLiters100kmOfPeriod?.value || 0 }}
    </template>

    <Transition name="fade">
      <my-modal
        v-show="modal === 'canExpenceFuelLiters100km'"
        @close="$emit('closeModal', '')"
      >
        <template #header>
          <div></div>
          <div
            class="canExpenceFuelLiters100km-target__table--title"
            v-html="canExpenceFuelLiters100kmTitle"
          ></div>
          <ui-close-button @click="$emit('closeModal', '')" />
        </template>

        <template #body>
          <ui-chart
            :id="'canExpenceFuelLiters100km-chart'"
            :data="data"
            :dataValueStr="'canExpenceFuelLiters100km'"
            :clientWidth="clientWidth"
            :clientHeight="clientHeight"
            :columnClickHandler="chartColumnClickHandler"
          />
        </template>
      </my-modal>
    </Transition>

    <Transition name="fade">
      <my-modal
        v-if="modal === 'canExpenceFuelLiters100km-table'"
        @close="$emit('closeModal', 'canExpenceFuelLiters100km')"
      >
        <template #header>
          <input
            type="search"
            v-model="searchStr"
            placeholder="Владелец"
            class="form-control filterOn modal-filter"
          />
          <div class="ratioOutLine-target__table--title">
            {{ `Расход топлива литров на 100 км.` }}
            <br v-if="clientWidth < 560" />
            {{ `с ${targetTableData.label[0]} по ${targetTableData.label[1]}` }}
          </div>
          <ui-close-button
            @click="$emit('closeModal', 'canExpenceFuelLiters100km')"
          />
        </template>
        <template #body>
          <ui-table class="canExpenceFuelLiters100km-target__table">
            <template #thead>
              <th
                v-for="(obj, index) in targetTableDataHead"
                :key="index"
                @click="sortTableOn(obj.column)"
              >
                {{ obj.name }}
                <div
                  v-if="sortingColumn === obj.column"
                  class="arrow"
                  :class="[
                    sortingFromLargerToSmaller ? 'arrow-bottom' : 'arrow-top',
                  ]"
                ></div>
              </th>
            </template>
            <template #tbody>
              <tr
                v-for="d in targetTableData.data"
                :key="d.id || 0"
                :bgcolor="d.hex"
                v-show="d.flag"
              >
                <td>{{ d.client }}</td>
                <td>{{ d.model }}</td>
                <td v-html="d.avtoNo"></td>
                <td
                  style="text-align: center; cursor: pointer"
                  @click="
                    tableDistanceClickHandler(d.objId, d.name, d.interval)
                  "
                >
                  <a
                    style="border-bottom: 1px dashed #000"
                    @click.prevent
                  >
                    {{ d.distance }}
                  </a>
                </td>
                <td style="text-align: center">
                  {{
                    isDistanceMore5km(d.distance, d.canExpenceFuelLiters100km)
                  }}
                </td>
                <td style="text-align: center">
                  {{ isDistanceMore10km(d.distance, d.sumAllViolations) }}
                </td>
                <td
                  style="text-align: center; cursor: pointer"
                  @click="showSkillsManReportModal_handler(d.objId, d.interval)"
                  @mouseenter="addHoverHandler($event, d.distance)"
                  @mouseleave="removeHoverHandler($event, d.distance)"
                >
                  <a
                    style="border-bottom: 1px dashed #000"
                    @click.prevent
                  >
                    {{
                      isDistanceMore10km(
                        d.distance,
                        d.violationsPer100kmPerPeriodOfObject,
                      )
                    }}
                  </a>
                  <ui-target :text="'Пробег менее 10км'" />
                </td>
              </tr>
            </template>
          </ui-table>
        </template>
      </my-modal>
    </Transition>

    <Transition name="fade">
      <my-modal
        v-show="showSkillsManReportModal"
        @close="closeSkillsManReportModal"
      >
        <template #header>
          <button
            class="not-print float-left"
            onclick="html_to_excel_helper.export( this, 'skills-man-template-content', 'Навыки MAN суммарный', 'portrait' )"
          >
            Экспорт&nbsp;в&nbsp;EXCEL
          </button>
          <ui-close-button @click="closeSkillsManReportModal" />
        </template>
        <template #body>
          <div
            id="section-violations-graphics-can-expence-fuel-liters-on-100-km-modal"
            style="height: calc(100% - 46px); overflow: scroll"
          ></div>
        </template>
      </my-modal>
    </Transition>

    <the-toaster ref="toaster" />
  </ui-module>
</template>

<script>
import MyModal from '@/App/Components/MyModal.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import UiChart from '../UiChart.vue';
import UiTable from '../UiTable.vue';
import UiModule from './UiModule.vue';
import TheToaster from '../../../Components/toaster/TheToaster.vue';
import UiTarget from '@/App/Monitoring/Sidebar/components/UiTarget.vue';

import {
  sortArrayByStringDown,
  sortArrayByStringUp,
} from '@/helpers/MineHelper';
import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../../../helpers/targetsHelper';

import { OurAggregated } from '@/src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../../../Template/skills_man_template/skills_man_template';
import { skillsManTemplateHtml } from '@/App/TemplateComponents/SkillsMan/SkillsManTemplateHtml';

export default {
  name: 'CanExpenceFuelLitersOn100Km',

  components: {
    MyModal,
    UiCloseButton,
    UiChart,
    UiTable,
    UiModule,
    TheToaster,
    UiTarget,
  },

  props: {
    clientWidth: {
      type: Number,
      required: true,
    },
    clientHeight: {
      type: Number,
      required: true,
    },
    objects: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    size: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      // required: true
    },
  },

  emits: ['showModal', 'closeModal'],

  inject: ['mapModalData'],

  data() {
    return {
      showSkillsManReportModal: false,
      targetTableData: [],
      searchStr: '',
      targetTableDataHead: [
        {
          name: 'Владелец',
          column: 'client',
        },
        {
          name: 'Тип КПП',
          column: 'model',
        },
        {
          name: 'Гос №',
          column: 'avtoNo',
        },
        {
          name: 'Пробег (км)',
          column: 'distance',
        },
        {
          name: 'Расход топлива, литров на 100 км.',
          column: 'canExpenceFuelLiters100km',
        },
        {
          name: 'Нарушения (кол-во)',
          column: 'sumAllViolations',
        },
        {
          name: 'Нарушения (на 100 км)',
          column: 'violationsPer100kmPerPeriodOfObject',
        },
      ],
      sortingColumn: '',
      sortingFromLargerToSmaller: true,
    };
  },

  watch: {
    searchStr() {
      this.search();
    },
  },

  mounted() {
    if (this.clientWidth <= 768) {
      const t = document.getElementById(
        'canExpenceFuelLiters100km-chart-target',
      );
      t.addEventListener('click', () => {
        this.$emit('showModal', 'canExpenceFuelLiters100km-table');
      });
    }
  },

  computed: {
    canExpenceFuelLiters100kmTitle() {
      if (this.objects.canExpenceFuelLiters100kmOfPeriod?.title) {
        return this.objects.canExpenceFuelLiters100kmOfPeriod.title;
      } else {
        return '';
      }
    },
  },

  methods: {
    addHoverHandler(event, distance) {
      if (distance < 10) {
        addHoverOnTarget(event);
      }
    },
    removeHoverHandler(event, distance) {
      if (distance < 10) {
        removeHoverOnTarget(event);
      }
    },
    search() {
      this.targetTableData.data.map((obj) => {
        obj.flag =
          obj.client.toLowerCase().indexOf(this.searchStr.toLowerCase()) !== -1
            ? true
            : false;
      });
    },
    isDistanceMore5km(distance, value) {
      return distance > 5 ? value : '-';
    },
    isDistanceMore10km(distance, value) {
      return distance > 10 ? value : '-';
    },
    sortTableOn(str) {
      if (this.sortingColumn === str) {
        if (this.sortingFromLargerToSmaller) {
          this.targetTableData.data = sortArrayByStringUp(
            this.targetTableData.data,
            str,
          );
        } else {
          this.targetTableData.data = sortArrayByStringDown(
            this.targetTableData.data,
            str,
          );
        }
        this.sortingFromLargerToSmaller = !this.sortingFromLargerToSmaller;
      } else {
        this.sortingColumn = str;
        this.sortingFromLargerToSmaller = true;
        this.targetTableData.data = sortArrayByStringDown(
          this.targetTableData.data,
          str,
        );
      }
    },
    chartColumnClickHandler(event, d) {
      d.countViolationsOfPeriods.map((obj) => (obj.flag = true));

      this.targetTableData = {
        data: d.countViolationsOfPeriods,
        label: d.label.split(' - '),
      };

      this.sortingColumn = '';
      this.sortTableOn('violationsPer100kmPerPeriodOfObject');

      if (this.clientWidth > 768) {
        this.$emit('showModal', 'canExpenceFuelLiters100km-table');
      }
    },
    tableDistanceClickHandler(id, name, interval) {
      try {
        const unixInterval = [
          new Date(interval[0]).getTime(),
          new Date(interval[1]).getTime(),
        ];

        this.mapModalData.data = {
          objId: id,
          objName: name,
          interval: unixInterval,
        };
      } catch (err) {
        console.error(err);
      }
    },
    closeSkillsManReportModal() {
      this.showSkillsManReportModal = false;
    },
    showSkillsManReportModal_handler(id, interval) {
      const ourAggregated = new OurAggregated(skillsManTemplate);

      const form_desc = {
        beginTime: new Date(interval[0]).getTime(),
        endTime: new Date(interval[1]).getTime(),
        objects: [id],
      };

      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName: 'skillsMan',
        form_desc,
        callback: this.skillsManReportModal_callback,
        roundTo: 10,
      });

      this.$refs.toaster.warning({
        message: 'Отправлен запрос для получения данных.',
        delay: 5000,
      });
    },
    skillsManReportModal_callback(data) {
      this.showSkillsManReportModal = true;
      lodashRenderHtmlByTemplateHtml_helper(
        data,
        skillsManTemplateHtml,
        'section-violations-graphics-can-expence-fuel-liters-on-100-km-modal',
      );
      const modal = document.getElementById(
        'section-violations-graphics-can-expence-fuel-liters-on-100-km-modal',
      );
      modal.getElementsByTagName('button')[0].style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
#canExpenceFuelLiters100km-chart-target {
  position: absolute;
  padding: 5px 10px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  display: none;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;

  &.show,
  &:hover {
    display: flex;
  }

  @media (max-width: 768px) {
    &.show {
      display: flex;
    }
  }
}
.canExpenceFuelLiters100km-target__table {
  max-width: 100%;
  max-height: calc(100% - 45px);
  overflow: scroll;
  position: relative;
  font-size: 16px;
  border-top: 1px solid #000;
  text-align: left;

  @media (max-width: 768px) {
    max-height: calc(100% - 75px);
  }

  &--title {
    @media (max-width: 560px) {
      font-size: 15px;
    }
    @media (max-width: 410px) {
      font-size: 14px;
    }
    @media (max-width: 385px) {
      font-size: 13px;
    }
    @media (max-width: 365px) {
      font-size: 12px;
    }
    @media (max-width: 340px) {
      font-size: 11px;
    }
  }
  & table tbody tr td,
  & table thead tr th {
    padding: 0 10px;
    white-space: nowrap;
    border: 1px solid #000;
    color: #000;
  }
  & table tbody tr {
    // &:hover {
    // }
    & td {
      background: transparent;
      font-size: 14px;
      cursor: default;
    }
  }
  & table thead tr th {
    background: #fff;
    text-align: center;
    border-top: none;
    white-space: nowrap;
    cursor: pointer;
  }
  & table {
    border-top: none;
  }
}
</style>
