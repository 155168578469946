<template>
  <div
    id="section-graphics-settings"
    class="full-width not-print"
  >
    <form
      action="javascript:void(null);"
      class="graphics-form"
    >
      <ui-datepicker
        v-model:date="datepickerDate"
        class="graphics-datepicker"
      />
      <input
        type="text"
        name="beginTime"
        class="d-none"
        :value="getTimeByDate(datepickerDate[0])"
      />
      <input
        type="text"
        name="endTime"
        class="d-none"
        :value="getTimeByDate(datepickerDate[1])"
      />

      <select
        name="objectsOption"
        class="custom-select form-control-sm"
        style="width: auto;"
      >
        <option value="list">выбранные для отчета</option>
        <option value="all">все доступные объекты</option>
      </select>

      <span
        class="target-show"
        @mouseenter="addHoverOnTarget($event)"
        @mouseleave="removeHoverOnTarget($event)"
      >
        <input
          class="btn btn-primary config-btn-primary btn-sm"
          type="button"
          data-element="smenasButton"
          value="Смены"
        />
        <span
          class="target-show-span"
          data-element="smenasButtonTarget"
        >
          Настройка смен
        </span>
      </span>

      <div
        data-element="smenasSetting"
        class="template-setting-window"
      >
        <!-- Настройка отчета: задний прозрачный фон-->
        <div
          class="background-wrap"
          style="display: flex"
        ></div>
        <!-- Настройка отчета: само окно-->
        <div
          class="template-setting-window"
          data-element="smenasSettingWrap"
          style="display: flex"
        >
          <div class="template-setting-window-header">
            <div class="template-setting-window-header-name">
              <strong>Настройка смен для графиков</strong><br />
            </div>
            <!-- Картинка крестика-->
            <img
              data-element="close"
              class="close"
              src="/images/close.png"
            />
          </div>

          <div
            data-element="settings"
            class="overflow-auto"
          >
            <div>
              <input
                data-element="smenasTemplateSmenasFlag"
                id="section-graphics-settings-SmenasFlag"
                class="smenasTemplate-SmenasFlag"
                type="checkbox"
                checked
              />
              <label for="section-graphics-settings-SmenasFlag">
                Включить смены
                <span class="isSmenasFlag"
                  ><i class="blue"> активно деление на смены</i></span
                >
                <span class="isNotSmenasFlag"
                  ><i class="blue"> активно деление на сутки</i></span
                >
              </label>
              <br />

              <label>
                <input
                  data-element="smenasTemplate-s1"
                  type="checkbox"
                  checked
                  disabled
                />
                Смена 1
              </label>
              <input
                data-element="smenasTemplate-s1-bh"
                type="text"
                class="input-hours"
                value="07"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s1-bm"
                type="text"
                class="input-hours"
                value="00"
              />
              <span>-</span>
              <input
                data-element="smenasTemplate-s1-eh"
                type="text"
                class="input-hours"
                value="19"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s1-em"
                type="text"
                class="input-hours"
                value="00"
              />
              <br />

              <label>
                <input
                  data-element="smenasTemplate-s2"
                  type="checkbox"
                  checked
                />
                Смена 2
              </label>
              <input
                data-element="smenasTemplate-s2-bh"
                type="text"
                class="input-hours"
                value="19"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s2-bm"
                type="text"
                class="input-hours"
                value="00"
              />
              <span>-</span>
              <input
                data-element="smenasTemplate-s2-eh"
                type="text"
                class="input-hours"
                value="07"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s2-em"
                type="text"
                class="input-hours"
                value="00"
              />
              <br />

              <label>
                <input
                  data-element="smenasTemplate-s3"
                  type="checkbox"
                />
                Смена 3
              </label>
              <input
                data-element="smenasTemplate-s3-bh"
                type="text"
                class="input-hours"
                value="00"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s3-bm"
                type="text"
                class="input-hours"
                value="00"
              />
              <span>-</span>
              <input
                data-element="smenasTemplate-s3-eh"
                type="text"
                class="input-hours"
                value="00"
              />
              <span>:</span>
              <input
                data-element="smenasTemplate-s3-em"
                type="text"
                class="input-hours"
                value="00"
              />
              <br />
            </div>
            <div class="padding-top-5 text-center">
              <small>Окончание настройки смен</small>
            </div>
          </div>
        </div>
      </div>

      <span
        class="target-show"
        @mouseenter="addHoverOnTarget($event)"
        @mouseleave="removeHoverOnTarget($event)"
      >
        <input
          class="btn btn-primary config-btn-primary btn-sm"
          type="submit"
          name="send"
          id="section-graphics-button"
          value="Запросить"
        />
        <span class="target-show-span"
          >Построить график за указанный период</span
        >
      </span>
    </form>
  </div>

  <div
    id="section-graphics-container"
    class="flex"
  >
    <div
      id="section-graphics-sidebar"
      class="flex-item not-print"
    >
      <div
        data-component="averageIndicator"
        class="graphic-pointer active"
      >
        Нарушения по дням<br />эксплуатации,<br />рейтинг водителей<br />период
        1
      </div>
      <div
        data-component="averageIndicatorCopy"
        class="graphic-pointer"
      >
        Нарушения по дням<br />эксплуатации,<br />рейтинг водителей<br />период
        2
      </div>
      <div
        data-component="violationsIndicator"
        class="graphic-pointer"
      >
        Нарушения по парку ТС<br />период 1
      </div>
      <div
        data-component="violationsIndicator2"
        class="graphic-pointer"
      >
        Нарушения по парку ТС<br />период 2
      </div>
      <div
        data-component="productionsIndicator"
        class="graphic-pointer"
      >
        Производственные<br />показатели,<br />работа сцепления<br />период 1
      </div>
      <div
        data-component="productionsIndicator2"
        class="graphic-pointer"
      >
        Производственные<br />показатели,<br />работа сцепления<br />период 2
      </div>
    </div>

    <div
      id="section-graphics-content"
      class="flex-item"
    >
      Контент для графиков
    </div>

    <the-toaster ref="toaster" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, inject } from 'vue';

import UiDatepicker from '@/App/Components/UiDoubleDatepicker.vue';
import TheToaster from '../Components/toaster/TheToaster.vue';
import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../helpers/targetsHelper';
import { GraphicsManager } from '../../Template/graphics/graphicsManager';
import { OurAggregated } from '../../src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template';

const globalObjectsList = inject('globalObjectsList');
const globalObjectsCheckedList = inject('monitoringCheckedObjectsList');

const datepickerDate = ref([]);

watch(datepickerDate, (newDate, oldDate) => {
  // const d = new Date();
  // const nowUnixTime = d.getTime();
  // const nowDay = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);

  // const endUnixTime = new Date(newDate[1]).getTime();

  // if (endUnixTime > nowUnixTime) {
  //   datepickerDate.value[1] = nowDay.toString();

  //   toaster.value.warning({
  //     message:
  //       'Интервал не может заканчиваться позже чем сегодня. Установлена сегодняшняя дата',
  //   });
  // }

  if (!newDate[1]) newDate[1] = oldDate[1];
});

onMounted(() => {
  const ourAggregated = new OurAggregated(
    skillsManTemplate,
    lodashRenderHtml_helper,
  );
  const graphicsManager = new GraphicsManager(globalObjectsList, globalObjectsCheckedList);
  const d = new Date();
  const now = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
  const oneMounthAgo = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate() - 1,
    0,
    0,
    0,
  );
  datepickerDate.value = [oneMounthAgo.toString(), now.toString()];
});

const getTimeByDate = (datepickerDate) => {
  const d = new Date(datepickerDate);

  const year = d.getFullYear();
  let month = 1 + d.getMonth();
  let day = d.getDate();

  let hours = d.getHours();
  let minutes = d.getMinutes();

  month = rotateToStringLen2_helper(month);
  day = rotateToStringLen2_helper(day);
  hours = rotateToStringLen2_helper(hours);
  minutes = rotateToStringLen2_helper(minutes);

  const date = day + '.' + month + '.' + year + ' ';

  return date + hours + ':' + minutes;
};
</script>

<style lang="scss">
.graphics-form {
  min-width: 690px;
  & .graphics-datepicker {
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .dp__pointer {
      font-size: 12px;
      padding: 5px 10px;
      padding-right: 25px;
    }
  }
}
</style>
