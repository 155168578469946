<template>
  <div
    class="monitoring"
    id="section-diagnostics"
  >
    <div v-if="diagnosticReports.length">
      <div
        id="open-sidebar-btn"
        class="open-sidebar-btn cursor-pointer"
        @click="isShow.sidebar = true"
      ></div>
      <monitoring-sidebar
        id="section-diagnostics-sidebar"
        :class="[{ 'sidebar-active': isShow.sidebar }]"
        :reports="diagnosticReports"
        @show="changeDisplaySections"
      />

      <aside
        id="section-diagnostics-map"
        :class="[{ 'map-active': isShow.map }]"
      ></aside>
      <violation-details-modal
        v-if="showViolationDetailsModal"
        :params-index="moreDetailViolationsObject.positionIndex"
        :count-of-positions-shown-top="5"
        :count-of-positions-shown-bottom="5"
        @close="showViolationDetailsModal = false"
      />
    </div>
    <div
      v-else
      class="diagnostics__no-rights"
      id="section-diagnostics"
    >
      Раздел не подключен, обратитесь к поставщику услуг
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch, inject } from 'vue';
import MonitoringSidebar from './Sidebar/DiagnosticsSidebar.vue';
import ViolationDetailsModal from './Map/components/ViolationDetailsModal.vue';

import {
  leafletMain,
  moreDetailViolationsObject,
} from '@/Leaflet/leaflet_main';

import { fuelSettingsStore } from '@/App/store/FuelSettingsStore';

const props = defineProps({
  openWindow: { type: String, required: true },
  clientWidth: { type: Number, required: true },
});
const emit = defineEmits(['ready', 'update:open-window']);

const userData = inject('userData');

const showViolationDetailsModal = ref(false);

const diagnosticReports = ref([]);

const isShow = ref({
  sidebar: true,
  map: true,
});

onMounted(async () => {
  diagnosticReports.value = getDiagnosticRights(userData);

  await nextTick();

  fuelSettingsStore.runUpdating();

  moreDetailViolationsObject.value.openModalHandler = openModalHandler;
});

watch(
  isShow,
  (show, oldShow) => {
    const sidebarNode = document.getElementById('section-diagnostics-sidebar');
    const mapNode = document.getElementById('section-diagnostics-map');
    const openBtnNode = document.getElementById('open-sidebar-btn');

    if (!sidebarNode || !mapNode) return;

    if (openBtnNode) openBtnNode.classList.remove('active');

    mapNode.style.width = '100%';

    if (show.sidebar && show.map) {
      sidebarNode.style.width = '420px';
    }
    if (show.sidebar && !show.map) {
      sidebarNode.style.width = '100%';
      mapNode.style.width = '75%';
    }

    if (!show.sidebar && show.map) {
      openBtnNode.classList.add('active');
    }

    if (!show.sidebar && !show.map) {
      isShow.value.sidebar = !oldShow.sidebar;
      isShow.value.map = !oldShow.map;
    }
  },
  { deep: true },
);

watch(
  () => userData,
  (newUserData, oldUserData) => {
    diagnosticReports.value = getDiagnosticRights(newUserData);
  },
  { deep: true },
);

const getDiagnosticRights = (newUserData) => {
  const templateNamesAvailable =
    newUserData?.senderRights?.templates_names_available_arr || {};
  const reports = [];
  for (const reportName in templateNamesAvailable) {
    if (templateNamesAvailable[reportName]) {
      reports.push(reportName);
    }
  }
  return reports;
};

const openModalHandler = (e) => {
  const target = e.target;

  moreDetailViolationsObject.value.selectedObjId = target.dataset.objId;

  moreDetailViolationsObject.value.positionIndex = target.dataset.paramsIndex;

  showViolationDetailsModal.value = true;
};

const changeDisplaySections = async (name) => {
  if (!name) return;

  isShow.value[name] = !isShow.value[name];

  await new Promise((r) => setTimeout(r, 300));
  leafletMain.map.invalidateSize();
};
</script>

<style lang="scss" scoped>
.diagnostics {
  &__no-rights {
    padding: 10px;
  }
}
.open-sidebar-btn {
  width: 80px;
  height: 80px;

  position: fixed;
  top: 50%;
  left: -80px;
  z-index: 500;

  transform: translateY(-50%);

  background: var(--blue);
  border-radius: 50%;

  transition: all 0.2s ease;

  &.active {
    left: -40px;

    &:hover {
      -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 11;
    width: 20px;
    height: 20px;
    transform: translateY(-50%) rotate(45deg);
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}
#section-diagnostics {
  overflow: hidden;
  @media (max-width: 450px) {
    display: flex;
  }
}
#section-diagnostics-sidebar {
  padding: 0;
  flex-direction: column;

  margin-left: -420px;

  transition: all 0.3s ease-in-out;
  &.sidebar-active {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    display: block;
  }
}
#section-diagnostics-map {
  margin-right: -100vw;

  transition: margin-right 0.3s ease-in-out;
  &.map-active {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    display: none;
  }
}
.monitoring {
  margin: 0;

  &__map {
    height: calc(100vh - 70px);
    width: 100%;

    @media (max-width: 1070px) {
      height: calc(100vh - 86px);
    }

    @media (max-width: 768px) {
      height: calc(100vh - 70px);
    }

    @media (max-width: 575px) {
      height: calc(100vh - 91px);
    }
  }
}
</style>
