type TCallback = (...args: any[]) => any;
type TListener<T extends TCallback> = T;

export default class Request {
  private _onSuccess: TListener<any> | null;
  private _onFailure: TListener<any> | null;
  private _onProcess: TListener<any> | null;
  private _onFinaly: TListener<any> | null;

  public run(cb: (...args: any[]) => Promise<any>): void {
    cb()
      .then((value: unknown) => {
        if (this._onSuccess) {
          this._onSuccess(value);
        }
      })
      .catch((reason: unknown) => {
        if (this._onFailure) {
          this._onFailure(reason);
        }
      })
      .finally(() => {
        if (this._onFinaly) {
          this._onFinaly();
        }
      });
  }

  public abort(): void {
    this._onSuccess = null;
    this._onFailure = null;
    this._onProcess = null;
    this._onFinaly = null;
  }

  public onSuccess<T extends TCallback>(listener: TListener<T>): Request {
    this._onSuccess = listener;
    return this;
  }

  public onFailure<T extends TCallback>(listener: TListener<T>): Request {
    this._onFailure = listener;
    return this;
  }

  public onFinaly<T extends TCallback>(listener: TListener<T>): Request {
    this._onFinaly = listener;
    return this;
  }

  public onProcess<T extends TCallback>(listener: TListener<T>): Request {
    this._onProcess = listener;
    return this;
  }
}
