import { rotateToStringLen2_helper } from '@/helpers/main_helper.js';

export const colorByNumberHelper = (num) => {
  if (num >= 40) {
    return '#FF0000';
  }
  if (num >= 20) {
    return '#F2DCDB';
  }
  if (num >= 10) {
    return '#C4D79B';
  }
  if (num > 0) {
    return '#92D050';
  }
  if (num === 0) {
    return '#D9D9D9';
  }
};

export const colorByNumberHelper2 = (num) => {
  // менее 0,6 – закрашивается красным, в диапазоне от 0,6 – 0,9 – синим, более 0,9 – зеленым.

  if (num <= 0.6) {
    return '#FF0000';
  }
  if (num <= 0.9) {
    return '#44CBF6';
  }
  if (num > 0.9) {
    return '#83f095';
  }
};

export const sortArrayByNumberUp = (arr, str) => {
  return arr.sort((a, b) => a[str] - b[str]);
};

export const sortArrayByNumberDown = (arr, str) => {
  return arr.sort((a, b) => b[str] - a[str]);
};

export const sortArrayByStringUp = (arr, str) => {
  return arr.sort((a, b) => {
    if (a[str] > b[str]) {
      return 1;
    }
    if (a[str] < b[str]) {
      return -1;
    }
    return 0;
  });
};

export const sortArrayByStringDown = (arr, str) => {
  return arr.sort((a, b) => {
    if (a[str] < b[str]) {
      return 1;
    }
    if (a[str] > b[str]) {
      return -1;
    }
    return 0;
  });
};

export const rounded100 = (val) => {
  return Math.floor(val * 100) / 100;
};

export const arraySumm = (arr) => {
  return arr.reduce((a, b) => a + b);
};

export const formatNum = (NUM) => {
  if (!NUM) return;
  const s = NUM.toString();

  // ^(.*?)((?:[,.]\d+)?|)$:
  // ^ - начало строки
  // (.*?) - Подмаска №1: 0 и более любых символов (можно заменить на (\d*) для захвата только цифр)
  // ((?:[,.]\d+)?|) - Подмаска №2: точка или запятая, а потом 1+ цифр или пустая строка
  // $ - конец строки
  // replace(/\B(?=(?:\d{3})*$)/g, ' ') вставляет 1 пробел в том месте, сразу после которого идёт 0 и более повторов 3 цифр до конца строки.

  let [_, num, suffix] = s.match(/^(.*?)((?:[,.]\d+)?|)$/);

  return `${num.replace(/\B(?=(?:\d{3})*$)/g, ' ')}${suffix}`;
};

function stristr(haystack, needle, bool = false) {
  var pos = 0;

  pos = haystack.toLowerCase().indexOf(needle.toLowerCase());

  if (pos == -1) {
    return false;
  } else {
    if (bool) {
      return haystack.substr(0, pos);
    } else {
      return haystack.slice(pos);
    }
  }
}
let cntSteps = '';
const getGearboxType = (sign, str) => {
  let find = stristr(str, sign);
  /* (ищем АКПП в наименовании модели ТС) */
  if (!find) {
    return false;
  }
  /* проверим наличие сцелпения в конфигурации - например МКПП_16 это БЕЗ сцепления*/
  // if (!stristr(str, sign + '_')) {
  //   isClutch = true;
  // }
  /* ищем сколько передач КПП */
  find = find.match(/\d+/g);
  if (!find) {
    /* не указано сколько передач */
    return true;
  }
  cntSteps = find[0];
  if (cntSteps < 5 || cntSteps > 30) {
    /* указано слишком не подходящее число */
    return true;
  }
  return true;
};

export const checkGearBoxType = (model) => {
  if (model) {
    /* модель не указана - нет смысла этот блок делать */
    if (getGearboxType(`Tip Matic`, model)) {
      return `АКПП(TipMatic) ${cntSteps}`;
    } else if (getGearboxType(`TipMatic`, model)) {
      return `АКПП(TipMatic) ${cntSteps}`;
    } else if (getGearboxType(`Traxon`, model)) {
      return `АКПП(Traxon) ${cntSteps}`;
    } else if (getGearboxType(`МКПП`, model)) {
      return `МКПП ${cntSteps}`;
    }
  }
};

export const tolocalDateFromUnix = (date) => {
  return date ? new Date(date * 1000).toLocaleString() : '-';
};

export const isTimeEqualZero = (time) => {
  return +time === 0
    ? '-'
    : formatDateHelper(new Date(time * 1000), 'dd.mm.yyyy hh:nn:ss');
};

export const isDateEqualZero = (time) => {
  return +time === 0
    ? '-'
    : new Date(Number(time * 1000)).toLocaleDateString().toString();
};

export const findObjectById = (arr, id) => {
  return arr.find((obj) => obj.id === id);
};

export const getStringByUnixTime = (unixtime) => {
  const d = new Date(unixtime);

  const year = d.getFullYear();
  let month = 1 + d.getMonth();
  let day = d.getDate();

  let hours = d.getHours();
  let minutes = d.getMinutes();

  month = rotateToStringLen2_helper(month);
  day = rotateToStringLen2_helper(day);
  hours = rotateToStringLen2_helper(hours);
  minutes = rotateToStringLen2_helper(minutes);

  const date = day + '.' + month + '.' + year + ' ';

  return date + hours + ':' + minutes;
};

export const formatDCount = (oldDCount, newDCount) => {
  if (!isNaN(+newDCount)) return (oldDCount += +newDCount);

  if (isNaN(newDCount)) return oldDCount;

  const [oldMin] = oldDCount.split(' ');
  const [newMin, str] = newDCount.split(' ');

  const minutes = +oldMin + +newMin;

  return `${minutes} ${str}`;
};

export const calculateCountPer100km = (value, distance) => {
  return (value / distance) * 100;
};
