<template>
  <div class="ui-chart">
    <span
      :id="`${id}-target`"
      @click="mobileClickHandler()"
    >
      <div class="violations-target__title"></div>
    </span>
    <div
      :id="`${id}-wrapper`"
      class="graphics__item"
    >
      <svg :id="id"></svg>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
export default {
  inheritAttrs: false,
  name: 'UiChart',

  props: {
    id: { type: String, required: true },
    data: {
      type: Array,
      required: true,
      default: [],
    },
    clientWidth: {
      type: Number,
      required: true,
    },
    clientHeight: {
      type: Number,
      required: true,
    },
    dataValueStr: {
      type: String,
      required: false,
      default: null,
    },
    columnClickHandler: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      column: {
        e: {},
        data: {},
      },
    };
  },

  watch: {
    data: {
      handler(newData) {
        this.draw(newData, this.clientWidth, this.clientHeight);
      },
    },
    clientWidth(newClientWidth) {
      this.draw(this.data, newClientWidth, this.clientHeight);
    },
    clientHeight(newClientHeight) {
      this.draw(this.data, this.clientWidth, newClientHeight);
    },
  },

  async mounted() {
    await nextTick();
    document
      .getElementById(`${this.id}-wrapper`)
      .addEventListener('scroll', () => {
        this.removeTargetShow();
      });
  },

  methods: {
    removeTargetShow() {
      const violationsTarget = document.getElementById(`${this.id}-target`);
      if (
        violationsTarget.classList.contains('show') &&
        this.clientWidth < 768
      ) {
        violationsTarget.classList.remove('show');
        return true;
      } else {
        return false;
      }
    },
    mobileClickHandler() {
      const isRemoveTargetShow = this.removeTargetShow();
      if (isRemoveTargetShow) {
        this.columnClickHandler(this.column.e, this.column.data);
      }
    },
    targetElementXHandler(layerX, target) {
      const width = this.clientWidth;
      const shareTargetWidth = target.offsetWidth / 1.5;

      const outsideLeftBorder = layerX - target.offsetWidth < 0;
      const outsideRightBorder = layerX + target.offsetWidth > width;

      if (outsideLeftBorder) {
        return shareTargetWidth;
      } else if (outsideRightBorder) {
        return width - shareTargetWidth - 5;
      } else {
        return layerX;
      }
    },
    draw(data, clientWidth, clientHeight) {
      let width = 800;
      let height = 450;
      const margin = { top: 20, bottom: 80, left: 50, right: 20 };

      const onePercentWidth = clientWidth / 100;
      const onePercentHeight = clientHeight / 100;
      if (clientWidth <= 768) {
        const countObjects = this.data.length;
        if (countObjects <= 3) {
          width = onePercentWidth * 90 - 40;
        } else if (countObjects <= 10) {
          width = countObjects * 50;
        } else {
          width = countObjects * 20;
        }
        height = onePercentHeight * 80 - 125;
      } else if (clientWidth <= 1024) {
        width = onePercentWidth * 90 - 40;

        height = onePercentHeight * 80 - 150;
      } else {
        width = onePercentWidth * 55 - 40;
        height = onePercentHeight * 80 - 150;
      }

      height = height > 0 ? height : 0;

      document.getElementById(this.id).innerHTML = '';
      const svg = d3
        .select(`#${this.id}`)
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height]);

      const x = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .range([margin.left, width - margin.right])
        .padding(0.2);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d[this.dataValueStr])])
        .range([height - margin.bottom, margin.top]);

      const heightIsMore0 = (d) =>
        d[this.dataValueStr] > 0 ? d[this.dataValueStr] : 0;
      const classIsMore0 = (d) =>
        d[this.dataValueStr] > 0 ? 'bar' : 'bar gray';

      const violationsTarget = document.getElementById(`${this.id}-target`);
      if (clientWidth < 769) {
        svg
          .append('g')
          .selectAll('rect')
          .data(data)
          // .data(data.sort((a, b) => d3.descending(a[valueStr], b[valueStr]))) Сортирует по количеству нарушений за смену
          .join('rect')
          .attr('fill', (d) => d.backgroundColor)
          .attr('x', (d, i) => x(i))
          .attr('y', (d) => y(heightIsMore0(d)))
          .attr('class', (d) => classIsMore0(d))
          .attr('width', x.bandwidth())
          .attr('height', (d) => y(0) - y(heightIsMore0(d)))
          .on('click', (event, d) => {
            violationsTarget.classList.add('show');
            violationsTarget.style.top = event.layerY - 20 + 'px';
            violationsTarget.style.left =
              this.targetElementXHandler(event.layerX, violationsTarget) + 'px';
            violationsTarget.firstChild.innerHTML = `${d.label}: <strong>${
              d[this.dataValueStr]
            }</strong>`;

            this.column.e = event;
            this.column.data = d;
          });
      } else {
        svg
          .append('g')
          .selectAll('rect')
          .data(data)
          // .data(data.sort((a, b) => d3.descending(a[valueStr], b[valueStr]))) Сортирует по количеству нарушений за смену
          .join('rect')
          .attr('fill', (d) => d.backgroundColor)
          .attr('x', (d, i) => x(i))
          .attr('y', (d) => y(heightIsMore0(d)) || 0)
          .attr('class', (d) => classIsMore0(d))
          .attr('width', x.bandwidth() || 0)
          .attr('height', (d) =>
            y(0) - y(heightIsMore0(d)) > 0 ? y(0) - y(heightIsMore0(d)) : 0,
          )
          .on('mouseover', (event, d) => {
            violationsTarget.classList.add('show');
            violationsTarget.style.top = event.layerY - 70 + 'px';
            violationsTarget.style.left = event.layerX + 'px';
            violationsTarget.firstChild.innerHTML = `${d.label}: <strong>${
              d[this.dataValueStr]
            }</strong>`;
          })
          .on('mouseout', () => {
            violationsTarget.classList.remove('show');
          })
          .on('click', (event, d) => this.columnClickHandler(event, d));
      }

      const xAxis = (g) => {
        g.attr('transform', `translate(0, ${height - margin.bottom})`)
          .attr('class', 'xAxisGroup')
          .call(
            d3.axisBottom(x).tickFormat((i) => {
              if (i !== 0 && !!(i % 7) && i !== data.length - 1) {
                return '';
              } else {
                return data[i].shortLabel;
              }
            }),
          );
      };

      const yAxis = (g) => {
        g.attr('transform', `translate(${margin.left}, 0)`).call(
          d3.axisLeft(y).ticks(10, data[this.dataValueStr]),
        );
      };

      svg.append('g').call(xAxis);
      svg.append('g').call(yAxis);
      svg.node();
    },
  },
};
</script>

<style lang="scss">
.ui-chart {
  max-width: 100%;
  position: relative;

  & .graphics__item {
    @media (max-width: 768px) {
      overflow: scroll;
      max-width: 100vw;
    }
  }

  & .xAxisGroup {
    & text {
      font-size: 13px;
      transform: translate(-27px, 22px) rotate(-45deg);
      font-family: monospace;
      font-weight: 700;
    }
  }
  & .violations-target__title {
    white-space: nowrap;
    user-select: none;
    @media (max-width: 768px) {
      border-bottom: 1px dashed #fff;
    }
  }
  & .bar {
    cursor: pointer;
    &:hover {
      fill: aquamarine;
    }
    &.gray {
      fill: #ccc;
    }
  }
  & path {
    stroke: #000;
  }
  & .tick {
    & line {
      stroke: #000;
    }
    & text {
      color: #000;
    }
  }
}
</style>
