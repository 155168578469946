<template>
  <!-- Настройка отчета: само окно-->
  <div
    id="shiftWork-template-setting-window"
    class="template-setting-window"
    style="display: flex"
  >
    <div class="template-setting-window-header">
      <div class="template-setting-window-header-name">
        <strong>Работа ТС посуточно (посменно)</strong><br />
        <small>Настройка отчета</small>
      </div>
      <!-- Картинка крестика-->
      <img
        class="close"
        src="/images/close.png"
        @click="closeHandler"
      />
      <!-- onclick="showHiddenWindow('none', 'shiftWork-template-setting-window')" -->
    </div>

    <div class="overflow-auto">
      <table class="unselectable">
        <tr>
          <td>
            <label
              for="shiftWork-total"
              class="w-100"
              >Итоги (сумма)</label
            >
          </td>
          <td>
            <label
              for="shiftWork-total"
              class="w-100"
            >
              <input
                id="shiftWork-total"
                type="checkbox"
                checked
              />
            </label>
          </td>
        </tr>

        <tr>
          <td>
            <label
              for="shiftWork-isDetail"
              class="w-100"
              >Детализация</label
            >
          </td>
          <td>
            <label
              for="shiftWork-isDetail"
              class="w-100"
            >
              <input
                id="shiftWork-isDetail"
                type="checkbox"
                checked
              />
            </label>
          </td>
        </tr>

        <tr>
          <td><label for="shiftWork-sortBy">Доп. сортировка</label></td>
          <td>
            <select
              id="shiftWork-sortBy"
              class="form-control-sm"
            >
              <option value="techType">Вид техники</option>
              <option value="unit">Подразделение</option>
              <option value="no">Нет (отключена)</option>
            </select>
          </td>
        </tr>

        <tr>
          <td class="padding-top-5"><label>Смены</label></td>
          <td></td>
        </tr>

        <label for="shiftWork-SmenasFlag">
          <tr>
            <td>
              <label
                for="shiftWork-SmenasFlag"
                class="w-100"
              >
                Включить смены
              </label>
            </td>
            <td>
              <label
                for="shiftWork-SmenasFlag"
                class="w-100"
              >
                <input
                  id="shiftWork-SmenasFlag"
                  type="checkbox"
                  checked
                />
              </label>
            </td>
          </tr>
        </label>

        <tr>
          <td>
            <label
              for="shiftWork-Breakdown"
              class="w-100"
              >Итоги смен</label
            >
          </td>
          <td>
            <label
              for="shiftWork-Breakdown"
              class="w-100"
            >
              <input
                id="shiftWork-Breakdown"
                type="checkbox"
                checked
              />
            </label>
          </td>
        </tr>

        <tr>
          <td><label for="shiftWork-s1">Смена 1</label></td>
          <td class="padding-tb-5">
            <input
              id="shiftWork-s1"
              type="checkbox"
              checked
              disabled
            />
            <input
              id="shiftWork-s1-bh"
              type="text"
              class="input-hours"
              value="07"
            />
            :
            <input
              id="shiftWork-s1-bm"
              type="text"
              class="input-hours"
              value="00"
            />
            -
            <input
              id="shiftWork-s1-eh"
              type="text"
              class="input-hours"
              value="19"
            />
            :
            <input
              id="shiftWork-s1-em"
              type="text"
              class="input-hours"
              value="00"
            />
          </td>
        </tr>

        <tr>
          <td><label for="shiftWork-s2">Смена 2</label></td>
          <td class="padding-tb-5">
            <input
              id="shiftWork-s2"
              type="checkbox"
              checked
            />
            <input
              id="shiftWork-s2-bh"
              type="text"
              class="input-hours"
              value="19"
            />
            :
            <input
              id="shiftWork-s2-bm"
              type="text"
              class="input-hours"
              value="00"
            />
            -
            <input
              id="shiftWork-s2-eh"
              type="text"
              class="input-hours"
              value="07"
            />
            :
            <input
              id="shiftWork-s2-em"
              type="text"
              class="input-hours"
              value="00"
            />
          </td>
        </tr>

        <tr>
          <td><label for="shiftWork-s3">Смена 3</label></td>
          <td class="padding-tb-5">
            <input
              id="shiftWork-s3"
              type="checkbox"
            />
            <input
              id="shiftWork-s3-bh"
              type="text"
              class="input-hours"
              value="00"
            />
            :
            <input
              id="shiftWork-s3-bm"
              type="text"
              class="input-hours"
              value="00"
            />
            -
            <input
              id="shiftWork-s3-eh"
              type="text"
              class="input-hours"
              value="00"
            />
            :
            <input
              id="shiftWork-s3-em"
              type="text"
              class="input-hours"
              value="00"
            />
          </td>
        </tr>
      </table>
      <hr />
      Столбцы
      <table>
        <tr>
          <td>
            <input
              id="shiftWork-cols-client"
              type="checkbox"
            />
          </td>
          <td>
            <label
              title="лист сумма"
              for="shiftWork-cols-client"
              class="w-100"
            >
              Владелец
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-endSmena"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="лист детализация"
              for="shiftWork-cols-endSmena"
              class="w-100"
            >
              Окончание смены, время
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-distIgn"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-distIgn"
              class="w-100"
              >Пробег с работающим ДВС, км</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-distIgnOff"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-distIgnOff"
              class="w-100"
              >Пробег с отключенным ДВС, км</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-can_dist"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-can_dist"
              class="w-100"
              >Побег по CAN, км</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-can_dist_deviation"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-can_dist_deviation"
              class="w-100"
              >Разница в пробеге по ГЛОНАСС и CAN, %</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-beginLevel"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="лист детализация"
              for="shiftWork-cols-beginLevel"
              class="w-100"
              >Уровень на начало дня по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-endLevel"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="лист детализация"
              for="shiftWork-cols-endLevel"
              class="w-100"
              >Уровень на конец дня по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-fuelIn"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-fuelIn"
              class="w-100"
              >Заправки по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-fuelOut"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="исты сумма и детализация"
              for="shiftWork-cols-fuelOut"
              class="w-100"
              >Сливы по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-fuelExpence"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-fuelExpence"
              class="w-100"
              >Расход по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-can_expence"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-can_expence"
              class="w-100"
              >Расход по CAN, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-can_expence_deviation"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-can_expence_deviation"
              class="w-100"
              >Разница расхода по CAN и ДУТ, %</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-fuelExpenceAvgLHour"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-fuelExpenceAvgLHour"
              class="w-100"
              >Общий расход по ДУТ, л/ч</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-expenceMove"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-expenceMove"
              class="w-100"
              >Расход в движении по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-expenceAktiv"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="Для спецтехники, листы сумма и детализация"
              for="shiftWork-cols-expenceAktiv"
              class="w-100"
              >Спецтехника: расход при активной работе, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-expenceIdle"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-expenceIdle"
              class="w-100"
              >Расход на стоянке по ДУТ, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-expenceIdlePercent"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-expenceIdlePercent"
              class="w-100"
              >Доля расхода на стоянке по ДУТ, %</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgExpenceL100km"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-avgExpenceL100km"
              class="w-100"
              >Общий расход по ДУТ, л/100км</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgExpenceMoveL100km"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-avgExpenceMoveL100km"
              class="w-100"
              >Расход в движении по ДУТ, л/100км</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgExpenceMoveLHour"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-avgExpenceMoveLHour"
              class="w-100"
              >Расход в движении по ДУТ, л/час</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgExpenceAktivLHour"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="Для спецтехники, листы сумма и детализация"
              for="shiftWork-cols-avgExpenceAktivLHour"
              class="w-100"
              >Спецтехника: расход при активной работе по ДУТ, л/час</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgExpenceParkLHour"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-avgExpenceParkLHour"
              class="w-100"
              >Расход на стоянке по ДУТ, л/час</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-avgSpeed"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-avgSpeed"
              class="w-100"
              >Средняя скорость по ГЛОНАСС, км/ч</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-maxSpeed"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-maxSpeed"
              class="w-100"
              >Максимальная скорость по ГЛОНАСС, км/ч</label
            >
          </td>
        </tr>

        <tr>
          <td>
            <input
              id="shiftWork-cols-engineWork"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-engineWork"
              class="w-100"
              >Работа ДВС, время</label
            >
          </td>
        </tr>

        <tr>
          <td>
            <input
              id="shiftWork-cols-engineWorkMove"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-engineWorkMove"
              class="w-100"
              >Работа ДВС в движении, время</label
            >
          </td>
        </tr>

        <tr>
          <td>
            <input
              id="shiftWork-cols-engineWorkAktiv"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="Для спецтехники, листы сумма и детализация"
              for="shiftWork-cols-engineWorkAktiv"
              class="w-100"
              >Спецтехника: время активной работы</label
            >
          </td>
        </tr>

        <tr>
          <td>
            <input
              id="shiftWork-cols-engineWorkPark"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-engineWorkPark"
              class="w-100"
              >Работа ДВС на стоянке, время</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-moto2"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-moto2"
              class="w-100"
              >Моточасы 2, время</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-expenceMoto2"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-expenceMoto2"
              class="w-100"
              >Расход по ДУТ при моточасах 2, л</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-dataReceivePercent"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-dataReceivePercent"
              class="w-100"
              >Получение данных, %</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-faultDUT"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-faultDUT"
              class="w-100"
              >Неисправность ДУТ, %</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-lastPwr"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="листы сумма и детализация"
              for="shiftWork-cols-lastPwr"
              class="w-100"
              >Б.пит. в посл. позиции (последняя позиция с включенной
              "массой")</label
            >
          </td>
        </tr>
        <tr>
          <td>
            <input
              id="shiftWork-cols-lastRealTime"
              type="checkbox"
              checked
            />
          </td>
          <td>
            <label
              title="лист сумма"
              for="shiftWork-cols-lastRealTime"
              class="w-100"
              >Актуальность данных (время последней полученной позиции)</label
            >
          </td>
        </tr>
      </table>
      <div class="padding-top-5 text-center">
        <small>Окончание настройки отчета</small>
      </div>
    </div>
    <div class="text-center">
      <span
        class="target-show"
        @mouseenter="(e) => addHoverOnTargetByParent(e)"
        @mouseleave="(e) => removeHoverOnTargetByParent(e)"
      >
        <input
          type="button"
          class="btn btn-primary config-btn-primary btn-sm"
          id="shiftWork-template-setting-window-get-template-button"
          value="Выполнить отчет"
          @click="renderReport"
        />
        <span class="target-show-span">Обсчет данных для получения отчета</span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { OurAggregated } from '../../../src/dataRequest/ourAggregated';
import { shiftWork_template } from '../../../Template/shiftWork_template/shiftWork_template';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template';

import {
  addHoverOnTargetByParent,
  removeHoverOnTargetByParent,
} from '@/App/helpers/targetsHelper';

const props = defineProps({
  form_desc: { type: Object, require: true },
});

const ourAggregated = new OurAggregated(skillsManTemplate);

shiftWork_template.ourAggregated = ourAggregated;

const emit = defineEmits(['close']);

const renderReport = () => {
  const state = shiftWork_template.submit(props.form_desc);
  if (state === 'error') {
    return;
  }

  closeHandler();
};

const closeHandler = () => {
  document.getElementById('background-wrap').style.display = 'none';
  emit('close', 'shiftWorkTemplateSettings');
};
</script>

<style></style>
