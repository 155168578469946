import { ref } from 'vue';

import {
  getDistanceFromMeters,
  getDistanceHelper,
} from './get_distance_helper.js';
import {
  convertDegrees,
  formatDateHelper,
  throttle,
} from '@/helpers/main_helper.js';

import { TRACK_DETAIL_COLUMNS } from '@/TableColumns/TrackDetail.js';
import { leafletCheckInGeofencies as checkInGeofencies } from './leaflet_check_in_geofencies.js';

const CENTER_POSITION_MARKER_BUTTON_NAME = 'centerPositionMarkerButton';

const getOnlyDate = (val) => {
  return formatDateHelper(new Date(+val * 1000), 'dd.mm.yyyy');
};

const getOnlyTime = (val) => {
  return formatDateHelper(new Date(+val * 1000), 'hh:nn:ss');
};

export const mapCenterPositionCoords = ref({});
export const mapCurrentPositionCoords = ref({});
export const watchedPositionIndexes = ref([]);

export class MapClass {
  map = '';
  geofencesGroup = {};
  pmCreateCb = null;
  pmEditCb = null;
  pmCutCb = null;
  pmRemoveCb = null;
  pmControlState = null;
  pmEditListeners = null;
  initializated = false;
  positionsOnMap = {};

  constructor(mapid) {
    if (!mapid) return;
    L.PolylineDecorator.include(L.Mixin.Events);

    const shema = this.getTileObject('mapbox/streets-v11');
    const satellite = this.getTileObject('mapbox/satellite-streets-v9');
    const osm = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution: '',
        maxZoom: 25,
      },
    );

    const baseMaps = {
      'Схема Mapbox': shema,
      'Схема OpenStreet': osm,
      'Спутник Mapbox': satellite,
    };

    const map = L.map(mapid, {
      layers: [shema],
      attributionControl: false,
    }).setView([61.262, 73.377], 13);

    this.map = map;

    L.control.layers(baseMaps).addTo(map);

    L.control
      .scale({
        maxWidth: 240,
        metric: true,
        imperial: false,
        position: 'bottomleft',
      })
      .addTo(map);
    map.addControl(
      new L.Control.LinearMeasurement({
        unitSystem: 'metric',
        color: '#FF0080',
        type: 'line',
      }),
    );

    map.pm.setLang('ru');

    this.addCoordinatePopup();
    this.globalRgb = this.globalRgbDefault();
    this.globalRgbBegin = this.globalRgbDefault();

    this.geofencesGroup = L.featureGroup().addTo(map);

    this.pmControlState = {
      drawMarker: false,
      drawPolyline: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolygon: false,
      drawCircle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      removalMode: false,
      rotateMode: false,
    };
    this.map.pm.disableDraw();
    this.map.pm.disableGlobalEditMode();
    this.pmControlStateApply();
    this.map.pm.removeControls();
    this.pmEditListeners = new Map();

    this.tracksLayerGroup = L.layerGroup({ pmIgnore: true });
    this.violationsLayerGroup = L.layerGroup();
    this.tracksLayerGroup.addTo(this.map);
    this.violationsLayerGroup.addTo(this.map);

    checkInGeofencies.initialization({
      map,
      featureGroup: this.geofencesGroup,
    });

    window.dispatchEvent(new Event('Map:initializated'));
    this.initializated = true;
    this.map.pm.disableGlobalEditMode();
  }

  checkOccurencePositions(polygonLatLngs, objectId) {
    const polyline = this.globalPolylines[objectId];
    const latlngs = polyline.getLatLngs();

    const indexes = [];

    latlngs.forEach(({ lat, lng }, index) => {
      if (
        polygonLatLngs[0].lat <= lat &&
        lat <= polygonLatLngs[2].lat &&
        polygonLatLngs[0].lng <= lng &&
        lng <= polygonLatLngs[2].lng
      ) {
        indexes.push(index);
      }
    });

    return { objectId, indexes };
  }

  displayOccurencePositions(occurenceIndexes, objId) {
    const getIcon = (index) => {
      return L.divIcon({
        html: `
        <div 
          class="
            row 
            inline 
            bg-red 
            text-caption 
            text-white 
            rounded-borders
            q-px-xs
            cursor-pointer
          "
        >
          №&nbsp;${index + 1}
        </div>`,
      });
    };

    const getPopupHtml = (index) => {
      const data = this.globalPolylinesValue[objId];

      let body = '';
      TRACK_DETAIL_COLUMNS.forEach((col) => {
        body += `
        <tr>
          <td>${col.label}</td>
          <td>
            ${
              col.format
                ? col.format(data.values[index][col.name])
                : data.values[index][col.name]
            }
          </td>
        </tr>
        `;
      });

      return `
      <div style="background: #00b4ff">
        <div class="q-py-xs text-center" style="background: #00b4ff">${data.objName}</div>
        <div 
          class="
            q-markup-table 
            q-table__container 
            q-table__card 
            q-table--cell-separator 
            q-table--dense 
            q-table--no-wrap 
            sticky-table-header
          " 
          style="max-height: 20vh;"
        >
          <table 
            class="q-table"
          >
            <tbody>
              ${body}
            </tbody>
          </table>
        </div>
      </div>
      `;
    };

    if (this.checkOccurencePolygonMarkerClusterGroup) {
      this.checkOccurencePolygonMarkerClusterGroup.clearLayers();

      occurenceIndexes.forEach((index) => {
        const latLng = this.globalPolylinesValue[objId].latlngs[index];

        const icon = getIcon(index);

        const html = getPopupHtml(index);

        const marker = L.marker(latLng, { icon });

        marker.bindPopup(html, { className: 'my-leaflet-popup' });

        this.checkOccurencePolygonMarkerClusterGroup.addLayer(marker);
      });

      this.checkOccurencePolygonMarkerClusterGroup.addTo(this.map);
    }
  }

  setWatchedPositionIndexes = throttle(
    (occurenceIndexes) => (watchedPositionIndexes.value = occurenceIndexes),
    100,
  );

  checkOccurencePositionsAndDisplayPopup(polygon) {
    const getTable = (indexes, objId) => {
      const headers = [
        {
          name: 'index',
          label: '№',
          format: null,
        },
        {
          name: 'time',
          label: 'Дата',
          format: (time) => getOnlyDate(time / 1000),
        },
        {
          name: 'time',
          label: 'Время',
          format: (time) => getOnlyTime(time / 1000),
        },
        { name: 'speed', label: 'Скорость' },
        {
          name: 'dist',
          label: 'Пробег',
          format: (dist) => Math.floor(dist / 1000),
        },
      ];
      let header = '';

      header += '<tr>';
      headers.forEach((head) => {
        header += `
            <th>${head.label}</th>
          `;
      });
      header += '</tr>';

      let rows = '';

      for (const index of indexes) {
        header += '<tr>';
        header += `<td>${index + 1}</td>`;
        headers.forEach((head) => {
          if (head.name === 'index') return;

          header += `
              <td>${
                head.format
                  ? head.format(
                      this.globalPolylinesValue[objId].values[index][head.name],
                    )
                  : this.globalPolylinesValue[objId].values[index][head.name]
              }</td>
            `;
        });
        header += '</td>';
      }

      return { header, rows };
    };

    const polygonLatLngs = polygon.getLatLngs()[0];

    let occurenceIndexes = [];
    let objId = '';

    for (const objectId in this.globalPolylines) {
      if (!this.globalPolylinesValue[objectId]) continue;

      const { objectId: id, indexes } = this.checkOccurencePositions(
        polygonLatLngs,
        objectId,
      );
      occurenceIndexes = indexes;
      objId = id;
    }

    this.setWatchedPositionIndexes(occurenceIndexes);

    this.displayOccurencePositions(occurenceIndexes, objId);

    if (!occurenceIndexes.length) {
      this.checkOccurencePolygonPopup._close();
      return;
    }

    const { header, rows } = getTable(occurenceIndexes, objId);

    this.checkOccurencePolygonPopup.setContent(
      `
      <div style="background: #00b4ff">
        <div class="q-py-xs text-center" style="background: #00b4ff">${this.globalPolylinesValue[objId].objName}</div>
        <div 
          class="
            q-markup-table 
            q-table__container 
            q-table__card 
            q-table--cell-separator 
            q-table--dense 
            q-table--no-wrap 
            sticky-table-header
          " 
          style="max-height: 20vh;"
        >
          <table 
            class="q-table"
          >
            <thead>
              ${header}
            </thead>
            <tbody>
              ${rows}
            </tbody>
          </table>
        </div>
      </div>
      `,
    );

    if (this.checkOccurencePolygonPopup.getContent()) {
      this.checkOccurencePolygonPopup.openOn(this.map);
    }
  }

  getRectangle({ lat, lng }, offset) {
    const { lat: latD, lng: lngD } = getDistanceFromMeters(
      lat,
      lng,
      offset,
      offset,
    );

    // for cursor to the center
    lat -= latD / 2;
    lng -= lngD / 2;

    const x1 = lat;
    const y1 = lng;

    const x2 = lat + latD;
    const y2 = lng;

    const x3 = lat + latD;
    const y3 = lng + lngD;

    const x4 = lat;
    const y4 = lng + lngD;

    return [
      [x1, y1],
      [x2, y2],
      [x3, y3],
      [x4, y4],
    ];
  }

  checkOccurencePolygon = null;
  checkOccurencePolygonPopup = null;
  checkOccurencePolygonMarkerClusterGroup = null;
  checkOccurencePolygonHold = false;

  toggleCheckPositions({ size, updateSpeed, speed } = {}) {
    if (this.map.hasLayer(this.checkOccurencePolygon)) {
      this.map.removeLayer(this.checkOccurencePolygon);
      this.map.removeLayer(this.checkOccurencePolygonPopup);
      this.checkOccurencePolygonMarkerClusterGroup.clearLayers();
      return false;
    }

    const defaultLatLng = this.getCenterPositionCoords();

    const latlngs = this.getRectangle(defaultLatLng, size.value);

    this.checkOccurencePolygon = L.rectangle(latlngs, {
      dashArray: [2],
      weight: 1,
      color: '#aaa',
    }).addTo(this.map);

    if (this.map.getZoom < 12) this.map.setZoom(12);

    const popupLatLng = {
      lat: latlngs[2][0],
      lng: (latlngs[1][1] + latlngs[2][1]) / 2,
    };

    this.checkOccurencePolygonPopup = new L.Popup()
      .setLatLng(popupLatLng)
      .setContent(
        '<div class="q-px-md q-py-xs">Для просмотра позиций установите над треком</div>',
      )
      .openOn(this.map);
    this.checkOccurencePolygonPopup._container.classList.add(
      'my-leaflet-popup',
    );

    this.checkOccurencePolygonMarkerClusterGroup = L.markerClusterGroup({
      pmIgnore: true,
      maxClusterRadius: 25,
      disableClusteringAtZoom: 18,
      iconCreateFunction: this.iconCreateFunction,
    });

    this.checkOccurencePolygonHold = false;

    const checkOccurencePositionsAndDisplayPopup = throttle(
      (...args) => this.checkOccurencePositionsAndDisplayPopup(...args),
      10,
    );

    this.checkOccurencePolygon.on('click', () => {
      this.checkOccurencePolygonHold = !this.checkOccurencePolygonHold;

      const popupContent = this.checkOccurencePolygonPopup.getContent();
      if (!popupContent) {
        this.checkOccurencePolygonPopup._close();
      } else {
        this.checkOccurencePolygonPopup.openOn(this.map);
      }
    });

    this.map.on('mousemove', (e) => {
      if (this.checkOccurencePolygonHold) return;

      const rectangleLatLngs = this.getRectangle(e.latlng, size.value);

      this.checkOccurencePolygon.setLatLngs(rectangleLatLngs);

      const popupLatLng = {
        lat: rectangleLatLngs[2][0],
        lng: (rectangleLatLngs[1][1] + rectangleLatLngs[2][1]) / 2,
      };
      this.checkOccurencePolygonPopup.setLatLng(popupLatLng);

      checkOccurencePositionsAndDisplayPopup(this.checkOccurencePolygon);
    });

    return true;
  }

  getOnJoistickTouchStartHandler(size, updateSpeed, speed) {
    return (e) => {
      this.lastSpeed.X = e.detail.X;
      this.lastSpeed.Y = e.detail.Y;

      if (this.checkPositionsInterval) return;

      this.checkPositionsInterval = setInterval(() => {
        const { X: xSpeed, Y: ySpeed } = this.lastSpeed;

        const { lat, lng } = this.lastLatLng;

        const newLatLng = {
          lat: lat + 0.00001 * speed.value * (ySpeed * -1),
          lng: lng + 0.00001 * speed.value * xSpeed,
        };

        this.lastLatLng = newLatLng;

        const rectangleLatLngs = this.getRectangle(newLatLng, size.value);

        this.checkOccurencePolygon.setLatLngs(rectangleLatLngs);

        const popupLatLng = {
          lat: rectangleLatLngs[2][0],
          lng: (rectangleLatLngs[1][1] + rectangleLatLngs[2][1]) / 2,
        };
        this.checkOccurencePolygonPopup.setLatLng(popupLatLng);

        this.checkOccurencePositionsAndDisplayPopup(this.checkOccurencePolygon);
      }, updateSpeed.value);
    };
  }
  getOnJoistickTouchMoveHandler() {
    return (e) => {
      this.lastSpeed.X = e.detail.X;
      this.lastSpeed.Y = e.detail.Y;
    };
  }
  getOnJoistickTouchEndHandler() {
    return (e) => {
      this.lastSpeed.X = 0;
      this.lastSpeed.Y = 0;
      clearInterval(this.checkPositionsInterval);
      this.checkPositionsInterval = null;
    };
  }

  lastSpeed = { X: 0, Y: 0 };
  lastLatLng = { lat: 0, lng: 0 };
  checkPositionsInterval = null;
  joistickListeners = {
    touchstart: null,
    touchmove: null,
    touchend: null,
  };
  toggleJoistickCheckPositions({ size, updateSpeed, speed } = {}) {
    if (this.map.hasLayer(this.checkOccurencePolygon)) {
      this.map.removeLayer(this.checkOccurencePolygon);
      this.map.removeLayer(this.checkOccurencePolygonPopup);
      this.checkOccurencePolygonMarkerClusterGroup.clearLayers();

      document.removeEventListener(
        'Joistick:touchstart',
        this.joistickListeners.touchstart,
      );
      document.removeEventListener(
        'Joistick:touchmove',
        this.joistickListeners.touchmove,
      );
      document.removeEventListener(
        'Joistick:touchend',
        this.joistickListeners.touchend,
      );
      this.joistickListeners = {
        touchstart: null,
        touchmove: null,
        touchend: null,
      };

      return false;
    }

    this.lastLatLng = this.getCenterPositionCoords();

    const latlngs = this.getRectangle(this.lastLatLng, size.value);

    this.checkOccurencePolygon = L.rectangle(latlngs, {
      dashArray: [2],
      weight: 1,
      color: '#aaa',
    }).addTo(this.map);

    if (this.map.getZoom < 12) this.map.setZoom(12);

    const popupLatLng = {
      lat: latlngs[2][0],
      lng: (latlngs[1][1] + latlngs[2][1]) / 2,
    };

    this.checkOccurencePolygonPopup = new L.Popup()
      .setLatLng(popupLatLng)
      .setContent(
        '<div class="q-px-md q-py-xs">Для просмотра позиций установите над треком</div>',
      )
      .openOn(this.map);
    this.checkOccurencePolygonPopup._container.classList.add(
      'my-leaflet-popup',
    );

    this.checkOccurencePolygonMarkerClusterGroup = L.markerClusterGroup({
      pmIgnore: true,
      maxClusterRadius: 25,
      disableClusteringAtZoom: 18,
      iconCreateFunction: this.iconCreateFunction,
    });

    this.joistickListeners = {
      touchstart: this.getOnJoistickTouchStartHandler(size, updateSpeed, speed),
      touchmove: this.getOnJoistickTouchMoveHandler(),
      touchend: this.getOnJoistickTouchEndHandler(),
    };
    document.addEventListener(
      'Joistick:touchstart',
      this.joistickListeners.touchstart,
    );
    document.addEventListener(
      'Joistick:touchmove',
      this.joistickListeners.touchmove,
    );
    document.addEventListener(
      'Joistick:touchend',
      this.joistickListeners.touchend,
    );

    this.checkOccurencePolygon.on('click', () => {
      const popupContent = this.checkOccurencePolygonPopup.getContent();
      if (!popupContent) {
        this.checkOccurencePolygonPopup._close();
      } else {
        this.checkOccurencePolygonPopup.addTo(this.map);
      }
    });

    this.checkOccurencePositionsAndDisplayPopup(this.checkOccurencePolygon);
    return true;
  }

  getOnMapMoveHandler({ size, updateSpeed, speed }) {
    return (e) => {
      const latLng = this.getCenterPositionCoords();

      const rectangleLatLngs = this.getRectangle(latLng, size.value);

      this.checkOccurencePolygon.setLatLngs(rectangleLatLngs);

      const popupLatLng = {
        lat: rectangleLatLngs[2][0],
        lng: (rectangleLatLngs[1][1] + rectangleLatLngs[2][1]) / 2,
      };
      this.checkOccurencePolygonPopup.setLatLng(popupLatLng);

      this.checkOccurencePositionsAndDisplayPopup(this.checkOccurencePolygon);
    };
  }

  onMapMove = null;
  toggleMapMoveCheckPositions({ size, updateSpeed, speed } = {}) {
    if (this.map.hasLayer(this.checkOccurencePolygon)) {
      this.map.removeLayer(this.checkOccurencePolygon);
      this.map.removeLayer(this.checkOccurencePolygonPopup);
      this.checkOccurencePolygonMarkerClusterGroup.clearLayers();

      this.map.off('drag', this.onMapMove);
      this.onMapMove = null;

      return false;
    }

    this.lastLatLng = this.getCenterPositionCoords();

    const latlngs = this.getRectangle(this.lastLatLng, size.value);

    this.checkOccurencePolygon = L.rectangle(latlngs, {
      dashArray: [2],
      weight: 1,
      color: '#aaa',
    }).addTo(this.map);

    if (this.map.getZoom < 12) this.map.setZoom(12);

    const popupLatLng = {
      lat: latlngs[2][0],
      lng: (latlngs[1][1] + latlngs[2][1]) / 2,
    };

    this.checkOccurencePolygonPopup = new L.Popup()
      .setLatLng(popupLatLng)
      .setContent(
        '<div class="q-px-md q-py-xs">Для просмотра позиций установите над треком</div>',
      )
      .openOn(this.map);
    this.checkOccurencePolygonPopup._container.classList.add(
      'my-leaflet-popup',
    );

    this.checkOccurencePolygonMarkerClusterGroup = L.markerClusterGroup({
      pmIgnore: true,
      maxClusterRadius: 25,
      disableClusteringAtZoom: 18,
      iconCreateFunction: this.iconCreateFunction,
    });

    this.onMapMove = this.getOnMapMoveHandler({ size, updateSpeed, speed });

    this.map.on('drag', this.onMapMove);

    this.checkOccurencePolygon.on('click', () => {
      const popupContent = this.checkOccurencePolygonPopup.getContent();
      if (!popupContent) {
        this.checkOccurencePolygonPopup._close();
      } else {
        this.checkOccurencePolygonPopup.addTo(this.map);
      }
    });

    this.checkOccurencePositionsAndDisplayPopup(this.checkOccurencePolygon);
    return true;
  }

  async takeScreenShot() {
    const format = 'blob'; // 'image' - return base64, 'canvas' - return canvas
    const overridedPluginOptions = {};
    return await this.screenshoter.takeScreen(format, overridedPluginOptions);
  }

  clearPosition(name) {
    if (this.positionsOnMap[name]) {
      this.map.removeLayer(this.positionsOnMap[name]);
      this.positionsOnMap[name] = null;
    }
  }

  getCenterPositionCoords() {
    const bounds = this.map.getBounds();

    const centerLat = (bounds._northEast.lat + bounds._southWest.lat) / 2;
    const centerLng = (bounds._northEast.lng + bounds._southWest.lng) / 2;

    return { lat: centerLat, lng: centerLng };
  }

  getPositionMarker(coords, imgSrc, draggable = false) {
    return new L.Marker(coords, {
      icon: new L.DivIcon({
        className: 'arrow-map-icon',
        html: `<img class="arrow-map-image" src="${imgSrc}"/>`,
      }),
      draggable,
    });
  }

  addPopupToCenterPositionMarker(marker, latDMS, lonDMS, sortHandler) {
    const div = document.createElement('span');

    div.classList.add('text-subtitle2');

    div.innerHTML = `Позиция центра карты:<br>
                      Широта: ${latDMS},<br>
                      Долгота: ${lonDMS} <br>`;

    if (sortHandler) {
      const button = document.createElement('button');
      button.id = CENTER_POSITION_MARKER_BUTTON_NAME;
      button.tabIndex = 0;
      button.type = 'button';
      button.classList.add(
        'q-btn',
        'q-btn-item',
        'no-outline',
        'q-btn--standard',
        'q-btn--rectangle',
        'q-btn--rounded',
        'bg-secondary',
        'text-white',
        'q-hoverable',
        'q-btn--no-uppercase',
        'cursor-pointer',
      );
      button.innerHTML = `
      <span class="q-focus-helper"></span>
      <span 
        class="
          q-btn__content 
          text-center 
          col 
          items-center 
          q-anchor--skip 
          justify-center 
          row 
          no-wrap 
          text-no-wrap
        "
      >
        Сортировать повторно 
      </span>`;

      button.onclick = sortHandler;

      div.appendChild(button);
    }

    marker.bindPopup(div);
  }

  clearCenterPosition() {
    this.clearPosition('center');

    mapCenterPositionCoords.value = null;
  }

  displayCenterPosition(sortHandler) {
    this.clearPosition('center');

    const coords = this.getCenterPositionCoords();
    const degrees = convertDegrees(coords.lat, coords.lng);

    const marker = this.getPositionMarker(coords, '/images/location.svg', true);

    this.addPopupToCenterPositionMarker(
      marker,
      degrees.latDMS,
      degrees.lonDMS,
      sortHandler,
    );

    if (sortHandler) {
      marker.on('dragend', (e) => {
        mapCenterPositionCoords.value = e.target.getLatLng();
        sortHandler();
      });
    }

    this.positionsOnMap['center'] = marker.addTo(this.map);

    mapCenterPositionCoords.value = coords;
  }

  getCurrentPositionPopupHtml(latDMS, lonDMS, timestamp) {
    const div = document.createElement('span');
    div.classList.add('text-subtitle2');
    div.innerHTML = `Мое местоположение:<br>
                      Широта: ${latDMS},<br>
                      Долгота: ${lonDMS} <br>
                      Время обновления: ${formatDateHelper(
                        new Date(timestamp),
                        'hh:nn:ss',
                      )}`;
    return div;
  }

  clearCurrentPosition() {
    this.clearPosition('current');

    mapCurrentPositionCoords.value = null;
  }

  displayCurrentPosition(position) {
    if (
      !position ||
      !position.coords?.latitude ||
      !position.coords?.longitude
    ) {
      throw new Error(
        'Error on display current position. position is not defined' +
          JSON.stringify(position),
      );
    }

    const coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    const degrees = convertDegrees(coords.lat, coords.lng);

    const div = this.getCurrentPositionPopupHtml(
      degrees.latDMS,
      degrees.lonDMS,
      position.timestamp,
    );

    let marker = this.positionsOnMap['current'];

    if (marker) {
      marker.setLatLng(coords);

      marker.bindPopup(div);

      mapCurrentPositionCoords.value = coords;
    } else {
      marker = this.getPositionMarker(
        coords,
        '/images/gps-location-circle.svg',
        false,
      );

      marker.bindPopup(div);

      this.positionsOnMap['current'] = marker.addTo(this.map);

      mapCurrentPositionCoords.value = coords;
    }
  }

  currentPositionFitBounds() {
    if (mapCurrentPositionCoords.value) {
      this.map.fitBounds([mapCurrentPositionCoords.value]);
    }
  }

  checkLayerInGeofence({ layer, lat, lng } = {}) {
    if (lat && lng && layer) {
      return checkInGeofencies.checkLayerInGeofence({ lat, lng }, layer);
    }
    return false;
  }
  checkLayerInGeofenceByLayerData({ layerData, lat, lng } = {}) {
    if (lat && lng && layerData) {
      return checkInGeofencies.checkLayerDataInGeofence(
        { lat, lng },
        layerData,
      );
    }
    return false;
  }

  checkPointInCircle({
    circleLat,
    cercleLng,
    cercleRadius,
    pointLat,
    pointLng,
  } = {}) {
    if (circleLat && cercleLng && cercleRadius && pointLat && pointLng) {
      return checkInGeofencies.checkCircleByCoords({
        circleLat,
        cercleLng,
        cercleRadius,
        pointLat,
        pointLng,
      });
    }
    return false;
  }

  getPointDistanceToGeo(point, layerData) {
    const { lat, lng } = point;
    const { _mRadius: radius = null, coords } =
      this.getLatLngsByLayerData(layerData);
    if (radius === null) {
      let distMin = null;
      const [latLngs] = coords; // только внешние границы
      if (!latLngs) {
        return null;
      }
      latLngs.forEach(({ lat: lat2, lng: lng2 }) => {
        // const {lat:lat2, lng:lng2} = latLng;
        const distToVertex = checkInGeofencies.getDistance({
          lat,
          lng,
          lat2,
          lng2,
        });
        if (distMin === null || distMin > distToVertex) {
          distMin = distToVertex;
        }
      });
      return distMin;
    } else {
      const { lat: lat2, lng: lng2 } = coords;
      const distToCircle =
        checkInGeofencies.getDistance({ lat, lng, lat2, lng2 }) + radius;
      return distToCircle;
    }
  }

  addPmEventListeners({ createCb, editCb, removeCb, cutCb }) {
    // вызывается из vue
    this.pmCreateCb = createCb;
    this.pmEditCb = editCb;
    this.pmRemoveCb = removeCb;
    this.pmCutCb = cutCb;

    this.map.on('pm:create', this.pmCreateListenEvent);
    this.geofencesGroup.on('pm:edit', this.pmEditListenEvent);
    this.geofencesGroup.on('pm:cut', this.pmCutListenEvent);
    this.geofencesGroup.on('pm:remove', this.pmRemoveListenEvent);
  }

  removePmEventListeners() {
    this.map.off('pm:create', this.pmCreateListenEvent);
    this.geofencesGroup.off('pm:edit', this.pmEditListenEvent);
    this.geofencesGroup.off('pm:remove', this.pmRemoveListenEvent);
    this.geofencesGroup.off('pm:cut', this.pmCutListenEvent);
    this.pmCreateCb = null;
    this.pmEditCb = null;
    this.pmRemoveCb = null;
  }

  addPmEditStyle(layer, flag) {
    if (!layer) {
      return;
    }
    const isIgnore = !flag;

    if (isIgnore) {
      layer.pm.disable();
      layer.pm.disableLayerDrag();
    }

    layer.setStyle({ pmIgnore: isIgnore });
  }

  pmEditListenEvent(e) {
    this.pmEditListenThisEvent(e);
  }

  pmCutListenEvent(e) {
    this.pmCutListenThisEvent(e);
  }

  pmRemoveListenEvent(e) {
    // this.pmRemoveListenThisEvent(e);

    const { _leaflet_id } = e.layer;
    this.pmRemoveCb(_leaflet_id);
  }

  pmEditListenThisEvent(e) {
    const { layer, shape } = e;
    this.roundCircleRadius(layer);
    const { _leaflet_id } = layer;
    // const {_mRadius:newRadius = 0} = layer;
    const newRadius = layer.getRadius ? layer.getRadius() : null;
    this.pmEditCb({ _leaflet_id, layer, shape, newRadius });

    // if (geofence) {
    // if (shape === 'Circle') {
    //   geoJson = this.getGeoJson(geofence.layer, shape, newRadius);
    // }

    // geofence.layer = layer;
    // geofence.geoJson = this.getGeoJson(layer);

    // }
    // layer.removeEventListener('pm:edit', {handleEvent: this.pmEditListenEvent});
    // , geofence
  }

  pmCutListenThisEvent(e) {
    const { originalLayer, layer } = e;
    const { _leaflet_id } = layer;
    const { _leaflet_id: _leaflet_id_original } = originalLayer;
    const shape = layer.feature.geometry.type;
    if (shape.toLowerCase() === 'multipolygon') {
      // отменим изменение т.к. мультиполигоны запрещены
      layer.setLatLngs(originalLayer.getLatLngs());
    }
    this.geofencesGroup.removeLayer(originalLayer);
    originalLayer.remove();
    this.geofencesGroup.addLayer(layer);

    this.pmCutCb({
      _leaflet_id,
      _leaflet_id_original,
      layer,
      shape: layer.feature.geometry.type,
    });
  }

  exchangeLatLngsFromGeoJson(layer, geoJson) {
    const tempLayer = this.geometryToLayer(geoJson);
    layer.setLatLngs(tempLayer.getLatLngs());
    layer.remove();
    layer.addTo(this.map);
  }

  removeGeofence(layer) {
    if (!layer) {
      this.map.off('pm:create', this.pmCreateListenEvent);
      this.pmControlDrawSet(false);
    } else {
      layer.remove();
      this.geofencesGroup.removeLayer(layer);
    }
  }

  showLayer(layer, isVisible) {
    if (!layer) {
      return;
    }
    if (isVisible) {
      this.geofencesGroup.addLayer(layer);
    } else {
      this.geofencesGroup.removeLayer(layer);
    }
  }

  reInitLayer(layer) {
    layer.remove();
    layer.addTo(this.map);
  }

  pmCreateListenThisEvent(e) {
    const pmLayer = e.layer;

    this.roundCircleRadius(pmLayer);

    const geoJson = this.getGeoJson(pmLayer);
    // this.pmControlDrawSet(false);

    const layer = this.geometryToLayer(geoJson);
    layer.setStyle({ pmIgnore: true });
    this.geofencesGroup.addLayer(layer);

    pmLayer.remove();

    if (this.checkSelfIntersection(layer)) {
      layer.remove();
      return;
    }

    this.pmCreateCb(geoJson, layer);
  }

  roundCircleRadius(layer) {
    if (layer.getRadius) {
      layer.setRadius(Math.round(layer.getRadius()));
    }
  }

  checkSelfIntersection(layer) {
    // самопересечения недопустимы
    return layer.pm.hasSelfIntersection && layer.pm.hasSelfIntersection();
  }

  pmControlDrawSet(flag) {
    this.pmControlState['drawPolygon'] = flag;
    this.pmControlState['drawCircle'] = flag;

    if (flag) {
      this.pmControlState.editMode = false;
    }

    this.pmControlStateApply();
  }

  pmControlEditSet(flag) {
    this.pmControlState.editMode = flag;
    this.pmControlState.dragMode = flag;
    this.pmControlState.cutPolygon = flag;
    this.pmControlState.removalMode = flag;
    this.pmControlState.rotateMode = flag;
    this.pmControlStateApply();
  }

  pmCreateListenEvent(e) {
    this.pmCreateListenThisEvent(e);
  }

  createLayerFromGeoJson(geoJson) {
    return L.geoJson(geoJson, {
      pointToLayer: (feature, latlng) => {
        if (feature.geometry.type === 'Point') {
          const radius = this.getCircleRadius(feature);
          if (radius) {
            return new L.Circle(latlng, radius);
          }
          return new L.Marker(latlng);
        }
      },
      style(feature) {
        return {
          // color: feature.properties.color,
          pmIgnore: true,
        };
      },
      // onEachFeature: (feature, layer) => {
      //   const { type } = feature.geometry;
      //   if (type === "Polygon" || type === "MultiPolygon") {
      //     poligons.push({ feature, layer });
      //     return;
      //   }
      //   if (type === "Point" && getCircleRadius(feature)) {
      //     circles.push({ feature, layer });
      //   }
      // },
    });
  }

  geometryToLayer(geoJson) {
    return L.GeoJSON.geometryToLayer(geoJson, {
      pointToLayer: (feature, latlng) => {
        if (feature.geometry.type === 'Point') {
          const radius = this.getCircleRadius(feature);
          if (radius) {
            return new L.Circle(latlng, radius);
          }
          return new L.Marker(latlng);
        }
      },
    });
  }

  getCircleRadius(feature) {
    if (feature.geometry.type === 'Point') {
      const { radius = 0 } = feature.properties ?? {};
      return radius ? parseFloat(radius) : radius;
    }
    return 0;
  }

  pmControlStateApply() {
    let drawModeOff = true;
    let editModeOff = true;

    for (const btnName in this.pmControlState) {
      const flag = this.pmControlState[btnName];
      this.map.pm.Toolbar.setButtonDisabled(btnName, !flag);
      if (flag && btnName.includes('draw')) {
        drawModeOff = false;
      }
      if (
        flag &&
        btnName.includes('edit', 'drag', 'cut', 'removal', 'rotate')
      ) {
        editModeOff = false;
      }
    }

    if (drawModeOff) {
      this.map.pm.disableDraw();
    } else {
      // запрет на стирание фигуры, если уже есть разреение на добавление новой фигуры для геозоны
      this.map.pm.Toolbar.setButtonDisabled('removalMode', true);
    }

    if (editModeOff) {
      this.map.pm.disableGlobalEditMode();
    }
  }

  layerMapFitBounds(layer) {
    if (!layer) {
      return;
    }
    this.map.fitBounds(layer.getBounds());
  }

  addGeofencesControls() {
    if (this.map.pm.controlsVisible()) {
      return;
    }

    this.map.pm.addControls({
      position: 'topleft',
      drawMarker: false,
      drawPolyline: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolygon: true,
      drawCircle: true,
      editMode: true,
      dragMode: true,
      cutPolygon: true,
      removalMode: true,
      rotateMode: true,
      oneBlock: true,
      drawControls: true,
      editControls: true,
      customControls: true,
    });
  }

  removeGeofencesControls() {
    this.map.pm.removeControls();
    this.map.pm.disableDraw();
    this.map.pm.disableGlobalEditMode();
  }

  generateGeoJson() {
    // var fg = L.featureGroup();
    var layers = this.findLayers(this.map);

    var geo = {
      type: 'FeatureCollection',
      features: [],
    };
    layers.forEach(function (layer) {
      const geoJson = this.getGeoJson(layer);
      geo.features.push(geoJson);
    });

    alert(JSON.stringify(geo));
  }

  getLatLngs(layer) {
    if (!layer) {
      return {};
    }

    // getRadius
    const { _mRadius } = layer;

    if (_mRadius) {
      return {
        _mRadius,
        coords: layer.getLatLng(),
      };
    }

    return {
      coords: layer.getLatLngs(),
    };
  }
  getLatLngsByLayerData(layerData) {
    if (!layerData) {
      return {};
    }

    // getRadius
    const { _mRadius } = layerData;

    if (_mRadius) {
      return {
        _mRadius,
        coords: layerData.latLngs,
      };
    }

    return {
      coords: layerData.latLngs,
    };
  }

  replaceLayerFromGeojson(layer, geoJson) {
    if (layer) {
      this.geofencesGroup.removeLayer(layer);
      layer.remove();
    }

    if (!geoJson) {
      return null;
    }

    const groupLayers = this.createLayerFromGeoJson(geoJson);
    const [newLayer] = groupLayers.getLayers();

    this.geofencesGroup.addLayer(newLayer);
    return newLayer;
  }

  getGeoJson(layer, type) {
    if (!layer) {
      return false;
    }

    var geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));
    if (!geoJson.properties) {
      geoJson.properties = {};
    }

    geoJson.properties = JSON.parse(JSON.stringify(layer.options));

    const radius = layer.getRadius ? layer.getRadius() : false; //layer.options.radius;
    if (radius !== false) {
      geoJson.properties.radius = parseFloat(radius);
    }

    if (type) {
      geoJson.properties.type = type.toLowerCase();
    } else if (layer instanceof L.Rectangle) {
      geoJson.properties.type = 'rectangle';
    } else if (layer instanceof L.Circle) {
      geoJson.properties.type = 'circle';
    } else if (layer instanceof L.CircleMarker) {
      geoJson.properties.type = 'circlemarker';
    } else if (layer instanceof L.Polygon) {
      geoJson.properties.type = 'polygon';
    } else if (layer instanceof L.Polyline) {
      geoJson.properties.type = 'polyline';
    } else if (layer instanceof L.Marker) {
      geoJson.properties.type = 'marker';
    }

    return geoJson;
  }

  findLayers(map) {
    var layers = [];
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Polyline ||
        layer instanceof L.Marker ||
        layer instanceof L.Circle ||
        layer instanceof L.CircleMarker
      ) {
        layers.push(layer);
      }
    });

    // filter out layers that don't have the leaflet-geoman instance
    layers = layers.filter((layer) => !!layer.pm);

    // filter out everything that's leaflet-geoman specific temporary stuff
    layers = layers.filter((layer) => !layer._pmTempLayer);

    return layers;
  }

  importGeo() {
    var prom = prompt();
    if (prom) {
      this.importGeoJSON(JSON.parse(prom));
    }
  }

  importGeoJSON(feature) {
    var geoLayer = L.geoJSON(feature, {
      style(feature) {
        return feature.properties.options;
      },
      pointToLayer(feature, latlng) {
        switch (feature.properties.type) {
          case 'marker':
            return new L.Marker(latlng);
          case 'circle':
            return new L.Circle(latlng, feature.properties.options);
          case 'circlemarker':
            return new L.CircleMarker(latlng, feature.properties.options);
        }
      },
    });

    geoLayer.getLayers().forEach((layer) => {
      if (layer._latlng) {
        var latlng = layer.getLatLng();
      } else {
        var latlng = layer.getLatLngs();
      }
      switch (layer.feature.properties.type) {
        case 'rectangle':
          new L.Rectangle(latlng, layer.options).addTo(map);
          break;
        case 'circle':
          new L.Circle(latlng, layer.options).addTo(map);
          break;
        case 'polygon':
          new L.Polygon(latlng, layer.options).addTo(map);
          break;
        case 'polyline':
          new L.Polyline(latlng, layer.options).addTo(map);
          break;
        case 'marker':
          new L.Marker(latlng, layer.options).addTo(map);
          break;
        case 'circlemarker':
          new L.CircleMarker(latlng, layer.options).addTo(map);
          break;
      }
    });
  }

  getTileObject(id) {
    return L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: '',
        // 'Map data &copy; <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id,
        accessToken:
          'pk.eyJ1IjoiaXVyaWktNTA1IiwiYSI6ImNqemlldHRqbzA4NjYzbW84bTQ0OXMzNXkifQ.tPUaHbgVZ6vCSpuxk3IUJA',
      },
    );
  }

  addCoordinatePopup() {
    /* клик на карте с popup-координатами*/
    let popup = L.popup();
    const map = this.map;
    function onMapClick(e) {
      popup
        .setLatLng(e.latlng)
        .setContent('You clicked the map at ' + e.latlng.toString())
        .openOn(map);
    }
    this.map.on('dblclick', onMapClick);

    this.map.on('popupopen', function (e) {
      const buttonNode = e.popup?._source?._popup?._contentNode.querySelector(
        '.js-more-detail-violations-button',
      );
    });
  }

  globalPositions = {};
  globalPolylines = {};
  globalPolylinesValue = {};
  globalMarkers = {};
  decorator = {};
  globalViolations = {};

  objectsLastPositions = {};
  globalRgb = {};
  globalRgbBegin = {};

  globalRgbDefault() {
    return {
      r: 65,
      g: 105,
      b: 255,
    };
  }

  colorHexShift(rgbColor) {
    rgbColor.b += 120;
    if (rgbColor.b > 255) {
      let overflow = this.globalRgb.b - 255;
      rgbColor.b -= 255;
      rgbColor.g += overflow;
    }
    if (rgbColor.g > 255) {
      let overflow = rgbColor.g - 255;
      rgbColor.g -= 255;
      rgbColor.r += overflow;
    }
    if (rgbColor.r > 255) {
      rgbColor.r -= 255;
    }
    return rgbColor;
  }

  clearPositions(objectId) {
    this.deleteMarkersDrainOrRefueling(objectId);

    this.clearLayers(objectId);

    return true;
  }

  clearLayers(objectId) {
    // clear on map
    if (objectId in this.globalMarkers) {
      this.globalMarkers[objectId].clearLayers();
    }
    if (objectId in this.globalPolylines) {
      this.map.removeLayer(this.globalPolylines[objectId]);
    }
    if (objectId in this.globalPolylinesValue) {
      delete this.globalPolylinesValue[objectId];
    }
    if (objectId in this.decorator) {
      this.map.removeLayer(this.decorator[objectId]);
    }
    if (objectId in this.globalViolations) {
      this.globalViolations[objectId].clearLayers(
        this.globalViolations[objectId],
      );
    }
  }

  mapFitBounds(objId) {
    if (!(objId in this.globalPolylines)) return;
    this.map.fitBounds(this.globalPolylines[objId].getBounds());
  }

  addPolylines(points, lineText, objectId, polylineColor) {
    this.clearLayers(objectId);

    function componentToHex(c) {
      const hex = c.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    }

    function rgbToHex(r, g, b) {
      return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }
    if (!polylineColor) {
      polylineColor = rgbToHex(
        this.globalRgb.r,
        this.globalRgb.g,
        this.globalRgb.b,
      );
    }

    if (!('latlngs' in points) || points['latlngs'].length == 0) return;

    // const latlngs = [];
    // const values = [];
    const latlngs = points.latlngs;
    const values = points.values;

    // const latLngsInterval = 5;

    // let prevLatLngs = [];

    // for (let i = 0; i < points.latlngs.length; i++) {
    //   if (i === 0 || i === points.latlngs.length - 1) {
    //     latlngs.push(points['latlngs'][i]);
    //     values.push(points['values'][i]);
    //     prevLatLngs = points['latlngs'][i];
    //     continue;
    //   }

    //   if (points.latlngs[i].violation_id) {
    //     latlngs.push(points['latlngs'][i]);
    //     values.push(points['values'][i]);
    //     prevLatLngs = points['latlngs'][i];
    //     continue;
    //   }

    //   if (points.consumptions_stat && i in points.consumptions_stat) {
    //     latlngs.push(points['latlngs'][i]);
    //     values.push(points['values'][i]);
    //     prevLatLngs = points['latlngs'][i];
    //     continue;
    //   }

    //   const positionDifferenceInKM = getDistanceHelper(
    //     points['latlngs'][i][0],
    //     points['latlngs'][i][1],
    //     prevLatLngs[0],
    //     prevLatLngs[1],
    //   );

    //   const positionDifference = positionDifferenceInKM * 1000;

    //   if (
    //     positionDifference > latLngsInterval ||
    //     positionDifference < -latLngsInterval
    //   ) {
    //     latlngs.push(points['latlngs'][i]);
    //     values.push(points['values'][i]);
    //     prevLatLngs = points['latlngs'][i];
    //   }
    // }

    this.globalPolylines[objectId] = this.addPolyline({
      latlngs,
      options: {
        color: polylineColor,
      },
      text: lineText,
    });
    if (lineText) {
      this.globalPolylines[objectId].openPopup();
    }

    this.globalPolylinesValue[objectId] = {
      objName: points.objName,
      values,
      latlngs,
    };

    this.globalRgb = this.colorHexShift(this.globalRgb);

    const dist = points.values[points.values.length - 1]?.distSumm ?? 0;
    if (dist > 1000) {
      // при пробеге меньше киллометра не нужны стрелки направления движения
      this.decorator[objectId] = L.polylineDecorator(
        this.globalPolylines[objectId],
        {
          pmIgnore: true,
          patterns: [
            {
              offset: '30px',
              repeat: 250,
              symbol: L.Symbol.arrowHead({
                pixelSize: 9,
                polygon: false,
                pathOptions: {
                  stroke: true,
                  color: polylineColor,
                  opacity: 0.7,
                },
              }),
            },
          ],
        },
      );

      this.decorator[objectId].onAdd = function (map) {
        this._map = map;
        this._draw();
        // original line:
        // this._map.on('moveend', this.redraw, this)
        // new line:
        this._map.on(
          'moveend',
          (event) => {
            this.redraw();
            this.bringToBack();
          },
          this,
        );
      };

      this.decorator[objectId].addTo(this.map);
    }

    this.tracksLayerGroup.addLayer(this.globalPolylines[objectId]);

    this.mapFitBounds(objectId);

    return polylineColor;
  }

  addViolationsOnMap(
    points,
    objectId,
    objectName,
    gearboxName,
    violationsSetting,
    isPrevDelete,
  ) {
    if (isPrevDelete && objectId in this.globalViolations) {
      // this.globalViolations[objectId].clearLayers(
      //   this.globalViolations[objectId],
      // );
      this.globalViolations[objectId].clearLayers();
      // this.map.removeLayer(this.globalViolations[objectId]);
      this.violationsLayerGroup.removeLayer(this.globalViolations[objectId]);
    }

    let events;
    let latlngs;
    if (!('params' in points) || !('latlngs' in points)) {
      events = [];
      latlngs = [];
    } else {
      events = points['params'];
      latlngs = points['latlngs'];
    }

    const isMarkers = false;
    let iobjAccelLimit = 50;

    let marker;
    const mCluster = L.markerClusterGroup({
      pmIgnore: true,
      maxClusterRadius: 25,
      disableClusteringAtZoom: 18,
      iconCreateFunction: this.iconCreateFunction,
    });

    for (let i = 0; i < events.length; i++) {
      if (!events[i]['violation_id']) {
        continue;
      }

      const violationIds = events[i]['violation_id'].split(';');
      let founded = false;
      violationIds.forEach((violId) => {
        if (violId in violationsSetting) {
          founded = true;
          return;
        }
      });

      if (!founded) {
        continue; // отключен вывод на карту этих нарушений в настройках пользователем
      }

      if (isMarkers) {
        marker = L.circle(latlngs[i], { pmIgnore: true });
      } else {
        marker = L.circle(latlngs[i], {
          pmIgnore: true,
          radius: 3.5,
          color: '#f00',
          rotationAngle: 45,
        });
      }

      let textShow = `<b>
        ${objectName}
      </b>
        ${gearboxName}
      <br>
      время: ${formatDateHelper(
        new Date(events[i]['viewTime'] * 1000),
        'hh:nn:ss dd.mm.yy',
      )}
      <br>
      скорость(км/ч): ${events[i]['speed'] / 10}
      <br>
      id нарушения: ${events[i]['violation_id']}
      <br>
      описание нарушения: <i>${events[i]['violation_text']}</i>
      </br>
      <div  
        class="link-to-popup js-more-detail-violations-button"
        data-params-index="${i}"
        data-obj-id="${objectId}"
      >
        подробнее
      </div>`;

      textShow = String(textShow)
        .replace(
          'clutch_time_unine',
          events[i]['violation_values']['clutch_time_unine'],
        )
        .replace(
          'pto_cnt_violation',
          events[i]['violation_values']['pto_cnt_violation'],
        )
        .replace('spd_accel', events[i]['violation_values']['spd_accel'] / 10)
        .replace('iobj_accelLimit', iobjAccelLimit);

      marker.bindPopup(textShow);

      // violationsArr.push(marker);
      mCluster.addLayer(marker);
    }

    // this.globalViolations[objectId] = L.layerGroup(violationsArr).addTo(
    //   this.map,
    // );
    this.globalViolations[objectId] = mCluster;
    // this.map.addLayer(mCluster);
    this.violationsLayerGroup.addLayer(mCluster);
  }

  markersDrainOrRefueling = {};
  addDrainsAndRefuelingOnMap(points, isPrevDelete) {
    const getDataByStatus = (status) => {
      // Статус уровнемера:
      // 0 - 4 - первые два байта - важность уровнемера
      // 8 - если установлено то это суммарный уровнемер
      // 16 - событие заправка
      // 32 - идет заправка
      // 64 - событие слив
      // 128 - идет слив

      if (status & 16) {
        const icon = this.createIcon({
          iconUrl: '/images/markers/marker-icon-refueling.png',
          shadowUrl: '/images/markers/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });

        const options = {
          icon,
        };

        return { event: 'заправка', options };
      }

      if (status & 64) {
        const icon = this.createIcon({
          iconUrl: '/images/markers/marker-icon-drain.png',
          shadowUrl: '/images/markers/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });

        const options = {
          icon,
        };

        return { event: 'слив', options };
      }
    };

    const { stateNumber, objId, consumptions_stat } = points;

    if (!this.markersDrainOrRefueling[objId]) {
      this.markersDrainOrRefueling[objId] = [];
    }

    this.deleteMarkersDrainOrRefueling(objId);

    if (!isPrevDelete) return;

    for (let key in consumptions_stat) {
      if (key === 'summ') continue;

      const timeStr = formatDateHelper(
        new Date(consumptions_stat[key].time),
        'hh:nn:ss dd.mm.yy',
      );

      const { event, options } = getDataByStatus(consumptions_stat[key].status);

      const text = `
        <span style="font-weight: bold">
          ${stateNumber}
        </span><br>
        Событие: ${event} (${consumptions_stat[key].value}л.),<br>
        Дата: ${timeStr}
      `;

      this.markersDrainOrRefueling[objId].push(
        this.addMaker({ latLon: consumptions_stat[key].latlon, text, options }),
      );
    }
  }

  deleteMarkersDrainOrRefueling(objId) {
    if (!this.markersDrainOrRefueling[objId]) return;

    this.markersDrainOrRefueling[objId].map((marker) => {
      this.dropMarker(marker);
    });

    this.markersDrainOrRefueling[objId].length = 0;
  }

  iconCreateFunction(cluster) {
    var childCount = cluster.getChildCount();
    var c = ' marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else if (childCount < 1000) {
      c += 'large';
    } else {
      c += 'extra-large';
    }

    return new L.DivIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'marker-cluster' + c,
      iconSize: new L.Point(40, 40),
    });
  }

  addObjPositions(objData, isRefresh) {
    //objectsPositions
    let latLon = [objData.lat, objData.lon];
    if (objData.dataId in this.objectsLastPositions) {
      // удалить слой на карте
      this.objectsLastPositions[objData.dataId].clearLayers(objData.dataId);
      delete this.objectsLastPositions[objData.dataId];
      if (!isRefresh) {
        return false;
      }
    }

    let rotationAngle = objData['speed'] > 0 ? objData.head : 0;
    let style = 'transform:rotate(-' + rotationAngle + 'deg);';
    if (!(rotationAngle > 0)) {
      style = '';
    }

    let markerSrc =
      objData['speed'] > 0
        ? '/images/arrow-move.png'
        : '/images/arrow-parking.png';

    let marker = new L.Marker(latLon, {
      icon: new L.DivIcon({
        className: 'arrow-map-icon',
        html:
          '<img class="arrow-map-image" src="' +
          markerSrc +
          '"/>' +
          '<span class="arrow-map-span" style="' +
          style +
          '">' +
          objData.name +
          '</span>',
        //   '<style>#' + objData.dataId + '-arrow-map-span' + ':{transform: rotate(' + objData.head + 'deg);} </style>' +
        //   '<style>#' + objData.dataId + '-arrow-map-span' + ':{color: white;} </style>'
      }),
      rotationAngle: rotationAngle,
    });

    // marker = L.circle( latLon, { radius : 2, color : "#f00", rotationAngle: objData.head } );
    let textShow =
      '<b>' +
      objData.name +
      '</b>' +
      ' (г/н ' +
      objData.stateNumber +
      ')<br>' +
      'время: ' +
      formatDateHelper(new Date(objData.lastPosTime), 'hh:nn:ss dd.mm.yy') +
      '<br>скорость(км/ч): ' +
      objData['speed'];
    marker.bindPopup(textShow);
    // marker.bindTooltip("textShow", {
    //     permanent: true,
    //     direction: 'right'
    // });

    this.objectsLastPositions[objData.dataId] = L.layerGroup([marker]).addTo(
      this.map,
    );

    if (!isRefresh) {
      this.map.fitBounds([latLon]);
    }

    return true;
  }

  createIcon(options) {
    return L.icon(options);
  }

  addMaker({ latLon, text, options } = {}) {
    const marker = L.marker(latLon, options).addTo(this.map);

    if (text) {
      marker.bindPopup(text).openPopup();
    }

    return marker;
  }

  moveMarker({ marker, latLon, text } = {}) {
    marker.setLatLng(latLon).setPopupContent(text);
  }

  dropMarker(marker) {
    marker.remove();
  }

  addPolyline({ latlngs, options, text } = {}) {
    options.pmIgnore = true;
    return new L.polyline(latlngs, options).addTo(this.map).bindPopup(text);
  }

  setLatLngsPolylyne({ polyline, latlngs, text } = {}) {
    polyline.setLatLngs(latlngs).setPopupContent(text);
  }

  dropPolline(polyline) {
    polyline.remove();
  }

  destroy() {
    this.removePmEventListeners();
  }
}

//создание карты
// var map = L.map('mapid').setView([61.262, 73.377], 13);
//получение карты
// L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiaXVyaWktNTA1IiwiYSI6ImNqemlldHRqbzA4NjYzbW84bTQ0OXMzNXkifQ.tPUaHbgVZ6vCSpuxk3IUJA', {
// 	attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
// 	maxZoom: 18,
// 	id: 'mapbox.streets',
// 	accessToken: 'your.mapbox.access.token'
// }).addTo(map);

//маркер на карте
//var marker = L.marker([61.262, 73.377]).addTo(mymap);
//marker.bindPopup("<b>Сургут</b><br>ЕНДС-ХМАО").openPopup();

/*function sendPost() {
    if (loc2 != null && loc1 != null) {
        var p1 = loc1.getLatLng(),
        p2 = loc2.getLatLng();
        $.post(
                //куда шлем запрос,
                {l1: p1.lat + ',' + p1.lng, l2: p2.lat + ',' + p2.lng},
        function(data) {
            if (data) {
                if (this.globalPolylines) {
                    map.removeLayer(this.globalPolylines);
                }
                var points = data;
                this.globalPolylines = new L.polyline(points, {color: 'red'});
                map.addLayer(this.globalPolylines);
                map.fitBounds(this.globalPolylines.getBounds());
            }
        },
                "json"
                );
    }
}*/
