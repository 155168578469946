import { computed, reactive } from 'vue';
import { useField } from './field';

const getError = (field, message) => {
  if (field.valid || !field.touched) return;

  const { required, requiredArray, email, minLength, maxLength, numeric } =
    field.errors;

  const checkValidatorWithRequired = (validator) => {
    if (validator && field.errors.hasOwnProperty('required')) {
      return true;
    }
    if (validator) {
      if (field.value.length > 0) return true;
    }

    field.valid = true;
    return false;
  };
  const error = (str) => {
    return message || str;
  };

  if (required) return error('Поле обязательно к заполнению');
  if (requiredArray) return error('Необходимо добавить хотя бы одну запись');

  if (email && checkValidatorWithRequired(email)) {
    return error('Email не корректен');
  }

  if (minLength && checkValidatorWithRequired(minLength)) {
    return error('Введено меньше необходимого количества символов');
  }

  if (maxLength && checkValidatorWithRequired(maxLength)) {
    return error('Введено больше допустимого количества символов');
  }

  if (numeric && checkValidatorWithRequired(numeric)) {
    return error('Вводимое значение должно быть числом');
  }
};

const hasOwnValidator = (field = {}, validatorName = '') => {
  const { validators } = field;

  if (!validators) return false;

  return validators.hasOwnProperty(validatorName);
};

export function useForm(init = {}) {
  const form = reactive({});
  const validKeys = ['valid', 'getError', 'hasOwnValidator', 'touched'];

  for (const [key, value] of Object.entries(init)) {
    form[key] = useField(value);
  }

  const withoutValid = (k) => !validKeys.includes(k);

  form.valid = computed(() =>
    Object.keys(form)
      .filter(withoutValid)
      .every((k) => form[k].valid),
  );

  form.touched = computed(() =>
    Object.keys(form)
      .filter(withoutValid)
      .some((k) => form[k].touched),
  );

  form.getError = getError;
  form.hasOwnValidator = hasOwnValidator;

  return form;
}
