const passPhrase = '7MCxX4#1NQzB';

export const encodeMessage = (message) => {
  try {
    if (!message && message === 'undefined') return;

    const B64Message = b64EncodeUnicode(message.toString());

    return enc(B64Message, passPhrase);
  } catch (e) {
    console.error(e);
  }
};

export const decodeMessage = (encodedMessage) => {
  try {
    if (!encodedMessage) return;

    const decodedBase64Message = dec(encodedMessage, passPhrase);

    return b64DecodeUnicode(decodedBase64Message);
  } catch (e) {
    console.error(e);
  }
};

function enc(str, key) {
  var length = key.length;
  var keyList = key.split('');
  var s = '',
    bit,
    bit1,
    bit2,
    bit3,
    bit4;
  for (var i = 0; i < str.length; i++) {
    bit = str.charCodeAt(i); // Получить кодировку Unicode символа (числа)
    bit1 = bit % length;
    bit = (bit - bit1) / length;
    bit2 = bit % length;
    bit = (bit - bit2) / length;
    bit3 = bit % length;
    bit = (bit - bit3) / length;
    bit4 = bit % length;
    // расположение ключа bit1, bit2, bit3, bit4
    // keyList [bit4] + keyList [bit3] + keyList [bit2] + keyList [bit1] Каждый символ в строке соответствует четырем символам секретного ключа
    s += keyList[bit4] + keyList[bit3] + keyList[bit2] + keyList[bit1];
  }
  return s;
}

function dec(str, key) {
  var length = key.length;
  var bit,
    bit1,
    bit2,
    bit3,
    bit4,
    j = 0,
    s;
  var s = new Array(Math.floor(str.length / 4));
  var result = [];
  bit = s.length;
  for (var i = 0; i < bit; i++) {
    bit1 = key.indexOf(str.charAt(j));
    j++;
    bit2 = key.indexOf(str.charAt(j));
    j++;
    bit3 = key.indexOf(str.charAt(j));
    j++;
    bit4 = key.indexOf(str.charAt(j));
    j++;
    // бит1, бит2, бит3, бит4 Положение каждых четырех ключевых символов соответствует одному символу строки
    s[i] =
      bit1 * length * length * length +
      bit2 * length * length +
      bit3 * length +
      bit4;
    // бит1 * длина * длина * длина + бит2 * длина * длина + бит3 * длина + бит4 восстанавливают кодировку Unicode для каждого символа строки
    result.push(String.fromCharCode(s[i])); // Восстановить данные из кодировки Unicode
  }
  // Восстановить символы
  return result.join('');
}

function b64EncodeUnicode(str) {
  // сначала мы используем encodeURIComponent,
  // чтобы получить кодировку UTF-8 в процентах,
  // затем мы преобразуем процентные кодировки в необработанные
  // байты, которые могут быть переданы в btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      },
    ),
  );
}

function b64DecodeUnicode(str) {
  // Двигаемся в обратном направлении: от потока байтов к кодированию в процентах, к исходной строке.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
}
